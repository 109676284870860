import {
  FETCH_PACKING_BEGIN,
  FETCH_PACKING_SUCCESS,
  FETCH_PACKING_FAILURE,
  DELETE_PACKING_SUCCESS,
  DELETE_PACKING_FAILURE,
  FETCH_PACKING_BY_ID_SUCCESS,
  FETCH_PACKING_BY_ID_FAILURE,
  UPDATE_PACKING_BEGIN,
  UPDATE_PACKING_FAILURE,
  UPDATE_PACKING_SUCCESS,
  CREATE_PACKING_BEGIN,
  CREATE_PACKING_FAILURE,
  CREATE_PACKING_SUCCESS,
  FETCH_PREPPING_BEGIN,
  FETCH_PREPPING_SUCCESS,
  FETCH_PREPPING_FAILURE,
  DELETE_PREPPING_SUCCESS,
  DELETE_PREPPING_FAILURE,
  FETCH_PREPPING_BY_ID_SUCCESS,
  FETCH_PREPPING_BY_ID_FAILURE,
  UPDATE_PREPPING_BEGIN,
  UPDATE_PREPPING_FAILURE,
  UPDATE_PREPPING_SUCCESS,
  CREATE_PREPPING_BEGIN,
  CREATE_PREPPING_FAILURE,
  CREATE_PREPPING_SUCCESS,
  FETCH_ADMINISTRATION_BEGIN,
  FETCH_ADMINISTRATION_SUCCESS,
  FETCH_ADMINISTRATION_FAILURE,
  DELETE_ADMINISTRATION_SUCCESS,
  DELETE_ADMINISTRATION_FAILURE,
  FETCH_ADMINISTRATION_BY_ID_SUCCESS,
  FETCH_ADMINISTRATION_BY_ID_FAILURE,
  UPDATE_ADMINISTRATION_BEGIN,
  UPDATE_ADMINISTRATION_FAILURE,
  UPDATE_ADMINISTRATION_SUCCESS,
  CREATE_ADMINISTRATION_BEGIN,
  CREATE_ADMINISTRATION_FAILURE,
  CREATE_ADMINISTRATION_SUCCESS,
  FETCH_INBOUND_BEGIN,
  FETCH_INBOUND_SUCCESS,
  FETCH_INBOUND_FAILURE,
  DELETE_INBOUND_SUCCESS,
  DELETE_INBOUND_FAILURE,
  FETCH_INBOUND_BY_ID_SUCCESS,
  FETCH_INBOUND_BY_ID_FAILURE,
  UPDATE_INBOUND_BEGIN,
  UPDATE_INBOUND_FAILURE,
  UPDATE_INBOUND_SUCCESS,
  CREATE_INBOUND_BEGIN,
  CREATE_INBOUND_FAILURE,
  CREATE_INBOUND_SUCCESS,
  FETCH_PICKING_BEGIN,
  FETCH_PICKING_SUCCESS,
  FETCH_PICKING_FAILURE,
  DELETE_PICKING_SUCCESS,
  DELETE_PICKING_FAILURE,
  FETCH_PICKING_BY_RANGE_SUCCESS,
  FETCH_PICKING_BY_ID_FAILURE,
  UPDATE_PICKING_BEGIN,
  UPDATE_PICKING_FAILURE,
  UPDATE_PICKING_SUCCESS,
  CREATE_PICKING_BEGIN,
  CREATE_PICKING_FAILURE,
  CREATE_PICKING_SUCCESS,
  FETCH_PICKING_TIERS_BEGIN,
  FETCH_PICKING_TIERS_SUCCESS,
  FETCH_PICKING_TIERS_FAILURE,
  CLEAR_RATES_NOTIFICATIONS,
  UPDATE_PICKERSERVICE_PRICES_BEGIN,
  UPDATE_PICKERSERVICE_PRICES_SUCCESS,
  UPDATE_PICKERSERVICE_PRICES_FAILURE,
  UPDATE_PICKERSERVICE_MARGINS_BEGIN,
  UPDATE_PICKERSERVICE_MARGINS_SUCCESS,
  UPDATE_PICKERSERVICE_MARGINS_FAILURE,
} from "actions/admin/ratesActions";

// specify initial state
const initialState = {
  packing: [],
  loading: false,
  error: null,
  notification: null,
  dummyData: [...Array(10)],
  fetchedPacking: {
    id: null,
    title: null,
    cost: null,
    min_volume: null,
    max_volume: null,
    min_actual_weight: null,
    max_actual_weight: null,
    archived: false,
    max_height: null,
    margin_L1: null,
    margin_L2: null,
    margin_L3: null,
    margin_L4: null,
    margin_L5: null,
    margin_L6: null,
    margin_L7: null,
    margin_L8: null,
  },
  formLoading: false,
  formSuccess: false,

  prepping: [],
  fetchedPrepping: {
    id: null,
    title: null,
    cost: null,
    description: null,
    margin_L1: null,
    margin_L2: null,
    margin_L3: null,
    margin_L4: null,
    margin_L5: null,
    margin_L6: null,
    margin_L7: null,
    margin_L8: null,
  },

  administration: [],
  fetchedAdministration: {
    id: null,
    title: null,
    cost: null,
    description: null,
    margin_L1: null,
    margin_L2: null,
    margin_L3: null,
    margin_L4: null,
    margin_L5: null,
    margin_L6: null,
    margin_L7: null,
    margin_L8: null,
  },

  inboundTypes: [],
  fetchedInbound: {
    id: null,
    title: null,
    cost: null,
    description: null,
    margin_L1: null,
    margin_L2: null,
    margin_L3: null,
    margin_L4: null,
    margin_L5: null,
    margin_L6: null,
    margin_L7: null,
    margin_L8: null,
  },

  picks: {},
  pickTiers: {},
  volumeRanges: [],
  pickTierMargins: [],
  marginRanges: {},
  fetchedPicking: {
    min_weight: null,
    max_weight: null,
    pick_tier_margin: {
      margin_L1: null,
      margin_L2: null,
      margin_L3: null,
      margin_L4: null,
      margin_L5: null,
      margin_L6: null,
      margin_L7: null,
      margin_L8: null,
    },
    picks: [
      {
        cost: null,
        volume_range: null,
      },
      {
        cost: null,
        volume_range: null,
      },
      {
        cost: null,
        volume_range: null,
      },
      {
        cost: null,
        volume_range: null,
      },
      {
        cost: null,
        volume_range: null,
      },
    ],
  },
};

/*views->actions->reducers(state)
    ^               |
    |---------------|
*/
export default function ratesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PACKING_BEGIN:
      return {
        ...state, //imp line
        notification: null,
        loading: true,
        error: null,
      };

    case FETCH_PACKING_SUCCESS:
      return {
        ...state,
        loading: false,
        packing: action.data,
      };

    case FETCH_PACKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        packing: [],
      };
    case DELETE_PACKING_SUCCESS:
      return {
        ...state,
        error: null,
        packing: state.packing.filter(
          (packing) => packing.id !== action.data.id
        ),
        notification: action.msg,
      };
    case DELETE_PACKING_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        packing: state.packing,
        notification: action.msg,
      };

    case FETCH_PACKING_BY_ID_SUCCESS:
      return {
        ...state,
        //childLoading: false,
        fetchedPacking: action.payload,
      };

    case FETCH_PACKING_BY_ID_FAILURE:
      return {
        ...state,
        //childLoading: false,
        error: action.payload.error,
        notification: action.msg,
      };
    case UPDATE_PACKING_BEGIN: {
      return {
        ...state,
        error: null,
        notification: null,
        formLoading: true,
        formSuccess: false,
      };
    }
    case UPDATE_PACKING_SUCCESS: {
      let updatedPackings = state.packing.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
      // let updatedPacking =  applyPackingInterface(action.payload.packing)
      return {
        ...state,
        formLoading: false,
        formSuccess: true,
        errors: null,
        updatePackingErrors: null,
        fetchedPacking: action.payload,
        packing: updatedPackings,
      };
    }
    case UPDATE_PACKING_FAILURE: {
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    }
    case CREATE_PACKING_BEGIN:
      return {
        ...state,
        error: null,
        notification: null,
        formLoading: true,
        formSuccess: false,
      };

    case CREATE_PACKING_SUCCESS: {
      return {
        ...state,
        errors: null,
        formSuccess: true,
        formLoading: false,
        packing: [action.payload.packing, ...state.packing],
      };
    }
    case CREATE_PACKING_FAILURE:
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    /*---------------------Prepping----------------------------*/
    case FETCH_PREPPING_BEGIN:
      return {
        ...state, //imp line
        error: null,
        notification: null,
        loading: true,
      };

    case FETCH_PREPPING_SUCCESS:
      return {
        ...state,
        loading: false,
        prepping: action.data,
      };

    case FETCH_PREPPING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        prepping: [],
      };
    case DELETE_PREPPING_SUCCESS:
      return {
        ...state,
        error: null,
        prepping: state.prepping.filter(
          (prepping) => prepping.id !== action.data.id
        ),
        notification: action.msg,
      };
    case DELETE_PREPPING_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        prepping: state.prepping,
        notification: action.msg,
      };

    case FETCH_PREPPING_BY_ID_SUCCESS:
      return {
        ...state,
        //childLoading: false,
        fetchedPrepping: action.payload,
      };

    case FETCH_PREPPING_BY_ID_FAILURE:
      return {
        ...state,
        //childLoading: false,
        error: action.payload.error,
        notification: action.msg,
      };
    case UPDATE_PREPPING_BEGIN: {
      return {
        ...state,
        error: null,
        notification: null,
        formLoading: true,
        formSuccess: false,
      };
    }
    case UPDATE_PREPPING_SUCCESS: {
      // let updatedPrepping =  applyPreppingInterface(action.payload.prepping)
      return {
        ...state,
        formLoading: false,
        formSuccess: true,
        errors: null,
        updatePreppingErrors: null,
        fetchedPrepping: action.payload,
        // prepping: updatedPreppings
      };
    }
    case UPDATE_PREPPING_FAILURE: {
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    }
    case CREATE_PREPPING_BEGIN:
      return {
        ...state,
        error: null,
        notification: null,
        formLoading: true,
        formSuccess: false,
      };

    case CREATE_PREPPING_SUCCESS: {
      return {
        ...state,
        errors: null,
        formSuccess: true,
        formLoading: false,
        prepping: [action.payload.prepping, ...state.prepping],
      };
    }
    case CREATE_PREPPING_FAILURE:
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };

    /*---------------------Administration----------------------------*/
    case FETCH_ADMINISTRATION_BEGIN:
      return {
        ...state, //imp line
        error: null,
        loading: true,
        notification: null,
      };

    case FETCH_ADMINISTRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        administration: action.data,
      };

    case FETCH_ADMINISTRATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        administration: [],
      };
    case DELETE_ADMINISTRATION_SUCCESS:
      return {
        ...state,
        error: null,
        administration: state.administration.filter(
          (administration) => administration.id !== action.data.id
        ),
        notification: action.msg,
      };
    case DELETE_ADMINISTRATION_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        administration: state.administration,
        notification: action.msg,
      };

    case FETCH_ADMINISTRATION_BY_ID_SUCCESS:
      return {
        ...state,
        //childLoading: false,
        fetchedAdministration: action.payload,
      };

    case FETCH_ADMINISTRATION_BY_ID_FAILURE:
      return {
        ...state,
        //childLoading: false,
        error: action.payload.error,
        notification: action.msg,
      };
    case UPDATE_ADMINISTRATION_BEGIN: {
      return {
        ...state,
        error: null,
        notification: null,
        formLoading: true,
        formSuccess: false,
      };
    }
    case UPDATE_ADMINISTRATION_SUCCESS: {
      let updatedAdministrations = state.administration.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
      // let updatedAdministration =  applyAdministrationInterface(action.payload.administration)
      return {
        ...state,
        formLoading: false,
        formSuccess: true,
        errors: null,
        updateAdministrationErrors: null,
        fetchedAdministration: action.payload,
        administration: updatedAdministrations,
      };
    }
    case UPDATE_ADMINISTRATION_FAILURE: {
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    }
    case CREATE_ADMINISTRATION_BEGIN:
      return {
        ...state,
        error: null,
        notification: null,
        formLoading: true,
        formSuccess: false,
      };

    case CREATE_ADMINISTRATION_SUCCESS: {
      return {
        ...state,
        errors: null,
        formSuccess: true,
        formLoading: false,
        administration: [
          action.payload.administration,
          ...state.administration,
        ],
      };
    }
    case CREATE_ADMINISTRATION_FAILURE:
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };

    /*---------------------Inbound----------------------------*/
    case FETCH_INBOUND_BEGIN:
      return {
        ...state, //imp line
        error: null,
        notification: null,
        loading: true,
        error: null,
      };

    case FETCH_INBOUND_SUCCESS:
      return {
        ...state,
        loading: false,
        inboundTypes: action.data,
      };

    case FETCH_INBOUND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        inboundTypes: [],
      };
    case DELETE_INBOUND_SUCCESS:
      return {
        ...state,
        error: null,
        inboundTypes: state.inboundTypes.filter(
          (inbound) => inbound.id !== action.data.id
        ),
        notification: action.msg,
      };
    case DELETE_INBOUND_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        inboundTypes: state.inbound,
        notification: action.msg,
      };

    case FETCH_INBOUND_BY_ID_SUCCESS:
      return {
        ...state,
        //childLoading: false,
        fetchedInbound: action.payload,
      };

    case FETCH_INBOUND_BY_ID_FAILURE:
      return {
        ...state,
        //childLoading: false,
        error: action.payload.error,
        notification: action.msg,
      };
    case UPDATE_INBOUND_BEGIN: {
      return {
        ...state,
        error: null,
        notification: null,
        formLoading: true,
        formSuccess: false,
      };
    }
    case UPDATE_INBOUND_SUCCESS: {
      let updatedInbounds = state.inboundTypes.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
      // let updatedInbound =  applyInboundInterface(action.payload.inbound)
      return {
        ...state,
        formLoading: false,
        formSuccess: true,
        errors: null,
        updateInboundErrors: null,
        fetchedInbound: action.payload,
        inboundTypes: updatedInbounds,
      };
    }
    case UPDATE_INBOUND_FAILURE: {
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    }
    case CREATE_INBOUND_BEGIN:
      return {
        ...state,
        error: null,
        notification: null,
        formLoading: true,
        formSuccess: false,
      };

    case CREATE_INBOUND_SUCCESS: {
      return {
        ...state,
        errors: null,
        formSuccess: true,
        formLoading: false,
        inboundTypes: [action.payload.inbound, ...state.inboundTypes],
      };
    }
    case CREATE_INBOUND_FAILURE:
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };

    /*---------------------Picking----------------------------*/
    case FETCH_PICKING_BEGIN:
      return {
        ...state, //imp line
        error: null,
        notification: null,
        loading: true,
      };
    case FETCH_PICKING_SUCCESS:
      return {
        ...state,
        loading: false,
        picks: action.data,
      };
    case FETCH_PICKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        picks: {},
      };
    case FETCH_PICKING_TIERS_BEGIN:
      return {
        ...state,
        loading: false,
        notification: null,
      };
    case FETCH_PICKING_TIERS_SUCCESS:
      let pick = { ...state.pickTiers };
      pick[action.level] = action.data;
      return {
        ...state,
        loading: false,
        pickTiers: pick,
      };
    case FETCH_PICKING_TIERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        pickTiers: {},
      };
    case DELETE_PICKING_SUCCESS:
      return {
        ...state,
        error: null,
        //picks: action.data,
        notification: action.msg,
      };
    case DELETE_PICKING_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        picks: state.picks,
        notification: action.msg,
      };
    case FETCH_PICKING_BY_RANGE_SUCCESS:
      return {
        ...state,
        //childLoading: false,
        fetchedPicking: action.payload,
      };
    case FETCH_PICKING_BY_ID_FAILURE:
      return {
        ...state,
        //childLoading: false,
        error: action.payload.error,
        notification: action.msg,
      };
    case UPDATE_PICKING_BEGIN: {
      return {
        ...state,
        error: null,
        notification: null,
        formLoading: true,
        formSuccess: false,
      };
    }
    case UPDATE_PICKING_SUCCESS: {
      /*let updatedPickings = state.picks.map(item => item.id === action.payload.id ?
                action.payload : item
            )*/
      // let updatedPicking =  applyPickingInterface(action.payload.picks)
      return {
        ...state,
        formLoading: false,
        formSuccess: true,
        errors: null,
        updatePickingErrors: null,
        fetchedPicking: action.payload,
        // picks: updatedPickings
      };
    }
    case UPDATE_PICKING_FAILURE: {
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    }
    case CREATE_PICKING_BEGIN:
      return {
        ...state,
        error: null,
        notification: null,
        formLoading: true,
        formSuccess: false,
      };
    case CREATE_PICKING_SUCCESS: {
      return {
        ...state,
        errors: null,
        formSuccess: true,
        formLoading: false,
        // picks: [action.payload.picking, ...state.picks]
      };
    }
    case CREATE_PICKING_FAILURE:
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    case CLEAR_RATES_NOTIFICATIONS:
      return {
        ...state,
        error: null,
        notification: null,
      };
    case UPDATE_PICKERSERVICE_PRICES_BEGIN: {
      return {
        ...state,
        formLoading: true,
        formSuccess: false,
        error: null,
        notification: null,
      };
    }
    case UPDATE_PICKERSERVICE_PRICES_SUCCESS: {
      return {
        ...state,
        formLoading: false,
        formSuccess: true,
        errors: null,
        fetchedPickerServicePrices: action.payload,
        pickerServicePrices: action.payload,
      };
    }
    case UPDATE_PICKERSERVICE_PRICES_FAILURE: {
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    }
    case UPDATE_PICKERSERVICE_MARGINS_BEGIN: {
      return {
        ...state,
        formLoading: true,
        formSuccess: false,
        error: null,
        notification: null,
      };
    }
    case UPDATE_PICKERSERVICE_MARGINS_SUCCESS: {
      return {
        ...state,
        formLoading: false,
        formSuccess: true,
        errors: null,
        fetchedPickerServicePrices: action.payload,
        pickerServicePrices: action.payload,
      };
    }
    case UPDATE_PICKERSERVICE_MARGINS_FAILURE: {
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    }
    default:
      // Always have a default case in case a reducer doesn't complete
      return state;
  }
}
