import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { reverseUserRoute } from 'routes';
import store from "index";

import { makeStyles, Card, Button, CircularProgress } from "@material-ui/core";
import { ValidatorForm } from 'react-material-ui-form-validator';

import Form from 'components/Form';
import { Page, HeaderAndSubtitle } from "components";
import BundleComponents from "./components/BundleComponents";
import SuccessSnackbar from 'components/Help/SuccessSnackbar';
import {UID} from 'AppConstants';
import { createBundle } from 'actions/bundleActions';

export const NewBundle = (props) => {
  const classes = useStyles();
  const {error, newBundleErrors, creating, permittedToAccess} = props
  const [newBundleState, setNewBundleState] = useState({})
  const [bundleComponents, setBundleComponents] = useState([])
  const [totalProducts, setTotalProducts] = useState(0);

  if (!permittedToAccess("bundles:create")) {props.history.push(`/user/${UID}/space/bundles`)}

  let inputRef = React.createRef("form");
  const dispatch = useDispatch();

  const bundleFormInputs = [
    {
      id: "title",
      label: "Bundle title",
      required: true,
    },
    {
      id: "sku",
      label: "Sku",
      required: true,
    },
    {
      id: "description",
      label: "Description",
      required: true,
    },
  ];


  React.useEffect(() => {
    var newTotalProductsCount = 0;
    bundleComponents.forEach((bundle_component) => {
      newTotalProductsCount += bundle_component.quantity;
    });
    setTotalProducts(newTotalProductsCount);
  }, [bundleComponents]);

  // if there's no bundle componnents added and no input in bundleFormInputs, disable Create button
  const countInputs = Object.values(newBundleState).filter(n=>n).length
  const submitEnabled = (bundleComponents.length > 0 && countInputs === bundleFormInputs.length)

  const quantityVariation = (id, quantityVariation) => {
    let updatedBundleComponent
    let newValue

    if (quantityVariation > 0) {
      newValue = parseInt(quantityVariation)
    } else {
      newValue = 1
    }

    updatedBundleComponent = bundleComponents.map(component => (
          component.item.id === id ? (
            { item: component.item, quantity: newValue }
          ) : (
            component
          )
    ))
    setBundleComponents(updatedBundleComponent);
  }

  const deleteComponent = (id) => {
    let updatedBundleComponent = bundleComponents.filter(component => (
        component.item.id !== id ))
    setBundleComponents(updatedBundleComponent);
  }

  const addBundleComponent = (id) => {
    let isNewBundleComponent = true;

    let bookingItemObj = bundleComponents.find(bundleComponent => bundleComponent.item && bundleComponent.item.id === id);
    if (bookingItemObj) isNewBundleComponent = false;

    if (!isNewBundleComponent) {
      let bundleComponentsUpdatedQty = bundleComponents.map((bundleComponent) =>
        bundleComponent.item_id === id
          ? { item_id: bundleComponent.item_id, quantity: 1 + bundleComponent.quantity }
          : bundleComponent
      );
      setBundleComponents(bundleComponentsUpdatedQty);
      return;
    }

    const item =  store.getState().spaceReducer.items.find(item => item.id === id);

    let newItem = { item: item, quantity: 1 };
    setBundleComponents([...bundleComponents, newItem]);
  }

  const handleChange = (event) => {
    setNewBundleState({...newBundleState, [event.target.id]: event.target.value})
  };

  const submit = (event) => {
    event.preventDefault();
    let components = bundleComponents.map((bundleComponent) => ({
      item_id: bundleComponent.item.id,
      item_quantity: bundleComponent.quantity,
    }));

    dispatch(createBundle(newBundleState, components)).then((result) => {
      if (result) {
        // result is null when in error
        props.history.push(reverseUserRoute('Bundles'));
      }
    });
  };

  return (
    <Page title="Add New Bundle" className={classes.root}>
        <SuccessSnackbar
          open={error !== null}
          error={error ? error.response.statusText : null}
        />
        <HeaderAndSubtitle
          header="Space / Bundles"
          subtitle="New Bundle"
        />
          <ValidatorForm
            ref={inputRef}
            onSubmit={submit}
            onError={error => console.log(error)}
          >
            <Card className={classes.card} >
                <Form
                  gridWidth={12}
                  key="3additionaldetails"
                  inputs={bundleFormInputs}
                  state={newBundleState}
                  onChange={handleChange}
                  errors={newBundleErrors}
                />
            </Card>
            <BundleComponents
              key="products"
              bundleComponents={bundleComponents}
              quantityVariation={quantityVariation}
              addBundleComponent={addBundleComponent}
              totalProducts={totalProducts}
              deleteComponent={deleteComponent}
              orderType="inbound"
            />
            <div className={classes.buttons} >
              { creating ? (
                  <CircularProgress color="secondary" />
              ) : (
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={()=>props.history.push(reverseUserRoute('Bundles'))}
                      style={{ marginRight: 10 }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type='submit'
                      aria-label="submit"
                      disabled={!submitEnabled}
                    >
                      Create Bundle
                    </Button>
                  </>
              )}
            </div>
          </ValidatorForm>
    </Page>
  )
}

const useStyles = makeStyles((theme) => ({
    root: {
          minHeight: '400px',
          flexWrap: 'wrap',
    },
    card: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    dialog: {
        background: '#eeeeee',
        overflowY: 'scroll',
        '-webkit-overflow-scrolling': 'touch',
    },
    dialogContent: {
        minHeight: '800px'
    },
    moreActions: {
        position: 'absolute',
        right: 8,
        top: 8,
    },
    buttons : {
        display: 'flex',
        justifyContent: 'right',
    }
}));

export default NewBundle;

