import React, { Component } from 'react';
import Step1 from './RegistrationSteps/Step1';
import Step2 from './RegistrationSteps/Step2';
import Step3 from './RegistrationSteps/Step3';
import { registerUser } from 'redux-token-auth-config' // <-- note this is YOUR file, not the redux-token-auth NPM module
import { connect } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles';
import {Paper} from '@material-ui/core';
import authStyles from "./styles";
import { createUserFailure, createUserSuccess, updateCreateUser } from 'actions/userActions'

export class RegisterScreen extends Component {
  constructor (props) {
    super(props);
    this.state = {
        errorCode: null,
    };
    this.submitForm = this.submitForm.bind(this);
    this.prevStep = this.prevStep.bind(this)
    this.nextStep = this.nextStep.bind(this)
    this.handleResendConfirmation = this.handleResendConfirmation.bind(this);
  }

  // Proceed to next step
  nextStep = () => {
    let nextStep = this.props.step + 1
    this.props.dispatch(updateCreateUser('step', nextStep))

  };

  // Go back to prev step
  prevStep = () => {
    let prevStep = this.props.step - 1
    this.props.dispatch(updateCreateUser('step', prevStep))
  };

  // Handle fields change
  handleChange = event => {
    let newValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    this.props.dispatch(updateCreateUser(event.target.name, newValue))
  }

  submitForm (e) {
    e.preventDefault()
    const {
        firstName,
        lastName,
        email,
        password,
        termsOfService
    } = this.props.newUser

    this.props.dispatch(registerUser({
        firstName,
        lastName,
        email,
        password,
        termsOfService,
        organisationsAttributes: [{
          email: this.props.organisationsAttributes.companyEmail,
          web_url: this.props.organisationsAttributes.webUrl,
          name: this.props.organisationsAttributes.name,
          orders_per_month: this.props.organisationsAttributes.ordersMonth,
        }]

      }))
    .then((response) => {
        this.props.dispatch(createUserSuccess())
    })
    .catch((error) => {
        let errors = error.response.data.errors
        this.props.dispatch(createUserFailure(errors))
    })
  }

  handleResendConfirmation(e) {
  }

  getStepContent(step, userValues, organisationValues) {
    switch (step) {
      case 1:
        return (
          <Step1
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            handleToggle={this.handleToggle}
            values={userValues}
            errors={this.props.errors}
          />
        );
      case 2:
        return (
          <Step2
            handleChange={this.handleChange}
            values={organisationValues}
            firstName={userValues.firstName}
            submitForm ={this.submitForm}
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            errors={this.props.errors}
          />
        );
      case 3:
        return (
          <Step3
            prevStep={this.prevStep}
            handleResendConfirmation={this.handleResendConfirmation}
          />

        );
      default:
        return null
      }
    }


  render() {
    const { step, classes } = this.props;
    const userValues = this.props.newUser;
    const organisationValues = this.props.organisationsAttributes;

    return (
      <Paper className={classes.paper}>
        <div className={classes.alignRight}>
          <a href="/auth/login">Log In</a>
        </div>
        {this.getStepContent(step, userValues, organisationValues)}
      </Paper>
    )
  }
}

const styles = theme => ({
    ...authStyles
})

const mapStateToProps = (state, ownProps) => ({
    step: state.usersReducer.step,
    errors: state.usersReducer.createError,
    newUser: state.usersReducer.newUser,
    organisationsAttributes: state.usersReducer.organisationsAttributes,
    loading: state.usersReducer.loading,
    ...ownProps
})

export default connect(mapStateToProps)(withStyles(styles)(RegisterScreen))
