import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import keepspaceApi from 'services/keepspaceApi';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Badge,
  IconButton,
  Toolbar,
  Hidden,
  Avatar,
  colors,
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import MenuIcon from '@material-ui/icons/Menu';

import NotificationsPopover from 'components/Popover/NotificationsPopover';
import { PricingModal } from 'components';

import KSLogo from 'assets/img/Keepspace-logo-200-inverted.png'
import ProfilePopover from 'components/Popover/ProfilePopover';
import OrganisationsPopover from 'components/Popover/OrganisationsPopover';
import { setCurrentOrganisation } from 'actions/organisationActions';
import checkPermission from "components/checkPermission";

import { UID } from 'AppConstants';
const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main
  },
  flexGrow: {
    flexGrow: 1
  },
  logo: {
    height: 24
  },
  iconButton: {
    //marginLeft: theme.spacing(1),
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
  avatar: {
    marginLeft: theme.spacing(1),
    width: 30,
    height: 30,
    padding: 1,
    background: 'white',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 0 5px 2px #babeff'
    }
  },
}));

const TopBar = props => {
  const { onOpenNavBarMobile, className, ...rest } = props;

  const classes = useStyles();
  const notificationsRef = useRef(null);
  const profileRef = useRef(null);
  const organisationRef = useRef(null);
  const [pricingModalOpen, setPricingModalOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openOrganisation, setOpenOrganisation] = useState(false);
  const currentOrganisation = useSelector(state => state.organisationsReducer.currentOrganisation);
  const organisations = useSelector(state => state.organisationsReducer.organisations);
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;

    const fetchNotifications = () => {
      keepspaceApi.getNotifications()
        .then(json => {
          if (mounted) setNotifications([
            { id: 1, title: `You have ${json.unread || '0'} new messages`, type: 'message', link: '/user/' + UID + '/chat' },
            { id: 2, title: `${json.needAttention || '0'} orders need your attention`, type: 'needAttention', link: '/user/' + UID + '/orders/summary' },
            { id: 3, title: `${json.lowStock || '0'} skus below recommended stock levels`, type: 'lowStock', link: '/user/' + UID + '/space/products/no_stock' }
          ])
        })
        .catch(error => console.log(error))
    };

    if (window.location.pathname !== "/auth/login") {
      fetchNotifications();
    }

    return () => {
      mounted = false;
    };
  }, []);

  const handlePricingClose = () => {
    setPricingModalOpen(false);
  };

  const handleNotificationsOpen = () =>
    setOpenNotifications(true);

  const handleNotificationsClose = () =>
    setOpenNotifications(false);

  const handleProfileOpen = () =>
    setOpenProfile(true);

  const handleProfileClose = () =>
    setOpenProfile(false);

  const handleOrganisationOpen = () =>
    setOpenOrganisation(true);

  const handleOrganisationClose = () =>
    setOpenOrganisation(false);

  const handleChangeOrganisation = organisation => {
    dispatch(setCurrentOrganisation(organisation))
    window.localStorage.setItem('organisation-uid', organisation.uid);
    keepspaceApi.refreshAuthToken();
    const url = window.location.href;
    if (url.includes('user') && !url.includes('getting-started') && !url.includes('changelog')) {
      const uid = url.split('/')[4];
      const updatedUrl = url.replace(uid, organisation.uid);
      window.location.assign("/");
    }
    else {
      window.location.assign(url);
    }
  }

  let logo
  let avatarUrl

  if (currentOrganisation.id) {
    avatarUrl = currentOrganisation.logo.url ? currentOrganisation.logo.url : require(`assets/img/avatars/00${currentOrganisation.id % 46 + 1}.svg`)
  } else {
    avatarUrl = require(`assets/img/avatars/001.svg`)
  }

  logo =
    <Avatar
      onClick={handleOrganisationOpen}
      ref={organisationRef}
      alt="Organisation"
      className={classes.avatar}
      src={avatarUrl}
    />


  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"  // set in makestyles
    >
      <Toolbar>

        <Hidden mdUp>
          <IconButton
            color="inherit"
            onClick={onOpenNavBarMobile}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>

        <a href='/'>
          <img className={classes.logo}
            alt="Logo"
            src={KSLogo}
          />
        </a>
        <div className={classes.flexGrow} />

        <IconButton
          className={classes.iconButton}
          color="inherit"
          onClick={handleProfileOpen}
          ref={profileRef}
        >
          <Badge
            badgeContent={1}
            classes={{ badge: classes.notificationsBadge }}
            variant="dot"
          >
            <PersonIcon />
          </Badge>
        </IconButton>

        <IconButton
          className={classes.iconButton}
          color="inherit"
          onClick={handleNotificationsOpen}
          ref={notificationsRef}
        >
          <Badge
            badgeContent={notifications.length}
            classes={{ badge: classes.notificationsBadge }}
            variant="dot"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>

        {logo}



      </Toolbar>
      <PricingModal
        onClose={handlePricingClose}
        open={pricingModalOpen}
      />
      <ProfilePopover
        anchorEl={profileRef.current}
        notifications={[]}
        onClose={handleProfileClose}
        open={openProfile}
        disabled={!checkPermission(currentOrganisation.currentOrganisationUser.role, "organisations:view")}
      />
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        seeAllLink={`/user/${UID}/overview`}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
      <OrganisationsPopover
        anchorEl={organisationRef.current}
        handleClick={handleChangeOrganisation}
        organisations={organisations}
        onClose={handleOrganisationClose}
        open={openOrganisation}
      />
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
