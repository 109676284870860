import { apiPath, authHeaders } from "AppConstants";
import adminApi from "services/admin/adminApi";

export const FETCH_SKU_BEGIN = "FETCH_SKU_BEGIN";
export const FETCH_SKU_SUCCESS = "FETCH_SKU_SUCCESS";
export const FETCH_SKU_FILTERS_SUCCESS = "FETCH_SKU_FILTERS_SUCCESS";
export const FETCH_SKU_FAILURE = "FETCH_SKU_FAILURE";

export const SEARCH_SKU_BEGIN = "SEARCH_SKU_BEGIN";
export const SEARCH_SKU_SUCCESS = "SEARCH_SKU_SUCCESS";
export const SEARCH_SKU_FAILURE = "SEARCH_SKU_FAILURE";

export const SET_SEARCH_TERM = "SET_SEARCH_TERM";
export const SET_SEARCH_ACTIVE = "SET_SEARCH_ACTIVE";

export const CLEAR_SKUS = "CLEAR_SKUS";

export const CREATE_SKU_BEGIN = "CREATE_SKU_BEGIN";
export const CREATE_SKU_SUCCESS = "CREATE_SKU_SUCCESS";
export const CREATE_SKU_FAILURE = "CREATE_SKU_FAILURE";

export const UPDATE_SKU_BEGIN = "UPDATE_SKU_BEGIN";
export const UPDATE_SKU_SUCCESS = "UPDATE_SKU_SUCCESS";
export const UPDATE_SKU_FAILURE = "UPDATE_SKU_FAILURE";
export const SET_MULTIPLE_SKU_ERROR = "SET_MULTIPLE_SKU_ERROR";
export const SET_SKU_FILTERS = "SET_SKU_FILTERS";

export const FETCH_SKU_BY_ID_BEGIN = "FETCH_SKU_BY_ID_BEGIN";
export const FETCH_SKU_BY_ID_SUCCESS = "FETCH_SKU_BY_ID_SUCCESS";
export const FETCH_SKU_BY_ID_FAILURE = "FETCH_SKU_BY_ID_FAILURE";

export const FETCH_ITEM_HISTORY_BEGIN = "FETCH_ITEM_HISTORY_BEGIN";
export const FETCH_ITEM_HISTORY_SUCCESS = "FETCH_ITEM_HISTORY_SUCCESS";
export const FETCH_ITEM_HISTORY_FAILURE = "FETCH_ITEM_HISTORY_FAILURE";

export const FETCH_MOVEMENT_BEGIN = "FETCH_MOVEMENT_BEGIN";
export const FETCH_MOVEMENT_SUCCESS = "FETCH_MOVEMENT_SUCCESS";
export const FETCH_MOVEMENT_FAILURE = "FETCH_MOVEMENT_FAILURE";

export const FETCH_TYPES_BEGIN = "FETCH_TYPES_BEGIN";
export const FETCH_TYPES_SUCCESS = "FETCH_TYPES_SUCCESS";
export const FETCH_TYPES_FAILURE = "FETCH_TYPES_FAILURE";

export const FETCH_SKU_ORDERS_BEGIN = "FETCH_SKU_ORDERS_BEGIN";
export const FETCH_SKU_ORDERS_SUCCESS = "FETCH_SKU_ORDERS_SUCCESS";
export const FETCH_SKU_ORDERS_FAILURE = "FETCH_SKU_ORDERS_FAILURE";

export const DELETE_SKU_TYPE_BEGIN = "DELETE_SKU_TYPE_BEGIN";
export const DELETE_SKU_TYPE_SUCCESS = "DELETE_SKU_TYPE_SUCCESS";
export const DELETE_SKU_TYPE_FAILURE = "DELETE_SKU_TYPE_FAILURE";

export const BREAKDOWN_SKU_TYPE_BEGIN = "BREAKDOWN_SKU_TYPE_BEGIN";
export const BREAKDOWN_SKU_TYPE_SUCCESS = "BREAKDOWN_SKU_TYPE_SUCCESS";
export const BREAKDOWN_SKU_TYPE_FAILURE = "BREAKDOWN_SKU_TYPE_FAILURE";

export const GET_PARENT_SKU_BEGIN = "GET_PARENT_SKU_BEGIN";
export const GET_PARENT_SKU_SUCCESS = "GET_PARENT_SKU_SUCCESS";
export const GET_PARENT_SKU_FAILURE = "GET_PARENT_SKU_FAILURE";

export const CREATE_SKU_TYPE_BEGIN = "CREATE_SKU_TYPE_BEGIN";
export const CREATE_SKU_TYPE_SUCCESS = "CREATE_SKU_TYPE_SUCCESS";
export const CREATE_SKU_TYPE_FAILURE = "CREATE_SKU_TYPE_FAILURE";

export const UPDATE_SKU_TYPE_BEGIN = "UPDATE_SKU_TYPE_BEGIN";
export const UPDATE_SKU_TYPE_SUCCESS = "UPDATE_SKU_TYPE_SUCCESS";
export const UPDATE_SKU_TYPE_FAILURE = "UPDATE_SKU_TYPE_FAILURE";

export const DELETE_SKU_BEGIN = "DELETE_SKU_BEGIN";
export const DELETE_SKU_SUCCESS = "DELETE_SKU_SUCCESS";
export const DELETE_SKU_FAILURE = "DELETE_SKU_FAILURE";

export const UNARCHIVE_SKU_BEGIN = "UNARCHIVE_SKU_BEGIN";
export const UNARCHIVE_SKU_SUCCESS = "UNARCHIVE_SKU_SUCCESS";
export const UNARCHIVE_SKU_FAILURE = "UNARCHIVE_SKU_FAILURE";

export const FETCH_LOCATIONS_BEGIN = "FETCH_LOCATIONS_BEGIN";
export const FETCH_LOCATIONS_SUCCESS = "FETCH_LOCATIONS_SUCCESS";
export const FETCH_LOCATIONS_FAILURE = "FETCH_LOCATIONS_FAILURE";
export const FETCH_LOCATIONS_BY_BARCODE_SUCCESS =
  "FETCH_LOCATIONS_BY_BARCODE_SUCCESS";
export const FETCH_LOCATIONS_BY_BARCODE_FAILURE =
  "FETCH_LOCATIONS_BY_BARCODE_FAILURE";
export const CLEAR_LOCATIONS = "CLEAR_LOCATIONS";

export const PRINT_BARCODES_SKUS_BEGIN = "PRINT_BARCODES_SKUS_BEGIN";
export const PRINT_BARCODES_SKUS_SUCCESS = "PRINT_BARCODES_SKUS_SUCCESS";
export const PRINT_BARCODES_SKUS_FAILURE = "PRINT_BARCODES_SKUS_FAILURE";

export const SEARCH_SKU_SUGGESTION_BEGIN = "SEARCH_SKU_SUGGESTION_BEGIN";
export const SEARCH_SKU_SUGGESTION_SUCCESS = "SEARCH_SKU_SUGGESTION_SUCCESS";
export const SEARCH_SKU_SUGGESTION_FAILURE = "SEARCH_SKU_SUGGESTION_FAILURE";

export const FILTER_SKU_SUGGESTION_BEGIN = "FILTER_SKU_SUGGESTION_BEGIN";
export const FILTER_SKU_SUGGESTION_SUCCESS = "FILTER_SKU_SUGGESTION_SUCCESS";
export const FILTER_SKU_SUGGESTION_FAILURE = "FILTER_SKU_SUGGESTION_FAILURE";

export const CLEAR_SKU_TMPLS = "CLEAR_SKU_TMPLS";

export const ADD_SKU_STOCK_BEGIN = "ADD_SKU_STOCK_BEGIN";
export const ADD_SKU_STOCK_SUCCESS = "ADD_SKU_STOCK_SUCCESS";
export const ADD_SKU_STOCK_FAILURE = "ADD_SKU_STOCK_FAILURE";

export const STOCK_CHECK_BEGIN = "STOCK_CHECK_BEGIN";
export const STOCK_CHECK_SUCCESS = "STOCK_CHECK_SUCCESS";
export const STOCK_CHECK_FAILURE = "STOCK_CHECK_FAILURE";

export const CHANGE_SKU_TYPE_BEGIN = "CHANGE_SKU_TYPE_BEGIN";
export const CHANGE_SKU_TYPE_SUCCESS = "CHANGE_SKU_TYPE_SUCCESS";
export const CHANGE_SKU_TYPE_FAILURE = "CHANGE_SKU_TYPE_FAILURE";

// Get Sku for Table

export function fetchSku(params) {
  return (dispatch) => {
    dispatch(fetchSkuBegin());
    return adminApi
      .fetchSku(params)
      .then((json) => {
        dispatch(fetchSkuSuccess(json));
        return json;
      })
      .catch((error) => {
        //dispatch(fetchSkuFailure(error))
        return error;
      });
  };
}

export function fetchSkuFilters(params) {
  return (dispatch) => {
    dispatch(fetchSkuBegin());
    return adminApi
      .fetchSkuFilters(params)
      .then((json) => {
        dispatch(fetchSkuFiltersSuccess(json));
        return json;
      })
      .catch((error) => {
        //dispatch(fetchSkuFailure(error))
        return error;
      });
  };
}

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export const fetchSkuBegin = () => ({
  type: FETCH_SKU_BEGIN,
});

export const fetchSkuSuccess = (json) => ({
  type: FETCH_SKU_SUCCESS,
  payload: {
    products: json.data.products,
    pagination: json.data.pagination,
    filters: json.data.filters,
  },
});
export const fetchSkuFiltersSuccess = (json) => ({
  type: FETCH_SKU_FILTERS_SUCCESS,
  payload: {
    filters: json.data.filters,
  },
});

export const fetchSkuFailure = (error) => ({
  type: FETCH_SKU_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

// Filtering & Searching the Table

export const setSKUFilters = (params) => ({
  type: SET_SKU_FILTERS,
  data: params,
});

//Get Sku details

export function fetchItemById(id) {
  return (dispatch) => {
    dispatch(fetchItemByIDBegin());
    return adminApi
      .fetchItemById(id)
      .then((json) => {
        dispatch(fetchItemByIDSuccess(json.data));
        return json;
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchItemByIDFailure(error));
      });
  };
}

export function fetchItemHistory(id) {
  return (dispatch) => {
    dispatch(fetchItemHistoryBegin());
    return adminApi
      .fetchItemHistory(id)
      .then((json) => {
        dispatch(fetchItemHistorySuccess(json));
        return json;
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchItemHistoryFailure(error));
      });
  };
}

export const fetchItemHistoryBegin = () => ({
  type: FETCH_ITEM_HISTORY_BEGIN,
});

export const fetchItemHistorySuccess = (res) => ({
  type: FETCH_ITEM_HISTORY_SUCCESS,
  payload: res.data,
});

export const fetchItemHistoryFailure = (error) => ({
  type: FETCH_ITEM_HISTORY_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const fetchItemByIDBegin = () => ({
  type: FETCH_SKU_BY_ID_BEGIN,
});

export const fetchItemByIDSuccess = (item) => ({
  type: FETCH_SKU_BY_ID_SUCCESS,
  payload: { item },
});

export const fetchItemByIDFailure = (error) => ({
  type: FETCH_SKU_BY_ID_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

// Add new Sku

export const createItemBegin = () => ({
  type: CREATE_SKU_BEGIN,
});

export const createSkuSuccess = ({ Sku }) => ({
  type: CREATE_SKU_SUCCESS,
  payload: { Sku },
});

export const createItemFailure = (error) => ({
  type: CREATE_SKU_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function createItem(formData) {
  return (dispatch) => {
    dispatch(createItemBegin());
    return adminApi
      .createItem(formData)
      .then((json) => {
        dispatch(createSkuSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(createItemFailure(error));
      });
  };
}

export function updateItem(formData, id) {
  return (dispatch) => {
    dispatch(updateItemBegin());
    return adminApi
      .updateItem(formData, id)
      .then((json) => {
        dispatch(updateSkuSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(updateItemFailure(error));
      });
  };
}

export function updateItemLocation(data, id) {
  return (dispatch) => {
    dispatch(updateItemBegin());
    return adminApi
      .updateItemLocation(data, id)
      .then((json) => {
        dispatch(updateSkuSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(updateItemFailure(error));
      });
  };
}

export function updateItemImages(data, id) {
  return (dispatch) => {
    dispatch(updateItemBegin());
    return adminApi
      .updateItemImages(data, id)
      .then((json) => {
        dispatch(updateSkuSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(updateItemFailure(error));
      });
  };
}

export function clearLocations() {
  return (dispatch) => {
    dispatch(clearLocationsReducer());
  };
}

export function getlocations(term, warehouseId = 1) {
  return (dispatch) => {
    dispatch(getLocationBegin());
    return adminApi
      .getSkuLocations(term, warehouseId)
      .then((json) => {
        dispatch(getlocationsSuccess(json.results));
        return json;
      })
      .catch((error) => {
        dispatch(getlocationsFailure(error));
      });
  };
}

export const getLocationBegin = (data) => ({
  type: FETCH_LOCATIONS_BEGIN,
});

export const clearLocationsReducer = () => ({
  type: CLEAR_LOCATIONS,
});

export const getlocationsSuccess = (data) => ({
  type: FETCH_LOCATIONS_SUCCESS,
  payload: { data },
});

export const getlocationsFailure = (error) => ({
  type: FETCH_LOCATIONS_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function getLocationsByBarcode(term, warehouseId) {
  return (dispatch) => {
    dispatch(getLocationBegin());
    return adminApi
      .getLocationsByBarcode(term, warehouseId)
      .then((json) => {
        dispatch(getLocationsByBarcodeSuccess(json.results));
        return json;
      })
      .catch((error) => {
        dispatch(getLocationsByBarcodeFailure(error));
      });
  };
}

export const getLocationsByBarcodeSuccess = (data) => ({
  type: FETCH_LOCATIONS_BY_BARCODE_SUCCESS,
  payload: { data },
});

export const getLocationsByBarcodeFailure = (error) => ({
  type: FETCH_LOCATIONS_BY_BARCODE_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const updateItemBegin = () => ({
  type: UPDATE_SKU_BEGIN,
});

export const updateSkuSuccess = (item) => ({
  type: UPDATE_SKU_SUCCESS,
  data: item.data.item_details,
});

export const updateItemFailure = (error) => ({
  type: UPDATE_SKU_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function deleteSKU(id) {
  return (dispatch) => {
    dispatch(archivedSKUBegin());
    return adminApi
      .archivedSKU(id)
      .then((json) => {
        dispatch(archivedSKUSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(archivedSKUFailure(error));
      });
  };
}

export const archivedSKUBegin = () => ({
  type: DELETE_SKU_BEGIN,
});

export const archivedSKUSuccess = (json) => ({
  type: DELETE_SKU_SUCCESS,
  payload: json.data,
});

export const archivedSKUFailure = (error) => ({
  type: DELETE_SKU_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function unarchiveSKU(id) {
  return (dispatch) => {
    dispatch(unarchivedSKUBegin());
    return adminApi
      .unarchivedSKU(id)
      .then((json) => {
        dispatch(unarchivedSKUSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(unarchivedSKUFailure(error));
      });
  };
}

export const unarchivedSKUBegin = () => ({
  type: UNARCHIVE_SKU_BEGIN,
});

export const unarchivedSKUSuccess = (json) => ({
  type: UNARCHIVE_SKU_SUCCESS,
  payload: json.data,
});

export const unarchivedSKUFailure = (error) => ({
  type: UNARCHIVE_SKU_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

// MOVEMENT

export function fetchMovements(id, params) {
  return (dispatch) => {
    dispatch(fetchMovementBegin());
    return adminApi
      .fetchMovementByItemId(id, params)
      .then((json) => {
        dispatch(fetchMovementSuccess(json, params));
        return json;
      })
      .catch((error) => {
        dispatch(fetchMovementFailure(error));
      });
  };
}

export const fetchMovementBegin = () => ({
  type: FETCH_MOVEMENT_BEGIN,
});

export const fetchMovementSuccess = (json, params) => ({
  type: FETCH_MOVEMENT_SUCCESS,
  payload: {
    movements: json.data,
    pagination: json.data.pagination,
    movementsFilter: params,
  },
});

export const fetchMovementFailure = (error) => ({
  type: FETCH_MOVEMENT_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

// TYPES

export function fetchTypes(id) {
  return (dispatch) => {
    dispatch(fetchTypesBegin());
    return adminApi
      .fetchTypesByItemId(id)
      .then((json) => {
        dispatch(fetchTypesSuccess(json));

        return json;
      })
      .catch((error) => {
        dispatch(fetchTypesFailure(error));
      });
  };
}

export const fetchTypesBegin = () => ({
  type: FETCH_TYPES_BEGIN,
});

export const fetchTypesSuccess = (json) => ({
  type: FETCH_TYPES_SUCCESS,
  item: json.data.item,
  payload: json.data.item.child_products,
});

export const fetchTypesFailure = (error) => ({
  type: FETCH_TYPES_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

// ORDERS

export function fetchSkuOrders(id, params) {
  return (dispatch) => {
    dispatch(fetchSkuOrdersBegin());
    return adminApi
      .fetchSkuOrders(id, params)
      .then((json) => {
        dispatch(fetchSkuOrdersSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(fetchSkuOrdersFailure(error));
      });
  };
}

export const fetchSkuOrdersBegin = () => ({
  type: FETCH_SKU_ORDERS_BEGIN,
});

export const fetchSkuOrdersSuccess = (json) => ({
  type: FETCH_SKU_ORDERS_SUCCESS,
  item: json.data.item,
  payload: json.data.orders,
  pagination: json.data.pagination,
});

export const fetchSkuOrdersFailure = (error) => ({
  type: FETCH_SKU_ORDERS_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function deleteSKUType(id) {
  return (dispatch) => {
    dispatch(archivedSKUTypeBegin());
    return adminApi
      .archivedSKUType(id)
      .then((json) => {
        dispatch(archivedSKUTypeSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(archivedSKUTypeFailure(error));
      });
  };
}

export const archivedSKUTypeBegin = () => ({
  type: DELETE_SKU_TYPE_BEGIN,
});

export const archivedSKUTypeSuccess = (json) => ({
  type: DELETE_SKU_TYPE_SUCCESS,
  payload: json.data,
});

export const archivedSKUTypeFailure = (error) => ({
  type: DELETE_SKU_TYPE_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function breakDownSKUType(id, warehouseId) {
  return (dispatch) => {
    dispatch(breakDownSKUTypeBegin());
    return adminApi
      .breakDownSKUType(id, { warehouse_id: warehouseId })
      .then((json) => {
        dispatch(breakDownSKUTypeSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(breakDownSKUTypeFailure(error));
      });
  };
}

export const breakDownSKUTypeBegin = () => ({
  type: BREAKDOWN_SKU_TYPE_BEGIN,
});

export const breakDownSKUTypeSuccess = (json) => ({
  type: BREAKDOWN_SKU_TYPE_SUCCESS,
  payload: json.data,
});

export const breakDownSKUTypeFailure = (error) => ({
  type: BREAKDOWN_SKU_TYPE_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function getParentSku(id, data) {
  return (dispatch) => {
    dispatch(getParentSkuBegin());
    return adminApi
      .getParentSku(id, data)
      .then((json) => {
        dispatch(getParentSkuSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(getParentSkuFailure(error));
      });
  };
}

export const getParentSkuBegin = () => ({
  type: GET_PARENT_SKU_BEGIN,
});

export const getParentSkuSuccess = (json) => ({
  type: GET_PARENT_SKU_SUCCESS,
  payload: json.data,
});

export const getParentSkuFailure = (error) => ({
  type: GET_PARENT_SKU_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function createSkuType(id, data) {
  return (dispatch) => {
    dispatch(createSkuTypeBegin());
    return adminApi
      .createSkuType(id, data)
      .then((json) => {
        dispatch(createSkuTypeSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(createSkuTypeFailure(error));
      });
  };
}

export const createSkuTypeBegin = () => ({
  type: CREATE_SKU_TYPE_BEGIN,
});

export const createSkuTypeSuccess = (json) => ({
  type: CREATE_SKU_TYPE_SUCCESS,
  payload: json.data,
});

export const createSkuTypeFailure = (error) => ({
  type: CREATE_SKU_TYPE_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function updateSkuType(id, data) {
  return (dispatch) => {
    dispatch(updateSkuTypeBegin());
    return adminApi
      .updateSkuType(id, data)
      .then((json) => {
        dispatch(updateSkuTypeSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(updateSkuTypeFailure(error));
      });
  };
}

export const updateSkuTypeBegin = () => ({
  type: UPDATE_SKU_TYPE_BEGIN,
});

export const updateSkuTypeSuccess = (json) => ({
  type: UPDATE_SKU_TYPE_SUCCESS,
  payload: json.data,
});

export const updateSkuTypeFailure = (error) => ({
  type: UPDATE_SKU_TYPE_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const clearSKUs = () => ({
  type: CLEAR_SKUS,
});

export const setMultipleSkuError = (error) => ({
  type: SET_MULTIPLE_SKU_ERROR,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function printBarcodes(data) {
  return (dispatch) => {
    dispatch(printBarcodesBegin());
    let res = adminApi
      .printSkuBarcodes(data)
      .then((json) => {
        dispatch(printBarcodesSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(printBarcodesFailure(error));
      });
    return res;
  };
}

export const printBarcodesBegin = () => ({
  type: PRINT_BARCODES_SKUS_BEGIN,
});
export const printBarcodesSuccess = (json) => ({
  type: PRINT_BARCODES_SKUS_SUCCESS,
  payload: json,
});

export const printBarcodesFailure = (error) => ({
  type: PRINT_BARCODES_SKUS_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
  msg: "An error occurred, please try again later",
});

export function searchSkuSuggestion(term, orgId) {
  return (dispatch) => {
    dispatch(searchSkuSuggestionBegin());
    let res = adminApi
      .searchSkuSuggestionByOrg(term, orgId)
      .then((json) => {
        dispatch(searchSkuSuggestionSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(searchSkuSuggestionFailure(error));
      });
    return res;
  };
}

export const searchSkuSuggestionBegin = () => ({
  type: SEARCH_SKU_SUGGESTION_BEGIN,
});
export const searchSkuSuggestionSuccess = (json) => ({
  type: SEARCH_SKU_SUGGESTION_SUCCESS,
  payload: json,
});

export const searchSkuSuggestionFailure = (error) => ({
  type: SEARCH_SKU_SUGGESTION_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function filterSkuSuggestion(term) {
  return (dispatch) => {
    dispatch(filterSkuSuggestionBegin());
    let res = adminApi
      .searchSkuSuggestion(term)
      .then((json) => {
        dispatch(filterSkuSuggestionSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(filterSkuSuggestionFailure(error));
      });
    return res;
  };
}

export const filterSkuSuggestionBegin = () => ({
  type: FILTER_SKU_SUGGESTION_BEGIN,
});
export const filterSkuSuggestionSuccess = (json) => ({
  type: FILTER_SKU_SUGGESTION_SUCCESS,
  payload: json,
});

export const filterSkuSuggestionFailure = (error) => ({
  type: FILTER_SKU_SUGGESTION_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const clearSKUTmpls = () => ({
  type: CLEAR_SKU_TMPLS,
});

export function deleteItemLocation(data, id) {
  return (dispatch) => {
    dispatch(updateItemBegin());
    return adminApi
      .deleteItemLocation(data, id)
      .then((json) => {
        dispatch(updateSkuSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(updateItemFailure(error));
      });
  };
}

export function addItemLocation(data, id) {
  return (dispatch) => {
    dispatch(updateItemBegin());
    return adminApi
      .addItemLocation(data, id)
      .then((json) => {
        dispatch(updateSkuSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(updateItemFailure(error));
      });
  };
}

export function addSkuStock(id, data) {
  return (dispatch) => {
    dispatch(addSkuStockBegin());
    let res = adminApi
      .addSkuStock(id, data)
      .then((json) => {
        dispatch(addSkuStockSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(addSkuStockFailure(error));
      });
    return res;
  };
}

export function stockCheck(id) {
  return (dispatch) => {
    dispatch(stockCheckBegin());
    let res = adminApi
      .stockCheck(id)
      .then((json) => {
        dispatch(stockCheckSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(stockCheckFailure(error));
      });
    return res;
  };
}

export function addSkuStockBegin() {
  return {
    type: ADD_SKU_STOCK_BEGIN,
  };
}

export function addSkuStockSuccess(json) {
  return {
    type: ADD_SKU_STOCK_SUCCESS,
    payload: {
      movements: json.data,
      pagination: json.data.pagination,
    },
  };
}

export function addSkuStockFailure(error) {
  return {
    type: ADD_SKU_STOCK_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function stockCheckBegin() {
  return {
    type: STOCK_CHECK_BEGIN,
  };
}

export function stockCheckSuccess(json) {
  return {
    type: STOCK_CHECK_SUCCESS,
    payload: json,
  };
}

export function stockCheckFailure(error) {
  return {
    type: STOCK_CHECK_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    }
  };
}

export const changeSkuTypeBegin = () => ({
  type: CHANGE_SKU_TYPE_BEGIN,
});
export const changeSkuTypeSuccess = (json) => ({
  type: CHANGE_SKU_TYPE_SUCCESS,
  payload: json,
});

export const changeSkuTypeFailure = (error) => ({
  type: CHANGE_SKU_TYPE_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function changeSKUType(id, params) {
  return (dispatch) => {
    dispatch(changeSkuTypeBegin());
    return adminApi
      .changeSkuType(id, params)
      .then((json) => {
        dispatch(changeSkuTypeSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(changeSkuTypeFailure(error))
        return error;
      });
  };
}
