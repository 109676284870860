import checkPermission from "./checkPermission";

const Can = props => 
  checkPermission(props.role, props.perform, props.data)
    ? props.yes()
    : (props.no() || null);


Can.defaultProps = {
  yes: () => null,
  no: () => null
};

export default Can;
