import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';

class Hint extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                {this.props.children}
            </div>
        )
    }
}

const styles = theme => ({
    root: {
        backgroundColor: '#f1f3f4',
        minHeight: 120,
        width: 300,
        padding: 24,
    },
})

export default withStyles(styles)(Hint);