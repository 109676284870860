// React
import React, { Component } from 'react'

// Redux
import { connect } from 'react-redux'
import { Redirect } from "react-router-dom"
// Local components
import LoginForm from '../components/LoginForm';
import { loginWithMail } from "../../../actions/admin/authenticationActions";
import Loader from 'layouts/AdminLayout/components/Loader';
//import { getStorageData, setStorageData } from "../../../services/admin/adminAuth";

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            errors: [],
            sending: false,
        };
        this.submit = this.submit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        // Add an event listener to handle the storage event
        window.addEventListener('storage', this.handleStorage);
    }

    componentWillUnmount(){
        // Cleanup: remove the event listener
        window.removeEventListener('storage', this.handleStorage);
    }

    handleStorage = (event) => {
        if (event.key === '_ADMIN') {
            window.location.reload();
        }
    };

    submit = async (event) => {
        event.preventDefault();
        const { email, password } = this.state;
        const { dispatch } = this.props;

        this.setState({ sending: true })

        await dispatch(loginWithMail(email, password));

    }

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    render() {
        return (
            <>
                {
                    !!this.props.adminAuth.isLoading ? (<Loader />) :


                        (this.props.adminAuth.currentAdmin.uid ?
                            <Redirect to="/admin/manage/orders/" /> :
                            <LoginForm
                                email={this.state.email}
                                password={this.state.password}
                                handleChange={this.handleChange}
                                submit={this.submit}
                                sending={this.props.adminAuth.isLoading}
                                adminAuth={this.props.adminAuth}
                            />)
                }
            </>
        )
    }

}

// mapping for states.
const mapStateToProps = state => ({
    adminAuth: state.adminAuthReducer
});


export default connect(mapStateToProps)(Login);
