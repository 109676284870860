import {
    FETCH_DUTIES_BEGIN,
    FETCH_DUTIES_SUCCESS,
    FETCH_DUTIES_FAILURE,
    DELETE_DUTIES_SUCCESS,
    DELETE_DUTIES_FAILURE,
    FETCH_DUTIES_BY_ID_SUCCESS,
    FETCH_DUTIES_BY_ID_FAILURE,
    UPDATE_DUTIES_BEGIN,
    UPDATE_DUTIES_FAILURE,
    UPDATE_DUTIES_SUCCESS,
    CREATE_DUTIES_BEGIN,
    CREATE_DUTIES_FAILURE,
    CREATE_DUTIES_SUCCESS,
    FETCH_DUTIES_MOVEMENT_SUCCESS,
    FETCH_DUTIES_MOVEMENT_FAILURE,
    CLEAR_DUTIES,
    SET_DUTIES_FILTER,
    CLEAR_DUTIES_FILTER,
    GET_BOOKING_ID_BEGIN,
    GET_BOOKING_ID_SUCCESS,
    GET_BOOKING_ID_FAILURE, FETCH_DUTIES_FILTERS_SUCCESS,
    CLEAR_DUTIES_NOTIFICATION
} from 'actions/admin/dutiesActions';


const defaultDuties = {
    // "id": null,
    "line_items": [],
    "attachment": null,
    "total_amount": 0
};
// specify initial state
const initialState = {
    duties: [],
    loading: false,
    error: null,
    notification: null,
    dummyData: [...Array(10)],
    fetchedDuties: {...defaultDuties},
    formLoading: false,
    formSuccess: false,
    filter: {},
    activeFilter: {
        page: 1,
        per_page: 25,
        id: null,
        type_of_charge: null,
        total_amount: null,
        amount_greater_than: null,
        organisation_ids: [],
        booking_id:null,
        reference:null
    },
    pagination: {
        "current_page": 1,
        "total_count": 1,
        "per_page": 1
    },
    movements: {},
    suppliers: [],
    bookings: []
}


export default function dutiesReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_DUTIES_BEGIN:
            return {
                ...state,//imp line
                loading: true,
                error: null,
                notification: null,
                formSuccess: false,
                fetchedDuties: {...defaultDuties},
                pagination: {
                    "current_page": 1,
                    "total_count": 1,
                    "per_page": 1
                },
                duties: []
            };

        case FETCH_DUTIES_SUCCESS:
            return {
                ...state,
                loading: false,
                duties: action.data,
                filter: action.filter,
                pagination: action.pagination,
                notification: null
            };
        case FETCH_DUTIES_FILTERS_SUCCESS:
            return {
                ...state,
                loading: false,
                filter: action.filter,
                notification: null
            };

        case FETCH_DUTIES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                duties: []
            };
        case DELETE_DUTIES_SUCCESS:
            return {
                ...state,
                error: null,
                duties: state.duties.filter(duties => duties.id !== action.data.id),
                fetchedDuties: action.data,
                notification: action.msg,
                formSuccess: true
            };
        case DELETE_DUTIES_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                duties: state.duties,
                notification: action.msg
            };

        case FETCH_DUTIES_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                fetchedDuties: action.payload
            };

        case FETCH_DUTIES_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                notification: action.msg
            };
        case UPDATE_DUTIES_BEGIN: {
            return {
                ...state,
                notification: null,
                error:null,
                formLoading: true,
                formSuccess: false
            }
        }
        case UPDATE_DUTIES_SUCCESS: {
            let updatedDutiess = state.duties.map(item => item.id === action.payload.id ?
                action.payload : item
            )
            return {
                ...state,
                formLoading: false,
                formSuccess: true,
                errors: null,
                updateDutiesErrors: null,
                fetchedDuties: action.payload,
                dutiess: updatedDutiess
            }
        }
        case UPDATE_DUTIES_FAILURE: {

            return {
                ...state,
                formLoading: false,
                formSuccess: false,
                error: action.payload.error,
                // updateDutiesErrors: update_duties_failure_errors
            }
        }
        case CLEAR_DUTIES:
            return {
                ...state,
                ...initialState
            }
            
        case CLEAR_DUTIES_NOTIFICATION:
            return {
                ...state,
                error:null,
                notification:null
            }
        case SET_DUTIES_FILTER:
            return {
                ...state,
                activeFilter: {...state.activeFilter, ...action.payload.filters}
            }
        case CLEAR_DUTIES_FILTER:
            return {
                ...state,
                activeFilter: initialState.activeFilter
            }
        case CREATE_DUTIES_BEGIN:
            return {
                ...state,
                notification: null,
                error: null,
                formLoading: true,
                formSuccess: false
            }

        case CREATE_DUTIES_SUCCESS: {

            return {
                ...state,
                errors: null,
                formSuccess: true,
                formLoading: false,
                duties: [action.payload.duties, ...state.duties],
                fetchedDuties: action.payload.duties
            }
        }
        case CREATE_DUTIES_FAILURE:

            return {
                ...state,
                formLoading: false,
                formSuccess: false,
                error: action.payload.error
            }
        case GET_BOOKING_ID_BEGIN:
            return {
                ...state,
                notification: null,
                error: null,
                loading: true,
                bookings: []
            }
        case GET_BOOKING_ID_SUCCESS:
            return {
                ...state,
                errors: null,
                loading: false,
                bookings: action.payload
            }

        case GET_BOOKING_ID_FAILURE:
            return {
                ...state,
                loading: false,
                formSuccess: false,
                error: action.payload.error,

            }
        default:
            // Always have a default case in case a reducer doesn't complete
            return state;
    }
};
