import adminApi from 'services/admin/adminApi';
/*----------------Organisation Integrations--------------------------*/
export const FETCH_INTEGRATIONS_BEGIN = 'FETCH_INTEGRATIONS_BEGIN';
export const FETCH_INTEGRATIONS_SUCCESS = 'FETCH_INTEGRATIONS_SUCCESS';
export const FETCH_INTEGRATIONS_FAILURE = 'FETCH_INTEGRATIONS_FAILURE'

export const DELETE_INTEGRATION = 'DELETE_INTEGRATION'
export const DELETE_INTEGRATION_SUCCESS = 'DELETE_INTEGRATION_SUCCESS'
export const DELETE_INTEGRATION_FAILURE = 'DELETE_INTEGRATION_FAILURE'

export const GET_INTEGRATION_BY_ID_BEGIN = 'GET_INTEGRATION_BY_ID_BEGIN';
export const GET_INTEGRATION_BY_ID_SUCCESS = 'GET_INTEGRATION_BY_ID_SUCCESS';
export const GET_INTEGRATION_BY_ID_FAILURE = 'GET_INTEGRATION_BY_ID_FAILURE';

export const CREATE_INTEGRATION_BEGIN = 'CREATE_INTEGRATION_BEGIN';
export const CREATE_INTEGRATION_SUCCESS = 'CREATE_INTEGRATION_SUCCESS';
export const CREATE_INTEGRATION_FAILURE = 'CREATE_INTEGRATION_FAILURE';

export const UPDATE_INTEGRATIONS_BEGIN = 'UPDATE_INTEGRATIONS_BEGIN';
export const UPDATE_INTEGRATION_SUCCESS = 'UPDATE_INTEGRATION_SUCCESS';
export const UPDATE_INTEGRATION_FAILURE = 'UPDATE_INTEGRATION_FAILURE';

export const GET_INTEGRATION_BY_NAME_BEGIN = 'GET_INTEGRATION_BY_NAME_BEGIN';
export const GET_INTEGRATION_BY_NAME_SUCCESS = 'GET_INTEGRATION_BY_NAME_SUCCESS';
export const GET_INTEGRATION_BY_NAME_FAILURE = 'GET_INTEGRATION_BY_NAME_FAILURE';

export const CLEAR_INTEGRATIONS = 'CLEAR_INTEGRATIONS';
export const CLEAR_INTEGRATIONS_NOTIFICATIONS = 'CLEAR_INTEGRATIONS_NOTIFICATIONS';



/*--------------------Integrations----------------------------*/
export function fetchIntegrations(params) {
    return dispatch => {
        dispatch(fetchIntegrationsBegin());//calling this action to show loading
        return adminApi.fetchIntegrations(params)// calling list of materials
            .then(json => {
                dispatch(fetchIntegrationsSuccess(json));
                return json;
            })
            .catch(error => {

                dispatch(fetchIntegrationsFailure(error))

            });
    };
}

export function deleteIntegrations(orgid, id) {
    return dispatch => {
        return adminApi.deleteIntegrations(orgid, id)
            .then(json => {
                dispatch(deleteIntegrationsSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(deleteIntegrationsFailure(error))

            });
    }
}


export function fetchIntegrationsByID(orgId, id) {
    return dispatch => {
        dispatch(fetchIntegrationsBegin());//calling this action to show loading
        return adminApi.getIntegrationDetail(orgId, id)
            .then(handleErrors)
            .then(json => {
                dispatch(fetchIntegrationByIDSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(fetchIntegrationByIDFailure(error))
            });
    }
}

export function updateIntegrations(data, id) {
    return dispatch => {
        dispatch(updateIntegrationsBegin());
        let res = adminApi.updateIntegration(data, id)
            .then(json => {
                dispatch(updateIntegrationsSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(updateIntegrationsFailure(error));

            })
        return res;
    }
}

export const fetchIntegrationsBegin = () => (
    // the whole object is n action
    {
        type: FETCH_INTEGRATIONS_BEGIN
    });

export const fetchIntegrationsSuccess = json => ({
    type: FETCH_INTEGRATIONS_SUCCESS,
    data: json.data
});
export const fetchIntegrationsFailure = error => ({
    type: FETCH_INTEGRATIONS_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});


export const deleteIntegrationsSuccess = (json) => ({
    type: DELETE_INTEGRATION_SUCCESS,
    data: json.data.app_integrations,
    msg: json.message
})

export const deleteIntegrationsFailure = (error) => ({
    type: DELETE_INTEGRATION_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'},
    msg: "An error occurred, please try again later"
});

export const fetchIntegrationByIDBegin = () => ({
    type: GET_INTEGRATION_BY_ID_BEGIN
});
export const fetchIntegrationByIDSuccess = json => ({
    type: GET_INTEGRATION_BY_ID_SUCCESS,
    payload: {
        data: json.data.app_integrations,
        apps: json.data.edit_options.app_options
    }
});

export const fetchIntegrationByIDFailure = error => ({
    type: GET_INTEGRATION_BY_ID_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'},
    msg: "An error occurred, please try again later"
});


export const fetchIntegrationByNameBegin = () => ({
    type: GET_INTEGRATION_BY_NAME_BEGIN
});
export const fetchIntegrationByNameSuccess = json => ({
    type: GET_INTEGRATION_BY_NAME_SUCCESS,
    data: json.results
});

export const fetchIntegrationByNameFailure = error => ({
    type: GET_INTEGRATION_BY_NAME_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'},
    msg: "An error occurred, please try again later"
});


export function updateIntegrationsBegin() {
    return {
        type: UPDATE_INTEGRATIONS_BEGIN
    }
}

export function updateIntegrationsSuccess(json) {
    return {
        type: UPDATE_INTEGRATION_SUCCESS,
        data: json.data.app_integrations

    }
}

export function updateIntegrationsFailure(error) {
    return {
        type: UPDATE_INTEGRATION_FAILURE,
        payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
    }
}

export function createIntegration(id, data) {
    return dispatch => {
        dispatch(createIntegrationBegin());
        let res = adminApi.createIntegration(id, data)
            .then(json => {
                dispatch(createIntegrationSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(createIntegrationFailure(error));
            })
        return res;
    }

}

export function createIntegrationBegin() {
    return {
        type: CREATE_INTEGRATION_BEGIN,
    }
}


export const createIntegrationSuccess = data => ({
    type: CREATE_INTEGRATION_SUCCESS,
    payload: {data}
});

export const createIntegrationFailure = error => ({
    type: CREATE_INTEGRATION_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});

export function clearIntegrations() {
    return {
        type: CLEAR_INTEGRATIONS
    }
}

export function clearIntegrationNotification(){
    return {
        type:CLEAR_INTEGRATIONS_NOTIFICATIONS
    }
}
// Private methods

function handleErrors(response) {
    if(!response.success) {
        throw Error(response.statusText);
    }
    return response;
}
