/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem
} from '@material-ui/core';
import Row from 'components/Table/KSTable/Row';
import Column from 'components/Table/KSTable/Column';
import Cell from 'components/Table/KSTable/Cell';

import keepspaceApi from 'services/keepspaceApi';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  addIcon: {
    marginRight: 8
  },
}));

const TopIntegrations = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  // const [integrations, setIntegrations] = useState([]);

  const [activeShopfronts, setActiveShopfronts] = useState([])

  useEffect(() => {
    let unmounted = false
    keepspaceApi.getMostActiveShopfronts()
      .then(json => {
        if (json.shopfronts && !unmounted) setActiveShopfronts(json.shopfronts.slice(0, 6));
      })
      .catch((error) => {
        console.error(error);
    })

    return () => {
      unmounted = true
    };
  }, []);


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        // action={
        //   <Button
        //     color="primary"
        //     size="small"
        //   >
        //     <AddIcon className={classes.addIcon} />
        //     Add
        //   </Button>={i < integrations.length - 1}
        // }
        title="Top Integrations"
      />
      <Divider />
      <CardContent className={classes.content}>
        <List>
          {activeShopfronts.map((integration, i) => (
            <ListItem
              divider={i < activeShopfronts.length - 1}
              key={integration.shopfront}
            >
              <Row>
                <Column flexBasis='33%'><Cell title='Platform'>{integration.shopfront}</Cell></Column>
                <Column flexBasis='33%'><Cell title='Orders'>{integration.size || '-'}</Cell></Column>
                <Column flexBasis='33%'><Cell title='Sales'>{integration.sales || '-'}</Cell></Column>
              </Row>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

TopIntegrations.propTypes = {
  className: PropTypes.string
};

export default TopIntegrations;
