import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';

import ConversationMessage from '../ConversationMessage';
import Loader from 'layouts/AdminLayout/components/Loader';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    overflow: 'scroll',
    maxHeight: '100%'
  },
  inner: {
    padding: theme.spacing(2),
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height:'70vh'
  }
}));

const ConversationMessages = props => {
  const { public_comments, className, currentUserAvatar, ...rest } = props;

  const classes = useStyles();

  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
  }

  useEffect(scrollToBottom, [public_comments]);

  return (
    <div
      { ...rest }
      className={ clsx(classes.root, className) }
    >
      <PerfectScrollbar>
        { public_comments ?
          <div className={ classes.inner }>
            { public_comments.map(public_comment => {
              return (
                <ConversationMessage
                  key={ public_comment.id }
                  public_comment={ public_comment }
                  currentUserAvatar={ currentUserAvatar }
                />
              );
            }) }
          </div>
          :
          <div className={ classes.loader }>
            <CircularProgress className={ classes.img } size={ 100 } variant={ 'indeterminate' } />
          </div>
        }
        <div ref={ messagesEndRef } />
      </PerfectScrollbar>
    </div>
  );
};

ConversationMessages.propTypes = {
  className: PropTypes.string,
  // public_comments: PropTypes.array.isRequired
};

export default ConversationMessages;
