import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ValidatorForm } from 'react-material-ui-form-validator';
import SuccessSnackbar from 'components/Help/SuccessSnackbar';
import TextFieldValidator from 'components/Form/TextFieldValidator';
import {
  clearWarehouse,
  updateWarehouse,
  createWarehouse,
  getDataToAddWarehouse,
  fetchWarehouseByID,
  clearNotification,
  deleteWarehouse,
  fetchWarehouse,
} from 'actions/admin/warehouseActions';
import { fetchAdminUsers } from 'actions/admin/UserActions';
import { Link, useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Header from 'components/Header/Header';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm', 'md')]: {
      padding: 0,
      paddingTop: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  grid: {
    padding: 54,
  },
  card: {
    boxShadow: '6px 6px 6px #6C6C6C29',
    borderRadius: '15px',
    opacity: 1,
  },
  cardContent: {
    margin: '10px 20px',
  },
  cancelButton: {
    color: '#696969',
    //minWidth: "150px",
    fontWeight: '600',
    margin: '0px 55px 0px 0px',
  },
  dialog: {
    // border: '1px solid #707070',
    borderRadius: '10px',
    overflow: 'hidden',
  },
  deleteButton: {
    color: '#FF6060 !important',
  },
  saveButton: {
    minWidth: '100px',
    color: '#40B5AD',
    fontWeight: 600,
    border: 2,
    boxShadow:
      '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
    //boxShadow: ' 6px 6px 6px #DEE5E7',
    borderRadius: '6px',
  },
  buttonsContainer: {
    '& > *': {
      margin: theme.spacing(1),
    },
    margin: '5px 49px',
    float: 'right',
  },
  dialogTitle: {
    marginTop: '25px',
    fontWeight: 600,
  },
  dialogAction: {
    marginTop: '45px',
  },
  font: {
    fontWeight: 600,
  },
  header: {
    backgroundColor: '#ffffff',
    boxShadow: '0px 3px 6px #00000029',
    padding: '20px 40px 15px',
    border: '1px solid #eeeeee',
    fontWeight: '600',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    fontSize: '0.9rem',
  },
  actions: {
    justifyContent: 'space-between',
    margin: '0 auto',
  },
  rows: {
    marginTop: 20,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const WarehouseForm = (props) => {
  const {
    error,
    history,
    formLoading,
    warehouse,
    formSuccess,
    notification,
    fetchWarehouseByID,
    updateWarehouse,
    createWarehouse,
    countries,
    clearNotification,
    getDataToAddWarehouse,
    adminUsers,
    deleteWarehouse,
  } = props;
  const { id } = useParams();
  const defaultValues = {
    name: '',
    country: 'AU',
    city: '',
    storage_space: '',
    floor_space: '',
    capacity: null,
    postal_code: '',
    address_1: '',
    state: '',
    price_per_cubic_meter: '',
    suburb: '',
  };

  let inputRef = React.createRef('form');
  const [values, setValues] = useState(defaultValues);
  const classes = useStyles();

  useEffect(() => {
    if (id) {
      fetchWarehouseByID(id);
    }
  }, [id]);

  useEffect(() => {
    props.fetchAdminUsers();
  }, []);

  useEffect(() => {
    const breadcrumbs = id && [
      {
        label: `Warehouse - ${warehouse.name}`,
        path: `/admin/warehouses/${id}`,
      },
    ];
    handleGenerateBreadcrumbs(breadcrumbs);
  }, [id, warehouse]);

  const handleChange = (event, val, name) => {
    const newValues = {
      ...values,
      [name || event.target.name]: val || event.target.value,
    };
    setValues(newValues);
  };

  const submitForm = () => {
    if (id) {
      const newValues = { ...values, ['id']: id };
      setValues(newValues);
      const dataToSubmit = { warehouse: values };
      updateWarehouse(id, dataToSubmit);
    } else {
      let dataToSubmit = { warehouse: { ...values } };
      createWarehouse(dataToSubmit);
    }
  };
  useEffect(() => {
    if (id) {
      fetchWarehouseByID(id);
    }
    getDataToAddWarehouse();
  }, []);

  useEffect(() => {
    if (formSuccess) {
      history.push('/admin/warehouses/summary');
    }
  }, [formSuccess]);

  useEffect(() => {
    if (id && warehouse.name) {
      setValues(warehouse);
    }
  }, [warehouse]);

  const formFields = [
    { value: 'name', label: 'Name', required: ['required'], fullWidth: true },
    { value: 'address_1', label: 'Address Line 1', required: ['required'] },
    { value: 'address_2', label: 'Address Line 2' },
    { value: 'suburb', label: 'Suburb', required: ['required'] },
    // { value: "city", label: "City", required: ["required"] },
    { value: 'postal_code', label: 'Postcode', required: ['required'] },
    { value: 'state', label: 'State', required: ['required'] },
    {
      value: 'country_iso2',
      label: 'Country',
      required: ['required'],
      options: countries,
      select: true,
      optionValue: 'code',
      optionText: 'text',
    },
    {
      value: 'floor_space',
      label: 'Floor Space (m2)',
      helperText: 'Enter the floor space',
      required: ['required', 'isFloat'],
      errorMessages: ['This field is required', 'Please enter a valid number'],
    },

    {
      value: 'storage_space',
      label: 'Volume Area (m3)',
      helperText: 'Enter the volume area',
      required: ['required', 'isFloat'],
      errorMessages: ['This field is required', 'Please enter a valid number'],
    },
    {
      value: 'capacity',
      label: 'Warehouse Capacity',
      helperText: 'Orders archived per day',
      required: ['required', 'isFloat'],
      errorMessages: ['This field is required', 'Please enter a valid value'],
    },
    {
      value: 'price_per_cubic_meter',
      label: '$ per m3',
      helperText: 'The price per cubic meter',
      required: ['required', 'isFloat'],
      errorMessages: ['This field is required', 'Please enter a valid price'],
    },
    {
      value: 'manager_id',
      label: 'Manager',
      required: ['required'],
      options: adminUsers,
      select: true,
      optionValue: 'id',
      optionText: 'full_name',
    },
  ];

  const location = useLocation();
  const urlParams = useParams();

  const [openDelete, setOpenDelete] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      label: 'Hello',
      path: location.pathname,
    },
  ]);
  const handleDeleteModal = () => {
    setOpenDelete(!openDelete);
  };

  const handleGenerateBreadcrumbs = (values) => {
    setBreadcrumbs(values);
  };

  const generateTitle = location.pathname.includes('add')
    ? 'Add Warehouse'
    : 'Edit Warehouse';

  return (
    <div className={classes.root}>
      <div>
        <div>
          <Breadcrumbs label="Warehouses" breadcrumbs={breadcrumbs}/>
        </div>
        <Header subtitle={generateTitle}/>
      </div>

      <Grid className="">
        <SuccessSnackbar
          open={error || notification ? true : false}
          error={error ? error.error_message || error.error || error : null}
          messageText={notification}
          onClose={() => clearNotification()}
        />

        <Card className={classes.card}>
          <div className={classes.header}>
            <span>{id ? 'Edit' : 'Add a new Warehouse'}</span>
          </div>
          <ValidatorForm
            ref={inputRef}
            onError={(errors) => console.log(errors)}
            onSubmit={() => submitForm()}
          >
            <CardContent>
              <Grid container justify="flex-start">
                {formFields.map((column, i) => (
                  <Grid
                    item
                    sm={column.fullWidth ? 10 : 5}
                    xs={12}
                    alignContent="center"
                    className={classes.cardContent}
                  >
                    <TextFieldValidator
                      keyVal={`${column.value}_${i}`}
                      classNames={classes.rows}
                      required={
                        column.required && !column.errorMessages ? true : false
                      }
                      validators={column.required}
                      errorMessages={column.errorMessages}
                      id={column.value}
                      label={column.label}
                      value={values[column.value] || ''}
                      onChange={handleChange}
                      helperText={column.helperText}
                      errors={error}
                      options={column.options}
                      select={column.select}
                      optionValue={column.optionValue}
                      optionText={column.optionText}
                    />
                  </Grid>
                ))}
              </Grid>
            </CardContent>
            <CardActions>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                sm={11}
                xs={12}
                className={classes.cardContent}
              >
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                >
                  <Link
                    to={
                      id
                        ? `/admin/warehouses/${id}`
                        : '/admin/warehouses/summary'
                    }
                  >
                    <Button
                      className={classes.cancelButton}
                      color="secondary"
                      disabled={formLoading}
                    >
                      Cancel
                    </Button>
                  </Link>
                  <Button
                    variant="contained"
                    type="submit"
                    className={classes.saveButton}
                    disabled={formLoading}
                  >
                    Save
                    {formLoading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </ValidatorForm>
        </Card>

        <Dialog
          open={openDelete}
          disableBackdropClick={true}
          onClose={() => handleDeleteModal()}
          aria-labelledby="delete-charge-title"
          aria-describedby="delete-charge-text"
          // className={classes.dialog}
        >
          <DialogTitle id="delete-charge-title">
            <Typography className={classes.font}>Delete</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="delete-charge-text"
              className={classes.popupText}
            >
              <Typography variant={'body2'}>
                Are you sure that you want to delete this warehouse from your
                system?
              </Typography>
              <Typography variant={'body2'}>
                Once you delete this it will be gone forever.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              className={classes.buttonsContainer}
              spacing={2}
            >
              <Button
                className={classes.saveButton + ' ' + classes.deleteButton}
                variant="outlined"
                disabled={formLoading}
                onClick={() => deleteWarehouse(id)}
              >
                Delete{' '}
                {formLoading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
              <Button
                variant="text"
                onClick={() => handleDeleteModal()}
                className={classes.cancelButton}
              >
                Cancel
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  loading: state.warehouseReducer.loading,
  error: state.warehouseReducer.error,
  warehouses: state.warehouseReducer.warehouses,
  locations: state.warehouseReducer.locations,
  locationDetail: state.warehouseReducer.location,
  warehouse: state.warehouseReducer.warehouse,
  pagination: state.warehouseReducer.pagination,
  notification: state.warehouseReducer.notification,
  dutyDetails: state.warehouseReducer.fetchedWarehouse,
  formLoading: state.warehouseReducer.formLoading,
  formSuccess: state.warehouseReducer.formSuccess,
  filter: state.warehouseReducer.filter,
  dummyData: state.warehouseReducer.dummyData,
  barcode: state.warehouseReducer.barcode,
  filename: state.warehouseReducer.filename,
  activeFilter: state.warehouseReducer.activeFilter,
  countries: state.adminAuthReducer.countries,
  users: state.warehouseReducer.users,
  adminUsers: state.adminUsersReducer.users,
});

function mapDispatchToProps(dispatch) {
  return {
    fetchWarehouses: (params) => dispatch(fetchWarehouse(params)),
    clearWarehouse: () => dispatch(clearWarehouse()),
    createWarehouse: (data) => dispatch(createWarehouse(data)),
    updateWarehouse: (id, data) => dispatch(updateWarehouse(id, data)),
    fetchWarehouseByID: (id) => dispatch(fetchWarehouseByID(id)),
    getDataToAddWarehouse: () => dispatch(getDataToAddWarehouse()),
    clearNotification: () => dispatch(clearNotification()),
    deleteWarehouse: (id) => dispatch(deleteWarehouse(id)),
    fetchAdminUsers: () => dispatch(fetchAdminUsers()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseForm);
