import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';

import useRouter from 'utils/useRouter';
import { Page } from 'components';
import {
  ConversationList,
  ConversationDetails,
  ConversationPlaceholder
} from './components';
import { fetchPublicComments,  fetchPublicCommentById, updateConversationRead } from 'actions/publicCommentActions';

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    display: 'flex',
    overflow: 'hidden',
    maxHeight: 'calc(100vH - 65px)',
    '@media (max-width: 863px)': {
      '& $conversationList, & $conversationDetails': {
        flexBasis: '100%',
        width: '100%',
        flexShrink: '0',
        transform: 'translateX(0)',
        maxWidth: '100vW'
      }
    }
  },
  openConversion: {
    '@media (max-width: 863px)': {
      '& $conversationList, & $conversationDetails': {
        maxWidth: '100vW'
      }
    }
  },
  conversationList: {
    width: 500,
    flexBasis: 500,
    flexShrink: 0,
    overflowY: 'auto',
    '@media (min-width: 863px)': {
      borderRight: `1px solid ${theme.palette.divider}`,
      maxWidth: '60%',
    }
  },
  conversationDetails: {
    flexGrow: 1,
    height: 'calc(100vH - 65px)',
  },
  conversationPlaceholder: {
    flexGrow: 1
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto',
  },
  error: {
    height: '40px',
    backgroundColor: '#f44336',
    textAlign: 'center',
    padding: '5px'
  }
}));


const Chat = () => {
  const classes = useStyles();
  const router = useRouter();
  // let [conversations, setConversations] = useState([]);
  const dispatch = useDispatch();
  const selectedConversationId = router.match.params.id || null;

  const fetchComments = (params) => {
    dispatch(fetchPublicComments(params))
  }

  useEffect(() => {
    dispatch(fetchPublicComments())
  }, [dispatch]);

  useEffect(() => {
    if (selectedConversationId) {
      dispatch(fetchPublicCommentById(selectedConversationId));
      dispatch(updateConversationRead(selectedConversationId))
    }
  }, [dispatch, selectedConversationId]);
  let conversations = useSelector(state =>  state.publicCommentsReducer.conversations);
  let pagination = useSelector(state => state.publicCommentsReducer.pagination);
  let error = useSelector(state =>  state.publicCommentsReducer.error)
  let selectedConversation = useSelector(state =>  state.publicCommentsReducer.conversation)

  const handleClick = (conversation) => {
    // dispatch(fetchPublicCommentById(conversation.id));
  }

  const currentUser = useSelector(state => state.reduxTokenAuth.currentUser.attributes);

  let currentUserAvatar

  currentUserAvatar = currentUser.id ? (currentUser.avatar.url || require(`assets/img/avatars/00${currentUser.id%46+1}.svg`)) : null

  return (
      <main className={classes.content}>
        {error ? (<div className = {classes.error} >{error.message}</div>) : null}

        <Page
        className={clsx({
          [classes.root]: true,
          [classes.openConversion]: selectedConversation
        })}
          title="Chat"
        >
          <ConversationList
            className={classes.conversationList}
            conversations={conversations}
            fetchComments = {fetchComments}
            pagination = {pagination}
            fetchConversation={handleClick}
            selectedConversationId={selectedConversationId}
            currentUserAvatar={currentUserAvatar}
          />
          {selectedConversationId? (
            <ConversationDetails
              className={classes.conversationDetails}
              conversation={selectedConversation}
              currentUserAvatar={currentUserAvatar}
            />
          ) : (
            <ConversationPlaceholder className={classes.conversationPlaceholder} />
          )}
        </Page>
      </main>
  );
};

export default Chat;
