import {
    FETCH_ADMIN_INVOICES_BEGIN,
    FETCH_ADMIN_INVOICES_SUCCESS,
    FETCH_ADMIN_INVOICES_FAILURE,
    SET_INVOICES_FILTERS,
    CLEAR_INVOICESS,
    FETCH_ADMIN_INVOICES_BY_ID_BEGIN,
    FETCH_ADMIN_INVOICES_BY_ID_SUCCESS,
    FETCH_ADMIN_INVOICES_BY_ID_FAILURE,
    CANCEL_INVOICE_BEGIN,
    CANCEL_INVOICE_SUCCESS,
    CANCEL_INVOICE_FAILURE,
    UPDATE_QUICKBOOKURL_BEGIN,
    UPDATE_QUICKBOOKURL_SUCCESS,
    UPDATE_QUICKBOOKURL_FAILURE,
    ADD_NEW_PAYMENT_BEGIN,
    ADD_NEW_PAYMENT_SUCCESS,
    ADD_NEW_PAYMENT_FAILURE,
    CREATE_INVOICES_BEGIN,
    CREATE_INVOICES_SUCCESS,
    CREATE_INVOICES_FAILURE,
    CLEAR_INVOICES_NOTIFICATION,
    RECREATE_PDF_BEGIN,
    RECREATE_PDF_SUCCESS,
    RECREATE_PDF_FAILURE
} from 'actions/admin/invoicesActions';

import {
    FETCH_ORG_INVOICES_BEGIN,
    FETCH_ORG_INVOICES_SUCCESS,
    FETCH_ORG_INVOICES_FAILURE
} from 'actions/admin/OrganisationsActions';
import invoices from 'views/admin/Accounting/Invoices/invoices';


const initialState = {
    invoices: [],
    invoiceDetail: {},
    loading: false,
    formSuccess: false,
    uploading: false,
    error: null,
    notification: null,

    activeFilter: {
        organisation_ids: [],
        invoice_date_before: null,
        invoice_date_after: null,
        statuses: []
    },
    pagination: {},
    filter: {},
}

export default function adminInvoiceReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_ADMIN_INVOICES_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                notification: null
            };
        case FETCH_ORG_INVOICES_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                notification: null
            };
        case FETCH_ADMIN_INVOICES_SUCCESS:
            return {
                ...state,
                loading: false,
                invoices: action.payload.invoices,
                pagination: action.payload.pagination,
                filters: action.payload.filters
            };

        case FETCH_ORG_INVOICES_SUCCESS:
            return {
                ...state,
                loading: false,
                invoices: action.payload.invoices,
                pagination: action.payload.pagination,
                filters: action.payload.filters,
                organisation: action.payload.organisation
            };

        case FETCH_ADMIN_INVOICES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                invoices: []
            };
        case FETCH_ORG_INVOICES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                invoices: []
            };
        case SET_INVOICES_FILTERS:
            return {
                ...state,
                activeFilter: action.data
            };

        case CLEAR_INVOICESS:
            return {
                ...state,
                ...initialState
            };
        
        case CLEAR_INVOICES_NOTIFICATION:
            return {
                ...state,
                error:null,
                notification:null
            }
        case FETCH_ADMIN_INVOICES_BY_ID_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                notification: null
            };


        case FETCH_ADMIN_INVOICES_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                invoiceDetail: action.payload.item
            };

        case FETCH_ADMIN_INVOICES_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case CREATE_INVOICES_BEGIN:
            return {
                ...state,
                loading: true,
                formSuccess: false,
                error: null,
                notification: null
            };


        case CREATE_INVOICES_SUCCESS:
            return {
                ...state,
                loading: false,
                formSuccess: true,
                invoices: [...state.invoices, action.payload.item]
            };

        case CREATE_INVOICES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case CANCEL_INVOICE_BEGIN:
            return {
                ...state,
                formSuccess: true,
                error: null,
                notification: null
            };


        case CANCEL_INVOICE_SUCCESS:
            let invoiceList = state.invoices.filter(o => o.id != action.payload.item.id);
            return {
                ...state,
                formSuccess: false,
                invoiceDetail: {},
                invoices: [...invoiceList]
            };

        case CANCEL_INVOICE_FAILURE:
            return {
                ...state,
                formSuccess: false,
                error: action.payload.error
            };
        case UPDATE_QUICKBOOKURL_BEGIN:
            return {
                ...state,
                loading: true,
                formSuccess: false,
                error: null,
                notification: null
            }

        case UPDATE_QUICKBOOKURL_SUCCESS:

            return {
                ...state,
                loading: false,
                formSuccess: true,
                error: null,
                invoiceDetail: action.payload.item.invoice,
                notification: "Invoice is updated"
            }

        case UPDATE_QUICKBOOKURL_FAILURE:
            return {
                ...state,
                loading: false,
                formSuccess: false,
                error: action.payload.error,
            };


        case ADD_NEW_PAYMENT_BEGIN:
            return {
                ...state,
                loading: true,
                formSuccess: false,
                error: null,
                notification: null
            }

        case ADD_NEW_PAYMENT_SUCCESS:

            return {
                ...state,
                loading: false,
                formSuccess: true,
                error: null,
                invoiceDetail: action.payload.item.invoice,
                notification: "Payment added successfully"
            }

        case ADD_NEW_PAYMENT_FAILURE:
            return {
                ...state,
                loading: false,
                formSuccess: false,
                error: action.payload.error,
            };

        case RECREATE_PDF_BEGIN:
            return {
                ...state,
                loading: true,
                formSuccess: false,
                error: null,
                notification: null
            }

        case RECREATE_PDF_SUCCESS:

            return {
                ...state,
                loading: false,
                formSuccess: true,
                error: null,
                invoiceDetail: action.payload.item.invoice,
                notification: "PDF has been recreated!"
            }

        case RECREATE_PDF_FAILURE:
            return {
                ...state,
                loading: false,
                formSuccess: false,
                error: action.payload.error,
            };

        default:
            // Always have a default case in case a reducer doesn't complete
            return state;
    }
};
