import React, { useState }  from 'react'
import { Typography, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Dropzone from 'components/Upload/Dropzone';
import { TextValidator} from 'react-material-ui-form-validator';
import SuccessSnackbar from 'components/Help/SuccessSnackbar';

const styles = theme => ({
    imageUploadZone: {
      marginTop: '119px',
      textAlign: 'center'
    },
    imageRow: {
      marginTop: '30px'
    },
    textField: {
      marginTop: '20px'
    },
    header: {
      padding: '10px',
    },
    hide: {
      display: 'none'
    },
    imageFileList: {
      margin: 'auto'
    }
})

function P1SKU(props) {
    const { classes, categories } = props;
    
    const options = [{id: '', title: ''}].concat(categories.filter(function(x) { return x.id !== "-1"; }))
    const fields =
      [
        {value: 'title', label: 'Title', required: ['required', 'maxStringLength:200'], errorMessages: ['Title is required', 'Max characters: 200']},
        {value: 'sku', label: 'Sku', required: ['required', 'maxStringLength:50', 'minStringLength:3'], errorMessages: ['Sku is required', 'Max characters: 50','Min characters: 3']},
        {value: 'barcode', label: 'Barcode', required: ['required'], errorMessages : ['Barcode is required']},
        {value: 'category_id', label: 'Category', required: []},
        { value: 'hs_code', label: 'HS Code', required: ['required'], errorMessages : ['HS Code is required'] },
        { value: 'country_of_manufacture', label: 'Country of manufacture', required: ['required'],  errorMessages : ['Country of Manufacture is required'], type: 'native-select', options: props.countries },
      ]
      const [errImgName, setErrImgName] = useState(null);
      const handleSnackbarClose = () => {
        setErrImgName(null);
      };
    return (
        <>
            <Typography variant='h6' className={classes.header}>
                SKU Details
            </Typography>
            <Grid container spacing={3} >
                <Grid item xs={12} sm={6}>

                    {fields.map((column, i) =>
                        <TextValidator
                            key={`${column.value}_${i}`}
                            className={classes.textField}
                            validators={column.required}
                            errorMessages={column.errorMessages}
                            id={column.value}
                            name={column.value}
                            label={column.label}
                            fullWidth
                            value={props.parentState[column.value]}
                            onChange={props.onChange}
                            variant="outlined"
                            select={column.value === 'category_id' || column.value === 'country_of_manufacture'}
                            // InputLabelProps={{shrink: column.value === 'category'}}
                            SelectProps={{ native: true }}

                        >
                          { column.value === 'category_id' ? (options.map(option => (
                             <option
                               key={option.id}
                               value={option.id}
                             >
                               {option.title}
                             </option>
                           ))) : null }

                          {column.value === 'country_of_manufacture'? (column.options.map(option => (
                            <option key={option.value} value={option.text}>
                              {option.text}
                            </option>
                            
                          ))) : null}
                        </TextValidator>
                    )}


                </Grid>

                <Grid item xs={12} sm={6}>
                    <div className={classes.imageUploadZone}>
                            <Dropzone
                                disabled={false}
                                onFilesAdded={props.onImageUpload}
                                accept="image/png,image/jpg,image/jpeg" 
                                preview={props.parentState.preview}
                                className={classes.dropzone}
                                errImgName={setErrImgName}
                            />
                            {props.parentState.image.map(file => {
                                return (
                                    <div key={file.name} className={classes.imageRow}>
                                        <span className={classes.filename}>{file.name}</span>
                                    </div>
                                );
                            })}
                            <TextValidator
                              // className={classes.hide}
                              value={props.parentState.image}
                              validators={['required']}
                              errorMessages={['Image is required']}
                              type='hidden'
                            />
                             <SuccessSnackbar
                                open={Boolean(errImgName)}
                                error={`Select only PNG, JPG or JPEG file`}
                                onClose={handleSnackbarClose}
                              />  

                    </div>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextValidator
                        className={classes.textField}
                        id="description"
                        name='description'
                        label='Description'
                        fullWidth
                        multiline
                        rowsMax='4'
                        value={props.parentState.description}
                        onChange={props.onChange}
                        variant="outlined"
                        validators={['required', 'maxStringLength:500']}
                        errorMessages={['Description is required', 'Max characters: 500']}
                    />
                    <Typography variant='subtitle2'> * This description will be used on customs declarations for international shipments </Typography>
                </Grid>
            </Grid>

        </>
    )
}

export default withStyles(styles)(P1SKU);
