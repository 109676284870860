import adminApi from 'services/admin/adminApi';
/*----------------materials--------------------------*/
export const FETCH_ADMIN_USERS_BEGIN = 'FETCH_ADMIN_USERS_BEGIN';
export const FETCH_ADMIN_USERS_SUCCESS = 'FETCH_ADMIN_USERS_SUCCESS';
export const FETCH_ADMIN_USERS_FAILURE = 'FETCH_ADMIN_USERS_FAILURE'

export const DELETE_ADMIN_USER = 'DELETE_ADMIN_USER'
export const DELETE_ADMIN_USER_SUCCESS = 'DELETE_ADMIN_USER_SUCCESS'
export const DELETE_ADMIN_USER_FAILURE = 'DELETE_ADMIN_USER_FAILURE'

export const GET_ADMIN_USER_BY_ID_BEGIN = 'GET_ADMIN_USER_BY_ID_BEGIN';

export const GET_ADMIN_USER_BY_ID_SUCCESS = 'GET_ADMIN_USER_BY_ID_SUCCESS';
export const GET_ADMIN_USER_BY_ID_FAILURE = 'GET_ADMIN_USER_BY_ID_FAILURE';

export const CREATE_ADMIN_USER_BEGIN = 'CREATE_ADMIN_USER_BEGIN';
export const CREATE_ADMIN_USER_SUCCESS = 'CREATE_ADMIN_USER_SUCCESS';
export const CREATE_ADMIN_USER_FAILURE = 'CREATE_ADMIN_USER_FAILURE';

export const UPDATE_ADMIN_USERS_BEGIN = 'UPDATE_ADMIN_USERS_BEGIN';
export const UPDATE_ADMIN_USER_SUCCESS = 'UPDATE_ADMIN_USER_SUCCESS';
export const UPDATE_ADMIN_USER_FAILURE = 'UPDATE_ADMIN_USER_FAILURE';

export const CLEAR_ADMIN_USERS = 'CLEAR_ADMIN_USERS';

export const SET_ADMIN_USERS_FILTER = 'SET_ADMIN_USERS_FILTER';
export const CLEAR_ADMIN_USERS_FILTER = 'CLEAR_ADMIN_USERS_FILTER';
export const CLEAR_USERS_NOTIFICATION = 'CLEAR_USERS_NOTIFICATION';


/*--------------------USERs----------------------------*/
export function fetchAdminUsers(params) {
    return dispatch => {
        dispatch(fetchAdminUsersBegin());//calling this action to show loading
        return adminApi.fetchAdminUsers(params)// calling list of materials
            .then(json => {

                dispatch(fetchAdminUsersSuccess(json));
                return json;
            })
            .catch(error => {
                console.log('error', error);

                dispatch(fetchAdminUsersFailure(error))

            });
    };
}

export function deleteAdminUsers(id) {
    return dispatch => {
        return adminApi.deleteAdminUsers(id)
            .then(json => {
                dispatch(deleteAdminUsersSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(deleteAdminUsersFailure(error))

            });
    }
}


export function fetchAdminUsersById(id) {
    return dispatch => {
        dispatch(fetchAdminUsersBegin());//calling this action to show loading
        return adminApi.getAdminUserDetail(id)
            .then(handleErrors)
            .then(json => {
                dispatch(fetchAdminUserByIdSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(fetchAdminUserByIdFailure(error))
            });
    }
}

// export function fetchAdminUsersByName(name) {
//     return dispatch => {
//         dispatch(fetchAdminUsersBegin());//calling this action to show loading
//         return adminApi.getAdminUserDetailByName(name)
//             .then(handleErrors)
//             .then(json => {
//                 dispatch(fetchAdminUserByIdSuccess(json));
//                 return json;
//             })
//             .catch(error => {
//                 console.log(error)
//                 dispatch(fetchAdminUserByIdFailure(error))
//             });
//     }
// }

export function updateAdminUsers(data, id) {
    return dispatch => {
        dispatch(updateAdminUsersBegin());
        let res = adminApi.updateAdminUser(data, id)
            .then(json => {
                dispatch(updateAdminUsersSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(updateAdminUsersFailure(error));

            })
        return res;
    }
}

export const fetchAdminUsersBegin = () => (
    // the whole object is n action
    {
        type: FETCH_ADMIN_USERS_BEGIN
    });

export const fetchAdminUsersSuccess = json => ({
    type: FETCH_ADMIN_USERS_SUCCESS,
    data: json.data
});
export const fetchAdminUsersFailure = error => ({
    type: FETCH_ADMIN_USERS_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});


export const deleteAdminUsersSuccess = (json) => ({
    type: DELETE_ADMIN_USER_SUCCESS,
    data: json.data.admin_user,
    msg: json.message
})

export const deleteAdminUsersFailure = (error) => ({
    type: DELETE_ADMIN_USER_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'},
    msg: "An error occurred, please try again later"
});

export const fetchAdminUserByIdBegin = () => ({
    type: GET_ADMIN_USER_BY_ID_BEGIN
});
export const fetchAdminUserByIdSuccess = json => ({
    type: GET_ADMIN_USER_BY_ID_SUCCESS,
    data: json.data.admin_user,
    //filters: json.data.filters
});

export const fetchAdminUserByIdFailure = error => ({
    type: GET_ADMIN_USER_BY_ID_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'},
    msg: "An error occurred, please try again later"
});

export function updateAdminUsersBegin() {
    return {
        type: UPDATE_ADMIN_USERS_BEGIN
    }
}

export function updateAdminUsersSuccess(json) {
    return {
        type: UPDATE_ADMIN_USER_SUCCESS,
        data: json.data.admin_user

    }
}

export function updateAdminUsersFailure(error) {
    return {
        type: UPDATE_ADMIN_USER_FAILURE,
        payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
    }
}

export function createAdminUser(data) {
    return dispatch => {
        dispatch(createAdminUserBegin());
        let res = adminApi.createAdminUser(data)
            .then(json => {
                dispatch(createAdminUserSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(createAdminUserFailure(error));
            })
        return res;
    }

}

export function createAdminUserBegin() {
    return {
        type: CREATE_ADMIN_USER_BEGIN,
    }
}


export const createAdminUserSuccess = admin_user => ({
    type: CREATE_ADMIN_USER_SUCCESS,
    payload: {admin_user}
});

export const createAdminUserFailure = error => ({
    type: CREATE_ADMIN_USER_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});

export function clearAdminUsers() {
    return {
        type: CLEAR_ADMIN_USERS
    }
}
export function setAdminUsersFilters(filters) {
    return {
        type: SET_ADMIN_USERS_FILTER,
        payload: {filters}
    }
}
export function clearAdminUsersFilters() {
    return {
        type: CLEAR_ADMIN_USERS_FILTER
    }
}

export function clearUserNotification() {
    return {
        type: CLEAR_USERS_NOTIFICATION
    }
}

// Private methods

function handleErrors(response) {
    if(!response.success) {
        throw Error(response.statusText);
    }
    return response;
}
