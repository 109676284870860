import React from 'react'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        height: 48
    },
  }));

const Cell = props => {

    const classes = useStyles();
    const { className, children, title } = props;


    return (
        <div className={clsx(classes.root, className)}>
            <Typography variant="subtitle2" style={{display: 'block'}}>{title}</Typography>
            <Typography variant="body1">{children}</Typography>
        </div>
    )
}

Cell.propTypes = {

}

export default Cell

