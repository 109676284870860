import adminApi from 'services/admin/adminApi';
/*----------------SETTING_TROLLEY--------------------------*/
export const FETCH_SETTING_TROLLEY_BEGIN = 'FETCH_SETTING_TROLLEY_BEGIN';
export const FETCH_SETTING_TROLLEY_SUCCESS = 'FETCH_SETTING_TROLLEY_SUCCESS';
export const FETCH_SETTING_TROLLEY_FAILURE = 'FETCH_SETTING_TROLLEY_FAILURE'

export const DELETE_SETTING_TROLLEY = 'DELETE_SETTING_TROLLEY'
export const DELETE_SETTING_TROLLEY_SUCCESS = 'DELETE_SETTING_TROLLEY_SUCCESS'
export const DELETE_SETTING_TROLLEY_FAILURE = 'DELETE_SETTING_TROLLEY_FAILURE'

export const FETCH_SETTING_TROLLEY_BY_ID_SUCCESS = 'FETCH_SETTING_TROLLEY_BY_ID_SUCCESS';
export const FETCH_SETTING_TROLLEY_BY_ID_FAILURE = 'FETCH_SETTING_TROLLEY_BY_ID_FAILURE';

export const CREATE_SETTING_TROLLEY_BEGIN = 'CREATE_SETTING_TROLLEY_BEGIN';
export const CREATE_SETTING_TROLLEY_SUCCESS = 'CREATE_SETTING_TROLLEY_SUCCESS';
export const CREATE_SETTING_TROLLEY_FAILURE = 'CREATE_SETTING_TROLLEY_FAILURE';

export const UPDATE_SETTING_TROLLEY_BEGIN = 'UPDATE_SETTING_TROLLEY_BEGIN';
export const UPDATE_SETTING_TROLLEY_SUCCESS = 'UPDATE_SETTING_TROLLEY_SUCCESS';
export const UPDATE_SETTING_TROLLEY_FAILURE = 'UPDATE_SETTING_TROLLEY_FAILURE';
export const PRINT_BARCODES_TROLLEY_BEGIN = 'PRINT_BARCODES_TROLLEY_BEGIN'

export const PRINT_BARCODES_TROLLEY_SUCCESS = 'PRINT_BARCODES_TROLLEY_SUCCESS';
export const PRINT_BARCODES_TROLLEY_FAILURE = 'PRINT_BARCODES_TROLLEY_FAILURE';

export const CLEAR_SETTING_TROLLEY = 'CLEAR_SETTING_TROLLEY';
export const CLEAR_TROLLEY_NOTIFICATION = 'CLEAR_TROLLEY_NOTIFICATION';


/*--------------------Trolley----------------------------*/
export function fetchTrolley(params, wid) {
    return dispatch => {
        dispatch(fetchTrolleyBegin());//calling this action to show loading
        return adminApi.fetchTrolleys(params, wid)// calling list of SETTING_TROLLEY
            .then(json => {

                dispatch(fetchTrolleySuccess(json));
                return json;
            })
            .catch(error => {
                console.log('error', error);

                dispatch(fetchTrolleyFailure(error))

            });
    };
}

export function deleteTrolley(id, wid) {

    return dispatch => {
        dispatch(updateTrolleyBegin());
        return adminApi.deleteTrolley(id, wid)
            .then(json => {
                dispatch(deleteTrolleySuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(deleteTrolleyFailure(error))
            });
    }
}


export function fetchTrolleyByID(id, wid) {
    return dispatch => {
        dispatch(fetchTrolleyBegin());//calling this action to show loading
        return adminApi.getTrolleyDetail(id, wid)
            .then(handleErrors)
            .then(json => {
                dispatch(fetchTrolleyByIDSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(fetchTrolleyByIDFailure(error))
            });
    }
}

export function updateTrolley(data, id, wid) {
    return dispatch => {
        dispatch(updateTrolleyBegin());
        let res = adminApi.updateTrolley(data, id, wid)
            .then(json => {
                dispatch(updateTrolleySuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(updateTrolleyFailure(error));
            })
        return res;
    }
}

export const fetchTrolleyBegin = () => (
    // the whole object is n action
    {
        type: FETCH_SETTING_TROLLEY_BEGIN
    });

export const fetchTrolleySuccess = json => ({
    type: FETCH_SETTING_TROLLEY_SUCCESS,
    data: json.data.trolleys,
    pagination: json.data.pagination
});

export const fetchTrolleyFailure = error => ({
    type: FETCH_SETTING_TROLLEY_FAILURE,
    payload: { error: (error.response) ? error.response.data : 'Something went wrong. Please try again' }
});

export const deleteTrolleySuccess = (json) => ({
    type: DELETE_SETTING_TROLLEY_SUCCESS,
    data: json.data.trolley,
    msg: json.message
})

export const deleteTrolleyFailure = (error) => ({
    type: DELETE_SETTING_TROLLEY_FAILURE,
    payload: { error: (error.response) ? error.response.data : 'Something went wrong. Please try again' },

});

export const fetchTrolleyByIDSuccess = json => ({
    type: FETCH_SETTING_TROLLEY_BY_ID_SUCCESS,
    payload: json.data.trolley
});

export const fetchTrolleyByIDFailure = error => ({
    type: FETCH_SETTING_TROLLEY_BY_ID_FAILURE,
    payload: { error: (error.response) ? error.response.data : 'Something went wrong. Please try again' },

});

export function updateTrolleyBegin() {
    return {
        type: UPDATE_SETTING_TROLLEY_BEGIN
    }
}

export function updateTrolleySuccess(json) {
    return {
        type: UPDATE_SETTING_TROLLEY_SUCCESS,
        payload: {
            data: json.data.trolley, success: true
        }

    }
}

export function updateTrolleyFailure(error) {
    return {
        type: UPDATE_SETTING_TROLLEY_FAILURE,
        payload: { error: (error.response) ? error.response.data : 'Something went wrong. Please try again' }
    }
}

export function createTrolley(data, wid) {
    return dispatch => {
        dispatch(createTrolleyBegin());
        return adminApi.createTrolley(data, wid)
            .then(json => {
                dispatch(createTrolleySuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(createTrolleyFailure(error))
            });
    }
}

    export function createTrolleyBegin() {
        return {
            type: CREATE_SETTING_TROLLEY_BEGIN,
        }
    }


    export function createTrolleySuccess(json) {
        return {
            type: CREATE_SETTING_TROLLEY_SUCCESS,
            payload: {
                data: json.data.trolley, success: true
            }
        }
    }

    export function createTrolleyFailure(error) {
        return {
            type: CREATE_SETTING_TROLLEY_FAILURE,
            payload: { error: (error.response) ? error.response.data : 'Something went wrong. Please try again' }
        }
    }

    export function copyTrolley(id, data, wid) {
        return dispatch => {
            dispatch(createTrolleyBegin());
            let res = adminApi.duplicateTrolley(id, data, wid)
                .then(json => {
                    dispatch(createTrolleySuccess(json));
                    return json;
                })
                .catch(error => {
                    dispatch(createTrolleyFailure(error));
                })
            return res;
        }
    }

    export function printBarcodes(data, wid) {
        return dispatch => {
            dispatch(printBarcodesBegin());
            let res = adminApi.printTrolleyBarcodes(data, wid)
                .then(json => {
                    dispatch(printBarcodesSuccess(json));
                    return json;
                })
                .catch(error => {
                    dispatch(printBarcodesFailure(error));
                })
            return res;
        }
    }

    export const printBarcodesBegin = () => ({
        type: PRINT_BARCODES_TROLLEY_BEGIN
    });

    export const printBarcodesSuccess = json => ({
        type: PRINT_BARCODES_TROLLEY_SUCCESS,
        payload: json
    });

    export const printBarcodesFailure = error => ({
        type: PRINT_BARCODES_TROLLEY_FAILURE,
        payload: { error: (error.response) ? error.response.data : 'Something went wrong. Please try again' },

    });

    export function clearTrolley() {
        return {
            type: CLEAR_SETTING_TROLLEY
        }
    }

    export function clearTrolleyNotification() {
        return {
            type: CLEAR_TROLLEY_NOTIFICATION
        }
    }


    // Private methods

    function handleErrors(response) {
        if (!response.success) {
            throw Error(response.statusText);
        }
        return response;
    }
