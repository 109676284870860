import React, { Component } from 'react'
import { connect } from 'react-redux'
import { resetPassword } from 'redux-token-auth-config' // <-- note this is YOUR file, not the redux-token-auth NPM module

import { CircularProgress, TextField, Button, Paper } from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


const styles = theme => ({
  paper: {
    position: 'relative',
    height: '100%',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    boxShadow: 'none'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: '20px'
  },
  submit: {
    marginTop: theme.spacing(3),
    color: 'white',
    backgroundImage: 'linear-gradient(#45b5f2, #249ddd)',
    boxShadow: 'none',
    textTransform: 'none',
    fontWeight: 'bolder',
  },
  subtitle: {
    width: '100%',
    textAlign: 'center',
    padding: '8px',
    fontSize: 16,
    lineHeight: 'normal'
  },
  header: {
    fontSize: theme.spacing(3.5),
    fontWeight: 'lighter',
    padding: '50px 50px 32px'
  },
})

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: [],
      errorCode: null,
      sending: false,
      success: false,
    };
    this.submit = this.submit.bind(this);

  }

  submit(e) {
    e.preventDefault()
    const origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    const reset_url = `${origin}/auth/reset_password_confirmation`
    const { resetPassword } = this.props
    const {
      email,
    } = this.state

    this.setState({ sending: true });
    resetPassword({ email, url: reset_url })
      .then((response) => {
        this.setState({ sending: false, success: true });
      })
      .catch((error) => {
        this.setState({ sending: false });
        if (error.response) {
          this.setState({
            errorCode: error.response.status,
            errors: error.response.data.errors || error.response.statusText,
          });
        }
        console.log('error: ', this.state.errors);
      });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  render(props) {
    const { submit: submitForm } = this
    const { classes } = this.props;
    return (
      <Paper className={classes.paper}>
        <div className={classes.alignRight}>
          <a href="/auth/register">Sign Up</a>
        </div>
        <div className={classes.header}>Lost Password</div>


        {!this.state.sending ? (
          !this.state.success ?

            <>
              <div className={classes.subtitle}>Please enter your email adress. <br /> You will receive a link to crate a new password via email</div>
              <form className={classes.form} onSubmit={submitForm}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  value={this.state.email}
                  onChange={this.handleChange}
                  id="email"
                  label="Email Address"
                  name="email"
                  type="email"
                  autoComplete="username"
                  InputLabelProps={{ style: { color: 'rgba(0, 0, 0, 0.54)' } }}
                // autoFocus
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={classes.submit}
                  color='secondary'
                >
                  Get New Password
                    </Button>

                <div>
                  {
                    this.state.errors ? (
                      <h6>{this.state.errors}</h6>
                    ) : (null)
                  }
                  {
                    this.state.errorCode === 500 ?
                      <h6>Server error 500, please contact support</h6> : (null)
                  }
                </div>
              </form>
            </>
            :
            <>
              <div className={classes.subtitle}>If the email address provided exists in our system, you should receive an email with further instructions momentarily</div>
            </>
        ) : (
            <CircularProgress />
          )}

        <div className={classes.footer}>
          <Link to="/auth/login">Log In</Link>
        </div>
      </Paper>
    )
  }
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(
  null,
  { resetPassword },
)(withStyles(styles)(ResetPassword))
