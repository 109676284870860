/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */

import DashboardIcon from '@material-ui/icons/Dashboard';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import BusinessIcon from '@material-ui/icons/Business';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import SettingsIcon from '@material-ui/icons/Settings';

export default [
  {

    pages: [
      {
        title: 'DASHBOARD',
        href: '/admin/dashboard/',
        icon: DashboardIcon,
        disabled:true
      },
      {
        title: 'MANAGE',
        icon: PlaylistAddCheckIcon,
        children: [
          {
            title: 'OVERVIEW',
            href: '/admin/orders/overview/',
            disabled:true
          },
          {
            title: 'ORDERS',
            href: '/admin/manage/orders/',
          },
          {
            title: 'BATCHES',
            href: '/admin/manage/batches/'
          },

        ]
      },
      {
        title: 'PRODUCTS',
        icon: LocalMallIcon,

        children: [
          {
            title: 'SKU',
            href: '/admin/products/sku'
          },
          {
            title: 'BUNDLES',
            href: `/admin/bundles`
          },

        ]
      },
      {
        title: 'SUPPORT',
        icon: HeadsetMicIcon,
        href: '/admin/support/tickets',
      },
      {
        title: 'ORGANISATIONS',
        href: '/admin/organisations/summary',
        icon: BusinessIcon
      },

      {
        title: 'ACCOUNTING',
        icon: CreditCardIcon,
        children: [
          {
            title: 'INVOICES',
            href: '/admin/accounting/invoices'
          },
         /* {
            title: 'HANDLING',
            href: '/admin/accounting/handling',
          },
          {
            title: 'SHIPPING',
            href: '/admin/accounting/shipping',
          },*/
          {
            title: 'OTHER',
            href: '/admin/accounting/others',
          },
        ]
      },
      
      /* {
        title: 'WAREHOUSE',
        href: '/admin/warehouse',
        icon: HomeWorkIcon
      },*/
      {
        title: 'WAREHOUSE',
        icon: HomeWorkIcon,
        children: [

          {
            title: 'SUMMARY',
            href: '/admin/warehouses/summary',

          },
          {
            title: 'INVENTORY',
            href: '/admin/warehouses/inventory',
          },
          {
            title: 'SUPPLIERS',
            href: '/admin/warehouses/suppliers',

          },
           /*{
             title: 'ZONES',
             href: '/admin/warehouses/zones',
 
           },*/
        ]
      },

      {
        title: 'SETTINGS',
        href: '/admin/settings/',
        icon: SettingsIcon
      },
    ]
  },

];
