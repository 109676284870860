import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,

  Typography
} from '@material-ui/core';
import PricingCardRow from './PricingCardRow';

const useStyles = makeStyles(theme => ({
  root: {
    // background: 'inherit'
    textTransform: 'capitalize',
  },
  content: {
    padding: '0px 39px 51px'
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  footer: {
    justifyContent: 'center'
  },
  itemConatiner: {
    display: 'flex',
  },
  item: {
    padding: 40,
    // width: '50%',
    flexGrow: 1,
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${theme.palette.divider}`
    }
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  }
}));

const OrderCard = props => {
  const {headRows, title, order, rows} = props;


  const classes = useStyles();
  return (
    <Card
      className={classes.root}
    >
      <CardHeader title={title} />
      <Divider />
      <CardContent className={classes.content}>
        <div className={classes.itemConatiner}>
          {headRows.map(row => 
            row.hidden ? <></> :  
              <div align='center' key={row.name} className={classes.item}>
                <Typography variant="overline" paragraph>
                  {row.label}
                </Typography>
                <Typography variant='h4'>
                  {"$" + order[row.name] || "-"}
                </Typography>
            </div>
          )}
        </div>
        <Divider />
        <Table>

          <TableBody>
            {rows.map(row =>
              <PricingCardRow row={row} order={order} />
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

OrderCard.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object.isRequired
};

export default OrderCard;
