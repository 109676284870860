import keepspacePubApi from "services/keepspacePubApi";

export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const fetchCountries = () => async (dispatch) => {

	try {
		const response = await keepspacePubApi.getCountries();
		const data = response;

		if(!!data && data.length) {

			const countryCodes = ["ABW", "AFG", "AGO", "AIA", "ALA", "ALB", "AND", "ANT", "ARE", "ARG", "ARM", "ASM", "ATA", "ATF", "ATG", "AUS", "AUT", "AZE", "BDI", "BEL", "BEN", "BES", "BFA", "BGD", "BGR", "BHR", "BHS", "BIH", "BLM", "BLR", "BLZ", "BMU", "BOL", "BRA", "BRB", "BRN", "BTN", "BVT", "BWA", "CAF", "CAN", "CCK", "CHE", "CHL", "CHN", "CIV", "CMR", "COG", "COK", "COL", "COM", "CPV", "CRI", "CUB", "CUW", "CXR", "CYM", "CYP", "CZE", "DEU", "DJI", "DMA", "DNK", "DOM", "DZA", "ECU", "EGY", "ERI", "ESH", "ESP", "EST", "ETH", "FIN", "FJI", "FLK", "FRA", "FRO", "FSM", "GAB", "GBR", "GEO", "GGY", "GHA", "GIB", "GIN", "GLP", "GMB", "GNB", "GNQ", "GRC", "GRD", "GRL", "GTM", "GUF", "GUM", "GUY", "HKG", "HMD", "HND", "HRV", "HTI", "HUN", "IDN", "IMN", "IND", "IOT", "IRL", "IRN", "IRQ", "ISL", "ISR", "ITA", "JAM", "JEY", "JOR", "JPN", "KAZ", "KEN", "KGZ", "KHM", "KIR", "KNA", "KOR", "KWT", "LAO", "LBN", "LBR", "LBY", "LCA", "LIE", "LKA", "LSO", "LTU", "LUX", "LVA", "MAC", "MAF", "MAR", "MCO", "MDA", "MDG", "MDV", "MEX", "MHL", "MLI", "MLT", "MMR", "MNE", "MNG", "MNP", "MOZ", "MRT", "MSR", "MTQ", "MUS", "MWI", "MYS", "MYT", "NAM", "NCL", "NER", "NFK", "NGA", "NIC", "NIU", "NLD", "NOR", "NPL", "NRU", "NZL", "OMN", "PAK", "PAN", "PCN", "PER", "PHL", "PLW", "PNG", "POL", "PRI", "PRK", "PRT", "PRY", "PSE", "PYF", "QAT", "REU", "ROU", "RUS", "RWA", "SAU", "SDN", "SEN", "SGP", "SGS", "SHN", "SJM", "SLB", "SLE", "SLV", "SMR", "SOM", "SPM", "SRB", "SSD", "STP", "SUR", "SVK", "SVN", "SWE", "SWZ", "SXM", "SYC", "SYR", "TCA", "TCD", "TGO", "THA", "TJK", "TKL", "TKM", "TLS", "TON", "TTO", "TUN", "TUR", "TUV", "TWN", "TZA", "UGA", "UKR", "UNK", "URY", "USA", "UZB", "VAT", "VCT", "VEN", "VGB", "VIR", "VNM", "VUT", "WLF", "WSM", "YEM", "ZAF", "ZMB", "ZWE"];
			const res = data.filter(item => countryCodes.includes(item?.iso3));
			
			let countryList = res.map(country => {
				if(country.iso3 === "USA") country.text = "United States";
				if(country.iso3 === "GBR") country.text = "United Kingdom";
				country.text = country.text.replace(/ \([\s\S]*?\)/g, '');// Remove text between parentheses
				return {
					text: country.text,
					value: country.value,
					uid : country.uid,
					code: country.iso2,
					alpha3Code: country.iso3,
					currencies: country.currency
				}
			});

			countryList = [{text: "", value: ""}, ...countryList];

			dispatch(fetchCountriesSuccess(countryList));
			return {
				success: true, countries: countryList
			};

		} else {
				dispatch(fetchCountriesSuccess([]));
			return {success: false, error: 'Something went wrong.', countries:[]};
		}
	} catch(e) {
		let error = 'An error occurred';
		console.log(e);

		if(e.response) {
			error = e.response.data
		}
		dispatch(fetchCountriesSuccess([]));
		return {success: false, error: 'Something went wrong.'};

	}

}
export const fetchCountriesSuccess = json => ({
	type: FETCH_COUNTRIES_SUCCESS,
	payload: json
});
