import React from 'react'

import {TextValidator} from 'react-material-ui-form-validator';

const TextFieldValidator = (props) => {
  const {
    classNames, id, errors, onChange, type, options, required, validators, value, inputProps, label, labelText,
    errorMessages, optionValue, select, multiline, rows, input_type, helperText, disabled, onBlur, keyVal, step, optionText
  } = props;
  return (
    <>
      {type ? (
        <>
          {

            type === 'native-select' ? (
              <TextValidator
                key={keyVal || id}
                validators={required ? ['required'] : (validators ? validators : [])}
                errorMessages={required ? ['This field is required'] : (errorMessages ? errorMessages : [])}
                inputProps={{
                  name: id,
                  id: id
                }}
                variant="outlined"
                fullWidth
                value={value[id] || ''}
                onChange={onChange}
                onBlur={onBlur || null}
                SelectProps={{native: true}}
                select
                label={label}
                error={errors ? errors[id] : false}
                helperText={errors ? errors[id] : false}
                disabled={disabled}
                InputLabelProps={(value[id] != "") ? {shrink: true} : {shrink: false}}

              >
                <option value=""/>
                {options.map(option => (
                  <option value={option.id || option.value}>{option.title || option.text}</option>
                ))}
              </TextValidator>
            ) : (null)
          }
        </>
      ) : (
        /**
         * If no 'type' is provided, simply return the default TextValidator
         */
        <TextValidator
          key={keyVal || id}
          variant="outlined"
          validators={required ? ['required'] : (validators ? validators : [])}
          errorMessages={required ? `${label || labelText}  is required` : (errorMessages ? errorMessages : [])}
          fullWidth
          id={id}
          name={id}
          label={(required || (validators && validators.includes('required'))) ? `${label || ''} *` : label}
          value={value}
          error={errors ? errors[id] : false}
          helperText={errors ? errors[id] : (helperText || false)}
          onChange={onChange}
          onBlur={onBlur}
          InputLabelProps={(value !== "") ? {shrink: true} : null}
          inputProps={inputProps ? inputProps : {}}
          className={classNames ? classNames : ""}
          SelectProps={{native: select}}
          select={select}
          multiline={multiline}
          rows={rows}
          type={input_type}
          step={step}
          disabled={disabled}
        >
          {options && options.map(option =>
            <option value={(optionValue) ? option[optionValue] : option['value'][id]}>
              {optionText ? option[optionText] : option.title || option.text || option.name}
            </option>
          )}
        </TextValidator>
      )}
    </>
  )
}


export default TextFieldValidator;
