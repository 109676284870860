import keepspaceApi from 'services/keepspaceApi';

export const FETCH_CATEGORIES_BEGIN = 'FETCH_CATEGORIES_BEGIN';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE'

export const FETCH_CATEGORY_BY_ID_BEGIN = 'FETCH_CATEGORY_BY_ID_BEGIN';
export const FETCH_CATEGORY_BY_ID_SUCCESS = 'FETCH_CATEGORY_BY_ID_SUCCESS';
export const FETCH_CATEGORY_BY_ID_FAILURE = 'FETCH_CATEGORY_BY_ID_FAILURE'

export const CREATE_CATEGORY_BEGIN = 'CREATE_CATEGORY_BEGIN';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE'

export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS'
export const UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE'

export const UPDATE_CATEGORY_FILTER = 'UPDATE_CATEGORY_FILTER';


export function fetchCategories() {
    return dispatch => {
        dispatch(fetchCategoriesBegin());
        return keepspaceApi.getCategories()
            .then(json => {
                dispatch(fetchCategoriesSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(fetchCategoriesFailure(error))
            });
    };
}

export function updateCategory(category) {
    return dispatch => {
        return keepspaceApi.updateCategory(category)
            .then(json => {
                dispatch(updateCategorySuccess(json));
                return json;
            })
            .catch(error => {
              dispatch(updateCategoryFailure(error));
              // return error
            });
    }
}

export function createCategory(formData) {
    return dispatch => {
        dispatch(createCategoryBegin());
        return keepspaceApi.createCategory(formData)
            .then(json => {
              dispatch(createCategorySuccess(json));
              return json;
            })
            .catch(error => {dispatch(createCategoryFailure(error))});
    }
}

export const fetchCategoriesBegin = () => ({
    type: FETCH_CATEGORIES_BEGIN
});

export const fetchCategoriesSuccess = categories => ({
    type: FETCH_CATEGORIES_SUCCESS,
    payload: { categories }
});

export const fetchCategoriesFailure = error => ({
    type: FETCH_CATEGORIES_FAILURE,
    payload: { error }
});

export const fetchCategoryByIDFailure = error => ({
    type: FETCH_CATEGORY_BY_ID_FAILURE,
    payload: { error }
});

export const updateCategorySuccess = (category) => ({
    type: UPDATE_CATEGORY_SUCCESS,
    payload: { category }
})

export const updateCategoryFailure = (error) => ({
    type: UPDATE_CATEGORY_FAILURE,
    payload: { error }
})


export const createCategoryBegin = () => ({
    type: CREATE_CATEGORY_BEGIN
});

export const createCategorySuccess = category => ({
    type: CREATE_CATEGORY_SUCCESS,
    payload: { category }
});

export const createCategoryFailure = error => ({
    type: CREATE_CATEGORY_FAILURE,
    payload: { error }
});


export function updateCategoryFilter(value, isChecked) {
    return {
        type: UPDATE_CATEGORY_FILTER,
        value,
        isChecked,
    }
}
