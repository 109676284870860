import React, { Component } from 'react'
import { connect } from 'react-redux'
import { resetPasswordTempSignin, changePassword, signOutUser } from 'redux-token-auth-config' // <-- note this is YOUR file, not the redux-token-auth NPM module

import { Input, Typography } from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';

import * as qs from 'query-string';
import { Link } from 'react-router-dom';

const styles = theme => ({
    paper: {
        position: 'relative',
        height: '100%',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        boxShadow: 'none'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginBottom: '20px'
    },
    submit: {
        marginTop: theme.spacing(3),
        color: 'white',
        backgroundImage: 'linear-gradient(#45b5f2, #249ddd)',
        boxShadow: 'none',
        textTransform: 'none',
        fontWeight: 'bolder',
    },
    subtitle: {
        width: '100%',
        textAlign: 'center',
        padding: '8px',
        fontSize: 16,
        lineHeight: 'normal'
    },
    header: {
        fontSize: theme.spacing(3.5),
        fontWeight: 'lighter',
        padding: '50px 50px 32px',
        lineHeight: 'normal',
        textAlign: 'center'
    },
})

class ResetPasswordConfirmation extends Component {
    constructor(props) {
        super(props);
        const params = qs.parse(this.props.location.search)
        this.state = {
            uid: params['uid'],
            client: params['client'],
            accessToken: params['access-token'],
            password: '',
            passwordConfirm: '',
            submissionSuccessful: false
        };
        this.submitForm = this.submitForm.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isSubmittingRequest === true &&
            this.props.isSubmittingRequest === false &&
            this.props.submissionError === '') {
            // Password change started, finished, no errors (ie, it worked successfully)
            this.setState({ submissionSuccessful: true })
        }
    }

    submitForm(e) {
        e.preventDefault()
        this.setState({
            submissionStarted: true,
        })
        const {
            uid,
            client,
            accessToken
        } = this.state;
        this.props.dispatchResetPasswordTempSignIn({
            uid,
            client,
            'access-token': accessToken
        })
        this.props.dispatchChangePassword({
            password: this.state.password,
            passwordConfirmation: this.state.passwordConfirm
        })
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    render(props) {
        const { submitForm } = this
        const { classes } = this.props;
        return (
            <Paper className={classes.paper}>
                {this.state.submissionSuccessful === false ? (
                    <>
                        <div className={classes.header}> Lost Password</div>
                        <div className={classes.subtitle}>Please enter a new password</div>
                        <form className={classes.form} onSubmit={submitForm}>
                            <FormControl margin="normal" fullWidth>
                                <InputLabel htmlFor="email">Email Address</InputLabel>
                                <Input disabled id="email" value={this.state.uid} onChange={this.handleChange} autoFocus />
                            </FormControl>

                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="email">New Password</InputLabel>
                                <Input type='password' id="password" value={this.state.password} onChange={this.handleChange} autoFocus />
                            </FormControl>

                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="email">Confirm New Password</InputLabel>
                                <Input type='password' id="passwordConfirm" value={this.state.passwordConfirm} onChange={this.handleChange} />
                            </FormControl>

                            <Typography variant='subtitle2' style={{ color: 'red' }}>
                                {this.props.submissionError}
                            </Typography>

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                className={classes.submit}
                            >
                                Change Password
                            </Button>
                        </form>
                    </>
                ) : (
                        <>
                            <div className={classes.header}> Password Change Successful</div>
                            <div className={classes.subtitle}>You can now Sign In</div>

                            <div className={classes.footer}>
                                <Link to="/auth/login">Log In</Link>
                            </div>
                        </>
                    )}
            </Paper>
        )
    }
}

ResetPasswordConfirmation.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = (state, ownProps) => ({
    isSubmittingRequest: state.reduxTokenAuth.currentUser.isSubmittingRequest,
    submissionError: state.reduxTokenAuth.currentUser.submissionError,
    ...ownProps
})

const mapDispatchToProps = dispatch => {
    return {
        dispatchChangePassword: (newPassword) => dispatch(changePassword(newPassword)),
        dispatchResetPasswordTempSignIn: (authHeaders) => dispatch(resetPasswordTempSignin(authHeaders)),
        dispatchSignOutUser: () => dispatch(signOutUser())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(ResetPasswordConfirmation))
