import React, {PureComponent} from 'react'
import {PropTypes} from 'prop-types';
import clsx from 'clsx';

import {
    withStyles,
    InputBase
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';

const styles = theme => ({
    search: {
        display: 'flex',
        position: 'relative',
        backgroundColor: "#fff",
        // width: '100%',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderRadius: '4px',
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
        flexGrow: 1,
        maxHeight: 40,
        alignItems: 'center',
        margin: 5
    },
    searchIcon: {
        width: theme.spacing(9),
        height: '100%',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {

        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '90%',
        },
    }
})

class OrdersSearch extends PureComponent {

    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.timer = null;
    }
    handleInputChange(event) {
        const text = event.target.value;
        const {onChange, updateState} = this.props;
        onChange(text);

        if(this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(function () {
            if(text?.length >= 3 || text?.length === 0) {
                updateState("page", 1, true);
            }
        }, 300)
    }

    render() {
        const {classes, placeholder, searchTerm, rightChild, className} = this.props;
        return (
            <div className={clsx(className, classes.search)}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    placeholder={placeholder}
                    onChange={this.handleInputChange}
                    autoFocus={false}
                    value={searchTerm}
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput
                    }}
                />
                {rightChild}
            </div>
        );
    }
}


OrdersSearch.defaultProps = {
    placeholder: "Search...",
}

OrdersSearch.propTypes = {
    placeholder: PropTypes.string,
    //searchTerm: PropTypes.string.isRequired,
    //onChange: PropTypes.func.isRequired,
    rightChild: PropTypes.element,
}

export default withStyles(styles)(OrdersSearch);
