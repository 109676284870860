import React from 'react';
import {
  Typography,
} from '@material-ui/core';

const PricingProposal = props => {
  return (
    <Typography>This is your KeepSpace pricing proposal breakdown of all the charges that can occur based on the services we provide within your order developments. </Typography>
  );
};


export default PricingProposal;
