import React, { Component } from 'react';
import {
  Typography,
  TextField,
  withStyles,
  Select,
  MenuItem,
  Button,
  Paper,
  Checkbox,
  FormControlLabel,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { createCategory } from 'actions/categoryActions';
import { SketchPicker } from 'react-color';
import Check from "@material-ui/icons/Check";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";

const styles = theme => ({
  ...extendedTablesStyle,
  paper: {
    padding: '20px !important',
    margin: 'auto',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    marginBottom: '8px',
    padding: '5px 0'
  },
  gridItem: {
    paddingRight: '8px',
  },
  sketchPicker: {
    boxShadow: 'none !important',
    margin: 'auto',
    padding: '50px 0'
  },
  sketchPickerContainer: {
    margin: '20px 0'
  },
  checkboxLabel: {
    width: '100%'
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  subcategoriesContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 0'
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  errorMessage: {
    color: 'red',
    fontSize: 11
  }
});

class NewCategory extends Component {

  constructor(props) {
    super(props);
    this.state = {
      // requestStatus: '',
      title: '',
      colour: '#FFFFFF',
      parent_id: '',
      subcategory: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.handleToggle = this.handleToggle.bind(this);
    this.updateParentId = this.updateParentId.bind(this);
  }

  updateParentId(event) {
    this.setState({
      parent_id: event.target.value
    });
  }

  handleToggle() {
    const { subcategory } = this.state;
    const newSubcategory = !subcategory;
    this.setState({
      subcategory: newSubcategory
    });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value,
    })
  }

  handleColourChange = (color) => {
    this.setState({ colour: color.hex });
  };

  submitForm(event) {
    event.preventDefault();

    var formData = new FormData();
    formData.append('title', this.state.title)
    formData.append('colour', this.state.colour)
    formData.append('parent_id', this.state.parent_id)

    this.props.dispatch(createCategory(formData))
      .then(result => {
        if (result) {
          // result is null when in error
          this.props.handleClose()
        }
      })
  }

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.paper}>
        <Typography variant='h5' className={classes.header}>
          Category details
        </Typography>
        <TextField
          className={classes.formControl}
          required
          id={'title'}
          name={'title'}
          label={'Title'}
          value={this.state.title}
          onChange={this.handleChange}
          error={this.props.error ? Boolean(this.props.error.response.data['title']) : false}
          helperText={this.props.error ? this.props.error.response.data['title'] : false}
        />
        <div className={classes.subcategoriesContainer}>
          <FormControl className={classes.formControl}>
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checkboxeStyle}
                  onClick={this.handleToggle}
                  checkedIcon={<Check color="primary" />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  checked={this.state.subcategory}
                />
              }
              label="Is a sub-category"
              className={classes.checkboxLabel}
            />
          </FormControl>

          <FormControl className={classes.formControl} disabled={!this.state.subcategory}>
            <InputLabel htmlFor="name-disabled">Parent category</InputLabel>
            <Select
              value={this.state.parent_id}
              id={'parent_id'}
              onChange={this.updateParentId}
              onBlur={this.onBlur}
              name={'parent_id'}
              error={this.props.error ? Boolean(this.props.error.response.data['parent_id']) : false}
              helperText={this.props.error ? this.props.error.response.data['parent_id'] : false}
            >
              {this.props.categories.map(category => (
                <MenuItem key={`sub${category.id}`} value={category.id}>{category.title}</MenuItem>
              ))}
            </Select>
          </FormControl>

        </div>

        <div className={classes.sketchPickerContainer}>
          <SketchPicker
            className={classes.sketchPicker}
            color={this.state.colour || 'white'}
            onChangeComplete={this.handleColourChange}
          />
          <div className={classes.errorMessage}>
            {this.props.error ? this.props.error.response.data['colour'] : false}
          </div>
        </div>
        <div className={classes.flex}>
          <Button
            variant="contained"
            color="secondary"
            onClick={this.props.handleClose}
          >
            Cancel
          </Button>

          <Button
            color='primary'
            variant="contained"
            onClick={this.submitForm}
          >
            Create
          </Button>
        </div>
      </Paper>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  error: state.categoriesReducer.error
})

export default connect(mapStateToProps)(withStyles(styles)(NewCategory));
