import React, {useState, memo, useEffect} from "react";
import {Button, makeStyles, Grid, Dialog, DialogTitle, Card, Drawer, Typography, DialogContent, DialogActions} from "@material-ui/core";
import filterIcon from '@iconify-icons/feather/filter';
import {Icon} from '@iconify/react';
// import BasicDrawer from "components/Drawers/BasicDrawer";
import CreateInvoice from 'views/admin/Accounting/Invoices/GenerateInvoice';
import InvoiceFilterDrawerContents from "views/admin/Accounting/Invoices/InvoiceFilterDrawerContents";
import styled from "styled-components";

const InvoiceMenuBar = memo((props) => {
  const classes = useStyles();
  const {createInvoice, loading, error, notification, formSuccess, activeFilters} = props;
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  const {id} = props.match.params;
  const [open, setOpen] = useState(false);

  const toggleDrawer = (side, open) => () => {
    setFilterPanelOpen(open);
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getAppliedFilters = () => {
    const filters = Object.keys(activeFilters).filter(key => !!activeFilters[key] && activeFilters[key].length !== 0 && key !== "per_page" && key !== "page");
    if(filters.length) {
      //exclude pagination
      return `(${filters.length})`
    }
    else {
      return false
    }
  }

  useEffect(() => {
    if(formSuccess) {
      handleClose();
    }
  }, [formSuccess]);

  const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: unset ;
     opacity: '.15';
  }
  `;
  const StyledDrawer = styled(Drawer)`
  .MuiBackdrop-root {
    background-color: unset ;
     opacity: '.15';
  }
  `;
  return (
    <Grid spacing={2}>
      <Grid className={classes.buttonsContainer}>

        {id && <Button variant="contained" className={classes.button} onClick={handleClickOpen}>
          Create Invoice
        </Button>}

        {!id && <Button variant="contained" className={classes.button}
          href="/admin/accounting/invoices/drafts">
          View Bulk Drafts
        </Button>}

        <Button className={classes.button}
          onClick={toggleDrawer("right", true)}
          variant="outlined"
          textColor="adminPrimary"
          startIcon={<Icon icon={filterIcon} />}
        >
          Filters  {getAppliedFilters()}
        </Button>
      </Grid>

      <Drawer
        style={{backgroundColor: '#aef1eb54'}}
        anchor="right"
        open={filterPanelOpen}
        onClose={toggleDrawer("right", false)}>
        <InvoiceFilterDrawerContents
          {...props}
        />
      </Drawer>



      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          <Typography variant="h4">Generate Invoice</Typography>
        </DialogTitle>
        <DialogContent>

          <CreateInvoice createInvoice={createInvoice} loading={loading} error={error} notification={notification} formSuccess={formSuccess} handleClose={handleClose} id={id} key="createInvoiceModal" />
        </DialogContent>

      </Dialog>


    </Grid>
  );
});

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {

    '& > *': {
      margin: theme.spacing(2),
    },
    float: 'right'
  },

  button: {
    minWidth: "150px",
    color: '#40B5AD',
    fontWeight: 600,
    border: 2,
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '6px',
    // overflow: 'auto',

  },
}));

export default InvoiceMenuBar;
