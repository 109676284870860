import React, {PureComponent} from 'react'
import {connect} from 'react-redux';

import {
    Fab,
    Grid,
    Typography,
    TextField,
    withStyles,
} from '@material-ui/core';

import PersonAddIcon from '@material-ui/icons/PersonAdd';

import {addFormDataToOrder} from 'actions/orderActions';
import Hint from 'components/Help/Hint';

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    newOrderObject: state.orderReducer.newOrderObject,
})

const formHeaders = [
    'firstName',
    'lastName',
    'email',
    'phone',
    'company',
    'address1',
    'address2',
    'suburb',
    'postcode',
    'state',
    'country',
]

const formFields = {
    firstName: {
        label: 'First Name',
        id: 'firstName',
        name: 'firstName',
        required: true,
        sm: 6
    },
    lastName: {
        label: 'Last Name',
        id: 'lastName',
        name: 'lastName',
        required: true,
        sm: 6
    },
    email: {
        label: 'Email Address',
        id: 'email',
        name: 'email',
        required: true,
        sm: 4
    },
    phone: {
        label: 'Phone Number',
        id: 'phone',
        name: 'phone',
        required: false,
        sm: 4
    },
    company: {
        label: 'Company',
        id: 'company',
        name: 'company',
        required: false,
        sm: 4
    },
    address1: {
        label: 'Address Line 1',
        id: 'address1',
        name: 'address1',
        required: true,
        sm: 4
    },
    address2: {
        label: 'Address Line 2',
        id: 'address2',
        name: 'address2',
        required: false,
        sm: 4
    },
    suburb: {
        label: 'Suburb',
        id: 'suburb',
        name: 'suburb',
        required: true,
        sm: 4
    },
    postcode: {
        label: 'Postcode',
        id: 'postcode',
        name: 'postcode',
        required: true,
        sm: 4
    },
    state: {
        label: 'State',
        id: 'state',
        name: 'state',
        required: true,
        sm: 4
    },
    country: {
        label: 'Country',
        id: 'country',
        name: 'country',
        required: true,
        sm: 4
    }
}

const styles = theme => ({
    part: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(100vh - 400px)'
    },
    textField: {
        borderRadius: '4px',
        border: '1px solid rgb(127, 127, 127)',
        marginBottom: '8px',
        marginRight: '8px',
    },
    gridItem: {
        paddingRight: '8px',
    },
    flexGrow: {
        flexGrow: 1,
    },

})

class P2Location extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            formData: {...this.props.newOrderObject},
        }
        this.onChange = this.onChange.bind(this)
        this.onBlur = this.onBlur.bind(this)
    }

    onBlur(event) {
        this.props.dispatch(addFormDataToOrder(event.target.id, event.target.value))
    }

    onChange(event) {
        this.setState({
            formData: {
                ...this.state.formData,
                [event.target.id]: event.target.value
            }
        })
    }

    render() {
        const {classes} = this.props;
        return (
            <Grid container spacing={8}>
                {formHeaders.map(field => (
                    <Grid item xs={12} sm={formFields[field].sm} className={classes.gridItem}>
                        <Typography variant='h6'>

                        </Typography>
                        <TextField
                            className={classes.textField}
                            required={formFields[field].required}
                            id={formFields[field].id}
                            name={formFields[field].name}
                            label={formFields[field].label}
                            fullWidth
                            value={this.state.formData[field] ? (this.state.formData[field]) : ('')}
                            onBlur={this.onBlur}
                            onChange={this.onChange}
                            autocomplete={formFields[field].name}
                        />
                    </Grid>
                ))}

                <div className={classes.flexGrow}></div>

                <Grid item xs={12}>

                    <Hint>
                        <Typography variant='h6'>Do you use this customer often?</Typography>
                        <Typography>Consider adding them as a repeat customer, and you won't have to fill out their details again</Typography>

                        <Fab
                            variant="extended"
                            aria-label="Add repeat customer"
                            className={classes.newOutboundButton}
                            style={{
                                marginTop: '24px',
                            }}
                        >
                            Add Repeat Customer<PersonAddIcon style={{marginLeft: '4px'}} />
                        </Fab>
                    </Hint>
                </Grid>

            </Grid>
        )
    }
}

export default connect(mapStateToProps)(withStyles(styles)(P2Location));
