import React, { useState, useEffect } from "react";
import { withStyles, CardContent, Card, Grid } from "@material-ui/core";
import InvoiceTable from "layouts/AdminLayout/components/InvoiceTable";
import InvoiceMenuBar from "./InvoiceMenuBar";

const InvoiceIndexAll = (props) => {
  const {
    classes,
    fetchInvoices,
    setInvoicesFilters,
    clearInvoices,
    invoices,
    match,
    fetchOrgInvoices,
    loading,
  } = props;
  const { id } = match.params;
  const params = {
    per_page: 25,
    page: 1,
  };
  useEffect(() => {
    // dispatch(fetchSuppliers())// calling summary api
    getInvoices(params);
    return () => {
      clearInvoices();
    };
  }, []);

  const getInvoices = (args) => {
    if (id) {
      fetchOrgInvoices(id, args);
    } else {
      fetchInvoices(args);
    }
  };

  const handleClearFilters = () => {
    const args = {
      per_page: 25,
      page: 1,
    };

    if (id) {
      fetchOrgInvoices(id, args);
    } else {
      fetchInvoices(args);
    }
    setInvoicesFilters({
      organisation_ids: [],
      invoice_date_before: null,
      invoice_date_after: null,
      statuses: [],
    });
  };

  return (
    <div>
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <Grid>
            <InvoiceMenuBar
              handleClearFilters={handleClearFilters}
              getInvoices={getInvoices}
              {...props}
            />
          </Grid>
          <Grid>
            <InvoiceTable
              style={{ marginTop: "50px" }}
              data={invoices}
              getInvoices={getInvoices}
              handleClearFilters={handleClearFilters}
              params={params}
              {...props}
            />
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

const styles = (theme) => ({
  root: {
    border: 0,
    //boxShadow: ' 10px 10px 15px rgba(70, 70, 70, 0.12)',
    borderRadius: "6px",
    // marginTop: '-42px'
  },
  topHead: {
    marginTop: "-30px",
  },
});

export default withStyles(styles)(InvoiceIndexAll);
