import React from 'react'
import { withStyles, Grid, Card, Table, TableRow, TableBody, TableCell, Typography } from '@material-ui/core';

const styles = theme => ({
  basic: {
    // background: '#f7f6f6',
    border: "2px solid #eeeeee",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    height: '100%',
    padding: '10px'
  },
  image: {
    // border: "2px solid grey " ,
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto'
  },
  flexItem: {
    width: '50%',
    padd: '0 10px'
  },
  image2: {
    border: "2px solid #eeeeee"
  }
})

function SummaryCard(props) {
  const { classes, item, index, type, units, categories = [], error } = props;

  let category = categories.find(category => category.id === parseFloat(item.category_id))

  const CustomRow = ({ row }) =>
    <>
      <TableRow >
        <TableCell>{row.name}: </TableCell>
        <TableCell>{item[row.id] ? `${[row.prefix]} ${item[row.id]} ${[row.suffix]}` : '-'}
          <Typography variant='subtitle2' color='error'>{(error && error.response) ? error.response.data[row.id] : null}</Typography>
        </TableCell>
      </TableRow>
    </>

  const infoRows = [
    { name: 'Title', id: 'title' },
    { name: 'Description', id: 'description' },
    { name: 'SKU', id: 'sku' },
    { name: 'Barcode', id: 'barcode' },
    { name: 'Code', id: 'hs_code' },
    { name: 'Country of manufacture', id: 'country_of_manufacture' },
  ]

  const otherUnitsRows = [
    { name: 'Unit', id: 'unit_type' },
    { name: 'SKU', id: 'sku' },
    { name: 'Barcode', id: 'barcode' },
    { name: 'Child items per unit: ', id: 'items_per_unit' }
  ]

  const dimensionRows = [
    { name: 'H', id: 'height', suffix: 'm' },
    { name: 'L', id: 'length', suffix: 'm' },
    { name: 'W', id: 'width', suffix: 'm' },
  ]

  const costsRows = [
    { name: 'Supplier Cost', id: 'supplier_cost', prefix: '$AUD' },
    { name: 'Retail Cost', id: 'retail_cost', prefix: '$AUD' },
    // { name: 'Wholesale', id: 'wholesale_cost', prefix: '$' },
    // { name: 'Shipping Value', id: 'shipping_value', prefix: '$' },
    { name: 'Low stock', id: 'low_stock_alert' },
  ]

  return (
    <Grid container spacing={3}>
      <>
        {type === 'basic' ?
          <>
            <Grid item xs={12} sm={2} >
              <Card className={classes.image2}>
                <img
                  alt="item"
                  className={classes.image}
                  src={item.preview}
                />
                <Typography variant='subtitle2' color='error'>{error && error['image'] ? error.response.data['image'] : null}</Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4} >
              <Card className={classes.basic} >
                <Table>
                  <TableBody>
                    {infoRows.map(row =>
                      <CustomRow row={row} key={`${index}_${row.name}`} />
                    )}
                    <>
                      <TableRow >
                        <TableCell>Category: </TableCell>
                        <TableCell>
                          {category ? category.title : '-'}
                          <Typography variant='subtitle2' color='error'>{(error && error.response) ? error.response.data['category_id'] : null}</Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  </TableBody>
                </Table>

              </Card>
            </Grid>
          </>
          :
          <>

            <Grid item xs={false} sm={2} >
            </Grid>
            <Grid item xs={12} sm={4} >
              <Card className={classes.basic} >
                <Table>
                  <TableBody>
                    {otherUnitsRows.map(row =>
                      <CustomRow row={row} key={`${index}_${row.name}`} />
                    )}
                    {index > 0 ? <TableRow ><TableCell>BASE items per unit: </TableCell><TableCell>{item.unitsOfLowerPackaging}</TableCell></TableRow> : null}
                  </TableBody>
                </Table>
              </Card>
            </Grid>
          </>
        }
        <Grid item xs={12} sm={3} >
          <Card className={classes.basic}>
            <Table>
              <TableBody>
                {dimensionRows.map(row =>
                  <CustomRow row={row} key={`${index}_${row.name}`} />
                )}
                <TableRow><TableCell>Cubic: {(item.height * item.length * item.width).toFixed(4)} m3</TableCell></TableRow>
                <TableRow><TableCell>Weight: {item.weight} kg</TableCell></TableRow>
              </TableBody>
            </Table>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3} >
          <Card className={classes.basic} >
            <Table>
              <TableBody>
                {costsRows.map(row =>
                  <CustomRow row={row} key={`${index}_${row.name}`} />
                )}
              </TableBody>
            </Table>
          </Card>
        </Grid>
      </>
    </Grid>
  )
}

export default withStyles(styles)(SummaryCard);



