// React Components (OSS)
import React, { PureComponent } from 'react';
import { Route } from 'react-router';

import { connect } from 'react-redux';
import { fetchOrdersSuccess, resetOrders } from 'actions/orderActions';
import { fetchItems } from 'actions/spaceActions';
import { fetchBundles } from 'actions/bundleActions';
import { hasFeature } from 'actions/featureFlagActions';

import OrdersIndexAll from 'views/user/Orders/OrdersIndexAll';
import OrdersAdd from 'views/user/Orders/OrdersAdd';
import {
	AddNewInbound,
	AddNewOutbound,
	EditInbound,
	EditOutbound,
} from './OrderForm';
import OrdersDetail from 'views/user/Orders/OrdersDetail/OrdersDetail';
import Loader from 'layouts/AdminLayout/components/Loader';
import keepspaceApi from 'services/keepspaceApi';

import { applyOrderInterface, applyOrdersCountryListInterface } from 'Interfaces';
// @Material-UI Components (OSS)
import { withStyles } from '@material-ui/core/styles';
import SuccessSnackbar from 'components/Help/SuccessSnackbar';
import {
	LinearProgress,
	CircularProgress,
	Paper,
	Typography,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

// Licensed Components

const styles = (theme) => ({
	root: {
		padding: theme.spacing(4),
	},
	flexGrow: {
		flexGrow: 1,
	},
	newOutboundButton: {
		margin: theme.spacing(1),
	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
	loadingProgressPanel: {
		backgroundColor: 'white',
		minHeight: 200,
		minWidth: 400,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		borderRadius: 2,
	},
	loadingProgressLine: {
		display: 'flex',
		flexDirection: 'row',
		padding: theme.spacing(1),
	},
	progress: {
		borderRadius: '0px',
	},
});

export class Orders extends PureComponent {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.state = {
			searchValue: '',
			open: false,
			countries: props.countries || [],
			statuses: [],
			warehouses: [],
			inOuts: [
				{ value: 'inbound', text: 'Inbound', isChecked: true },
				{ value: 'outbound', text: 'Outbound', isChecked: true },
			],
			startDate: '',
			endDate: '',
			processedStartDate: '',
			processedEndDate: '',
			total: 0,
			maxPages: 0,
			page: 1,
			perPage: 25,
			items: [],
			orders: props.orders || [],
			loading: false,
			error: '',
			newOrderInProgress: false,
			updateSuccess: false,
			pagination: {},
			searchTerm: '',
			//user prefs
			saveFilter: false,
		};
		this.handleClose = this.handleClose.bind(this);
		this.ordersDoneLoading = this.ordersDoneLoading.bind(this);
		this.itemsDoneLoading = this.itemsDoneLoading.bind(this);
		this.allDoneLoading = this.allDoneLoading.bind(this);
		this.statusClick = this.statusClick.bind(this);
		this.handleClearFilters = this.handleClearFilters.bind(this);
		this.sortKeyClick = this.sortKeyClick.bind(this);
		this.fetchOrdersProcedure = this.fetchOrdersProcedure.bind(this);
		this.updateState = this.updateState.bind(this);
		this.updateCountries = this.updateCountries.bind(this);
	}

	componentDidMount() {
		this._isMounted = true;

		this.props.dispatch(fetchItems());

		if (this.props.bundles && this.props.bundles.length === 0) {
			this.props.dispatch(fetchBundles());
		}

		let userPref = localStorage.getItem('userPref');
		const UID = window.localStorage.getItem('uid');
		if (userPref && userPref !== 'null') {
			const filterPrefs = JSON.parse(userPref).filterPref;
			const UIDPref = JSON.parse(userPref).uid;

			if (UID == UIDPref && filterPrefs) {
				let newFilters = filterPrefs.inOuts;
				this.updateState('inOuts', newFilters);

				let newStatuses = filterPrefs.statuses;
				this.updateState('statuses', newStatuses, true);
			} else {
				userPref = {
					uid: UID,
				};
				window.localStorage.setItem('userPref', JSON.stringify(userPref));
				this.fetchOrdersProcedure();
			}
		} else {
			userPref = {
				filterPref: {
					inOuts: this.state.inOuts,
					statuses: this.state.statuses,
				},
				uid: UID,
			};

			window.localStorage.setItem('userPref', JSON.stringify(userPref));
			this.fetchOrdersProcedure();
		}

		if (!this.props.loading && !userPref) {
			this.fetchOrdersProcedure();
		}
	}

	componentWillReceiveProps(nextProps) {
		if (Boolean(this.props.error) || Boolean(nextProps.error)) {
			this.setState({ open: true });
		}
		let diff =
			this.state.orders.filter((x) => !nextProps.orders.includes(x)) || [];
		if (diff.length || nextProps.orders.length !== this.state.orders.length) {
			this.setState({ orders: nextProps.orders });
		}
		if (this.state.countries.length !== this.props.countries.length) {
			this.updateCountries(this.props.countries);
		}
	}

	componentWillUpdate(nextProps, nextState) {
		if (nextProps.orders.length !== this.state.orders.length) {
			this.fetchOrdersProcedure();
		}
  }

	componentWillUnmount() {
		this._isMounted = false;

		this.props.dispatch(resetOrders());
	}

	handleClose() {
		this.setState({ open: false });
	}

	updateState(name, value, loadData) {
		this.setState({page: 1})
		this.setState({ [name]: value }, () => {
			if (loadData && !this.state.loading) {
				this.fetchOrdersProcedure();
			}
		});
	}

	ordersDoneLoading() {
		if (this.props.orders && this.props.orders.length === this.props.total) {
			return true;
		} else {
			return false;
		}
	}

	itemsDoneLoading() {
		if (this.props.items && this.props.items.length > 0) {
			return true;
		} else {
			return false;
		}
	}

	allDoneLoading() {
		if (this.itemsDoneLoading() && this.ordersDoneLoading()) {
			return true;
		} else {
			return false;
		}
	}

	statusClick(value, isChecked) {
		let newStatuses = this.state.statuses.map((status) => ({
			...status,
			isChecked: status.value === value ? isChecked : status.isChecked,
		}));

		// GET FILTERED DATA FROM SERVER

		this.updateState('statuses', newStatuses, true);

		this.updateState('saveFilter', true);
	}

	sortKeyClick(key, value, isChecked) {
		let newFilters = this.state[key].map((inOut) => ({
			...inOut,
			isChecked: inOut.value === value ? isChecked : inOut.isChecked,
		}));

		// GET FILTERED DATA FROM SERVER
		this.updateState('inOuts', newFilters, true);

		this.updateState('saveFilter', true);
	}

	handleClearFilters() {
		this.updateState('processedStartDate', null);
		this.updateState('processedEndDate', null);
		this.updateState('startDate', null);
		this.updateState('endDate', null);
		this.updateState('processedStartDateNew', null);
		this.updateState('processedEndDateNew', null);
		this.updateState('startDateNew', null);
		this.updateState('endDateNew', null);

		let newFilters = this.state.inOuts.map((inOut) => ({
			...inOut,
			isChecked: true,
		}));
		this.updateState('inOuts', newFilters);

		let newStatuses = this.state.statuses.map((status) => ({
			...status,
			isChecked: true,
		}));
		this.updateState('statuses', newStatuses, true);
	}

	updateCountries(data) {
		this.setState({ countries: data });
	}

	fetchOrdersProcedure() {
    let apiUrl = "";
    let {perPage, page, inOuts, statuses, startDateNew, endDateNew, processedStartDateNew, processedEndDateNew, searchTerm} = this.state;

    this.setState({
      loading: true
    });

    apiUrl = `per_page=${perPage}&page=${page}`;

    if (inOuts.length) {
      let filtered = inOuts.filter(obj => obj.isChecked == true);
      let r = [], orderLink = "";
      let res = filtered.map(function (o) {
        r.push(o.value);
        return r;
      });
      for (const element of r) {
        orderLink = orderLink + `&order_types[]=${element}`;
      }
      apiUrl = apiUrl + orderLink;

    }

    if (statuses.length) {
      let filtered1 = statuses.filter(obj => obj.isChecked == true);
      let r = [], statusLink = "";
      let res = filtered1.map(function (o) {

        r.push(o.value);
        return r;
      });
      for (const element of r) {
        statusLink = statusLink + `&statuses[]=${element}`;
      }
      apiUrl = apiUrl + statusLink;
    }
    if (startDateNew) {
      apiUrl = apiUrl + `&created_from_date=${startDateNew}`;
    }
    if (endDateNew) {
      apiUrl = apiUrl + `&created_to_date=${endDateNew}`;
    }
    if (processedStartDateNew) {
      apiUrl = apiUrl + `&processed_from_date=${processedStartDateNew}`;
    }
    if (processedEndDateNew) {
      apiUrl = apiUrl + `&processed_to_date=${processedEndDateNew}`;
    }

    if (searchTerm) {
      apiUrl = apiUrl + `&user_search_order_summary=${searchTerm}`;
    }

    keepspaceApi.getOrders(apiUrl)
      .then(json => {
        if (json.success === true) {

          let data = json.data;
          let statuses = new Set(this.state.statuses);
          let warehouses = new Set(this.state.warehouses);

          let orders = (data.bookings) ? data.bookings.map(order => ({
            ...applyOrderInterface(order)
          })) : [];

          if (!this.state.statuses.length) {
            //get status from payload only first time
            statuses = (data.filters) ? [...data.filters.statuses] : [];

            statuses = statuses.map(v => ({...v, isChecked: true}));
          } else {
            statuses = [...statuses];
          }

          this.setState({
            loading: false,
            orders: orders,
            statuses: statuses,
            warehouses: data?.filters?.warehouses || [],
            perPage: (data.pagination) ? parseInt(data.pagination.per_page) : 25,
            page: data.pagination ? parseInt(data.pagination.current_page) : 1,
            total: data.pagination ? parseInt(data.pagination.total_count) : 0,
            maxPages: data.pagination ? parseInt(data.pagination.total_pages) : 1,
            pagination: data.pagination
          });

          this.props.dispatch(fetchOrdersSuccess(json));
        } else {
          this.setState({
            loading: false,
            error: "Unspecified Error",
          })
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          error: error
            || "Unspecified Error",
        })
      })

  }

	render() {
		const { classes, searchTerm, ...rest } = this.props;

		return (
			<>
				{this.state.loading ? <Loader /> : null}
				<div className={classes.root}>
					<SuccessSnackbar
						open={Boolean(this.props.error)}
						error={this.props.error ? this.props.error.message : null}
						onClose={this.handleClose}
					/>
					<>
						{hasFeature(this.props.features, 'show_order_loading_panel') ? (
							this.allDoneLoading() ? null : (
								<Paper className={classes.loadingProgressPanel}>
									{this.ordersDoneLoading() ? (
										<div className={classes.loadingProgressLine}>
											<Typography>Orders Done </Typography>{' '}
											<DoneIcon style={{ fontSize: 16 }} />
										</div>
									) : (
										<>
											<div className={classes.loadingProgressLine}>
												<Typography>Orders Loading... </Typography>
												<CircularProgress size={16} />
											</div>
										</>
									)}
									<LinearProgress value={78} variant='determinate' />
									{this.itemsDoneLoading() ? (
										<div className={classes.loadingProgressLine}>
											<Typography>Items Done</Typography>{' '}
											<DoneIcon style={{ fontSize: 16 }} />
										</div>
									) : (
										<div className={classes.loadingProgressLine}>
											<Typography>Items Loading...</Typography>
											<CircularProgress size={16} />
										</div>
									)}
								</Paper>
							)
						) : null}
					</>

					<Route
						path='/user/:uid/orders/summary'
						exact
						render={(renderProps) => (
							<OrdersIndexAll
								data={this.state.orders}
								history={this.props.history}
								newOrderInProgress={this.props.newOrderInProgress}
								updateSuccess={this.state.updateSuccess}
								statusChange={this.statusChange}
								statusClick={this.statusClick}
								sortKeyClick={this.sortKeyClick}
								handleClearFilters={this.handleClearFilters}
								fetchOrdersProcedure={this.fetchOrdersProcedure}
								updateState={this.updateState}
								{...this.state}
							/>
						)}
					/>

					<Route
						path='/user/:uid/orders/:string/:id/edit_inbound'
						exact={true}
						render={(routeProps) => (
							<EditInbound
								{...routeProps}
								countries={this.state.countries}
								warehouses={this.state.warehouses}
								orders={this.props.orders}
								updateCountries={this.updateCountries}
							/>
						)}
					/>
					<Route
						path='/user/:uid/orders/:string/:id/edit_outbound'
						exact={true}
						render={(routeProps) => (
							<EditOutbound
								{...routeProps}
								warehouses={this.state.warehouses}
								countries={this.state.countries}
								orders={this.props.orders}
								updateCountries={this.updateCountries}
							/>
						)}
					/>
					<Route
						path='/user/:uid/orders/:string/:id/details'
						exact={true}
						render={(routeProps) => (
							<OrdersDetail
								{...routeProps}
								countries={this.state.countries}
								orders={this.props.orders}
							/>
						)}
					/>
					<Route
						path='/user/:uid/orders/:string/:id/details/:tab'
						exact={true}
						render={(routeProps) => (
							<OrdersDetail
								{...routeProps}
								countries={this.state.countries}
								orders={this.props.orders}
							/>
						)}
					/>

					<Route
						path='/user/:uid/orders/:string/add/'
						/**Going to be deprecated */
						exact
						render={(routeProps) => (
							<OrdersAdd {...routeProps} {...this.props} />
						)}
					/>

					<Route
						path='/user/:uid/orders/:string/create_outbound'
						exact
						render={() => (
							<AddNewOutbound
								warehouses={this.state.warehouses}
								countries={this.state.countries}
								updateCountries={this.updateCountries}
							/>
						)}
					/>

					<Route
						path='/user/:uid/orders/:string/create_inbound'
						exact
						render={() => (
							<AddNewInbound
								warehouses={this.state.warehouses}
								countries={this.state.countries}
								updateCountries={this.updateCountries}
							/>
						)}
					/>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	...ownProps,
	features: state.featureFlagReducer.featureFlags,
	items: state.spaceReducer.items,
	bundles: state.bundlesReducer.bundles,
	newOrderInProgress: state.orderReducer.newOrderInProgress,
	orders: state.orderReducer.orders,
	loading: state.orderReducer.loading,
	countries: applyOrdersCountryListInterface(state.adminAuthReducer.countries),
});

function search(searchTerm, stringToProcess) {
	let stp = stringToProcess.toLowerCase();
	let st = searchTerm.toLowerCase();
	return stp.includes(st);
}

export default connect(mapStateToProps)(withStyles(styles)(Orders));
