import React from 'react'
import PropTypes from 'prop-types';

import KeepSpaceLogo from 'assets/img/Keepspace Logo big.png'

export default function KeepSpaceIcon(props) {
    return (
        <div style={{
            height: props.height,
            width: props.width
        }}>
            <img
                style={{
                    height: 'inherit',
                    width: 'inherit'
                }}
                alt="Under development"
                src={KeepSpaceLogo}
            />
        </div>
    )
}

KeepSpaceIcon.defaultProps = {
    height: 64,
    width: 64
}

KeepSpaceIcon.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number
}