import { apiPath, authHeaders } from 'AppConstants';
import adminApi from 'services/admin/adminApi';

export const FETCH_BUNDLES_BEGIN = 'FETCH_BUNDLES_BEGIN';
export const FETCH_BUNDLES_SUCCESS = 'FETCH_BUNDLES_SUCCESS';
export const FETCH_BUNDLES_FILTERS_SUCCESS = 'FETCH_BUNDLES_FILTERS_SUCCESS';
export const FETCH_BUNDLES_FAILURE = 'FETCH_BUNDLES_FAILURE'

export const SEARCH_BUNDLES_BEGIN = 'SEARCH_BUNDLES_BEGIN'
export const SEARCH_BUNDLES_SUCCESS = 'SEARCH_BUNDLES_SUCCESS'
export const SEARCH_BUNDLES_FAILURE = 'SEARCH_BUNDLES_FAILURE'

export const SET_SEARCH_TERM = 'SET_SEARCH_TERM'
export const SET_SEARCH_ACTIVE = 'SET_SEARCH_ACTIVE'

export const CLEAR_BUNDLESS = 'CLEAR_BUNDLESS'

export const CREATE_BUNDLES_BEGIN = 'CREATE_BUNDLES_BEGIN';
export const CREATE_BUNDLES_SUCCESS = 'CREATE_BUNDLES_SUCCESS';
export const CREATE_BUNDLES_FAILURE = 'CREATE_BUNDLES_FAILURE'

export const UPDATE_BUNDLES_BEGIN = 'UPDATE_BUNDLES_BEGIN';
export const UPDATE_BUNDLES_SUCCESS = 'UPDATE_BUNDLES_SUCCESS';
export const UPDATE_BUNDLES_FAILURE = 'UPDATE_BUNDLES_FAILURE'
export const SET_MULTIPLE_BUNDLES_ERROR = 'SET_MULTIPLE_BUNDLES_ERROR'
export const SET_BUNDLES_FILTERS = 'SET_BUNDLES_FILTERS'

export const FETCH_BUNDLES_BY_ID_BEGIN = 'FETCH_BUNDLES_BY_ID_BEGIN';
export const FETCH_BUNDLES_BY_ID_SUCCESS = 'FETCH_BUNDLES_BY_ID_SUCCESS';
export const FETCH_BUNDLES_BY_ID_FAILURE = 'FETCH_BUNDLES_BY_ID_FAILURE';

export const ARCHIVE_BUNDLE_BEGIN = 'ARCHIVE_BUNDLE_BEGIN';
export const ARCHIVE_BUNDLE_SUCCESS = 'ARCHIVE_BUNDLE_SUCCESS';
export const ARCHIVE_BUNDLE_FAILURE = 'ARCHIVE_BUNDLE_FAILURE';

export const UNARCHIVE_BUNDLE_BEGIN = "UNARCHIVE_BUNDLE_BEGIN";
export const UNARCHIVE_BUNDLE_SUCCESS = "UNARCHIVE_BUNDLE_SUCCESS";
export const UNARCHIVE_BUNDLE_FAILURE = "UNARCHIVE_BUNDLE_FAILURE";

export const CLEAR_BUNDLES_NOTIFICATION = 'CLEAR_BUNDLES_NOTIFICATION';

// Get Bundles for Table


export function fetchBundles(params) {
    return dispatch => {
        dispatch(fetchBundlesBegin());
        return adminApi.fetchBundles(params)
            .then(json => {
                dispatch(fetchBundlesSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(fetchBundlesFailure(error))

            });
    };
}
export function fetchBundlesFilters(params) {
    return dispatch => {
        dispatch(fetchBundlesBegin());
        return adminApi.fetchBundlesFilters(params)
            .then(json => {
                dispatch(fetchBundlesFiltersSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(fetchBundlesFailure(error))

            });
    };
}

export function archiveBundle(id) {
    return dispatch => {
        dispatch(archiveBundleBegin());
        return adminApi.deleteBundle(id)
            .then(json => {
                dispatch(archiveBundleSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(archiveBundleFailure(error))

            });
    };
}

export const archiveBundleBegin = () => ({
    type: ARCHIVE_BUNDLE_BEGIN
});

export const archiveBundleSuccess = item => ({
    type: ARCHIVE_BUNDLE_SUCCESS, 
    payload : {...item?.data}
});

export const archiveBundleFailure = error => ({
    type: ARCHIVE_BUNDLE_FAILURE,
    payload: { error: (error.response) ? error.response.data : 'Something went wrong. Please try again' }
});

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export const fetchBundlesBegin = () => ({
    type: FETCH_BUNDLES_BEGIN
});

export const fetchBundlesSuccess = json => ({
    type: FETCH_BUNDLES_SUCCESS,
    payload: {
        products: json.data.products,
        pagination: json.data.pagination,
        filters: json.data.filters
    }
});
export const fetchBundlesFiltersSuccess = json => ({
    type: FETCH_BUNDLES_FILTERS_SUCCESS,
    payload: {
        filters: json.data.filters
    }
});

export const fetchBundlesFailure = error => ({
    type: FETCH_BUNDLES_FAILURE,
    payload: { error: (error.response) ? error.response.data : 'Something went wrong. Please try again' }
});


// Filtering & Searching the Table

export const setBundlesFilters = (params) => ({
    type: SET_BUNDLES_FILTERS,
    data: params
})

//Get Bundles details

export function fetchBundleById(id) {
    return dispatch => {
        dispatch(fetchBundleByIDBegin());
        return adminApi.fetchBundleById(id)
            .then(json => {
                dispatch(fetchBundleByIDSuccess(json.data));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(fetchBundleByIDFailure(error))
            });
    }
}

export const fetchBundleByIDBegin = () => ({
    type: FETCH_BUNDLES_BY_ID_BEGIN
});

export const fetchBundleByIDSuccess = item => ({
    type: FETCH_BUNDLES_BY_ID_SUCCESS,
    payload: { item }
});

export const fetchBundleByIDFailure = error => ({
    type: FETCH_BUNDLES_BY_ID_FAILURE,
    payload: { error: (error.response) ? error.response.data : 'Something went wrong. Please try again' }
});

// Add new Bundles

export const createBundleBegin = () => ({
    type: CREATE_BUNDLES_BEGIN
});

export const createBundlesSuccess = json => ({
    type: CREATE_BUNDLES_SUCCESS,
    payload: json.data
});

export const createBundleFailure = error => ({
    type: CREATE_BUNDLES_FAILURE,
    payload: { error: (error.response) ? error.response.data : 'Something went wrong. Please try again' }
});


export function createBundle(formData) {
    return dispatch => {
        dispatch(createBundleBegin());
        return adminApi.createBundle(formData)
            .then(json => {
                dispatch(createBundlesSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(createBundleFailure(error))
            });
    }
}

export function updateBundle(formData, id) {
    return dispatch => {
        dispatch(updateBundleBegin());
        return adminApi.updateBundleById(formData, id)
            .then(json => {
                dispatch(updateBundlesSuccess(json.data));
                return json;
            })
            .catch(error => { dispatch(updateBundleFailure(error)) });
    }
}


export const updateBundleBegin = () => ({
    type: UPDATE_BUNDLES_BEGIN,
})


export const updateBundlesSuccess = (item) => ({
    type: UPDATE_BUNDLES_SUCCESS,
    item: item.bundle
})

export const updateBundleFailure = error => ({
    type: UPDATE_BUNDLES_FAILURE,
    payload: { error: (error.response) ? error.response.data : 'Something went wrong. Please try again' }
});


export const clearBundles = () => ({
    type: CLEAR_BUNDLESS
});

export const clearBundlesNotification = () => ({
    type: CLEAR_BUNDLES_NOTIFICATION
})

export const setMultipleBundlesError = error => ({
    type: SET_MULTIPLE_BUNDLES_ERROR,
    payload: { error: (error.response) ? error.response.data : 'Something went wrong. Please try again' }
});

export function unarchiveBundle(id) {
    return (dispatch) => {
        dispatch(unarchiveBundleBegin());
        return adminApi.unarchiveBundle(id)
            .then((json) => {
                dispatch(unarchiveBundleSuccess(json));
                return json;
            })
            .catch((error) => {
                dispatch(unarchiveBundleFailure(error));
            });
    };
}

export const unarchiveBundleBegin = () => ({
    type: UNARCHIVE_BUNDLE_BEGIN,
});

export const unarchiveBundleSuccess = (json) => ({
    type: UNARCHIVE_BUNDLE_SUCCESS,
    payload: json.data,
});

export const unarchiveBundleFailure = (error) => ({
    type: UNARCHIVE_BUNDLE_FAILURE,
    payload: {
        error: error.response
            ? error.response.data
            : "Something went wrong. Please try again",
    },
});