import { generateAuthActions } from 'redux-token-auth';
import { authUrl } from './AppConstants';


const config = {
    authUrl,
    userAttributes: {
        id: 'id',
        provider: 'provider',
        active: 'active',
        address: 'address',
        phone: 'phone',
        email: 'email',
        termsOfService: 'terms_of_service',
        firstName: 'first_name',
        lastName: 'last_name',
        avatar: 'avatar'
    },
    userRegistrationAttributes: {
        email: 'email',
        termsOfService: 'terms_of_service',
        firstName: 'first_name',
        lastName: 'last_name',
        organisationsAttributes: 'organisations_attributes'
    },
    storage: window.localStorage
}

const {
    registerUser,
    signInUser,
    signOutUser,
    verifyCredentials,
    resetPassword,
    resetPasswordTempSignin,
    changePassword
} = generateAuthActions(config)

export {
    registerUser,
    signInUser,
    signOutUser,
    verifyCredentials,
    resetPassword,
    resetPasswordTempSignin,
    changePassword,
}
