import {
  FETCH_SKU_BEGIN,
  FETCH_SKU_SUCCESS,
  FETCH_SKU_FILTERS_SUCCESS,
  FETCH_SKU_FAILURE,
  SET_SKU_FILTERS,
  CLEAR_SKUS,
  FETCH_SKU_BY_ID_BEGIN,
  FETCH_SKU_BY_ID_SUCCESS,
  FETCH_SKU_BY_ID_FAILURE,
  FETCH_MOVEMENT_BEGIN,
  FETCH_MOVEMENT_SUCCESS,
  FETCH_MOVEMENT_FAILURE,
  FETCH_TYPES_BEGIN,
  FETCH_TYPES_SUCCESS,
  FETCH_TYPES_FAILURE,
  FETCH_SKU_ORDERS_BEGIN,
  FETCH_SKU_ORDERS_SUCCESS,
  FETCH_SKU_ORDERS_FAILURE,
  DELETE_SKU_BEGIN,
  DELETE_SKU_SUCCESS,
  DELETE_SKU_FAILURE,
  UNARCHIVE_SKU_BEGIN,
  UNARCHIVE_SKU_SUCCESS,
  UNARCHIVE_SKU_FAILURE,
  UPDATE_SKU_SUCCESS,
  UPDATE_SKU_BEGIN,
  UPDATE_SKU_FAILURE,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_LOCATIONS_FAILURE,
  FETCH_LOCATIONS_BEGIN,
  CLEAR_LOCATIONS,
  FETCH_LOCATIONS_BY_BARCODE_SUCCESS,
  FETCH_LOCATIONS_BY_BARCODE_FAILURE,
  DELETE_SKU_TYPE_BEGIN,
  DELETE_SKU_TYPE_SUCCESS,
  DELETE_SKU_TYPE_FAILURE,
  BREAKDOWN_SKU_TYPE_BEGIN,
  BREAKDOWN_SKU_TYPE_SUCCESS,
  BREAKDOWN_SKU_TYPE_FAILURE,
  GET_PARENT_SKU_BEGIN,
  GET_PARENT_SKU_SUCCESS,
  GET_PARENT_SKU_FAILURE,
  CREATE_SKU_TYPE_BEGIN,
  CREATE_SKU_TYPE_SUCCESS,
  CREATE_SKU_TYPE_FAILURE,
  UPDATE_SKU_TYPE_BEGIN,
  UPDATE_SKU_TYPE_SUCCESS,
  UPDATE_SKU_TYPE_FAILURE,
  CREATE_SKU_BEGIN,
  CREATE_SKU_SUCCESS,
  CREATE_SKU_FAILURE,
  PRINT_BARCODES_SKUS_BEGIN,
  PRINT_BARCODES_SKUS_SUCCESS,
  PRINT_BARCODES_SKUS_FAILURE,
  SEARCH_SKU_SUGGESTION_BEGIN,
  SEARCH_SKU_SUGGESTION_SUCCESS,
  SEARCH_SKU_SUGGESTION_FAILURE,
  FILTER_SKU_SUGGESTION_BEGIN,
  FILTER_SKU_SUGGESTION_SUCCESS,
  FILTER_SKU_SUGGESTION_FAILURE,
  CLEAR_SKU_TMPLS,
  ADD_SKU_STOCK_BEGIN,
  ADD_SKU_STOCK_SUCCESS,
  ADD_SKU_STOCK_FAILURE,
  STOCK_CHECK_BEGIN,
  STOCK_CHECK_SUCCESS,
  STOCK_CHECK_FAILURE,
  FETCH_ITEM_HISTORY_SUCCESS,
  CHANGE_SKU_TYPE_SUCCESS,
  CHANGE_SKU_TYPE_FAILURE,
} from "actions/admin/skuActions";

import {
  FETCH_ORG_SKU_BEGIN,
  FETCH_ORG_SKU_SUCCESS,
  FETCH_ORG_SKU_FAILURE,
} from "actions/admin/OrganisationsActions";

import { applySKUInterface } from "Interfaces";
import { AuthActions } from "actions/admin/actionTypes";

const initialState = {
  items: [],
  fetchedItem: {},
  loading: false,
  uploading: false,
  updating: false,
  formSuccess: false,
  error: null,
  notification: null,
  multipleSkuError: null,
  barcode: null,
  activeFilters: {
    company_ids: [],
    sku_type: "",
    sku: "",
    sku_barcode: "",
  },
  movementsFilter: {
    page: 1,
    per_page: 25,
  },
  pagination: {},
  ordersPagination: {},
  filter: {},
  movements: {},
  movementsPagination: {},
  types: [],
  orders: [],
  ordersItem: {},
  locations: [],
  typeObj: {},
  filename: "",
  itemNamesList: [],
  formLoading: false,
};

export default function skuReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SKU_BEGIN:
      return {
        ...state,
        loading: true,
        formSuccess: false,
        error: null,
        notification: null,
        items: [],
        pagination: {},
      };

    case FETCH_SKU_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.products.map((item) => ({
          ...applySKUInterface(item),
        })),
        pagination: action.payload.pagination,
        filters: action.payload.filters,
      };

    case FETCH_SKU_FILTERS_SUCCESS:
      return {
        filters: action.payload.filters,
      };

    case FETCH_SKU_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };

    case FETCH_ORG_SKU_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        notification: null,
      };

    case FETCH_ORG_SKU_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.products.map((item) => ({
          ...applySKUInterface(item),
        })),
        pagination: action.payload.pagination,
        filters: action.payload.filters,
        organisation: action.payload.organisation,
      };

    case FETCH_ORG_SKU_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };

    case SET_SKU_FILTERS:
      return {
        ...state,
        activeFilters: action.data,
      };

    case CLEAR_SKUS:
      return {
        ...state,
        ...initialState,
      };

    case FETCH_SKU_BY_ID_BEGIN:
      return {
        ...state,
        loading: true,
        formSuccess: false,
        error: null,
        notification: null,
      };

    case FETCH_SKU_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchedItem: action.payload.item,
        formSuccess: false,
        filters: action.payload.item.filters,
      };

    case FETCH_SKU_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    case FETCH_MOVEMENT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        notification: null,
      };

    case FETCH_MOVEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        movements: action.payload.movements,
        movementsFilter: action.payload.movementsFilter,
        movementsPagination: action.payload.pagination,
      };

    case FETCH_MOVEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        movements: {},
      };

    case FETCH_TYPES_BEGIN:
      return {
        ...state,
        loading: true,
        formSuccess: false,
        error: null,
        notification: null,
      };
    case FETCH_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        formSuccess: false,
        item: action.item,
        types: action.payload,
      };

    case FETCH_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        types: [],
      };
    case FETCH_SKU_ORDERS_BEGIN:
      return {
        ...state,
        loading: true,
        formSuccess: false,
        error: null,
        notification: null,
        ordersPagination: {},
      };
    case FETCH_SKU_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        formSuccess: false,
        ordersItem: action.item,
        orders: action.payload,
        ordersPagination: action.pagination,
      };

    case FETCH_SKU_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        orders: [],
        ordersPagination: {},
      };
    case DELETE_SKU_TYPE_BEGIN:
      return {
        ...state,
        updating: true,
        formSuccess: false,
        error: null,
        notification: null,
      };

    case DELETE_SKU_TYPE_SUCCESS:
      return {
        ...state,
        updating: false,
        formSuccess: true,
      };

    case DELETE_SKU_TYPE_FAILURE:
      return {
        ...state,
        updating: false,
        formSuccess: false,
        error: action.payload.error,
      };

    case BREAKDOWN_SKU_TYPE_BEGIN:
      return {
        ...state,
        updating: true,
        formSuccess: false,
        error: null,
        notification: null,
      };
    case BREAKDOWN_SKU_TYPE_SUCCESS:
      return {
        ...state,
        updating: false,
        formSuccess: true,
        error: null,
      };
    case BREAKDOWN_SKU_TYPE_FAILURE:
      return {
        ...state,
        updating: false,
        formSuccess: false,
        error: action.payload.error,
      };
    case GET_PARENT_SKU_BEGIN:
      return {
        ...state,
        updating: true,
        formSuccess: false,
        error: null,
        notification: null,
      };
    case GET_PARENT_SKU_SUCCESS:
      return {
        ...state,
        updating: false,
        typeObj: action.payload.item,
        error: null,
      };
    case GET_PARENT_SKU_FAILURE:
      return {
        ...state,
        updating: false,
        error: action.payload.error,
      };
    case CREATE_SKU_TYPE_BEGIN:
      return {
        ...state,
        updating: true,
        formSuccess: false,
        error: null,
        notification: null,
      };
    case CREATE_SKU_TYPE_SUCCESS:
      return {
        ...state,
        updating: false,
        typeObj: action.payload.item,
        formSuccess: true,
      };
    case CREATE_SKU_TYPE_FAILURE:
      return {
        ...state,
        updating: false,
        formSuccess: false,
        error: action.payload.error,
      };

    case UPDATE_SKU_TYPE_BEGIN:
      return {
        ...state,
        updating: true,
        error: null,
        notification: null,
        formSuccess: false,
      };
    case UPDATE_SKU_TYPE_SUCCESS:
      return {
        ...state,
        updating: false,
        formSuccess: true,
      };
    case UPDATE_SKU_TYPE_FAILURE:
      return {
        ...state,
        updating: false,
        formSuccess: false,
        error: action.payload.error,
      };
    case DELETE_SKU_BEGIN:
      return {
        ...state,
        updating: true,
        formSuccess: false,
        error: null,
        notification: null,
      };
    case DELETE_SKU_SUCCESS:
      return {
        ...state,
        updating: false,
        formSuccess: true,
        fetchedItem: {
          ...action.payload.item,
          is_archived: true,
        },
      };
    case DELETE_SKU_FAILURE:
      return {
        ...state,
        updating: false,
        formSuccess: false,
        error: action.payload.error,
      };

    case UNARCHIVE_SKU_BEGIN:
      return {
        ...state,
        updating: true,
        formSuccess: false,
        notification: null,
        error: null,
      };

    case UNARCHIVE_SKU_SUCCESS:
      return {
        ...state,
        updating: false,
        formSuccess: true,
        notification: "This item has been unarchived successfully.",
        fetchedItem: {
          ...state.fetchedItem,
          item: action.payload.item,
        },
      };

    case UNARCHIVE_SKU_FAILURE:
      return {
        ...state,
        updating: false,
        formSuccess: false,
        error: action.payload.error,
      };

    case CREATE_SKU_BEGIN:
      return {
        ...state,
        updating: true,
        formSuccess: false,
        error: null,
        notification: null,
      };

    case CREATE_SKU_SUCCESS:
      return {
        ...state,
        updating: false,
        formSuccess: true,
        error: null,
        fetchedItem: {
          ...state.fetchedItem,
          item: action.data,
        },
      };

    case CREATE_SKU_FAILURE:
      return {
        ...state,
        updating: false,
        formSuccess: false,
        error: action.payload.error,
      };

    case UPDATE_SKU_BEGIN:
      return {
        ...state,
        updating: true,
        formSuccess: false,
        error: null,
        notification: null,
      };

    case UPDATE_SKU_SUCCESS:
      return {
        ...state,
        updating: false,
        formSuccess: true,
        error: null,
        fetchedItem: {
          ...state.fetchedItem,
          item: action.data,
        },
      };

    case UPDATE_SKU_FAILURE:
      return {
        ...state,
        updating: false,
        formSuccess: false,
        error: action.payload.error,
      };
    case FETCH_LOCATIONS_BEGIN:
      return {
        ...state,
        loading: false,
        locations: [],
        notification: null,
      };
    case CLEAR_LOCATIONS:
      return {
        ...state,
        loading: false,
        locations: [],
        notification: null,
      };
    case FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        locations: action.payload.data,
        notification: null,
      };

    case FETCH_LOCATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        locations: [],
        notification: null,
      };

    case FETCH_LOCATIONS_BY_BARCODE_SUCCESS:
      let locations = action.payload.data;
      return {
        ...state,
        loading: false,
        notification: "Location found!",
        locations: action.payload.data,
        error: locations.length
          ? null
          : "Location not found. Try again or search by row",
      };
    case FETCH_LOCATIONS_BY_BARCODE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        locations: [],
        notification: null,
      };
    case PRINT_BARCODES_SKUS_BEGIN:
      return {
        ...state,
        formLoading: true,
        barcode: null,
        error: null,
        notification: null,
      };
    case PRINT_BARCODES_SKUS_SUCCESS:
      let headerLine = action.payload.headers["content-disposition"];
      let startFileNameIndex = headerLine.indexOf('"') + 1;
      let endFileNameIndex = headerLine.lastIndexOf('"');
      let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
      return {
        ...state,
        formLoading: false,
        barcode: action.payload.data,
        filename: filename,
      };
    case PRINT_BARCODES_SKUS_FAILURE:
      return {
        ...state,
        formLoading: false,
        barcode: null,
        filename: "",
      };
    case SEARCH_SKU_SUGGESTION_BEGIN:
      return {
        ...state,
        itemNamesList: [],
        error: null,
        notification: null,
      };
    case SEARCH_SKU_SUGGESTION_SUCCESS:
      return {
        ...state,
        itemNamesList: action.payload.results,
      };
    case SEARCH_SKU_SUGGESTION_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };

    case FILTER_SKU_SUGGESTION_BEGIN:
      return {
        ...state,
        itemNamesList: [],
        error: null,
        notification: null,
      };
    case FILTER_SKU_SUGGESTION_SUCCESS:
      return {
        ...state,
        itemNamesList: action.payload.results,
      };
    case FILTER_SKU_SUGGESTION_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case CLEAR_SKU_TMPLS:
      return {
        ...state,
        notification: null,
        error: null,
        barcode: null,
      };
    case ADD_SKU_STOCK_BEGIN:
      return {
        ...state,
        notification: null,
        error: null,
        formLoading: true,
        formSuccess: false,
      };
    case ADD_SKU_STOCK_SUCCESS: {
      return {
        ...state,
        error: null,
        formSuccess: true,
        formLoading: false,
        movements: {
          ...state.movements,
          stock_movement: action.payload.movements.stock_movement,
        },
        movementsPagination: action.payload.pagination,
      };
    }
    case ADD_SKU_STOCK_FAILURE:
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };

    case STOCK_CHECK_BEGIN:
      return {
        ...state,
        notification: null,
        error: null,
        formLoading: true,
        formSuccess: false,
      };
    case STOCK_CHECK_SUCCESS: {
      return {
        ...state,
        error: null,
        formSuccess: true,
        formLoading: false,
        fetchedItem: {
          ...state.fetchedItem,
          item: action.payload.data.item_details,
        }
      };
    }
    case STOCK_CHECK_FAILURE:
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };

    case FETCH_ITEM_HISTORY_SUCCESS:
      return {
        ...state,
        updating: false,
        error: null,
        item: action.payload.item,
      };
    case CHANGE_SKU_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchedItem: {
          ...state.fetchedItem,
          item: action.payload.data.item_details,
        },
        formSuccess: false,
      };

    case CHANGE_SKU_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    default:
      // Always have a default case in case a reducer doesn't complete
      return state;
  }
}
