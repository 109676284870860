import React from 'react';
import { Typography,
    withStyles,
    Button,
    Paper,
    Checkbox,
    FormControlLabel,
    FormControl,
    FormHelperText
} from '@material-ui/core';


import { ValidatorForm } from 'react-material-ui-form-validator';
import { updateCategory } from 'actions/categoryActions';
import { connect } from 'react-redux';
import { SketchPicker } from 'react-color';
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import FormTextValidator from 'components/Form/FormTextValidator';

const styles = theme => ({
    ...extendedTablesStyle,
    paper: {
      padding: '20px !important',
      margin: 'auto',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      color: '#00acc1'
    },
    textField: {
      marginBottom: '8px',
      padding: '5px 0'
    },
    gridItem: {
        paddingRight: '8px',
    },
    sketchPicker: {
      boxShadow: 'none !important',
      margin: 'auto',
      padding: '50px 0'
    },
    header: {
      margin: '20px 0'
    },
    sketchPickerContainer:{
      margin: '20px 0'
    },
    checkboxLabel: {
      width: '100%'
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
    },
    subcategoriesContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '20px 0'
    },
    flex: {
      display: 'flex'
    },
    error: {
      fontSize: '12px',
      color: theme.palette.error.main,
      marginLeft: '10px',
      marginBottom: '10px',
    }
});

class CategoryEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.category.id,
      category: this.props.category,
      background: this.props.color,
      subcategory: this.props.category.parent_id != null,
      title: this.props.category.title,
      parentId: this.props.category.parent_id,
      colour: this.props.category.colour
    }
    this.submitForm = this.submitForm.bind(this)
    this.updateCategoryState = this.updateCategoryState.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    // this.options = this.options.bind(this);
  }

  handleToggle() {
    if (this.state.category.has_subcategory ) {
      const newError = !this.state.error
      this.setState({
        error: newError
      });
      return
    }
    const { subcategory } = this.state;
    const newSubcategory = !subcategory;
    this.setState({
      subcategory: newSubcategory
    });
    if (newSubcategory === false) {
      this.setState({
        parentId: null
      });
    }
  }

  updateCategoryState(event) {
    this.setState({ [event.target.name]: event.target.value,});
  }

  handleChangeComplete = (color) => {
    this.setState({colour: color.hex});
  };

  submitForm(event){
    event.preventDefault();
    this.setState({saving: true});
    this.props.dispatch(updateCategory({id: this.state.id, title: this.state.title, colour: this.state.colour, parent_id: this.state.parentId}))
      .then(result => {
        if (result) {
            // result is null when in error
            this.props.handleClose()
        }
    })
  }

  render() {
    const {classes, errors} = this.props;
    const options = this.props.categories.filter(category => category.id !== this.props.category.id)
    const   fields = {
      title:  {
        label: 'Title',
        errorMessages: ['this field is required'],
        validators: ['required'],
        fullWidth: true,
        id: 'title'
      },
      parentId: {
        label: 'Parent category',
        errorMessages: ['this field is required'],
        validators: ['required'],
        fullWidth: true,
        id: 'parentId',
        type: 'native-select',
        options: options,
        disabled: !this.state.subcategory
      }
    }

    return (
      <Paper className={classes.paper}>
        <Close onClick={this.props.handleClose} className={classes.close}/>
        <Typography variant='subtitle1'  className={classes.header}>
          Category informations
        </Typography>

            <ValidatorForm
                ref="form"
                onSubmit={this.submitForm}
                onError={errors => console.log(errors)}
            >
              <div style={{display: 'flex', flexDirection: 'column'}}>
                Name
                <FormTextValidator
                  onChange={this.updateCategoryState}
                  state={this.state}
                  errors = {errors}
                  formInput={fields.title}
                />
              </div>
              <div className={classes.subcategoriesContainer}>
                <FormControl className={classes.formControl} error={this.state.error}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={classes.checkboxeStyle}
                        onClick={this.handleToggle}
                        checkedIcon={<Check color="primary" />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        checked={this.state.subcategory}
                     />
                    }
                    label="Is a sub-category"
                    className={classes.checkboxLabel}
                  />
                  <FormHelperText>{this.state.error ? 'This is a main category' : null}</FormHelperText>
                </FormControl>

                <FormTextValidator
                  onChange={this.updateCategoryState}
                  state={this.state}
                  errors = {errors}
                  formInput={fields.parentId}
                />
              </div>

              <SketchPicker
                className={classes.sketchPicker}
                color={ this.state.colour || 'white'}
                onChangeComplete={ this.handleChangeComplete }
              />
              {errors && errors.colour ? <div className={classes.error}>This color {errors.colour}</div> : null}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                >
                Save
              </Button>
            </ValidatorForm>
      </Paper>
  );
  }
}

const mapStateToProps = (state, ownProps)  => ({
  ...ownProps,
})

export default connect(mapStateToProps)(withStyles(styles)(CategoryEditForm))
