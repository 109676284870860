import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withStyles } from '@material-ui/core/styles';
import CategoryEditForm from 'components/FormsPleaseStopDoingThis/CategoryForm/CategoryEditForm';
import {UID} from 'AppConstants';
import checkPermission from "components/checkPermission";

class EditCategory extends Component {

    constructor(props){
        super(props)
        this.state = {
            id: props.match.params.id,
            open: true,
            anchorEl: false,
         }
        this.handleClose = this.handleClose.bind(this)
        this.handleMoreActionsClose = this.handleMoreActionsClose.bind(this)
        this.handleOrderMoreActions = this.handleOrderMoreActions.bind(this)
    }

    handleClose(){
        this.setState({
            open: false,
        })
        setTimeout(() => {
          this.props.history.push(`/user/${UID}/space/categories`);
        }, 100);
    }

    handleOrderMoreActions(event){
        this.setState({
            anchorEl: event.currentTarget
        })
    }

    handleMoreActionsClose(event){
        this.setState({
            anchorEl: null
        })
    }

    permittedToAccess = (page) => {
      return checkPermission(this.props.currentOrganisation.currentOrganisationUser.role, page)
    }

    render() {
      const { classes } = this.props
      if (!this.permittedToAccess("categories:edit")) {this.props.history.push(`/user/${UID}/space/categories`)}

      return (
          <Dialog
              // fullScreen={this.props.fullScreen}
              maxWidth='xs'
              classes = {{paper: classes.dialogPaper, scrollPaper: classes.dialogScrollPaper}}
              open={this.state.open}
              onEscapeKeyDown={this.handleClose}
              onClose={this.handleClose}
              onBackdropClick={this.handleClose}
              aria-labelledby="responsive-dialog-title"
              overflow="visible"
          >
              <div className={classes.dialog}>
                <CategoryEditForm categories={this.props.categories} category={this.props.category} handleClose={this.handleClose} errors={this.props.errors}/>
              </div>
          </Dialog>
      );
    }
}

EditCategory.propTypes = {
  // fullScreen: PropTypes.bool.isRequired,
};

const styles = theme => ({
    root: {
      minHeight: '400px',
      flexWrap: 'wrap'
    },
    dialog: {
      background: '#eeeeee',
      overflowY: 'scroll',
      '-webkit-overflow-scrolling': 'touch',
    },
    dialogTitle: {
      display: 'inline-block',
    },
    dialogPaper: {
      minHeight: '100%'
    },
    dialogScrollPaper: {
      justifyContent: 'right'
    }
});

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    categories: state.categoriesReducer.categories.filter(category => category.parent_id === null),
    category: state.categoriesReducer.categories.filter(category => {
        return category.id === parseInt(ownProps.match.params.id)
    })[0],
    errors: state.categoriesReducer.error,
    currentOrganisation: state.organisationsReducer.currentOrganisation
})

export default connect(mapStateToProps)(withMobileDialog({
    breakpoint: 'sm',
})(withStyles(styles)(EditCategory)));


