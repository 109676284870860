import adminApi from "services/admin/adminApi";
/*----------------packing--------------------------*/
export const FETCH_PACKING_BEGIN = "FETCH_PACKING_BEGIN";
export const FETCH_PACKING_SUCCESS = "FETCH_PACKING_SUCCESS";
export const FETCH_PACKING_FAILURE = "FETCH_PACKING_FAILURE";

export const DELETE_PACKING = "DELETE_PACKING";
export const DELETE_PACKING_SUCCESS = "DELETE_PACKING_SUCCESS";
export const DELETE_PACKING_FAILURE = "DELETE_PACKING_FAILURE";

export const FETCH_PACKING_BY_ID_SUCCESS = "FETCH_PACKING_BY_ID_SUCCESS";
export const FETCH_PACKING_BY_ID_FAILURE = "FETCH_PACKING_BY_ID_FAILURE";

export const CREATE_PACKING_BEGIN = "CREATE_PACKING_BEGIN";
export const CREATE_PACKING_SUCCESS = "CREATE_PACKING_SUCCESS";
export const CREATE_PACKING_FAILURE = "CREATE_PACKING_FAILURE";

export const UPDATE_PACKING_BEGIN = "UPDATE_PACKING_BEGIN";
export const UPDATE_PACKING_SUCCESS = "UPDATE_PACKING_SUCCESS";
export const UPDATE_PACKING_FAILURE = "UPDATE_PACKING_FAILURE";

/*----------------Prepping-----------------------*/
export const FETCH_PREPPING_BEGIN = "FETCH_PREPPING_BEGIN";
export const FETCH_PREPPING_SUCCESS = "FETCH_PREPPING_SUCCESS";
export const FETCH_PREPPING_FAILURE = "FETCH_PREPPING_FAILURE";

export const DELETE_PREPPING = "DELETE_PREPPING";
export const DELETE_PREPPING_SUCCESS = "DELETE_PREPPING_SUCCESS";
export const DELETE_PREPPING_FAILURE = "DELETE_PREPPING_FAILURE";

export const FETCH_PREPPING_BY_ID_SUCCESS = "FETCH_PREPPING_BY_ID_SUCCESS";
export const FETCH_PREPPING_BY_ID_FAILURE = "FETCH_PREPPING_BY_ID_FAILURE";

export const CREATE_PREPPING_BEGIN = "CREATE_PREPPING_BEGIN";
export const CREATE_PREPPING_SUCCESS = "CREATE_PREPPING_SUCCESS";
export const CREATE_PREPPING_FAILURE = "CREATE_PREPPING_FAILURE";

export const UPDATE_PREPPING_BEGIN = "UPDATE_PREPPING_BEGIN";
export const UPDATE_PREPPING_SUCCESS = "UPDATE_PREPPING_SUCCESS";
export const UPDATE_PREPPING_FAILURE = "UPDATE_PREPPING_FAILURE";

/*----------------Administration-----------------------*/
export const FETCH_ADMINISTRATION_BEGIN = "FETCH_ADMINISTRATION_BEGIN";
export const FETCH_ADMINISTRATION_SUCCESS = "FETCH_ADMINISTRATION_SUCCESS";
export const FETCH_ADMINISTRATION_FAILURE = "FETCH_ADMINISTRATION_FAILURE";

export const DELETE_ADMINISTRATION = "DELETE_ADMINISTRATION";
export const DELETE_ADMINISTRATION_SUCCESS = "DELETE_ADMINISTRATION_SUCCESS";
export const DELETE_ADMINISTRATION_FAILURE = "DELETE_ADMINISTRATION_FAILURE";

export const FETCH_ADMINISTRATION_BY_ID_SUCCESS =
  "FETCH_ADMINISTRATION_BY_ID_SUCCESS";
export const FETCH_ADMINISTRATION_BY_ID_FAILURE =
  "FETCH_ADMINISTRATION_BY_ID_FAILURE";

export const CREATE_ADMINISTRATION_BEGIN = "CREATE_ADMINISTRATION_BEGIN";
export const CREATE_ADMINISTRATION_SUCCESS = "CREATE_ADMINISTRATION_SUCCESS";
export const CREATE_ADMINISTRATION_FAILURE = "CREATE_ADMINISTRATION_FAILURE";

export const UPDATE_ADMINISTRATION_BEGIN = "UPDATE_ADMINISTRATION_BEGIN";
export const UPDATE_ADMINISTRATION_SUCCESS = "UPDATE_ADMINISTRATION_SUCCESS";
export const UPDATE_ADMINISTRATION_FAILURE = "UPDATE_ADMINISTRATION_FAILURE";

/*----------------Inbound-----------------------*/
export const FETCH_INBOUND_BEGIN = "FETCH_INBOUND_BEGIN";
export const FETCH_INBOUND_SUCCESS = "FETCH_INBOUND_SUCCESS";
export const FETCH_INBOUND_FAILURE = "FETCH_INBOUND_FAILURE";

export const DELETE_INBOUND = "DELETE_INBOUND";
export const DELETE_INBOUND_SUCCESS = "DELETE_INBOUND_SUCCESS";
export const DELETE_INBOUND_FAILURE = "DELETE_INBOUND_FAILURE";

export const FETCH_INBOUND_BY_ID_SUCCESS = "FETCH_INBOUND_BY_ID_SUCCESS";
export const FETCH_INBOUND_BY_ID_FAILURE = "FETCH_INBOUND_BY_ID_FAILURE";

export const CREATE_INBOUND_BEGIN = "CREATE_INBOUND_BEGIN";
export const CREATE_INBOUND_SUCCESS = "CREATE_INBOUND_SUCCESS";
export const CREATE_INBOUND_FAILURE = "CREATE_INBOUND_FAILURE";

export const UPDATE_INBOUND_BEGIN = "UPDATE_INBOUND_BEGIN";
export const UPDATE_INBOUND_SUCCESS = "UPDATE_INBOUND_SUCCESS";
export const UPDATE_INBOUND_FAILURE = "UPDATE_INBOUND_FAILURE";

/*----------------Picking-----------------------*/
export const FETCH_PICKING_BEGIN = "FETCH_PICKING_BEGIN";
export const FETCH_PICKING_SUCCESS = "FETCH_PICKING_SUCCESS";
export const FETCH_PICKING_FAILURE = "FETCH_PICKING_FAILURE";

export const DELETE_PICKING = "DELETE_PICKING";
export const DELETE_PICKING_SUCCESS = "DELETE_PICKING_SUCCESS";
export const DELETE_PICKING_FAILURE = "DELETE_PICKING_FAILURE";

export const FETCH_PICKING_BY_RANGE_SUCCESS = "FETCH_PICKING_BY_RANGE_SUCCESS";
export const FETCH_PICKING_BY_ID_FAILURE = "FETCH_PICKING_BY_ID_FAILURE";

export const CREATE_PICKING_BEGIN = "CREATE_PICKING_BEGIN";
export const CREATE_PICKING_SUCCESS = "CREATE_PICKING_SUCCESS";
export const CREATE_PICKING_FAILURE = "CREATE_PICKING_FAILURE";

export const UPDATE_PICKING_BEGIN = "UPDATE_PICKING_BEGIN";
export const UPDATE_PICKING_SUCCESS = "UPDATE_PICKING_SUCCESS";
export const UPDATE_PICKING_FAILURE = "UPDATE_PICKING_FAILURE";

export const FETCH_PICKING_TIERS_BEGIN = "FETCH_PICKING_TIERS_BEGIN";
export const FETCH_PICKING_TIERS_SUCCESS = "FETCH_PICKING_TIERS_SUCCESS";
export const FETCH_PICKING_TIERS_FAILURE = "FETCH_PICKING_TIERS_FAILURE";

export const CLEAR_RATES_NOTIFICATIONS = "CLEAR_RATES_NOTIFICATIONS";

export const UPDATE_PICKERSERVICE_PRICES_BEGIN =
  "UPDATE_PICKERSERVICE_PRICES_BEGIN";
export const UPDATE_PICKERSERVICE_PRICES_SUCCESS =
  "UPDATE_PICKERSERVICE_PRICES_SUCCESS";
export const UPDATE_PICKERSERVICE_PRICES_FAILURE =
  "UPDATE_PICKERSERVICE_PRICES_FAILURE";

export const UPDATE_PICKERSERVICE_MARGINS_BEGIN =
  "UPDATE_PICKERSERVICE_MARGINS_BEGIN";
export const UPDATE_PICKERSERVICE_MARGINS_SUCCESS =
  "UPDATE_PICKERSERVICE_MARGINS_SUCCESS";
export const UPDATE_PICKERSERVICE_MARGINS_FAILURE =
  "UPDATE_PICKERSERVICE_MARGINS_FAILURE";

/*--------------------Packing----------------------------*/
export function fetchPacking(wid) {
  return (dispatch) => {
    dispatch(fetchPackingBegin()); //calling this action to show loading
    return adminApi
      .fetchPacking(wid) // calling list of packing
      .then((json) => {
        dispatch(fetchPackingSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log("error", error);

        dispatch(fetchPackingFailure(error));
      });
  };
}

export function deletePacking(id, wid) {
  return (dispatch) => {
    return adminApi
      .deletePacking(id, wid)
      .then((json) => {
        dispatch(deletePackingSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log(error);
        dispatch(deletePackingFailure(error));
      });
  };
}

export function fetchPackingByID(id, wid) {
  return (dispatch) => {
    return adminApi
      .getPackingDetail(id, wid)
      .then(handleErrors)
      .then((json) => {
        dispatch(fetchPackingByIDSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchPackingByIDFailure(error));
      });
  };
}

export function updatePacking(data, wid) {
  return (dispatch) => {
    dispatch(updatePackingBegin());
    let res = adminApi
      .updatePacking(data, wid)
      .then((json) => {
        dispatch(updatePackingSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(updatePackingFailure(error));
      });
    return res;
  };
}

export const fetchPackingBegin = () =>
  // the whole object is n action
  ({
    type: FETCH_PACKING_BEGIN,
  });

export const fetchPackingSuccess = (json) => ({
  type: FETCH_PACKING_SUCCESS,
  data: json.data.handlings,
});

export const fetchPackingFailure = (error) => ({
  type: FETCH_PACKING_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const deletePackingSuccess = (json) => ({
  type: DELETE_PACKING_SUCCESS,
  data: json.data.handling,
  msg: json.message,
});

export const deletePackingFailure = (error) => ({
  type: DELETE_PACKING_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const fetchPackingByIDSuccess = (json) => ({
  type: FETCH_PACKING_BY_ID_SUCCESS,
  payload: json.data.handling,
});

export const fetchPackingByIDFailure = (error) => ({
  type: FETCH_PACKING_BY_ID_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function updatePackingBegin() {
  return {
    type: UPDATE_PACKING_BEGIN,
  };
}

export function updatePackingSuccess(json) {
  return {
    type: UPDATE_PACKING_SUCCESS,
    payload: json.data.handling,
  };
}

export function updatePackingFailure(error) {
  return {
    type: UPDATE_PACKING_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function createPacking(data, wid) {
  return (dispatch) => {
    dispatch(createPackingBegin());
    let res = adminApi
      .createPacking(data, wid)
      .then((json) => {
        dispatch(createPackingSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(createPackingFailure(error));
      });
    return res;
  };
}

export function createPackingBegin() {
  return {
    type: CREATE_PACKING_BEGIN,
  };
}

export function createPackingSuccess(json) {
  return {
    type: CREATE_PACKING_SUCCESS,
    payload: {
      packing: json.data.handling,
    },
  };
}

export function createPackingFailure(error) {
  return {
    type: CREATE_PACKING_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

/*-----------Prepping-------------------*/
export function fetchPrepping(wid) {
  return (dispatch) => {
    dispatch(fetchPreppingBegin()); //calling this action to show loading
    return adminApi
      .fetchPrepping(wid) // calling list of packing
      .then((json) => {
        dispatch(fetchPreppingSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log("error", error);

        dispatch(fetchPreppingFailure(error));
      });
  };
}

export function deletePrepping(id, wid) {
  return (dispatch) => {
    return adminApi
      .deletePrepping(id, wid)
      .then((json) => {
        dispatch(deletePreppingSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log(error);
        dispatch(deletePreppingFailure(error));
      });
  };
}

export function fetchPreppingByID(id, wid) {
  return (dispatch) => {
    return adminApi
      .getPreppingDetail(id, wid)
      .then(handleErrors)
      .then((json) => {
        dispatch(fetchPreppingByIDSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchPreppingByIDFailure(error));
      });
  };
}

export function updatePrepping(data, wid) {
  return (dispatch) => {
    dispatch(updatePreppingBegin());
    let res = adminApi
      .updatePrepping(data, wid)
      .then((json) => {
        dispatch(updatePreppingSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(updatePreppingFailure(error));
      });
    return res;
  };
}

export const fetchPreppingBegin = () =>
  // the whole object is n action
  ({
    type: FETCH_PREPPING_BEGIN,
  });

export const fetchPreppingSuccess = (json) => ({
  type: FETCH_PREPPING_SUCCESS,
  data: json.data.preppings,
});

export const fetchPreppingFailure = (error) => ({
  type: FETCH_PREPPING_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const deletePreppingSuccess = (json) => ({
  type: DELETE_PREPPING_SUCCESS,
  data: json.data.prepping,
  msg: json.message,
});

export const deletePreppingFailure = (error) => ({
  type: DELETE_PREPPING_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const fetchPreppingByIDSuccess = (json) => ({
  type: FETCH_PREPPING_BY_ID_SUCCESS,
  payload: json.data.prepping,
});

export const fetchPreppingByIDFailure = (error) => ({
  type: FETCH_PREPPING_BY_ID_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function updatePreppingBegin() {
  return {
    type: UPDATE_PREPPING_BEGIN,
  };
}

export function updatePreppingSuccess(json) {
  return {
    type: UPDATE_PREPPING_SUCCESS,
    payload: json.data.prepping,
  };
}

export function updatePreppingFailure(error) {
  return {
    type: UPDATE_PREPPING_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function createPrepping(data, wid) {
  return (dispatch) => {
    dispatch(createPreppingBegin());
    let res = adminApi
      .createPrepping(data, wid)
      .then((json) => {
        dispatch(createPreppingSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(createPreppingFailure(error));
      });
    return res;
  };
}

export function createPreppingBegin() {
  return {
    type: CREATE_PREPPING_BEGIN,
  };
}

export function createPreppingSuccess(json) {
  return {
    type: CREATE_PREPPING_SUCCESS,
    payload: {
      prepping: json.data.prepping,
    },
  };
}

export function createPreppingFailure(error) {
  return {
    type: CREATE_PREPPING_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

/*-----------Administration-------------------*/
export function fetchAdministration(wid) {
  return (dispatch) => {
    dispatch(fetchAdministrationBegin()); //calling this action to show loading
    return adminApi
      .fetchAdministration(wid) // calling list of packing
      .then((json) => {
        dispatch(fetchAdministrationSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log("error", error);

        dispatch(fetchAdministrationFailure(error));
      });
  };
}

export function deleteAdministration(id, wid) {
  return (dispatch) => {
    return adminApi
      .deleteAdministration(id, wid)
      .then((json) => {
        dispatch(deleteAdministrationSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log(error);
        dispatch(deleteAdministrationFailure(error));
      });
  };
}

export function fetchAdministrationByID(id, wid) {
  return (dispatch) => {
    return adminApi
      .getAdministrationDetail(id, wid)
      .then(handleErrors)
      .then((json) => {
        dispatch(fetchAdministrationByIDSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchAdministrationByIDFailure(error));
      });
  };
}

export function updateAdministration(data, wid) {
  return (dispatch) => {
    dispatch(updateAdministrationBegin());
    let res = adminApi
      .updateAdministration(data, wid)
      .then((json) => {
        dispatch(updateAdministrationSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(updateAdministrationFailure(error));
      });
    return res;
  };
}

export const fetchAdministrationBegin = () =>
  // the whole object is n action
  ({
    type: FETCH_ADMINISTRATION_BEGIN,
  });

export const fetchAdministrationSuccess = (json) => ({
  type: FETCH_ADMINISTRATION_SUCCESS,
  data: json.data.administration_costs,
});

export const fetchAdministrationFailure = (error) => ({
  type: FETCH_ADMINISTRATION_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const deleteAdministrationSuccess = (json) => ({
  type: DELETE_ADMINISTRATION_SUCCESS,
  data: json.data.administration_cost,
  msg: json.message,
});

export const deleteAdministrationFailure = (error) => ({
  type: DELETE_ADMINISTRATION_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const fetchAdministrationByIDSuccess = (json) => ({
  type: FETCH_ADMINISTRATION_BY_ID_SUCCESS,
  payload: json.data.administration_cost,
});

export const fetchAdministrationByIDFailure = (error) => ({
  type: FETCH_ADMINISTRATION_BY_ID_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function updateAdministrationBegin() {
  return {
    type: UPDATE_ADMINISTRATION_BEGIN,
  };
}

export function updateAdministrationSuccess(json) {
  return {
    type: UPDATE_ADMINISTRATION_SUCCESS,
    payload: json.data.administration_cost,
  };
}

export function updateAdministrationFailure(error) {
  return {
    type: UPDATE_ADMINISTRATION_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function createAdministration(data, wid) {
  return (dispatch) => {
    dispatch(createAdministrationBegin());
    let res = adminApi
      .createAdministration(data, wid)
      .then((json) => {
        dispatch(createAdministrationSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(createAdministrationFailure(error));
      });
    return res;
  };
}

export function createAdministrationBegin() {
  return {
    type: CREATE_ADMINISTRATION_BEGIN,
  };
}

export function createAdministrationSuccess(json) {
  return {
    type: CREATE_ADMINISTRATION_SUCCESS,
    payload: {
      administration: json.data.administration_cost,
    },
  };
}

export function createAdministrationFailure(error) {
  return {
    type: CREATE_ADMINISTRATION_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

/*-----------Inbound-------------------*/
export function fetchInbound(wid) {
  return (dispatch) => {
    dispatch(fetchInboundBegin()); //calling this action to show loading
    return adminApi
      .fetchInbound(wid) // calling list of packing
      .then((json) => {
        dispatch(fetchInboundSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(fetchInboundFailure(error));
      });
  };
}

export function deleteInbound(id, wid) {
  return (dispatch) => {
    return adminApi
      .deleteInbound(id, wid)
      .then((json) => {
        dispatch(deleteInboundSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log(error);
        dispatch(deleteInboundFailure(error));
      });
  };
}

export function fetchInboundByID(id, wid) {
  return (dispatch) => {
    return adminApi
      .getInboundDetail(id, wid)
      .then(handleErrors)
      .then((json) => {
        dispatch(fetchInboundByIDSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchInboundByIDFailure(error));
      });
  };
}

export function updateInbound(data, wid) {
  return (dispatch) => {
    dispatch(updateInboundBegin());
    let res = adminApi
      .updateInbound(data, wid)
      .then((json) => {
        dispatch(updateInboundSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(updateInboundFailure(error));
      });
    return res;
  };
}

export const fetchInboundBegin = () =>
  // the whole object is n action
  ({
    type: FETCH_INBOUND_BEGIN,
  });

export const fetchInboundSuccess = (json) => ({
  type: FETCH_INBOUND_SUCCESS,
  data: json.data.inbound_charges,
});

export const fetchInboundFailure = (error) => ({
  type: FETCH_INBOUND_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const deleteInboundSuccess = (json) => ({
  type: DELETE_INBOUND_SUCCESS,
  data: json.data.inbound_charge,
  msg: json.message,
});

export const deleteInboundFailure = (error) => ({
  type: DELETE_INBOUND_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const fetchInboundByIDSuccess = (json) => ({
  type: FETCH_INBOUND_BY_ID_SUCCESS,
  payload: json.data.inbound_charge,
});

export const fetchInboundByIDFailure = (error) => ({
  type: FETCH_INBOUND_BY_ID_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function updateInboundBegin() {
  return {
    type: UPDATE_INBOUND_BEGIN,
  };
}

export function updateInboundSuccess(json) {
  return {
    type: UPDATE_INBOUND_SUCCESS,
    payload: json.data.inbound_charge,
  };
}

export function updateInboundFailure(error) {
  return {
    type: UPDATE_INBOUND_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function createInbound(data, wid) {
  return (dispatch) => {
    dispatch(createInboundBegin());
    let res = adminApi
      .createInbound(data, wid)
      .then((json) => {
        dispatch(createInboundSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(createInboundFailure(error));
      });
    return res;
  };
}

export function createInboundBegin() {
  return {
    type: CREATE_INBOUND_BEGIN,
  };
}

export function createInboundSuccess(json) {
  return {
    type: CREATE_INBOUND_SUCCESS,
    payload: {
      inbound: json.data.inbound_charge,
    },
  };
}

export function createInboundFailure(error) {
  return {
    type: CREATE_INBOUND_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

/*-----------Picking-------------------*/
export function fetchPicking(wid, noLoading = false) {
  return (dispatch) => {
    if (!noLoading) dispatch(fetchPickingBegin()); //calling this action to show loading
    return adminApi
      .fetchPicking(wid) // calling list of packing
      .then((json) => {
        dispatch(fetchPickingSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log("error", error);

        dispatch(fetchPickingFailure(error));
      });
  };
}

export function deletePicking(range, wid) {
  return (dispatch) => {
    return adminApi
      .deletePicking(range, wid)
      .then((json) => {
        dispatch(deletePickingSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log(error);
        dispatch(deletePickingFailure(error));
      });
  };
}

export function fetchPickingByRange(range, wid) {
  return (dispatch) => {
    return adminApi
      .getPickingDetail(range, wid)
      .then(handleErrors)
      .then((json) => {
        dispatch(fetchPickingByRangeSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchPickingByRangeFailure(error));
      });
  };
}

export function updatePicking(data, wid) {
  return (dispatch) => {
    dispatch(updatePickingBegin());
    let res = adminApi
      .updatePicking(data, wid)
      .then((json) => {
        dispatch(updatePickingSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(updatePickingFailure(error));
      });
    return res;
  };
}

export const fetchPickingBegin = () =>
  // the whole object is n action
  ({
    type: FETCH_PICKING_BEGIN,
  });

export const fetchPickingSuccess = (json) => ({
  type: FETCH_PICKING_SUCCESS,
  data: json.data,
});

export const fetchPickingFailure = (error) => ({
  type: FETCH_PICKING_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const deletePickingSuccess = (json) => ({
  type: DELETE_PICKING_SUCCESS,
  data: json.data,
  msg: json.message,
});

export const deletePickingFailure = (error) => ({
  type: DELETE_PICKING_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const fetchPickingByRangeSuccess = (json) => ({
  type: FETCH_PICKING_BY_RANGE_SUCCESS,
  payload: json.data,
});

export const fetchPickingByRangeFailure = (error) => ({
  type: FETCH_PICKING_BY_ID_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function updatePickingBegin() {
  return {
    type: UPDATE_PICKING_BEGIN,
  };
}

export function updatePickingSuccess(json) {
  return {
    type: UPDATE_PICKING_SUCCESS,
    payload: json.data,
  };
}

export function updatePickingFailure(error) {
  return {
    type: UPDATE_PICKING_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function createPicking(data, wid) {
  return (dispatch) => {
    dispatch(createPickingBegin());
    let res = adminApi
      .createPicking(data, wid)
      .then((json) => {
        dispatch(createPickingSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(createPickingFailure(error));
      });
    return res;
  };
}

export function createPickingBegin() {
  return {
    type: CREATE_PICKING_BEGIN,
  };
}

export function createPickingSuccess(json) {
  return {
    type: CREATE_PICKING_SUCCESS,
    payload: {
      picking: json.data.pick,
    },
  };
}

export function createPickingFailure(error) {
  return {
    type: CREATE_PICKING_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export const fetchPickTiersBegin = () =>
  // the whole object is n action
  ({
    type: FETCH_PICKING_TIERS_BEGIN,
  });

export const fetchPickTiersSuccess = (json, level) => ({
  type: FETCH_PICKING_TIERS_SUCCESS,
  data: json.data,
  level: level,
});

export const fetchPickTiersFailure = (error) => ({
  type: FETCH_PICKING_TIERS_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function fetchPickTiers(data, level, wid) {
  return (dispatch) => {
    dispatch(fetchPickTiersBegin()); //calling this action to show loading
    return adminApi
      .fetchPickTiers(data, wid) // calling list of packing
      .then((json) => {
        dispatch(fetchPickTiersSuccess(json, level));
        return json;
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(fetchPickTiersFailure(error));
      });
  };
}

export const clearRatesNotifications = () => ({
  type: CLEAR_RATES_NOTIFICATIONS,
});

// Private methods

function handleErrors(response) {
  if (!response.success) {
    throw Error(response.statusText);
  }
  return response;
}

function handlePackingMessageErrors(response) {
  if (!response.success) {
    throw Error(response.statusText);
  }
  return response;
}

const updatePickerServicePricesBegin = () => {
  return {
    type: UPDATE_PICKERSERVICE_PRICES_BEGIN,
  };
};
const updatePickerServicePricesSuccess = (json) => {
  return {
    type: UPDATE_PICKERSERVICE_PRICES_SUCCESS,
    payload: {
      zones: json.data,
    },
  };
};
const updatePickerServicePricesFailure = (error) => {
  return {
    type: UPDATE_PICKERSERVICE_PRICES_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
};

export function updatePickerServicePrices(wid, data) {
  return (dispatch) => {
    dispatch(updatePickerServicePricesBegin());
    let res = adminApi
      .updatePickerServicePrices(wid, data)
      .then((json) => {
        dispatch(updatePickerServicePricesSuccess(json));
        dispatch(fetchPicking(wid));
        dispatch(fetchPickTiers({ tier: "L1" }, 1, wid));
        dispatch(fetchPickTiers({ tier: "L2" }, 2, wid));
        dispatch(fetchPickTiers({ tier: "L3" }, 3, wid));
        dispatch(fetchPickTiers({ tier: "L4" }, 4, wid));
        return json;
      })
      .catch((error) => {
        dispatch(updatePickerServicePricesFailure(error));
      });
    return res;
  };
}

const updatePickerServiceMarginsBegin = () => {
  return {
    type: UPDATE_PICKERSERVICE_MARGINS_BEGIN,
  };
};
const updatePickerServiceMarginsSuccess = (json) => {
  return {
    type: UPDATE_PICKERSERVICE_MARGINS_SUCCESS,
    payload: {
      zones: json.data,
    },
  };
};
const updatePickerServiceMarginsFailure = (error) => {
  return {
    type: UPDATE_PICKERSERVICE_MARGINS_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
};

export function updatePickerServiceMargins(wid, data, tier) {
  return (dispatch) => {
    dispatch(updatePickerServiceMarginsBegin());
    let res = adminApi
      .updatePickerServiceMargins(wid, data)
      .then((json) => {
        const level = tier.slice(1);
        dispatch(updatePickerServiceMarginsSuccess(json));
        dispatch(fetchPicking(wid));
        dispatch(fetchPickTiers({ tier }, level, wid));
        return json;
      })
      .catch((error) => {
        dispatch(updatePickerServiceMarginsFailure(error));
      });
    return res;
  };
}
