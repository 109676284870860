import React, {useState, useEffect, Fragment} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {ValidatorForm} from 'react-material-ui-form-validator';

import {updateOrganisation} from 'actions/organisationActions';

import FlexGrow from 'components/Layout/FlexGrow';
import SuccessSnackbar from 'components/Help/SuccessSnackbar';

import {makeStyles} from '@material-ui/styles';
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  Switch,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Grid
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  flex1: {

    flexGrow: 1,
  },

  margin10: {
    margin: '10px',
    maxWidth: '50%'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: theme.palette['header'].main,
    '&:hover': {
      backgroundColor: theme.palette['header'].dark
    }
  },

  labelPlacementStart: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '0px',
    width: '33vW'

  },
  inLineItem: {
    display: 'flex',
    minHeight: '63px',
    alignItems: 'center',
    fontSize: '18px',
    lineHeight: '10px',
    padding: '8px 15px',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


const Shipping = props => {
  const {currentOrganisation, error} = props;

  const [values, setValues] = useState(
    {
      signatureOnDelivery: '',
      shippingDuties: '',
      insuranceDomesticOrders: false,
      insuranceInternationalOrders: false
    })
  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const dispatch = useDispatch();
  let inputRef = React.createRef("form");

  const dutyOptions =
    [
      {
        value: 'delivered_duty_unpaid',
        hint: 'Send your International parcels with tax import duties unpaid',
        label: 'Delivered Duty Unpaid'
      },
      {
        value: 'delivered_duty_paid',
        hint: 'Send your International parcels with tax import duties paid',
        label: 'Delivered Duty Paid'
      },
    ]

  const signatureOptions =
    [
      {value: 'authority_to_leave', hint: 'Default to allowing the parcels to be left', label: 'Authority to leave'},
      {
        value: 'authority_to_leave_requestable',
        hint: 'Default to signature required but provide a message option to reciever to choose to leave the parcel',
        label: 'Authority to leave requestable'
      },
      {
        value: 'signature_required',
        hint: 'Defaulting that parcel requiring a signature before releasing',
        label: 'Signature Required'
      },
    ]

  const insuranceOptions =
    [
      {
        name: 'insuranceDomesticOrders',
        value: 'shipping_insurance_domestic',
        hint: 'Default to place a minimum of $100 insurance cover on domestic orders',
        label: 'Insurance Domestic Orders'
      },
      {
        name: 'insuranceInternationalOrders',
        value: 'shipping_insurance_international',
        hint: 'Default to place a minimum of $100 insurance cover on international orders',
        label: 'Insurance International Orders'
      },

    ]

  useEffect(() => {
    if (currentOrganisation.organisation_preference)
      setValues(
        {
          ...values,
          signatureOnDelivery: currentOrganisation.signature_on_delivery,
          shippingDuties: currentOrganisation.organisation_preference.shipping_duties,
          insuranceDomesticOrders: currentOrganisation.organisation_preference.shipping_insurance_domestic,
          insuranceInternationalOrders: currentOrganisation.organisation_preference.shipping_insurance_international
        })
  }, [currentOrganisation.signature_on_delivery, currentOrganisation.organisation_preference]);

  const handleChange = (event) => {
    event.persist();

    setValues({
      ...values,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    });
  }

  const submitForm = event => {
    // event.preventDefault();
    var formData = new FormData();

    formData.append('signature_on_delivery', values.signatureOnDelivery)
    formData.append('shipping_duties', values.shippingDuties)
    formData.append('shipping_insurance_domestic', values.insuranceDomesticOrders)
    formData.append('shipping_insurance_international', values.insuranceInternationalOrders)

    dispatch(updateOrganisation(currentOrganisation.id, formData));
    setOpenSnackbar(true)
  }

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className={classes.flex1}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Card
          >
            <CardHeader variant="h1" style={{fontSize: '18px'}} title="Shipping Settings"/>
            <Divider/>
            <ValidatorForm
              ref={inputRef}
              onSubmit={submitForm}
              onError={errors => console.log(errors)}
            >
              <CardContent>


                <div className={classes.inLineItem}>

                  <FormControl component="fieldset">

                    <FormLabel component="legend" style={{fontSize: '17px', fontWeight: '500'}}>Domestic</FormLabel>
                    <RadioGroup aria-label="Domestic" name='signatureOnDelivery' value={values.signatureOnDelivery}
                                onChange={handleChange}>
                      {signatureOptions.map((option) =>
                        <Fragment key={option.value}>

                          <FormControlLabel value={option.value} labelPlacement="start" name={option.name} classes={{
                            labelPlacementStart: classes.labelPlacementStart,
                          }} control={<Radio/>} label={option.label}/>
                          <Typography variant='subtitle2'
                                      style={{fontSize: '11px', marginTop: '-12px'}}>{option.hint}</Typography>
                        </Fragment>
                      )}
                    </RadioGroup>

                  </FormControl>

                </div>

                <br/>
                <br/>

                <div className={classes.inLineItem}>
                  <FormControl component="fieldset">

                    <FormLabel component="legend"
                               style={{fontSize: '18px', fontWeight: '500'}}>International</FormLabel>

                    <RadioGroup aria-label="International" name="shippingDuties" value={values.shippingDuties}
                                onChange={handleChange}>
                      {dutyOptions.map((option) =>
                        <Fragment key={option.value}>
                          <FormControlLabel value={option.value} labelPlacement="start" name={option.name} classes={{
                            labelPlacementStart: classes.labelPlacementStart
                          }} control={<Radio/>} label={option.label}/>
                          <Typography variant='subtitle2'
                                      style={{fontSize: '11px', marginTop: '-12px'}}>{option.hint}</Typography>
                        </Fragment>
                      )}

                    </RadioGroup>

                  </FormControl>

                </div>


              </CardContent>
              <Divider/>
              <CardActions>
                <Button
                  className={classes.saveButton}

                  variant="contained"
                  onClick={submitForm}
                >
                  Save changes
                </Button>
              </CardActions>
            </ValidatorForm>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardHeader title="Insurance Settings"/>
            <Divider/>
            <CardContent>
              {insuranceOptions.map((option) =>

                <div className={classes.inLineItem} key={option.value}>
                  <div>
                    <Typography>{option.label}</Typography>
                    <Typography variant='subtitle2'>{option.hint}</Typography>
                  </div>
                  <FlexGrow/>

                  <Switch
                    type='checkbox'
                    checked={values[option.name]}
                    value={values[option.name]}
                    name={option.name}
                    onChange={handleChange}
                    color="primary"
                  />


                </div>
              )}

            </CardContent>
            <CardActions>
              <Button
                className={classes.saveButton}
                variant="contained"
                onClick={submitForm}
              >
                Save changes
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardHeader title="Warehouses Association"/>
            <Divider/>
            <CardContent>


              <div className={classes.inLineItem}>
                <div>
                  <Typography variant={"h3"}>{currentOrganisation?.warehouses || "NA"}</Typography>
                </div>

              </div>

            </CardContent>
          </Card>
        </Grid>

      </Grid>
      <SuccessSnackbar
        onClose={handleSnackbarClose}
        open={openSnackbar}
        error={error ? error.message : null}
        messageText='Changes updated'
      />
    </div>
  );
};

Shipping.propTypes = {
  className: PropTypes.string
};


export default Shipping;
