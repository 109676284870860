import {
    FETCH_BATCHES_BEGIN,
    FETCH_BATCHES_SUCCESS,
    FETCH_BATCHES_FILTERS_SUCCESS,
    FETCH_BATCHES_FAILURE,
    DELETE_BATCHES_SUCCESS,
    DELETE_BATCHES_FAILURE,
    FETCH_BATCHES_BY_ID_SUCCESS,
    FETCH_BATCHES_BY_ID_FAILURE,
    UPDATE_BATCHES_BEGIN,
    UPDATE_BATCHES_FAILURE,
    UPDATE_BATCHES_SUCCESS,
    UPDATE_BATCH_FILTER,
    UPDATE_BATCH_UID_FILTER,
    UPDATE_LEVEL_FILTER,
    UPDATE_TROLLEY_FILTER,
    UPDATE_STATUS_FILTER,
    CREATE_BATCH_BEGIN,
    CREATE_BATCH_SUCCESS,
    CREATE_BATCH_FAILURE,

    UPDATE_ORDER_FILTER,
    UPDATE_ORGANISATION_FILTER,
    UPDATE_USER_FILTER,
    UPDATE_INTERNATIONAL_FILTER,
    UPDATE_SHIPPING_FILTER,
    UPDATE_CARRIER_FILTER,
    UPDATE_SHIPPINGMETHOD_FILTER,
    UPDATE_MAXVOLUME_FILTER,
    UPDATE_TODATE_FILTER,
    UPDATE_FROMDATE_FILTER,

    FETCH_TROLLEYS_BEGIN,
    FETCH_TROLLEYS_SUCCESS,
    FETCH_TROLLEYS_FAILURE,

    CREATE_BATCH_TROLLEY_BEGIN,
    CREATE_BATCH_TROLLEY_SUCCESS,
    CREATE_BATCH_TROLLEY_FAILURE,

    FETCH_ORDER_BEGIN,
    FETCH_ORDER_SUCCESS,
    FETCH_ORDER_FAILURE,

    UPDATE_STOCK_INFO_BEGIN,
    UPDATE_STOCK_INFO_SUCCESS,
    UPDATE_STOCK_INFO_FAILURE,

    SELECT_ORDER_BEGIN,
    SELECT_ORDER_SUCCESS,
    SELECT_ORDER_FAILURE,

    FETCH_SLOTS_BEGIN,
    FETCH_SLOTS_SUCCESS,
    FETCH_SLOTS_FAILURE,

    FETCH_CREATED_BATCH_BEGIN,
    FETCH_CREATED_BATCH_SUCCESS,
    FETCH_CREATED_BATCH_FAILURE,

    UPDATE_STATUSES_SUCCESS,
    UPDATE_STATUSES_FAILURE,

    FETCH_PICKER_PACKER_BEGIN,
    FETCH_PICKER_PACKER_SUCCESS,
    FETCH_PICKER_PACKER_FAILURE,
    CLEAR_BATCHES,
    UPDATE_SELECTED_ORDERS,

} from 'actions/admin/batchesActions';


// specify initial state
const initialState = {
    batches: [],
    loading: false,
    error: null,
    notification: null,
    fetchedBatches: {},
    formLoading: false,
    pagination: {
        "current_page": 1,
        "total_count": 1,
        "per_page": 100
    },
    filters: [],
    selectedTrolley: [],
    selectedWarehouse: [],
    selectedBatch: [],
    selectedBatchUid: '',
    selectedStatus: [],
    selectedLevel: "",
    selectedFromDate: "",
    selectedToDate: "",

    createdBatch: {},
    selectedOrder: "",
    selectedOrganisation: "",
    selectedUser: "",
    selectedInternational: "",
    selectedShipping: "",
    selectedCarrier: "",
    selectedShippingMethod: "",
    selectedMaxVolume: "",

    trolleys: [],
    batchedTrolley: {},
    bookings: [],
    stockInfo: [
    ],
    selectOrder: {
        trolley_slots: []
    },
    slots: {
        trolley_slots: []
    },
    newBatch: {
        orders: [],
        skus: [],
        batch_logs: [],
        batch_statuses: []
    },
    pickerPacker: {
        pickers: [],
        packers: []
    },
    statuses: {},
    formSuccess: false,
    orderFilters: {}
}

export default function batchesReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_BATCHES_BEGIN:
            return {
                ...state,//imp line
                loading: true,
                error: null,
                notification: null
            };

        case FETCH_BATCHES_SUCCESS:
            return {
                ...state,
                loading: false,
                batches: action.data.batches,
                pagination: action.data.pagination,
                filters: action.data.filters,
            };

        case FETCH_BATCHES_FILTERS_SUCCESS:
            return {
                ...state,
                loading: false,
                filters: action.data.filters,
            };

        case FETCH_BATCHES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                batches: []
            };
        case UPDATE_BATCH_FILTER:
            return {
                ...state,
                selectedBatch: action.data
            };
        case UPDATE_BATCH_UID_FILTER:
            return {
                ...state,
                selectedBatchUid: action.data
            };
        case UPDATE_TROLLEY_FILTER:
            return {
                ...state,
                selectedTrolley: action.data
            }
        case UPDATE_LEVEL_FILTER:
            return {
                ...state,
                selectedLevel: action.data
            }

        case UPDATE_STATUS_FILTER:
            return {
                ...state,
                selectedStatus: action.data
            }
        case DELETE_BATCHES_SUCCESS:
            return {
                ...state,
                error: null,
                batches: state.batches.filter(batches => batches.id !== action.data.id),
                notification: action.msg
            };
        case DELETE_BATCHES_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                batches: state.batches,
                notification: action.msg
            };

        case FETCH_BATCHES_BY_ID_SUCCESS:
            return {
                ...state,
                //childLoading: false,
                fetchedBatches: action.payload
            };

        case FETCH_BATCHES_BY_ID_FAILURE:
            return {
                ...state,
                //childLoading: false,
                error: action.payload.error,
                notification: action.msg
            };
        case UPDATE_BATCHES_BEGIN: {
            return {
                ...state,
                formLoading: true,
                notification: null,
            }
        }
        case UPDATE_BATCHES_SUCCESS: {
            let updatedBatchess = state.batchesReducer.batches.map(item => item.id === action.payload.id ?
                action.payload : item
            )
            // let updatedBatches =  applyBatchesInterface(action.payload.batches)
            return {
                ...state,
                formLoading: false,
                errors: null,
                updateBatchesErrors: null,
                fetchedBatches: action.payload,
                batchess: updatedBatchess
            }
        }
        case UPDATE_BATCHES_FAILURE: {

            let update_batches_failure_errors
            if(action.payload.error.response && action.payload.error.response.status === 422) {
                // Unprocessable Entity; It's likely that the response contains fields with errors
                update_batches_failure_errors = {
                    ...action.payload.error.response.data
                }
            }
            let error = {}
            if(action.payload.error.response && action.payload.error.response.data.base) {
                action.payload.error.response.data.base.forEach(e =>
                    error.message ? error.message += `${e} ` : error.message = `${e} `
                )
            }
            return {
                ...state,
                formLoading: false,
                error: error.message ? error : action.payload.error,
                updateBatchesErrors: update_batches_failure_errors
            }
        }
        case CREATE_BATCH_BEGIN:
            return {
                ...state,
                loading: true,
                notification: null,
                error: null
            };

        case CREATE_BATCH_SUCCESS:
            return {
                ...state,
                loading: false,
                createdBatch: action.data,
            };

        case CREATE_BATCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                createdBatch: {
                }
            };

        case FETCH_TROLLEYS_BEGIN:
            return {
                ...state,//imp line
                loading: true,
                error: null,
                notification: null
            };

        case FETCH_TROLLEYS_SUCCESS:
            return {
                ...state,
                loading: false,
                trolleys: action.data.trolleys
            };

        case FETCH_TROLLEYS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case CREATE_BATCH_TROLLEY_BEGIN:
            return {
                ...state,
                loading: true,
                notification: null,
                error: null,
                formSuccess: false
            };

        case CREATE_BATCH_TROLLEY_SUCCESS:
            return {
                ...state,
                loading: false,
                batchedTrolley: action.data.batch_trolley,
                formSuccess: true
            };

        case CREATE_BATCH_TROLLEY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case FETCH_ORDER_BEGIN:
            return {
                ...state,//imp line
                loading: true,
                notification: null,
                error: null
            };

        case FETCH_ORDER_SUCCESS:
            const bookings = action.data.bookings.map(function (el) {
                let o = Object.assign({}, el);
                o.isChecked = false;
                return o;
            })
            return {
                ...state,
                loading: false,
                bookings: bookings,
                // bookings: action.data.bookings,
                pagination: action.data.pagination,
                orderFilters: action.data.filters,
            };

        case FETCH_ORDER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                bookings: []
            };

        case UPDATE_SELECTED_ORDERS:

            const bookingArr = state.bookings.map(function (booking) {

                if(booking.id === action.data) {
                    return {
                        ...booking,
                        isChecked: action.isChecked
                    }
                }
                return booking
            });


            return {
                ...state,
                loading: false,
                bookings: bookingArr
            };

        case UPDATE_ORDER_FILTER:
            return {
                ...state,
                selectedOrder: action.data
            };
        case UPDATE_ORGANISATION_FILTER:
            return {
                ...state,
                selectedOrganisation: action.data
            };

        case UPDATE_USER_FILTER:
            return {
                ...state,
                selectedUser: action.data
            };
        case UPDATE_INTERNATIONAL_FILTER:
            return {
                ...state,
                selectedInternational: action.data
            };
        case UPDATE_SHIPPING_FILTER:
            return {
                ...state,
                selectedShipping: action.data
            };
        case UPDATE_CARRIER_FILTER:
            return {
                ...state,
                selectedCarrier: action.data
            };

        case UPDATE_SHIPPINGMETHOD_FILTER:
            return {
                ...state,
                selectedShippingMethod: action.data
            }

        case UPDATE_MAXVOLUME_FILTER:
            return {
                ...state,
                selectedMaxVolume: action.data
            }
        case UPDATE_FROMDATE_FILTER:
            return {
                ...state,
                selectedFromDate: action.data
            }
        case UPDATE_TODATE_FILTER:
            return {
                ...state,
                selectedToDate: action.data
            }
        case UPDATE_STOCK_INFO_BEGIN:/*update stock info*/
            return {
                ...state,
                loading: true,
                notification: null,
                error: null
            };
        case UPDATE_STOCK_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                stockInfo: action.data
            };
        case UPDATE_STOCK_INFO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        case SELECT_ORDER_BEGIN:
            return {
                ...state,
                // loading: true,
                error: null,
                notification: null,
            };

        case SELECT_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                selectOrder: action.data
            };

        case SELECT_ORDER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case FETCH_SLOTS_BEGIN:
            return {
                ...state,//imp line
                loading: true,
                notification: null,
                error: null
            };

        case FETCH_SLOTS_SUCCESS:
            return {
                ...state,
                loading: false,
                slots: action.data
            };

        case FETCH_SLOTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        /* Endpoint 8.... Display created batch with slots and order ID...*/

        case FETCH_CREATED_BATCH_BEGIN:
            return {
                ...state,//imp line
                //loading: true,
                formLoading: true,
                notification: null,
                error: null
            };
        case FETCH_CREATED_BATCH_SUCCESS:
            return {
                ...state,
                formLoading: false,
                newBatch: action.data.batch,
                createdBatch: action.data.batch,
                filters: action.data.filters
            };

        case FETCH_CREATED_BATCH_FAILURE:
            return {
                ...state,
                formLoading: false,
                error: action.payload.error,
            };
        /*End point 9 ....update picker/packer/status........*/

        case UPDATE_STATUSES_SUCCESS:
            return {
                ...state,
                loading: false,
                createdBatch: action.data,
                newBatch: action.data,
                notification: "Batch updated"
            };

        case UPDATE_STATUSES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        /*End point 10...To get the lsit of Picker & packer in the system.....*/

        case FETCH_PICKER_PACKER_BEGIN:
            return {
                ...state,//imp line
                notification: null,
                loading: true,
                error: null
            };
        case FETCH_PICKER_PACKER_SUCCESS:
            return {
                ...state,
                loading: false,
                pickerPacker: action.data
            };
        case FETCH_PICKER_PACKER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case CLEAR_BATCHES:
            const createdBatch = state.createdBatch,
                batchedTrolley = state.batchedTrolley
            return {
                ...state,
                ...initialState,
                createdBatch: createdBatch,
                batchedTrolley: batchedTrolley
            }

        default:
            // Always have a default case in case a reducer doesn't complete
            return state;
    }
};
