import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {Drawer, Grid, Typography, Button, Hidden} from '@material-ui/core';
import Cancel from '@material-ui/icons/Close';
import CloudDownload from '@material-ui/icons/CloudDownload';
import {CSVLink} from "react-csv";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  toolbarButton: {
    textDecoration: 'none',
    color: 'inherit'
  }
}));

const OrdersEditBar = props => {
  const {
    selectedIds,
    selectedOrders,
    className,
    onCancel,
    onDuplicate,
    onDownload,
    ...rest
  } = props;

  const rejectKeys = (item, keys, addedColumns) => {
    let exportedItems = Object.assign({}, item)
    addedColumns.forEach(addedColumn => exportedItems[addedColumn.label] = exportedItems[addedColumn.value] ? exportedItems[addedColumn.value][addedColumn.nestedValue] :  null)
    keys.forEach(key => (delete exportedItems[key]));
    return exportedItems
  }

  const classes = useStyles();
  const open = selectedIds.length > 0;
  const columnsSkippedForCsv = ['url', 'status_obj', 'order_type', 'booking_items', "warehouse", "warehouse_id", "shipper_id", "shipping_carrier_og"]
  const nestedColumnsAddedToCSV = [{
    label: 'sku',
    value: 'booking_items',
    nestedValue: 'length'
  },
  { label: 'warehouse_name', 
    value: 'warehouse', 
    nestedValue: 'name'
  }]

  const canCancel = selectedOrders.filter(order => ['info_received', 'no_stock', 'amending'].includes(order.status)).length === selectedOrders.length
  let exportItems = selectedOrders.map((order) => (rejectKeys(order, columnsSkippedForCsv, nestedColumnsAddedToCSV)))

  return (
    <Drawer
      anchor="bottom"
      open={open}
      // eslint-disable-next-line react/jsx-sort-props
      PaperProps={{elevation: 1}}
      variant="persistent"
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Grid
          alignItems="center"
          container
          spacing={2}
        >
          <Hidden smDown>
            <Grid
              item
              md={3}
            >
              <Typography
                color="textSecondary"
                variant="subtitle1"
              >
                {selectedIds.length} selected
              </Typography>
            </Grid>
          </Hidden>
          <Grid
            item
            md={6}
            xs={12}
          >
            <div className={classes.actions}>
              <Button onClick={onCancel} disabled={!canCancel}>
                <Cancel className={classes.buttonIcon}/>
                Cancel Order
              </Button>
              <Button onClick={onDownload}>
                <CloudDownload className={classes.buttonIcon}/>
                <CSVLink className={classes.toolbarButton} data={exportItems}>Download CSV</CSVLink>
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </Drawer>
  );
};

OrdersEditBar.propTypes = {
  className: PropTypes.string,
  onDownload: PropTypes.func,
  onCancel: PropTypes.func,
  onDuplicate: PropTypes.func,
  // selected: PropTypes.array.isRequired
};

export default OrdersEditBar;
