import React, {PureComponent} from 'react';
import {withStyles, Typography, Tabs, Tab} from '@material-ui/core';
import {connect} from 'react-redux';
import General from './Components/General/General';
import {fetchCurrentUser} from 'actions/userActions';
import Security from './Components/Security/Security';

class Profile extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            id: this.props.id
        }
    }

    handleChange = (event, value) => {
        this.setState({value});
    }

    componentDidMount() {
        if(this.state.id) this.props.dispatch(fetchCurrentUser(this.state.id));
    }


    render() {
        const {classes, currentUser, error, notification} = this.props;
        const {value} = this.state;

        return (
            <div className={classes.root}>
                <Typography variant="h2" style={{fontSize: '11px', marginBottom: '8px'}}>SETTINGS</Typography>
                <Typography variant="h1" style={{fontSize: '24px'}}>Manage your profile...</Typography>

                <div className={classes.tabsPanel}>
                    <Tabs className={classes.tabs} classes={{indicator: classes.tabsIndicator}} value={value} onChange={this.handleChange}>
                        <Tab label="General" />
                        <Tab label="Security" />
                    </Tabs>
                    <div className={classes.tabChildContainer}>
                        {value === 0 && <General currentUser={currentUser} error={error} notification={notification} />}
                        {value === 1 && <Security currentUser={currentUser} />}
                    </div>
                </div>
            </div>
        )
    }
}

const styles = theme => ({
    root: {
        padding: theme.spacing(3)

    },
    tabsPanel: {
        flexGrow: 1,
        marginBottom: '50px'
    },
    tabs: {
        marginTop: 16,
        borderBottom: '1px solid #ccc',
        marginBottom: '24px'
    },
    tabsIndicator: {
        backgroundColor: '#626d7a'
    },
    tabChildContainer: {

    }
})

const mapPropsToState = (state, ownProps) => ({
    ...ownProps,
    id: state.reduxTokenAuth.currentUser.attributes.id,
    currentUser: state.usersReducer.currentUser,
    error: state.usersReducer.error,
    notification: state.usersReducer.notification
})
export default connect(mapPropsToState)(withStyles(styles)(Profile));


