import React, { useState, useEffect } from 'react';
import { 
  withStyles,  
  Button,
  Link,
  Grid,
  Divider,
  Typography, } from '@material-ui/core';
import { connect } from 'react-redux';
import SuccessSnackbar from 'components/Help/SuccessSnackbar';
import { useDispatch } from 'react-redux';
import { createOrganisation, setCurrentOrganisation } from 'actions/organisationActions';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

function Organisation(props) {
    const { classes, error, history } = props;
    const [values, setValues] = useState({});
    const dispatch = useDispatch();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    let inputRef = React.createRef("form");

    const fields =
      [
        { value: 'name', label: 'Name', required: ['required'], errorMessages: ['is required'] },
        { value: 'web_url', label: 'Website', required: ['required'], errorMessages: ['is required'] },
        { value: 'email', label: 'Contact Email', required: ['required', 'isEmail'], errorMessages: ['is required', 'please use a valid email'] },
        { value: 'phone', label: 'Contact Phone', required: ['required'], errorMessages: ['is required'] },
        { value: 'address_line_1', label: 'Address Line 1', required: ['required'], errorMessages: ['is required'] },
        { value: 'address_line_2', label: 'Address Line 2', required: [], errorMessages: [] },
      ]


    useEffect(() => {
        setValues({
          name: '',
          web_url: '',
          address_line_1: '',
          address_line_2: '',
          email: '',
          phone: '',
        });
    }, []);

    useEffect(() => {
      if (error && error.statusText) setOpenSnackbar(true)
    }, [error])

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };
    
    
    const handleChange = event => {
      event.persist();
  
      setValues({
        ...values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      });
    };
  
    const handleSubmit = event => {
  
      event.preventDefault();
      var formData = new FormData();
  
      Object.keys(values).forEach(e => {
        formData.append(e, values[e]);
    });

  
    dispatch(createOrganisation(formData))
        .then((result) => {
          if (result) {
            // result is null when in error
            setOpenSnackbar(true);
            dispatch(setCurrentOrganisation(result))

            history.push('/');

          }
        });
    };

    return (
          <div className={classes.root}>
              <SuccessSnackbar
                  onClose={handleSnackbarClose}
                  open={openSnackbar}
                  error={(error && error.statusText) ? error.statusText : null}
                  messageText="Successfully saved"
              />

              <Typography variant="h1" style={{ fontSize: '24px', textAlign: 'center' }}>Create New Organisation</Typography>
              <Typography variant="subtitle2" style={{marginBottom: 10, textAlign: 'center' }}>You are not an active member of any organisation</Typography>

              <ValidatorForm
                ref={inputRef}
                onSubmit={handleSubmit}
              >
                <Grid
                    container
                    spacing={4}
                  >
                    {fields.map((field) => (
                      <Grid
                        key={field.value}
                        item
                        md={6}
                        xs={12}
                      >
                        <TextValidator
                          fullWidth
                          label={field.label}
                          name={field.value}
                          onChange={handleChange}
                          value={values[field.value] || ""}
                          error={error ? Boolean(error[field.value]) : false}
                          helperText={error ? error[field.value] : false}
                          validators={field.required}
                          errorMessages={field.errorMessages}
                        />
                      </Grid>
                    ))}

                </Grid>
                <Divider />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color='secondary'
                    className={classes.submit}
                >
                    Create
                </Button>

              </ValidatorForm>
              <div className={classes.footer}>
                <Link to="/auth/login">Not you? Log into another account</Link>
              </div>
          </div>
    )
}

const styles = theme => ({
    root: {
        padding: theme.spacing(3)
    },
    submit: {
      marginTop: theme.spacing(3),
      color: 'white',
      backgroundImage: 'linear-gradient(#45b5f2, #249ddd)',
      boxShadow: 'none',
      textTransform: 'none',
      fontWeight: 'bolder',
      cursor: 'pointer'
    },
    footer: {
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.54)',
      padding: '20px',
      fontSize: '10px',
      lineHeight: 'normal',
      cursor: 'pointer'

    },
})

const mapPropsToState = (state, ownProps) => ({
    ...ownProps,
    currentOrganisation: state.organisationsReducer.currentOrganisation,
    error: state.organisationsReducer.error,
})

export default connect(mapPropsToState)(withStyles(styles)(Organisation));


