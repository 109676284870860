import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import {UID} from 'AppConstants';
import { withStyles } from '@material-ui/core/styles';
import SKUNewForm from 'components/FormsPleaseStopDoingThis/SKUForm/SKUNewForm';

function ResponsiveDialog(props) {

    const [open, setOpen] = React.useState(true)

    const { classes, permittedToAccess } = props;

    function handleClose(){
        setOpen(false);
        setTimeout(() => {
            props.history.push(`/user/${UID}/space/products/`);
        }, 100);
    }

    if (!permittedToAccess("products:create")) {props.history.push(`/user/${UID}/space/products/`)}
    
    return (
        <Dialog
            fullScreen={props.fullScreen}
            maxWidth='lg'
            fullWidth={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            overflow="visible"
        >
            <div className={classes.dialog}>
                <SKUNewForm handleClose={handleClose} countries={props.countries}/>
            </div>
        </Dialog>
    );
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

const styles = theme => ({
    root: {
        minHeight: '400px',
        flexWrap: 'wrap'
    },
    dialog: {
        background: '#eeeeee',
        // overflowY: 'scroll',
        // '-webkit-overflow-scrolling': 'touch',
    },
    dialogContent: {
        minHeight: '800px'
    },
    moreActions: {
        position: 'absolute',
        right: 8,
        top: 8,
    },
  });

export default withMobileDialog({
    breakpoint: 'sm',
})(withStyles(styles)(ResponsiveDialog));


