import React from 'react'
import { makeStyles } from '@material-ui/styles';

const ProgressBar = props => {
  const {percent, color} = props
  const classes = useStyles();
  // const [percentage, setPercentage] = useState(props.percentage);
    return (
       <div className={classes.root}>
         <div className={classes.barConatiner}>
           <div className={classes.progressTracker} style={{width: percent+ "%", backgroundColor: color}}/>
         </div>
         <div className={classes.progressLabel} >{percent} %</div>
       </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
       width: '100%',
       height: 20,
       margin: '15px auto',
       borderRadius: 10,
       display: 'flex',
       alignItems: 'center'
    },
    barConatiner: {
      flexGrow: 1,
      background: '#e3e3e3',
      borderRadius: 10,
      height: 5,
      display: 'flex',
    },
    progressTracker: {
      borderRadius: 10,
      height: 5,
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    progressLabel: {
      marginLeft: 10
    }


}));

export default ProgressBar
