import keepspaceApi from 'services/keepspaceApi';

export const FETCH_FEATUREFLAG_BEGIN = 'FETCH_FEATUREFLAG_BEGIN';
export const FETCH_FEATUREFLAG_SUCCESS = 'FETCH_FEATUREFLAG_SUCCESS';
export const FETCH_FEATUREFLAG_FAILURE = 'FETCH_FEATUREFLAG_FAILURE';

export const fetchFlagsBegin = () => ({
    type: FETCH_FEATUREFLAG_BEGIN
});

export const fetchFlagsSuccess = flags => ({
    type: FETCH_FEATUREFLAG_SUCCESS,
    payload: { flags }
});

export const fetchFlagsFailure = error => ({
    type: FETCH_FEATUREFLAG_FAILURE,
    payload: { error }
});

export function fetchFlags() {
    return dispatch => {

        let interval = setInterval(() => {
            if (window.localStorage.getItem('uid') !== null) {
                clearInterval(interval);
                dispatch(fetchFlagsBegin());
                return keepspaceApi.getFeatureFlags()
                    .then(json => dispatch(fetchFlagsSuccess(json)))
                    .catch(error => dispatch(fetchFlagsFailure(error)));
            }
            else {
                // console.log("Waiting on authentication to check features")
            }
        }, 1000);

        
    };
}

export function hasFeature(features, featureName) {
    let hasFeature = false;
    if (features) {
        // eslint-disable-next-line array-callback-return
        features.map(feature => {
            if (feature.feature_title_external === featureName) {
                hasFeature = true;
            }
        }
        )
    }
    return hasFeature;
}