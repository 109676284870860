import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import OrderCard from '../OrderCard';
import SkusCard from '../SkusCard';
import ReportsCard from '../ReportsCard';
import StatusCell from 'components/Table/OrdersTable/StatusCell'
import ProgressBar from 'components/ProgressBar/ProgressBar'

const useStyles = makeStyles((theme) => ({
	root: {
		flexWrap: 'wrap',
		width: '100%',
	},
	secondItem: {
		marginTop: 16
	},
	progressBar: {
		display: 'flex',
		alignItems: 'center'
	},
	status: {
		// minWidth: '10%',
		paddingLeft: '20px',
		textAlign: "right"
	},
	flexBox: {
		display: 'flex',
		flexDirection: 'column'
	}
}));

const Overview = props => {
	const { order, countries, report, getLabel, loadingLabel } = props;
	const classes = useStyles();

	if (order.country != "") {
		countries.forEach(function (country, index) {
			if (country.code == order.country) {
				order.country = country.text
			}
		})
	}

	const customerHeadRows = [
		{ name: "created_at", label: "Created" },
		{ name: "reference", label: "Reference" },
	]
	const customerRows = [
		{ name: "contact_name", label: "Name" },
		{ name: "company_name", label: "Company" },
		{ name: "email", label: "Email" },
		{ name: "contact_phone", label: "Phone" }
	]

	const skuHeadRows = [
		{ name: "sku_count", label: "Total skus" },
		{ name: "total_products", label: "Total products" },
	]

	const addressRows = [
		{ name: "address", label: "Address" },
		{ name: "address2", label: "Address Line 2" },
		{ name: "suburb", label: "Suburb / City" },
		{ name: "state", label: "State" },
		{ name: "city", label: "Postcode" },
		{ name: "country", label: "Country" }
	]

	const shippingHeadRows = [
		{ name: "shipper_name", label: "Shipped" },
		{ name: "processed_on", label: "Processed" },
	]
	const shippingRows = [
		{ name: "warehouse_name", label: "KeepSpace Warehouse" },
		{ name: "no_of_parcels", label: "Number of parcels" },
		{
			name: "booking_parcels", label: "Dimensions", children: [
				{ name: "parcel_height", label: "H" },
				{ name: "parcel_width", label: "W" },
				{ name: "parcel_length", label: "L" }
			]
		},
		{ name: "actual_weight", label: "Actual Weight", suffix: "Kg" },
		{ name: "cubic_volume", label: "Cubic Volume", suffix: "m", supText: "3" },
		{ name: "cubic_weight", label: "Cubic Weight", suffix: "Kg" },
		{ name: "shipping_method", label: "Method" },
		{ name: "signature_on_delivery", label: "Signature on delivery" },
		{ name: "delivery_instructions", label: "Delivery instructions" },
		{ name: "tracking_id", label: "Tracking number" },
	]
	const inboundHeadRows = [
		{ name: "warehouse_name", label: "KeepSpace Warehouse" },
		{ name: "shipper_name", label: "Carrier" },
		{ name: "eta", label: "Estimated arrival" },
	]
	const inboundRows = [
		{ name: "reference", label: "Waybill/Consignment" },
	]

	return (
		<Grid
			className={classes.root}
			container
			spacing={2}
			direction="row"
		>
			<Grid
				item
				md={12}
				xs={12}
			>
				<div className={classes.progressBar}>
					<ProgressBar
						percent={order?.status_obj?.percentage}
						color={order?.status_obj?.color}
					/>
					<div className={classes.status} style={{ color: order?.status_obj?.color }}>
						<StatusCell status={order?.status_obj} />
					</div>
				</div>
			</Grid>
			<Grid
				item
				md={6}
				xs={12}
				className={classes.flexBox}
			>
				<OrderCard
					title={order?.booking_type === 'outbound' ? "Customer Info" : "Supplier Info"}
					order={order}
					headRows={customerHeadRows}
					rows={customerRows}
					printLabelAllowed={order?.booking_type === 'inbound'}
					getLabel={getLabel}
					loadingLabel={loadingLabel}
				/>
				<SkusCard
					title="SKU"
					order={order}
					headRows={skuHeadRows}
					items={order.booking_items}
				/>


			</Grid>
			<Grid
				item
				md={6}
				xs={12}
			>
				<OrderCard
					title="Address Info"
					order={order}
					headRows={[]}
					rows={addressRows}
					countries={countries}
				/>
				<OrderCard
					title="Shipping Info"
					order={order}
					headRows={order?.booking_type === 'outbound' ? shippingHeadRows : inboundHeadRows}
					rows={order?.booking_type === 'outbound' ? shippingRows : inboundRows}
					margin={true}
					tracking_button={order.tracking_link}
				/>
				{report?.length > 0 && <ReportsCard
					title="Order Process Report"
					margin={true}
					report={report}
				/>}
			</Grid>
		</Grid>
	);
};

Overview.propTypes = {
	className: PropTypes.string
};

export default Overview;
