
import { apiPath, authHeaders } from '../AppConstants';
import keepspaceApi from 'services/keepspaceApi';
import { apiErrorHandler } from 'utils/apiErrorHandler';
import * as Storage from 'utils/storage';
export const FETCH_CURRENT_USER_BEGIN = 'FETCH_CURRENT_USER_BEGIN';
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';
export const FETCH_CURRENT_USER_FAILURE = 'FETCH_CURRENT_USER_FAILURE'

export const UPDATE_CURRENT_USER_BEGIN = 'UPDATE_CURRENT_USER_BEGIN'
export const UPDATE_CURRENT_USER_SUCCESS = 'UPDATE_CURRENT_USER_SUCCESS'
export const UPDATE_CURRENT_USER_FAILURE = 'UPDATE_CURRENT_USER_FAILURE'

export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const UPDATE_CREATE_USER = 'UPDATE_CREATE_USER'
export const CLEAR_CURRENT_USER_ERRORS = 'CLEAR_CURRENT_USER_ERRORS'
export const INITIAL_CURRENT_USER = 'INITIAL_CURRENT_USER';

function handleErrors(response) {
    if (!response.ok) {
        apiErrorHandler(response)
        throw Error(response.statusText);
    }
    return response;
}

export function fetchCurrentUser(id) {
    return dispatch => {
        dispatch(fetchCurrentUserBegin());
        return fetch(
            (apiPath + '/users/' + id),
            {
                method: 'GET',
                headers: authHeaders
            }
        )
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(fetchCurrentUserSuccess(json));
                return json;
            })
            .catch(error => {
                console.log('catch error', error)
                dispatch(fetchCurrentUserFailure(error))
            });
    };
}

export function updateTerms() {
    return dispatch => {
        return fetch(
            (apiPath + '/users/accept_tos'),
            {
                method: 'POST',
                headers: authHeaders
            }
        )
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(fetchCurrentUserSuccess(json));
                return json;
            })
            .catch(error => {
                console.log('catch error', error)
                dispatch(fetchCurrentUserFailure(error))
            });
    };
}

export const fetchCurrentUserBegin = () => ({
    type: FETCH_CURRENT_USER_BEGIN
});

export const fetchCurrentUserSuccess = (user) => ({
    type: FETCH_CURRENT_USER_SUCCESS,
    payload: { user }
})

export const fetchCurrentUserFailure = error => ({
    type: FETCH_CURRENT_USER_FAILURE,
    payload: { error }
});


export function updateUser(id, data) {
    return dispatch => {
        dispatch(updateUserBegin())
        return keepspaceApi.updateUser(id, data)
            .then(json => {
                dispatch(updateUserSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(updateUserFailure(error))

            });
    }
}

export function saveChanges(signatureOnDelivery, shippingDuties) {
    return dispatch => {
        return fetch(

            (apiPath + '/users/' + signatureOnDelivery)
        )

    }
}

export function clearUserErrors() {
    return dispatch => {
        dispatch(clearCurrentUserErrors())
    }
}

export function clearCurrentUser() {
    return dispatch => {
        dispatch(clearUserErrors())
    }
}

export const clearCurrentUserErrors = () => ({
    type: CLEAR_CURRENT_USER_ERRORS
})

export const updateUserBegin = () => ({
    type: UPDATE_CURRENT_USER_BEGIN
})

export const updateUserSuccess = (user) => ({
    type: UPDATE_CURRENT_USER_SUCCESS,
    payload: { user }
})

export const updateUserFailure = error => ({
    type: UPDATE_CURRENT_USER_FAILURE,
    payload: { error: (error.response) ? error.response.data : 'Something went wrong. Please try again' }

});

export const createUserFailure = error => ({
    type: CREATE_USER_FAILURE,
    payload: { error }
});

export const createUserSuccess = () => ({
    type: CREATE_USER_SUCCESS,
});

export const updateCreateUser = (feature, value) => ({
    type: UPDATE_CREATE_USER,
    payload: { feature, value }
});

export const internalClearCurrentUser = () => ({
    type: INITIAL_CURRENT_USER
});



export const removeUserDetails = async () => {
    await Storage.removeUserInfo();
    window.location.href = "/auth/login";
}
