import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    setNewOrderInProgress,
    destroyNewOrder,
} from 'actions/orderActions'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import NewOrderForm from 'components/FormsPleaseStopDoingThis/NewOrderForm/NewOrderForm';

import { withStyles } from '@material-ui/core/styles';

import {UID} from 'AppConstants';

const styles = theme => ({
    dialog: {
    },
    flexGrow: {
        flexGrow: 1,
    },
    newOutboundButton: {
        margin: theme.spacing
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },

})


const mapStateToProps = (state, ownProps) => ({
    //orders: state.orderReducer.orders,
    //loading: state.orderReducer.loading,
    //error: state.orderReducer.error,
    newOrderInProgress: state.orderReducer.newOrderInProgress,
    newOrderItems: state.orderReducer.newOrderItems,
    //newOrderError: state.orderReducer.newOrderError,
    newOrderObject: state.orderReducer.newOrderObject,
    ...ownProps,
})


class OrdersAdd extends Component {

    constructor(props) {
        super(props)
        this.state = {
            open: true,
            anchorEl: false,
            shouldDestroyOrder: false,
        }
        this.handleClose = this.handleClose.bind(this)
        this.handleMoreActionsClose = this.handleMoreActionsClose.bind(this)
        this.handleOrderMoreActions = this.handleOrderMoreActions.bind(this)
    }

    componentDidMount() {
        this.props.dispatch(setNewOrderInProgress(true));
    }

    componentWillUnmount() {
        if (this.props.newOrderInProgress &&
            this.props.newOrderItems.length === 0 &&
            this.props.newOrderObject === null
        ) {
            this.props.dispatch(destroyNewOrder());
        }
        if (this.state.shouldDestroyOrder) {
            this.props.dispatch(destroyNewOrder());
        }

    }

    handleClose() {
        this.setState({
            open: false,
        })
        setTimeout(() => {
            this.props.history.push(`/user/${UID}/orders/summary/`);
        }, 100);

    }

    handleDestroyNewOrderButton() {
        this.setState({
            shouldDestroyOrder: true
        })
        this.handleClose()
    }

    handleOrderMoreActions(event) {
        this.setState({
            anchorEl: event.currentTarget
        })
    }

    handleMoreActionsClose(event) {
        this.setState({
            anchorEl: null
        })
    }

    render() {
        const { classes } = this.props
        return (
            <Dialog
                fullScreen={this.props.fullScreen}
                maxWidth='xl'
                fullWidth={true}
                open={this.state.open}
                onEscapeKeyDown={this.handleClose}
                onClose={this.handleClose}
                onBackdropClick={this.handleClose}
                aria-labelledby="Add new Order"
            >
                <div className={classes.dialog}>
                    <DialogTitle id='Add new Order'>Add new Order</DialogTitle>
                </div>

                <NewOrderForm />

                <DialogActions>

                    <Button onClick={() => this.handleDestroyNewOrderButton()} color="primary">
                        Cancel Order
                    </Button>

                    <Button onClick={() => this.handleClose()} color="primary">
                        Minimize
                    </Button>

                </DialogActions>

            </Dialog>
        )
    }
}


export default connect(mapStateToProps)(withMobileDialog({ breakpoint: 'sm' })(withStyles(styles)(OrdersAdd)));
