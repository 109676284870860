import React from "react";
import {
  ListItem,
  ListItemText,
  Button,
  Card,
  TextField,
  makeStyles,
} from "@material-ui/core";

// Project Components
import BundleComponent from "components/OrderItem/OrderItem";

// Local Components
import SKUDialog from "./SKUDialog";

const Products = (props) => {
  const {
    bundleComponents,
    quantityVariation,
    addBundleComponent,
    totalProducts,
    deleteComponent
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleSkuOpen = () => setOpen(true);
  const title = 'Products in the Bundle'

  return (
    <div>
      <Card className={classes.card} >

        <>
          <div className={classes.header}>
            <div className={classes.title}> {title}</div>
            <Button onClick={() => handleSkuOpen()}
              variant="contained"
              color="primary"
            >
              Add SKU to bundle
          </Button>

          </div>

          {bundleComponents.map((line) => (
            <BundleComponent
              key={line.item.id}
              className={classes.listItem}
              line={line}
              deleteItemFunction={() =>
                deleteComponent(line.item.id)
              }
              quantityChangeCallback={(event) => {
                let qtyVar = event.target.value
                if (qtyVar) quantityVariation(line.item.id, qtyVar);
              }}
            />
          ))}

          {
            (totalProducts > 0) ? <ListItem className={classes.gutter}>
              <ListItemText> </ListItemText>
              <TextField
                id="outlined-read-only-input"
                label="Total Products"
                value={totalProducts}
                className={classes.textField}
                inputProps={{
                  readOnly: true,
                  style: {
                    textAlign: "right",
                    padding: "8px 14px",
                    width: 140,
                  },
                }}
                variant="outlined"
              />
            </ListItem> : null
          }

          <SKUDialog
            open={open}
            setOpen={setOpen}
            addBundleComponent={addBundleComponent}
          />
        </>
      </Card>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  pointer: {
    cursor: "pointer",
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '21px'
  },
  title: {
    fontSize: '16px',
    fontWeight: 500
  },
  gutter: {
    paddingLeft: '16px',
    paddingRight: '22px',
    paddingBottom: '22px'
  }
}));

export default Products;
