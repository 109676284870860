import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/styles";
import { Breadcrumbs as MUIBreadcrumbs, Typography } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useLocation, Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  breadcrumb: {
    textTransform: "capitalize",
    marginBottom: 0,
  },
}));

// should be refactored to use route config
const disabledBreadcrumbs = [
  "/admin/warehouses/summary",
  "/admin/warehouses/inventory",
  "/admin/warehouses/suppliers",
];

const Breadcrumbs = ({ label, breadcrumbs = [] }) => {
  const styles = useStyles();
  const location = useLocation();

  const isDisabled = () => {
    let check = true;

    disabledBreadcrumbs.forEach((uri) => {
      if (location.pathname === uri) {
        check = false;
      }
    });
    return check;
  };

  const breadcrumbItems = useMemo(() => {
    if (location.pathname.includes("add")) {
      return [
        ...breadcrumbs,
        {
          label: "Add",
          path: location.pathname,
        },
      ];
    } else if (location.pathname.includes("edit")) {
      return [
        ...breadcrumbs,
        {
          label: "Edit",
          path: location.pathname,
        },
      ];
    }

    return breadcrumbs;
  }, [breadcrumbs, location.pathname]);

  const renderBreadcrumbs = () => {
    if (isDisabled()) {
      return breadcrumbItems.map((breadcrumb) => {
        return (
          <Link to={breadcrumb.path} key={`${breadcrumb.path}_${breadcrumb.label}`}>
            <Typography color="textPrimary" className={styles.breadcrumb}>
              {breadcrumb.label}
            </Typography>
          </Link>
        );
      });
    }
  };

  return (
    <MUIBreadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      <Typography color="inherit">{label}</Typography>
      {renderBreadcrumbs()}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
