import React, {Component} from 'react'
import store from '../../index';
import keepspaceApi from "../../services/keepspaceApi";
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import {
    CircularProgress,
    Paper
} from '@material-ui/core';
import {signOutUser, verifyCredentials} from 'redux-token-auth-config';
import * as qs from 'query-string';
import {connect} from 'react-redux';
import * as Storage from 'utils/storage';

const styles = theme => ({

    paper: {
        position: 'relative',
        height: '100%',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        boxShadow: 'none'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginBottom: '20px'
    },
    submit: {
        marginTop: theme.spacing(3),
        color: 'white',
        backgroundImage: 'linear-gradient(#45b5f2, #249ddd)',
        boxShadow: 'none',
        textTransform: 'none',
        fontWeight: 'bolder',
    },
    subtitle: {
        width: '100%',
        textAlign: 'center',
        padding: '8px',
        fontSize: 16,
        lineHeight: 'normal'
    },
    header: {
        fontSize: theme.spacing(3.5),
        fontWeight: 'lighter',
        padding: '50px 50px 32px'
    },
});


const mapStateToProps = (state) => ({
    reduxTokenAuth: state.reduxTokenAuth
})
const mapDispatchToProps = dispatch => ({
    signOutUser: () => dispatch(signOutUser()),
    verifyCredentials: () => dispatch(verifyCredentials())
})

class MagicSigninLink extends Component {
    constructor(props) {
        super(props);
        const params = qs.parse(this.props.location.search)

        if(params['uid'].includes(" ")) {
            params['uid'] = params['uid'].replace(/ /g, '+')
        }

        this.state = {
            uid: params['uid'],
            client: params['client'],
            accessToken: params['access-token'],
            organisationUid: params['organisation-uid'],
        };

    }


    async componentDidMount() {
        try {
            const token = await Storage.getItem('access-token');
            if(!!token && token !== "undefined") {
                await this.logOutUser(); // Logout existing user
            }
            const isLSUpdated = await this.updateLS();
            // console.log('store is', store);
            await verifyCredentials(store); // Verify new user credentials
            const isHeadersUpdated = await keepspaceApi.refreshAuthToken();
            /*----Redirecting user only if local storage and headers are updated with new values---*/
            if(isLSUpdated && isHeadersUpdated) {
                this.props.history.push("/");
            }
        }
        catch(e) {
            // console.log('>>>> component error >>>>', e);
            this.props.history.push("/");
        }

    }
    /*
    * Update localstorage with magic values
    */
    async updateLS() {
        try {
            // console.log('>>>> this.state >>>', this.state);
            if(this.state.accessToken) {
                await Storage.setItem('uid', this.state.uid)
                await Storage.setItem('client', this.state.client)
                await Storage.setItem('access-token', this.state.accessToken)
                await Storage.setItem('organisation-uid', this.state.organisationUid)
                await Storage.removeItem('expiry')
                await Storage.removeItem('token')
            }
            return true
        }
        catch(e) {
            return false
        }
    }

    async logOutUser() {
        try {
            await this.props.signOutUser();
        } catch (e) {
        } finally {
            await Storage.removeUserInfo();
            return true;
        }
    }


    render(props) {
        const {classes} = this.props;
        return (
            <Paper className={classes.paper}>
                <div className={classes.header}> Now Signing in...</div>
                <CircularProgress />
                <div className={classes.subtitle}>{this.state.uid}</div>
            </Paper>
        )
    }
}

MagicSigninLink.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MagicSigninLink));
