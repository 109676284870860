import React from 'react'
import {Route} from 'react-router-dom';

import Login from 'views/admin/auth/Login';

import CssBaseline from '@material-ui/core/CssBaseline';
import withStyles from '@material-ui/core/styles/withStyles';

// import Particles from './Particles';
//import login from "assets/img/adminPage.jpg";
import password from "assets/img/password.png";
import register from "assets/img/LoginBackgroundImage.jpg";
import { useAbsoluteLayout } from 'react-table';
import { Translate } from '@material-ui/icons';
import HeartIcon from '@material-ui/icons/Favorite';

const path = '/admin/auth';

const styles = theme => ({
    main: {
        height: '100vh',
        marginRight: 'none',
        color: 'rgba(0, 0, 0, 0.54)',
        "& a": {
            color: theme.palette.keepspaceBlue.main, textDecoration: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            // height: '100%',
            overflow: 'auto',
            marginLeft: 'auto'
        },
        [theme.breakpoints.up('md')]: {
            width: 400,
            // height: '100%',
            overflow: 'auto',
            margin: 'auto'
            
        },
    },
    container: {
        width: 'auto',
        height: '100%',
        display: 'block', // Fix IE 11 issue.
    },
    flex: {
        display: 'flex',
        background: "white"
    },
    imageBox: {
        backgroundImage: `url(${register})`,
        // position: 'relative',
        textAlign: 'center',
        color: 'white',
        width: '100%',
        backgroundSize: 'cover',
        repeat: 'none',
        height: '100vh',
        width: '50vw',
        //padding:'10%',
        [theme.breakpoints.down('xs')]: {
            width: '0',
            
        }
    },
    textLabel: {
        marginTop: '20%',
        // fontSize: ,
        // position: 'absolute',
        // top: '39%',
        // left: '59%',
        // transform: 'translate(-50%, -50%)',
        textAlign: 'left',
        width: '100%',
        padding: '30px',  
    },  
    title: {
        marginBottom: '30px',
        fontSize: '80px',
        fontWeight: 500,
        lineHeight: '85px'
    },
    subtitle: {
        fontSize: '24px',
        marginBottom: '20px'
    }
})

const base = (theme) => ({
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'right',
})

const AdminAuthLayout = (props) => {
    const {classes} = props;
    // const className = classes.image

    return (
        <div className={classes.flex}>
            <div className={classes.imageBox}>
                
                <div className={classes.textLabel}>
                
                    <div className={classes.title}>Welcome Back<HeartIcon style={{ fill: 'white', fontSize: '80px' }} /></div> 
                    <div className={classes.subtitle}>We love the work you do and appreciate everything you are </div>
                    <div className={classes.subtitle}>Lets do this!</div>
                </div>
            </div>
            <main className={classes.main}>
                <CssBaseline/>

                <>
                    <Route path={`${path}/login/`} render={(props) => <Login history={props.history}/>}/>
                </>

            </main>
        </div>
    )
}

export default withStyles(styles)(AdminAuthLayout);
