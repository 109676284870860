import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import GeneralSettings from './components/GeneralSettings';
import { connect } from 'react-redux';

import checkPermission from "components/checkPermission";

const useStyles = () => ({
  root: {}
});

class General extends React.Component  {

  render() {
    const { className, title, classes, error, values, handleSubmit, onImageUpload, handleChange, action, currentOrganisationUser={}, ...rest } = this.props;

    let permissions = action === "Create" ? true : checkPermission(currentOrganisationUser.role, "organisations:edit")

    return (
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <GeneralSettings 
            title={title} 
            error={error}
            values={values}
            handleSubmit={handleSubmit}
            onImageUpload={onImageUpload}
            handleChange={handleChange}
            permissions={permissions}
            action={action}
          />
        </Grid>
      </Grid>
    );
  }
};

General.propTypes = {
  className: PropTypes.string
};

const mapPropsToState = (state, ownProps) => ({
    ...ownProps,
})

export default connect(mapPropsToState)(withStyles(useStyles)(General));
