import React, { memo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  Table,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import SuccessSnackbar from "components/Help/SuccessSnackbar";
import StatusCell from "./StatusCell";
import NoRecords from "layouts/AdminLayout/components/NoRecords";
import Pagination from "layouts/Pagination";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(3),
    border: "0px solid #eeeeee",
    borderRadius: "16px",
  },
  paper: {
    width: "100%",
    overflow: "auto",
    style: { border: "none" },
    color: "#40B5AD",
    marginBottom: theme.spacing(3),
  },
  marginBottom: {},

  themeText: {
    color: theme.palette.adminPrimary.dark,
  },
  tableHead: {
    backgroundColor: "#ffffff",
    boxShadow: "0px 6px 6px #00000029",
    border: "0px solid #eeeeee",
    "& th": {
      fontWeight: "bold",
    },
  },
  tableFont: {
    fontWeight: "600",
  },
  hidden: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  checkboxCell: {
    marginRight: 10,
  },
  pageTilesContainer: {
    display: "flex",
  },
  pageTile: {
    border: "1px #8080803d solid",
    height: 20,
    minWidth: 20,
    width: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 2,
    cursor: "pointer",
  },
  navigationTile: {
    border: "1px #8080803d solid",
    height: 20,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 2,
    cursor: "pointer",
  },
  shadow: {
    boxShadow: "0px 3px 6px #00000029",
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end",
    flexWrap: "wrap",
    "& .MuiTablePagination-actions": {
      display: "none",
    },
    "& .MuiTablePagination-input + .MuiTablePagination-caption": {
      display: "none",
    },
  },
  buttonsContainer: {
    "& > *": {
      margin: theme.spacing(1),
    },
    margin: "5px 49px",
    float: "right",
  },
  saveButton: {
    minWidth: "150px",
    color: "#40B5AD",
    fontWeight: 600,
    border: 2,
    boxShadow: " 6px 6px 6px #DEE5E7",
    borderRadius: "6px",
  },
  deleteButton: {
    color: "#E69A9A",
  },
  cancelButton: {
    minWidth: "150px",
    color: "#696969",
    fontWeight: 600,
  },
  btnLink: {
    height: "1em!important",
  },
  font: {
    fontWeight: 600,
  },
  popupText: {
    "& > *": {
      color: "#6E838E",
    },

    padding: "0 40px",
  },
});

export function InvoicesTable(props) {
  const {
    classes,
    params,
    match,
    data,
    pagination,
    loading,
    error,
    formSuccess,
    notification,
    getInvoices,
    activeFilters,
    cancelInvoice,
    clearInvoicesNotifications,
  } = props;

  const { id } = match.params;

  const [selectedRecord, setSelectedRecord] = useState({});
  const [openCancelAlert, setOpenCancelAlert] = useState(false);
  const dummyData = [...Array(10)];

  const handleChangePage = (event, page) => {
    params.per_page = pagination.per_page;
    params.page = page;
    getInvoices({ ...activeFilters, ...params });
  };

  const handleChangeRowsPerPage = (event) => {
    const perPage =
      event.target.value === "All"
        ? pagination.total_count
        : event.target.value;
    params.per_page = perPage;
    params.page = 1;
    getInvoices({ ...activeFilters, ...params });
  };

  const cancelInvoiceClick = () => {
    cancelInvoice(selectedRecord.id, params);
  };

  const openPdf = (row) => {
    if (row.pdf_url.url) {
      window.open(row.pdf_url.url, "_blank");
    }
  };

  const handleCancelAlertOpen = (record) => {
    setSelectedRecord(record);
    setOpenCancelAlert(true);
  };

  const handleCancelAlertClose = () => {
    setSelectedRecord({});
    setOpenCancelAlert(false);
  };

  useEffect(() => {
    if (formSuccess) {
      handleCancelAlertClose();
      getInvoices(params);
    }
  }, [formSuccess]);

  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        <SuccessSnackbar
          open={error || notification ? true : false}
          error={error ? error.error_message || error.error || error : null}
          messageText={notification}
          onClose={() => clearInvoicesNotifications()}
        />

        <Grid container className={classes.table}>
          <Table>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: 600 }}>
                  Id
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 600 }}>
                  Type
                </TableCell>
                {!id && (
                  <TableCell align="left" style={{ fontWeight: 600 }}>
                    Organisation
                  </TableCell>
                )}
                <TableCell align="center" style={{ fontWeight: 600 }}>
                  Published
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 600 }}>
                  Charge
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 600 }}>
                  Credit
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 600 }}>
                  Outstanding
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 600 }}>
                  Status
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 600 }}>
                  View
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 600 }}>
                  Download
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 600 }}>
                  Void
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {loading ? (
                <>
                  {dummyData.map((row) => (
                    <TableRow style={{ padding: 50 }}>
                      <TableCell style={{ width: 170 }}>
                        <Skeleton animation="wave" width="100%">
                          <Typography className={classes.font}>.</Typography>
                        </Skeleton>
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton animation="wave" width="100%">
                          <Typography>.</Typography>
                        </Skeleton>
                      </TableCell>
                      {!id && (
                        <TableCell align="center">
                          <Skeleton animation="wave" width="100%">
                            <Typography>.</Typography>
                          </Skeleton>
                        </TableCell>
                      )}
                      <TableCell align="center">
                        <Skeleton animation="wave" width="100%">
                          <Typography>.</Typography>
                        </Skeleton>
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton animation="wave" width="100%">
                          <Typography>.</Typography>
                        </Skeleton>
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton animation="wave" width="100%">
                          <Typography>.</Typography>
                        </Skeleton>
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton animation="wave" width="100%">
                          <Typography>.</Typography>
                        </Skeleton>
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton animation="wave" width="100%">
                          <Typography>.</Typography>
                        </Skeleton>
                      </TableCell>
                      <TableCell align="center">
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          <Skeleton
                            animation="wave"
                            variant="circle"
                            width={40}
                            height={40}
                          >
                            <IconButton
                              className={classes.iconButton}
                            ></IconButton>
                          </Skeleton>
                        </Grid>
                      </TableCell>
                      <TableCell align="center">
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          <Skeleton
                            animation="wave"
                            variant="circle"
                            width={40}
                            height={40}
                          >
                            <IconButton
                              className={classes.iconButton}
                            ></IconButton>
                          </Skeleton>
                        </Grid>
                      </TableCell>
                      <TableCell align="center">
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          <Skeleton
                            animation="wave"
                            variant="circle"
                            width={40}
                            height={40}
                          >
                            <IconButton
                              className={classes.iconButton}
                            ></IconButton>
                          </Skeleton>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <>
                  {data.map((row) => (
                    <TableRow hover key={row.id}>
                      <TableCell
                        align="center"
                        className={classes.themeText}
                        style={{ width: "150px" }}
                      >
                        {row.details_present ? (
                          <Link
                            to={`/admin/accounting/invoices/${row.id}/details`}
                            target="_blank"
                            className={classes.btnLink}
                          >
                            {row.id}{" "}
                          </Link>
                        ) : (
                          `${row.id} `
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {row.invoice_generation_type}
                      </TableCell>
                      {!id && (
                        <TableCell align="left" style={{ width: "250px" }}>
                          <Link
                            target="_blank"
                            to={`/admin/organisations/summary/${row.organisation_id}`}
                            className={classes.link}
                          >
                            <Typography className={classes.themeText}>
                              {row.organisation_name}
                            </Typography>
                          </Link>
                          {row.organisation_owner_id ? (
                            <Link
                              target="_blank"
                              to={`/admin/organisations/users/${row.organisation_owner_id}`}
                            >
                              <Typography className={classes.subText}>
                                {row.organisation_owner_name}
                              </Typography>
                            </Link>
                          ) : (
                            <Typography className={classes.subText}>
                              {row.organisation_owner_name}
                            </Typography>
                          )}
                        </TableCell>
                      )}
                      <TableCell align="center">{row.published}</TableCell>

                      <TableCell align="center">{row.charge}</TableCell>

                      <TableCell align="center">{row.credit}</TableCell>

                      <TableCell align="center">{row.balance}</TableCell>

                      <TableCell align="center">
                        <StatusCell status={row.status}>
                          {row.status}
                        </StatusCell>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          edge="end"
                          className={classes.shadow}
                          disabled={!row.details_present ? true : false}
                        >
                          <Link
                            to={
                              row.details_present
                                ? `/admin/accounting/invoices/${row.id}/details`
                                : false
                            }
                            target="_blank"
                            className={classes.btnLink}
                          >
                            <InsertDriveFileIcon
                              className={
                                row.details_present ? classes.themeText : null
                              }
                            />
                          </Link>
                        </IconButton>
                      </TableCell>

                      <TableCell align="center">
                        <IconButton
                          edge="end"
                          className={classes.shadow}
                          disabled={!row.pdf_url ? true : false}
                        >
                          <a
                            href={
                              row.pdf_url && row.pdf_url.url
                                ? `${row.pdf_url.url}`
                                : false
                            }
                            target="_blank"
                            className={classes.btnLink}
                          >
                            <GetAppIcon
                              className={
                                row.pdf_url && row.pdf_url.url
                                  ? classes.themeText
                                  : null
                              }
                            />{" "}
                          </a>
                        </IconButton>
                      </TableCell>

                      <TableCell variant="outlined" align="center">
                        <IconButton
                          edge="end"
                          className={classes.shadow}
                          onClick={() => handleCancelAlertOpen(row)}
                          disabled={["paid", "void", "cancelled"].includes(
                            row.status
                          )}
                        >
                          <DeleteIcon
                            className={
                              ["paid", "void", "cancelled"].includes(row.status)
                                ? null
                                : classes.deleteButton
                            }
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
          {!data.length && !loading ? <NoRecords /> : null}
        </Grid>
        <Grid justify="flex-end" container direction="row" alignItems="center">
          <CardActions className={classes.actions}>
            <Pagination
              pagination={pagination}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </CardActions>
        </Grid>

        <Dialog
          open={openCancelAlert}
          disableBackdropClick={true}
          onClose={() => handleCancelAlertOpen()}
          aria-labelledby="cancel-invoice-title"
          aria-describedby="cancel-invoice-text"
        // className={classes.dialog}
        >
          <DialogTitle id="cancel-invoice-title">
            <Typography className={classes.font}>Cancel</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="cancel-invoice-text"
              className={classes.popupText}
            >
              <Typography>
                Are you sure that you want to cancel this invoice?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              className={classes.buttonsContainer}
              spacing={2}
            >
              <Button
                className={classes.saveButton + " " + classes.deleteButton}
                variant="outlined"
                onClick={cancelInvoiceClick}
              >
                Cancel Invoice
              </Button>
              <Button
                variant="text"
                onClick={() => handleCancelAlertClose()}
                className={classes.cancelButton}
              >
                Back
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
InvoicesTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default memo(withStyles(styles)(InvoicesTable));
