import {
    FETCH_MATERIAL_BEGIN,
    FETCH_MATERIAL_SUCCESS,
    FETCH_MATERIAL_FAILURE,
    DELETE_MATERIAL_SUCCESS,
    DELETE_MATERIAL_FAILURE,
    FETCH_MATERIAL_BY_ID_SUCCESS,
    FETCH_MATERIAL_BY_ID_FAILURE,
    UPDATE_MATERIAL_BEGIN,
    UPDATE_MATERIAL_FAILURE,
    UPDATE_MATERIAL_SUCCESS,
    CREATE_MATERIAL_BEGIN,
    CREATE_MATERIAL_FAILURE,
    CREATE_MATERIAL_SUCCESS,
    FETCH_MATERIAL_MOVEMENT_SUCCESS,
    FETCH_MATERIAL_MOVEMENT_FAILURE,
    CLEAR_MATERIALS,
    ADD_MATERIAL_STOCK_BEGIN,
    ADD_MATERIAL_STOCK_SUCCESS,
    ADD_MATERIAL_STOCK_FAILURE,
    SET_MATERIAL_FILTER,CLEAR_MATERIAL_NOTIFICATION,
    CLEAR_MATERIAL_FILTER, FETCH_MATERIAL_FILTERS_SUCCESS,
    FETCH_LOCATIONS_SUCCESS,
    FETCH_LOCATIONS_FAILURE,
    FETCH_LOCATIONS_BEGIN,
    FETCH_LOCATIONS_BY_BARCODE_SUCCESS,
    FETCH_LOCATIONS_BY_BARCODE_FAILURE,
} from 'actions/admin/materialActions';


const defaultMaterial = {
    "id": null,
    "title": null,
    "sku": null,
    "description": null,
    "weight": "",
    "height": "",
    "width": null,
    "length": null,
    "max_weight": null,
    "archived": false,
    "external_code": null,
    "material_type": null,
    "image": {
        "url": null,
        "thumb": {
            "url": null
        }
    },
    "supplier_name": null,
    "cost": 0.0,
    "quantity_in_storage": 0,
    "burn_rate_90": null,
    "location": null,
    "primary_storage_location": null,
    "secondary_storage_location": null,
    "location_histories": []
};
// specify initial state
const initialState = {
    materials: [],
    loading: false,
    error: null,
    notification: null,
    dummyData: [...Array(10)],
    fetchedMaterial: {...defaultMaterial},
    formLoading: false,
    formSuccess: false,
    filter: {},
    activeFilter: {
        page: 1,
        per_page: 25,
        title: null,
        material_type: null,
        max_weight: null,
        stockLevel: null
    },
    pagination: {
        "current_page": 1,
        "total_count": 1,
        "per_page": 2
    },
    movements: {},
    locations: [],
    filters: [],
    movementsPagination: {}
}


/*views->actions->reducers(state)
    ^               |
    |---------------|
*/
export default function ratesReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_MATERIAL_BEGIN:
            return {
                ...state,//imp line
                loading: true,
                notification: null,
                error: null,
                formSuccess: false
            };

        case FETCH_MATERIAL_SUCCESS:
            return {
                ...state,
                loading: false,
                materials: action.data,
                filter: action.filter,
                pagination: action.pagination,
                filters: action.filters,
                //fetchedMaterial: {...defaultMaterial},
                notification: null
            };

        case FETCH_MATERIAL_FILTERS_SUCCESS:
            return {
                ...state,
                loading: false,
                filter: action.filter,
                notification: null
            };

        case FETCH_MATERIAL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                materials: []
            };
        case FETCH_MATERIAL_MOVEMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                formSuccess: false,
                movements: action.data,
                movementsPagination: action.data.pagination
            };

        case FETCH_MATERIAL_MOVEMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                movements: {}
            };
        case DELETE_MATERIAL_SUCCESS:
            return {
                ...state,
                error: null,
                materials: state.materials.filter(materials => materials.id !== action.data.id),
                fetchedMaterial: action.data,
                notification: action.msg
            };
        case DELETE_MATERIAL_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                materials: state.materials,
                notification: action.msg
            };

        case FETCH_MATERIAL_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                fetchedMaterial: action.payload.parcel_material,
                filters: action.payload.filters,
            };

        case FETCH_MATERIAL_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                notification: action.msg
            };
        case UPDATE_MATERIAL_BEGIN: {
            return {
                ...state,
                notification: null,
                error: null,
                formLoading: true,
                formSuccess: false
            }
        }
        case UPDATE_MATERIAL_SUCCESS: {

            return {
                ...state,
                formLoading: false,
                formSuccess: true,
                error: null,
                updateMaterialErrors: null,
                fetchedMaterial: action.payload,
            }
        }
        case UPDATE_MATERIAL_FAILURE: {

            return {
                ...state,
                formLoading: false,
                formSuccess: false,
                error: action.payload.error
            }
        }
        case CLEAR_MATERIALS:
            return {
                ...initialState
            }
        case SET_MATERIAL_FILTER:
            return {
                ...state,
                activeFilter: {...state.activeFilter, ...action.payload.filters}
            }
        case CLEAR_MATERIAL_FILTER:
            return {
                ...state,
                activeFilter: initialState.activeFilter
            }
        case CREATE_MATERIAL_BEGIN:
            return {
                ...state,
                notification: null,
                error: null,
                formLoading: true,
                formSuccess: false
            }

        case CREATE_MATERIAL_SUCCESS: {

            return {
                ...state,
                error: null,
                formSuccess: true,
                formLoading: false,
                materials: [action.payload.material, ...state.materials],
                fetchedMaterial: action.payload.material
            }
        }
        case CREATE_MATERIAL_FAILURE:

            return {
                ...state,
                formLoading: false,
                formSuccess: false,
                error: action.payload.error

            }

        case ADD_MATERIAL_STOCK_BEGIN:
            return {
                ...state,
                notification: null,
                error: null,
                formLoading: true,
                formSuccess: false
            }

        case ADD_MATERIAL_STOCK_SUCCESS: {
            let updatedMaterial = {...state.movements}//.parcel_material_stocks,action.payload]
            updatedMaterial.parcel_material_stocks = action.payload;

            return {
                ...state,
                error: null,
                formSuccess: true,
                formLoading: false,
                movements: updatedMaterial
            }
        }
        case ADD_MATERIAL_STOCK_FAILURE:

            return {
                ...state,
                formLoading: false,
                formSuccess: false,
                error: action.payload.error

            }
        case FETCH_LOCATIONS_BEGIN:
            return {
                ...state,
                loading: false,
                locations: [],
                notification:null
            }
            case FETCH_LOCATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                locations: action.payload.data,
                notification:null
            }

        case FETCH_LOCATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                locations: [],
                notification:null
            };
            
        case FETCH_LOCATIONS_BY_BARCODE_SUCCESS:
            let locations = action.payload.data;
            return {
                ...state,
                loading: false,
                notification:"Location found!",
                locations: action.payload.data,
                error:locations.length ? null : "Location not found. Try again or search by row",
            };
        case FETCH_LOCATIONS_BY_BARCODE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                locations: [],
                notification:null
            };
        case CLEAR_MATERIAL_NOTIFICATION:
            return {
                ...state,
                error: null,
                notification:null
            }
        default:
            // Always have a default case in case a reducer doesn't complete
            return state;
    }
};
