
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TextField,
  IconButton,
  Grid,
  Typography,
  Card,
  ListItem,
  List,
  Button,
  makeStyles,
  Divider,
} from '@material-ui/core';

import Loader from 'layouts/AdminLayout/components/Loader';
import SuccessSnackbar from 'components/Help/SuccessSnackbar';
//import { DateRangePicker } from 'material-date-range-picker';
//import { DateRangePicker } from 'material-ui-datetime-range-picker';
//import DateRangePicker from 'react-daterange-picker'//working
//import 'react-daterange-picker/dist/css/react-calendar.css'; // For some basic styling.
import {KeyboardDatePicker} from "@material-ui/pickers";

const useStyles = makeStyles(() => ({
  root: {},
  paper: {
    //borderRadius: '10pt',
    //margin: '46px',
    //boxShadow: '6px 6px 6px #6C6C6C29',
    padding: '47px 0px'
  },
  header: {
    marginTop: '36px',
    marginLeft: '45px'
  },
  title: {
    marginTop: '70px',
  },
  list: {
    fontWeight: 600,
    marginBlock: 'revert'
  },
  listItem: {
    marginBlock: 'revert'
  },
  cancelButton: {
    minWidth: "100px",
  },
  button: {
    minWidth: "150px",
    color: '#40B5AD',
    fontWeight: 600,
    border: 2,
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '6px',
  },
  grids: {
    padding: '0px 7px 0px 0',
    lineHeight: 2
  },
  bigGrids: {
    //padding: '0px 26px 0px 0'
  },
  header2: {
    border: '1px solid #eeeeee',
    boxShadow: '0pt 3pt 6pt #00000029',
    fontWeight: 600,
    borderRadius: '0pt',
    backgroundColor: '#ffffff',
    marginTop: 50
  },
  divider: {
    marginLeft: 50,
    marginRight: 50
  },
  edit: {
    color: '#40B5AD',
    width: '14px',
    height: '14px'
  },
  shadow: {
    boxShadow: '0px 3px 6px #00000029',
    width: '14px',
    height: '14px',
    marginLeft: '30px'
  }
}
));

function InvoicePreview(props) {
  //const InvoicePreview = (props) => {
  const classes = useStyles();
  const {createInvoice, loading, error, notification, formSuccess, handleClose, id, showLoading} = props;
  const [value, setValue] = useState({});
  const [validationError, setValidationError] = useState(null);
  const handleChange = (val, name) => {
    const newValues = {...value, [name]: val};
    setValue(newValues)
  }

  const DatePickerComp = (key, label) => (<>
    <KeyboardDatePicker
      disableToolbar
      variant="inline"
      format="DD/MM/YYYY"
      autoOk={true}
      //margin="normal"
      key={key}
      id={key}
      label={label}
      value={value[key] || null}
      onChange={(date) => handleChange(date, key)}
      name={key}
      KeyboardButtonProps={{
        'aria-label': `change ${label}`,
      }}
    />
  </>)

  const generateInvoice = () => {
    if(value.start_date && value.end_date) {
      setValidationError(false)
      createInvoice(id, {
        "start_date": value.start_date.format('YYYY-MM-DD'),
        "end_date": value.end_date.format('YYYY-MM-DD')
      })
    }
    else {
      setValidationError(true)

      setTimeout(() => {
        setValidationError(false)
      }, 3000);
    }
  };

  useEffect(() => {
    if(formSuccess) handleClose();

  }, [formSuccess]);

  return (<>
    {loading ? <Loader /> :
      <Grid>
        <SuccessSnackbar
          open={(error || notification) ? true : false}
          error={(error) ? error.error_message || error.error || error : null}
          messageText={notification}
        />
        <SuccessSnackbar
          open={validationError}
          error={'Select start and end date to generate the invoice'}
        />


        <Grid className={classes.paper} container spacing={5}
          direction="row"
          justify="center"
          alignItems="center">

          <Grid item>
            {DatePickerComp('start_date', 'Start Date')}
          </Grid>

          <Grid item >
            {DatePickerComp('end_date', 'End Date')}

          </Grid>

          <Grid item>
            <Button
              onClick={() => generateInvoice()}
              className={classes.button} disabled={loading}
              style={{color: "#FFFFFF", backgroundColor: "#3FB5AC"}}>
              GENERATE
            </Button>
            <Button className={classes.cancelButton}
              onClick={() => handleClose()} disabled={loading}
            >Cancel</Button>
          </Grid>
        </Grid>

      </Grid>
    }</>);
}

InvoicePreview.propTypes = {
  className: PropTypes.string,
  invoice: PropTypes.object.isRequired
};

export default InvoicePreview;
