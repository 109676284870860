import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp';
import PerfectScrollbar from 'react-perfect-scrollbar';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import SuccessSnackbar from 'components/Help/SuccessSnackbar';
import Loader from 'layouts/AdminLayout/components/Loader';
import _ from 'lodash'
import {
  Box,
  Paper,
  Grid,
  Typography,
  IconButton,
  Button,
  makeStyles,
  Divider, List, ListItem, ListItemText, TextField, Tooltip
} from '@material-ui/core';

import { styled } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const useStyles = makeStyles(() => ({
  root: {},
  paper: {
    borderRadius: '10pt',
    //margin: '46px',
    boxShadow: '6px 6px 6px #6C6C6C29',
    //padding: 47,
    overflow: 'scroll'
  },
  header: {
    marginTop: '36px',
    marginLeft: '45px'
  },
  title: {
    //marginTop: '70px',
    padding: "50px 10px 0 0"
  },
  list: {
    fontWeight: 600,
    marginBlock: 'revert'
  },
  listItem: {
    marginBlock: 'revert',

  },
  infoText: {
    textAlign: "right"
  },
  paid: {
    color: '#40B5AD',
    textTransform: 'uppercase'
  },
  unPaid: {
    color: '#CBCBCB',
    textTransform: 'uppercase'
  },
  saveButton: {
    minWidth: "100px",
    color: '#40B5AD',
    fontWeight: 600,
    border: 2,
    boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
    margin: "0 20px",
    borderRadius: '6px',
  },
  bold: {
    fontWeight: 600,
  },
  topContainer: {
    padding: "20px"
  },
  topInfo: {
    paddingBottom: "20px"
  },
  statusIcon: {
    marginRight: "5px",
    marginTop: "5px"
  },
  themeColorText: {
    //   color:theme.palette.themeTextColor
    color: '#3FB5AC',
    '& > *': {
      color: '#3FB5AC'
    },
  },
  itemHeading: {
    padding: "25px 0 5px",
    fontWeight: 600,
    textTransform: 'capitalize'
  },
  paymentField: {
    marginBottom: 10
  },
  subTitle: {
    padding: '75px 0px 0 0',
    color: "#6E838E"
  },
  themeTextColor: {
    color: "#3FB5AC"
  },
  invoiceSubItem: {
    textTransform: 'capitalize',
    marginBottom: '15px',
    fontWeight: 500
    //fontSize: "0.9em"
  },
  invoiceSubHeading: {
    textTransform: 'capitalize',
    fontWeight: 600,
    fontSize: "0.9rem"
  },
  oneLineRecord: {
    marginTop: "-27px"
  },
  subText: {
    color: "#6E838E"
  },
  subTextHeader: {
    color: "#6E838E",
    fontWeight: 500
  },
  subTextContent: {
    fontWeight: 500
  },
  alignRight: {
    textAlign: "right"
  },
  alignCenter: {
    display: "flex",
    alignItems: "center"
  },
  borderBottom: {
    borderBottom: "1px solid #EDEEEE"
  },
  mt_10: {
    marginTop: "10px"
  },
  uploadBt: {
    padding: '12px',
    maxWidth: '100%',
    fontSize: '12px',
  },
  pdfInvoiceContainer: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
  },
  pdfFont:{
    fontWeight: '400',
    fontSize: '15px'
  },
  pdflink: {
    color: "#1976d2",
  },
  uploadBtContainer:{
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center'
  },
  invoiceLabel:{
    marginLeft: '10px'
  }
}));

const InvoiceDetail = props => {
  const {match, fetchInvoiceById, invoiceDetail, loading, updateQuickBookUrl, error, notification, addNewPayment, clearInvoices, recreateInvoicePdf} = props;
  const {invoice_id} = match.params;
  const classes = useStyles();
  const [openCategory, setOpenCategory] = useState({});
  const [quickBookLink, setQuickBookLink] = useState(null);
  const [newPayment, setNewPayment] = useState(null);
  const [openPanels, setOpenPanels] = useState({});
  const [accountingInvoice, setAccountingInvoice] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [openSnackBar, setOpenSnackBar] = useState(null);

  const uploadInvoicePDF = (e) => {
    if (e.target) {
      const file = e.target.files[0];
      setQuickBookLink(file)
    }
  };

  const handleSnackBarClose = () => {
    setOpenSnackBar(false);
  }

  const togglePanel = id => {
    setOpenPanels({...openPanels, [id]: !openPanels[id]})
  }

  const setOpenHandling = category => {
    setOpenCategory({...openCategory, [category]: !openCategory[category]})
  }

  const groupBy = objectArray => {
    return objectArray.reduce(function (r, a, index) {
      r[a.title] = r[a.title] || [];
      r[a.title].push(a);
      return r;
    }, Object.create(null));
  }

  const countTotal = array => {
    let total = 0
    array.forEach(el => total+=el["total_amount"])
    return ` $ ${total.toFixed(2)}`;
  }


  const invoiceItem = (row, key) => {

    return <>
      {row.category_details && row.category_details.map((o, ind) => (<>

        {<Grid item xs={12}>
          <Grid item xs={12} direction="row" container>
            <Grid item xs={2}>
            </Grid>
            <Grid item xs={2}><Typography gutterBottom variant="h6"
              className={classes.invoiceSubItem}>{o.warehouse_name}</Typography></Grid>
            <Grid item xs={4}>
              <Typography gutterBottom className={classes.invoiceSubHeading}></Typography>
            </Grid>
            <Grid item xs={1}><Typography gutterBottom variant="h6"
              className={classes.invoiceSubItem}></Typography></Grid>
            <Grid item xs={1}><Typography gutterBottom variant="h6" className={classes.invoiceSubItem}
              align="center"></Typography></Grid>
            <Grid item xs={2} container direction="row" justify="flex-end">
              <Typography gutterBottom align="right"
                className={classes.invoiceSubHeading}>{` $ ${o["total_amount"].toFixed(2)}`}</Typography>
            </Grid>
          </Grid>
        </Grid>}
        {details(o, ind, key)}
      </>))}
    </>
  }

  const lineItemReferenceDetails = (reference, lineItemDetail) => {
    return <>
      <Grid item xs={12} direction="row" container>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={4}>
          { reference.reference_type.toLowerCase() === "booking" ?
          <a href={`/admin/manage/orders/${reference.reference_id}`}
            className={classes.themeTextColor}><Typography gutterBottom
              variant="h6"
              className={classes.subText}>{reference.title}</Typography></a> :

          <a href={`/admin/accounting/others/${reference.reference_id}/detail`}
            className={classes.themeTextColor}><Typography gutterBottom
              variant="h6"
              className={classes.subText}>{reference.title}</Typography></a>

          }
        </Grid>
        <Grid item xs={1}><Typography gutterBottom variant="h6"
          className={classes.invoiceSubItem}>{(lineItemDetail.unit_amount) ? ` $ ${lineItemDetail.unit_amount.toFixed(2)}` : null}</Typography>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2} container direction="row" justify="flex-end">
          <Typography gutterBottom variant="h6"
            className={classes.invoiceSubItem} align="right">
            {(reference.total_amount) ? ` $ ${reference.total_amount.toFixed(2)}` : null}</Typography>
        </Grid>
      </Grid>
    </>
  }

  const lineItemDetailsHeader = lineItemDetail => {
    return <>
      <Grid item xs={12} direction="row" container>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={4}><Typography gutterBottom variant="h6" className={classes.invoiceSubItem}>{lineItemDetail.description}</Typography> </Grid>
        <Grid item xs={1}><Typography gutterBottom variant="h6"
          className={classes.invoiceSubItem}>{(lineItemDetail.unit_amount) ? ` $ ${lineItemDetail.unit_amount.toFixed(2)}` : null}</Typography>
        </Grid>
        <Grid item xs={1}><Typography gutterBottom variant="h6" className={classes.invoiceSubItem} align="center">
          {lineItemDetail.quantity}</Typography>
        </Grid>
        <Grid item xs={2}> <Typography gutterBottom variant="h6" className={classes.invoiceSubItem} align="right">
          {(lineItemDetail.total_amount) ? ` $ ${lineItemDetail.total_amount.toFixed(2)}` : null}</Typography>
        </Grid>
      </Grid>
    </>
  }

  const lineItemDetailsBreakdown = lineItemDetail => {
    return <>
      <Grid item xs={12} direction="row" container >
        <Grid item xs={2}></Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={4}>
          {lineItemDetail.description ? (<Typography gutterBottom variant="h6"
            className={classes.invoiceSubItem}>{lineItemDetail.description}</Typography>) :
            ((lineItemDetail.title) ? <Typography gutterBottom variant="h6"
              className={classes.invoiceSubItem}>{lineItemDetail.title}</Typography> : null)
          }
        </Grid>
        <Grid item xs={1}><Typography gutterBottom variant="h6"
          className={classes.invoiceSubItem}>{(lineItemDetail.unit_amount) ? ` $ ${lineItemDetail.unit_amount.toFixed(2)}` : null}</Typography>
        </Grid>
        <Grid item xs={1}><Typography gutterBottom variant="h6"
          className={classes.invoiceSubItem}
          align="center">{lineItemDetail.quantity}</Typography></Grid>
        <Grid item xs={2} container direction="row" justify="flex-end">
          <Typography gutterBottom variant="h6"
            className={classes.invoiceSubItem} align="right">
            {(lineItemDetail.total_amount) ? ` $ ${lineItemDetail.total_amount.toFixed(2)}` : null}</Typography>
        </Grid>
      </Grid>
    </>
  }

  const details = (o, ind, key) => {
    let groupedDetails = groupBy(o.invoice_line_item_details, key)

    return <>
        <Grid item xs={12}>
            <Grid item xs={12}>
              {groupedDetails && Object.keys(groupedDetails).map(groupTitle => (<>
                <Grid item xs={12} direction="row" container>
                  <Grid item xs={2}>
                  </Grid>
                  <Grid item xs={2} className={classes.alignRight}>
                    {key == "shippings" &&
                      <IconButton aria-label="expand row" size="small"
                        onClick={() => togglePanel(groupTitle)}>
                        {(openPanels[groupTitle]) ?
                          <KeyboardArrowUpIcon className={classes.themeTextColor} /> :
                          <KeyboardArrowDownIcon className={classes.themeTextColor} />}
                      </IconButton>}
                  </Grid>
                  <Grid item xs={4} className={classes.alignCenter}>
                    <Typography gutterBottom className={classes.invoiceSubHeading}>
                      {groupTitle !== groupedDetails[groupTitle][0].description ? groupTitle : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={2}>
                    {key == "shippings" && <Typography gutterBottom variant="h6" className={classes.invoiceSubItem} align="right">
                        {countTotal(groupedDetails[groupTitle])}
                      </Typography>
                    }
                  </Grid>
                </Grid>
                <Collapse in={(openPanels[groupTitle]) ? true : (key !== "shippings") ? true : false}>
                  {groupedDetails[groupTitle].map(lineItemDetail=>(<>
                    {lineItemDetail.description == "" ? <></> : lineItemDetail.description !== lineItemDetail.title ? 
                      lineItemDetailsHeader(lineItemDetail) : lineItemDetailsBreakdown(lineItemDetail)
                    }
                    { key === "shippings" && lineItemDetail.invoice_line_item_detail_references?.map(reference => (
                        lineItemReferenceDetails(reference, lineItemDetail)
                    ))}</>
                  ))}
                </Collapse>
              </>))}
            </Grid>
        </Grid>
    </>
  }

  const readableText = (text) => {
    if(text && text.includes('_')) {
      return text.replace(/_/g, ' ').replace(/(?: |\b)(\w)/g, function (key) {
        return key.toUpperCase()
      })
    } else {
      return text
    }
  }

  const updateQuickbookLink = () => {

    if(quickBookLink && accountingInvoice) {
      setValidationError(false)
      const formData = new FormData();
      formData.append('added_attachment', quickBookLink);
      formData.append('accounting_invoice_no', accountingInvoice);
      updateQuickBookUrl(invoice_id, formData)
    }
    else {
      setValidationError(true);

      setTimeout(() => {
        setValidationError(false)
      }, 3000);
    }
  }

  const addNewPaymentToInvoice = () => {
    addNewPayment(invoice_id, {
      "amount": newPayment
    })
  }

  useEffect(() => {
    fetchInvoiceById(invoice_id);
    return () => {
      clearInvoices();
    }
  }, []);

  useEffect(() => {
    setNewPayment("");
  }, [invoiceDetail.invoice_payments]);

  useEffect(() => {
    if(invoiceDetail.attachment_url) setQuickBookLink(invoiceDetail.attachment_url)

    if(invoiceDetail.accounting_invoice_no) setAccountingInvoice(invoiceDetail.accounting_invoice_no)

  }, [invoiceDetail]);

  useEffect(() => {
   if(error || notification) {
    setOpenSnackBar(true);
   }
  }, [error, notification]);

  return (<>
    {loading ? <Loader /> :
      <Grid>
        <Paper
          className={classes.paper}
        >

          <SuccessSnackbar
            open={(openSnackBar) ? true : false}
            error={(error) ? error.error_message || error.error || error : null}
            messageText={notification} onClose={handleSnackBarClose}
          />

          <PerfectScrollbar>
            <Box
              minWidth={800}
              p={4}
            >

              <Grid className={classes.topContainer}>
                {/*(invoiceDetail.status && invoiceDetail.status !== "due" && invoiceDetail.status !== "part_paid")*/}
                {invoiceDetail.status &&
                  <Grid className={classes.topInfo}
                    container
                    alignItems="flex-start"
                    justify="space-between"
                    spacing={3}
                  >
                    {invoiceDetail.status !== "draft" && invoiceDetail.status !== "cancelled" && <Grid item xs={12} container justify="flex-end" align="right">
                      <Tooltip title="CAUTION Only use in case of auto-generation error and when PDF fails to open correctly.">
                        <Button onClick={() => recreateInvoicePdf(invoice_id)} variant="contained"
                          className={classes.saveButton} disable={loading}>Recreate pdf</Button>
                      </Tooltip>
                    </Grid>}

                    {invoiceDetail.status !== "paid" ?
                      <Grid container xs={8}>
                          <Grid container xs={12} spacing={2}>
                          
                          <Grid item xs={12}>
                            <TextField type="text" variant="outlined" fullWidth={true}
                              value={accountingInvoice}
                              label="Quickbooks Invoice ID"
                              onChange={(e) => setAccountingInvoice(e.target.value)} />
                          </Grid>

                          
                        </Grid>
                        <Grid container xs={12} spacing={2} className={classes.mt_10}>
                          <Grid item xs={12}>
                             <div className={classes.uploadBtContainer}>
                                <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} className={classes.uploadBt}>
                                    Upload Quickbooks PDF
                                    <VisuallyHiddenInput type="file" accept='.pdf' onChange={uploadInvoicePDF} />
                                  </Button>
                                  <div className={classes.invoiceLabel}>
                                    {
                                      invoiceDetail && invoiceDetail?.attachment_url ? <Typography variant="h6" className={classes.pdfFont}>
                                      <a href={invoiceDetail.attachment_url} target='_blank' className={classes.pdflink} rel="noreferrer" color="primary" variant="body2">Click here</a>  to view quickbooks invoice.
                                      </Typography>: ''
                                    }
                                  </div>
                             </div>
                                {quickBookLink && quickBookLink?.name && (
                                  <Typography variant="h6" color="textPrimary" gutterBottom className={classes.mt_10}>Selected file: {quickBookLink.name}</Typography>
                                )}
                          </Grid>

                          {/* <Grid item xs={7} className={classes.pdfInvoiceContainer}>
                            {
                              invoiceDetail && invoiceDetail?.attachment_url ? <Typography variant="h6" className={classes.pdfFont}>
                              <a href={invoiceDetail.attachment_url} target='_blank' className={classes.pdflink} rel="noreferrer" color="primary" variant="body2">Click here</a>  to view quickbooks invoice.
                              </Typography>: ''
                            }
                          </Grid> */}
                        </Grid>
                      </Grid>

                      : <>
                        <Grid container spacing={3}>
                          <Grid item xs={3}>
                            <Typography variant="h5" className={classes.bold}>Accounting Link</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            {invoiceDetail.attachment_url ? <a href={(invoiceDetail.attachment_url.includes('http')) ? `${invoiceDetail.attachment_url}` : `https://${invoiceDetail.attachment_url}`}
                              target="_blank"><Typography variant="subTitle1"
                                className={classes.themeColorText}>Open
                                Link</Typography></a> : "None"}
                          </Grid>
                        </Grid>
                      </>
                    }
                    {invoiceDetail.status !== "paid" && invoiceDetail.status !== "cancelled" && <Grid item xs={3} container justify="flex-end" align="right">
                      <Button onClick={updateQuickbookLink} variant="contained"
                        className={classes.saveButton} disable={loading}>{invoiceDetail.attachment_url ? `UPDATE INVOICE` : `PUBLISH INVOICE`}</Button>
                    </Grid>}

                  </Grid>}
                <br /><br />

                <SuccessSnackbar
                  open={validationError}
                  error={'Please upload quickbooks pdf and quickbook invoice ID to publish the invoice.'}
                />

                <Grid className={classes.topInfo} container alignItems="flex-start" justify="space-between"
                >
                  {(invoiceDetail.status === "due" || invoiceDetail.status === "part_paid" || (invoiceDetail.invoice_payments && invoiceDetail.invoice_payments.length > 0)) &&
                    <Grid item><Typography variant="h5" className={classes.bold}>Amount Received</Typography></Grid>
                  }

                  <Grid item xs={6}>
                    {(invoiceDetail.status === "due" || invoiceDetail.status === "part_paid") && <>
                      <TextField className={classes.paymentField} type="number" variant="outlined"
                        fullWidth={true} value={newPayment} min={0}
                        onChange={(e) => setNewPayment((e.target.value < 0) ? 0 : e.target.value)} /> </>}

                    {(invoiceDetail.invoice_payments && invoiceDetail.invoice_payments.length) ? invoiceDetail.invoice_payments.map(row => (
                      <Typography variant="h6"
                        gutterBottom>$ {row.amount} - {row.created_at} - {row.payment_type}</Typography>
                    )) : null}

                  </Grid>
                  <Grid item xs={2} align="right">
                    {(invoiceDetail.status === "due" || invoiceDetail.status === "part_paid") &&
                      <Button onClick={addNewPaymentToInvoice} variant="contained"
                        className={classes.saveButton} disable={loading}>Add Payment</Button>}
                  </Grid>
                </Grid>

              </Grid>
              <Divider />
              <Grid className={classes.topContainer}>
                <Grid
                  container
                  justify="space-between"
                >
                  <Grid container
                    direction="column"
                    justify="flex-end"
                    alignItems="flex-end"
                  >
                    <Typography
                      align="right"
                      variant="h2"
                      color="textPrimary"
                      className={invoiceDetail.status === "paid" ? classes.paid : classes.unPaid}
                    >
                      {invoiceDetail.status === "paid" ?
                        <CheckCircleSharpIcon className={classes.statusIcon} /> :
                        null}
                      {invoiceDetail.status && invoiceDetail.status.replace(/_/g, ' ')}
                    </Typography>

                  </Grid>
                </Grid>
                <Box my={4}>
                  <Grid
                    container
                    justify="space-between" alignItems="baseline"
                  >
                    <Grid item>
                      <Typography variant="h2" color="textPrimary" className={classes.title} gutterBottom>
                        Invoice #{invoiceDetail.accounting_invoice_no}
                        {' '}
                      </Typography> <br />
                      <Typography variant="h5" color="textPrimary" gutterBottom>
                        Reference #{invoiceDetail.id}
                        {' '}
                        <br />
                      </Typography>
                      <Typography variant="h3" className={classes.subText}>{invoiceDetail.invoice_date}</Typography>
                    </Grid>

                    <Grid item>
                      <Typography align="right" variant="body1">To</Typography>
                      {invoiceDetail.organisation_owner_id ? <Link to={`/admin/organisations/users/${invoiceDetail.organisation_owner_id}`}><Typography align="right" variant="body1"
                        color="textPrimary">{invoiceDetail.organisation_owner_name ? invoiceDetail.organisation_owner_name : null}</Typography></Link> :
                        <Typography align="right" variant="body1"
                          color="textPrimary">{invoiceDetail.organisation_owner_name ? invoiceDetail.organisation_owner_name : null}</Typography>
                      }

                      <Link target="_blank" to={`/admin/organisations/summary/${invoiceDetail.organisation_id}`} className={classes.link}><Typography align="right" variant="body1"
                        color="textPrimary">{invoiceDetail.organisation_name && <> {invoiceDetail.organisation_name}</>}</Typography></Link>

                      <Typography align="right" className={classes.subText}
                        variant="body1">{invoiceDetail.organisation_address_line_1 && <> {invoiceDetail.organisation_address_line_1}</>}</Typography>
                      <Typography align="right" className={classes.subText}
                        variant="body1" >{invoiceDetail.organisation_address_line_2 && <> {invoiceDetail.organisation_address_line_2}</>}</Typography>
                      <Typography align="right" className={classes.subText}
                        variant="body1"> {invoiceDetail.organisation_city} {invoiceDetail.organisation_state} {invoiceDetail.organisation_postcode}</Typography>
                      <Grid item container direction="column" justify="center" alignItems="flex-end">
                        <Typography variant="h6" className={classes.subTitle} gutterBottom align="right">Total
                          Due </Typography>
                        <Typography variant="h2" color="textPrimary" className={classes.subTitle22}
                          gutterBottom>{invoiceDetail.invoice_charges ? invoiceDetail.invoice_charges.gross_total : ""}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>

                <Divider />

                <Box my={4}>
                  <Grid
                    container
                    justify="space-between"
                  >
                    <Grid item>
                      <Typography
                        gutterBottom
                        variant="h5"
                        className={classes.subTextHeader}
                      >
                        INVOICE DATES
                      </Typography>
                      <Typography
                        variant="h5"
                        align="center"
                        className={classes.subTextContent}
                      >
                        {invoiceDetail.invoicing_dates}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        gutterBottom
                        variant="h5"
                        className={classes.subTextHeader}
                      >
                        WAREHOUSES
                      </Typography>
                      <Typography
                        variant="h5"
                        align="center"
                        className={classes.subTextContent}
                      >
                        {invoiceDetail.warehouses}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        gutterBottom
                        variant="h5"
                        className={classes.subTextHeader}
                      >
                        TOTAL SPACE USED
                      </Typography>
                      <Typography
                        variant="h5"
                        align="center"
                        className={classes.subTextContent}
                      >
                        {invoiceDetail.space_usage} m<sup>3</sup>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        gutterBottom
                        variant="h5"
                        className={classes.subTextHeader}
                      >
                        TOTAL ORDERS SENT
                      </Typography>
                      <Typography
                        variant="h5"
                        align="center"
                        className={classes.subTextContent}
                      >
                        {invoiceDetail.total_orders_sent}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <br />
                <Box my={4}>

                  <Grid item xs={12} direction="row" container>
                    <Grid item xs={3}>
                      <Typography gutterBottom variant="h5"
                        className={`${classes.bold} ${classes.subText}`}>SERVICE</Typography>
                    </Grid>
                    <Grid item xs={5}><Typography gutterBottom variant="h5" className={`${classes.bold} ${classes.subText}`}></Typography></Grid>

                    <Grid item xs={1}><Typography gutterBottom variant="h5" className={`${classes.bold} ${classes.subText}`} ></Typography></Grid>
                    <Grid item xs={1}><Typography gutterBottom variant="h5" className={`${classes.bold} ${classes.subText}`}
                      align="center"></Typography></Grid>

                    <Grid item xs={2} container direction="row" justify="flex-end">
                      <Typography gutterBottom variant="h5" align="right" className={`${classes.bold} ${classes.subText}`}>TOTAL</Typography>
                    </Grid>
                  </Grid>

                </Box>
                <Divider />
                { invoiceDetail.invoice_line_items && Object.keys(invoiceDetail.invoice_line_items).map(lineItem =>
                  <Grid
                    container direction="row"
                    justify="space-between"
                  >
                    <Grid item xs={12} justify="space-between" container>
                      <Grid item xs={2}>
                        <Typography gutterBottom variant="h5" className={classes.itemHeading}
                        > {lineItem}</Typography></Grid>
                        {/*{console.log('invoiceDetail.invoice_line_items[lineItem]["details"]', invoiceDetail.invoice_line_items[lineItem]["details"])}*/}
                      <Grid item xs={1} container justifycontent="center" alignItems="center">
                        {(invoiceDetail.invoice_line_items[lineItem]["details"].length > 0) &&
                        <Typography gutterBottom variant="h5" className={classes.itemHeading}
                        >
                          <IconButton className={classes.iconButton} aria-label="expand row"
                            size="small" onClick={() => setOpenHandling(lineItem)}>
                            {(openCategory[lineItem]) ?
                            <KeyboardArrowUpIcon className={classes.themeTextColor} /> :
                            <KeyboardArrowDownIcon className={classes.themeTextColor} />}
                          </IconButton>

                        </Typography>
                        }
                      </Grid>

                      <Grid item xs={7}></Grid>

                      <Grid item xs={2} container direction="row" justify="flex-end">
                        <Typography gutterBottom variant="h5"
                          className={classes.itemHeading}>{`$ ${invoiceDetail.invoice_line_items[lineItem]["total_amount"].toFixed(2)}`}</Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Collapse in={openCategory[lineItem]}>

                        {<> {invoiceDetail.invoice_line_items[lineItem]["details"].map((o, i) => <>
                          {o.category && <><Grid item xs={12} direction="row" container className={classes.borderBottom}>
                            <Grid item xs={2}>
                              { o.category == "Storage" || o.category == "Admin" ? <></> :
                                <Typography gutterBottom variant="h6"
                                  className={classes.invoiceSubHeading}>{readableText(o.category)}</Typography>
                              }
                            </Grid>
                            <Grid item xs={2}>
                              <Typography gutterBottom variant="h6"
                                className={`${classes.bold} ${classes.subText}`}>{(i === 0) && `LOCATION`}</Typography>
                            </Grid>
                            <Grid item xs={4}><Typography gutterBottom variant="h6"
                              className={`${classes.bold} ${classes.subText}`}>{(i === 0) && `DESCRIPTION`}</Typography></Grid>

                            <Grid item xs={1}><Typography gutterBottom variant="h6"
                              className={`${classes.bold} ${classes.subText}`}>{(i === 0) && `RATE`}</Typography></Grid>
                            <Grid item xs={1}><Typography gutterBottom variant="h6"
                              align="center"
                              className={`${classes.bold} ${classes.subText}`}>{(i === 0) && `QTY`}</Typography></Grid>

                            <Grid item xs={2} container direction="row" justify="flex-end">
                              <Typography gutterBottom variant="h6"
                                className={classes.invoiceSubHeading}>{(o.total_amount) ? ` $ ${o.total_amount.toFixed(2)}` : null}</Typography>

                            </Grid>
                          </Grid><br />
                          {invoiceItem(o, lineItem)}</>}
                        </>)}

                        </>}
                      </Collapse>
                    </Grid>
                  </Grid>
                )}
                <Divider />


                <Divider />


                <Box my={4}>
                  <Grid
                    container
                    justify="space-between"

                  >
                    <Grid item sm={4}>
                      <Typography
                        variant="h5"
                        className={classes.subTextHeader}
                      >
                        INVOICE DUE
                        {' '}
                        <br />
                      </Typography>
                      <Typography
                        color="red"

                      >{invoiceDetail.invoice_due}
                      </Typography>

                    </Grid>

                    <Grid item sm={4}>
                      <List component="nav" aria-label="Details" className={classes.content}>
                        {<> {invoiceDetail.invoice_charges && Object.keys(invoiceDetail.invoice_charges).map((o, i) => <>
                          <ListItem>
                            <ListItemText className={classes.list} disableTypography={true}
                              primary={<Typography variant="h6">{`${_.startCase(readableText(o) || '')} :`}</Typography>} />
                            <ListItemText className={classes.infoText}
                              primary={`${invoiceDetail.invoice_charges[o] || 0}`} />
                          </ListItem>
                        </>)}</>}
                        <Divider />
                        <ListItem>
                          <ListItemText className={classes.list}
                            primary={<Typography variant="h5"
                              color="textPrimary"
                              className={classes.invoiceSubHeading}>Outstanding
                              :</Typography>} />
                          <ListItemText className={classes.infoText}
                            primary={<Typography variant="h5"
                              color="textPrimary"
                              className={classes.invoiceSubHeading}>{`$ ${invoiceDetail.balance || 0}`}</Typography>} />
                        </ListItem>

                      </List>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Box>

          </PerfectScrollbar>

        </Paper>
      </Grid>
    }</>);
}

InvoiceDetail.propTypes = {
  className: PropTypes.string,
  invoice: PropTypes.object.isRequired
};

export default InvoiceDetail;
