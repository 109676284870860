import { apiPath, authHeaders } from 'AppConstants';
import keepspaceApi from 'services/keepspaceApi';
import { apiErrorHandler } from 'utils/apiErrorHandler';

export const FETCH_PUBLIC_COMMENTS_BEGIN = 'FETCH_PUBLIC_COMMENTS_BEGIN';
export const FETCH_PUBLIC_COMMENTS_SUCCESS = 'FETCH_PUBLIC_COMMENTS_SUCCESS';
export const FETCH_PUBLIC_COMMENTS_FAILURE = 'FETCH_PUBLIC_COMMENTS_FAILURE'

export const FETCH_PUBLIC_COMMENT_BY_ID_BEGIN = 'FETCH_PUBLIC_COMMENT_BY_ID_BEGIN';
export const FETCH_PUBLIC_COMMENT_BY_ID_SUCCESS = 'FETCH_PUBLIC_COMMENT_BY_ID_SUCCESS';
export const FETCH_PUBLIC_COMMENT_BY_ID_FAILURE = 'FETCH_PUBLIC_COMMENT_BY_ID_FAILURE'

export const CREATE_PUBLIC_COMMENT_BEGIN = 'CREATE_PUBLIC_COMMENT_BEGIN';
export const CREATE_PUBLIC_COMMENT_SUCCESS = 'CREATE_PUBLIC_COMMENT_SUCCESS';
export const CREATE_PUBLIC_COMMENT_FAILURE = 'CREATE_PUBLIC_COMMENT_FAILURE'

// export const UPDATE_CONVERSATION_SUCCESS = 'UPDATE_CONVERSATION_SUCCESS'
// export const UPDATE_CONVERSATION_FAILURE = 'UPDATE_CONVERSATION_FAILURE'
export const UPDATE_CONVERSATION_READ = 'UPDATE_CONVERSATION_READ'

function handleErrors(response) {
    if (!response.ok) {
        apiErrorHandler(response)
        throw Error(response.statusText);
        
    }
    return response;
}

export function fetchPublicComments(args) {
    return dispatch => {
        dispatch(fetchPublicCommentsBegin());
        return keepspaceApi.getPublicComments(args)
            .then(json => {
                dispatch(fetchPublicCommentsSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(fetchPublicCommentsFailure(error))
            });
    };
}
export const fetchPublicCommentsBegin = () => ({
    type: FETCH_PUBLIC_COMMENTS_BEGIN
});

export const fetchPublicCommentsSuccess = conversations => ({
    type: FETCH_PUBLIC_COMMENTS_SUCCESS,
    payload: { conversations }
});

export const fetchPublicCommentsFailure = error => ({
    type: FETCH_PUBLIC_COMMENTS_FAILURE,
    payload: { error }
});


export function updateConversationRead(id) {
    return dispatch => {
        // dispatch(deleteCustomerBegin());
        return fetch(
            (apiPath + '/public_comments/update_read/' + id),
            {
                method: 'PUT',
                headers: {
                    ...authHeaders,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // body: JSON.stringify({customer: customer}),
            }
        )
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(updateConversationReadSuccess(id));
            })
            .catch(error => {
                // dispatch(updateConversationFailure(error))
            });
    }
}

// export const updateConversationFailure = error => ({
//     type: UPDATE_CONVERSATION_FAILURE,
//     payload: { error }
// })

// export const updateConversationSuccess = (id) => ({
//     type: UPDATE_CONVERSATION_SUCCESS,
//     payload: { id }
// })

export const updateConversationReadSuccess = (id) => ({
    type: UPDATE_CONVERSATION_READ,
    payload: { id }
})

export function createPublicComment(formData, id) {
    return dispatch => {
        dispatch(createPublicCommentBegin());
        return keepspaceApi.createPublicComment(formData)
            .then(json => {
                dispatch(createPublicCommentSuccess(json, id));
            })
            .catch(error => {
                dispatch(createPublicCommentFailure(error))
            });
    }
}

// Add new customers

export const createPublicCommentBegin = () => ({
    type: CREATE_PUBLIC_COMMENT_BEGIN
});

export const createPublicCommentSuccess = (public_comment, id) => ({
    type: CREATE_PUBLIC_COMMENT_SUCCESS,
    payload: { public_comment, id }
});

export const createPublicCommentFailure = error => ({
    type: CREATE_PUBLIC_COMMENT_FAILURE,
    payload: { error }
});

export function fetchPublicCommentById(id) {
    return dispatch => {
        dispatch(fetchPublicCommentByIdBegin());
        return keepspaceApi.getPublicCommentById(id)
            .then(json => {
                dispatch(fetchPublicCommentByIdSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(fetchPublicCommentByIdFailure(error))
            });
    };
}
export const fetchPublicCommentByIdBegin = () => ({
    type: FETCH_PUBLIC_COMMENT_BY_ID_BEGIN
});

export const fetchPublicCommentByIdSuccess = conversation => ({
    type: FETCH_PUBLIC_COMMENT_BY_ID_SUCCESS,
    payload: { conversation }
});

export const fetchPublicCommentByIdFailure = error => ({
    type: FETCH_PUBLIC_COMMENT_BY_ID_FAILURE,
    payload: { error }
});
