import React, { useRef, useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Button,
  Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { updateUser } from 'actions/userActions';

const useStyles = makeStyles(theme => ({
  root: {
  },
  userPanel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '25px',
    borderRadius: '4px',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    backgroundColor: '#FFFFFF',
  },
  removeButton: {
    width: '100%'
  },
  editButton: {
    color: 'red',
    width: '100%'
  },
  actionPanel: {
    padding: '20px'
  },
  avatar: {
    height: 100,
    width: 100
  },
  fileInput: {
    display: 'none'
  }
}));

const ProfileDetails = props => {
  const { profile } = props;

  const classes = useStyles();
  const fileInputRef = useRef(null);
  // const [image, setImage] = useState({});
  const [avatar, setAvatar] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setAvatar(profile.avatar ? profile.avatar.url : null)
  }, [profile])

  const handleAttach = () => {
    fileInputRef.current.click();
  };

  const onFilesAdded = (evt) => {
    const files = evt.target.files;

    onImageUpload(files);
  }

  const onImageUpload = (files) => {
    let reader = new FileReader();
    let file = files[0]
    reader.onloadend = () => {
      // setImage(file)
      setAvatar(reader.result)
    };
    if (file) {
      reader.readAsDataURL(file);
      handleSubmit(files);
    }
  }

  const removeImage = event => {
    var formData = new FormData();

    setAvatar(null);
    formData.append('delete_image', true)
    dispatch(updateUser(profile.id, formData));
  }

  const handleSubmit = files => {

    var formData = new FormData();
    if (files[0]) {
      formData.append('avatar', files[0], files[0].name)
    }
    dispatch(updateUser(profile.id, formData));

  }


  if (!profile.id) {
    return null
  }

  return (
    <div className={classes.root}>
      <div className={classes.userPanel}>
        <Avatar
          className={classes.avatar}
          src={avatar || require(`assets/img/avatars/00${profile.id % 46 + 1}.svg`)}
          onClick={handleAttach}
          style={{ cursor: 'pointer' }}
        />

        <Typography
          style={{
            fontSize: '24px',
            margin: '8px',
          }}
          variant='h3'
        >
          {profile.first_name + ' ' + profile.last_name}
        </Typography>

        <Typography> {profile.role} </Typography>
        <div className={classes.actionPanel}>
          <Button
            className={classes.editButton}
            variant="text"
            onClick={handleAttach}
          >
            Edit picture
          </Button>

          <input
            className={classes.fileInput}
            ref={fileInputRef}
            type="file"
            accept="image/png,image/jpg,image/jpeg"
            onChange={onFilesAdded}
          />

          <Button
            className={classes.removeButton}
            variant="text"
            onClick={removeImage}
          >
            Remove picture
          </Button>
        </div>
      </div>
    </div>
  )
};


export default ProfileDetails;

