import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchUsers, updateOrganisationUser, createOrganisationUser, resendInvitation, deleteOrganisationUser } from 'actions/organisationUserActions';

import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';

import ConfirmAlert from 'components/Help/ConfirmAlert';
import OrganisationUserForm from './OrganisationUserForm';
import OrganisationUserInvitation from './OrganisationUserInvitation';
import checkPermission from "components/checkPermission";
import Can from "components/Can";
import SuccessSnackbar from 'components/Help/SuccessSnackbar';

import {
  Avatar,
  Button,
  Card,
  CardContent,
  Typography,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 20,
  },
  content: {
    padding: 0,
    overflow: 'scroll'
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 60,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10
    }
  },
  nameDiv: {
    textAlign:'left'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(2)
  },
  head: {
    background: 'white',
  },
  roleCell:{
    margin: 'auto',
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: 4,
    borderColor: theme.palette.keepspaceBlue.main,
    color: theme.palette.keepspaceBlue.main,
    padding: 7,
    maxWidth: '115px',
    textAlign: 'center',
    textTransform: 'uppercase',
    [theme.breakpoints.down("sm")]: {
      minWidth: '-moz-available',
    }
  },
  buttonDiv: {
    width: '100%',
    textAlign: 'right'
  },
  invitationSent: {
    marginLeft: 50,
    marginRight: 50,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover':{
      fontWeight: 600
    }
  },
  paddingLeft: {
    paddingLeft: 130,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 80
    }
  }
}));


const Users = props => {
  const { className, currentOrganisation, error, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const [users, setUsers] = useState([])
  const [userToUpdate, setUserToUpdate] = useState({})
  const [newUser, setNewUser] = useState({})
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [invitationUser, setInvitationUser] = useState({});

  let fetchedUsers = useSelector((state) => state.organisationUsersReducer.users);

  useEffect(() => {
    if (fetchedUsers.length === 0)
      dispatch(fetchUsers())
  }, [dispatch, fetchedUsers.length]);

  useEffect(() => {
    if (fetchedUsers.length !== 0)
      setUsers(fetchedUsers)
  }, [fetchedUsers.length]);

  useEffect(() => {
    if (error || (successMessage.length > 0)) setSnackbarOpen(true)
  }, [error, successMessage]);

  // Confirm Alert
  const handleConfirmOpen = (event, checkedUser) => {
    let updatedUser = users.find(user => 
      user.id === checkedUser.id
    )
    updatedUser = {...updatedUser, active: event.target.checked}

    setUserToUpdate(updatedUser)
    setOpenConfirmModal(true);
  };

  const handleConfirmClose = () => {
    setOpenConfirmModal(false);
  };

  // Edit User
  const handleEditSubmit = (event) => {
    event.persist();
    
    let updatedUsers = users.map(user => 
      user.id === userToUpdate.id ? 
        userToUpdate : user
    )

    setUsers(updatedUsers)
    dispatch(updateOrganisationUser(userToUpdate))
    handleConfirmClose()
    handleEditDialogClose()
    setSuccessMessage("Member updated")
  }

  const handleEditChange = (event) => {
    setUserToUpdate({
      ...userToUpdate,
      [event.target.id]: event.target.value,
    });
  }

  const handleEditDialogOpen = (user) => {
    setUserToUpdate(user)
    setOpenEdit(true);
  };

  const handleEditDialogClose = () => {
    setUserToUpdate({})
    setOpenEdit(false);
  };

  //  create new User
  const handleChange = (event) => {
    setNewUser({
      ...newUser,
      [event.target.id]: event.target.value,
    });
  }

  const handleCreateSubmit = (event) => {
    event.persist();
    dispatch(createOrganisationUser(newUser))
      .then(result => {
        if (result) {
            // result is null when in error
            handleCreateClose()
            setSuccessMessage("Member invited")
        }
      })
  }

  const handleCreateOpen = () => {
    setOpenCreate(true);
  }

  const handleCreateClose = () => {
    setOpenCreate(false);
    setNewUser({})
  }

  // Resend invitation
  const handleResendInvitation = () => {
    let organisationUser = invitationUser
    dispatch(resendInvitation(organisationUser.id))
      .then(result => {
        if (result) {
            // result is null when in error
            setSuccessMessage("Invitation sent")
        }
      })
      handleInvitationClose(false)
      setInvitationUser({})
  }

  const handleDeleteUser = organisationUser => {
    dispatch(deleteOrganisationUser(organisationUser.id))
      .then(result => {
        if (result) {
            // result is null when in error
            setSuccessMessage("User removed")
        }
      })
      handleInvitationClose(false)
      setUserToUpdate({})
      setOpenEdit(false);
  }

  const handleInvitationClose = () => {
    setInvitationUser({});
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
    setSuccessMessage('')
  }

  const pendingInvitation = user =>  user.invitation_sent_at && !user.invitation_accepted_at

  const columns = 
    [ 
      {value:'', align: 'center'},
      {value: 'Type', align: 'center'},
      {value: 'Active',align: 'center'},
      {value: 'Edit', align: 'center'}
    ]

  const roleOptions =  
    [
      '', 
      'admin', 
      'member'
    ]
     
  const editFields =
    [
      {value: 'first_name', label: 'First Name', options: [], disabled: true },
      {value: 'last_name', label: 'Last Name', options: [], disabled: true },
      {value: 'email', label: 'E-mail', options: [], disabled: true },
      {value: 'role', label: 'Role', options: roleOptions, select: true, optionValue: 'id', required: ['required'], errorMessages: ['is required'] }
    ]

  const createFields =
    [
      {value: 'first_name', label: 'First Name', options: [], required: ['required'], errorMessages: ['is required']},
      {value: 'last_name', label: 'Last Name', options: []},
      {value: 'email', label: 'E-mail', options: [], required: ['required', 'isEmail'], errorMessages: ['is required', 'Email not valid']},
      {value: 'role', label: 'Role', options: roleOptions, select: true, optionValue: 'id', required: ['required'], errorMessages: ['is required'] }
    ]

  return (
    <>
      <Can
        role={currentOrganisation.currentOrganisationUser.role}
        perform="users:invite"
        yes={() => (
          <div className={classes.buttonDiv}>
            <Button  
              variant="outlined"
              color="primary"
              onClick={()=> handleCreateOpen()}>
                Invite Member
            </Button>
          </div> 
        )}
      />

      <SuccessSnackbar
          onClose={handleSnackbarClose}
          open={snackbarOpen}
          error={(error && error.statusText) ? error.statusText : null}
          messageText={successMessage}
      />
      
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >

        <ConfirmAlert
          open = {openConfirmModal}
          description  = "Are you sure you want to change status of this member?"
          onSubmit = {handleEditSubmit}
          onClose = {handleConfirmClose}
        />

        <OrganisationUserForm
          handleClose={handleEditDialogClose}
          user={userToUpdate}
          handleChange={handleEditChange}
          handleSave={handleEditSubmit}
          handleDelete={handleDeleteUser}
          open={openEdit}
          error={error}
          fields={editFields}
          header="Update User Details"
          save="Update"
        />

        <OrganisationUserForm
          handleClose={handleCreateClose}
          user={newUser}
          handleChange={handleChange}
          handleSave={handleCreateSubmit}
          open={openCreate}
          error={error}
          fields={createFields}
          header="Invite New Member"
          save="Invite"
        />
      
      <OrganisationUserInvitation
        user={invitationUser}
        handleClose={handleInvitationClose}
        handleResend={handleResendInvitation}
        handleCancel={handleDeleteUser}
      />

        <CardContent className={classes.content}>

          <PerfectScrollbar>
            <Table className={classes.table}>
              <TableHead className={classes.head}>
                <TableRow>
                  <TableCell align='left' variant='head' className={classes.paddingLeft}> 
                      Name
                  </TableCell>
                  {columns.map(column => (
                    <TableCell key ={column.value} align={column.align} variant='head'> 
                      {column.align === 'left' ? <div className={classes.avatar}></div> : null  }
                      {column.value}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user, i) => (
                  <TableRow
                    key={user.id}
                  >
                      <TableCell align='center'>
                          <div className={classes.nameCell}>
                            <Avatar
                              className={classes.avatar}
                              src={user.avatar.url || require(`assets/img/avatars/00${user.id%46+1}.svg`)}
                            >
                            </Avatar>
                            <div className={classes.nameDiv}>
                              <Typography variant="h6">{`${user.first_name} ${user.last_name}`}</Typography>
                              <Typography variant="subtitle2">{user.email} </Typography>
                            </div>
                          </div>
                      </TableCell>
                      <TableCell>
                        {pendingInvitation(user) ? 
                          <div 
                            className={classes.invitationSent} 
                            onClick={() =>setInvitationUser(user)}
                          > 
                            Pending invitation 
                          </div> : null
                        }
                      </TableCell>
                      <TableCell align='center'>
                        <div
                          className={classes.roleCell}
                        >
                          {user.role}
                      </div>
                      </TableCell>

                      <TableCell align='center'>
                        <Switch
                          onChange={event => handleConfirmOpen(event, user)}
                          type='checkbox'
                          checked={user.active || false}
                          value={user.active || false}
                          name="active"
                          color="primary"
                          disabled={!checkPermission(currentOrganisation.currentOrganisationUser.role, "users:edit") || user.role === "owner" || pendingInvitation(user)} 
                        />
                      </TableCell>
                      <TableCell align='center'>
                        <Button disabled={!checkPermission(currentOrganisation.currentOrganisationUser.role, "users:edit") || user.role === "owner"} onClick={() => handleEditDialogOpen(user)}>
                          <EditIcon className={classes.buttonIcon} />
                        </Button>

                      </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

          </PerfectScrollbar>
        </CardContent>
      </Card>
    </>
  );
};

Users.propTypes = {
  className: PropTypes.string
};


export default Users;
