import React, { useEffect, useState } from 'react'
import { Grid, TableBody, TableRow, TableCell } from '@material-ui/core'
import { VectorMap } from "react-jvectormap";
import Table from "@material-ui/core/Table";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { getCode, getName } from 'country-list';
import keepspaceApi from 'services/keepspaceApi';

const useStyles = makeStyles(theme => ({
    root: {
    }
}))

const Countries = props => {
    const { className } = props;
    const classes = useStyles();

    const [activeCountries, setActiveCountries] = useState([])

    useEffect(() => {
        let ignore = false;

        keepspaceApi.getMostActiveCountries()
            .then(json => {
                if (!ignore) {
                    if (json) {
                        setActiveCountries(Object.entries(json.top_countries))
                    }
                }
            })
            .catch(error => console.log(error));

        return () => {
            ignore = true;
        };
    }, [])

    const mapCountries = (array) => {
      let data = {}
      array.forEach(countryData => {
        if (countryData[0] === null || ['AUSTRALIA', 'AU', 'AUS', 'AUSTR', '', '.', '..'].includes(countryData[0].toUpperCase().trim()) ){
          data['AU'] = data['AU'] === undefined ? countryData[1] : data['AU'] + countryData[1]
        } else {
          let myRe = /^[^(]+/g
          let regexCountryMatch = myRe.exec(countryData[0].trim())
          if (!regexCountryMatch) return data['undefined'] = data['undefined'] === undefined ? countryData[1] : data['undefined'] + countryData[1]
          regexCountryMatch = regexCountryMatch[0]
          let code = regexCountryMatch === undefined ? 'US' : regexCountryMatch.trim() === 'United States' ? 'US' : regexCountryMatch.trim() === 'United Kingdom' ? 'GB' :  regexCountryMatch.trim() === 'Republic of Ireland' ? 'IE' : getCode(regexCountryMatch.trim())
          if (code !== undefined) {data[code] = data[code] === undefined ? countryData[1] : data[code] + countryData[1]}
        }
      })
      return data
    }

    const countryData = mapCountries(activeCountries)
    const countryDataSorted = Object.keys(countryData)
      .sort(function(a,b){return countryData[a]-countryData[b]}).reverse()

    return (
        <Card className={clsx(classes.root, className)}>
            <Grid container justify="space-between">
                <Grid item xs={12} sm={12} md={5}>
                    <Table>
                        <TableBody>
                            {countryDataSorted.slice(0,6).map(country => (
                                <TableRow key={country}>
                                    <TableCell>{getName(country) || 'undefined'}</TableCell>
                                    <TableCell>{countryData[country]}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <VectorMap
                        map={"world_mill"}
                        backgroundColor="transparent"
                        zoomOnScroll={false}
                        containerStyle={{
                            width: "100%",
                            height: "280px"
                        }}
                        containerClassName="map"
                        regionStyle={{
                            initial: {
                                fill: "#f4f6f8",
                                "fill-opacity": 0.9,
                                stroke: "none",
                                "stroke-width": 0,
                                "stroke-opacity": 0
                            }
                        }}
                        onRegionTipShow= {(e, el, code)=>{el.html(el.html()+' '+countryData[code])}}
                        series={{
                            regions: [
                                {
                                    values: countryData,
                                    scale: ["#daeef2", "#2f84af"],
                                    normalizeFunction: "polynomial"
                                }
                            ]
                        }}
                    />
                </Grid>
            </Grid>
        </Card>
    )
}

export default Countries;
