import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  Button,
} from '@material-ui/core';

import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import FlexGrow from 'components/Layout/FlexGrow'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  rows: {
    marginTop: 10
  },
  header: {
    marginBottom: 20,
    textAlign: "center"
  },
  link: {
    color: theme.palette.keepspaceBlue.main
  }
}));



const OrganisationUserForm = props => {

  const { header, open, handleClose, className, handleSave, handleChange, user, 
          title, fields, error, save, handleDelete,...rest } = props;
  const classes = useStyles();
  let inputRef = React.createRef("form");

  if (!open) {
    return null;
  }

  // let moreFields = null
  const inputFields =  () =>

      <Grid
        item
        md={12}
        xs={12}
      >      
      <Typography variant="h3" className={classes.header}>{header}</Typography>   
        {fields.map((column, i) =>
          <TextValidator
            key={`${column.value}_${i}`}
            className={classes.rows}
            validators={column.required}
            errorMessages={column.errorMessages}
            id={column.value}
            name={column.value}
            label={column.label}
            fullWidth
            value={user[column.value] || ''}
            onChange={handleChange}
            variant="outlined"
            SelectProps={{ native: column.select }}
            select={ Boolean(column.select) }
            error={ error ? Boolean(error[column.value]) : false }
            helperText={error ? error[column.value] : false}
            disabled={column.disabled}
          >
            { column.options.map(option =>
              <option
                key={option}
                value={option}
              >
                {option.toUpperCase()}
              </option>
            )}
          </TextValidator>
        )}
        <Typography variant="subtitle2">For user roles details please refer to <a href={`/user/getting-started`} className={classes.link}>this table</a></Typography>

      </Grid>


  return (
    <Modal
      onClose={handleClose}
      open={open}
    >
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
          <ValidatorForm
              ref={inputRef}
              onSubmit={handleSave}
              onError={errors => console.log(errors)}
          >
          <CardContent>
            <Typography
              align="center"
              gutterBottom
              variant="h3"
            >
              {title}
            </Typography>
            <Grid
              className={classes.container}
              container
              spacing={3}
            >
             {inputFields()}
            </Grid>
          </CardContent>
          <CardActions className={classes.actions}>
            { handleDelete ? 
              <Button
                onClick={() => handleDelete(user)}
                variant="outlined"
              >
                Remove User
              </Button> : null
            }
            <FlexGrow/>
            <Button
              onClick={handleClose}
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              type="submit"
              color="primary"
            >
              {save}
            </Button>
          </CardActions>
        </ValidatorForm>
      </Card>
    </Modal>
  );
};

OrganisationUserForm.displayName = 'OrganisationUserForm';

OrganisationUserForm.propTypes = {
  className: PropTypes.string,
  user: PropTypes.any,
  handleClose: PropTypes.func,
  open: PropTypes.bool
};

OrganisationUserForm.defaultProps = {
  open: false,
  handleClose: () => {}
};

export default OrganisationUserForm;





