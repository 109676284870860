import React, {useState} from 'react';
import {connect} from 'react-redux';
import {useDispatch} from 'react-redux';
import keepspaceApi from 'services/keepspaceApi';
import {useSelector} from 'react-redux';

import {updateOrganisation, setCurrentOrganisation} from 'actions/organisationActions';

import ConfirmAlert from 'components/Help/ConfirmAlert';
import {
  withStyles,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Switch,
  Avatar,
  ListItemText,
  Button
} from '@material-ui/core';

function OrganisationsList(props) {
  const {classes, organisations} = props;

  const [openAlert, setOpenAlert] = useState(false);
  const [organisationToUpdate, setOrganisationToUpdate] = useState({})
  const dispatch = useDispatch();
  const currentOrganisation = useSelector(state => state.organisationsReducer.currentOrganisation);

  const columns = [
    {label: 'Name', tooltip: '', value: 'name'},
    {label: 'Team Size', tooltip: '', value: 'team_size'},
    {label: 'Active', tooltip: '', value: 'active'},
  ]

  // Confirmation Alert
  const handleAlertOpen = (event, checkedOrganisation) => {
    let updatedOrganisation = organisations.find(organisation =>
      organisation.id === checkedOrganisation.id
    )
    updatedOrganisation = {...updatedOrganisation, active: event.target.checked}

    setOrganisationToUpdate(updatedOrganisation)
    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  // Edit organisation
  const handleEditSubmit = (event) => {
    event.persist();

    dispatch(updateOrganisation(organisationToUpdate.id, organisationToUpdate))
    handleAlertClose()
  }

  const handleChangeOrganisation = organisation => {
    dispatch(setCurrentOrganisation(organisation))
    window.localStorage.setItem('organisation-uid', organisation.uid);
    keepspaceApi.refreshAuthToken();
    const url = window.location.href;
    const uid = url.split('/')[4];
    const updatedUrl = url.replace(uid, organisation.uid);
    window.location.assign("/");
  }

  return (
    <div className={classes.root}>
      <Typography variant="h2" style={{fontSize: '11px', marginBottom: '8px'}}>SETTINGS</Typography>
      <Typography variant="h1" style={{fontSize: '24px', marginBottom: 10}}>Your Organisations</Typography>

      <ConfirmAlert
        open={openAlert}
        description="Are you sure you want to change status of this organisation?"
        onSubmit={handleEditSubmit}
        onClose={handleAlertClose}
      />

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell key={column.label} className={classes.tableCell}> {column.label}</TableCell>
            ))}
            {organisations.length !== 1 ?
              <TableCell className={classes.tableCell}>Switch Organisation</TableCell>
              : null
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {organisations.map(organisation => (
            <TableRow
              key={organisation.id}
              hover
            >
              <TableCell className={classes.tableCellFlex}>
                <Avatar
                  alt="Organisation"
                  className={classes.logo}
                  src={organisation.logo.url ? organisation.logo.url : require(`assets/img/avatars/00${organisation.id % 46 + 1}.svg`)}
                />
                <ListItemText
                  primary={organisation.name}
                  primaryTypographyProps={{variant: 'h4', color: 'inherit'}}
                  secondary={organisation.web_url}
                />
              </TableCell>
              <TableCell className={classes.tableCell}>
                {organisation['team_size']}
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Switch
                  onChange={event => handleAlertOpen(event, organisation)}
                  type='checkbox'
                  checked={organisation.active || false}
                  value={organisation.active || false}
                  name="active"
                  color="primary"
                />
              </TableCell>
              {organisation.uid !== currentOrganisation.uid ?
                <TableCell className={classes.tableCell}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleChangeOrganisation(organisation)}>
                    Switch
                  </Button>
                </TableCell> :
                <TableCell className={classes.tableCell} />
              }

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    overflow: 'auto'
  },
  logo: {
    width: 40,
    height: 40,
    marginRight: 20
  },
  tableCellFlex: {
    display: 'flex'
  },
  table: {
    background: 'white'
  }
})

const mapPropsToState = (state, ownProps) => ({
  ...ownProps,
  organisations: state.organisationsReducer.organisations,
  error: state.organisationsReducer.error,
})

export default connect(mapPropsToState)(withStyles(styles)(OrganisationsList));


