import {
  FETCH_ADMIN_ORDER_BEGIN,
  FETCH_ADMIN_ORDER_SUCCESS,
  FETCH_ADMIN_ORDER_FILTERS_SUCCESS,
  FETCH_ADMIN_ORDER_FAILURE,
  FETCH_ORDER_SUMMARY_BEGIN,
  FETCH_ORDER_SUMMARY_SUCCESS,
  FETCH_ORDER_SUMMARY_FAILURE,
  FETCH_ORDER_HISTORY_BEGIN,
  FETCH_ORDER_HISTORY_SUCCESS,
  FETCH_ORDER_HISTORY_FAILURE,
  FETCH_SHIPPING_ORDER_BEGIN,
  FETCH_SHIPPING_ORDER_SUCCESS,
  FETCH_SHIPPING_ORDER_FAILURE,
  UPDATE_PARCELMATERIAL_FILTER,
  FETCH_ORDER_PRICING_BEGIN,
  FETCH_ORDER_PRICING_SUCCESS,
  FETCH_ORDER_PRICING_FAILURE,
  FETCH_ORDER_CHAT_BEGIN,
  FETCH_ORDER_CHAT_SUCCESS,
  FETCH_ORDER_CHAT_FAILURE,
  FETCH_ORDER_SKU_BEGIN,
  FETCH_ORDER_SKU_SUCCESS,
  FETCH_ORDER_SKU_FAILURE,
  FETCH_ORDER_NOTE_BEGIN,
  UPDATE_ORDER_NOTE_SUCCESS,
  UPDATE_ORDER_NOTE_FAILURE,
  DOWNLOAD_AU_CSV_BEGIN,
  DOWNLOAD_AU_CSV_SUCCESS,
  DOWNLOAD_AU_CSV_FAILURE,
  ORDER_BULK_UPDATE_BEGIN,
  ORDER_BULK_UPDATE_SUCCESS,
  ORDER_BULK_UPDATE_FAILURE,
  ORDER_CHAT_CREATE_BEGIN,
  ORDER_CHAT_CREATE_SUCCESS,
  ORDER_CHAT_CREATE_FAILURE,
  INTERNAL_COMMENT_CREATE_BEGIN,
  INTERNAL_COMMENT_CREATE_SUCCESS,
  INTERNAL_COMMENT_CREATE_FAILURE,
  SET_ADMIN_ORDERS_FILTER,
  CLEAR_ADMIN_ORDERS_FILTER,
  UPDATE_ORDER_SHIPMENT_BEGIN,
  UPDATE_ORDER_SHIPMENT_SUCCESS,
  UPDATE_ORDER_SHIPMENT_FAILURE,
  UPDATE_ORDER_SKUS_BEGIN,
  UPDATE_ORDER_SKUS_SUCCESS,
  UPDATE_ORDER_SKUS_FAILURE,
  UPDATE_SERIAL_NUMBERS_BEGIN,
  UPDATE_SERIAL_NUMBERS_SUCCESS,
  UPDATE_SERIAL_NUMBERS_FAILURE,
  UPDATE_ORDER_PRICING_BEGIN,
  UPDATE_ORDER_PRICING_SUCCESS,
  UPDATE_ORDER_PRICING_FAILURE,
  FETCH_ORG_ORDER_BEGIN,
  FETCH_ORG_ORDER_SUCCESS,
  FETCH_ORG_ORDER_FAILURE,
  CLEAR_ADMIN_ORDERS,
  ORDER_CREATE_BEGIN,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAILURE,
  SELECT_CARRIER_BEGIN,
  SELECT_CARRIER_SUCCESS,
  SELECT_CARRIER_FAILURE,
  CANCEL_LABEL_BEGIN,
  CANCEL_LABEL_SUCCESS,
  CANCEL_LABEL_FAILURE,
  REMOVE_CARRIER_BEGIN,
  REMOVE_CARRIER_SUCCESS,
  REMOVE_CARRIER_FAILURE,
  CREATE_CUSTOM_CARRIER_BEGIN,
  CREATE_CUSTOM_CARRIER_SUCCESS,
  CREATE_CUSTOM_CARRIER_FAILURE,
  VIEW_CARRIER_BEGIN,
  VIEW_CARRIER_SUCCESS,
  VIEW_CARRIER_FAILURE,
  PRINT_SHIPPING_LABEL_BEGIN,
  PRINT_SHIPPING_LABEL_SUCCESS,
  PRINT_SHIPPING_LABEL_FAILURE,
  GET_ORDER_PARCELS_BEGIN,
  GET_ORDER_PARCELS_SUCCESS,
  GET_ORDER_PARCELS_FAILURE,
  UPDATE_ORDER_PARCELS_BEGIN,
  UPDATE_ORDER_PARCELS_SUCCESS,
  UPDATE_ORDER_PARCELS_FAILURE,
  SUGGEST_HANDLING_BEGIN,
  SUGGEST_HANDLING_SUCCESS,
  SUGGEST_HANDLING_FAILURE,
  UNPROCESS_ORDER_BEGIN,
  UNPROCESS_ORDER_SUCCESS,
  UNPROCESS_ORDER_FAILURE,
  BOOKING_ITEM_DETAIL_BEGIN,
  BOOKING_ITEM_DETAIL_SUCCESS,
  BOOKING_ITEM_DETAIL_FAILURE,
  DOWNLOAD_ORDER_TMPL_BEGIN,
  DOWNLOAD_ORDER_TMPL_SUCCESS,
  DOWNLOAD_ORDER_TMPL_FAILURE,
  UPLOAD_ORDER_TMPL_BEGIN,
  UPLOAD_ORDER_TMPL_SUCCESS,
  UPLOAD_ORDER_TMPL_FAILURE,
  CLEAR_TMPLS,
  FETCH_BATCH_DETAIL_BEGIN,
  FETCH_BATCH_DETAIL_SUCCESS,
  FETCH_BATCH_DETAIL_FAILURE,
  FETCH_SUGGESTED_CARRIER_BEGIN,
  FETCH_SUGGESTED_CARRIER_SUCCESS,
  FETCH_SUGGESTED_CARRIER_FAILURE,
  ARCHIVE_COMMENT_BEGIN,
  ARCHIVE_COMMENT_SUCCESS,
  ARCHIVE_COMMENT_FAILURE,
  ORDER_WAREHOUSE_UPDATE_BEGIN,
  ORDER_WAREHOUSE_UPDATE_SUCCESS,
  ORDER_WAREHOUSE_UPDATE_FAILURE,
  FETCH_INBOUND_LABEL_BEGIN,
  FETCH_INBOUND_LABEL_SUCCESS,
  FETCH_INBOUND_LABEL_FAILURE,
  FETCH_INBOUND_REPORT_BEGIN,
  FETCH_INBOUND_REPORT_SUCCESS,
  FETCH_INBOUND_REPORT_FAILURE,
  FETCH_INBOUND_BOXES_BEGIN,
  FETCH_INBOUND_BOXES_SUCCESS,
  FETCH_INBOUND_BOXES_FAILURE,
  PRINT_INBOUND_SHIPPING_LABEL_BEGIN,
  PRINT_INBOUND_SHIPPING_LABEL_SUCCESS,
  PRINT_INBOUND_SHIPPING_LABEL_FAILURE,
} from "actions/admin/adminOrdersActions";

const initialState = {
  loading: false,
  loading_carrier: false,
  error: null,
  notification: null,
  orders: [],
  dummyData: [...Array(10)],
  // fetchedOrder:{},
  orderSummary: {
    booking_history: [],
  },
  items: [],
  shippingOrder: {
    parcels: [],
    suggested_carriers: [],
  },
  filters: {},
  parcels: [],
  parcelMaterialFilter: "",
  orderPricing: {
    admin: {},
    shipping: {
      shipping: {},
    },

    picking: {
      picking: [],
    },
    packing: {},
    prepping: {},
  },
  orderChat: {
    chats: [],
    internal_comments: [],
    currentChat: [],
  },
  orderSku: {
    skus: [],
  },
  orderHistory: {
    booking_history: [],
  },
  batchDetail: {
    orders: [],
  },
  pagination: {},
  formLoading: false,
  formSuccess: false,
  csvData: null,
  updatedOrders: {
    success_bookings: [],
    failed_bookings: [],
  },
  activeFilter: {
    page: 1,
    per_page: 25,
    company_ids: [],
    exclude_company_ids: [],
    shipping_carriers: [],
    statuses: [],
    booking_type: "",
    country: "",
    state: "",
    contact_name: "",
    tracking_id: "",
    trolley_id: "",
    item_id: "",
    batched: null,
    warehouse_id: "",
  },
  printedLabel: {},
  printedPDF: null,
  labelType: null,
  handling: {},
  itemDetail: null,
  template: null,
  disableParcelEdit: true,
  hasPrePackedItems: false,
  inboundLabel: {
    loading: true,
  },
  inboundReport: {
    services: [],
    loading: true,
  },
  inboundBoxes: {
    loading: true,
  },
};

export default function adminOrdersReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ADMIN_ORDER_BEGIN:
      return {
        ...state, //imp line
        loading: true,
        error: null,
        notification: null,
        formSuccess: false,
        orders: [],
        updatedOrders: initialState.updatedOrders,
      };
    case FETCH_ORG_ORDER_BEGIN:
      return {
        ...state, //imp line
        loading: true,
        error: null,
        notification: null,
        formSuccess: false,
        orders: [],
        updatedOrders: initialState.updatedOrders,
      };
    case FETCH_ADMIN_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.data,
        pagination: action.pagination,
        filters: action.filters,
        actions: action.actions,
      };
    case FETCH_ADMIN_ORDER_FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        filters: action.filters,
        actions: action.actions,
      };

    case FETCH_ORG_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.data,
        pagination: action.pagination,
        filters: action.filters,
        organisation: action.organisation,
        actions: action.actions,
      };
    case FETCH_ADMIN_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case FETCH_ORG_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case FETCH_ORDER_SUMMARY_BEGIN:
      return {
        ...state, //imp line
        loading: true,
        error: null,
        notification: null,
        formSuccess: false,
        updatedOrders: initialState.updatedOrders,
      };

    case FETCH_ORDER_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        orderSummary: action.data.booking,
        filters: action.data.filters,
      };
    case FETCH_ORDER_SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case FETCH_ORDER_HISTORY_BEGIN:
      return {
        ...state, //imp line
        loading: true,
        error: null,
        notification: null,
        formSuccess: false,
      };

    case FETCH_ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        orderHistory: action.data.booking,
      };

    case FETCH_ORDER_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case FETCH_SHIPPING_ORDER_BEGIN:
      return {
        ...state, //imp line
        loading: true,
        error: null,
        notification: null,
        formSuccess: false,
        printedLabel: {},
        printedPDF: null,
        labelType: null,
      };

    case FETCH_SHIPPING_ORDER_SUCCESS:
      return {
        ...state,
        shippingOrder: {
          ...action.data.booking,
          suggested_carriers: state.shippingOrder
            ? state.shippingOrder.suggested_carriers
            : [],
        },
        filters: { ...state.filters, ...action.data.filters },
        loading: false,
      };

    case FETCH_SHIPPING_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_PARCELMATERIAL_FILTER:
      return {
        ...state,
        parcelMaterialFilter: action.data,
      };

    case FETCH_ORDER_PRICING_BEGIN:
      return {
        ...state, //imp line
        loading: true,
        error: null,
        notification: null,
        formSuccess: false,
      };

    case FETCH_ORDER_PRICING_SUCCESS:
      return {
        ...state,
        loading: false,
        orderPricing: {
          ...action.data.booking,
          filters: action.data.filters,
        },
      };
    case FETCH_ORDER_PRICING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_ORDER_PRICING_BEGIN: {
      return {
        ...state,
        loading: true,
        formSuccess: false,
        error: null,
        notification: null,
      };
    }

    case UPDATE_ORDER_PRICING_SUCCESS:
      return {
        ...state,
        loading: false,
        formSuccess: true,
        orderPricing: {
          ...action.data.booking,
          filters: state.orderPricing.filters,
        },
      };

    case UPDATE_ORDER_PRICING_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    }

    case FETCH_ORDER_CHAT_BEGIN:
      return {
        ...state,
        loading: true,
        formSuccess: false,
        error: null,
        notification: null,
      };

    case FETCH_ORDER_CHAT_SUCCESS:
      return {
        ...state,
        loading: false,
        orderChat: action.data.booking,
      };

    case FETCH_ORDER_CHAT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case FETCH_ORDER_SKU_BEGIN:
      return {
        ...state,
        loading: true,
        formSuccess: false,
        error: null,
        notification: null,
        itemDetail: null,
      };

    case FETCH_ORDER_SKU_SUCCESS: {
      return {
        ...state,
        loading: false,
        orderSku: action.data.booking,
      };
    }
    case FETCH_ORDER_SKU_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_ORDER_NOTE_BEGIN:
      return {
        ...state,
        error: null,
        notification: null,
        formLoading: true,
        formSuccess: false,
      };
    case UPDATE_ORDER_NOTE_SUCCESS:
      return {
        ...state,
        formLoading: false,
        formSuccess: true,
        orderSummary: action.payload,
      };

    case UPDATE_ORDER_NOTE_FAILURE:
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };

    case DOWNLOAD_AU_CSV_BEGIN:
      return {
        ...state,
        formSuccess: false,
        error: null,
        notification: "Downloding AU Post CSV",
        csvData: "",
      };
    case DOWNLOAD_AU_CSV_SUCCESS:
      return {
        ...state,
        notification: null,
        csvData: action.payload,
      };
    case DOWNLOAD_AU_CSV_FAILURE:
      return {
        ...state,
        notification: null,
        csvData: null,
        error: action.payload.error,
      };
    case ORDER_BULK_UPDATE_BEGIN:
      return {
        ...state,
        updatedOrders: { ...initialState.updatedOrders },
        formSuccess: false,
        formLoading: true,
        notification: "Updating orders..",
        error: null,
      };
    case ORDER_BULK_UPDATE_SUCCESS:
      return {
        ...state,
        updatedOrders: action.data,
        formLoading: false,
        formSuccess: true,
        notification: "Status Updated",
      };
    case ORDER_BULK_UPDATE_FAILURE:
      return {
        ...state,
        notification: null,
        formLoading: false,
        error: action.payload.error,
      };
    case UPDATE_ORDER_SHIPMENT_BEGIN:
      return {
        ...state,
        error: null,
        notification: null,
        formLoading: true,
        formSuccess: false,
      };
    case UPDATE_ORDER_SHIPMENT_SUCCESS:
      return {
        ...state,
        formLoading: false,
        formSuccess: true,
        shippingOrder: {
          ...action.payload.booking,
          suggested_carriers: state.shippingOrder.suggested_carriers,
        },
      };
    case UPDATE_ORDER_SHIPMENT_FAILURE:
      return {
        ...state,
        formLoading: false,
        error: action.payload.error,
      };

    case CLEAR_ADMIN_ORDERS:
      return {
        ...state,
        ...initialState,
      };
    case UPDATE_ORDER_SKUS_BEGIN:
      return {
        ...state,
        error: null,
        notification: null,
        formLoading: true,
        formSuccess: false,
      };
    case UPDATE_ORDER_SKUS_SUCCESS:
      return {
        ...state,
        formLoading: false,
        formSuccess: true,
        orderSku: action.payload,
      };
    case UPDATE_ORDER_SKUS_FAILURE:
      return {
        ...state,
        formLoading: false,
        error: action.payload.error,
        orderSku: state.orderSku,
      };

    case UPDATE_SERIAL_NUMBERS_BEGIN:
      return {
        ...state,
        formLoading: true,
        notification: "Updating serial numbers",
      };
    case UPDATE_SERIAL_NUMBERS_SUCCESS:
      return {
        ...state,
        formLoading: false,
        formSuccess: true,
        orderSku: action.data,
        notification: "Serial numbers updated",
      };

    case UPDATE_SERIAL_NUMBERS_FAILURE:
      return {
        ...state,
        formLoading: false,
        notification: null,
        error: action.payload.error,
      };
    case SET_ADMIN_ORDERS_FILTER:
      return {
        ...state,
        activeFilter: { ...state.activeFilter, ...action.payload.filters },
      };
    case CLEAR_ADMIN_ORDERS_FILTER:
      return {
        ...state,
        activeFilter: initialState.activeFilter,
      };
    case ORDER_CHAT_CREATE_BEGIN:
      return {
        ...state,
        error: null,
        notification: null,
        formLoading: true,
        formSuccess: false,
      };
    case ORDER_CHAT_CREATE_SUCCESS:
      return {
        ...state,
        formLoading: false,
        formSuccess: true,
        orderChat: {
          ...state.orderChat,
          internal_comments: action.payload.booking.internal_comments,
          chats: action.payload.booking.chats,
        },
      };
    case ORDER_CHAT_CREATE_FAILURE:
      return {
        ...state,
        formLoading: false,
        error: action.payload.error,
      };
    case INTERNAL_COMMENT_CREATE_BEGIN:
      return {
        ...state,
        error: null,
        notification: null,
        loading: true,
        formSuccess: false,
      };
    case INTERNAL_COMMENT_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        formSuccess: true,
        orderChat: {
          ...state.orderChat,
          internal_comments: action.payload.booking.internal_comments,
          chats: action.payload.booking.chats,
        },
      };
    case INTERNAL_COMMENT_CREATE_FAILURE:
      return {
        ...state,
        formLoading: false,
        error: action.payload.error,
      };

    case ORDER_CREATE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        formSuccess: false,
        notification: null,
      };

    case ORDER_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        formSuccess: true,
      };
    case ORDER_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    case SELECT_CARRIER_BEGIN:
      return {
        ...state,
        loading: true,
        loading_carrier: true,
        error: null,
        notification: "Updating selected carrier",
        formSuccess: false,
        printedLabel: {},
      };
    case SELECT_CARRIER_SUCCESS:
      return {
        ...state,
        loading: false,
        loading_carrier: false,
        shippingOrder: {
          ...action.payload.booking,
          suggested_carriers: state.shippingOrder.suggested_carriers,
        },
        printedLabel: {},
        notification: null,
      };
    case SELECT_CARRIER_FAILURE:
      return {
        ...state,
        loading: false,
        formSuccess: false,
        error: action.payload.error,
        loading_carrier: false,
        notification: null,
      };

    case CANCEL_LABEL_BEGIN:
      return {
        ...state,
        error: null,
        notification: null,
        printedPDF: {},
        labelType: null,
      };
    case CANCEL_LABEL_SUCCESS: {
      return {
        ...state,
        notification: "Canceling selected label",
        shippingOrder: {
          ...action.payload.booking,
          suggested_carriers: state.shippingOrder.suggested_carriers,
        },
        printedLabel: {},
      };
    }
    case CANCEL_LABEL_FAILURE:
      return {
        ...state,
        notification: null,
        error: action.payload.error,
      };
    case REMOVE_CARRIER_BEGIN:
      return {
        ...state,
        loading: true,
        loading_carrier: true,
        error: null,
        notification: "Removing selected carrier",
      };
    case REMOVE_CARRIER_SUCCESS:
      return {
        ...state,
        loading: false,
        loading_carrier: false,
        shippingOrder: {
          ...action.payload.booking,
          suggested_carriers: state.shippingOrder.suggested_carriers,
        },
        printedLabel: {},
        notification: null,
      };

    case REMOVE_CARRIER_FAILURE:
      return {
        ...state,
        loading: false,
        loading_carrier: false,
        error: action.payload.error,
        notification: null,
      };
    case CREATE_CUSTOM_CARRIER_BEGIN:
      return {
        ...state,
        formLoading: true,
        loading_carrier: true,
        formSuccess: false,
        error: null,
        notification: null,
      };
    case CREATE_CUSTOM_CARRIER_SUCCESS:
      return {
        ...state,
        loading: false,
        loading_carrier: false,
        formLoading: false,
        formSuccess: true,
        shippingOrder: {
          ...action.payload.booking,
          suggested_carriers: state.shippingOrder.suggested_carriers,
        },
        printedLabel: {},
      };
    case CREATE_CUSTOM_CARRIER_FAILURE:
      return {
        ...state,
        formLoading: false,
        loading_carrier: false,
        error: action.payload.error,
        notification: null,
      };
    case VIEW_CARRIER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        notification: null,
        printedLabel: {},
      };
    case VIEW_CARRIER_SUCCESS:
      return {
        ...state,
        loading: false,
        printedLabel: action.payload,
      };
    case VIEW_CARRIER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        notification: null,
      };
    case PRINT_SHIPPING_LABEL_BEGIN:
      return {
        ...state,
        formLoading: true,
        formSuccess: false,
        error: null,
        notification: null,
        printedPDF: null,
      };
    case PRINT_SHIPPING_LABEL_SUCCESS:
      return {
        ...state,
        formLoading: false,
        formSuccess: true,
        printedPDF: action.payload?.data || action.payload,
        labelType: action.labelType,
        shippingOrder: {
          ...state.shippingOrder,
          suggested_carriers: state.shippingOrder.suggested_carriers,
          label_url: action.payload.data?.label_url
            ? action.payload.data.label_url
            : state.shippingOrder.label_url,
        },
      };
    case PRINT_SHIPPING_LABEL_FAILURE:
      return {
        ...state,
        formLoading: false,
        error: action.payload.error,
        notification: null,
      };
    case GET_ORDER_PARCELS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        notification: null,
      };
    case GET_ORDER_PARCELS_SUCCESS:
      return {
        ...state,
        loading: false,
        parcels: action.data.parcels,
        filters: action.data.filters,
        disableParcelEdit: action.data.disable_parcel_edit,
        hasPrePackedItems: action.data.has_prepacked_items
      };

    case GET_ORDER_PARCELS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        notification: null,
      };
    case UPDATE_ORDER_PARCELS_BEGIN:
      return {
        ...state,
        formLoading: true,
        formSuccess: false,
        error: null,
        notification: null,
      };
    case UPDATE_ORDER_PARCELS_SUCCESS:
      return {
        ...state,
        formLoading: false,
        formSuccess: true,
        parcels: action.data.booking,
      };
    case UPDATE_ORDER_PARCELS_FAILURE:
      return {
        ...state,
        formLoading: false,
        error: action.payload.error,
        notification: null,
      };
    case SUGGEST_HANDLING_BEGIN:
      return {
        ...state,
        error: null,
        notification: "Fetching packaging details",
      };
    case SUGGEST_HANDLING_SUCCESS:
      return {
        ...state,
        handling: action.data,
        notification: null,
      };
    case SUGGEST_HANDLING_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        notification: null,
      };

    case UNPROCESS_ORDER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        notification: null,
      };
    case UNPROCESS_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        orderSummary: action.data.booking,
      };
    case UNPROCESS_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        notification: null,
      };
    case BOOKING_ITEM_DETAIL_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        notification: null,
      };
    case BOOKING_ITEM_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        itemDetail: action.data.item,
      };
    case BOOKING_ITEM_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        notification: null,
      };
    case DOWNLOAD_ORDER_TMPL_BEGIN:
      return {
        ...state,
        notification: "Downloading..Please Wait",
        template: null,
      };
    case DOWNLOAD_ORDER_TMPL_SUCCESS:
      return {
        ...state,
        notification: null,
        template: action.payload.template,
      };
    case DOWNLOAD_ORDER_TMPL_FAILURE:
      return {
        ...state,
        notification: null,
        error: action.payload.error,
      };
    case UPLOAD_ORDER_TMPL_BEGIN:
      return {
        ...state,
        notification: "Uploading..Please Wait",
      };
    case UPLOAD_ORDER_TMPL_SUCCESS:
      return {
        ...state,
        notification: "File uploaded",
      };
    case UPLOAD_ORDER_TMPL_FAILURE:
      return {
        ...state,
        notification: null,
        error: action.payload.error,
      };
    case CLEAR_TMPLS:
      return {
        ...state,
        notification: null,
        error: null,
        template: null,
        csvData: null,
        printedPDF: null,
      };
    case FETCH_BATCH_DETAIL_BEGIN:
      return {
        ...state,
        loading: true,
        batchDetail: initialState.batchDetail,
      };
    case FETCH_BATCH_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        batchDetail: action.data,
      };
    case FETCH_BATCH_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        notification: null,
        error: action.payload.error,
      };

    case FETCH_SUGGESTED_CARRIER_BEGIN:
      return {
        ...state, //imp line
        loading_carrier: true,
        error: null,
        notification: null,
        formSuccess: false,
        printedLabel: {},
        printedPDF: null,
        labelType: null,
      };

    case FETCH_SUGGESTED_CARRIER_SUCCESS:
      return {
        ...state,
        shippingOrder: {
          ...state.shippingOrder,
          suggested_carriers: action.data.booking.suggested_carriers,
        },
        loading_carrier: false,
      };

    case FETCH_SUGGESTED_CARRIER_FAILURE:
      return {
        ...state,
        loading_carrier: false,
        error: action.payload.error,
        notification: null,
      };
    case ARCHIVE_COMMENT_BEGIN:
      return {
        ...state,
        formSuccess: false,
        notification: "Deleting..",
      };
    case ARCHIVE_COMMENT_SUCCESS:
      return {
        ...state,
        formSuccess: true,
        notification: null,
        orderChat: {
          ...state.orderChat,
          internal_comments: action.payload.booking.internal_comments,
          chats: action.payload.booking.chats,
        },
      };
    case ARCHIVE_COMMENT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        notification: null,
      };

    case ORDER_WAREHOUSE_UPDATE_BEGIN:
      return {
        ...state, //imp line
        loading: true,
        error: null,
        notification: null,
        formSuccess: false,
        updatedOrders: initialState.updatedOrders,
      };

    case ORDER_WAREHOUSE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        orderSummary: action.data.booking,
      };
    case ORDER_WAREHOUSE_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case FETCH_INBOUND_LABEL_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FETCH_INBOUND_LABEL_SUCCESS:
      return {
        ...state,
        inboundLabel: action.payload.inbound_label,
        loading: false,
      };

    case FETCH_INBOUND_LABEL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FETCH_INBOUND_BOXES_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FETCH_INBOUND_BOXES_SUCCESS:
      return {
        ...state,
        inboundBoxes: action.payload.boxes,
        loading: false,
      };

    case FETCH_INBOUND_BOXES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FETCH_INBOUND_REPORT_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FETCH_INBOUND_REPORT_SUCCESS:
      return {
        ...state,
        inboundReport: action.payload.report,
        loading: false,
      };

    case FETCH_INBOUND_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PRINT_INBOUND_SHIPPING_LABEL_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case PRINT_INBOUND_SHIPPING_LABEL_SUCCESS:
      return {
        ...state,
        inboundReport: action.payload,
        loading: false,
      };

    case PRINT_INBOUND_SHIPPING_LABEL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
