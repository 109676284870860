import React, {Component}  from 'react'
import {logoutAdmin} from "../../actions/admin/authenticationActions";
import {connect} from 'react-redux'

class AdminDemo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
        };
        this.submit = this.submit.bind(this);
    }

    submit = async (event) => {
        const self = this;
        event.preventDefault();
        const {id} = this.state;
        const {dispatch} = this.props;

        this.setState({sending: true})

        const response = await dispatch(logoutAdmin(id));
    }

    render() {
        return (
            <div>
            {/*{console.log('this.props', this.props.adminAuth.currentAdmin.id)}*/}
            AdminDemo View
            <button onClick={this.submit}>
                Logout
            </button>
        </div>
        )
    }

}

// mapping for states.
const mapStateToProps = state => ({
    adminAuth: state.adminAuthReducer
});


export default connect(mapStateToProps)(AdminDemo);
