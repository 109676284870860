import {
    FETCH_FEATUREFLAG_BEGIN,
    FETCH_FEATUREFLAG_SUCCESS,
    FETCH_FEATUREFLAG_FAILURE,
} from 'actions/featureFlagActions';

const initialState = {
    featureFlags: [],
    loading: false,
    errors: null,
}

export default function featureFlagReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_FEATUREFLAG_BEGIN:
            return {
                ...state,
                errors: null,
                loading: true,
            };        
        case FETCH_FEATUREFLAG_SUCCESS:
            return {
                ...state,
                loading: false,
                featureFlags: action.payload.flags
            };
        case FETCH_FEATUREFLAG_FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.payload.error
            };
        
        default:
            return {
                ...state
            };
    }
}