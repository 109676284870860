import adminApi from 'services/admin/adminApi';
/*----------------materials--------------------------*/
export const FETCH_ORG_TERMINOLOGIES_BEGIN = 'FETCH_ORG_TERMINOLOGIES_BEGIN';
export const FETCH_ORG_TERMINOLOGIES_SUCCESS = 'FETCH_ORG_TERMINOLOGIES_SUCCESS';
export const FETCH_ORG_TERMINOLOGIES_FAILURE = 'FETCH_ORG_TERMINOLOGIES_FAILURE'

export const DELETE_TERMINOLOGIES = 'DELETE_TERMINOLOGIES'
export const DELETE_ORG_TERMINOLOGIES_SUCCESS = 'DELETE_ORG_TERMINOLOGIES_SUCCESS'
export const DELETE_ORG_TERMINOLOGIES_FAILURE = 'DELETE_ORG_TERMINOLOGIES_FAILURE'

export const GET_ORG_TERMINOLOGIES_BY_ID_BEGIN = 'GET_ORG_TERMINOLOGIES_BY_ID_BEGIN';
export const GET_ORG_TERMINOLOGIES_BY_ID_SUCCESS = 'GET_ORG_TERMINOLOGIES_BY_ID_SUCCESS';
export const GET_ORG_TERMINOLOGIES_BY_ID_FAILURE = 'GET_ORG_TERMINOLOGIES_BY_ID_FAILURE';

export const CREATE_ORG_TERMINOLOGIES_BEGIN = 'CREATE_ORG_TERMINOLOGIES_BEGIN';
export const CREATE_ORG_TERMINOLOGIES_SUCCESS = 'CREATE_ORG_TERMINOLOGIES_SUCCESS';
export const CREATE_ORG_TERMINOLOGIES_FAILURE = 'CREATE_ORG_TERMINOLOGIES_FAILURE';

export const UPDATE_TERMINOLOGIES_BEGIN = 'UPDATE_TERMINOLOGIES_BEGIN';
export const UPDATE_ORG_TERMINOLOGIES_SUCCESS = 'UPDATE_ORG_TERMINOLOGIES_SUCCESS';
export const UPDATE_ORG_TERMINOLOGIES_FAILURE = 'UPDATE_ORG_TERMINOLOGIES_FAILURE';


export const CLEAR_TERMINOLOGIES = 'CLEAR_TERMINOLOGIES';
export const CLEAR_TERMINOLOGIES_NOTIFICATION = 'CLEAR_TERMINOLOGIES_NOTIFICATION';


/*--------------------Terminologies----------------------------*/
export function fetchTerminologies(id) {
    return dispatch => {
        dispatch(fetchTerminologiesBegin());//calling this action to show loading
        return adminApi.fetchTerminologies(id)// calling list of materials
            .then(json => {

                dispatch(fetchTerminologiesSuccess(json));
                return json;
            })
            .catch(error => {
                console.log('error', error);

                dispatch(fetchTerminologiesFailure(error))

            });
    };
}

export function deleteTerminologies(id) {
    return dispatch => {
        return adminApi.deleteTerminologies(id)
            .then(json => {
                dispatch(deleteTerminologiesSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(deleteTerminologiesFailure(error))

            });
    }
}


export function fetchTerminologiesByID(id) {
    return dispatch => {
        dispatch(fetchTerminologiesBegin());//calling this action to show loading
        return adminApi.getTerminologyDetail(id)
            .then(handleErrors)
            .then(json => {
                dispatch(fetchTerminologyByIDSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(fetchTerminologyByIDFailure(error))
            });
    }
}

export function updateTerminologies(data, id) {
    return dispatch => {
        dispatch(updateTerminologiesBegin());
        let res = adminApi.updateTerminology(data, id)
            .then(json => {
                dispatch(updateTerminologiesSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(updateTerminologiesFailure(error));

            })
        return res;
    }
}

export const fetchTerminologiesBegin = () => (
    // the whole object is n action
    {
        type: FETCH_ORG_TERMINOLOGIES_BEGIN
    });

export const fetchTerminologiesSuccess = json => ({
    type: FETCH_ORG_TERMINOLOGIES_SUCCESS,
    data: json.data
});
export const fetchTerminologiesFailure = error => ({
    type: FETCH_ORG_TERMINOLOGIES_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});


export const deleteTerminologiesSuccess = (json) => ({
    type: DELETE_ORG_TERMINOLOGIES_SUCCESS,
    data: json.data.terminologies,
    msg: json.message
})

export const deleteTerminologiesFailure = (error) => ({
    type: DELETE_ORG_TERMINOLOGIES_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'},
    msg: "An error occurred, please try again later"
});

export const fetchTerminologyByIDBegin = () => ({
    type: GET_ORG_TERMINOLOGIES_BY_ID_BEGIN
});
export const fetchTerminologyByIDSuccess = json => ({
    type: GET_ORG_TERMINOLOGIES_BY_ID_SUCCESS,
    data: json.data.terminologies
});

export const fetchTerminologyByIDFailure = error => ({
    type: GET_ORG_TERMINOLOGIES_BY_ID_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'},
    msg: "An error occurred, please try again later"
});

export function updateTerminologiesBegin() {
    return {
        type: UPDATE_TERMINOLOGIES_BEGIN
    }
}

export function updateTerminologiesSuccess(json) {
    return {
        type: UPDATE_ORG_TERMINOLOGIES_SUCCESS,
        data: json.data.terminology

    }
}

export function updateTerminologiesFailure(error) {
    return {
        type: UPDATE_ORG_TERMINOLOGIES_FAILURE,
        payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
    }
}

export function createTerminology(data) {
    return dispatch => {
        dispatch(createTerminologyBegin());
        let res = adminApi.createTerminology(data)
            .then(json => {
                dispatch(createTerminologySuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(createTerminologyFailure(error));
            })
        return res;
    }

}

export function createTerminologyBegin() {
    return {
        type: CREATE_ORG_TERMINOLOGIES_BEGIN,
    }
}


export const createTerminologySuccess = (json) => ({
    type: CREATE_ORG_TERMINOLOGIES_SUCCESS
});

export const createTerminologyFailure = error => ({
    type: CREATE_ORG_TERMINOLOGIES_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});

export function clearTerminologies() {
    return {
        type: CLEAR_TERMINOLOGIES
    }
}

export function clearTerminologiesNotification() {
    return {
        type:CLEAR_TERMINOLOGIES_NOTIFICATION
    }
}

// Private methods

function handleErrors(response) {
    if(!response.success) {
        throw Error(response.statusText);
    }
    return response;
}
