import adminApi from 'services/admin/adminApi';
/*----------------materials--------------------------*/
export const FETCH_ADMIN_ORG_USERS_BEGIN = 'FETCH_ADMIN_ORG_USERS_BEGIN';
export const FETCH_ADMIN_ORG_USERS_SUCCESS = 'FETCH_ADMIN_ORG_USERS_SUCCESS';
export const FETCH_ADMIN_ORG_USERS_FAILURE = 'FETCH_ADMIN_ORG_USERS_FAILURE'

export const DELETE_ADMIN_ORG_USER = 'DELETE_ADMIN_ORG_USER'
export const DELETE_ADMIN_ORG_USER_SUCCESS = 'DELETE_ADMIN_ORG_USER_SUCCESS'
export const DELETE_ADMIN_ORG_USER_FAILURE = 'DELETE_ADMIN_ORG_USER_FAILURE'

export const GET_ADMIN_ORG_USER_BY_ID_BEGIN = 'GET_ADMIN_ORG_USER_BY_ID_BEGIN';

export const GET_ADMIN_ORG_USER_BY_ID_SUCCESS = 'GET_ADMIN_ORG_USER_BY_ID_SUCCESS';
export const GET_ADMIN_ORG_USER_BY_ID_FAILURE = 'GET_ADMIN_ORG_USER_BY_ID_FAILURE';

export const UPDATE_ADMIN_ORG_USERS_BEGIN = 'UPDATE_ADMIN_ORG_USERS_BEGIN';
export const UPDATE_ADMIN_ORG_USER_SUCCESS = 'UPDATE_ADMIN_ORG_USER_SUCCESS';
export const UPDATE_ADMIN_ORG_USER_FAILURE = 'UPDATE_ADMIN_ORG_USER_FAILURE';

export const CLEAR_ADMIN_ORG_USERS = 'CLEAR_ADMIN_ORG_USERS';

export const SET_ADMIN_ORG_USERS_FILTER = 'SET_ADMIN_ORG_USERS_FILTER';
export const CLEAR_ADMIN_ORG_USERS_FILTER = 'CLEAR_ADMIN_ORG_USERS_FILTER';


export const MAGIC_LOGIN_BEGIN = 'MAGIC_LOGIN_BEGIN';
export const MAGIC_LOGIN_SUCCESS = 'MAGIC_LOGIN_SUCCESS';
export const MAGIC_LOGIN_NEW_SUCCESS = 'MAGIC_LOGIN_NEW_SUCCESS';
export const MAGIC_LOGIN_FAILURE = 'MAGIC_LOGIN_FAILURE';

export const CLEAR_MAGIC_LOGIN = 'CLEAR_MAGIC_LOGIN';
export const CLEAR_USER_NOTIFICATION = 'CLEAR_USER_NOTIFICATION';
/*--------------------USERs----------------------------*/
export function fetchAdminOrgUsers(params) {
    return dispatch => {
        dispatch(fetchAdminOrgUsersBegin());//calling this action to show loading
        return adminApi.fetchAdminOrgUsers(params)// calling list of users
            .then(json => {

                dispatch(fetchAdminOrgUsersSuccess(json));
                return json;
            })
            .catch(error => {
                console.log('error', error);
                dispatch(fetchAdminOrgUsersFailure(error))
            });
    };
}

export function deleteAdminOrgUsers(id) {
    return dispatch => {
        return adminApi.deleteAdminOrgUsers(id)
            .then(json => {
                dispatch(deleteAdminOrgUsersSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(deleteAdminOrgUsersFailure(error))
            });
    }
}


export function fetchAdminOrgUsersById(id) {
    return dispatch => {
        dispatch(fetchAdminOrgUsersBegin());//calling this action to show loading
        return adminApi.getAdminOrgUserDetail(id)
            .then(handleErrors)
            .then(json => {
                dispatch(fetchAdminOrgUserByIdSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(fetchAdminOrgUserByIdFailure(error))
            });
    }
}

export function updateAdminOrgUsers(data, id) {
    return dispatch => {
        dispatch(updateAdminOrgUsersBegin());
        let res = adminApi.updateAdminOrgUser(data, id)
            .then(json => {
                dispatch(updateAdminOrgUsersSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(updateAdminOrgUsersFailure(error));

            })
        return res;
    }
}

export const fetchAdminOrgUsersBegin = () => ({
    type: FETCH_ADMIN_ORG_USERS_BEGIN
});

export const fetchAdminOrgUsersSuccess = json => ({
    type: FETCH_ADMIN_ORG_USERS_SUCCESS,
    data: json.data
});

export const fetchAdminOrgUsersFailure = error => ({
    type: FETCH_ADMIN_ORG_USERS_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});

export const deleteAdminOrgUsersSuccess = (json) => ({
    type: DELETE_ADMIN_ORG_USER_SUCCESS,
    data: json.data.user,
    msg: json.message
})

export const deleteAdminOrgUsersFailure = (error) => ({
    type: DELETE_ADMIN_ORG_USER_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'},
    msg: "An error occurred, please try again later"
});

export const fetchAdminOrgUserByIdBegin = () => ({
    type: GET_ADMIN_ORG_USER_BY_ID_BEGIN
});
export const fetchAdminOrgUserByIdSuccess = json => ({
    type: GET_ADMIN_ORG_USER_BY_ID_SUCCESS,
    data: json.data.user
});

export const fetchAdminOrgUserByIdFailure = error => ({
    type: GET_ADMIN_ORG_USER_BY_ID_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'},
    msg: "An error occurred, please try again later"
});

export function updateAdminOrgUsersBegin() {
    return {
        type: UPDATE_ADMIN_ORG_USERS_BEGIN
    }
}

export function updateAdminOrgUsersSuccess(json) {
    return {
        type: UPDATE_ADMIN_ORG_USER_SUCCESS,
        data: json.data.user
    }
}

export function updateAdminOrgUsersFailure(error) {
    return {
        type: UPDATE_ADMIN_ORG_USER_FAILURE,
        payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
    }
}

export function clearAdminOrgUsers() {
    return {
        type: CLEAR_ADMIN_ORG_USERS
    }
}
export function setOrgUsersFilters(filters) {
    return {
        type: SET_ADMIN_ORG_USERS_FILTER,
        payload: {filters}
    }
}
export function clearOrgUsersFilters() {
    return {
        type: CLEAR_ADMIN_ORG_USERS_FILTER
    }
}
export function clearOrgUsersNotification() {
    return {
        type: CLEAR_USER_NOTIFICATION
    }
}


export function userMagicLogin(id) {
    return dispatch => {
        dispatch(userMagicLoginBegin());
        let res = adminApi.magicLogin(id)
            .then(json => {
                dispatch(userMagicLoginSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(userMagicLoginFailure(error));

            })
        return res;
    }
}


export function userMagicLoginNew(id) {
    return dispatch => {
        dispatch(userMagicLoginBegin());
        let res = adminApi.magicLogin(id)
            .then(json => {
                dispatch(userMagicLoginSuccessNew(json));
                return json;
            })
            .catch(error => {
                dispatch(userMagicLoginFailure(error));

            })
        return res;
    }
}

export function userMagicLoginBegin() {
    return {
        type: MAGIC_LOGIN_BEGIN
    }
}

export function userMagicLoginSuccess(json) {
    return {
        type: MAGIC_LOGIN_SUCCESS,
        data: json.data
    }
}

export function userMagicLoginSuccessNew(json) {
    return {
        type: MAGIC_LOGIN_NEW_SUCCESS,
        data: json.data
    }
}

export function userMagicLoginFailure(error) {
    return {
        type: MAGIC_LOGIN_FAILURE,
        payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
    }
}

export function clearMagicLogin() {
    return {
        type: CLEAR_MAGIC_LOGIN
    }
}


// Private methods

function handleErrors(response) {
    if(!response.success) {
        throw Error(response.statusText);
    }
    return response;
}
