import React from 'react'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: 8
    },
  }));

const Column = props => {   

    const classes = useStyles();
    const { className, children, flexBasis } = props;


    return (
        <div className={clsx(classes.root, className)} style={{flexBasis}}>
            {children}        
        </div>
    )
}

Column.propTypes = {

}

export default Column

