import keepspaceApi from 'services/keepspaceApi';
import { updateAuthHeaders } from 'AppConstants';

export const FETCH_ORGANISATIONS_BEGIN = 'FETCH_ORGANISATIONS_BEGIN';
export const FETCH_ORGANISATIONS_SUCCESS = 'FETCH_ORGANISATIONS_SUCCESS';
export const FETCH_ORGANISATIONS_FAILURE = 'FETCH_ORGANISATIONS_FAILURE'

export const SET_CURRENT_ORGANISATION_SUCCESS = 'SET_CURRENT_ORGANISATION_SUCCESS'

export const UPDATE_ORGANISATION_BEGIN = 'UPDATE_ORGANISATION_BEGIN';
export const UPDATE_ORGANISATION_SUCCESS = 'UPDATE_ORGANISATION_SUCCESS';
export const UPDATE_ORGANISATION_FAILURE = 'UPDATE_ORGANISATION_FAILURE'

export const CREATE_ORGANISATION_BEGIN = 'CREATE_ORGANISATION_BEGIN';
export const CREATE_ORGANISATION_SUCCESS = 'CREATE_ORGANISATION_SUCCESS';
export const CREATE_ORGANISATION_FAILURE = 'CREATE_ORGANISATION_FAILURE'

export function fetchOrganisations() {
  return dispatch => {
      dispatch(fetchOrganisationsBegin());
      return keepspaceApi.getOrganisations()
          .then(json => {
              dispatch(fetchOrganisationsSuccess(json));
              return json;
          })
          .catch(error => {
              console.log(error)
              dispatch(fetchOrganisationsFailure(error))
          });
  };
}

export function updateOrganisation(id, organisation) {
  return dispatch => {
      dispatch(updateOrganisationBegin());
      return keepspaceApi.updateOrganisation(id, organisation)
          .then(json => {
              dispatch(updateOrganisationSuccess(json));
              return json;
          })
          .catch(error => {
              console.log(error)
              dispatch(updateOrganisationFailure(error))
          });
  };
}

export function createOrganisation(formData) {
  return dispatch => {
      dispatch(createOrganisationBegin());
      return keepspaceApi.createOrganisation(formData)
          .then(json => {
              dispatch(createOrganisationSuccess(json));
              return json;
          })
          .catch(error => {
              dispatch(createOrganisationFailure(error))
          });
  }
}

// fetch organisation
export const fetchOrganisationsBegin = () => ({
  type: FETCH_ORGANISATIONS_BEGIN
});

export const fetchOrganisationsSuccess = organisations => ({
  type: FETCH_ORGANISATIONS_SUCCESS,
  payload: { organisations }
});

export const fetchOrganisationsFailure = error => ({
  type: FETCH_ORGANISATIONS_FAILURE,
  payload: { error }
});

// update organisation
export const updateOrganisationBegin = () => ({
  type: UPDATE_ORGANISATION_BEGIN
});

export const updateOrganisationSuccess = organisation => ({
  type: UPDATE_ORGANISATION_SUCCESS,
  payload: { organisation }
});

export const updateOrganisationFailure = error => ({
  type: UPDATE_ORGANISATION_FAILURE,
  payload: { error }
});

// create organisation
export const createOrganisationBegin = () => ({
  type: CREATE_ORGANISATION_BEGIN
});

export const createOrganisationSuccess = organisation => ({
  type: CREATE_ORGANISATION_SUCCESS,
  payload: { organisation }
});

export const createOrganisationFailure = error => ({
  type: CREATE_ORGANISATION_FAILURE,
  payload: { error }
});

// set organisation
export function setCurrentOrganisation(organisation) {
  window.localStorage.setItem('organisation-uid', organisation.uid);
  keepspaceApi.refreshAuthToken();
  updateAuthHeaders();
  return dispatch => {
    dispatch(setOrganisationSuccess(organisation))
    return organisation;
  }
}

export const setOrganisationSuccess = organisation => ({
  type: SET_CURRENT_ORGANISATION_SUCCESS,
  organisation
})