import {
    FETCH_CURRENT_USER_BEGIN,
    FETCH_CURRENT_USER_SUCCESS,
    FETCH_CURRENT_USER_FAILURE,
    UPDATE_CURRENT_USER_BEGIN,
    UPDATE_CURRENT_USER_SUCCESS,
    UPDATE_CURRENT_USER_FAILURE,
    CREATE_USER_FAILURE,
    CREATE_USER_SUCCESS,
    UPDATE_CREATE_USER,
    CLEAR_CURRENT_USER_ERRORS,
    INITIAL_CURRENT_USER
} from 'actions/userActions';


const initialState = {
    step: 1,
    currentUser: {},
    loading: false,
    error: null,
    createError: {},
    notification: null,
    newUser: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        termsOfService: false,
    },
    organisationsAttributes: {
        name: '',
        webUrl: '',
        ordersMonth: '',
        companyEmail: ''
    }
}

export default function usersReducer(state = initialState, action) {
    let newUser = { ...state.newUser };
    let organisationsAttributes = { ...state.organisationsAttributes };
    let step = state.step

    switch(action.type) {
        case INITIAL_CURRENT_USER:
            return {
                ...initialState
            }
        case FETCH_CURRENT_USER_BEGIN:
            return  {
                ...state,
                loading: true,
                error: null,
                notification: null
            };
        case FETCH_CURRENT_USER_SUCCESS:
            return  {
                ...state,
                loading: false,
                currentUser: action.payload.user,
                error: null,
                notification: null
            };
        case FETCH_CURRENT_USER_FAILURE:
            return  {
                ...state,
                loading: false,
                error: action.payload.error,
                currentUser: {},
                notification: null
            };
        case UPDATE_CURRENT_USER_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                notification: null
            }
        case UPDATE_CURRENT_USER_SUCCESS:
            return {
              ...state,
              loading: false,
              currentUser: action.payload.user,
              error: null,
              notification: "Details updated"
            }
        case UPDATE_CURRENT_USER_FAILURE:
           return  {
              ...state,
              loading: false,
              error: action.payload.error,
              notification: null
            };
        case CREATE_USER_FAILURE:
          let currentStep = Object.keys(action.payload.error).some(key => key.includes('email', 'password')) ? 1 : 2

          return  {
              ...state,
              loading: false,
              createError: action.payload.error,
              step: currentStep,
              newUser: state.newUser
            };
        case CREATE_USER_SUCCESS:
           return  {
              ...state,
              newUser: state.newUser,
              step: 3
            };
        case UPDATE_CREATE_USER:
            if (action.payload.feature === 'step') {
                step =  action.payload.value
            } else {
                Object.keys(newUser).includes(action.payload.feature) ?
                    newUser[action.payload.feature] = action.payload.value
                    : organisationsAttributes[action.payload.feature] = action.payload.value
            }
            return {
                ...state,
                newUser: newUser,
                step: step,
                organisationsAttributes: organisationsAttributes,
                createError: {}
            }
        case CLEAR_CURRENT_USER_ERRORS:
            return {
                ...state,
                loading: false,
                error: null,
                notification: null
            }
        default:
            return state
    }
}
