import React, { Component } from 'react'
import { connect } from 'react-redux'

import { CircularProgress, Button, Paper } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import PropTypes from 'prop-types';
import * as qs from 'query-string';

import keepspaceApi from 'services/keepspaceApi';

const styles = theme => ({
  paper: {
    position: 'relative',
    height: '100%',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    boxShadow: 'none'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: '20px'
  },
  submit: {
    marginTop: theme.spacing(3),
    color: 'white',
    backgroundImage: 'linear-gradient(#45b5f2, #249ddd)',
    boxShadow: 'none',
    textTransform: 'none',
    fontWeight: 'bolder',
  },
  title: {
    fontSize: 24,
    marginBottom: 24
  },
  subtitle: {
    width: '100%',
    textAlign: 'center',
    padding: '8px',
    fontSize: 16,
    lineHeight: 'normal'
  },
  header: {
    fontSize: theme.spacing(3.5),
    fontWeight: 'lighter',
    padding: '50px 50px 32px'
  },
  errorMessage: {
    color: 'red',
    fontSize: 11
  }
})

class AcceptInviatation extends Component {
  constructor(props) {
    super(props);
    const params = qs.parse(this.props.location.search)
    this.fileInputRef = React.createRef();
    this.state = {
      invitationToken: params['invitation_token'],
      firstName: params['first_name'],
      password: '',
      confirmPassword: '',
      submissionSuccessful: false,
      errors: []
    };
    this.submit = this.submit.bind(this);

  }
  
  componentDidMount() {
    // custom rule will have name 'isPasswordMatch'
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
        if (value !== this.state.password) {
            return false;
        }
        return true;
    });
  }

  componentWillUnmount() {
      // remove rule when it is not needed
      ValidatorForm.removeValidationRule('isPasswordMatch');
  }

  submit(e) {
    e.preventDefault()

    let body = {
      invitation_token: this.state.invitationToken,
      password: this.state.password,
      password_confirmation: this.state.confirmPassword
    }

    const { history } = this.props;

    keepspaceApi.acceptInvitation(body)
      .then((response) => {
        history.push('/auth/login');
      })
      .catch((error) => {
        if (error.response) {
          this.setState({
              errors: error.response.data.errors
          });
        }
      });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }


  render(props) {
    const { submit: submitForm } = this
    const { classes } = this.props;
    return (
      <Paper className={classes.paper}>

        {!this.state.sending ? (
            <>
              <div className={classes.title}>Welcome to Keepspace, {this.state.firstName}!</div>
              <div className={classes.subtitle}>Create your password</div>
              <ValidatorForm
                    ref={this.fileInputRef}
                    onSubmit={submitForm}
                    onError={errors => console.log(errors)}
                >
                <TextValidator
                    validators={['required', 'minStringLength:6']}
                    errorMessages={['This field is required',  'Minimum length 6 characters']}
                    margin="normal"
                    required
                    fullWidth
                    value={this.state.password}
                    onChange={this.handleChange}
                    id="password"
                    label="Password"
                    name="password"
                    type="password"
                    autoComplete="username"
                    InputLabelProps={{ style: { color: 'rgba(0, 0, 0, 0.54)' } }}
                  // autoFocus
                  />
                  <div className={classes.errorMessage}>
                    {this.state.errors}
                  </div>
                  <TextValidator
                    validators={['isPasswordMatch','required', 'minStringLength:6']}
                    errorMessages={['Passwords don\'t match', 'This field is required',  'Minimum length 6 characters']}
                    margin="normal"
                    required
                    fullWidth
                    value={this.state.confirmPassword}
                    onChange={this.handleChange}
                    id="confirmPassword"
                    label="Confirm Password"
                    name="confirmPassword"
                    type="password"
                    autoComplete="username"
                    InputLabelProps={{ style: { color: 'rgba(0, 0, 0, 0.54)' } }}
                  // autoFocus
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className={classes.submit}
                    color='secondary'
                  >
                    Join Keepspace
                  </Button>
                  </ValidatorForm>
            </>
        ) : (
          <CircularProgress />
        )}
      </Paper>
    )
  }
}

AcceptInviatation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(
  null,
  { AcceptInviatation },
)(withStyles(styles)(AcceptInviatation))
