import { pubApiPath } from "AppConstants";
import ApiService from "./Api";

const BASE_URL = pubApiPath;
let headers = {};

let client = new ApiService({ baseURL: BASE_URL, headers });

const keepspacePubApi = {};

keepspacePubApi.getCountries = () => client.get("/countries");

export default keepspacePubApi;
