import {
    FETCH_ORG_TERMINOLOGIES_BEGIN,
    FETCH_ORG_TERMINOLOGIES_SUCCESS,
    FETCH_ORG_TERMINOLOGIES_FAILURE,
    CREATE_ORG_TERMINOLOGIES_BEGIN,
    CREATE_ORG_TERMINOLOGIES_SUCCESS,
    CREATE_ORG_TERMINOLOGIES_FAILURE,
    GET_ORG_TERMINOLOGIES_BY_ID_BEGIN,
    GET_ORG_TERMINOLOGIES_BY_ID_SUCCESS,
    GET_ORG_TERMINOLOGIES_BY_ID_FAILURE,
    UPDATE_ORG_TERMINOLOGIES_SUCCESS,
    UPDATE_ORG_TERMINOLOGIES_FAILURE,
    DELETE_ORG_TERMINOLOGIES_SUCCESS,
    DELETE_ORG_TERMINOLOGIES_FAILURE,
    CLEAR_TERMINOLOGIES,
    UPDATE_TERMINOLOGIES_BEGIN,
    CLEAR_TERMINOLOGIES_NOTIFICATION
} from 'actions/admin/terminologiesActions';


const initialState = {
    terminologies: [],
    loading: false,
    error: null,
    notification: null,
    dummyData: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    pagination: {},
    terminology: {},
    formSuccess: false,
    searchResult: []
}

export default function terminologyOrgReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_ORG_TERMINOLOGIES_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_ORG_TERMINOLOGIES_SUCCESS:
            return {
                ...state,
                loading: false,
                terminologies: action.data.terminologies,
                pagination: action.data.pagination,
                filters: action.data.filters,
                organisation: action.data.organisation
            };
        case FETCH_ORG_TERMINOLOGIES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                terminologies: []
            };

        case CREATE_ORG_TERMINOLOGIES_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case CREATE_ORG_TERMINOLOGIES_SUCCESS:
            return {
                ...state,
                loading: false,
                formSuccess: true
            };
        case CREATE_ORG_TERMINOLOGIES_FAILURE:
            return {
                ...state,
                loading: false,
                formSuccess: false,
                error: action.payload.error,
            };
        case GET_ORG_TERMINOLOGIES_BY_ID_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case GET_ORG_TERMINOLOGIES_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                terminology: action.data
            };

        case GET_ORG_TERMINOLOGIES_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };


        case UPDATE_TERMINOLOGIES_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case UPDATE_ORG_TERMINOLOGIES_SUCCESS:

            return {
                ...state,
                loading: false,
                formSuccess: true,
                terminology: action.data
            }
        case UPDATE_ORG_TERMINOLOGIES_FAILURE:
            return {
                ...state,
                loading: false,
                formSuccess: false,
                error: action.payload.error,
            };
        case DELETE_ORG_TERMINOLOGIES_SUCCESS:
            return {
                ...state,
                formSuccess: true,
                loading: false,
                notification: action.msg
            };
        case DELETE_ORG_TERMINOLOGIES_FAILURE:
            return {
                ...state,
                loading: false,
                formSuccess: false,
                error: action.payload.error,
            };
        case CLEAR_TERMINOLOGIES:
            return {
                ...initialState
            }
        case CLEAR_TERMINOLOGIES_NOTIFICATION:
            return {
                ...state,
                error:null,
                notification:null
            }
        default:
            return state
    }
}
