'use strict';


export const AuthActions = {
    LOGIN_START: 'LOGIN_START',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',
    CLEAR_AUTH: 'CLEAR_AUTH'
}

// add different action types here with namespace like added above for authActions
// and then import particular types in required files instead of requiring all

const Actions = {
    AuthActions
}

export default Actions;
