import {
    FETCH_SETTING_TROLLEY_BEGIN,
    FETCH_SETTING_TROLLEY_SUCCESS,
    FETCH_SETTING_TROLLEY_FAILURE,
    DELETE_SETTING_TROLLEY_SUCCESS,
    DELETE_SETTING_TROLLEY_FAILURE,
    FETCH_SETTING_TROLLEY_BY_ID_SUCCESS,
    FETCH_SETTING_TROLLEY_BY_ID_FAILURE,
    UPDATE_SETTING_TROLLEY_BEGIN,
    UPDATE_SETTING_TROLLEY_FAILURE,
    UPDATE_SETTING_TROLLEY_SUCCESS,
    CREATE_SETTING_TROLLEY_BEGIN,
    CREATE_SETTING_TROLLEY_FAILURE,
    CREATE_SETTING_TROLLEY_SUCCESS,
    CLEAR_SETTING_TROLLEY,
    PRINT_BARCODES_TROLLEY_BEGIN,
    PRINT_BARCODES_TROLLEY_SUCCESS,
    PRINT_BARCODES_TROLLEY_FAILURE,
    CLEAR_TROLLEY_NOTIFICATION
} from 'actions/admin/trolliesActions';

const initialState = {
    trollies: [],
    trolley: {},
    pagination: {},
    loading: false,
    error: null,
    notification: null,
    dummyData: [...Array(10)],
    formLoading: false,
    formSuccess: false,
    barcodes: null,
    filename: ""
}


export default function trolliesReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_SETTING_TROLLEY_BEGIN:
            return {
                ...state,//imp line
                loading: true,
                formSuccess: false,
                error: null
            };

        case FETCH_SETTING_TROLLEY_SUCCESS:
            return {
                ...state,
                loading: false,
                trollies: action.data,
                pagination: action.pagination
            };

        case FETCH_SETTING_TROLLEY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                trolley: {}
            };
        case DELETE_SETTING_TROLLEY_SUCCESS:
            return {
                ...state,
                error: null,
                formLoading: false,
                formSuccess: true,
                trollies: state.trollies.filter(trolley => trolley.id !== action.data.id)
            };
        case DELETE_SETTING_TROLLEY_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                formLoading: false,
                formSuccess: true,
                trollies: state.trollies
            };

        case FETCH_SETTING_TROLLEY_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                //childLoading: false,
                trolley: action.payload
            };

        case FETCH_SETTING_TROLLEY_BY_ID_FAILURE:
            return {
                ...state,
                //childLoading: false,
                error: action.payload.error
            };
        case UPDATE_SETTING_TROLLEY_BEGIN: {
            return {
                ...state,
                formLoading: true,
                formSuccess: false,
                notification: null,
                error: null
            }
        }
        case UPDATE_SETTING_TROLLEY_SUCCESS: {

            // let updatedPacking =  applyPackingInterface(action.payload.trolley)
            return {
                ...state,
                formLoading: false,
                formSuccess: true,
                error: null,
                updatePackingErrors: null,
                trolley: action.payload,
            }
        }
        case UPDATE_SETTING_TROLLEY_FAILURE: {

            return {
                ...state,
                formLoading: false,
                formSuccess: false,
                error: action.payload.error,
                updatePackingErrors: action.payload.error
            }
        }
        case CREATE_SETTING_TROLLEY_BEGIN:
            return {
                ...state,
                formLoading: true,
                formSuccess: false,
                notification: null,
                error: null
            }

        case CREATE_SETTING_TROLLEY_SUCCESS: {

            return {
                ...state,
                error: null,
                formSuccess: true,
                formLoading: false,
                trolley: action.payload,
            }
        }
        case CREATE_SETTING_TROLLEY_FAILURE:

            return {
                ...state,
                formLoading: false,
                formSuccess: false,
                error: action.payload.error

            }

        case CLEAR_SETTING_TROLLEY:
            return {
                ...initialState
            }
        case PRINT_BARCODES_TROLLEY_BEGIN:
            return {
                ...state,
                formLoading: true,
                barcodes: null,
                error: null
            }
        case PRINT_BARCODES_TROLLEY_SUCCESS:
            let headerLine = action.payload.headers['content-disposition'];
            let startFileNameIndex = headerLine.indexOf('"') + 1
            let endFileNameIndex = headerLine.lastIndexOf('"');
            let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
            return {
                ...state,
                formLoading: false,
                barcodes: action.payload.data,
                filename: filename
            }
        case PRINT_BARCODES_TROLLEY_FAILURE:
            return {
                ...state,
                error: action.error
            }
            
        case CLEAR_TROLLEY_NOTIFICATION:
            return {
                ...state,
                error:null,
                notification:null
            }
        default:
            // Always have a default case in case a reducer doesn't complete
            return state;
    }
};