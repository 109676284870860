import React, { Fragment, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Paper, Avatar, Typography } from '@material-ui/core';


const Loader = props => {
  const { singleLine } = props;



  return (<>
    <div className="loaderContent">
      <div className="spinner">
        <div className="dot1"></div>
        <div className="dot2"></div>
      </div>
    </div>
    {/*<div className="loaderContent" >
      <div className="stage" >
        <div className="dot-windmill"></div>
      </div></div >*/}
  </>);
};

export default Loader;
