import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, Button, Hidden } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import HeaderImage from 'assets/img/undraw_growth_analytics_8btt.svg'
import { reverseUserRoute } from 'routes';

const useStyles = makeStyles(theme => ({
  root: {
    // minHeight: 400,
    marginBottom: 16
  },
  summaryButton: {
    backgroundColor: theme.palette.white
  },
  barChartIcon: {
    marginRight: theme.spacing(1)
  },
  image: {
    width: '100%',
    maxHeight: 400,
    minHeight: 400 // These duplications exist to control heights of neiboring elements on load
  }
}));

const Header = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const session = useSelector(state => state.reduxTokenAuth.currentUser);

  let d = new Date();
  let hour = d.getHours();
  const greeting = hour < 12 ? "Good morning" : hour > 17 ? "Good evening" : "Good afternoon"

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems="center"
        container
        justify="space-between"
        spacing={3}
      >
        <Grid
          item
          md={6}
          xs={12}
        >
          <Typography
            component="h2"
            gutterBottom
            variant="overline"
          >
            Home
          </Typography>
          <Typography
            component="h1"
            gutterBottom
            variant="h3"
          >
            {greeting}, {session.attributes.firstName}
          </Typography>
          <Typography
            gutterBottom
            variant="subtitle1"
          >
            Here’s what’s happening with your orders today
          </Typography>
          <Button
            className={classes.summaryButton}
            edge="start"
            variant="contained"
            component={RouterLink}
            to={reverseUserRoute('Orders:Summary')}
          >
            <BarChartIcon className={classes.barChartIcon} />
            View summary
          </Button>
        </Grid>
        <Hidden smDown>
          <Grid
            item
            md={6}
          >
            <img
              alt="Cover"
              className={classes.image}
              src={HeaderImage}
            />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
