import {
    FETCH_PUBLIC_COMMENTS_BEGIN,
    FETCH_PUBLIC_COMMENTS_SUCCESS,
    FETCH_PUBLIC_COMMENTS_FAILURE,
    FETCH_PUBLIC_COMMENT_BY_ID_BEGIN,
    FETCH_PUBLIC_COMMENT_BY_ID_SUCCESS,
    FETCH_PUBLIC_COMMENT_BY_ID_FAILURE,
    // DELETE_PUBLIC_COMMENT_SUCCESS,
    // DELETE_PUBLIC_COMMENT_FAILURE,
    CREATE_PUBLIC_COMMENT_BEGIN,
    CREATE_PUBLIC_COMMENT_SUCCESS,
    CREATE_PUBLIC_COMMENT_FAILURE,
    // UPDATE_CONVERSATION_SUCCESS,
    // UPDATE_CONVERSATION_FAILURE,
    UPDATE_CONVERSATION_READ,
} from 'actions/publicCommentActions';


const initialState = {
    conversations: [],
    conversation: {},
    loading: false,
    error: null,
    uploading: false,
}

export default function publicCommentsReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_PUBLIC_COMMENTS_BEGIN:
            return  {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_PUBLIC_COMMENTS_SUCCESS:
            return  {
                ...state,
                loading: false,
                conversations: action.payload.conversations?.chats || [],
                pagination: action.payload.conversations?.pagination,
            };

        case FETCH_PUBLIC_COMMENTS_FAILURE:
            return  {
                ...state,
                loading: false,
                error: action.payload.error,
                conversations: []
            };
        case FETCH_PUBLIC_COMMENT_BY_ID_BEGIN:
            return  {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_PUBLIC_COMMENT_BY_ID_SUCCESS:
            return  {
                ...state,
                loading: false,
                conversation: action.payload.conversation,
                conversations: state.conversations.map((conversation) => {
                  if (conversation.id === parseFloat(action.payload.conversation.id)) {
                    return {
                      ...conversation,
                      unread: 0
                    }
                  } else return conversation;
                })
            };

        case FETCH_PUBLIC_COMMENT_BY_ID_FAILURE:
            return  {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case UPDATE_CONVERSATION_READ:
            return {
              ...state,
              error: null,
              conversation: {...state.conversation, unread: 0},
            };
        case CREATE_PUBLIC_COMMENT_BEGIN:
            return {
                ...state,
                uploading: true,
                error: null
            };

        case CREATE_PUBLIC_COMMENT_SUCCESS:
            let updatedConversations = state.conversations.map(conversation => conversation.id === parseFloat(action.payload.id) ?
               {id: parseFloat(action.payload.id), last_message: action.payload.public_comment} : conversation
            )
            return {
                ...state,
                uploading: false,
                error: null,
                conversation: state.conversation.id === action.payload.id ?
                  {
                    ...state.conversation,
                    public_comments: [...state.conversation.public_comments, action.payload.public_comment]
                  } : state.conversation,
                conversations: updatedConversations
              };

        case CREATE_PUBLIC_COMMENT_FAILURE:
            return {
                ...state,
                uploading: false,
                error: action.payload.error,
            };

        default:
            return state
    }
}
