import React, { Component } from 'react'
import authStyles from "../styles.jsx";

// import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import { TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import CheckboxValidator from 'components/ValidatorElements/CheckboxValidator';

const styles = theme => ({
    ...customCheckboxRadioSwitch,
    ...authStyles,
    // ...registerPageStyle,
    nameFields: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    footer: {
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.54)',
      padding: '20px',
      fontSize: '10px',
      lineHeight: 'normal'
    },
    textField: {
      margin: '10px 0'
    },
    divider: {
      paddingTop: '20px',
      width: '100%',
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.35)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.35)',
      lineHeight: '0.1em',
      margin: '10px 0 20px',
      "& > span": {
          background:'#ffffff',
          padding: '0 10px',
      }
    },
    header: {
      fontSize: '20px',
      fontWeight: '200',
      marginTop: '50px'
    },
  });

export class Step1 extends Component {

  continue = e => {
    e.preventDefault();

    this.props.nextStep();
  };

  componentDidMount() {
      ValidatorForm.addValidationRule('isTruthy', value => value);
  }

  render() {
    const { classes, values, handleChange, errors } = this.props;
    return (
       <>
        <div className={classes.header}> Create an Account</div>
          <ValidatorForm
              ref="form"
              onSubmit={this.continue}
              onError={errors => console.log(errors)}
          >
              <div className={classes.nameFields}>
                  <TextValidator
                    id="firstName"
                    name="firstName"
                    // autoComplete="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    label="First name"
                    className= {classes.textField}
                    style={{width: '45%'}}
                    validators= {['required']}
                    errorMessages= {['is required']}
                    // variant='outlined'
                  />
                  <TextValidator
                    id="lastName"
                    name="lastName"
                    autoComplete="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    label="Last name"
                    className= {classes.textField}
                    style={{width: '45%'}}
                    validators= {['required']}
                    errorMessages= {['is required']}

                  />
              </div>

              <TextValidator
                id="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                error={errors['email'] ? errors['email'] : false}
                helperText={errors['email'] ? errors['email'][0] : false}
                label="Email"
                validators= {['required', 'isEmail']}
                errorMessages= {['is required', 'Email not valid']}
                fullWidth
                className= {classes.textField}

              />
              <TextValidator
                name="password"
                type="password"
                id="password"
                value={values.password}
                onChange={handleChange}
                autoComplete="current-password"
                error={errors['password'] ? errors['password'] : false}
                helperText={errors['password'] ? errors['password'][0] : false}
                fullWidth
                label="Password"
                validators= {['required', 'minStringLength:6']}
                errorMessages= {['is required', 'Min 6 characters']}
                className= {classes.textField}

              />

          <FormControlLabel
            classes={{
              root: classes.checkboxLabelControl,
              label: classes.checkboxLabel
            }}
            control={
               <CheckboxValidator
                // required
                name="termsOfService"
                checked={values.termsOfService}
                onChange={handleChange}
                value={values.termsOfService}
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                 }}
                validators= {['isTruthy']}
                errorMessages= {['Accept to continue']}
              />
            }
            label={
              <span>
                I agree to the{" "}
                <a href="https://www.keepspace.com.au/terms-and-conditions">Terms and Conditions</a>.
              </span>
            }
          />
          <Button
              type="submit"
              fullWidth
              variant="contained"
              color='secondary'
          >
              Get KeepSpace
          </Button>
          <div className={classes.divider} />
            <div className={classes.footer}>
              We're commited to your privacy. KeepSpace uses the information you provide to us to contact you about relevant content. You may unsubcribe from the communication at any time. For more information check our <a href="/privacy_policy">Privacy Policy</a>.
            </div>
          <div>

          {
              // eslint-disable-next-line eqeqeq
              values.errorCode == 500 ?
                  <h6>Server error 500, please contact support</h6> : (null)
          }
          </div>
        </ValidatorForm>
      </>
    );
  }
}

export default withStyles(styles)(Step1)
