import {
    FETCH_INTEGRATIONS_BEGIN,
    FETCH_INTEGRATIONS_SUCCESS,
    FETCH_INTEGRATIONS_FAILURE,
    CREATE_INTEGRATION_BEGIN,
    CREATE_INTEGRATION_SUCCESS,
    CREATE_INTEGRATION_FAILURE,
    GET_INTEGRATION_BY_ID_BEGIN,
    GET_INTEGRATION_BY_ID_SUCCESS,
    GET_INTEGRATION_BY_ID_FAILURE,
    UPDATE_INTEGRATION_SUCCESS,
    UPDATE_INTEGRATION_FAILURE,
    DELETE_INTEGRATION_SUCCESS,
    DELETE_INTEGRATION_FAILURE,
    CLEAR_INTEGRATIONS,
    UPDATE_INTEGRATIONS_BEGIN,
    GET_INTEGRATION_BY_NAME_BEGIN,
    GET_INTEGRATION_BY_NAME_FAILURE,
    GET_INTEGRATION_BY_NAME_SUCCESS,
    CLEAR_INTEGRATIONS_NOTIFICATIONS
} from 'actions/admin/integrationActions';


const initialState = {
    integrations: [],
    loading: false,
    error: null,
    notification: null,
    dummyData: [...Array(10)],
    pagination: {},
    integrationDetail: {},
    formSuccess: false,
    redirectUrl: null,
    apps: []
}

export default function orgIntegrationsReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_INTEGRATIONS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                notification: null,
            };
        case FETCH_INTEGRATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                integrations: action.data.app_integrations,
                pagination: action.data.pagination,
                filters: action.data.filters,
                apps: action.data.filters.app_options,
                organisation: action.data.organisation
            };
        case FETCH_INTEGRATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                integrations: []
            };

        case CREATE_INTEGRATION_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                notification: null,
                formSuccess: false,
                redirectUrl: null
            };
        case CREATE_INTEGRATION_SUCCESS:
            return {
                ...state,
                loading: false,
                formSuccess: true,
                redirectUrl: action.payload.data
            };
        case CREATE_INTEGRATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case GET_INTEGRATION_BY_ID_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                notification: null,
            };

        case GET_INTEGRATION_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                integrationDetail: action.payload.data,
                apps: action.payload.apps
            };

        case GET_INTEGRATION_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case UPDATE_INTEGRATIONS_BEGIN:
            return {
                ...state,
                loading: true,
                formSuccess: false,
                error: null,
                notification: null,
            };
        case UPDATE_INTEGRATION_SUCCESS:

            return {
                ...state,
                loading: false,
                formSuccess: true,
                integrationDetail: action.data
            }
        case UPDATE_INTEGRATION_FAILURE:
            return {
                ...state,
                loading: false,
                formSuccess: false,
                error: action.payload.error,
            };
        case DELETE_INTEGRATION_SUCCESS:
            return {
                ...state,
                formSuccess: true,
                loading: false,
                notification: action.msg
            };
        case DELETE_INTEGRATION_FAILURE:
            return {
                ...state,
                loading: false,
                formSuccess: false,
                error: action.payload.error,
            };
        case CLEAR_INTEGRATIONS:
            return {
                ...state,
                integrations: [],
                loading: false,
                error: null,
                integrationDetail: {},
                formSuccess: false
            }
        case CLEAR_INTEGRATIONS_NOTIFICATIONS:
            return {
                ...state,
                error:null,
                notification:null
            }
        default:
            return state
    }
}
