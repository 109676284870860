// React
import React, {Component} from 'react'

// Redux
import {connect} from 'react-redux'
import {signInUser} from 'redux-token-auth-config'
import {fetchFlags} from 'actions/featureFlagActions';
import {fetchOrganisations, setCurrentOrganisation} from 'actions/organisationActions';
import {fetchCurrentUser} from 'actions/userActions';

// To refresh the auth token in the api client
import keepspaceApi from 'services/keepspaceApi';
import {updateAuthHeaders} from 'AppConstants';

// Local components
import LoginForm from '../components/LoginForm';
import SuccessSnackbar from 'components/Help/SuccessSnackbar';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            errors: [],
            errorCode: null,
            sending: false,
            openSnackbar: ((this.props.invitationError !== null) || (this.props.invitationAccepted))
        };

        this.submit = this.submit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updatePermissions = this.updatePermissions.bind(this);
        this.setOrganisation = this.setOrganisation.bind(this);
        this.fetchOrganisations = this.fetchOrganisations.bind(this);
        this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
    }

    componentDidMount() {
        if(this.props.invitationError !== null) {
            this.setState({openSnackbar: true})
        }

        const token = window.localStorage.getItem('access-token');
        const oUid = window.localStorage.getItem('organisation-uid');

        if(token && token !== "null" && oUid) {
            this.props.history.push(`/`)
        }
    }

    submit(event) {
        event.preventDefault();
        const {email, password} = this.state;
        const {dispatch} = this.props;

        this.setState({sending: true})
        dispatch(signInUser({email, password}))
            .then(response => {
                this.updatePermissions(response)
                this.setState({sending: false})
                this.fetchOrganisations()
            })
            .catch(error => {
                this.setState({sending: false})
                if(error?.response) {
                    this.setState({
                        errors: error.response.data.errors
                    });
                }
                console.log(error);
                console.log(error?.response ? error.response : null);
            });
    }

    updatePermissions(response) {
        const {dispatch} = this.props;

        keepspaceApi.refreshAuthToken();
        updateAuthHeaders();
        try {
            window._slaask.identify();
        } catch(error) {
            console.warn('Slaask could not identify contact', error)
        }

    }

    fetchOrganisations() {
        const {dispatch, history} = this.props;
        dispatch(fetchOrganisations())
            .then(response => {
                if(response.length === 1) {
                    this.setOrganisation(response[0])

                } else if(response.length === 0) {
                    history.push('/auth/add_organisation');

                } else {
                    history.push('/auth/organisations');
                }

            })
            .catch(error => {
                console.log('error', error)
            });
    }

    async setOrganisation(organisation) {
        const {dispatch, history} = this.props;

        await dispatch(setCurrentOrganisation(organisation))
        if(this.props.currentUser.id) await dispatch(fetchCurrentUser(this.props.currentUser.id))
        history.push('/');
    }

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    // Used for facebook auth, whereas plain auth does this automatically
    setLocalStorage(uid, accessToken, clientToken) {
        if(accessToken) {
            window.localStorage.setItem('uid', uid);
            window.localStorage.setItem('access-token', accessToken);
            window.localStorage.setItem('client', clientToken);
        }
    }

    handleSnackbarClose() {
        this.setState({openSnackbar: false})
    };

    render() {
        const {invitationError, invitationAccepted} = this.props;

        return (
            <>
                <SuccessSnackbar
                    onClose={this.handleSnackbarClose}
                    open={this.state.openSnackbar}
                    error={(invitationError && invitationError.data) ? invitationError.data : null}
                    messageText={invitationAccepted ? "Invitation Accepted" : null}
                />
                <LoginForm
                    email={this.state.email}
                    password={this.state.password}
                    handleChange={this.handleChange}
                    submit={this.submit}
                    sending={this.state.sending}
                    errors={this.state.errors}
                />
            </>
        )
    }

}

const mapStateToProps = (state) => ({
    currentUser: state.reduxTokenAuth.currentUser,
    invitationError: state.organisationUsersReducer.invitationError,
    invitationAccepted: state.organisationUsersReducer.invitationAccepted,
})

export default connect(mapStateToProps)(Login);
