import {
    FETCH_ORGANISATIONS_BEGIN,
    FETCH_ORGANISATIONS_SUCCESS,
    FETCH_ORGANISATIONS_FAILURE,
    UPDATE_ORGANISATION_BEGIN,
    UPDATE_ORGANISATION_SUCCESS,
    UPDATE_ORGANISATION_FAILURE,
    CREATE_ORGANISATION_BEGIN,
    CREATE_ORGANISATION_SUCCESS,
    CREATE_ORGANISATION_FAILURE,
    SET_CURRENT_ORGANISATION_SUCCESS,
} from 'actions/organisationActions';


const initialState = {
    currentOrganisation: { currentOrganisationUser: {} },
    organisations: [],
    loading: false,
    error: null,
    uploading: false,
}

export default function organisationsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ORGANISATIONS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_ORGANISATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                organisations: action.payload.organisations
            };
        case FETCH_ORGANISATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                organisations: []
            };
        case UPDATE_ORGANISATION_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case UPDATE_ORGANISATION_SUCCESS:
            let updatedOrganisations = state.organisations.map(organisation => organisation.id === action.payload.organisation.id ?
                action.payload.organisation : organisation
            )

            // if action was updating current organisation, set replace it with the payload reuslt
            // otherwise do not change current organisation 
            let organisation = state.currentOrganisation.id === action.payload.organisation.id ? action.payload.organisation : state.currentOrganisation

            return {
                ...state,
                loading: false,
                currentOrganisation: organisation,
                organisations: updatedOrganisations
            };
        case UPDATE_ORGANISATION_FAILURE:
            let errorObject = action.payload.error.response
            if (action.payload.error.response && action.payload.error.response.status === 422) {
                // Unprocessable Entity; It's likely that the response contains fields with errors
                errorObject = {
                    ...action.payload.error.response.data
                }
            }
            return {
                ...state,
                loading: false,
                error: errorObject
            };
        case CREATE_ORGANISATION_BEGIN:
            return {
                ...state,
                uploading: true,
                error: null
            };
        case CREATE_ORGANISATION_SUCCESS:
            return {
                ...state,
                uploading: false,
                error: null,
                organisations: [action.payload.organisation, ...state.organisations]
            };
        case CREATE_ORGANISATION_FAILURE:
            let errorCreateObject = action.payload.error.response
            if (action.payload.error.response && action.payload.error.response.status === 422) {
                // Unprocessable Entity; It's likely that the response contains fields with errors
                errorCreateObject = {
                    ...action.payload.error.response.data
                }
            }
            return {
                ...state,
                uploading: false,
                error: errorCreateObject,
            };
        case SET_CURRENT_ORGANISATION_SUCCESS:
            return {
                ...state,
                currentOrganisation: action.organisation
            };

        default:
            return state
    }
}
