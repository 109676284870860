import palette from './palette';
//import {$h1,$h2,$h3,$h4,$h5,$h6,$p} from 'assets/scss/keepspace-css/admin/_variable.scss';
export default {
  h1: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '2.5rem',//'35px',
    letterSpacing: '-0.24px',
    //lineHeight: '2.5rem'
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '2rem',//'29px',
    letterSpacing: '-0.24px',
    //lineHeight: '2rem'
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '1.5rem',//'24px',
    letterSpacing: '-0.06px',
    //lineHeight: '1.5rem'
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '1.25rem',//'20px',
    letterSpacing: '-0.06px',
    //lineHeight: '1.25rem'
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '1.1rem',//'16px',
    letterSpacing: '-0.05px',
    //lineHeight: '1.1rem'
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '0.9rem',//'.875rem',//'14px',
    letterSpacing: '-0.05px',
    //lineHeight: '0.9rem'
  },
  subtitle1: {
    color: palette.text.primary,
    fontSize: '1.1rem',//'16px',
    letterSpacing: '-0.05px',
    //lineHeight: '1.1rem'
  },
  subtitle2: {
    color: palette.text.secondary,
    fontWeight: 400,
    fontSize: '.75rem',
    letterSpacing: '-0.05px',
    //lineHeight: '.75rem'
  },
  body1: {
    color: palette.text.primary,
    fontSize: '0.9rem',//'14px'
    letterSpacing: '-0.05px',
    //lineHeight: '0.9rem'
  },
  body2: {
    color: palette.text.secondary,
    fontSize: '.875rem',//'12px',
    letterSpacing: '-0.04px',
    //lineHeight: '.875rem'
  },
  button: {
    color: palette.text.primary,
    fontSize: '0.9rem',//'14px'
  },
  caption: {
    color: palette.text.secondary,
    fontSize: '.75rem',//'11px',
    letterSpacing: '0.33px',
    //lineHeight: '.75rem'
  },
  overline: {
    color: palette.text.secondary,
    fontSize: '.75rem',//'11px',
    fontWeight: 500,
    letterSpacing: '0.33px',
    //lineHeight: '.75rem',
    textTransform: 'uppercase'
  },
  p: {
    color: palette.text.primary,
    fontSize: '0.9rem',//'14px'
    letterSpacing: '-0.05px',
    //lineHeight: '0.9rem'
  },
};
