import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import AlmostThere from '../AlmostThere';


const styles = theme => ({
    container: {
      minHeight: "400px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "50px"
    },
    divider: {
      width: '100%'
    },
    resendEmail: {
      textAlign: 'center'
    }
  });



export class Step3 extends Component {
  continue = e => {
    e.preventDefault();
    // PROCESS FORM //
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { handleResendConfirmation } = this.props;

    return (
      <AlmostThere handleResendConfirmation={handleResendConfirmation}/>
   );
  }
}

export default withStyles(styles)(Step3)
