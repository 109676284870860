import React from 'react';
import { makeStyles } from '@material-ui/styles';
// import SweetAlert from "react-bootstrap-sweetalert";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Typography
} from '@material-ui/core';

const ConfirmAlert = ({ open,  description,  onSubmit,  onClose, title, subtext }) => {

    // const { onConfirm, onCancel, open } = props;
    const classes = useStyles();

    return (
        <Dialog open={open} >
        { title ? 
          <DialogTitle>
            <Typography variant="h4">{title}</Typography>
          </DialogTitle>
          : <></>}
          <DialogContent>
            <DialogContentText>{description}</DialogContentText>
            <DialogContentText variant="body2">{subtext}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className={classes.confirm} onClick={onSubmit}>
              YES, I CONFIRM
            </Button>
            <Button  onClick={onClose} autoFocus>
              CANCEL
            </Button>
          </DialogActions>
        </Dialog>
    )

}

const useStyles = makeStyles(theme => ({
    confirm: {
      color: theme.palette.error.main
    }
}))

export default ConfirmAlert;
