import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import {connect} from 'react-redux';

import CategoriesIndex from 'views/user/Categories/CategoriesIndex';
import {fetchCategories} from 'actions/categoryActions';

import {
  withStyles
} from '@material-ui/core';

import CategoriesAdd from './CategoriesAdd';
import CategoriesEdit from './CategoriesEdit';
import SuccessSnackbar from 'components/Help/SuccessSnackbar';
import checkPermission from "components/checkPermission";
import Loader from 'layouts/AdminLayout/components/Loader';
class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }

  }

  componentDidMount() {
    if(!this.props.loading) {
      if(this.props.categories.length === 0) {
        this.props.dispatch(fetchCategories());
      }
    }
  }

  permittedToAccess = (page) => {
    return checkPermission(this.props.currentOrganisation.currentOrganisationUser.role, page)
  }

  render() {
    const {classes} = this.props;
    return (
      <div>

        {
          this.props.loading ? (
            <Loader />
          ) : null
        }
        <SuccessSnackbar
          open={this.props.error !== null}
          error={this.props.error?.response ? this.props.error.response.statusText : null}
        />
        {!this.props.loading &&
          <>
            <Route path='/user/:uid/space/categories/' exact render={(routeProps) => <CategoriesIndex {...routeProps} {...this.props} permittedToAccess={this.permittedToAccess} />} />
            <Route path='/user/:uid/space/categories/:id(\d+)/edit' exact={true} component={CategoriesEdit} permittedToAccess={this.permittedToAccess} />
            <Route path='/user/:uid/space/categories/add' exact render={(routeProps) => <CategoriesAdd {...routeProps} {...this.props} permittedToAccess={this.permittedToAccess} />} />

          </>

        }
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    width: '100%',
    margin: 'auto'
  },
  progress: {
    borderRadius: "0px"
  },
})


const mapStateToProps = (state, ownProps) => ({
  categories: state.categoriesReducer.categories.filter(category => category.parent_id === null),
  loading: state.categoriesReducer.loading,
  error: state.categoriesReducer.error,
  currentOrganisation: state.organisationsReducer.currentOrganisation,
  ...ownProps,
});

export default connect(mapStateToProps)(withStyles(styles)(Categories));