import { identity } from "lodash";
import adminApi from "services/admin/adminApi";
/*----------------materials--------------------------*/
export const FETCH_SHIPPERS_BEGIN = "FETCH_SHIPPERS_BEGIN";
export const FETCH_SHIPPERS_SUCCESS = "FETCH_SHIPPERS_SUCCESS";
export const FETCH_SHIPPERS_FAILURE = "FETCH_SHIPPERS_FAILURE";

export const DELETE_SHIPPER_SERVICE_BEGIN = "DELETE_SHIPPER_SERVICE_BEGIN";
export const DELETE_SHIPPER_SERVICE_SUCCESS = "DELETE_SHIPPER_SERVICE_SUCCESS";
export const DELETE_SHIPPER_SERVICE_FAILURE = "DELETE_SHIPPER_SERVICE_FAILURE";

export const UPDATE_SHIPPER_SERVICE_BEGIN = "UPDATE_SHIPPER_SERVICE_BEGIN";
export const UPDATE_SHIPPER_SERVICE_SUCCESS = "UPDATE_SHIPPER_SERVICE_SUCCESS";
export const UPDATE_SHIPPER_SERVICE_FAILURE = "UPDATE_SHIPPER_SERVICE_FAILURE";

export const DELETE_SHIPPER_BEGIN = "DELETE_SHIPPER_BEGIN";
export const DELETE_SHIPPER_SUCCESS = "DELETE_SHIPPER_SUCCESS";
export const DELETE_SHIPPER_FAILURE = "DELETE_SHIPPER_FAILURE";

export const CREATE_SHIPPER_BEGIN = "CREATE_SHIPPER_BEGIN";
export const CREATE_SHIPPER_SUCCESS = "CREATE_SHIPPER_SUCCESS";
export const CREATE_SHIPPER_FAILURE = "CREATE_SHIPPER_FAILURE";

export const UPDATE_SHIPPER_BEGIN = "UPDATE_SHIPPER_BEGIN";
export const UPDATE_SHIPPER_SUCCESS = "UPDATE_SHIPPER_SUCCESS";
export const UPDATE_SHIPPER_FAILURE = "UPDATE_SHIPPER_FAILURE";

export const CREATE_SHIPPER_SERVICE_BEGIN = "CREATE_SHIPPER_SERVICE_BEGIN";
export const CREATE_SHIPPER_SERVICE_SUCCESS = "CREATE_SHIPPER_SERVICE_SUCCESS";
export const CREATE_SHIPPER_SERVICE_FAILURE = "CREATE_SHIPPER_SERVICE_FAILURE";

export const CLEAR_SHIPPERS = "CLEAR_SHIPPERS";
export const CLEAR_SHIPPERS_NOTIFICATION = "CLEAR_SHIPPERS_NOTIFICATION";

export const UPDATE_MANAGER_DETAIL_BEGIN = "UPDATE_MANAGER_DETAIL_BEGIN";
export const UPDATE_MANAGER_DETAIL_SUCCESS = "UPDATE_MANAGER_DETAIL_SUCCESS";
export const UPDATE_MANAGER_DETAIL_FAILURE = "UPDATE_MANAGER_DETAIL_FAILURE";

export const FETCH_MANIFESTS_BEGIN = "FETCH_MANIFESTS_BEGIN";
export const FETCH_MANIFESTS_SUCCESS = "FETCH_MANIFESTS_SUCCESS";
export const FETCH_MANIFESTS_FAILURE = "FETCH_MANIFESTS_FAILURE";

export const CLEAR_SHIPPER_STATE = "CLEAR_SHIPPER_STATE";

/*--------------------Setting - Shipping----------------------------*/
export function fetchShippers(params, wid, id) {
  return (dispatch) => {
    dispatch(fetchShippersBegin()); //calling this action to show loading
    return adminApi
      .fetchShippers(params, wid, id) // calling list of materials
      .then((json) => {
        dispatch(fetchShippersSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(fetchShippersFailure(error));
      });
  };
}

export function deleteShipperService(shipperId, id, wid) {
  return (dispatch) => {
    dispatch(deleteShipperServiceBegin()); //calling this action to show loading
    return adminApi
      .deleteShipperService(shipperId, id, wid)
      .then((json) => {
        dispatch(deleteShippersSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log(error);
        dispatch(deleteShippersFailure(error));
      });
  };
}

export function updateShipperService(wid, sid, data) {
  return (dispatch) => {
    dispatch(fetchShippersBegin()); //calling this action to show loading
    return adminApi
      .updateShipperService(wid, sid, data)
      .then(handleErrors)
      .then((json) => {
        dispatch(updateShipperServiceSuccess(json));
        dispatch(fetchShippers(null, wid, data.shipper_id));
        return json;
      })
      .catch((error) => {
        console.log(error);
        dispatch(updateShipperServiceFailure(error));
      });
  };
}

export function createShipperService(data, wid) {
  return (dispatch) => {
    return adminApi
      .createShipperService(data, data.shipper_id, wid)
      .then(handleErrors)
      .then((json) => {
        dispatch(createShipperServiceSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log(error);
        dispatch(createShipperServiceFailure(error));
      });
  };
}

export function updateShippers(data, id, wid) {
  return (dispatch) => {
    dispatch(updateShippersBegin());
    let res = adminApi
      .updateShipper(data, id, wid)
      .then((json) => {
        dispatch(updateShippersSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(updateShippersFailure(error));
      });
    return res;
  };
}

export const fetchShippersBegin = () => ({
  type: FETCH_SHIPPERS_BEGIN,
});

export const fetchShippersSuccess = (json) => ({
  type: FETCH_SHIPPERS_SUCCESS,
  data: json.data,
});
export const fetchShippersFailure = (error) => ({
  type: FETCH_SHIPPERS_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const deleteShipperServiceBegin = () => ({
  type: DELETE_SHIPPER_SERVICE_BEGIN,
});

export const deleteShippersSuccess = (json) => ({
  type: DELETE_SHIPPER_SERVICE_SUCCESS,
  data: json.data.shipper_service,
  msg: json.message,
});

export const deleteShippersFailure = (error) => ({
  type: DELETE_SHIPPER_SERVICE_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
  msg: "An error occurred, please try again later",
});

export const updateShipperServiceBegin = () => ({
  type: UPDATE_SHIPPER_SERVICE_BEGIN,
});
export const updateShipperServiceSuccess = (json) => ({
  type: UPDATE_SHIPPER_SERVICE_SUCCESS,
  data: json.data.shipper_service,
});

export const updateShipperServiceFailure = (error) => ({
  type: UPDATE_SHIPPER_SERVICE_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
  msg: "An error occurred, please try again later",
});

export const createShipperServiceBegin = () => ({
  type: CREATE_SHIPPER_SERVICE_BEGIN,
});
export const createShipperServiceSuccess = (json) => ({
  type: CREATE_SHIPPER_SERVICE_SUCCESS,
  data: json.data.shipper_service,
});

export const createShipperServiceFailure = (error) => ({
  type: CREATE_SHIPPER_SERVICE_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
  msg: "An error occurred, please try again later",
});

export function updateShippersBegin() {
  return {
    type: CREATE_SHIPPER_BEGIN,
  };
}

export function updateShippersSuccess(json) {
  return {
    type: UPDATE_SHIPPER_SUCCESS,
    data: json.data.shipper,
  };
}

export function updateShippersFailure(error) {
  return {
    type: UPDATE_SHIPPER_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}
export function deleteShipper(id, wid) {
  return (dispatch) => {
    dispatch(deleteShipperBegin());
    let res = adminApi
      .deleteShipper(id, wid)
      .then((json) => {
        dispatch(deleteShipperSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(deleteShipperFailure(error));
      });
    return res;
  };
}
export function deleteShipperBegin() {
  return {
    type: DELETE_SHIPPER_BEGIN,
  };
}

export const deleteShipperSuccess = (json) => ({
  type: DELETE_SHIPPER_SUCCESS,
  payload: {
    shipper: json.data.shipper,
    msg: json.data.message,
  },
});

export const deleteShipperFailure = (error) => ({
  type: DELETE_SHIPPER_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function createShipper(data, wid) {
  return (dispatch) => {
    dispatch(createShipperBegin());
    let res = adminApi
      .createShipper(data, wid)
      .then((json) => {
        dispatch(createShipperSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(createShipperFailure(error));
      });
    return res;
  };
}

export function createShipperBegin() {
  return {
    type: CREATE_SHIPPER_BEGIN,
  };
}

export const createShipperSuccess = (json) => ({
  type: CREATE_SHIPPER_SUCCESS,
  payload: { shipper: json.data.shipper },
});

export const createShipperFailure = (error) => ({
  type: CREATE_SHIPPER_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function clearShippers() {
  return {
    type: CLEAR_SHIPPERS,
  };
}

export function clearShippersDetailsState() {
  return {
    type: CLEAR_SHIPPER_STATE,
  };
}

export function clearShippersNotification() {
  return {
    type: CLEAR_SHIPPERS_NOTIFICATION,
  };
}

export function updateManagerDetails(values, id, wid) {
  return (dispatch) => {
    dispatch(updateManagerDetailsBegin());
    let res = adminApi
      .updateManagerDetails(values, id, wid)
      .then((json) => {
        dispatch(updateManagerDetailsSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(updateManagerDetailsFailure(error));
      });
    return res;
  };
}
export function updateManagerDetailsBegin() {
  return {
    type: UPDATE_MANAGER_DETAIL_BEGIN,
  };
}

export const updateManagerDetailsSuccess = (json) => ({
  type: UPDATE_MANAGER_DETAIL_SUCCESS,
  payload: {
    shipper: json.data.shipper,
    msg: json.data.message,
  },
});

export const updateManagerDetailsFailure = (error) => ({
  type: UPDATE_MANAGER_DETAIL_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const fetchEParcelManifestsPDF = (wid, sid, mid) => async (dispatch) => {
  try {
    const response = await adminApi.fetchEParcelManifestsPDF(wid, sid, mid);
    return {success: true, loading: false, error: null, data: response};
  } catch(e) {
    let error = 'An error occurred, please try again later';
    console.log(e);

    if(e.response) {
      error = e.response.data
    }
    
    return {success: false, loading: false, error: error};
  }
}

function handleErrors(response) {
  if (!response.success) {
    throw Error(response.statusText);
  }
  return response;
}

export function fetchManifests(wid, sid, params) {
  return (dispatch) => {
    dispatch(fetchManifestsBegin());
    return adminApi
      .fetchEParcelManifests(wid, sid, params)
      .then((json) => {
        dispatch(fetchManifestsSuccess(json, params));
        return json;
      })
      .catch((error) => {
        console.log("error", error);

        dispatch(fetchManifestsFailure(error));
      });
  };
}

export const fetchManifestsBegin = () => ({
  type: FETCH_MANIFESTS_BEGIN,
});

export const fetchManifestsSuccess = (json, params) => ({
  type: FETCH_MANIFESTS_SUCCESS,
  payload: {
    ...json.data,
    filters: params,
  }
});

export const fetchManifestsFailure = (error) => ({
  type: FETCH_MANIFESTS_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});