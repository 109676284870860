import adminApi from 'services/admin/adminApi';
/*----------------supplier--------------------------*/
export const FETCH_SUPPLIERS_BEGIN = 'FETCH_SUPPLIERS_BEGIN';
export const FETCH_SUPPLIERS_SUCCESS = 'FETCH_SUPPLIERS_SUCCESS';
export const FETCH_SUPPLIERS_FAILURE = 'FETCH_SUPPLIERS_FAILURE';

export const DELETE_SUPPLIER = 'DELETE_SUPPLIER'
export const DELETE_ORG_SUPPLIER_SUCCESS = 'DELETE_ORG_SUPPLIER_SUCCESS'
export const DELETE_ORG_SUPPLIER_FAILURE = 'DELETE_ORG_SUPPLIER_FAILURE'

export const GET_ORG_SUPPLIER_BY_ID_BEGIN = 'GET_ORG_SUPPLIER_BY_ID_BEGIN';
export const GET_ORG_SUPPLIER_BY_ID_SUCCESS = 'GET_ORG_SUPPLIER_BY_ID_SUCCESS';
export const GET_ORG_SUPPLIER_BY_ID_FAILURE = 'GET_ORG_SUPPLIER_BY_ID_FAILURE';

export const CREATE_ORG_SUPPLIER_BEGIN = 'CREATE_ORG_SUPPLIER_BEGIN';
export const CREATE_ORG_SUPPLIER_SUCCESS = 'CREATE_ORG_SUPPLIER_SUCCESS';
export const CREATE_ORG_SUPPLIER_FAILURE = 'CREATE_ORG_SUPPLIER_FAILURE';

export const UPDATE_SUPPLIERS_BEGIN = 'UPDATE_SUPPLIERS_BEGIN';
export const UPDATE_ORG_SUPPLIER_SUCCESS = 'UPDATE_ORG_SUPPLIER_SUCCESS';
export const UPDATE_ORG_SUPPLIER_FAILURE = 'UPDATE_ORG_SUPPLIER_FAILURE';

export const GET_ORG_SUPPLIER_BY_NAME_BEGIN = 'GET_ORG_SUPPLIER_BY_NAME_BEGIN';
export const GET_ORG_SUPPLIER_BY_NAME_SUCCESS = 'GET_ORG_SUPPLIER_BY_NAME_SUCCESS';
export const GET_ORG_SUPPLIER_BY_NAME_FAILURE = 'GET_ORG_SUPPLIER_BY_NAME_FAILURE';

export const CLEAR_SUPPLIERS = 'CLEAR_SUPPLIERS';
export const CLEAR_SUPPLIERS_NOTIFICATION = 'CLEAR_SUPPLIERS_NOTIFICATION';


/*--------------------Suppliers----------------------------*/
export function fetchSuppliers(params) {
    return dispatch => {
        dispatch(fetchSuppliersBegin());//calling this action to show loading
        return adminApi.fetchSuppliers(params)// calling list of materials
            .then(json => {

                dispatch(fetchSuppliersSuccess(json));
                return json;
            })
            .catch(error => {
                console.log('error', error);

                dispatch(fetchSuppliersFailure(error))

            });
    };
}

export function deleteSuppliers(id) {
    return dispatch => {
        return adminApi.deleteSuppliers(id)
            .then(json => {
                dispatch(deleteSuppliersSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(deleteSuppliersFailure(error))

            });
    }
}


export function fetchSuppliersByID(id) {
    return dispatch => {
        dispatch(fetchSuppliersBegin());//calling this action to show loading
        return adminApi.getSupplierDetail(id)
            .then(handleErrors)
            .then(json => {
                dispatch(fetchSupplierByIDSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(fetchSupplierByIDFailure(error))
            });
    }
}

export function fetchSuppliersByName(name) {
    return dispatch => {
        dispatch(fetchSupplierByNameBegin());//calling this action to show loading
        return adminApi.getSupplierDetailByName(name)
            .then(handleErrors)
            .then(json => {
                dispatch(fetchSupplierByNameSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)

                dispatch(fetchSupplierByNameFailure(error))
            });
    }
}

export function updateSuppliers(data, id) {
    return dispatch => {
        dispatch(updateSuppliersBegin());
        let res = adminApi.updateSupplier(data, id)
            .then(json => {
                dispatch(updateSuppliersSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(updateSuppliersFailure(error));

            })
        return res;
    }
}

export const fetchSuppliersBegin = () => (
    // the whole object is n action
    {
        type: FETCH_SUPPLIERS_BEGIN
    });

export const fetchSuppliersSuccess = json => ({
    type: FETCH_SUPPLIERS_SUCCESS,
    data: json.data
});
export const fetchSuppliersFailure = error => ({
    type: FETCH_SUPPLIERS_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});


export const deleteSuppliersSuccess = (json) => ({
    type: DELETE_ORG_SUPPLIER_SUCCESS,
    data: json.data.supplier,
    msg: json.message
})

export const deleteSuppliersFailure = (error) => ({
    type: DELETE_ORG_SUPPLIER_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'},
    msg: "An error occurred, please try again later"
});

export const fetchSupplierByIDBegin = () => ({
    type: GET_ORG_SUPPLIER_BY_ID_BEGIN
});
export const fetchSupplierByIDSuccess = json => ({
    type: GET_ORG_SUPPLIER_BY_ID_SUCCESS,
    data: json.data.supplier
});

export const fetchSupplierByIDFailure = error => ({
    type: GET_ORG_SUPPLIER_BY_ID_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'},
    msg: "An error occurred, please try again later"
});


export const fetchSupplierByNameBegin = () => ({
    type: GET_ORG_SUPPLIER_BY_NAME_BEGIN
});
export const fetchSupplierByNameSuccess = json => ({
    type: GET_ORG_SUPPLIER_BY_NAME_SUCCESS,
    data: json.results
});

export const fetchSupplierByNameFailure = error => ({
    type: GET_ORG_SUPPLIER_BY_NAME_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'},
    msg: "An error occurred, please try again later"
});


export function updateSuppliersBegin() {
    return {
        type: UPDATE_SUPPLIERS_BEGIN
    }
}

export function updateSuppliersSuccess(json) {
    return {
        type: UPDATE_ORG_SUPPLIER_SUCCESS,
        data: json.data.supplier

    }
}

export function updateSuppliersFailure(error) {
    return {
        type: UPDATE_ORG_SUPPLIER_FAILURE,
        payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
    }
}

export function createSupplier(data) {
    return dispatch => {
        dispatch(createSupplierBegin());
        let res = adminApi.createSupplier(data)
            .then(json => {
                dispatch(createSupplierSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(createSupplierFailure(error));
            })
        return res;
    }

}

export function createSupplierBegin() {
    return {
        type: CREATE_ORG_SUPPLIER_BEGIN,
    }
}


export const createSupplierSuccess = supplier => ({
    type: CREATE_ORG_SUPPLIER_SUCCESS,
    payload: {supplier}
});

export const createSupplierFailure = error => ({
    type: CREATE_ORG_SUPPLIER_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});

export function clearSuppliers() {
    return {
        type: CLEAR_SUPPLIERS
    }
}

export function clearSupplierNotification() {
    return {
         type: CLEAR_SUPPLIERS_NOTIFICATION
    }
}

// Private methods

function handleErrors(response) {
    if(!response.success && !response.results) {
        throw Error(response.statusText);
    }
    return response;
}
