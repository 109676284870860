import {
    FETCH_USERS_BEGIN,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAILURE,
  
    UPDATE_ORGANISATION_USER_BEGIN,
    UPDATE_ORGANISATION_USER_SUCCESS,
    UPDATE_ORGANISATION_USER_FAILURE,
  
    CREATE_ORGANISATION_USER_BEGIN,
    CREATE_ORGANISATION_USER_SUCCESS,
    CREATE_ORGANISATION_USER_FAILURE,
  
    DELETE_ORGANISATION_USER_BEGIN,
    DELETE_ORGANISATION_USER_SUCCESS,
    DELETE_ORGANISATION_USER_FAILURE,
  
    ACCEPT_INVITATION_BEGIN,
    ACCEPT_INVITATION_SUCCESS,
    ACCEPT_INVITATION_FAILURE,
  
    RESEND_INVITATION_BEGIN,
    RESEND_INVITATION_SUCCESS,
    RESEND_INVITATION_FAILURE,
  } from 'actions/organisationUserActions';
  
  
  const initialState = {
    users: [],
    invitationError: null,
    loading: false,
    error: null,
    invitationAccepted: false
  }
  
  export default function organisationUsersReducer(state = initialState, action) {
    switch(action.type) {
      case FETCH_USERS_BEGIN:
          return  {
              ...state,
              loading: true,
              error: null,
          };
          
      case FETCH_USERS_SUCCESS:
          return  {
              ...state,
              loading: false,
              users: action.payload.users
          };
          
      case FETCH_USERS_FAILURE:
          return  {
              ...state,
              loading: false,
              error: action.payload.error,
              users: []
          };
  
      case UPDATE_ORGANISATION_USER_BEGIN:
          return  {
              ...state,
              loading: true,
              error: null
          };
          
      case UPDATE_ORGANISATION_USER_SUCCESS:
          let updatedUsers = state.users.map(organisationUser => organisationUser.id === action.payload.organisationUser.id ?
              action.payload.organisationUser : organisationUser
            )
          return  {
              ...state,
              loading: false,
              users: updatedUsers
          };
          
      case UPDATE_ORGANISATION_USER_FAILURE:
          let errorObject = action.payload.error.response
          if (action.payload.error.response && action.payload.error.response.status === 422) {
              // Unprocessable Entity; It's likely that the response contains fields with errors
              errorObject = {
                  ...action.payload.error.response.data
              }
          }
          return  {
              ...state,
              loading: false,
              error: errorObject
          };
  
      case CREATE_ORGANISATION_USER_BEGIN:
          return {
              ...state,
              uploading: true,
              error: null
          };
  
      case CREATE_ORGANISATION_USER_SUCCESS:
          return {
              ...state,
              uploading: false,
              users: [action.payload.organisationUser, ...state.users ]
          };
  
      case CREATE_ORGANISATION_USER_FAILURE:
          let createErrorObject = action.payload.error.response
          if (action.payload.error.response && action.payload.error.response.status === 422) {
              // Unprocessable Entity; It's likely that the response contains fields with errors
              errorObject = {
                  ...action.payload.error.response.data
              }
          }
          return {
              ...state,
              uploading: false,
              error: createErrorObject,
          }; 
           
      case DELETE_ORGANISATION_USER_BEGIN:
          return {
              ...state,
              uploading: true,
              error: null
          };
  
      case DELETE_ORGANISATION_USER_SUCCESS:
          return {
              ...state,
              uploading: false,
              users: state.users.filter(organisationUsers => organisationUsers.id !== action.payload.id),
          };
  
      case DELETE_ORGANISATION_USER_FAILURE:
          return {
              ...state,
              uploading: false,
              error: action.payload.error.response,
          };
  
      case ACCEPT_INVITATION_BEGIN:
          return {
              ...state,
              uploading: true,
              invitationError: null,
              invitationAccepted: false
          };
  
      case ACCEPT_INVITATION_SUCCESS:
          return {
              ...state,
              uploading: false,
              invitationError: null,
              invitationAccepted: true
          };
  
      case ACCEPT_INVITATION_FAILURE:
          let invitationErrorObject = action.payload.error.response
          if (action.payload.error.response && action.payload.error.response.status === 422) {
              // Unprocessable Entity; It's likely that the response contains fields with errors
              invitationErrorObject = {
                  ...action.payload.error.response.data
              }
          }
  
          return {
              ...state,
              uploading: false,
              invitationError: invitationErrorObject,
              invitationAccepted: false
          };      
          
      case RESEND_INVITATION_BEGIN:
          return {
              ...state,
              uploading: true,
              error: null,
          };
  
      case RESEND_INVITATION_SUCCESS:
          return {
              ...state,
              uploading: false,
              error: null
          };
          
      case RESEND_INVITATION_FAILURE:
          let resendErrorObject = action.payload.error.response
          if (action.payload.error.response && action.payload.error.response.status === 422) {
              // Unprocessable Entity; It's likely that the response contains fields with errors
              resendErrorObject = {
                  ...action.payload.error.response.data
              }
          }
  
          return {
              ...state,
              uploading: false,
              error: resendErrorObject,
          };  
  
      default:
          return state
    }
  }
  