import { clearCurrentUser } from 'actions/userActions';
import * as Storage from "../utils/storage";
import store from 'index';

export const apiErrorHandler = (response) => {
	// If the response status code is 401 (Unauthorized) or 403 (Forbidden)
  if (response.status === 401 || response.status === 403) {
    // Get the current URL path
    const path = window.location.pathname;
    // If the path includes '/admin' and does not include 'auth'
    if (path.includes('/admin') && !path.includes('auth')) {
      // Remove the '_ADMIN' item from storage
      Storage.removeItem('_ADMIN');
      // Redirect the user to the admin login page
      window.location.href = '/admin/auth/login';
    } else if (!path.includes('auth')) {
      // If the path does not include 'auth'
      // Clear the current user data using the Redux action 'clearCurrentUser'
      store.dispatch(clearCurrentUser());
      // Get the '_ADMIN' item from storage
      const ADMIN = Storage.getItem('_ADMIN');
      // Clear all storage items
      Storage.clear();
      // If there was an '_ADMIN' item, set it back to storage
      if (ADMIN) Storage.setItem('_ADMIN', ADMIN);
      // Redirect the user to the general login page
      window.location.href = '/auth/login';
    }
  }
};
