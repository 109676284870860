import rules from "../rbac-rules";

const checkPermission = (role, action, data) => {
  if (!Boolean(role)) return false
  if (role.toLowerCase() === "owner") return true

  const permissions = rules[role.toLowerCase()];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

export default checkPermission;