import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
  },
  content: {
    padding: '0px 39px 40px'
  },
  textSecondary: {
    color: theme.palette.text.secondary
  }
}));

const ReportsCard = props => {
  const {title, margin, report} = props;

  const classes = useStyles();

  return (
    <Card
      className={classes.root}
      style={margin ? {marginTop: 16} : null}
    >
      <CardHeader title={title} />
      <Divider />
      <CardContent className={classes.content}>
        {report.length && <Table>

          <TableBody>
            <>
              {report && report.map(row =>
                <TableRow key={row.name}>
                  <TableCell>{row.title} <span className={classes.textSecondary}>{row.description}</span> </TableCell>

                  <TableCell></TableCell>
                  <TableCell align='right'>{row.value}</TableCell>
                      
                </TableRow>
              )}
            </>
          </TableBody>
          </Table>
        }
      </CardContent>
    </Card>
  );
};

ReportsCard.propTypes = {
  className: PropTypes.string
};

export default ReportsCard;
