import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 35,
  },
}));

const Header = React.memo((props) => {
  const { className, title, subtitle, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid
        alignItems="flex-end"
        container
        justify="space-between"
        spacing={1}
        className={classes.title}
      >
        <Grid item>
          {title && (
            <Typography
              component="h2"
              gutterBottom
              paragraph
              variant="overline"
            >
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography component="h1" variant="h3">
              {subtitle}
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
});

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
