import {
  FETCH_SHIPPERS_BEGIN,
  FETCH_SHIPPERS_SUCCESS,
  FETCH_SHIPPERS_FAILURE,
  DELETE_SHIPPER_BEGIN,
  DELETE_SHIPPER_SUCCESS,
  DELETE_SHIPPER_FAILURE,
  UPDATE_SHIPPER_SERVICE_BEGIN,
  UPDATE_SHIPPER_SERVICE_SUCCESS,
  UPDATE_SHIPPER_SERVICE_FAILURE,
  UPDATE_SHIPPER_SUCCESS,
  UPDATE_SHIPPER_FAILURE,
  DELETE_SHIPPER_SERVICE_BEGIN,
  DELETE_SHIPPER_SERVICE_SUCCESS,
  DELETE_SHIPPER_SERVICE_FAILURE,
  CLEAR_SHIPPERS,
  UPDATE_SHIPPER_BEGIN,
  CREATE_SHIPPER_SERVICE_BEGIN,
  CREATE_SHIPPER_SERVICE_FAILURE,
  CREATE_SHIPPER_SERVICE_SUCCESS,
  CREATE_SHIPPER_BEGIN,
  CREATE_SHIPPER_SUCCESS,
  CREATE_SHIPPER_FAILURE,
  CLEAR_SHIPPERS_NOTIFICATION,
  UPDATE_MANAGER_DETAIL_BEGIN,
  UPDATE_MANAGER_DETAIL_SUCCESS,
  UPDATE_MANAGER_DETAIL_FAILURE,
  FETCH_MANIFESTS_BEGIN,
  FETCH_MANIFESTS_SUCCESS,
  FETCH_MANIFESTS_FAILURE,
  CLEAR_SHIPPER_STATE,
} from "actions/admin/shippersActions";

const initialState = {
  shippers: [],
  loading: false,
  error: null,
  notification: null,
  pagination: {},
  shipperDetail: {},
  formSuccess: false,
  searchResult: [],
  filters: [],
  eparcel_manifests: [],
  currentShipper : {},
  currentShipperTabs :[]
};

export default function orgSuppliers(state = initialState, action) {
  switch (action.type) {
    case FETCH_SHIPPERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        notification: null,
      };
    case FETCH_SHIPPERS_SUCCESS:
      return {
        ...state,
        loading: false,
        shippers: action.data,
        currentShipper: action.data?.shipper,
        currentShipperTabs : action.data?.tab_names
      };
    case FETCH_SHIPPERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        shippers: [],
      };

    case DELETE_SHIPPER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        formSuccess: false,
        notification: null,
      };
    case DELETE_SHIPPER_SUCCESS: {
      return {
        ...state,
        loading: false,
        formSuccess: true,
        notification: action.payload.msg,
      };
    }
    case DELETE_SHIPPER_FAILURE:
      return {
        ...state,
        loading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    case UPDATE_SHIPPER_SERVICE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        notification: null,
      };

    case UPDATE_SHIPPER_SERVICE_SUCCESS: {
      let updatedShipper = state.shippers.shipper_services.map((item) => {
        return item.id === action.data.id
          ? {
              ...item,
              ...action.data,
            }
          : item;
      });

      return {
        ...state,
        loading: false,
        shippers: {
          ...state.shippers,
          shipper_services: updatedShipper,
        },
        notification: "Type successfully updated",
      };
    }

    case UPDATE_SHIPPER_SERVICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        shippers: state.shippers,
      };

    case CREATE_SHIPPER_SERVICE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        notification: null,
      };

    case CREATE_SHIPPER_SERVICE_SUCCESS:
      let updatedShippers = state.shippers?.map((item) =>
        item.id === action.data.shipper_id
          ? {
              ...item,
              shipper_services: [...item.shipper_services, action.data],
            }
          : item
      );
      return {
        ...state,
        loading: false,
        shippers: updatedShippers,
        error: null,
        notification: "Type successfully added",
      };

    case CREATE_SHIPPER_SERVICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_SHIPPER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        formSuccess: false,
        notification: null,
      };
    case UPDATE_SHIPPER_SUCCESS:
      return {
        ...state,
        loading: false,
        formSuccess: true,
        shipperDetail: action.data,
        error: null,
        notification: null,
      };
    case UPDATE_SHIPPER_FAILURE:
      return {
        ...state,
        loading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    case CREATE_SHIPPER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        formSuccess: false,
        notification: null,
      };
    case CREATE_SHIPPER_SUCCESS:
      return {
        ...state,
        loading: false,
        formSuccess: true,
        error: null,
        notification: null,
        shippers: [action.payload.shipper, ...state.shippers],
      };
    case CREATE_SHIPPER_FAILURE:
      return {
        ...state,
        loading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    case DELETE_SHIPPER_SERVICE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        formSuccess: false,
        notification: null,
      };
    case DELETE_SHIPPER_SERVICE_SUCCESS:
      let updatedShipper = state.shippers.map((item) =>
        item.id === action.data.shipper_id
          ? {
              ...item,
              shipper_services: item.shipper_services.filter(
                (ele) => ele.id !== action.data.id
              ),
            }
          : item
      );
      return {
        ...state,
        formSuccess: true,
        loading: false,
        shippers: updatedShipper,
        error: action.msg,
      };
    case DELETE_SHIPPER_SERVICE_FAILURE:
      return {
        ...state,
        loading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    case CLEAR_SHIPPERS:
      return {
        ...initialState,
      };
    case CLEAR_SHIPPERS_NOTIFICATION:
      return {
        ...state,
        error: null,
        notification: null,
      };
    case UPDATE_MANAGER_DETAIL_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        formSuccess: false,
        notification: null,
      };

    case UPDATE_MANAGER_DETAIL_SUCCESS:
      return {
        loading: false,
        formSuccess: true,
        notification: "Manager details updated.",
      };
    case UPDATE_MANAGER_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    case FETCH_MANIFESTS_BEGIN:
      return {
        ...state, //imp line
        loading: true,
        error: null,
        notification: null,
      };
    case FETCH_MANIFESTS_SUCCESS:
      return {
        ...state,
        success: true, 
        error: null, 
        loading: false,
        eparcel_manifests: action.payload.eparcel_manifests,
        currentShipper: action.payload?.shipper,
        currentShipperTabs : action.payload?.tab_names,
        filters: action.payload.filters,
        tabs: action.payload.tab_names,
        pagination: action.payload.pagination,
      };
    case FETCH_MANIFESTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        eparcel_manifests: [],
        pagination: {},
        filters: {},
        tabs: [],
      };

      case CLEAR_SHIPPER_STATE:
        return {
          ...state,
          currentShipper: {},
          currentShipperTabs :[],
          filters: [],
          eparcel_manifests: [],
        };
        
    default:
      return state;
  }
}
