import React, { Component } from 'react'
import { withStyles } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const styles = theme => ({
    dropzone: {
        height: '200px',
        width: '200px',
        backgroundColor: '#fff',
        border: '2px dashed rgb(187,186,186)',
        borderRadius: '4%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        fontSize: '16px',
        margin: 'auto',
        marginTop: 0,
    },
    dropzoneHighlight: {
        height: '200px',
        width: '200px',
        backgroundColor: 'rgb(188,185,236)',
        border: '2px dashed rgb(187,186,186)',
        borderRadius: '4%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        fontSize: '16px',
        margin: 'auto',
        marginTop: 0,
    },
    icon: {
        opacity: 0.3,
        height: '64px',
        width: '64px',
    },
    imagePreview: {
        height: '164px',
        width: '164px',
        opacity: 0.7,
        borderRadius: '4%'
    },
    fileInput: {
        display: 'none',
    }
})

class Dropzone extends Component {
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
        this.state = {
            highlight: false
        };
        this.openFileDialog = this.openFileDialog.bind(this)
        this.onFilesAdded = this.onFilesAdded.bind(this)
        this.errImgName = this.errImgName.bind(this);

        this.onDragOver = this.onDragOver.bind(this)
        this.onDragLeave = this.onDragLeave.bind(this)
        this.onDrop = this.onDrop.bind(this)
    }

    openFileDialog() {
        if (this.props.disabled) return;
        this.fileInputRef.current.click();
    }

    errImgName(name) {
        this.props.errImgName(name)
    }
    checkValidFile(file) {
        if (file) {
            return file.name.match(/.(jpg|jpeg|png)$/i);
        }
        else {
            return false;
        }
    }
    onFilesAdded(evt) {
        if (this.props.disabled) return;
        const files = evt.target.files;

        const isFileValid = [...files].some(this.checkValidFile);

        if (!isFileValid && files.length) {
            this.errImgName(files[0].name);
            return
        }
        else {
            this.errImgName("")
        }

        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files);
            this.props.onFilesAdded(array);
        }
    }


    onDragOver(evt) {
        evt.preventDefault();

        if (this.props.disabled) return;

        this.setState({ highlight: true });
    }

    onDragLeave() {
        this.setState({ highlight: false })
    }

    onDrop(evt) {
        evt.preventDefault();

        if (this.props.disabled) return;

        const files = evt.dataTransfer.files;

        const isFileValid = [...files].some(this.checkValidFile);

        if (!isFileValid && files.length) {
            this.errImgName(files[0].name);
            return
        }
        else {
            this.errImgName("");
        }

        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files)
            this.props.onFilesAdded(array);
        }
        this.setState({ highlight: false })
    }

    fileListToArray(list) {
        const array = [];
        for (var i = 0; i < list.length; i++) {
            array.push(list.item(i))
        }
        return array;
    }

    render() {
        const { classes } = this.props;
        return (
            <div
                className={this.state.highlight ? classes.dropzoneHighlight : classes.dropzone}
                id='dropzone'
                onClick={this.openFileDialog}
                onDragOver={this.onDragOver}
                onDragLeave={this.onDragLeave}
                onDrop={this.onDrop}
                style={{
                    cursor: this.props.disabled ? 'default' : 'pointer'
                }}
            >
                {!this.props.preview ?

                    <>{!this.props.icon ? <CloudUploadIcon className={classes.icon} /> : this.props.icon}</>

                    : <img className={classes.imagePreview} src={this.props.preview} alt="Drop images here" title="Click to change the logo"/>}
                {!this.props.icon && <span>Upload Image</span>}
                <input
                    ref={this.fileInputRef}
                    className={classes.fileInput}
                    id='dropzone-input'
                    type="file"
                    accept={this.props.accept}
                    multiple
                    onChange={this.onFilesAdded}
                />
            </div>
        )
    }
}

export default withStyles(styles)(Dropzone);
