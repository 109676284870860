import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Autosuggest from 'react-autosuggest';

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: 24,
    marginRight:'40px'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.icon
  },
  searchInput: {
    flexGrow: 1
  },
  container: {
    position: 'relative',
    flexGrow: 1,
    backgroundColor: '#fff',
    border: '1px solid #eaeaea',
  },
  input: {
    width: '90%',
    height: 30,
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 300,
    fontSize: 16,
    border: 'none',
    padding: '5px 20px'
  },
  inputFocused: {
    outline: 'none'
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  suggestionsContainer: {
    display: 'none'
  },
  suggestionsContainerOpen: {
    border: '1px solid #eaeaea',
    display: 'block',
    position: 'absolute',
    width: '100%',
    backgroundColor: '#fff',
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 100,
    fontSize: 14,
    zIndex: 2
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  suggestion: {
    cursor: 'pointer',
    padding: '10px 20px'
  },
  suggestionHighlighted: {
    backgroundColor: '#ddd'
  },
  column: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const getSuggestions = (value, suggestions, autosuggestFields) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  let count = 0
  let matchArray = autosuggestFields.match

  return inputLength === 0 ? [] :
    suggestions.filter(suggestion => {
        const keep =
          count < 100 && (matchArray.some(field => suggestion[field] ? suggestion[field].slice(0, inputLength).toLowerCase() === inputValue : false));

        if (keep) {
          count += 1;
        }
        return keep;
      });
};


const getSuggestionValue = (suggestion, autosuggestFields) => suggestion[autosuggestFields.value];

const renderSuggestion = (suggestion, autosuggestFields) => {
  const title = autosuggestFields.display.title
  const subtitles = autosuggestFields.display.subtitle

  let row2 = ''
  subtitles.map((subtitle, i) => row2 += `${subtitle.label} ${suggestion[subtitle.value] || '-'}  ${i < subtitles.length-1 ? ' / ' : ''}`)

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <Typography variant='h5'>{`${title.label || ""} ${suggestion[title.value] || ""} `}</Typography>
      <Typography variant='h6'>{row2}</Typography>
    </div>
  )
};


const SearchBar = props => {
  const { suggestionsList, handleSelect, autosuggestFields } = props;

  const classes = useStyles();
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const onSuggestionSelected = (e, { suggestion}) => {
      e.preventDefault();
      handleSelect(suggestion);
  }

  const onChange = (event, { newValue }) => {
    setValue(newValue)
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    let newValue = getSuggestions(value, suggestionsList, autosuggestFields)
    setSuggestions(newValue)
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  };

  const inputProps = {
    placeholder: autosuggestFields.placeholder,
    value,
    onChange: onChange
  };

  return (

        <div className={classes.searchBar}>

              <SearchIcon className={classes.searchIcon} />
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={suggestion=>getSuggestionValue(suggestion, autosuggestFields)}
                renderSuggestion={suggestion=>renderSuggestion(suggestion, autosuggestFields)}
                inputProps={inputProps}
                theme={classes}
                onSuggestionSelected={onSuggestionSelected}
              />

        </div>
  );
};


export default SearchBar;
