import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Tooltip,
  CircularProgress
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment'
import PrintIcon from "@material-ui/icons/Print";

const useStyles = makeStyles(theme => ({
  root: {
  },
  content: {
    padding: '0px 39px 40px'
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  footer: {
    justifyContent: 'center'
  },
  itemConatiner: {
    display: 'flex',
  },
  item: {
    padding: 40,
    width: '50%',
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${theme.palette.divider}`
    }
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  },
  wordBreak: {
    wordBreak: "break-all"
  },
  superText: {
    verticalAlign: "super",
    fontSize: "10px"
  },
  lowerCase: {
    textTransform: "initial"
  },
  splitButton: {
    border: "1px solid #45b5f2;",
    color: "#45b5f2;"
  },
  themeColorText: {
    color: "#45b5f2;",
  }
}));

const OrderCard = props => {
  const { headRows, title, order, rows, handleEditOpen, edit, margin, tracking_button, printLabelAllowed, getLabel, loadingLabel } = props;

  const classes = useStyles();

  let editAction
  if (edit) {
    editAction =
      <CardActions className={classes.actions}>
        <Button onClick={handleEditOpen}>
          <EditIcon className={classes.buttonIcon} />
          Edit
        </Button>
      </CardActions>
  }

  let button
  if (order.booking_type == "outbound" && tracking_button)
    button =
      <CardActions className={classes.footer}>
        <Button color='primary' variant='contained' >
          <a target="_blank" rel="noopener noreferrer" href={order.tracking_id} className={classes.link}>Track order</a>
        </Button>
      </CardActions>

  return (
    <Card
      className={classes.root}
      style={margin ? { marginTop: 16 } : null}
    >
      <CardHeader
        title={title}
        action={printLabelAllowed &&
          <Tooltip title="Print Label">
            {!loadingLabel ?
              <Button
                className={classes.splitButton}
                onClick={() => getLabel()}
              >
                <PrintIcon className={classes.themeColorText} />
              </Button> : <CircularProgress />
            }
          </Tooltip>}
      />
      <Divider />
      <CardContent className={classes.content}>
        <div className={classes.itemConatiner}>
          {headRows.map(row =>
            <div align='center' key={row.name} className={classes.item}>
              <Typography variant="overline">
                {row.label}
              </Typography>
              <Typography variant='h4'>
                {order[row.name] instanceof Date ? moment(order[row.name]).format('ddd, DD/MM/YYYY') : (order[row.name] || "-")}
              </Typography>
              {(row.name == "created_at" && <Typography variant="overline">
                {moment(order[row.name]).format('HH:mm')}
              </Typography>)}

            </div>
          )}
        </div>
        {headRows.length > 0 ? <Divider /> : null}
        <Table>

          <TableBody>
            {rows.map(row =>
              <TableRow key={row.name}>
                <TableCell>{row.label}</TableCell>
                <TableCell align='right' className={classes.wordBreak}>

                  {
                    (!row.children) ? <>{row.prefix || ''} {order[row.name] || '-'}
                      <Typography className={classes.lowerCase} variant='captionText'> {row.suffix || ''}</Typography>
                      <Typography className={classes.superText} variant='captionText'>{row.supText || ''}</Typography></>
                      :
                      order[row.name].map(orderChild =>
                        <Typography>
                          {
                            row.children.map(rowChild =>
                              ` ${rowChild.label || ''}:${orderChild[rowChild.name] || '-'}`
                            )
                          }
                        </Typography>
                      )
                  }

                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>

      {editAction}
      {button}
    </Card>
  );
};

OrderCard.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object.isRequired
};

export default OrderCard;
