import {reverseUserRoute} from 'routes';

export const orderTypes = {
  'inbound': {
    type: "INBOUND",
    string: 'In'
  },
  'outbound': {
    type: "OUTBOUND",
    string: 'Out'
  }
}

export const orderStatusStrings = [
  {
    value: 'info_received',
    string: 'Info Received'
  },
  {
    value: 'pick_and_packing',
    string: 'Pick & Pack'
  },
  {
    value: 'Ready_to_Ship',
    string: 'Ready to Ship'
  },
]

export const orderStatuses = {
  'info_received': {
    value: "info_received",
    string: "Info Received",
    color: "#244a76",
    percentage: 10,
    isChecked: true,
  },
  'pick_and_packing': {
    value: "pick_and_packing",
    string: "Pick & Pack",
    color: "#00abe9",
    percentage: 30,
    isChecked: true,
  },
  'picked': {
    value: "picked", // We want both picked and pick_and_packing to show up as the same thing to the user
    string: "Pick & Pack",
    color: "#00abe9",
    percentage: 30,
    isChecked: true,
  },
  'Ready_to_Ship': {
    value: "Ready_to_Ship",
    string: "Shipping",
    color: "#f2a255",
    percentage: 70,
    isChecked: true,
  },
  'processed': {
    value: "processed",
    string: "Processed",
    color: "#0a904c",
    percentage: 100,
    isChecked: true,
  },
  'canceled': {
    value: "canceled",
    string: "Cancelled",
    color: "rgba(255, 0, 0, 0.56)",
    percentage: 100,
    isChecked: true,
  },
  'amending': {
    value: "amending",
    string: "Amending",
    color: "#b68ac6",
    percentage: 50,
    isChecked: true,
  },
  'no_stock': {
    value: "no_stock",
    string: "No Stock",
    color: "#dc3832",
    percentage: 30,
    isChecked: true,
  },
  'stocking': {
    value: "stocking",
    string: "Stocking",
    color: "#0071b6",
    percentage: 30,
    isChecked: true,
  },
  'awaiting_arrival': {
    value: "awaiting_arrival",
    string: "Awaiting Arrival",
    color: "#3e84ed",
    percentage: 5,
    isChecked: true,
  },
  'arrived': {
    value: "arrived",
    string: "Arrived",
    color: "#3e84ed",
    percentage: 20,
    isChecked: true,
  },
  'unconfirmed': {
    value: "unconfirmed",
    string: "Unconfirmed",
    color: "#cccccc",
    percentage: 0,
    isChecked: true,
  },
  'packed': {
    value: "packed",
    string: "Packed",
    color: "#3F8CB5",
    percentage: 75,
    isChecked: true,
  },
  'shipped': {
    value: "shipped",
    string: "Shipped",
    color: "#88c82b",
    percentage: 95,
    isChecked: true,
  },
}

// replace apracel and mypost with Australia Post
export const shippingCarriers = {
  'eparcel': 'Australia Post',
  'my_post_business': 'Australia Post',
}

export const orderSchema = {
  id: 0,
  createdAt: Date(),
  updatedAt: Date(),
  processedOn: Date(),
  bookingType: '',
  address: '',
  contactName: '',
  contactPhone: '',
  status: '',
  trackingId: 0,
  email: '',
  reference: '',
  address2: '',
  suburb: '',
  state: '',
  postcode: 0,
  country: '',
  deliveryInstructions: '',
  shippingMethod: '',
  shippingCarrier: '',
  skus: [
    {
      id: 0,
      sku: '',
      title: '',
      description: '',
      image: '',
      quantity_available: 0,
    }
  ],
  bookingItems: [
    {
      id: 0,
      quantityOrdered: 0,
      sku: {
        id: 0,
        sku: '',
        title: '',
        description: '',
        image: '',
        quantity_available: 0,
      }
    }
  ],
  comments: [

  ],
  url: '',
  status_obj: {
    string: '',
    color: '#000000',
    percentage: 0,
  },
  order_type: {
    type: "OUTBOUND",
    string: 'Outbound'
  }
}



export function applyOrderInterface(order) {
  return {
    ...order,
    status_obj: orderStatuses[order.status],
    order_type: orderTypes[order.booking_type],
    created_at: new Date(order.created_at),
    updated_at: new Date(order.updated_at),
    processed_on: order.processed_on ? new Date(order.processed_on) : null,
    eta: order.eta ? new Date(order.eta) : null,
    shipping_carrier_og: order.shipper_id,
    shipper_id: shippingCarriers[order.shipper_id] || order.shipper_id
  }
}

export function applyCustomerInterface(customer) {
  return {
    ...customer,
    // permalink: "/user/customers/" + customer.id
  }
}

export function applySKUInterface(sku) {
  return {
    ...sku,
    permalink: `${reverseUserRoute('Space')}/${sku.id}`
    // Add additional fields or transformations here
  }
}

export function applyCategoryInterface(category) {
  return {
    ...category,
  }
}

export function applySupplierInterface(supplier) {
  return {
    ...supplier,
  }
}

export function applyDateInterface(date) {
  let dateObject = new Date(date)
  return `${dateObject.toLocaleTimeString()} , ${dateObject.toDateString()}`
}

//orders work on country name only not following uid as value, so this is to to transform the countries list such that text: country_name and value also same as text(country_name)
export const applyOrdersCountryListInterface = (list) => {
  if (!!!list || !!!list.length) {
    return [];
  }
  const updatedList = list?.map((country) => ({
    ...country,
    value: country.text,
  }));

  return updatedList;
};