import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setSearchTerm } from 'actions/spaceActions';

import {
      AppBar,
      Dialog,
      IconButton,
      List,
      ListItem,
      ListItemText,
      Slide,
      Toolbar,
      Typography,
      Paper,
      makeStyles
  } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Dots } from 'components/Loading';
import Search from 'components/Search/Search';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});


const SKUDialog = (props) => {
    const { open, setOpen, addBundleComponent } = props;
    const classes = useStyles();

    const allItems = useSelector(state => state.spaceReducer.items);
    const unarchivedItems = allItems.filter(item => !item.archived)

    const searchTerm = useSelector(state => state.spaceReducer.searchTerm);

    const loadingItems = useSelector(state => state.spaceReducer.loading);
    const dispatch = useDispatch();

    const handleSkuClose = () => {
        setOpen(false);
    }

    const addSKU = id => {
        handleSkuClose();
        addBundleComponent(id);
        dispatch(setSearchTerm(''));
    }

    const search = (searchTerm, stringToProcess) => {
        let stp = stringToProcess.toLowerCase()
        let st = searchTerm.toLowerCase()
        return stp.includes(st)
      }

    const applyTermSearch = (items, searchTerm) => {
      let searchedItems = items
      if (searchTerm && searchTerm.length > 0) {
          searchedItems = items.filter(item => (
            search(searchTerm, item.sku) ||
            (searchTerm === item.id.toString()) ||
            search(searchTerm, item.title)
          ))
      }
      return searchedItems;
    }

    const handleSearchInput = e => {
      dispatch(setSearchTerm(e.target.value));
    }

    return (
        <Dialog fullScreen open={open} onClose={handleSkuClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleSkuClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" style={{ color: "inherit"}} className={classes.title}>
                        Add SKU to bundle
                    </Typography>
                </Toolbar>
            </AppBar>

            <div className={classes.root}
                style={{
                    paddingTop: 90 // Appbar height
                }}
            >

                <Search
                    className={classes.search}
                    searchTerm={searchTerm}
                    onChange={handleSearchInput}
                />

                <Paper className={classes.paper}>
                    <List>
                        {applyTermSearch(unarchivedItems, searchTerm).map(item => (
                            <ListItem button divider key={item.id} onClick={() => { addSKU(item.id) }}>
                                <ListItemText primary={item.title} secondary={`${item.sku} - ${item.quantity_available} in stock`} />
                            </ListItem>
                        ))}

                        {loadingItems ? (
                            <Dots />
                        ) : (null)}
                    </List>
                </Paper>

            </div>

        </Dialog>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        backgroundColor: theme.palette.background.default,
        minHeight: '100vh',
    },
    paper: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(3),
    },
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    search: {
        marginBottom: theme.spacing(3),
    },
}))

export default SKUDialog
