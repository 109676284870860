import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  fade,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 7,
    fontFamily: theme.typography.fontFamily,
    alignItems: 'center',
    borderRadius: 5,
    display: 'inline-flex',
    flexGrow: 0,
    whiteSpace: 'nowrap',
    border: '2px solid',
    borderStyle: 'solid',
    width: 135,
    cursor: 'default',
    flexShrink: 0,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    //height: 24,
    justifyContent: 'center',
    letterSpacing: "0.08em",
    minWidth: 20,
    padding: "5px 10px",
    textTransform: 'uppercase',
    [theme.breakpoints.down("xs", "sm")]: {
      padding: "5px 2px",
      width: "auto",
      border: "none",
      fontSize: theme.typography.pxToRem(10)
    },
  },

  due: {
    color: "#CC9F48"
  },
  not_paid: {
    color: "#CC9F48 ",

  },
  paid: {
    color: "#3FB5AC ",

  },
  draft: {
    color: "#DDDDDD ",

  },
  part_paid: {
    color: "#5D8FD3 ",

  },
  void: {
    color: "#AD0707 ",

  },
  cancel: {
    color: "#F23D1C ",

  },
}));
// "to_be_picked", "picking", "picked", "to_be_packed", "packing", "packed", "to_be_shipped", "completed", "cancelled"
function StatusCell({
  className,
  children,
  style,
  status,
  ...rest
}) {
  const classes = useStyles();

  return (
    <span
      className={
        clsx(classes.root, classes[status], className)
      }
      {...rest}
    >
      {children ? children.replace(/_/g, ' ') : null}
    </span>
  );
}

StatusCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object
};

StatusCell.defaultProps = {
  className: '',
  color: ''
};

export default StatusCell;



