import React from 'react'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%'
    },
  }));

const Row = props => {   

    const classes = useStyles();
    const { className, children } = props;


    return (
        <div className={clsx(classes.root, className)}>
            {children}        
        </div>
    )
}

Row.propTypes = {

}

export default Row

