import React, { useState, useEffect } from 'react';
import { withStyles, Typography, Tabs, Tab } from '@material-ui/core';
import { connect } from 'react-redux';
import General from './Components/General/General';
import Subscription from './Components/Subscription/Subscription';
import Users from './Components/Users/Users';
import Shipping from './Components/Shipping/Shipping';
import SuccessSnackbar from 'components/Help/SuccessSnackbar';
import { useDispatch } from 'react-redux';
import { updateOrganisation } from 'actions/organisationActions';
import Can from "components/Can";

function Organisation(props) {
  const { classes, currentOrganisation, error, organisationUsersError, currentOrganisationUser } = props;
  const [tabValue, setTabValue] = useState(0);
  const [values, setValues] = useState({});
  const dispatch = useDispatch();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    setValues({
      name: currentOrganisation.name,
      preferred_company_name: currentOrganisation.preferred_company_name,
      abn: currentOrganisation.abn,
      web_url: currentOrganisation.web_url,
      address_line_1: currentOrganisation.address_line_1,
      address_line_2: currentOrganisation.address_line_2,
      city: currentOrganisation.city,
      postcode: currentOrganisation.postcode,
      state: currentOrganisation.state,
      country: currentOrganisation.country,
      email: currentOrganisation.email,
      phone: currentOrganisation.phone,
      logo: currentOrganisation.logo || [],
      preview: currentOrganisation.logo ? currentOrganisation.logo.url : null,
      // loading: true
    });
  }, [currentOrganisation]);
  useEffect(() => {
    if (error && error.statusText) setOpenSnackbar(true)
  }, [error])

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const onImageUpload = files => {
    let reader = new FileReader();
    let file = files[0]
    reader.onloadend = () => (
      setValues({
        ...values,
        logo: files,
        preview: reader.result
      })
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  }


  const handleChange = event => {
    event.persist();

    setValues({
      ...values,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    });
  };

  const handleSubmitUpdate = event => {

    event.preventDefault();
    var formData = new FormData();

    Object.keys(values).forEach(e => {
      if (e === 'logo' || e === 'preview') return;
      formData.append(e, values[e]);
    });

    if (values.logo[0]) {
      formData.append('logo', values.logo[0], values.logo[0].name)
    }

    dispatch(updateOrganisation(currentOrganisation.id, formData))
      .then((result) => {
        if (result) {
          // result is null when in error
          setOpenSnackbar(true);
        }
      });
  };


  return (
    <Can
      role={currentOrganisation.currentOrganisationUser.role}
      perform="organisations:view"
      yes={() => (
        <div className={classes.root}>
          <SuccessSnackbar
            onClose={handleSnackbarClose}
            open={openSnackbar}
            error={(error && error.statusText) ? error.statusText : null}
            messageText="Successfully saved"
          />
          <Typography variant="h2" style={{ fontSize: '11px', marginBottom: '8px' }}>SETTINGS</Typography>
          <Typography variant="h1" style={{ fontSize: '24px' }}>Manage your Organisation</Typography>

          <div className={classes.tabsPanel}>
            <Tabs className={classes.tabs} classes={{ indicator: classes.tabsIndicator }} value={tabValue} onChange={handleTabChange}>
              <Tab label="General" />
              <Tab label="Users" />
              <Tab label="Price & Subscriptions" />
              <Tab label="Shipping" />
            </Tabs>
            <div className={classes.tabChildContainer}>
              {tabValue === 0 &&
                <General
                  title={currentOrganisation.name}
                  error={error}
                  values={values}
                  handleSubmit={handleSubmitUpdate}
                  onImageUpload={onImageUpload}
                  handleChange={handleChange}
                  action="Update"
                  currentOrganisationUser={currentOrganisation.currentOrganisationUser}
                />
              }
              {tabValue === 1 && <Users currentOrganisation={currentOrganisation} error={organisationUsersError} currentOrganisationUser={currentOrganisationUser} />}
              {tabValue === 2 && <Subscription currentOrganisation={currentOrganisation} error={error} />}
              {tabValue === 3 && <Shipping currentOrganisation={currentOrganisation} error={error} />}
            </div>
          </div>
        </div>
      )}
    />
  )
}

const styles = theme => ({
  root: {
    padding: theme.spacing(3)

  },
  tabsPanel: {
    flexGrow: 1,
    marginBottom: '50px'
  },
  tabs: {
    marginTop: 16,
    borderBottom: '1px solid #ccc',
    marginBottom: '24px'
  },
  tabsIndicator: {
    backgroundColor: '#626d7a'
  },
  tabChildContainer: {

  }
})

const mapPropsToState = (state, ownProps) => ({
  ...ownProps,
  currentOrganisation: state.organisationsReducer.currentOrganisation,
  error: state.organisationsReducer.error,
  organisationUsersError: state.organisationUsersReducer.error,
  currentOrganisationUser: state.usersReducer.currentUser
})

export default connect(mapPropsToState)(withStyles(styles)(Organisation));


