import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import { withStyles } from '@material-ui/core';
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

class BasicDrawer extends Component {
    render() {
        const { classes } = this.props;
        return (
            <Drawer
                anchor="right"

                open={this.props.open}
                onClose={this.props.onClose}
            >
                <div
                    className={classes.root}
                    tabIndex={0}
                    role="button"
                    //onClick={this.props.onClose}
                    onKeyDown={this.props.onClose}
                >

                    <div className={classes.list}>
                        <List>
                            <ListItem onClick={this.props.onClose} button>
                                <ListItemIcon> <CloseIcon /> </ListItemIcon>
                                <ListItemText />
                            </ListItem>
                            {this.props.children}
                        </List>
                    </div>
                </div>
            </Drawer>
        )
    }
}

const styles = theme => ({
    root: {
        width: '100vw',
        [theme.breakpoints.up('sm')]: {
            width: 350,
        },
    },
    list: {
        width: '100%',
    }
})

export default withStyles(styles)(BasicDrawer);

BasicDrawer.propTypes = {
    children: PropTypes.element.isRequired
}