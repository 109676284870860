import React from 'react'
import {Link as RRLink} from 'react-router-dom';
import {detect} from 'detect-browser';
import {apiPath} from 'AppConstants';

// DISABLE FACEBOOK LOGIN
// import FacebookLogin from 'react-facebook-login';

import {makeStyles} from '@material-ui/styles';
import {
    Button,
    Grid,
    Paper,
    TextField,
    Link
} from '@material-ui/core';

import KeepSpaceIcon from 'components/Icons/KeepSpaceIcon';

import authStyles from "../styles";
import {UID} from 'AppConstants';

const RouterLink = React.forwardRef((props, ref) => (
    <RRLink innerRef={ref} {...props} />
));


const useStyles = makeStyles(theme => ({
    ...authStyles
}))


const LoginForm = props => {
    const classes = useStyles();
    const {submit, handleChange, errors, errorCode, sending} = props;
    const browser = detect();

    const responseFacebook = (response) => {
        fetch(
            (apiPath + '/facebook'),
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'facebook_access_token': response['accessToken']
                }),
            }
        )
            .then(res => res.json())
            .then(json => {
                if(!!json['accessToken']) {
                    window.localStorage.setItem(
                        'uid',
                        json['uid']
                    )
                    window.localStorage.setItem(
                        'access-token',
                        json['accessToken']
                    )
                    window.localStorage.setItem(
                        'client',
                        json['client']
                    )
                    window.location.assign(`/user/${UID}/space/products`);
                    window.location.reload();
                }
            })
            .catch(error => {
                console.log(error)
            });
    }

    return (
        <Paper className={classes.paper}>
            <KeepSpaceIcon height={64} width={64} />
            <div className={classes.header}> Log In to KeepSpace</div>
            <form className={classes.form} onSubmit={submit}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    value={props.email}
                    onChange={handleChange}
                    id="email"
                    label="Email Address"
                    name="email"
                    type="email"
                    autoComplete="username"
                />
                <TextField
                    // variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    id="password"
                    label="Password"
                    value={props.password}
                    onChange={handleChange}
                    type="password"
                    autoComplete="current-password"
                />
                {errors ? <span>{errors}</span> : <></>}
                <Button
                    type="submit"
                    fullWidth
                    disabled={sending}
                    variant="contained"
                    color='secondary'
                    className={classes.submit}
                >
                    Log In
                </Button>
                <Grid container>
                    <Grid item xs>
                        <Link component={RouterLink} to="/auth/reset_password" variant='body1' className={classes.link}>
                            Forgot your password?
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link component={RouterLink} to="/auth/register" variant='body1'>Don't have an account? Sign Up</Link>
                    </Grid>
                </Grid>
                <Grid container className={classes.betaVersionText}>

                        <Link component={RouterLink} target="_blank" to={{ pathname: 'https://app.keepspace.com/auth/login' }} variant='body1' className={classes.link}>
                            Try our new Beta Version 4.0
                        </Link>

                </Grid>

                {/* DISABLE FACEBOOK LOGIN  */}
                {/* <div className={classes.divider} ><span>OR</span></div>
                {
                    browser.name === 'firefox' ? 'Firefox detected, Facebook & Google Auth will not work, please try Chrome or Safari if you require facebook or google to log in' : (

                        <div className={classes.buttons}>

                            <FacebookLogin
                                appId={process.env.REACT_APP_FACEBOOK_ID}
                                autoLoad={false}
                                fields="name,email,picture"
                                callback={responseFacebook}
                                textButton="Facebook"
                                cssClass={`MuiButtonBase-root MuiButton-root MuiButton-fullWidth MuiButton-contained ${classes.facebook}`}
                                tag="button"
                            />

                        </div>
                    )
                } */}
                <div className={classes.divider} />

                <div>
                    {
                        errors ? (
                            <h6>{errors}</h6>
                        ) : (null)
                    }
                    {
                        errorCode === 500 ?
                            <h6>Server error 500, please contact support</h6> : (null)
                    }
                </div>
            </form>
        </Paper>
    )
}

export default LoginForm;
