import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Divider,
  TextField,
} from '@material-ui/core';

import { updateUser } from 'actions/userActions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '100px'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: theme.palette['header'].main,
    '&:hover': {
      backgroundColor: theme.palette['header'].dark
    }
  },
  dropzone: {
    height: '200px',
    width: '200px',
    backgroundColor: '#fff',
    border: '2px dashed rgb(187,186,186)',
    borderRadius: '4%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '16px',
    margin: '8px',
    marginTop: 0,
  },
  dropzoneHighlight: {
    height: '200px',
    width: '200px',
    backgroundColor: 'rgb(188,185,236)',
    border: '2px dashed rgb(187,186,186)',
    borderRadius: '4%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '16px',
    margin: '8px',
    marginTop: 0,
  },
  icon: {
    opacity: 0.3,
    height: '64px',
    width: '64px',
  },
  imagePreview: {
    height: '164px',
    width: '164px',
    opacity: 0.7,
    borderRadius: '4%'
  },
  fileInput: {
    display: 'none',
  }
}));

const GeneralSettings = props => {
  const { profile, className, error,...rest } = props;

  const classes = useStyles();
  const [values, setValues] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    setValues({
      // id: profile.id,
      first_name: profile.first_name,
      last_name: profile.last_name,
      address: profile.address,
      email: profile.email,
      phone: profile.phone
      // loading: true
    });
  }, [profile]);

  const handleChange = event => {
    event.persist();

    setValues({
      ...values,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    var formData = new FormData();

    Object.keys(values).forEach(e => {
      formData.append(e, values[e]);
    })

    // setSubmitted(false);
    dispatch(updateUser(profile.id, formData));
  };

  const fields =
    [
      { value: 'first_name', label: 'First Name', required: true },
      { value: 'last_name', label: 'Last Name', required: true },
      { value: 'address', label: 'Address', required: true },
      { value: 'phone', label: 'Phone', required: true },
      { value: 'email', label: 'Email', required: true },
    ]


  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const verifyEmail = emailRex.test(values.email) ? null : 'Email not valid'


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form onSubmit={handleSubmit}>
        <CardHeader title="Profile" />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={4}
          >
            {fields.map((field) => (
              <Grid
                key={field.value}
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label={field.label}
                  name={field.value}
                  onChange={handleChange}
                  value={values[field.value] || ""}
                  variant="outlined"

                  SelectProps={{ native: true }}
                  helperText={field.value === 'email' ? verifyEmail : null}
                >

                </TextField>

              </Grid>
            ))}
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            className={classes.saveButton}
            type="submit"
            variant="contained"
          >
            Save Changes
          </Button>
        </CardActions>
      </form>

    </Card>
  );
};

GeneralSettings.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.object.isRequired
};

export default GeneralSettings;
