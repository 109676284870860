import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import InputIcon from '@material-ui/icons/Input';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Badge,
  IconButton,
  Toolbar,
  Hidden,
  colors,
  Button,
  ListItemText,
  useMediaQuery
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { logoutAdmin } from "../../../../actions/admin/authenticationActions";
import MenuIcon from '@material-ui/icons/Menu';
import useRouter from 'utils/useRouter';
import NotificationsPopover from 'components/Popover/NotificationsPopover';
import { PricingModal } from 'components';
import Avatar from '@material-ui/core/Avatar';
//import SearchIcon from '@material-ui/icons/Search'; 
import KSLogo from 'assets/img/Keepspace-logo-200-inverted.png'
import ProfilePopover from 'components/Popover/ProfilePopover';
import { UID } from 'AppConstants';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    // backgroundColor: theme.palette.primary.main
    backgroundColor: '#40B5AD'
  },
  flexGrow: {
    flexGrow: 50
  },
  logo: {
    height: 24
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit'
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  trialIcon: {
    marginRight: theme.spacing(1),
  },
  iconButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
  avatar: {
    marginLeft: theme.spacing(1),
  },
  // TopWidth: {
  //   width: '440vW'
  // }


}));

const TopBar = props => {
  const { onOpenNavBarMobile, className, ...rest } = props;

  const session = useSelector(state => state.adminAuthReducer.currentAdmin);

  const id = session.id;
  const classes = useStyles();
  const { history } = useRouter();
  // const searchRef = useRef(null);
  const dispatch = useDispatch();

  const notificationsRef = useRef(null);
  const profileRef = useRef(null);
  const [pricingModalOpen, setPricingModalOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);


  const handleLogout = async () => {
     localStorage.setItem('logout', Date.now().toString());
     await dispatch(logoutAdmin(id));
  };


  useEffect(() => {
    // Add an event listener to handle the storage event
    const handleStorage = async (event) => {
      if (event.key === 'logout') {
        localStorage.removeItem('logout');
      }
      else if(event.key === '_ADMIN'){
        window.location.reload();
      }
    };
    window.addEventListener('storage', handleStorage, false);
    
    return () => {
      window.removeEventListener('storage', handleStorage);
    };
  }, []);

  const handlePricingClose = () => {
    setPricingModalOpen(false);
  };

  const handleNotificationsOpen = () =>
    setOpenNotifications(true);

  const handleNotificationsClose = () =>
    setOpenNotifications(false);

  const handleProfileOpen = () =>
    setOpenProfile(true);

  const handleProfileClose = () =>
    setOpenProfile(false);

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"  // set in makestyles
    >
      <Toolbar>
        {/* <RouterLink to="/"> */}
        <a href='/admin'>
          <img className={classes.logo}
            alt="Logo"
            src={KSLogo}
          />
        </a>
        <div className={classes.flexGrow} />
        {/* <div className={classes.TopWidth}> */}

        <Button
          className={classes.logoutButton}
          color="inherit"
          onClick={handleLogout}
        >
          <InputIcon className={classes.logoutIcon} />
        </Button>

        <div className={classes.avatar}>
          <Avatar alt="Admin" src={session.id ? require(`assets/img/avatars/00${session.id % 46 + 1}.svg`) : null} />
        </div>

        <Hidden mdUp>
          <IconButton
            color="inherit"
            onClick={onOpenNavBarMobile}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>

      </Toolbar>
      <PricingModal
        onClose={handlePricingClose}
        open={pricingModalOpen}
      />
      <ProfilePopover
        anchorEl={profileRef.current}
        notifications={[]}
        onClose={handleProfileClose}
        open={openProfile}
      />
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        seeAllLink={`/user/${UID}/overview`}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
