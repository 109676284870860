import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    subscriptionItemBody: {
        display: 'flex',
        minHeight: '72px',
        alignItems: 'center',
        padding: '12px 24px',
        justifyContent: 'right'
    },
}));


const ApiIntegration = props => {
  const { integrations, handleIntegrationChange } = props;

  const classes = useStyles();

  return (
      <>
        <Typography>KeepSpace will establish an integration to your website via API link and pull orders once they are generated. Please note that toggling on and off sites will be done at the end of a weeks invoice run. </Typography>
        <Typography>And any new integrations requested here will incur a one off $100AUD set up fee to initiate, run and test.</Typography>


        {integrations.map(integration => (
             <div  key={integration.id} className={classes.subscriptionItemBody}>
               <div>
                 <TextField
                   name='shop'
                   onChange={e => handleIntegrationChange(e, integration.id)}
                   value={integration.shop || ''}
                   variant="outlined"
                   label="Url Site"
                   disabled
                 />
                 <Typography style={{fontSize: '8px'}}> Please specify your URL of the site your integrating</Typography>
               </div>
               <Switch
                   type='checkbox'
                   checked={integration.active || false}
                   value={integration.active || false}
                   name="active"
                   onChange={e => handleIntegrationChange(e, integration.id)}
                   color="primary"
               />
             </div>
         ))}
        {/*
           <div  className={classes.subscriptionItemBody}>
            <div>
              Add another site
            </div>
          </div>
        */}
      </>
  );
};


export default ApiIntegration;
