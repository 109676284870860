import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
} from '@material-ui/core';
import Close from "@material-ui/icons/Close";

import P1SKU from './Parts/P1SKU';
import P2Weight from './Parts/P2Weight';
import P3Values from './Parts/P3Values'
import P4Summary from './Parts/P4Summary'
import AccurateDetails from './AccurateDetails'
import Notice from './Notice'
import isFractionalNumber from './isFractionalNumber'

import SuccessSnackbar from 'components/Help/SuccessSnackbar';

import { connect } from 'react-redux';
import { createItem } from 'actions/spaceActions';

import { ValidatorForm } from 'react-material-ui-form-validator';


const styles = theme => ({
  paper: {
    padding: '42px',
    position: 'relative',
    [theme.breakpoints.down("sm")]: {
      padding: 0
    }
  },
  stepContainer: {
    padding: '24px',
  },
  backButton: {
    position: 'absolute',
    left: 10,
    bottom: 10,
    margin: '16px',
  },
  nextButton: {
    position: 'absolute',
    right: 10,
    bottom: 10,
    margin: '16px',
  },
  buttons: {
    padding: '20px',
    [theme.breakpoints.down("sm")]: {
      padding: 28
    },
  },
  stepper: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  mrgnBottom: {
    marginBottom: 10
  },
  closeBtn: {
    float: 'right'
  }
});

class SKUForm extends React.Component {

  constructor(props) {
    super(props);
    this.steps = ['SKU Details', 'Size & Weight', 'Values', 'Summary'];
    this.state = {
      activeStep: 0,

      requestStatus: '',
      units: [],
      isOpen: false,

      // Form Data 1
      title: '',
      sku: '',
      barcode: '',
      category_id: '',
      hs_code: '',
      country_of_manufacture: '',
      description: '',
      image: [],
      preview: '',

      //Form Data 2
      length: '',
      width: '',
      height: '',
      weight: '',
      //Form Data 3
      supplier_cost: '',
      wholesale_cost: '',
      retail_cost: '',
      low_stock_alert: '',

    }
    this.handleChange = this.handleChange.bind(this)
    this.handleBack = this.handleBack.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.onImageUpload = this.onImageUpload.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.handleCloseConfiramtion = this.handleCloseConfiramtion.bind(this)
  }



  getStepContent(step) {
    switch (step) {
      case 0:
        return <P1SKU countries={this.props.countries} onChange={this.handleChange} onImageUpload={this.onImageUpload} parentState={this.state} categories={this.props.categories} />;
      case 1:
        return <P2Weight onChange={this.handleChange} parentState={this.state} addUnitFields={this.addUnitFields} onNewFieldChange={this.onNewFieldChange} handleDelete={this.handleDelete} />;
      case 2:
        return <P3Values onChange={this.handleChange} parentState={this.state} onNewFieldChange={this.onNewFieldChange} />;
      case 3:
        return <P4Summary parentState={this.state} categories={this.props.categories} error={this.props.error} />;
      default:
        throw new Error('Unknown step');
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    });
  }

  onNewFieldChange = (e, index) => {
    let units = [...this.state.units]
    units[index][e.target.name] = e.target.value
    if (e.target.name === 'items_per_unit') units = this.calculateParentUnits(units)

    this.setState({ units });
  }

  handleDelete = i => e => {
    e.preventDefault()
    let newUnits = [
      ...this.state.units.slice(0, i),
      ...this.state.units.slice(i + 1)
    ]
    this.setState({
      units: newUnits
    });
  }


  addUnitFields = e => {
    let newUnit = {
      unit_type: '',
      items_per_unit: '',
      sku: '',
      barcode: '',
      length: '',
      width: '',
      height: '',
      weight: '',
      supplier_cost: '',
      wholesale_cost: '',
      retail_cost: '',
      shipping_value: '',
      low_stock_alert: '',
      unitsOfLowerPackaging: null
    }

    this.setState({
      units: [...this.state.units, newUnit]
    });
  }

  handleNext = () => {
    var activeStep = this.state.activeStep + 1

    if (activeStep === this.steps.length) {
      this.setState({
        isOpen: true
      });
    } else {
      this.setState({
        activeStep: activeStep
      })
    }

  };

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1
    })
  };

  handleCloseConfiramtion() {
    this.setState({
      isOpen: false
    });
  }

  calculateParentUnits(array) {
    array.forEach((u, index) => {
      if (index > 0) {
        let numberOfParentUnits = array[index]['items_per_unit']
        numberOfParentUnits = numberOfParentUnits * array[index - 1]['items_per_unit'];
        array[index]['unitsOfLowerPackaging'] = numberOfParentUnits
      }
    })
    return array
  }


  onImageUpload(files) {
    let reader = new FileReader();
    let file = files[0]
    reader.onloadend = () => (
      this.setState(prevState => ({
        image: prevState.image.concat(files),
        preview: reader.result
      }))
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  submitForm(event) {
    var formData = new FormData();
    Object.keys(this.state).forEach(attribute => {
      if (attribute === 'image' || attribute === 'preview' || attribute === 'activeStep') return;
      if (attribute === 'units') {
        // nest other unit types associated with this Sku
        let units = this.state[attribute]
        units.map((unit, i) =>
          Object.keys(unit).forEach(unitAttribute => {
            let string = `units[units${i}][${unitAttribute}]`
            formData.append(string, unit[unitAttribute]);
          })
        )
      } else {
        formData.append(attribute, this.state[attribute]);
      }
    });

    if (this.state.image[0]) {
      formData.append('image', this.state.image[0], this.state.image[0].name)
    }

    this.props.dispatch(createItem(formData))
      .then(result => {
        if (result) {
          this.setState({
            activeStep: this.state.activeStep + 1
          });
          setTimeout(() => {
            this.props.handleClose();
          }, 1000);
        }
      })
    this.handleCloseConfiramtion();
  }


  render() {
    const { classes, handleClose, error } = this.props;

    let errorText = (error && error.response) ? error.response.statusText : null;
    if (!errorText && error) {
      errorText = error.message;
    }
    return (
      <Paper className={classes.paper}>
        <SuccessSnackbar
          open={Boolean(error)}
          error={errorText || null}
        />
        <Button onClick={handleClose} className={classes.closeBtn}>
          <Close />
        </Button>
        <Typography component="h1" variant="h4" align='center'>
          Product Type
        </Typography>

        <Stepper activeStep={this.state.activeStep} className={classes.stepper}>
          {this.steps.map(label => (
            <Step key={label} className={classes.mrgnBottom}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <div className={classes.stepContainer}>
          {this.props.loading ?
            (
              <Notice sub1="Saving items." sub2="This may take a while." progressBar={true} />
            ) : this.state.activeStep === this.steps.length ? (
              <Notice sub1="SKU has been added" sub2="Window will close shortly" progressBar={false} />
            ) : (
              <>
                <ValidatorForm
                  ref="form"
                  onSubmit={this.handleNext}
                  onError={errors => console.log(errors)}
                >
                  {this.getStepContent(this.state.activeStep)}

                  <div className={classes.buttons}>
                    {this.state.activeStep !== 0 && (
                      <Button
                        variant="contained"
                        onClick={this.handleBack}
                        className={classes.backButton}
                      >
                        Back
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      // if unitsOfLowerPackaging is not a whole number (integar), breaking this unit into smaller units will cause errors
                      disabled={this.props.loading || this.state.units.some(u => isFractionalNumber(u.unitsOfLowerPackaging))}
                      color="primary"
                      type='submit'
                      className={classes.nextButton}
                      aria-label="go to next step"
                    >
                      {this.state.activeStep === this.steps.length - 1 ? 'Create' : 'Next'}
                    </Button>
                  </div>
                </ValidatorForm>

              </>
            )
          }
        </div>
        <AccurateDetails isOpen={this.state.isOpen} onSubmit={this.submitForm} handleClose={this.handleCloseConfiramtion} />
      </Paper>

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  loading: state.spaceReducer.uploading,
  error: state.spaceReducer.error,
  countries : state.adminAuthReducer.countries,
  categories: (state.categoriesReducer.categories.length) ? state.categoriesReducer.categories : state.spaceReducer.categoriesFilter

})

export default connect(mapStateToProps)(withStyles(styles)(SKUForm));
