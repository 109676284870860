import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Button,
  Divider,
  TextField,
  colors
} from '@material-ui/core';
// import { useDispatch, useSelector } from 'react-redux';
import SuccessSnackbar from '../SuccessSnackbar';
// import { updateUser } from 'actions/userActions';
import { resetPasswordTempSignin, changePassword } from '../../../../../redux-token-auth-config' // <-- note this is YOUR file, not the redux-token-auth NPM module
import { useDispatch } from 'react-redux';
import { clearUserErrors } from 'actions/userActions';

const useStyles = makeStyles(theme => ({
  root: {},
  margin10: {
    margin: '10px'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const Security = props => {
  const { className, currentUser, error, ...rest } = props;

  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    newPassword: '',
    confirmPassword: ''
  });

  const [state] = useState({
      uid: window.localStorage.getItem('uid'),
      client: window.localStorage.getItem('client'),
      accessToken:  window.localStorage.getItem('access-token'),
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  useEffect(() => {
    dispatch(clearUserErrors());    
  }, [])

    // isSubmittingRequest: state.reduxTokenAuth.currentUser.isSubmittingRequest,
    // submissionError: state.reduxTokenAuth.currentUser.submissionError,

  const dispatchChangePassword =  (newPassword) => {dispatch(changePassword(newPassword))}
  const dispatchResetPasswordTempSignIn =  (authHeaders) => {dispatch(resetPasswordTempSignin(authHeaders))}

  const submitForm = event => {

    event.preventDefault();

    const {
        uid,
        client,
        accessToken
    } = state;

    dispatchResetPasswordTempSignIn({
        uid,
        client,
        'access-token': accessToken
    })

    dispatchChangePassword({
        password: values.newPassword,
        passwordConfirmation: values.confirmPassword
    })

    setOpenSnackbar(true);


  }

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const valid = values.newPassword.length >= 6 && values.newPassword === values.confirmPassword;
  const minLength = values.newPassword.length >= 6
  const passwordMatch = values.confirmPassword ? values.confirmPassword === values.newPassword : true


  return (
    <>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader title="Change password" />
        <Divider />
        <CardContent>
          <form>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={4}
                sm={12}
                xs={12}
              >

                <TextField
                  fullWidth
                  label="New password"
                  name="newPassword"
                  onChange={handleChange}
                  value={values.newPassword}
                  variant="outlined"
                  className={classes.margin10}
                  helperText={minLength ? null : 'Min. 6 characters'}
                  type='password'
                />
                <TextField
                  fullWidth
                  label="Confirm password"
                  name="confirmPassword"
                  onChange={handleChange}
                  value={values.confirmPassword}
                  variant="outlined"
                  className={classes.margin10}
                  helperText={passwordMatch ? null : 'Passwords must match'}
                  type='password'
                />
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            className={classes.saveButton}
            disabled={!valid}
            variant="contained"
            onClick ={submitForm}
          >
            Save changes
          </Button>
        </CardActions>
      </Card>

      <SuccessSnackbar
        onClose={handleSnackbarClose}
        open={openSnackbar}
      />
    </>
  );
};

Security.propTypes = {
  className: PropTypes.string
};



export default Security;
