import adminApi from 'services/admin/adminApi';
/*----------------dutiess--------------------------*/
export const FETCH_DUTIES_BEGIN = 'FETCH_DUTIES_BEGIN';
export const FETCH_DUTIES_SUCCESS = 'FETCH_DUTIES_SUCCESS';
export const FETCH_DUTIES_FILTERS_SUCCESS = 'FETCH_DUTIES_FILTERS_SUCCESS';
export const FETCH_DUTIES_FAILURE = 'FETCH_DUTIES_FAILURE'

export const DELETE_DUTIES = 'DELETE_DUTIES'
export const DELETE_DUTIES_SUCCESS = 'DELETE_DUTIES_SUCCESS'
export const DELETE_DUTIES_FAILURE = 'DELETE_DUTIES_FAILURE'

export const FETCH_DUTIES_BY_ID_SUCCESS = 'FETCH_DUTIES_BY_ID_SUCCESS';
export const FETCH_DUTIES_BY_ID_FAILURE = 'FETCH_DUTIES_BY_ID_FAILURE';

export const CREATE_DUTIES_BEGIN = 'CREATE_DUTIES_BEGIN';
export const CREATE_DUTIES_SUCCESS = 'CREATE_DUTIES_SUCCESS';
export const CREATE_DUTIES_FAILURE = 'CREATE_DUTIES_FAILURE';

export const UPDATE_DUTIES_BEGIN = 'UPDATE_DUTIES_BEGIN';
export const UPDATE_DUTIES_SUCCESS = 'UPDATE_DUTIES_SUCCESS';
export const UPDATE_DUTIES_FAILURE = 'UPDATE_DUTIES_FAILURE';


export const FETCH_DUTIES_MOVEMENT_SUCCESS = 'FETCH_DUTIES_MOVEMENT_SUCCESS';
export const FETCH_DUTIES_MOVEMENT_FAILURE = 'FETCH_DUTIES_MOVEMENT_FAILURE';

export const SET_DUTIES_FILTER = 'SET_DUTIES_FILTER';
export const CLEAR_DUTIES_FILTER = 'CLEAR_DUTIES_FILTER';
export const CLEAR_DUTIES = 'CLEAR_DUTIES';
export const CLEAR_DUTIES_NOTIFICATION = 'CLEAR_DUTIES_NOTIFICATION';

export const GET_BOOKING_ID_BEGIN = 'GET_BOOKING_ID_BEGIN';
export const GET_BOOKING_ID_SUCCESS = 'GET_BOOKING_ID_SUCCESS';
export const GET_BOOKING_ID_FAILURE = 'GET_BOOKING_ID_FAILURE';

/*--------------------Duties----------------------------*/
export function fetchDuties(params) {
    return dispatch => {
        dispatch(fetchDutiesBegin());//calling this action to show loading
        return adminApi.fetchDuties(params)// calling list of dutiess
            .then(json => {

                dispatch(fetchDutiesSuccess(json));
                return json;
            })
            .catch(error => {
                console.log('error', error);

                dispatch(fetchDutiesFailure(error))

            });
    };
}
export function fetchDutiesFilters() {
    return dispatch => {
        dispatch(fetchDutiesBegin());//calling this action to show loading
        return adminApi.fetchDutiesFilters()// calling list of dutiess
            .then(json => {

                dispatch(fetchDutiesFiltersSuccess(json));
                return json;
            })
            .catch(error => {
                console.log('error', error);

                dispatch(fetchDutiesFailure(error))

            });
    };
}

export function deleteDuties(id) {
    return dispatch => {
        return adminApi.deleteDuties(id)
            .then(json => {
                dispatch(deleteDutiesSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(deleteDutiesFailure(error))

            });
    }
}

export function fetchDutiesByID(id) {
    return dispatch => {
        dispatch(fetchDutiesBegin());//calling this action to show loading
        return adminApi.getDutiesDetail(id)
            .then(handleErrors)
            .then(json => {
                dispatch(fetchDutiesByIDSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(fetchDutiesByIDFailure(error))

            });
    }
}

export function updateDuties(id, data) {
    return dispatch => {
        dispatch(updateDutiesBegin());
        let res = adminApi.updateDuties(id, data)
            .then(json => {
                dispatch(updateDutiesSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(updateDutiesFailure(error));
            })
        return res;
    }
}

export const fetchDutiesBegin = () => (
    // the whole object is n action
    {
        type: FETCH_DUTIES_BEGIN
    });

export const fetchDutiesSuccess = json => ({
    type: FETCH_DUTIES_SUCCESS,
    data: json.data.organisation_extra_charges,
    filter: json.data.filters,
    pagination: json.data.pagination,
});
export const fetchDutiesFiltersSuccess = json => ({
    type: FETCH_DUTIES_FILTERS_SUCCESS,
    filter: json.data.filters
});
export const fetchDutiesFailure = error => ({
    type: FETCH_DUTIES_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});

export const deleteDutiesSuccess = (json) => ({
    type: DELETE_DUTIES_SUCCESS,
    data: json.data.organisation_extra_charge,
    msg: json.message
})

export const deleteDutiesFailure = (error) => ({
    type: DELETE_DUTIES_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'},
    msg: "An error occurred, please try again later"
});

export const fetchDutiesByIDSuccess = json => ({
    type: FETCH_DUTIES_BY_ID_SUCCESS,
    payload: json.data.organisation_extra_charge
});

export const fetchDutiesByIDFailure = error => ({
    type: FETCH_DUTIES_BY_ID_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'},
    msg: "An error occurred, please try again later"
});

export function updateDutiesBegin() {
    return {
        type: UPDATE_DUTIES_BEGIN
    }
}

export function updateDutiesSuccess(json) {
    return {
        type: UPDATE_DUTIES_SUCCESS,
        payload: json.data.organisation_extra_charge

    }
}

export function updateDutiesFailure(error) {
    return {
        type: UPDATE_DUTIES_FAILURE,
        payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
    }
}

export function createDuties(data) {
    return dispatch => {
        dispatch(createDutiesBegin());
        let res = adminApi.createDuties(data)
            .then(json => {
                dispatch(createDutiesSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(createDutiesFailure(error));
            })
        return res;
    }

}

export function createDutiesBegin() {
    return {
        type: CREATE_DUTIES_BEGIN,
    }
}


export function createDutiesSuccess(json) {
    return {
        type: CREATE_DUTIES_SUCCESS,
        payload: {
            duties: json.data.organisation_extra_charge
        }
    }
}


export function createDutiesFailure(error) {
    return {
        type: CREATE_DUTIES_FAILURE,
        payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
    }
}


export function setDutiesFilter(filters) {
    return {
        type: SET_DUTIES_FILTER,
        payload: {filters}
    }
}

export function clearDutiesFilters() {
    return {
        type: CLEAR_DUTIES_FILTER
    }
}
export function clearDuties() {
    return {
        type: CLEAR_DUTIES
    }
}

export function clearDutiesNotification() {
    return {
        type:CLEAR_DUTIES_NOTIFICATION
         }
}

export function getBookingByReference(orgId, param) {
    return dispatch => {
        dispatch(getBookingByRefBegin());//calling this action to show loading
        return adminApi.getBookingByReference(orgId, param)
            .then(handleErrors)
            .then(json => {
                dispatch(getBookingByRefSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(getBookingByRefFailure(error))

            });
    }
}
export const getBookingByRefBegin = () => (
    {
        type: GET_BOOKING_ID_BEGIN
    });
export const getBookingByRefSuccess = json => ({
    type: GET_BOOKING_ID_SUCCESS,
    payload: json.data.bookings
});

export const getBookingByRefFailure = error => ({
    type: GET_BOOKING_ID_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});
// Private methods

function handleErrors(response) {
    if(!response.success) {
        throw Error(response.statusText);
    }
    return response;
}

function handleDutiesMessageErrors(response) {
    if(!response.success) {
        throw Error(response.statusText);
    }
    return response;
}
