import React, {useState, useEffect} from 'react';
import {renderRoutes} from 'react-router-config';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {useDispatch} from 'react-redux';
import '../../assets/scss/keepspace-css/admin/_global.scss'
import {NavBar, TopBar} from './components';
import * as Storage from 'utils/storage';
import {
  fetchCountries
} from 'actions/otherActions';


const UserLayout = props => {
  const {route, location} = props;
  const dispatch = useDispatch();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };
  const getCountries = async () => {
    const ADMIN = Storage.getItem('_ADMIN');
    if(ADMIN) await dispatch(fetchCountries());


  }
  useEffect(() => {

    getCountries()
  }, []);

  return (
    <div className='appRoot'>
      <TopBar
        className='topBar'
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />

      <div className='container'>

        {!location.pathname.includes('admin/errors') && <NavBar
          className='navBar'
          onMobileClose={handleNavBarMobileClose}
          onMobileOpen={handleNavBarMobileOpen}
          openMobile={openNavBarMobile}
        />}

        <main className='main'>
          {/* <Suspense fallback={<LinearProgress />}> */}
          {renderRoutes(route.routes)}
          {/* </Suspense> */}
        </main>

      </div>

    </div>
  );
};

UserLayout.propTypes = {
  route: PropTypes.object
};

export default UserLayout;
