import React, { useState, useEffect } from "react";

import { makeStyles, Grid } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { TextValidator } from "react-material-ui-form-validator";

const FormField = (props) => {
  /* Any time you use a custom built form element (or a native one) you
   * can use this ref to set the width of the label to cut some room from the outline for it.
   * Without it, the outline will cross through the text of the label
   **/
  const { value, id, options, type, required, onChange, label, errors, gridWidth, minDate, minLength } = props;
  const ref = React.useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    setLabelWidth(ref.current ? ref.current.offsetWidth : 0);
  }, []);


  return (
    <Grid key={id} item xs={12} sm={gridWidth}>
      {type ? (
        <>

          {type === "date" ? (
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="DD/MM/YYYY"
              margin="normal"
              required={required}
              id={id}
              name={id}
              label={label}
              value={value[id] || null}
              error={errors ? errors[id] : false}
              helperText={errors ? errors[id] : false}
              fullWidth
              autoComplete={id}
              minDate={minDate || false}
              onChange={(date) => {
                let event = {
                  target: {
                    id: id,
                    value: date,
                  },
                };
                onChange(event);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          ) : null}
          {type === "native-select" ? (
            <TextValidator
              validators={required ? ["required"] : []}
              errorMessages={required ? ["This field is required"] : []}
              inputProps={{
                name: id,
                id: id,
              }}

              fullWidth
              value={value[id] || ""}
              onChange={onChange}
              variant="outlined"
              SelectProps={{ native: true }}
              select
              label={required ? `${label}*` : label}
              error={errors ? errors[id] : false}
              helperText={errors ? errors[id] : false}
              InputLabelProps={(value[id]) ? { shrink: true } : { shrink: false }}
            >
              {!options.some(o => (o.value == null || o.value == "")) && <option value="" />}
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.text || option.name}
                </option>
              ))}

            </TextValidator>
          ) : null}
        </>
      ) : (
        /**
         * If no 'type' is provided, simply return the default TextField
         */
        <TextValidator

          key={id}
          variant="outlined"
          // required={required}
          validators={required ? ["required"] : []}
          errorMessages={required ? ["This field is required"] : []}
          id={id}
          name={id}
          label={required ? `${label}*` : label}
          value={value[id] || ""}
          error={errors ? errors[id] : false}
          helperText={errors ? errors[id] : false}
          fullWidth
          autoComplete={id}
          onChange={onChange}
          inputProps={minLength ? { minLength: 3 } : {}}
          InputLabelProps={(value[id]) ? { shrink: true } : { shrink: false }}
        />
      )}
    </Grid>
  );
};

const Form = (props) => {

  const { children, inputs, state, errors, onChange, gridWidth, updateCountries } = props;

  let padding = { value: 20 }
  if (gridWidth !== 12) padding.value = "15px 65px 58px"

  const classes = useStyles(padding);
  const [inputList, setInputList] = useState([...inputs]);
  const [values, setValues] = useState(state);

  const updateCountryList = () => {
    let countryObj = inputList.find(element => element.id === "country");

    if (countryObj && countryObj.options.length) {
      const found = countryObj.options.some(el => el.text === values["country"]);
      if (!found) {
        const codeFound = countryObj.options.some(el => (el.code === values["country"] || el.alpha3Code === values["country"]));
        if (codeFound) {
          const country = countryObj.options.find(el => (el.code === values["country"] || el.alpha3Code === values["country"]));
          const newVal = { ...values, country: country.text }
          setValues(newVal);
          onChange(null, "country", country.text)
        }
        else {
          const updatedOptList = [...countryObj.options, {
            text: values["country"],
            value: values["country"]
          }];
          if (updateCountries) updateCountries(updatedOptList)

        }

      }
    }
    else if (countryObj && countryObj.options && (!countryObj.options.length) && values["country"]) {
      countryObj.options.push({
        text: values["country"],
        value: values["country"]
      });
      if (updateCountries) updateCountries(countryObj.options)

    }
  }
  useEffect(() => {
    setValues(state)
    setInputList(inputs)
  }, [state, inputs]);

  useEffect(() => {
    if (values["country"]) {
      updateCountryList()
    }
  }, [values]);

  return (
    <Grid container spacing={3} className={classes.form}>
      {inputList.map((formInput) => (
        <FormField
          key={formInput.id}
          onChange={onChange}
          value={values}
          errors={errors}
          gridWidth={gridWidth}
          {...formInput}
        />
      ))}
      {children}
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    padding: padding => padding.value
  },
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textField: {},
}));

export default Form;
