import React, { Component } from 'react'
import { connect } from 'react-redux';
import { addSkuToOrder, modSkuQuantityToOrder } from 'actions/orderActions'

import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import Cell from 'components/FormsPleaseStopDoingThis/NewOrderForm/Parts/Cell'

const styles = theme => ({
    part: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(100vh - 400px)'
    },
    textField: {
        borderRadius: '4px',
        border: '1px solid rgb(127, 127, 127)',
        marginBottom: '8px'
    },
    SKUContainer: {
        border: '#ccc 1px solid',
        flexGrow: 5,
        
    },
    secondSectionHeader: {
        marginTop: 32
    },
    SKUSearch: {
        flexGrow: 5,
        border: '#ccc 1px solid',
        //maxHeight: 150,
        minHeight: 64,
        overflowY: 'scroll'
    },
    flexGrow: {
        flexGrow: 1,
    },
    fab: {
        height: 32,
        width: 34,
    },
})

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    items: state.spaceReducer.items,
    orders: state.orderReducer.orders,
    newOrderInProgress: state.orderReducer.newOrderInProgress,
    newOrderItems: state.orderReducer.newOrderItems,
    newOrderError: state.orderReducer.newOrderError,
    newOrderObject: state.orderReducer.newOrderObject,
})


class P1SKU extends Component {

    constructor(props){
        super(props);
        this.addSku = this.addSku.bind(this)
        this.quantityVariation = this.quantityVariation.bind(this)
    }

    addSku(id){
        this.props.dispatch(addSkuToOrder(id))
    }

    quantityVariation(id, quantityVariation){
        this.props.dispatch(modSkuQuantityToOrder(id, quantityVariation))
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.part}>
                <Typography>
                    SKUs for this order:
                </Typography>
                
                <div className={classes.SKUContainer}>
                    {(this.props.newOrderItems.length > 0) ? (
                        this.props.newOrderItems.map(orderItem => (
                            <Cell key={orderItem.item.id}>
                                <span>{orderItem.item.title}, {orderItem.item.id}</span>
                                
                                <div className={classes.flexGrow}></div>
                                
                                <span> Quantity: {orderItem.quantity}</span>
                                
                                <Fab id={orderItem.item.id} key={orderItem.item.id} color="primary" aria-label="Add" className={classes.fab} onClick={() => this.quantityVariation(orderItem.item.id, 1)}>
                                    <AddIcon />
                                </Fab>
                                <Fab id={orderItem.item.id} key={orderItem.item.id} color="primary" aria-label="Reduce" className={classes.fab} onClick={() => this.quantityVariation(orderItem.item.id, -1)}>
                                    <RemoveIcon />
                                </Fab>

                            </Cell>
                        ))
                    ):(
                        <Cell>Order has no items, add some below</Cell>
                    )}
                </div>

                <Typography className={classes.secondSectionHeader}>
                    SKUs in your account:
                </Typography>    
                <div className={classes.SKUSearch}>
                    {this.props.items.length > 0 ? (
                        this.props.items.map(item => (
                            <Cell key={item.id}>
                                <span>{item.title}, {item.id}</span>
                                <div className={classes.flexGrow}></div>
                                <Fab id={item.id} key={item.id} color="primary" aria-label="Add" className={classes.fab} onClick={() => this.addSku(item.id)}>
                                    <AddIcon />
                                </Fab>
                            </Cell>
                        ))
                    ):(
                        <Cell>You do not yet have any SKU's in your account, please add some from the Spaces page</Cell>
                    )}
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(withStyles(styles)(P1SKU));
