import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Input, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';


const styles = theme => ({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    header: {
      fontSize: '20px',
      fontWeight: '200',
      marginTop: '50px'
    },
    error: {
      fontSize: '12px',
      color: theme.palette.error.main,
      marginLeft: '10px',
      marginBottom: '10px',
    }

  });

export class Step2 extends Component {
  continue = e => {
    e.preventDefault();
    // PROCESS FORM //
    this.props.submitForm(e);
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { firstName, values, handleChange, classes, errors } = this.props;

    function capitalize(s) {
      return s[0].toUpperCase() + s.substr(1);
    }
    return (
      <>
        <div className={classes.header}> Hi {capitalize(firstName)}, tell us about your Company </div>
        <form className={classes.form} onSubmit={this.continue}>
          <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="name">What is your Company name?</InputLabel>
              <Input
                id="name"
                name="name"
                autoComplete="name"
                value={values.name}
                onChange={handleChange}
                autoFocus
                error={Boolean(errors['organisations.name'])}
              />
              {Boolean(errors['organisations.name']) ? <Typography className={classes.error}>{errors['organisations.name']}</Typography> : null}
          </FormControl>
          <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">What is your Company email?</InputLabel>
              <Input
                id="companyEmail"
                name="companyEmail"
                autoComplete="companyEmail"
                value={values.companyEmail}
                onChange={handleChange}
                error={Boolean(errors['organisations.email'])}
              />
              {Boolean(errors['organisations.email']) ? <Typography className={classes.error}>{errors['organisations.email']}</Typography> : null}
          </FormControl>
          <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="webUrl">Website/Marketplace URL</InputLabel>
              <Input
                id="webUrl"
                name="webUrl"
                autoComplete="webUrl"
                value={values.webUrl}
                onChange={handleChange}  />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="ordersMonth">How many orders are made a month?</InputLabel>
              <Input
                id="ordersMonth"
                name="ordersMonth"
                autoComplete="ordersMonth"
                value={values.ordersMonth}
                onChange={handleChange}
              />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color='secondary'
            style={{marginTop: 20, marginBottom: 20}}
          >
            Complete Signup
        </Button>
      </form>
          <div>
            <div onClick={this.back}>Back</div>
          </div>
      </>
    );
  }
}

export default withStyles(styles)(Step2)
