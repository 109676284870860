import adminApi from "services/admin/adminApi";
/*----------------ORGS--------------------------*/
export const FETCH_ADMIN_ORGANISATIONS_BEGIN =
  "FETCH_ADMIN_ORGANISATIONS_BEGIN";
export const FETCH_ADMIN_ORGANISATIONS_SUCCESS =
  "FETCH_ADMIN_ORGANISATIONS_SUCCESS";
export const FETCH_ADMIN_ORGANISATIONS_FAILURE =
  "FETCH_ADMIN_ORGANISATIONS_FAILURE";

export const DELETE_ADMIN_ORGANISATION = "DELETE_ADMIN_ORGANISATION";
export const DELETE_ADMIN_ORGANISATION_SUCCESS =
  "DELETE_ADMIN_ORGANISATION_SUCCESS";
export const DELETE_ADMIN_ORGANISATION_FAILURE =
  "DELETE_ADMIN_ORGANISATION_FAILURE";

export const GET_ADMIN_ORGANISATION_BY_ID_BEGIN =
  "GET_ADMIN_ORGANISATION_BY_ID_BEGIN";

export const GET_ADMIN_ORGANISATION_BY_ID_SUCCESS =
  "GET_ADMIN_ORGANISATION_BY_ID_SUCCESS";
export const GET_ADMIN_ORGANISATION_BY_ID_FAILURE =
  "GET_ADMIN_ORGANISATION_BY_ID_FAILURE";

export const CREATE_ADMIN_ORGANISATION_BEGIN =
  "CREATE_ADMIN_ORGANISATION_BEGIN";
export const CREATE_ADMIN_ORGANISATION_SUCCESS =
  "CREATE_ADMIN_ORGANISATION_SUCCESS";
export const CREATE_ADMIN_ORGANISATION_FAILURE =
  "CREATE_ADMIN_ORGANISATION_FAILURE";

export const UPDATE_ADMIN_ORGANISATIONS_BEGIN =
  "UPDATE_ADMIN_ORGANISATIONS_BEGIN";
export const UPDATE_ADMIN_ORGANISATION_SUCCESS =
  "UPDATE_ADMIN_ORGANISATION_SUCCESS";
export const UPDATE_ADMIN_ORGANISATION_FAILURE =
  "UPDATE_ADMIN_ORGANISATION_FAILURE";

export const SET_ADMIN_ORGANISATIONS_FILTER = "SET_ADMIN_ORGANISATIONS_FILTER";
export const CLEAR_ADMIN_ORGANISATIONS = "CLEAR_ADMIN_ORGANISATIONS";
export const CLEAR_ADMIN_ORGANISATIONS_FILTER =
  "CLEAR_ADMIN_ORGANISATIONS_FILTER";

export const FETCH_ORG_SKU_BEGIN = "FETCH_ORG_SKU_BEGIN";
export const FETCH_ORG_SKU_SUCCESS = "FETCH_ORG_SKU_SUCCESS";
export const FETCH_ORG_SKU_FAILURE = "FETCH_ORG_SKU_FAILURE";

export const FETCH_ORG_BUNDLES_BEGIN = "FETCH_ORG_BUNDLES_BEGIN";
export const FETCH_ORG_BUNDLES_SUCCESS = "FETCH_ORG_BUNDLES_SUCCESS";
export const FETCH_ORG_BUNDLES_FAILURE = "FETCH_ORG_BUNDLES_FAILURE";

export const FETCH_ORG_INVOICES_BEGIN = "FETCH_ORG_INVOICES_BEGIN";
export const FETCH_ORG_INVOICES_SUCCESS = "FETCH_ORG_INVOICES_SUCCESS";
export const FETCH_ORG_INVOICES_FAILURE = "FETCH_ORG_INVOICES_FAILURE";

export const FETCH_ORDERED_QUANTITIES_BEGIN = "FETCH_ORDERED_QUANTITIES_BEGIN";
export const FETCH_ORDERED_QUANTITIES_SUCCESS =
  "FETCH_ORDERED_QUANTITIES_SUCCESS";
export const FETCH_ORDERED_QUANTITIES_FAILURE =
  "FETCH_ORDERED_QUANTITIES_FAILURE";

export const FETCH_ORDERED_QUANTITIES_ORGANISATIONS =
  "FETCH_ORDERED_QUANTITIES_ORGANISATIONS";
export const SET_ORDERED_QUANTITIES_FILTER = "SET_ORDERED_QUANTITIES_FILTER";
export const CLEAR_ORDERED_QUANTITIES_FILTER =
  "CLEAR_ORDERED_QUANTITIES_FILTER";

export const FETCH_ORG_SUPPLIERS_BEGIN = "FETCH_ORG_SUPPLIERS_BEGIN";
export const FETCH_ORG_SUPPLIERS_SUCCESS = "FETCH_ORG_SUPPLIERS_SUCCESS";
export const FETCH_ORG_SUPPLIERS_FAILURE = "FETCH_ORG_SUPPLIERS_FAILURE";

export const DOWNLOAD_SKU_TMPL_BEGIN = "DOWNLOAD_SKU_TMPL_BEGIN";
export const DOWNLOAD_SKU_TMPL_SUCCESS = "DOWNLOAD_SKU_TMPL_SUCCESS";
export const DOWNLOAD_SKU_TMPL_FAILURE = "DOWNLOAD_SKU_TMPL_FAILURE";
export const UPLOAD_SKU_TMPL_BEGIN = "UPLOAD_SKU_TMPL_BEGIN";
export const UPLOAD_SKU_TMPL_SUCCESS = "UPLOAD_SKU_TMPL_SUCCESS";
export const UPLOAD_SKU_TMPL_FAILURE = "UPLOAD_SKU_TMPL_FAILURE";

export const DOWNLOAD_BUNDLE_TMPL_BEGIN = "DOWNLOAD_BUNDLE_TMPL_BEGIN";
export const DOWNLOAD_BUNDLE_TMPL_SUCCESS = "DOWNLOAD_BUNDLE_TMPL_SUCCESS";
export const DOWNLOAD_BUNDLE_TMPL_FAILURE = "DOWNLOAD_BUNDLE_TMPL_FAILURE";
export const UPLOAD_BUNDLE_TMPL_BEGIN = "UPLOAD_BUNDLE_TMPL_BEGIN";
export const UPLOAD_BUNDLE_TMPL_SUCCESS = "UPLOAD_BUNDLE_TMPL_SUCCESS";
export const UPLOAD_BUNDLE_TMPL_FAILURE = "UPLOAD_BUNDLE_TMPL_FAILURE";

export const DOWNLOAD_BUNDLE_COMP_TMPL_BEGIN =
  "DOWNLOAD_BUNDLE_COMP_TMPL_BEGIN";
export const DOWNLOAD_BUNDLE_COMP_TMPL_SUCCESS =
  "DOWNLOAD_BUNDLE_COMP_TMPL_SUCCESS";
export const DOWNLOAD_BUNDLE_COMP_TMPL_FAILURE =
  "DOWNLOAD_BUNDLE_COMP_TMPL_FAILURE";
export const UPLOAD_BUNDLE_COMP_TMPL_BEGIN = "UPLOAD_BUNDLE_COMP_TMPL_BEGIN";
export const UPLOAD_BUNDLE_COMP_TMPL_SUCCESS =
  "UPLOAD_BUNDLE_COMP_TMPL_SUCCESS";
export const UPLOAD_BUNDLE_COMP_TMPL_FAILURE =
  "UPLOAD_BUNDLE_COMP_TMPL_FAILURE";

export const FETCH_ADMIN_ORGANISATION_HISTORY_BEGIN =
  "FETCH_ADMIN_ORGANISATION_HISTORY_BEGIN";
export const FETCH_ADMIN_ORGANISATION_HISTORY_SUCCESS =
  "FETCH_ADMIN_ORGANISATION_HISTORY_SUCCESS";
export const FETCH_ADMIN_ORGANISATION_HISTORY_FAILURE =
  "FETCH_ADMIN_ORGANISATION_HISTORY_FAILURE";

export const CLEAR_TMPLS = "CLEAR_TMPLS";

/*--------------------Organisations----------------------------*/
export function fetchAdminOrganisations(params) {
  return (dispatch) => {
    dispatch(fetchAdminOrganisationsBegin()); //calling this action to show loading
    return adminApi
      .fetchAdminOrganisations(params) // calling list of materials
      .then((json) => {
        dispatch(fetchAdminOrganisationsSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log("error", error);

        dispatch(fetchAdminOrganisationsFailure(error));
      });
  };
}

export function deleteAdminOrganisations(id) {
  return (dispatch) => {
    return adminApi
      .deleteAdminOrganisations(id)
      .then((json) => {
        dispatch(deleteAdminOrganisationsSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log(error);
        dispatch(deleteAdminOrganisationsFailure(error));
      });
  };
}

export function fetchAdminOrganisationsById(id) {
  return (dispatch) => {
    dispatch(fetchAdminOrganisationsBegin()); //calling this action to show loading
    return adminApi
      .getAdminOrganisationDetail(id)
      .then(handleErrors)
      .then((json) => {
        dispatch(fetchAdminOrganisationByIdSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchAdminOrganisationByIdFailure(error));
      });
  };
}

export function updateAdminOrganisations(data, id) {
  return (dispatch) => {
    dispatch(updateAdminOrganisationsBegin());
    let res = adminApi
      .updateAdminOrganisation(data, id)
      .then((json) => {
        dispatch(updateAdminOrganisationsSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(updateAdminOrganisationsFailure(error));
      });
    return res;
  };
}

export const fetchAdminOrganisationsBegin = () => ({
  type: FETCH_ADMIN_ORGANISATIONS_BEGIN,
});

export const fetchAdminOrganisationsSuccess = (json) => ({
  type: FETCH_ADMIN_ORGANISATIONS_SUCCESS,
  data: json.data,
});
export const fetchAdminOrganisationsFailure = (error) => ({
  type: FETCH_ADMIN_ORGANISATIONS_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const deleteAdminOrganisationsSuccess = (json) => ({
  type: DELETE_ADMIN_ORGANISATION_SUCCESS,
  data: json.data.organisation,
  msg: json.message,
});

export const deleteAdminOrganisationsFailure = (error) => ({
  type: DELETE_ADMIN_ORGANISATION_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
  msg: "An error occurred, please try again later",
});

export const fetchAdminOrganisationByIdBegin = () => ({
  type: GET_ADMIN_ORGANISATION_BY_ID_BEGIN,
});
export const fetchAdminOrganisationByIdSuccess = (json) => ({
  type: GET_ADMIN_ORGANISATION_BY_ID_SUCCESS,
  payload: {
    data: json.data.organisation,
    editOtps: json.data.edit_options,
  },
});

export const fetchAdminOrganisationByIdFailure = (error) => ({
  type: GET_ADMIN_ORGANISATION_BY_ID_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
  msg: "An error occurred, please try again later",
});

export function updateAdminOrganisationsBegin() {
  return {
    type: UPDATE_ADMIN_ORGANISATIONS_BEGIN,
  };
}

export function updateAdminOrganisationsSuccess(json) {
  return {
    type: UPDATE_ADMIN_ORGANISATION_SUCCESS,
    data: json.data.organisation,
  };
}

export function updateAdminOrganisationsFailure(error) {
  return {
    type: UPDATE_ADMIN_ORGANISATION_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function createAdminOrganisation(data) {
  return (dispatch) => {
    dispatch(createAdminOrganisationBegin());
    let res = adminApi
      .createAdminOrganisation(data)
      .then((json) => {
        dispatch(createAdminOrganisationSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(createAdminOrganisationFailure(error));
      });
    return res;
  };
}

export function createAdminOrganisationBegin() {
  return {
    type: CREATE_ADMIN_ORGANISATION_BEGIN,
  };
}

export const createAdminOrganisationSuccess = (organisation) => ({
  type: CREATE_ADMIN_ORGANISATION_SUCCESS,
  payload: { organisation },
});

export const createAdminOrganisationFailure = (error) => ({
  type: CREATE_ADMIN_ORGANISATION_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function clearAdminOrganisations() {
  return {
    type: CLEAR_ADMIN_ORGANISATIONS,
  };
}
export function setOrgFilters(filters) {
  return {
    type: SET_ADMIN_ORGANISATIONS_FILTER,
    payload: { filters },
  };
}
export function clearOrgFilters() {
  return {
    type: CLEAR_ADMIN_ORGANISATIONS_FILTER,
  };
}



export function fetchOrgBundles(id, params) {
  return (dispatch) => {
    dispatch(fetchOrgBundlesBegin());
    return adminApi
      .fetchOrgBundles(id, params)
      .then((json) => {
        dispatch(fetchOrgBundlesSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(fetchOrgBundlesFailure(error));
      });
  };
}

export const fetchOrgBundlesBegin = () => ({
  type: FETCH_ORG_BUNDLES_BEGIN,
});

export const fetchOrgBundlesSuccess = (json) => ({
  type: FETCH_ORG_BUNDLES_SUCCESS,
  payload: {
    products: json.data.bundles,
    pagination: json.data.pagination,
    filters: json.data.filters,
    organisation: json.data.organisation,
  },
});

export const fetchOrgBundlesFailure = (error) => ({
  type: FETCH_ORG_BUNDLES_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function fetchOrgInvoices(id, params) {
  return (dispatch) => {
    dispatch(fetchOrgInvoicesBegin());
    return adminApi
      .fetchOrgInvoices(id, params)
      .then((json) => {
        dispatch(fetchOrgInvoicesSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(fetchOrgInvoicesFailure(error));
      });
  };
}

export const fetchOrgInvoicesBegin = () => ({
  type: FETCH_ORG_INVOICES_BEGIN,
});

export const fetchOrgInvoicesSuccess = (json) => ({
  type: FETCH_ORG_INVOICES_SUCCESS,
  payload: {
    invoices: json.data.invoices,
    pagination: json.data.pagination,
    filters: json.data.filters,
    organisation: json.data.organisation,
  },
});

export const fetchOrgInvoicesFailure = (error) => ({
  type: FETCH_ORG_INVOICES_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

/*--------------------Ordered Quantities----------------------------*/
export function fetchOrderedQuantities(id, params) {
  return (dispatch) => {
    dispatch(fetchOrderedQuantitiesBegin()); //calling this action to show loading
    return adminApi
      .fetchOrderedQuantities(id, params) // calling list of materials
      .then((json) => {
        dispatch(fetchOrderedQuantitiesSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log("error", error);

        dispatch(fetchOrderedQuantitiesFailure(error));
      });
  };
}

export const fetchOrderedQuantitiesBegin = () => ({
  type: FETCH_ORDERED_QUANTITIES_BEGIN,
});

export const fetchOrderedQuantitiesSuccess = (json) => ({
  type: FETCH_ORDERED_QUANTITIES_SUCCESS,
  data: json.data,
});
export const fetchOrderedQuantitiesFailure = (error) => ({
  type: FETCH_ORDERED_QUANTITIES_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function clearOrderedQuantities() {
  return {
    type: FETCH_ORDERED_QUANTITIES_ORGANISATIONS,
  };
}
export function setOrderedQuantityFilters(filters) {
  return {
    type: SET_ORDERED_QUANTITIES_FILTER,
    payload: { filters },
  };
}
export function clearOrderedQuantityFilters() {
  return {
    type: CLEAR_ORDERED_QUANTITIES_FILTER,
  };
}

/*--------------------Suppliers----------------------------*/
export function fetchOrgSuppliers(id, params) {
  return (dispatch) => {
    dispatch(fetchOrgSuppliersBegin()); //calling this action to show loading
    return adminApi
      .fetchOrgSuppliers(id, params) // calling list of materials
      .then((json) => {
        dispatch(fetchOrgSuppliersSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log("error", error);

        dispatch(fetchOrgSuppliersFailure(error));
      });
  };
}
export const fetchOrgSuppliersBegin = () =>
  // the whole object is n action
  ({
    type: FETCH_ORG_SUPPLIERS_BEGIN,
  });

export const fetchOrgSuppliersSuccess = (json) => ({
  type: FETCH_ORG_SUPPLIERS_SUCCESS,
  data: json.data,
});
export const fetchOrgSuppliersFailure = (error) => ({
  type: FETCH_ORG_SUPPLIERS_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

/*-------------csv-------------------*/

export function downloadSKUTmpl(id, is_create) {
  return (dispatch) => {
    dispatch(downloadSKUTmplBegin());
    return adminApi
      .downloadSKUTmpl(id, is_create)
      .then((res) => {
        dispatch(downloadSKUTmplSuccess(res));
        return res;
      })
      .catch((error) => {
        dispatch(downloadSKUTmplFailure(error));
      });
  };
}
export const downloadSKUTmplBegin = () => ({
  type: DOWNLOAD_SKU_TMPL_BEGIN,
});

export const downloadSKUTmplSuccess = (res) => ({
  type: DOWNLOAD_SKU_TMPL_SUCCESS,
  payload: {
    template: res,
  },
});

export const downloadSKUTmplFailure = (error) => ({
  type: DOWNLOAD_SKU_TMPL_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function uploadSKUTmpl(id, params, is_create) {
  return (dispatch) => {
    dispatch(uploadSKUTmplBegin());
    return adminApi
      .uploadSKUTmpl(id, params, is_create)
      .then((json) => {
        dispatch(uploadSKUTmplSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(uploadSKUTmplFailure(error));
      });
  };
}
export const uploadSKUTmplBegin = () => ({
  type: UPLOAD_SKU_TMPL_BEGIN,
});

export const uploadSKUTmplSuccess = (json) => ({
  type: UPLOAD_SKU_TMPL_SUCCESS,
  payload: {
    message: json.message,
  },
});

export const uploadSKUTmplFailure = (error) => ({
  type: UPLOAD_SKU_TMPL_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function downloadBundleTmpl(id) {
  return (dispatch) => {
    dispatch(downloadBundleTmplBegin());
    return adminApi
      .downloadBundleTmpl(id)
      .then((res) => {
        dispatch(downloadBundleTmplSuccess(res));
        return res;
      })
      .catch((error) => {
        dispatch(downloadBundleTmplFailure(error));
      });
  };
}
export const downloadBundleTmplBegin = () => ({
  type: DOWNLOAD_BUNDLE_TMPL_BEGIN,
});

export const downloadBundleTmplSuccess = (res) => ({
  type: DOWNLOAD_BUNDLE_TMPL_SUCCESS,
  payload: {
    template: res,
  },
});

export const downloadBundleTmplFailure = (error) => ({
  type: DOWNLOAD_BUNDLE_TMPL_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function uploadBundleTmpl(id, params) {
  return (dispatch) => {
    dispatch(uploadBundleTmplBegin());
    return adminApi
      .uploadBundleTmpl(id, params)
      .then((json) => {
        dispatch(uploadBundleTmplSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(uploadBundleTmplFailure(error));
      });
  };
}
export const uploadBundleTmplBegin = () => ({
  type: UPLOAD_BUNDLE_TMPL_BEGIN,
});

export const uploadBundleTmplSuccess = (json) => ({
  type: UPLOAD_BUNDLE_TMPL_SUCCESS,
  payload: {
    template: json.data,
  },
});

export const uploadBundleTmplFailure = (error) => ({
  type: UPLOAD_BUNDLE_TMPL_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function downloadBundleCompTmpl(id) {
  return (dispatch) => {
    dispatch(downloadBundleCompTmplBegin());
    return adminApi
      .downloadBundleCompTmpl(id)
      .then((res) => {
        dispatch(downloadBundleCompTmplSuccess(res));
        return res;
      })
      .catch((error) => {
        dispatch(downloadBundleCompTmplFailure(error));
      });
  };
}
export const downloadBundleCompTmplBegin = () => ({
  type: DOWNLOAD_BUNDLE_COMP_TMPL_BEGIN,
});

export const downloadBundleCompTmplSuccess = (res) => ({
  type: DOWNLOAD_BUNDLE_COMP_TMPL_SUCCESS,
  payload: {
    template: res,
  },
});

export const downloadBundleCompTmplFailure = (error) => ({
  type: DOWNLOAD_BUNDLE_COMP_TMPL_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function uploadBundleCompTmpl(id, params) {
  return (dispatch) => {
    dispatch(uploadBundleCompTmplBegin());
    return adminApi
      .uploadBundleCompTmpl(id, params)
      .then((json) => {
        dispatch(uploadBundleCompTmplSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(uploadBundleCompTmplFailure(error));
      });
  };
}
export const uploadBundleCompTmplBegin = () => ({
  type: UPLOAD_BUNDLE_COMP_TMPL_BEGIN,
});

export const uploadBundleCompTmplSuccess = (json) => ({
  type: UPLOAD_BUNDLE_COMP_TMPL_SUCCESS,
  payload: {
    template: json.data,
  },
});

export const uploadBundleCompTmplFailure = (error) => ({
  type: UPLOAD_BUNDLE_COMP_TMPL_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const clearTmpls = () => ({
  type: CLEAR_TMPLS,
});

// Private methods

function handleErrors(response) {
  if (!response.success) {
    throw Error(response.statusText);
  }
  return response;
}

export const fetchAdminOrganisationHistoryBegin = () => ({
  type: FETCH_ADMIN_ORGANISATION_HISTORY_BEGIN,
});

export const fetchAdminOrganisationHistorySuccess = (json) => ({
  type: FETCH_ADMIN_ORGANISATION_HISTORY_SUCCESS,
  payload: json.data,
});

export const fetchAdminOrganisationHistoryFailure = (error) => ({
  type: FETCH_ADMIN_ORGANISATION_HISTORY_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function fetchAdminOrganisationHistory(orgId, params) {
  return (dispatch) => {
    dispatch(fetchAdminOrganisationHistoryBegin());
    return adminApi
      .fetchOrganisationHistory(orgId, params)
      .then(handleErrors)
      .then((json) => {
        dispatch(fetchAdminOrganisationHistorySuccess(json));
        return json;
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchAdminOrganisationHistoryFailure(error));
      });
  };
}
