import {
  FETCH_ADMIN_ORGANISATIONS_BEGIN,
  FETCH_ADMIN_ORGANISATIONS_SUCCESS,
  FETCH_ADMIN_ORGANISATIONS_FAILURE,
  CREATE_ADMIN_ORGANISATION_BEGIN,
  CREATE_ADMIN_ORGANISATION_SUCCESS,
  CREATE_ADMIN_ORGANISATION_FAILURE,
  GET_ADMIN_ORGANISATION_BY_ID_BEGIN,
  GET_ADMIN_ORGANISATION_BY_ID_SUCCESS,
  GET_ADMIN_ORGANISATION_BY_ID_FAILURE,
  UPDATE_ADMIN_ORGANISATION_SUCCESS,
  UPDATE_ADMIN_ORGANISATION_FAILURE,
  DELETE_ADMIN_ORGANISATION_SUCCESS,
  DELETE_ADMIN_ORGANISATION_FAILURE,
  CLEAR_ADMIN_ORGANISATIONS,
  UPDATE_ADMIN_ORGANISATIONS_BEGIN,
  CLEAR_ADMIN_ORGANISATIONS_FILTER,
  SET_ADMIN_ORGANISATIONS_FILTER,
  FETCH_ORDERED_QUANTITIES_BEGIN,
  FETCH_ORDERED_QUANTITIES_SUCCESS,
  FETCH_ORDERED_QUANTITIES_FAILURE,
  DOWNLOAD_SKU_TMPL_BEGIN,
  DOWNLOAD_SKU_TMPL_SUCCESS,
  DOWNLOAD_SKU_TMPL_FAILURE,
  UPLOAD_SKU_TMPL_BEGIN,
  UPLOAD_SKU_TMPL_SUCCESS,
  UPLOAD_SKU_TMPL_FAILURE,
  DOWNLOAD_BUNDLE_TMPL_BEGIN,
  DOWNLOAD_BUNDLE_TMPL_SUCCESS,
  DOWNLOAD_BUNDLE_TMPL_FAILURE,
  UPLOAD_BUNDLE_TMPL_BEGIN,
  UPLOAD_BUNDLE_TMPL_SUCCESS,
  UPLOAD_BUNDLE_TMPL_FAILURE,
  DOWNLOAD_BUNDLE_COMP_TMPL_BEGIN,
  DOWNLOAD_BUNDLE_COMP_TMPL_SUCCESS,
  DOWNLOAD_BUNDLE_COMP_TMPL_FAILURE,
  UPLOAD_BUNDLE_COMP_TMPL_BEGIN,
  UPLOAD_BUNDLE_COMP_TMPL_SUCCESS,
  UPLOAD_BUNDLE_COMP_TMPL_FAILURE,
  CLEAR_TMPLS,
  FETCH_ADMIN_ORGANISATION_HISTORY_SUCCESS,
} from "actions/admin/OrganisationsActions";

const initialState = {
  organisations: [],
  editOptions: {
    duties_terms_of_sale: [],
  },
  loading: false,
  error: null,
  notification: null,
  dummyData: [...Array(10)],
  pagination: {},
  organisationDetail: {},
  formSuccess: false,
  activeFilter: {
    page: 1,
    per_page: 25,
    active: null,
    name: null,
    owner_id: null,
    warehouse_ids: [],
    uid: null,
    email: null,
  },
  orderQuantities: [],
  orderQuantityFilter: {},
  template: null,
};

export default function adminOrganisationsReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case FETCH_ADMIN_ORGANISATIONS_BEGIN:
      return {
        ...state,
        loading: true,
        formSuccess: false,
        notification: null,
        error: null,
      };
    case FETCH_ADMIN_ORGANISATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        organisations: action.data.organisations,
        pagination: action.data.pagination,
        filters: action.data.filters,
      };
    case FETCH_ADMIN_ORGANISATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        organisations: [],
      };

    case CREATE_ADMIN_ORGANISATION_BEGIN:
      return {
        ...state,
        loading: true,
        notification: null,
        error: null,
      };
    case CREATE_ADMIN_ORGANISATION_SUCCESS:
      return {
        ...state,
        loading: false,
        formSuccess: true,
      };
    case CREATE_ADMIN_ORGANISATION_FAILURE:
      return {
        ...state,
        loading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    case GET_ADMIN_ORGANISATION_BY_ID_BEGIN:
      return {
        ...state,
        loading: true,
        notification: null,
        error: null,
      };

    case GET_ADMIN_ORGANISATION_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        organisationDetail: action.payload.data,
        editOptions: action.payload.editOtps,
      };

    case GET_ADMIN_ORGANISATION_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_ADMIN_ORGANISATIONS_BEGIN:
      return {
        ...state,
        loading: true,
        formSuccess: false,
        notification: null,
        error: null,
      };
    case UPDATE_ADMIN_ORGANISATION_SUCCESS:
      return {
        ...state,
        loading: false,
        formSuccess: true,
        organisationDetail: action.data,
      };
    case UPDATE_ADMIN_ORGANISATION_FAILURE:
      return {
        ...state,
        loading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    case DELETE_ADMIN_ORGANISATION_SUCCESS:
      return {
        ...state,
        formSuccess: true,
        loading: false,
        notification: action.msg,
      };
    case DELETE_ADMIN_ORGANISATION_FAILURE:
      return {
        ...state,
        loading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    case CLEAR_ADMIN_ORGANISATIONS:
      return {
        ...initialState,
      };
    case SET_ADMIN_ORGANISATIONS_FILTER:
      return {
        ...state,
        activeFilter: { ...state.activeFilter, ...action.payload.filters },
      };
    case CLEAR_ADMIN_ORGANISATIONS_FILTER:
      return {
        ...state,
        activeFilter: initialState.activeFilter,
      };

    case FETCH_ORDERED_QUANTITIES_BEGIN:
      return {
        ...state,
        loading: true,
        formSuccess: false,
        notification: null,
        error: null,
      };
    case FETCH_ORDERED_QUANTITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        orderQuantities: action.data.ordered_quantities,
        pagination: action.data.pagination,
        filters: action.data.filters,
      };
    case FETCH_ORDERED_QUANTITIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        orderQuantities: [],
      };
    case DOWNLOAD_SKU_TMPL_BEGIN:
      return {
        ...state,
        notification: "Downloading..Please Wait",
        template: null,
      };
    case DOWNLOAD_BUNDLE_TMPL_BEGIN:
      return {
        ...state,
        notification: "Downloading..Please Wait",
        template: null,
      };
    case DOWNLOAD_BUNDLE_COMP_TMPL_BEGIN:
      return {
        ...state,
        notification: "Downloading..Please Wait",
        template: null,
      };
    case DOWNLOAD_SKU_TMPL_SUCCESS:
      return {
        ...state,
        notification: null,
        template: action.payload.template,
      };
    case DOWNLOAD_BUNDLE_TMPL_SUCCESS:
      return {
        ...state,
        notification: null,
        template: action.payload.template,
      };
    case DOWNLOAD_BUNDLE_COMP_TMPL_SUCCESS:
      return {
        ...state,
        notification: null,
        template: action.payload.template,
      };
    case DOWNLOAD_SKU_TMPL_FAILURE:
      return {
        ...state,
        notification: null,
        error: action.payload.error,
      };
    case DOWNLOAD_BUNDLE_TMPL_FAILURE:
      return {
        ...state,
        notification: null,
        error: action.payload.error,
      };
    case DOWNLOAD_BUNDLE_COMP_TMPL_FAILURE:
      return {
        ...state,
        notification: null,
        error: action.payload.error,
      };
    case CLEAR_TMPLS:
      return {
        ...state,
        notification: null,
        error: null,
        template: null,
      };
    case UPLOAD_SKU_TMPL_BEGIN:
      return {
        ...state,
        notification: "Uploading..Please Wait",
      };
    case UPLOAD_BUNDLE_TMPL_BEGIN:
      return {
        ...state,
        notification: "Uploading..Please Wait",
      };
    case UPLOAD_BUNDLE_COMP_TMPL_BEGIN:
      return {
        ...state,
        notification: "Uploading..Please Wait",
      };
    case UPLOAD_SKU_TMPL_SUCCESS:
      return {
        ...state,
        notification: action.payload.message,
      };
    case UPLOAD_BUNDLE_TMPL_SUCCESS:
      return {
        ...state,
        notification: "File uploaded",
      };
    case UPLOAD_BUNDLE_COMP_TMPL_SUCCESS:
      return {
        ...state,
        notification: "File uploaded",
      };
    case UPLOAD_SKU_TMPL_FAILURE:
      return {
        ...state,
        notification: null,
        error: action.payload.error,
      };
    case UPLOAD_BUNDLE_TMPL_FAILURE:
      return {
        ...state,
        notification: null,
        error: action.payload.error,
      };
    case UPLOAD_BUNDLE_COMP_TMPL_FAILURE:
      return {
        ...state,
        notification: null,
        error: action.payload.error,
      };
    case FETCH_ADMIN_ORGANISATION_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        organisationDetail: action.payload.organisation,
      };

    default:
      return state;
  }
}
