import React from "react";
// Redux
import {useSelector} from "react-redux";
import {
  ListItem,
  ListItemText,
  Button,
  TextField,
  makeStyles,
  Card,
  Typography
} from "@material-ui/core";

// Project Components
import OrderItem from "components/OrderItem/OrderItem";

// Local Components
import SKUDialog from "./SKUDialog";
import FlexGrow from "components/Layout/FlexGrow";

const Products = (props) => {
  const {
    pendingOrderSKUs,
    quantityVariation,
    addBookingItems,
    orderType,
    pricingModal,
    formState
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [totalProducts, setTotalProducts] = React.useState(0);

  const cardTitle =
    orderType === "inbound" ? "What's arriving?" : "What's going out?";

  const handleSkuOpen = () => setOpen(true);

  React.useEffect(() => {
    var newTotalProductsCount = 0;
    pendingOrderSKUs.forEach((booking_item) => {
      newTotalProductsCount += booking_item.quantity;
    });
    setTotalProducts(newTotalProductsCount);
  }, [pendingOrderSKUs]);

  return (
    <div>
      <Card className={classes.card}>
        {/* children of CardWithTitle in Fragment <> ... </> */}
        <>

          <div className={classes.header}>


            <div className={classes.title}>{cardTitle}</div>
            <Button onClick={() => handleSkuOpen()}
                    variant="contained"
                    color="primary"
            >
              Add SKU to order
            </Button>

          </div>
          {pendingOrderSKUs && pendingOrderSKUs.map((line) => (<>
              {line.item && <OrderItem
                key={line.item.id}
                className={classes.listItem}
                line={line}
                deleteItemFunction={() =>
                  quantityVariation(line.item.id, -line.quantity)
                }
                quantityChangeCallback={(event) => {
                  let qtyVar;
                  if (
                    parseInt(event.target.value) === 0 ||
                    isNaN(parseInt(event.target.value))
                  )
                    qtyVar = -line.quantity + 1;
                  else qtyVar = parseInt(event.target.value) - line.quantity;
                  quantityVariation(line.item.id, qtyVar);
                }}
              />}</>
          ))}

          {
            (totalProducts > 0) ? <ListItem className={classes.gutter}>
              <ListItemText> </ListItemText>
              <TextField
                id="outlined-read-only-input"
                label="Total Products"
                value={totalProducts}
                className={classes.textField}
                inputProps={{
                  readOnly: true,
                  style: {
                    textAlign: "right",
                    padding: "8px 14px",
                    width: 140,
                  },
                }}
                variant="outlined"
              />
            </ListItem> : null
          }

          <SKUDialog
            open={open}
            setOpen={setOpen}
            handleEditOrder={addBookingItems}
          />
        </>
      </Card>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(3),
    // height: '74px',
    marginBottom: theme.spacing(3),
  },
  pointer: {
    cursor: "pointer",
  },
  button: {
    marginLeft: "1148px",
    marginTop: "-79px"
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '21px'
  },
  title: {
    fontSize: '16px',
    fontWeight: 500
  },
  gutter: {
    paddingLeft: '16px',
    paddingRight: '22px',
    paddingBottom: '22px'
  }
}));

export default Products;
