import adminApi from "services/admin/adminApi";
import { fetchPickTiers } from "./ratesActions";

export const FETCH_AC_SHIPPERS_BEGIN = "FETCH_AC_SHIPPERS_BEGIN";
export const FETCH_AC_SHIPPERS_SUCCESS = "FETCH_AC_SHIPPERS_SUCCESS";
export const FETCH_AC_SHIPPERS_FAILURE = "FETCH_AC_SHIPPERS_FAILURE";

/*----------------packing--------------------------*/
export const FETCH_TIERS_BEGIN = "FETCH_TIERS_BEGIN";
export const FETCH_TIERS_SUCCESS = "FETCH_TIERS_SUCCESS";
export const FETCH_TIERS_FAILURE = "FETCH_TIERS_FAILURE";

export const DELETE_PACKING = "DELETE_PACKING";
export const DELETE_PACKING_SUCCESS = "DELETE_PACKING_SUCCESS";
export const DELETE_PACKING_FAILURE = "DELETE_PACKING_FAILURE";

export const TEST_REPORT_BEGIN = "TEST_REPORT_BEGIN";
export const TEST_REPORT_SUCCESS = "TEST_REPORT_SUCCESS";
export const TEST_REPORT_FAILURE = "TEST_REPORT_FAILURE";

export const RUN_REPORT_BEGIN = "RUN_REPORT_BEGIN";
export const RUN_REPORT_SUCCESS = "RUN_REPORT_SUCCESS";
export const RUN_REPORT_FAILURE = "RUN_REPORT_FAILURE";

/*----------------Zones-----------------------*/
export const FETCH_ZONES_BEGIN = "FETCH_ZONES_BEGIN";
export const FETCH_ZONES_SUCCESS = "FETCH_ZONES_SUCCESS";
export const FETCH_ZONES_FAILURE = "FETCH_ZONES_FAILURE";

export const DELETE_ZONES = "DELETE_ZONES";
export const DELETE_ZONES_SUCCESS = "DELETE_ZONES_SUCCESS";
export const DELETE_ZONES_FAILURE = "DELETE_ZONES_FAILURE";

export const FETCH_ZONES_BY_ID_BEGIN = "FETCH_ZONES_BY_ID_BEGIN";
export const FETCH_ZONES_BY_ID_SUCCESS = "FETCH_ZONES_BY_ID_SUCCESS";
export const FETCH_ZONES_BY_ID_FAILURE = "FETCH_ZONES_BY_ID_FAILURE";

export const CREATE_ZONES_BEGIN = "CREATE_ZONES_BEGIN";
export const CREATE_ZONES_SUCCESS = "CREATE_ZONES_SUCCESS";
export const CREATE_ZONES_FAILURE = "CREATE_ZONES_FAILURE";

export const UPDATE_ZONES_BEGIN = "UPDATE_ZONES_BEGIN";
export const UPDATE_ZONES_SUCCESS = "UPDATE_ZONES_SUCCESS";
export const UPDATE_ZONES_FAILURE = "UPDATE_ZONES_FAILURE";

export const FETCH_COUNTRY_SUCCESS = "FETCH_COUNTRY_SUCCESS";
export const FETCH_COUNTRY_FAILURE = "FETCH_COUNTRY_FAILURE";

/*----------------ShipperServicePrices-----------------------*/
export const FETCH_SHIPPERSERVICE_PRICES_BEGIN =
  "FETCH_SHIPPERSERVICE_PRICES_BEGIN";
export const FETCH_SHIPPERSERVICE_PRICES_SUCCESS =
  "FETCH_SHIPPERSERVICE_PRICES_SUCCESS";
export const FETCH_SHIPPERSERVICE_PRICES_FAILURE =
  "FETCH_SHIPPERSERVICE_PRICES_FAILURE";
export const CLEAR_SHIPPERSERVICE_STATE =
  "CLEAR_SHIPPERSERVICE_STATE";

export const DELETE_SHIPPERSERVICE_PRICES_ = "DELETE_SHIPPERSERVICE_PRICES_";
export const DELETE_SHIPPERSERVICE_PRICES_SUCCESS =
  "DELETE_SHIPPERSERVICE_PRICES_SUCCESS";
export const DELETE_SHIPPERSERVICE_PRICES_FAILURE =
  "DELETE_SHIPPERSERVICE_PRICES_FAILURE";

export const FETCH_SHIPPERSERVICE_PRICES_BY_ID_SUCCESS =
  "FETCH_SHIPPERSERVICE_PRICES_BY_ID_SUCCESS";
export const FETCH_SHIPPERSERVICE_PRICES_BY_ID_FAILURE =
  "FETCH_SHIPPERSERVICE_PRICES_BY_ID_FAILURE";

export const CREATE_SHIPPERSERVICE_PRICES_BEGIN =
  "CREATE_SHIPPERSERVICE_PRICES_BEGIN";
export const CREATE_SHIPPERSERVICE_PRICES_SUCCESS =
  "CREATE_SHIPPERSERVICE_PRICES_SUCCESS";
export const CREATE_SHIPPERSERVICE_PRICES_FAILURE =
  "CREATE_SHIPPERSERVICE_PRICES_FAILURE";

export const UPDATE_SHIPPERSERVICE_PRICES_BEGIN =
  "UPDATE_SHIPPERSERVICE_PRICES_BEGIN";
export const UPDATE_SHIPPERSERVICE_PRICES_SUCCESS =
  "UPDATE_SHIPPERSERVICE_PRICES_SUCCESS";
export const UPDATE_SHIPPERSERVICE_PRICES_FAILURE =
  "UPDATE_SHIPPERSERVICE_PRICES_FAILURE";
/*----------------ShipperServiceMargins-----------------------*/
export const FETCH_SHIPPERSERVICE_MARGINS_BEGIN =
  "FETCH_SHIPPERSERVICE_MARGINS_BEGIN";
export const FETCH_SHIPPERSERVICE_MARGINS_SUCCESS =
  "FETCH_SHIPPERSERVICE_MARGINS_SUCCESS";
export const FETCH_SHIPPERSERVICE_MARGINS_FAILURE =
  "FETCH_SHIPPERSERVICE_MARGINS_FAILURE";

export const DELETE_SHIPPERSERVICE_MARGINS = "DELETE_SHIPPERSERVICE_MARGINS";
export const DELETE_SHIPPERSERVICE_MARGINS_SUCCESS =
  "DELETE_SHIPPERSERVICE_MARGINS_SUCCESS";
export const DELETE_SHIPPERSERVICE_MARGINS_FAILURE =
  "DELETE_SHIPPERSERVICE_MARGINS_FAILURE";

export const FETCH_SHIPPERSERVICE_MARGINS_BY_ID_SUCCESS =
  "FETCH_SHIPPERSERVICE_MARGINS_BY_ID_SUCCESS";
export const FETCH_SHIPPERSERVICE_MARGINS_BY_ID_FAILURE =
  "FETCH_SHIPPERSERVICE_MARGINS_BY_ID_FAILURE";

export const CREATE_SHIPPERSERVICE_MARGINS_BEGIN =
  "CREATE_SHIPPERSERVICE_MARGINS_BEGIN";
export const CREATE_SHIPPERSERVICE_MARGINS_SUCCESS =
  "CREATE_SHIPPERSERVICE_MARGINS_SUCCESS";
export const CREATE_SHIPPERSERVICE_MARGINS_FAILURE =
  "CREATE_SHIPPERSERVICE_MARGINS_FAILURE";

export const UPDATE_SHIPPERSERVICE_MARGINS_BEGIN =
  "UPDATE_SHIPPERSERVICE_MARGINS_BEGIN";
export const UPDATE_SHIPPERSERVICE_MARGINS_SUCCESS =
  "UPDATE_SHIPPERSERVICE_MARGINS_SUCCESS";
export const UPDATE_SHIPPERSERVICE_MARGINS_FAILURE =
  "UPDATE_SHIPPERSERVICE_MARGINS_FAILURE";

/*----------------Picking-----------------------*/
export const FETCH_PICKING_BEGIN = "FETCH_PICKING_BEGIN";
export const FETCH_PICKING_SUCCESS = "FETCH_PICKING_SUCCESS";
export const FETCH_PICKING_FAILURE = "FETCH_PICKING_FAILURE";

export const DELETE_PICKING = "DELETE_PICKING";
export const DELETE_PICKING_SUCCESS = "DELETE_PICKING_SUCCESS";
export const DELETE_PICKING_FAILURE = "DELETE_PICKING_FAILURE";

export const FETCH_PICKING_BY_RANGE_SUCCESS = "FETCH_PICKING_BY_RANGE_SUCCESS";
export const FETCH_PICKING_BY_ID_FAILURE = "FETCH_PICKING_BY_ID_FAILURE";

export const CREATE_PICKING_BEGIN = "CREATE_PICKING_BEGIN";
export const CREATE_PICKING_SUCCESS = "CREATE_PICKING_SUCCESS";
export const CREATE_PICKING_FAILURE = "CREATE_PICKING_FAILURE";

export const UPDATE_PICKING_BEGIN = "UPDATE_PICKING_BEGIN";
export const UPDATE_PICKING_SUCCESS = "UPDATE_PICKING_SUCCESS";
export const UPDATE_PICKING_FAILURE = "UPDATE_PICKING_FAILURE";

export const CLEAR_AC_SHIPPERS = "CLEAR_AC_SHIPPERS";
export const CLEAR_ZONES = "CLEAR_ZONES";
export const CLEAR_SHIPPING_NOTIFICATION = "CLEAR_SHIPPING_NOTIFICATION";

/*-------------------- - Shipping----------------------------*/
export function fetchAccShippers(wid) {
  return (dispatch) => {
    dispatch(fetchShippersBegin()); //calling this action to show loading
    return adminApi
      .fetchAccShippers(wid) // calling list of materials
      .then((json) => {
        dispatch(fetchShippersSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log("error", error);

        dispatch(fetchShippersFailure(error));
      });
  };
}

export function fetchShippers(params, wid) {
  return (dispatch) => {
    dispatch(fetchShippersBegin()); //calling this action to show loading
    return adminApi
      .fetchShippers(params, wid) // calling list of materials
      .then((json) => {
        dispatch(fetchShippersSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log("error", error);

        dispatch(fetchShippersFailure(error));
      });
  };
}

export function clearACShippers() {
  return {
    type: CLEAR_AC_SHIPPERS,
  };
}

export function clearZones() {
  return {
    type: CLEAR_ZONES,
  };
}

export function clearShippingNotifications() {
  return {
    type: CLEAR_SHIPPING_NOTIFICATION,
  };
}

export const fetchShippersBegin = () => ({
  type: FETCH_AC_SHIPPERS_BEGIN,
});

export const fetchShippersSuccess = (json) => ({
  type: FETCH_AC_SHIPPERS_SUCCESS,
  data: json.data,
});
export const fetchShippersFailure = (error) => ({
  type: FETCH_AC_SHIPPERS_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

/*--------------------Tiers----------------------------*/
export function fetchTiers(id, wid) {
  return (dispatch) => {
    dispatch(fetchTiersBegin()); //calling this action to show loading
    return adminApi
      .fetchTiers(id, wid) // calling list of packing
      .then((json) => {
        dispatch(fetchTiersSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log("error", error);

        dispatch(fetchTiersFailure(error));
      });
  };
}

export function runReport(data, id, wid) {
  return (dispatch) => {
    dispatch(runReportBegin());
    let res = adminApi
      .runReport(data, id, wid)
      .then((json) => {
        dispatch(runReportSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(runReportFailure(error));
      });
    return res;
  };
}

export const fetchTiersBegin = () =>
  // the whole object is n action
  ({
    type: FETCH_TIERS_BEGIN,
  });

export const fetchTiersSuccess = (json) => ({
  type: FETCH_TIERS_SUCCESS,
  data: json.data,
});

export const fetchTiersFailure = (error) => ({
  type: FETCH_TIERS_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function runReportBegin() {
  return {
    type: RUN_REPORT_BEGIN,
  };
}

export function runReportSuccess(json) {
  return {
    type: RUN_REPORT_SUCCESS,
    payload: json.data,
  };
}

export function runReportFailure(error) {
  return {
    type: RUN_REPORT_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function testReport(data, id, wid) {
  return (dispatch) => {
    dispatch(testReportBegin());
    let res = adminApi
      .testReport(data, id, wid)
      .then((json) => {
        dispatch(testReportSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(testReportFailure(error));
      });
    return res;
  };
}

export function testReportBegin() {
  return {
    type: TEST_REPORT_BEGIN,
  };
}

export function testReportSuccess(json) {
  return {
    type: TEST_REPORT_SUCCESS,
    payload: json.data,
  };
}

export function testReportFailure(error) {
  return {
    type: TEST_REPORT_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

/*-----------Zones-------------------*/
export function fetchZones(id, wid) {
  return (dispatch) => {
    dispatch(fetchZonesBegin()); //calling this action to show loading
    return adminApi
      .fetchZones(id, wid) // calling list of packing
      .then((json) => {
        dispatch(fetchZonesSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log("error", error);

        dispatch(fetchZonesFailure(error));
      });
  };
}

export function fetchZonesByID(shipperId, id, wid) {
  return (dispatch) => {
    dispatch(fetchZonesByIDBegin()); //calling this action to show loading
    return adminApi
      .getZonesDetail(shipperId, id, wid)
      .then(handleErrors)
      .then((json) => {
        dispatch(fetchZonesByIDSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchZonesByIDFailure(error));
      });
  };
}

export function updateZones(shipperId, id, data, wid) {
  return (dispatch) => {
    dispatch(updateZonesBegin());
    let res = adminApi
      .updateZone(shipperId, id, data, wid)
      .then((json) => {
        dispatch(updateZonesSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(updateZonesFailure(error));
      });
    return res;
  };
}

export const fetchZonesBegin = () =>
  // the whole object is n action
  ({
    type: FETCH_ZONES_BEGIN,
  });

export const fetchZonesSuccess = (json) => ({
  type: FETCH_ZONES_SUCCESS,
  data: json.data,
});

export const fetchZonesFailure = (error) => ({
  type: FETCH_ZONES_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const fetchZonesByIDBegin = () =>
  // the whole object is n action
  ({
    type: FETCH_ZONES_BEGIN,
  });

export const fetchZonesByIDSuccess = (json) => ({
  type: FETCH_ZONES_BY_ID_SUCCESS,
  payload: json.data.shipper_zone,
});

export const fetchZonesByIDFailure = (error) => ({
  type: FETCH_ZONES_BY_ID_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
  msg: "An error occurred, please try again later",
});

export function updateZonesBegin() {
  return {
    type: UPDATE_ZONES_BEGIN,
  };
}

export function updateZonesSuccess(json) {
  return {
    type: UPDATE_ZONES_SUCCESS,
    payload: json.data.shipper_zone,
  };
}

export function updateZonesFailure(error) {
  return {
    type: UPDATE_ZONES_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function createZones(shipperId, data, wid) {
  return (dispatch) => {
    dispatch(createZonesBegin());
    let res = adminApi
      .createZones(shipperId, data, wid)
      .then((json) => {
        dispatch(createZonesSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(createZonesFailure(error));
      });
    return res;
  };
}

export function createZonesBegin() {
  return {
    type: CREATE_ZONES_BEGIN,
  };
}

export function createZonesSuccess(json) {
  return {
    type: CREATE_ZONES_SUCCESS,
    payload: {
      zones: json.data.shipper_zone,
    },
  };
}

export function createZonesFailure(error) {
  return {
    type: CREATE_ZONES_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

/*-----------ShipperServicePrices-------------------*/
export function fetchShipperServicePrices(id, wid) {
  return (dispatch) => {
    dispatch(fetchShipperServicePricesBegin()); //calling this action to show loading
    return adminApi
      .fetchShipperServicePrices(id, wid) // calling list of packing
      .then((json) => {
        dispatch(fetchShipperServicePricesSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log("error", error);

        dispatch(fetchShipperServicePricesFailure(error));
      });
  };
}

export function updateShipperServicePrices(wid, sid, data) {
  return (dispatch) => {
    dispatch(updateShipperServicePricesBegin());
    let res = adminApi
      .updateShipperServicePrices(wid, sid, data)
      .then((json) => {
        dispatch(updateShipperServicePricesSuccess(json));
        dispatch(fetchShipperServicePrices(sid, wid));
        return json;
      })
      .catch((error) => {
        dispatch(updateShipperServicePricesFailure(error));
      });
    return res;
  };
}

export const fetchShipperServicePricesBegin = () =>
  // the whole object is n action
  ({
    type: FETCH_SHIPPERSERVICE_PRICES_BEGIN,
  });

export const fetchShipperServicePricesSuccess = (json) => ({
  type: FETCH_SHIPPERSERVICE_PRICES_SUCCESS,
  data: json.data,
});



export const fetchShipperServicePricesFailure = (error) => ({
  type: FETCH_SHIPPERSERVICE_PRICES_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function updateShipperServicePricesBegin() {
  return {
    type: UPDATE_SHIPPERSERVICE_PRICES_BEGIN,
  };
}

export function updateShipperServicePricesSuccess(json) {
  return {
    type: UPDATE_SHIPPERSERVICE_PRICES_SUCCESS,
    payload: json.data,
  };
}

export function updateShipperServicePricesFailure(error) {
  return {
    type: UPDATE_SHIPPERSERVICE_PRICES_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function createShipperServicePrices(data, wid) {
  return (dispatch) => {
    dispatch(createShipperServicePricesBegin());
    let res = adminApi
      .createShipperServicePrices(data, wid)
      .then((json) => {
        dispatch(createShipperServicePricesSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(createShipperServicePricesFailure(error));
      });
    return res;
  };
}

export function createShipperServicePricesBegin() {
  return {
    type: CREATE_SHIPPERSERVICE_PRICES_BEGIN,
  };
}

export function createShipperServicePricesSuccess(json) {
  return {
    type: CREATE_SHIPPERSERVICE_PRICES_SUCCESS,
    payload: {
      administration: json.data.administration_cost,
    },
  };
}

export function createShipperServicePricesFailure(error) {
  return {
    type: CREATE_SHIPPERSERVICE_PRICES_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}
export const clearShippersState = () =>({
  type: CLEAR_SHIPPERSERVICE_STATE,

})


/*-----------ShipperServiceMargins-------------------*/

export function fetchShipperServiceMargins(id, data, level, wid) {
  return (dispatch) => {
    dispatch(fetchShipperServiceMarginsBegin()); //calling this action to show loading
    return adminApi
      .fetchShipperServiceMargins(id, data, wid) // calling list of packing
      .then((json) => {
        dispatch(fetchShipperServiceMarginsSuccess(json, level));
        return json;
      })
      .catch((error) => {
        console.log("error", error);

        dispatch(fetchShipperServiceMarginsFailure(error));
      });
  };
}

export function deleteShipperServiceMargins(id, wid) {
  return (dispatch) => {
    return adminApi
      .deleteShipperServiceMargins(id, wid)
      .then((json) => {
        dispatch(deleteShipperServiceMarginsSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log(error);
        dispatch(deleteShipperServiceMarginsFailure(error));
      });
  };
}

export function fetchShipperServiceMarginsByID(id, wid) {
  return (dispatch) => {
    return adminApi
      .getShipperServiceMarginsDetail(id, wid)
      .then(handleErrors)
      .then((json) => {
        dispatch(fetchShipperServiceMarginsByIDSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchShipperServiceMarginsByIDFailure(error));
      });
  };
}

export function updateShipperServiceMargins(wid, sid, data) {
  return (dispatch) => {
    dispatch(updateShipperServiceMarginsBegin());
    let res = adminApi
      .updateShipperServiceMargins(wid, sid, data)
      .then((json) => {
        dispatch(updateShipperServiceMarginsSuccess(json));
        dispatch(fetchShipperServicePrices(sid, wid));
        return json;
      })
      .catch((error) => {
        dispatch(updateShipperServiceMarginsFailure(error));
      });
    return res;
  };
}

export const fetchShipperServiceMarginsBegin = () =>
  // the whole object is n action
  ({
    type: FETCH_SHIPPERSERVICE_MARGINS_BEGIN,
  });

export const fetchShipperServiceMarginsSuccess = (json, level) => ({
  type: FETCH_SHIPPERSERVICE_MARGINS_SUCCESS,
  data: json.data,
  level: level,
});

export const fetchShipperServiceMarginsFailure = (error) => ({
  type: FETCH_SHIPPERSERVICE_MARGINS_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const deleteShipperServiceMarginsSuccess = (json) => ({
  type: DELETE_SHIPPERSERVICE_MARGINS_SUCCESS,
  data: json.data.inbound_type,
  msg: json.message,
});

export const deleteShipperServiceMarginsFailure = (error) => ({
  type: DELETE_SHIPPERSERVICE_MARGINS_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
  msg: "An error occurred, please try again later",
});

export const fetchShipperServiceMarginsByIDSuccess = (json) => ({
  type: FETCH_SHIPPERSERVICE_MARGINS_BY_ID_SUCCESS,
  payload: json.data.inbound_type,
});

export const fetchShipperServiceMarginsByIDFailure = (error) => ({
  type: FETCH_SHIPPERSERVICE_MARGINS_BY_ID_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
  msg: "An error occurred, please try again later",
});

export function updateShipperServiceMarginsBegin() {
  return {
    type: UPDATE_SHIPPERSERVICE_MARGINS_BEGIN,
  };
}

export function updateShipperServiceMarginsSuccess(json) {
  return {
    type: UPDATE_SHIPPERSERVICE_MARGINS_SUCCESS,
    payload: json.data,
  };
}

export function updateShipperServiceMarginsFailure(error) {
  return {
    type: UPDATE_SHIPPERSERVICE_MARGINS_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function createShipperServiceMargins(data, wid) {
  return (dispatch) => {
    dispatch(createShipperServiceMarginsBegin());
    let res = adminApi
      .createShipperServiceMargins(data, wid)
      .then((json) => {
        dispatch(createShipperServiceMarginsSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(createShipperServiceMarginsFailure(error));
      });
    return res;
  };
}

export function createShipperServiceMarginsBegin() {
  return {
    type: CREATE_SHIPPERSERVICE_MARGINS_BEGIN,
  };
}

export function createShipperServiceMarginsSuccess(json) {
  return {
    type: CREATE_SHIPPERSERVICE_MARGINS_SUCCESS,
    payload: {
      inbound: json.data.inbound_type,
    },
  };
}

export function createShipperServiceMarginsFailure(error) {
  return {
    type: CREATE_SHIPPERSERVICE_MARGINS_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

/*-----------Picking-------------------*/
export function fetchPicking(wid, noLoading = false) {
  return (dispatch) => {
    if (!noLoading) dispatch(fetchPickingBegin()); //calling this action to show loading
    return adminApi
      .fetchPicking(wid) // calling list of packing
      .then((json) => {
        dispatch(fetchPickingSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(fetchPickingFailure(error));
      });
  };
}

export function deletePicking(range) {
  return (dispatch) => {
    return adminApi
      .deletePicking(range)
      .then((json) => {
        dispatch(deletePickingSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log(error);
        dispatch(deletePickingFailure(error));
      });
  };
}

export function fetchPickingByRange(range) {
  return (dispatch) => {
    return adminApi
      .getPickingDetail(range)
      .then(handleErrors)
      .then((json) => {
        dispatch(fetchPickingByRangeSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchPickingByRangeFailure(error));
      });
  };
}

export function updatePicking(data) {
  return (dispatch) => {
    dispatch(updatePickingBegin());
    let res = adminApi
      .updatePicking(data)
      .then((json) => {
        dispatch(updatePickingSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(updatePickingFailure(error));
      });
    return res;
  };
}

export const fetchPickingBegin = () =>
  // the whole object is n action
  ({
    type: FETCH_PICKING_BEGIN,
  });

export const fetchPickingSuccess = (json) => ({
  type: FETCH_PICKING_SUCCESS,
  data: json.data,
});

export const fetchPickingFailure = (error) => ({
  type: FETCH_PICKING_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const deletePickingSuccess = (json) => ({
  type: DELETE_PICKING_SUCCESS,
  data: json.data,
  msg: json.message,
});

export const deletePickingFailure = (error) => ({
  type: DELETE_PICKING_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
  msg: "An error occurred, please try again later",
});

export const fetchPickingByRangeSuccess = (json) => ({
  type: FETCH_PICKING_BY_RANGE_SUCCESS,
  payload: json.data,
});

export const fetchPickingByRangeFailure = (error) => ({
  type: FETCH_PICKING_BY_ID_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
  msg: "An error occurred, please try again later",
});

export function updatePickingBegin() {
  return {
    type: UPDATE_PICKING_BEGIN,
  };
}

export function updatePickingSuccess(json) {
  return {
    type: UPDATE_PICKING_SUCCESS,
    payload: json.data,
  };
}

export function updatePickingFailure(error) {
  return {
    type: UPDATE_PICKING_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function createPicking(data) {
  return (dispatch) => {
    dispatch(createPickingBegin());
    let res = adminApi
      .createPicking(data)
      .then((json) => {
        dispatch(createPickingSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(createPickingFailure(error));
      });
    return res;
  };
}

export function createPickingBegin() {
  return {
    type: CREATE_PICKING_BEGIN,
  };
}

export function createPickingSuccess(json) {
  return {
    type: CREATE_PICKING_SUCCESS,
    payload: {
      picking: json.data.pick,
    },
  };
}

export function createPickingFailure(error) {
  return {
    type: CREATE_PICKING_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function fetchCountryByName(serchterm) {
  return (dispatch) => {
    let res = adminApi
      .fetchCountryByName(serchterm)
      .then((json) => {
        dispatch(fetchCountryByNameSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(fetchCountryByNameFailure(error));
      });
    return res;
  };
}

export function fetchCountryByNameSuccess(json) {
  return {
    type: FETCH_COUNTRY_SUCCESS,

    countries: json.results,
  };
}

export function fetchCountryByNameFailure(error) {
  return {
    type: FETCH_COUNTRY_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

// Private methods

function handleErrors(response) {
  if (!response.success) {
    throw Error(response.statusText);
  }
  return response;
}

function handlePackingMessageErrors(response) {
  if (!response.success) {
    throw Error(response.statusText);
  }
  return response;
}
