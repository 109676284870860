import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

/**
 * *Small* header at the top,
 * *Big* subtitle underneath
 *
 * *Child* to the *right* (E.G. a button or something)
 */

const HeaderAndSubtitle = props => {

  const { className, header, subtitle, children, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems="flex-end"
        container
        justify="space-between"
        spacing={3}
      >
        <Grid item>
          <Typography
            component="h2"
            gutterBottom
            variant="overline"
          >
            {header}
          </Typography>
          <Typography
            component="h1"
            variant="h3"
          >
            {subtitle}
          </Typography>
        </Grid>
        <Grid item>
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

HeaderAndSubtitle.propTypes = {
  className: PropTypes.string
};

export default HeaderAndSubtitle;
