import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { Button, Tooltip } from '@material-ui/core';

const styles = theme => ({
  link: {
    textAlign: 'right'
  }
})

function AddButton(props) {
  const { classes, tooltip, link, text, disabled } = props;

  return (
    <div className={classes.link}>
      <Tooltip title={tooltip} placement="left">
        <Button
          variant="contained"
          color="primary"
          component={Link}
          aria-label={text}
          to={link}
          disabled={disabled}
        >
          {text}
        </Button>
      </Tooltip>
    </div>
  )
}

export default withStyles(styles)(AddButton);
