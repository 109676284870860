import React, { useState } from 'react'
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Divider,
  Typography,
} from '@material-ui/core';

import Dropzone from 'components/Upload/Dropzone';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import SuccessSnackbar from 'components/Help/SuccessSnackbar';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '100px'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: theme.palette['header'].main,
    '&:hover': {
      backgroundColor: theme.palette['header'].dark
    }
  },
  dropzone: {
    height: '200px',
    width: '200px',
    backgroundColor: '#fff',
    border: '2px dashed rgb(187,186,186)',
    borderRadius: '4%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '16px',
    margin: '8px',
    marginTop: 0,
  },
  dropzoneHighlight: {
    height: '200px',
    width: '200px',
    backgroundColor: 'rgb(188,185,236)',
    border: '2px dashed rgb(187,186,186)',
    borderRadius: '4%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '16px',
    margin: '8px',
    marginTop: 0,
  },
  icon: {
    opacity: 0.3,
    height: '64px',
    width: '64px',
  },
  imagePreview: {
    height: '164px',
    width: '164px',
    opacity: 0.7,
    borderRadius: '4%'
  },
  fileInput: {
    display: 'none',
  }
}));

const GeneralSettings = props => {
  const { title, className, error, values, handleSubmit, onImageUpload, handleChange, permissions, action, ...rest } = props;

  const classes = useStyles();

  const fields =
    [
      { value: 'name', label: 'Company name', required: ['required'], errorMessages: ['is required'] },
      { value: 'preferred_company_name', label: 'Preferred Company Name', required: [], errorMessages: [], hint: "This name will be used in email communication with your customers" },
      { value: 'web_url', label: 'Website', required: ['required'], errorMessages: ['is required'] },
      { value: 'email', label: 'Contact Email', required: ['required', 'isEmail'], errorMessages: ['is required', 'please use a valid email'] },
      { value: 'phone', label: 'Contact Phone', required: ['required'], errorMessages: ['is required'] },
      { value: 'address_line_1', label: 'Address Line 1', required: ['required'], errorMessages: ['is required'] },
      { value: 'address_line_2', label: 'Address Line 2', required: [], errorMessages: [] },
      { value: 'postcode', label: 'Postcode', required: ['required'], errorMessages: ['is required'] },
      { value: 'city', label: 'Suburb/City', required: ['required'], errorMessages: ['is required'] },
      { value: 'state', label: 'State', required: ['required'], errorMessages: ['is required'] },
      { value: 'country', label: 'Country', required: ['required'], errorMessages: ['is required'] },
      { value: 'abn', label: 'ABN', required: [], errorMessages: [] }
    ]

  let inputRef = React.createRef("form");
  const [errImgName, setErrImgName] = useState(null);
  const handleSnackbarClose = () => {
    setErrImgName(null);
  };
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <ValidatorForm
        ref={inputRef}
        onSubmit={handleSubmit}
      >
        <CardHeader title={title} />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={4}
          >
            {fields.map((field) => (
              <Grid
                key={field.value}
                item
                md={6}
                xs={12}
              >
                <TextValidator
                  fullWidth
                  label={field.label}
                  name={field.value}
                  onChange={handleChange}
                  value={values[field.value] || ""}
                  variant="outlined"
                  error={error ? Boolean(error[field.value]) : false}
                  helperText={error ? error[field.value] : false}
                  validators={field.required}
                  errorMessages={field.errorMessages}
                  disabled={!permissions}
                />
                {(field.hint) && <Typography style={{marginTop: '5px',paddingLeft:'12px' }} variant="subtitle2">{field.hint}</Typography >}
              </Grid>
            ))}
            <Grid item xs={12} sm={12}>
              <Typography style={{ margin: '8px' }}>Company logo</Typography>
              <div className={classes.imageUploadZone}>
                <div className={classes.imageFileList}>
                  <Dropzone
                    onFilesAdded={onImageUpload}
                    accept="image/png,image/jpg,image/jpeg"
                    preview={values.preview}
                    disabled={!permissions}
                    errImgName={setErrImgName}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            className={classes.saveButton}
            type="submit"
            variant="contained"
            disabled={!permissions}
          >
            {action === "Create" ? "Create" : "Save Changes"}
          </Button>
        </CardActions>
      </ValidatorForm>
      <SuccessSnackbar
        open={Boolean(errImgName)}
        error={`Select only PNG, JPG or JPEG file`}
        onClose={handleSnackbarClose}
      />
    </Card>
  );
};

GeneralSettings.propTypes = {
  className: PropTypes.string,
};

export default GeneralSettings;
