import adminApi from 'services/admin/adminApi';

export const FETCH_BATCHES_BEGIN = 'FETCH_BATCHES_BEGIN';
export const FETCH_BATCHES_SUCCESS = 'FETCH_BATCHES_SUCCESS';
export const FETCH_BATCHES_FILTERS_SUCCESS = 'FETCH_BATCHES_FILTERS_SUCCESS';
export const FETCH_BATCHES_FAILURE = 'FETCH_BATCHES_FAILURE';

export const DELETE_BATCHES = 'DELETE_BATCHES';
export const DELETE_BATCHES_SUCCESS = 'DELETE_BATCHES_SUCCESS';
export const DELETE_BATCHES_FAILURE = 'DELETE_BATCHES_FAILURE';

export const FETCH_BATCHES_BY_ID_SUCCESS = 'FETCH_BATCHES_BY_ID_SUCCESS';
export const FETCH_BATCHES_BY_ID_FAILURE = 'FETCH_BATCHES_BY_ID_FAILURE';

export const UPDATE_BATCHES_BEGIN = 'UPDATE_BATCHES_BEGIN';
export const UPDATE_BATCHES_SUCCESS = 'UPDATE_BATCHES_SUCCESS';
export const UPDATE_BATCHES_FAILURE = 'UPDATE_BATCHES_FAILURE';

export const FETCH_ITEMS_BEGIN = 'FETCH_ITEMS_BEGIN';
export const FETCH_ITEMS_SUCCESS = 'FETCH_ITEMS_SUCCESS';
export const FETCH_ITEMS_FAILURE = 'FETCH_ITEMS_FAILURE';

// filter
export const UPDATE_BATCH_FILTER = 'UPDATE_BATCH_FILTER';
export const UPDATE_BATCH_UID_FILTER = 'UPDATE_BATCH_UID_FILTER';
export const UPDATE_TROLLEY_FILTER = 'UPDATE_TROLLEY_FILTER';
export const UPDATE_STATUS_FILTER = 'UPDATE_STATUS_FILTER';
export const UPDATE_SEARCH_VALUE = 'UPDATE_SEARCH_VALUE';
export const UPDATE_LEVEL_FILTER = 'UPDATE_SEARCH_VALUE';

//add New Batch
export const CREATE_BATCH_BEGIN = 'CREATE_BATCH_BEGIN';
export const CREATE_BATCH_SUCCESS = 'CREATE_BATCH_SUCCESS';
export const CREATE_BATCH_FAILURE = 'CREATE_BATCH_FAILURE';

/*................choose trolley......*/
export const FETCH_TROLLEYS_BEGIN = 'FETCH_TROLLEYS_BEGIN';
export const FETCH_TROLLEYS_SUCCESS = 'FETCH_TROLLEYS_SUCCESS';
export const FETCH_TROLLEYS_FAILURE = 'FETCH_TROLLEYS_FAILURE';

/*..............Add trolley to batch.....*/

export const CREATE_BATCH_TROLLEY_BEGIN = 'CREATE_BATCH_TROLLEY_BEGIN';
export const CREATE_BATCH_TROLLEY_SUCCESS = 'CREATE_BATCH_TROLLEY_SUCCESS';
export const CREATE_BATCH_TROLLEY_FAILURE = 'CREATE_BATCH_TROLLEY_FAILURE';
/*................ List Orders......................*/

export const FETCH_ORDER_BEGIN = 'FETCH_ORDER_BEGIN';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAILURE = 'FETCH_ORDER_FAILURE';

// filter
export const UPDATE_ORDER_FILTER = 'UPDATE_ORDER_FILTER';
export const UPDATE_ORGANISATION_FILTER = 'UPDATE_ORGANISATION_FILTER';
export const UPDATE_USER_FILTER = 'UPDATE_USER_FILTER';
export const UPDATE_INTERNATIONAL_FILTER = 'UPDATE_INTERNATIONAL_FILTER';
export const UPDATE_SHIPPING_FILTER = 'UPDATE_SHIPPING_FILTER';
export const UPDATE_CARRIER_FILTER = 'UPDATE_CARRIER_FILTER';
export const UPDATE_SHIPPINGMETHOD_FILTER = 'UPDATE_SHIPPINGMETHOD_FILTER';
export const UPDATE_MAXVOLUME_FILTER = 'UPDATE_MAXVOLUME_FILTER';
export const UPDATE_FROMDATE_FILTER = 'UPDATE_FROMDATE_FILTER';
export const UPDATE_TODATE_FILTER = 'UPDATE_TODATE_FILTER';

/*............update Stock info.............*/

export const UPDATE_STOCK_INFO_BEGIN = 'UPDATE_STOCK_INFO_BEGIN';
export const UPDATE_STOCK_INFO_SUCCESS = 'UPDATE_STOCK_INFO_SUCCESS';
export const UPDATE_STOCK_INFO_FAILURE = 'UPDATE_STOCK_INFO_FAILURE';

/*..............Add bookings to trolley.........*/

export const SELECT_ORDER_BEGIN = 'SELECT_ORDER_BEGIN';
export const SELECT_ORDER_SUCCESS = 'SELECT_ORDER_SUCCESS';
export const SELECT_ORDER_FAILURE = 'SELECT_ORDER_FAILURE';

/*.............slots.............*/
export const FETCH_SLOTS_BEGIN = 'FETCH_SLOTS_BEGIN';
export const FETCH_SLOTS_SUCCESS = 'FETCH_SLOTS_SUCCESS';
export const FETCH_SLOTS_FAILURE = 'FETCH_SLOTS_FAILURE';

/*.............Display created batch with slots and orders.....*/
export const FETCH_CREATED_BATCH_BEGIN = 'FETCH_CREATED_BATCH_BEGIN';
export const FETCH_CREATED_BATCH_SUCCESS = 'FETCH_CREATED_BATCH_SUCCESS';
export const FETCH_CREATED_BATCH_FAILURE = 'FETCH_CREATED_BATCH_FAILURE';

/*.............update picker/packer/status.............*/

export const UPDATE_STATUSES_SUCCESS = 'UPDATE_STATUSES_SUCCESS';
export const UPDATE_STATUSES_FAILURE = 'UPDATE_STATUSES_FAILURE';

/* .........list all pickerPackers.......*/
export const FETCH_PICKER_PACKER_BEGIN = 'FETCH_PICKER_PACKER_BEGIN';
export const FETCH_PICKER_PACKER_SUCCESS = 'FETCH_PICKER_PACKER_SUCCESS';
export const FETCH_PICKER_PACKER_FAILURE = 'FETCH_PICKER_PACKER_FAILURE';

export const UPDATE_PACKER_STATUS = 'UPDATE_PACKER_STATUS';
export const UPDATE_PICKER_STATUS = 'UPDATE_PICKER_STATUS';
export const UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS';


export const UPDATE_SELECTED_ORDERS = 'UPDATE_SELECTED_ORDERS';

export const CLEAR_BATCHES = 'CLEAR_BATCHES';


export const fetchBatches = (filters) => async (dispatch) => {

  try {
    if (!filters) {
      filters = `per_page=25&page=1`;
    }
    const response = await adminApi.fetchBatches(filters);
    const data = (response) ? response.data : null;
    if (!!data && !!response.success) {
      return {
        success: true,
        error: null,
        loading: false,
        batches: data.batches,
        pagination: data.pagination,
        filters: data.filters,
      };

    } else {
      return { success: false, loading: false, error: data || 'Something went wrong. Please try again' };
    }
  } catch (e) {
    let error = 'An error occurred, please try again later';
    console.log(e);

    if (e.response) {
      error = e.response.data;
    }
    return { success: false, loading: false, error: error };

  }

};
/*
export function fetchBatchesFilters() {
    return dispatch => {
        dispatch(fetchBatchesBegin());//calling this action to show loading

        return adminApi.fetchBatchesFilters()// calling list of batches
            .then(json => {

                dispatch(fetchBatchesFiltersSuccess(json));
                return json;
            })
            .catch(error => {
                console.log('error', error);
                dispatch(fetchBatchesFailure(error))
            });
    };
}*/

export const createBatch = (batch) => async (dispatch) => {

  try {

    const response = await adminApi.createBatch(batch);
    const data = (response) ? response.data : null;
    if (!!data && !!response.success) {
      return { success: true, error: null, loading: false, createdBatch: data.batch };

    } else {
      return { success: false, loading: false, error: data || 'Something went wrong. Please try again' };
    }
  } catch (e) {
    let error = 'An error occurred, please try again later';
    console.log(e);

    if (e.response) {
      error = e.response.data;
    }
    return { success: false, loading: false, error: error };

  }

};
export const fetchTrolleys = (batch_id) => async (dispatch) => {
  try {

    const response = await adminApi.fetchAvailableTrolleys(batch_id);
    const data = (response) ? response.data : null;
    if (!!data && !!response.success) {
      return { success: true, error: null, loading: false, trolleys: data.trolleys };

    } else {
      return { success: false, loading: false, error: data || 'Something went wrong. Please try again' };
    }
  } catch (e) {
    let error = 'An error occurred, please try again later';
    console.log(e);

    if (e.response) {
      error = e.response.data;
    }
    return { success: false, loading: false, error: error };

  }
};
export const createBatchTrolley = (batchTrolley) => async (dispatch) => {
  try {

    const response = await adminApi.createBatchTrolley(batchTrolley);
    const data = (response) ? response.data : null;
    if (!!data && !!response.success) {
      return { success: true, error: null, loading: false, batchedTrolley: data.batch_trolley, formSuccess: true };

    } else {
      return { success: false, loading: false, error: data || 'Something went wrong. Please try again' };
    }
  } catch (e) {
    let error = 'An error occurred, please try again later';
    console.log(e);

    if (e.response) {
      error = e.response.data;
    }
    return { success: false, loading: false, error: error };

  }
};
export const fetchOrder = (filters) => async (dispatch) => {
  try {
    const response = await adminApi.fetchBatchBookings(filters);
    const data = (response) ? response.data : null;
    if (!!data && !!response.success) {
      const bookings = data.bookings.map(function (el) {
        let o = Object.assign({}, el);
        o.isChecked = el?.is_checked || false;
        return o;
      });
      return {
        success: true, error: null,
        loading: false,
        bookings: bookings,
        pagination: data.pagination,
        orderFilters: data.filters,
      };

    } else {
      return { success: false, loading: false, error: data || 'Something went wrong. Please try again' };
    }
  } catch (e) {
    let error = 'An error occurred, please try again later';
    console.log(e);

    if (e.response) {
      error = e.response.data;
    }
    return { success: false, loading: false, error: error };
  }

};


export const updateStockInfo = (stockInfo) => async (dispatch) => {

  try {
    const response = await adminApi.updateStockInfo(stockInfo);
    const data = (response) ? response.data : null;
    if (!!data && !!response.success) {

      return { success: true, loading: false, stockInfo: data.batch_trolley, error: null };

    } else {
      return { success: false, loading: false, error: data || 'Something went wrong. Please try again' };
    }
  } catch (e) {
    let error = 'An error occurred, please try again later';
    console.log(e);

    if (e.response) {
      error = e.response.data;
    }
    return { success: false, loading: false, error: error };
  }
};


export const updateSelectedOrders = (orderId, isChecked, bookings) => async (dispatch) => {
  try {
    const bookingArr = bookings.map(function (booking) {

      if (booking.id === orderId) {
        return {
          ...booking,
          isChecked: isChecked,
        };
      }
      return booking;
    });


    return {
      loading: false,
      bookings: bookingArr,
    };


  } catch (e) {
    let error = 'An error occurred, please try again later';
    console.log(e);

    if (e.response) {
      error = e.response.data;
    }
    return { success: false, loading: false, error: error };
  }

};

export const selectOrder = (batchTrolleyItem) => async (dispatch) => {
  try {
    const response = await adminApi.selectOrder(batchTrolleyItem);
    const data = (response) ? response.data : null;
    if (!!data && !!response.success) {

      return {
        success: true, error: null, loading: false, selectOrderData: data.batch_trolley, isSelected: true,
      };

    } else {
      return { success: false, loading: false, error: data || 'Something went wrong. Please try again' };
    }
  } catch (e) {
    let error = 'An error occurred, please try again later';
    console.log(e);

    if (e.response) {
      error = e.response.data;
    }
    return { success: false, loading: false, error: error };
  }
};
export const deleteSelectOrder = (batchTrolleyItem) => async (dispatch) => {

  try {
    const response = await adminApi.deleteSelectOrder(batchTrolleyItem);
    const data = (response) ? response.data : null;
    if (!!data && !!response.success) {

      return {
        success: true, error: null, loading: false, selectOrderData: data.batch_trolley, isSelected: false,
      };

    } else {
      return { success: false, loading: false, error: data || 'Something went wrong. Please try again' };
    }
  } catch (e) {
    let error = 'An error occurred, please try again later';
    console.log(e);

    if (e.response) {
      error = e.response.data;
    }
    return { success: false, loading: false, error: error };
  }
};

export const fetchSlots = (id) => async (dispatch) => {
  try {
    const response = await adminApi.fetchSlots(id);
    const data = (response) ? response.data : null;
    if (!!data && !!response.success) {

      return { success: true, loading: false, slots: data.batch_trolley };

    } else {
      return { success: false, loading: false, error: data || 'Something went wrong. Please try again' };
    }
  } catch (e) {
    let error = 'An error occurred, please try again later';
    console.log(e);

    if (e.response) {
      error = e.response.data;
    }
    return { success: false, loading: false, error: error };
  }
};

export const fetchBatchCreated = (id) => async (dispatch) => {

  try {
    const response = await adminApi.fetchBatchCreated(id);
    const data = (response) ? response.data : null;
    if (!!data && !!response.success) {
      return {
        success: true, error: null, formLoading: false,
        newBatch: data.batch,
        createdBatch: data.batch,
        filters: data.filters,
      };

    } else {
      return { success: false, formLoading: false, error: data || 'Something went wrong. Please try again' };
    }
  } catch (e) {
    let error = 'An error occurred, please try again later';
    console.log(e);

    if (e.response) {
      error = e.response.data;
    }
    return { success: false, loading: false, error: error };

  }

};

export const updateStatuses = (id, updateBatch) => async (dispatch) => {

  try {
    const response = await adminApi.updateStatuses(id, updateBatch);
    const data = (response) ? response.data : null;
    if (!!data && !!response.success) {
      return {
        success: true, error: null, loading: false,
        createdBatch: data.batch,
        newBatch: data.batch,
        notification: 'Batch updated',
      };

    } else {
      return { success: false, loading: false, error: data || 'Something went wrong. Please try again' };
    }
  } catch (e) {
    let error = 'An error occurred, please try again later';
    console.log(e);

    if (e.response) {
      error = e.response.data;
    }
    return { success: false, loading: false, error: error };

  }
};

export const fetchPickerPacker = () => async (dispatch) => {

  try {
    const response = await adminApi.fetchPickerPacker();
    const data = (response) ? response.data : null;
    if (!!data) {
      return {
        success: true, error: null, loading: false, pickerPacker: data,
      };

    } else {
      return { success: false, loading: false, error: data || 'Something went wrong. Please try again' };
    }
  } catch (e) {
    let error = 'An error occurred, please try again later';
    console.log(e);

    if (e.response) {
      error = e.response.data;
    }
    return { success: false, loading: false, error: error };

  }
};

/*
export const updateIsChecked = (orderId, isChecked) => ({
    type: UPDATE_SELECTED_ORDERS,
    data: orderId,
    isChecked: isChecked
});*/


// Private methods

function handleErrors(response) {
  if (!response.success) {
    throw Error(response.statusText);
  }
  return response;
}

function handleBatchesMessageErrors(response) {
  if (!response.success) {
    throw Error(response.statusText);
  }
  return response;
}

