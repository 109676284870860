import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core';
import OrdersTable from 'components/Table/OrdersTable';
import OrdersMenuBar from './OrdersMenuBar'
import Header from 'components/Header/Header';


class OrdersIndexAll extends PureComponent {
    render() {
        const { classes, handleClearFilters ,updateState, ...rest } = this.props;
        return (
            <div className={classes.root}>
                <Header title="Orders" subtitle="Summary" />
                <OrdersMenuBar newOrderInProgress={this.props.newOrderInProgress} handleClearFilters={handleClearFilters} updateState={updateState} {...rest} />
                <OrdersTable data={this.props.data} updateSuccess={this.props.updateSuccess} handleClearFilters={handleClearFilters} updateState={updateState} {...rest} />
            </div>
        )
    }
}

const styles = theme => ({
    root: {
    },
})

export default withStyles(styles)(OrdersIndexAll);
