import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { useDispatch, useSelector } from 'react-redux';
import { createPublicComment } from 'actions/publicCommentActions';

import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Divider,
  IconButton,
  Input,
  Paper,
  Tooltip,
  CircularProgress
} from '@material-ui/core';

import SendIcon from '@material-ui/icons/Send';
import AddPhotoIcon from '@material-ui/icons/AddPhotoAlternate';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3, 4)
  },
  paper: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    padding:theme.spacing(1, 4)
  },
  input: {
    width: '100%'
  },
  divider: {
    width: 1,
    height: 24
  },
  fileInput: {
    display: 'none'
  },
  preview: {
    fontStyle: 'italic'
  }
}));

const ConversationForm = props => {
  const dispatch = useDispatch();
  const { className, id, currentUserAvatar, ...rest } = props;

  const classes = useStyles();

  const fileInputRef = useRef(null);
  const uploading = useSelector(state => state.publicCommentsReducer.uploading);

  const [value, setValue] = useState('');
  const [image, setImage] = useState([]);
  // const [preview, setPreview] = useState('');

  React.useEffect(() => {
    setValue('')
    setImage([])
  }, [id]);

  const handleChange = event => {
    event.persist();
    setValue(event.target.value);
  }

  const handleAttach = () => {
    fileInputRef.current.click();
  };

  const handleRemove = () => {
    setImage([])
  }

  const onFilesAdded = (evt) => {
    const files = evt.target.files;
    const array = fileListToArray(files);
    onImageUpload(array);
  }

  const onImageUpload = (files) => {
    let reader = new FileReader();
    let file = files[0]
    if (file) {
      if (file.size / 1000000 > 5) {
        alert("Image must be less than 5MB");
      } else {
        setImage(files);
        reader.readAsDataURL(file);
      };
    }
  }

  const fileListToArray = (list) => {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i))
    }
    return array;
  }

  const submitComment = () => {
    if (value === '' && image.length === 0) return

    var formData = new FormData();
    formData.append('body', value)
    formData.append('resource_id', id)
    formData.append('resource_type', "Booking")
    formData.append('author_type', "User")
    formData.append('namespace', "admin")
    if (image[0]) {
      formData.append('image', image[0], image[0].name)
    }

    dispatch(createPublicComment(formData, id));
    setValue(value => '');
    setImage(image => []);
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Avatar
        alt="Person"
        src={currentUserAvatar}
      />
      <Paper
        className={classes.paper}
        elevation={1}
      >
        <Input
          className={classes.input}
          disableUnderline
          onChange={handleChange}
          placeholder="Leave a message"
          value={value}
          multiline
        />
      </Paper>
      <Tooltip title="Send" >
        {!uploading ? (
          <IconButton onClick={submitComment} type="submit" color={value.length > 0 || image.length > 0 ? 'primary' : 'default'}>
            <SendIcon />
          </IconButton>
        ) : (
          <IconButton>
            <CircularProgress />
          </IconButton>
        )}
      </Tooltip>

      <Divider className={classes.divider} />
      <Tooltip title="Attach photo max 5 MB">
        <IconButton
          edge="end"
          onClick={handleAttach}
          color={image.length > 0 ? 'primary' : 'default'}
        >
          <AddPhotoIcon />
        </IconButton>
      </Tooltip>
      <input
        className={classes.fileInput}
        ref={fileInputRef}
        type="file"
        onChange={onFilesAdded}
        accept=".jpg,.png,.pdf"
        maxSize={500}
      />
      {image.length > 0 ? (
        <div className={classes.preview} >
          {image[0].name.slice(0, 20)}
          <IconButton
            edge="end"
            onClick={handleRemove}
            color={'primary'}
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        </div>) : null}
    </div>
  );
};

ConversationForm.propTypes = {
  className: PropTypes.string
};

export default ConversationForm;
