import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Popover,
    Divider,
    colors,
    List,
    ListItem,
    ListItemText,
    Avatar
} from '@material-ui/core';

import { reverseUserRoute } from 'routes';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    root: {
        width: 200,
        maxWidth: '100%'
    },
    actions: {
        backgroundColor: colors.grey[50],
        justifyContent: 'center'
    },
    avatar: {
        width: 25,
        height: 25,
        marginRight: 10
    }
}));

const OrganisationsPopover = props => {
    const classes = useStyles();
    const { organisations, anchorEl, handleClick, ...rest } = props;

    return (
        <Popover
            {...rest}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
        >
            <div className={classes.root} onClick={props.onClose}>
                <RouterLink to={reverseUserRoute('OrganisationsList')}>
                    <List component='div' aria-label='profile tab'>
                        <ListItem button >
                            <ListItemText primary="Your Organisations" />
                        </ListItem>
                    </List>
                </RouterLink>
                <Divider />
                <List component='div' aria-label='profile tab'>
                    {organisations.map(organsiation =>
                        <ListItem button key={organsiation.id} onClick={organisation => handleClick(organsiation)}>
                              <Avatar
                                alt={organsiation.name}
                                className={classes.avatar}
                                src={organsiation.logo.url ? organsiation.logo.url : require(`assets/img/avatars/00${organsiation.id%46+1}.svg`)}
                            />
                            <ListItemText primary={organsiation.name} />
                        </ListItem>
                    )}
                </List>
                <Divider />
                <RouterLink to={reverseUserRoute('AddOrganisation')}>
                    <List component='div' aria-label='profile tab'>
                        <ListItem button >
                            <ListItemText primary="Create New Organisation" />
                        </ListItem>
                    </List>
                </RouterLink>
            
            </div>
        </Popover>
    );
};

OrganisationsPopover.propTypes = {
    anchorEl: PropTypes.any,
    className: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default OrganisationsPopover;
