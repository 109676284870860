import React, {Component} from 'react';
import {connect} from 'react-redux';

import {
  Grid,
  TextField,
  withStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from '@material-ui/core';

import {addFormDataToOrder} from 'actions/orderActions';


const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  newOrderObject: state.orderReducer.newOrderObject,
})

const styles = theme => ({
  part: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 400px)'
  },
  textField: {
    width: '100%',
    borderRadius: '4px',
    border: '1px solid rgb(127, 127, 127)',
    marginBottom: '8px',
    marginRight: '8px',
  },
  gridItem: {
    paddingRight: '8px',
  },
  shippingEstimate: {
    display: 'flex',
    position: 'absolute',
    bottom: 0,
    width: '98%',
    marginBottom: '14px',
    borderBottom: '1px solid rgb(127, 127, 127)',
  },
  flexGrow: {
    flexGrow: 1,
  },

})

const shippingMethods = ["Economy", "Standard", "Express", "Pickup"];

class P3Method extends Component {

  constructor(props) {
    super(props)
    this.state = {
      formData: {...this.props.newOrderObject},
    }
    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  onBlur(event) {
    this.props.dispatch(addFormDataToOrder(event.target.name, event.target.value))
  }

  onChange(event) {
    this.setState({
      formData: {
        ...this.state.formData,
        [event.target.name]: event.target.value
      }
    })
  }


  render() {
    const {classes} = this.props;
    return (
      <Grid container spacing={16}>

        <Grid item xs={12} sm={4}>
          <TextField
            className={classes.textField}
            //required = {formFields[field].required}
            id='parcelVolume'
            name='parcelVolume'
            label="Parcel Volume"
            fullWidth
            value={this.state.formData['parcelVolume'] ? (this.state.formData['parcelVolume']) : ('')}
            onBlur={this.onBlur}
            onChange={this.onChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">m³</InputAdornment>
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            className={classes.textField}
            //required = {formFields[field].required}
            id='totalWeight`'
            name='totalWeight'
            label="Total Weight"
            fullWidth
            value={this.state.formData['totalWeight'] ? (this.state.formData['totalWeight']) : ('')}
            InputProps={{
              endAdornment: <InputAdornment position="end">Kg</InputAdornment>
            }}
            onBlur={this.onBlur}
            onChange={this.onChange}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            className={classes.textField}
            //required = {formFields[field].required}
            id='shippingValue'
            name='shippingValue'
            label="Shipping Value"
            fullWidth
            value={this.state.formData['shippingValue'] ? (this.state.formData['shippingValue']) : ('')}
            onBlur={this.onBlur}
            onChange={this.onChange}
          />
        </Grid>

        {/* new line */}

        <Grid item xs={12} sm={6}>

          <FormControl className={classes.textField}>
            <InputLabel htmlFor='shipping_method'>Shipping Method</InputLabel>
            <Select
              value={this.state.formData['shipping_method'] ? (this.state.formData['shipping_method']) : ('')}
              id='shipping_method'
              onChange={this.onChange}
              onBlur={this.onBlur}
              inputProps={{
                name: 'shipping_method',
                id: 'shipping_method'
              }}
            >
              {shippingMethods.map(method => (
                <MenuItem key={method} value={method}>{method}</MenuItem>
              ))}
            </Select>

          </FormControl>

        </Grid>

        <Grid item xs={12} sm={6}>

          <TextField
            className={classes.textField}
            //required = {formFields[field].required}
            id='reference'
            name='reference'
            label="Order Reference Number"
            fullWidth
            value={this.state.formData['reference'] ? (this.state.formData['reference']) : ('')}
            onBlur={this.onBlur}
            onChange={this.onChange}
          />
        </Grid>

        <Grid item xs={12} sm={6}>

          <FormControl className={classes.textField}>
            <InputLabel htmlFor='shipper_id'>Recommended Shipping Carrier</InputLabel>
            <Select
              value={this.state.formData['shipper_id'] ? (this.state.formData['shipper_id']) : ('')}
              id='shipper_id'
              onChange={this.onChange}
              onBlur={this.onBlur}
              inputProps={{
                name: 'shipper_name',
                id: 'shipper_id'
              }}
            >
              {shippingMethods.map(method => (
                <MenuItem key={method} value={method}>{method}</MenuItem>
              ))}
            </Select>

          </FormControl>

        </Grid>

        <Grid item xs={12} sm={6} style={{position: 'relative'}}>
          <div className={classes.shippingEstimate}>
            <span>Shipping Estimate</span>
            <div className={classes.flexGrow}></div>
            <span>$23.45</span>
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            className={classes.textField}
            //required = {formFields[field].required}
            id='delivery_instructions'
            name='delivery_instructions'
            label="Delivery Instructions"
            fullWidth
            value={this.state.formData['delivery_instructions'] ? (this.state.formData['delivery_instructions']) : ('')}
            onBlur={this.onBlur}
            onChange={this.onChange}
          />
        </Grid>




      </Grid>
    )
  }
}

export default connect(mapStateToProps)(withStyles(styles)(P3Method));

