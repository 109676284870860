import React, {PureComponent} from 'react'

// @Material-UI components (OSS)
import {InputBase, withStyles, Grow} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import GridOnIcon from '@material-ui/icons/GridOn';
import ListIcon from '@material-ui/icons/List';
import ReactTooltip from 'react-tooltip';


const styles = theme => ({
    root: {
        width: '100%',
    },
    search: {
        display: 'flex',
        position: 'relative',
        backgroundColor: "#fff",
        // marginRight: theme.spacing(2),
        // marginLeft: 0,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderRadius: '4px',
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
        maxHeight: 40,
        // marginBottom: 5,
        margin: 5
    },
    searchIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: theme.spacing(9),
        height: '100%',
        pointerEvents: 'none',
    },
    gridViewIcon: {
        width: theme.spacing(9),
        height: '100%',
        // pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '90%',
    },
    inputInput: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '90%',
        },
    }
})

class SpaceSearch extends PureComponent {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.timer = null;
    }

    handleInputChange(event) {
        const text = event.target.value;
        this.props.onChange(text);

        if(this.timer) clearTimeout(this.timer);
        const self = this;
        this.timer = setTimeout(function () {
            self.props.fetchSearchResult(text);
        }, 300)
    }

    render() {
        const {classes} = this.props;
        return (
            <Grow in style={{flexGrow: 1}}>
                <div className={classes.search}>
                    <ReactTooltip id='list' place='top' type='dark' effect='float' />
                    <ReactTooltip id='grid' place='top' type='dark' effect='float' />
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <InputBase
                        placeholder="Search..."
                        onChange={this.handleInputChange}
                        autoFocus={true}
                        value={this.props.searchTerm}
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput
                        }}
                    />
                    <div className={classes.gridViewIcon}>
                        {this.props.isGrid ?
                            <ListIcon data-tip="View products as a list" data-for='list' onClick={this.props.swapGrid} />
                            :
                            <GridOnIcon data-tip="View products as a grid" data-for='grid' onClick={this.props.swapGrid} />
                        }

                    </div>
                </div>
            </Grow>
        );
    }
}

export default withStyles(styles)(SpaceSearch);
