import React from 'react'

import { makeStyles } from "@material-ui/core";
import { KeyboardDatePicker } from '@material-ui/pickers';
import { TextValidator as TextValidatorUnformatted } from 'react-material-ui-form-validator';


const TextValidator = (props) => {
    const classes = useStyles();

    return (
        <TextValidatorUnformatted
            {...props}
            className={classes.textValidator}
            margin="dense"
        />
    )
}

const FormField = (props) => {

    /* Any time you use a custom built form element (or a native one) you
    * can use this ref to set the width of the label to cut some room from the outline for it.
    * Without it, the outline will cross through the text of the label
    **/
    const ref = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(ref.current ? ref.current.offsetWidth : 0);
    }, []);

    return (
        <>
            {props.type ? (
                <>
                    {
                        props.type === 'date' ? (
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/DD/YYYY"
                                margin="normal"
                                required={props.required}
                                id={props.id}
                                name={props.id}
                                label={props.label}
                                value={props.value ? props.value[props.id] : ''}
                                error={props.errors ? props.errors[props.id] : false}
                                helperText={props.errors ? props.errors[props.id] : false}
                                fullWidth
                                autoComplete={props.id}
                                onChange={(date) => {
                                    let event = {
                                        target: {
                                            id: props.id,
                                            value: new Date(date.toDate())
                                        }
                                    }
                                    props.onChange(event)
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}

                            />
                        ) : (null)
                    }
                    {

                        props.type === 'native-select' ? (
                            <TextValidator
                                validators={props.required ? ['required'] : []}
                                errorMessages={props.required ? ['This field is required'] : []}
                                inputProps={{
                                    name: props.id,
                                    id: props.id
                                }}
                                fullWidth
                                value={props.value[props.id] || ''}
                                onChange={props.onChange}
                                SelectProps={{ native: true }}
                                select
                                label={props.required ? `${props.label}*` : props.label}
                                error={props.errors ? props.errors[props.id] : false}
                                helperText={props.errors ? props.errors[props.id] : false}
                            >
                                <option value="" />
                                {props.options.map(option => (
                                    <option value={option.id}>{option.title}</option>
                                ))}
                            </TextValidator>
                        ) : (null)
                    }
                </>
            ) : (
                /**
                 * If no 'type' is provided, simply return the default TextValidator
                 */
                <TextValidator
                    key={props.id}
                    required={props.required}
                    id={props.id}
                    name={props.id}
                    label={props.value.label}
                    value={props.value ? props.value[props.id] : ''}
                    error={props.errors ? props.errors[props.id] : false}
                    helperText={props.errors ? props.errors[props.id] : false}
                    autoComplete={props.id}
                    onChange={props.onChange}
                />
            )}
        </>
    )
}

const FormTextValidator = (props) => {
    const { children, formInput, state, errors, onChange, type, options } = props;
    return (
        <>
            <FormField
                onChange={onChange}
                value={state}
                errors={errors}
                type={type}
                options={options}
                {...formInput}
            />

            {children}
        </>
    )
}

const useStyles = makeStyles(theme => ({
    form: {
        padding: theme.spacing(3)
    },
    card: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    textValidator: {

    }
}))

export default FormTextValidator;
