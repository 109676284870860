import React from 'react'

export const CubeGrid = props => {

  const { showLoader, isLoading } = props;

  return (

    <div style={{
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      display: showLoader ? 'flex' : 'none',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: isLoading ? 1 : 0,
      transition: isLoading? '': 'opacity 1s ease-out, visibility 0s linear',
      background:'white',
      zIndex: 9999
    }}>

      <div className="sk-cube-grid">
        <div className="sk-cube sk-cube1"></div>
        <div className="sk-cube sk-cube2"></div>
        <div className="sk-cube sk-cube3"></div>
        <div className="sk-cube sk-cube4"></div>
        <div className="sk-cube sk-cube5"></div>
        <div className="sk-cube sk-cube6"></div>
        <div className="sk-cube sk-cube7"></div>
        <div className="sk-cube sk-cube8"></div>
        <div className="sk-cube sk-cube9"></div>
      </div>

    </div>
  )
}
