import React from "react";
import { TableRow, TableCell, Typography } from "@material-ui/core";
import { get } from "lodash";

const PricingCardRow = ({ row, order }) => {
  const showQuantity = (quantity) => (quantity ? ` x ${quantity}` : "");

  if (row.name === "shipping_price") {
    const sortedBreakdownData = order?.shipping_prices_breakdown?.sort((a, b) => b.total - a.total);

    return order?.shipping_prices_breakdown &&
      order?.shipping_prices_breakdown.length > 0 ? (
      <TableRow key={row.name}>
        <TableCell>
          <Typography variant="h5">{row.label}</Typography>
          <Typography variant="subtitle2">{row.subtitle}</Typography>
        </TableCell>
        <TableCell>
          {sortedBreakdownData?.map((breakdownItem) => (
            <Typography>
              {`${get(order, row.details)} - ${breakdownItem.title}`}
            </Typography>
          ))}
        </TableCell>
        <TableCell>
          {sortedBreakdownData?.map((breakdownItem) => (
            <Typography align="right">
              {row.prefix}
              {breakdownItem.total}
            </Typography>
          ))}
        </TableCell>
      </TableRow>
    ) : (
      <TableRow key={row.name}>
        <TableCell>
          <Typography variant="h5">{row.label}</Typography>
          <Typography variant="subtitle2">{row.subtitle}</Typography>
        </TableCell>

        <TableCell>{`${order[row.details]} ${showQuantity(
            order[row.quantity]
          )}`}</TableCell>
          <TableCell align="right">{`${row.prefix || ""} ${
            order[row.name]
          }`}</TableCell>
      </TableRow>
    );
  }

  return row.hidden ? (
    <></>
  ) : row.name !== "extra_costs" ? (
    <TableRow key={row.name}>
      <TableCell>
        <Typography variant="h5">{row.label}</Typography>
        <Typography variant="subtitle2">{row.subtitle}</Typography>
      </TableCell>

      {!row.children ? (
        <>
          <TableCell>{`${order[row.details]} ${showQuantity(
            order[row.quantity]
          )}`}</TableCell>
          <TableCell align="right">{`${row.prefix || ""} ${
            order[row.name]
          }`}</TableCell>
        </>
      ) : (
        <>
          <TableCell>
            {order[row.name].map((orderChild) => (
              <Typography>
                {row.children.map(
                  (rowChild) =>
                    `${
                      orderChild[rowChild.subcategory][rowChild.details]
                    } ${showQuantity(
                      orderChild[rowChild.subcategory][rowChild.quantity]
                    )}`
                )}
              </Typography>
            ))}
          </TableCell>
          <TableCell>
            {order[row.name].map((orderChild) => (
              <Typography align="right">
                {row.children.map(
                  (rowChild) =>
                    `${rowChild.prefix || ""} ${
                      orderChild[rowChild.subcategory][rowChild.name]
                    }`
                )}
              </Typography>
            ))}
          </TableCell>
        </>
      )}
    </TableRow>
  ) : (
    order.extra_costs.map((extra_cost) => (
      <TableRow key={row.name}>
        <TableCell>
          {row.label} - {extra_cost.description}
        </TableCell>
        <TableCell></TableCell>
        <TableCell align="right">{`${row.prefix} ${extra_cost.price}`}</TableCell>
      </TableRow>
    ))
  );
};

export default PricingCardRow;
