import adminApi from 'services/admin/adminApi';
/*----------------warehouses--------------------------*/
export const FETCH_WAREHOUSES_BEGIN = 'FETCH_WAREHOUSES_BEGIN';
export const FETCH_WAREHOUSES_SUCCESS = 'FETCH_WAREHOUSES_SUCCESS';
export const FETCH_WAREHOUSES_FAILURE = 'FETCH_WAREHOUSES_FAILURE';

export const FETCH_WAREHOUSES_BY_ID_SUCCESS = 'FETCH_WAREHOUSES_BY_ID_SUCCESS';
export const FETCH_WAREHOUSES_BY_ID_FAILURE = 'FETCH_WAREHOUSES_BY_ID_FAILURE';

export const CREATE_WAREHOUSES_BEGIN = 'CREATE_WAREHOUSES_BEGIN';
export const CREATE_WAREHOUSES_SUCCESS = 'CREATE_WAREHOUSES_SUCCESS';
export const CREATE_WAREHOUSES_FAILURE = 'CREATE_WAREHOUSES_FAILURE';

export const UPDATE_WAREHOUSES_BEGIN = 'UPDATE_WAREHOUSES_BEGIN';
export const UPDATE_WAREHOUSES_SUCCESS = 'UPDATE_WAREHOUSES_SUCCESS';
export const UPDATE_WAREHOUSES_FAILURE = 'UPDATE_WAREHOUSES_FAILURE';

export const FETCH_LOCATION_BY_ID_SUCCESS = 'FETCH_LOCATION_BY_ID_SUCCESS';
export const FETCH_LOCATION_BY_ID_FAILURE = 'FETCH_LOCATION_BY_ID_FAILURE';
export const FETCH_LOCATION_SUCCESS = 'FETCH_LOCATION_SUCCESS';
export const FETCH_LOCATION_FAILURE = 'FETCH_LOCATION_FAILURE';

export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_FAILURE = 'CREATE_LOCATION_FAILURE';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_FAILURE = 'UPDATE_LOCATION_FAILURE';
export const PRINT_BARCODES_SUCCESS = 'PRINT_BARCODES_SUCCESS';
export const PRINT_BARCODES_FAILURE = 'PRINT_BARCODES_FAILURE';
export const CLEAR_WAREHOUSES_FILTER = 'CLEAR_WAREHOUSES_FILTER';
export const CLEAR_WAREHOUSES = 'CLEAR_WAREHOUSES';
export const CLEAR_WAREHOUSES_NOTIFICATION ='CLEAR_WAREHOUSES_NOTIFICATION';

export const FETCH_WH_ZONES_BEGIN = 'FETCH_WH_ZONES_BEGIN';
export const FETCH_WH_ZONES_SUCCESS = 'FETCH_WH_ZONES_SUCCESS';
export const FETCH_WH_ZONES_FAILURE = 'FETCH_WH_ZONES_FAILURE'

export const FETCH_WH_ZONE_BY_ID_SUCCESS = 'FETCH_WH_ZONE_BY_ID_SUCCESS';
export const FETCH_WH_ZONE_BY_ID_FAILURE = 'FETCH_WH_ZONE_BY_ID_FAILURE';

export const CREATE_WH_ZONE_BEGIN = 'CREATE_WH_ZONE_BEGIN';
export const CREATE_WH_ZONE_SUCCESS = 'CREATE_WH_ZONE_SUCCESS';
export const CREATE_WH_ZONE_FAILURE = 'CREATE_WH_ZONE_FAILURE';

export const UPDATE_WH_ZONE_BEGIN = 'UPDATE_WH_ZONE_BEGIN';
export const UPDATE_WH_ZONE_SUCCESS = 'UPDATE_WH_ZONE_SUCCESS';
export const UPDATE_WH_ZONE_FAILURE = 'UPDATE_WH_ZONE_FAILURE';


export const DELETE_WH_ZONE_BEGIN = 'DELETE_WH_ZONE_BEGIN';
export const DELETE_WH_ZONE_SUCCESS = 'DELETE_WH_ZONE_SUCCESS';
export const DELETE_WH_ZONE_FAILURE = 'DELETE_WH_ZONE_FAILURE';

export const FETCH_DETAILS_FOR_WAREHOUSES_BEGIN = 'FETCH_DETAILS_FOR_WAREHOUSES_BEGIN';
export const FETCH_DETAILS_FOR_WAREHOUSES_SUCCESS = 'FETCH_DETAILS_FOR_WAREHOUSES_SUCCESS';
export const FETCH_DETAILS_FOR_WAREHOUSES_FAILURE = 'FETCH_DETAILS_FOR_WAREHOUSES_FAILURE';

export const DELETE_WAREHOUSE_BEGIN = 'DELETE_WAREHOUSE_BEGIN';
export const DELETE_WAREHOUSE_SUCCESS = 'DELETE_WAREHOUSE_SUCCESS';
export const DELETE_WAREHOUSE_FAILURE = 'DELETE_WAREHOUSE_FAILURE';

export const FETCH_TEAMS_BEGIN = 'FETCH_TEAMS_BEGIN';
export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS';
export const FETCH_TEAMS_FAILURE = 'FETCH_TEAMS_FAILURE';

export const FETCH_WH_COUNTRY_SUCCESS = 'FETCH_WH_COUNTRY_SUCCESS';
export const FETCH_WH_COUNTRY_FAILURE = 'FETCH_WH_COUNTRY_FAILURE';

export const FETCH_PRIORITY_BEGIN = 'FETCH_PRIORITY_BEGIN';
export const FETCH_PRIORITY_SUCCESS = 'FETCH_PRIORITY_SUCCESS';
export const FETCH_PRIORITY_FAILURE = 'FETCH_PRIORITY_FAILURE';

export const FETCH_PRIORITY_BY_ID_SUCCESS = 'FETCH_PRIORITY_BY_ID_SUCCESS';
export const FETCH_PRIORITY_BY_ID_FAILURE = 'FETCH_PRIORITY_BY_ID_FAILURE';

export const UPDATE_PRIORITY_BEGIN = 'UPDATE_PRIORITY_BEGIN';
export const UPDATE_PRIORITY_SUCCESS = 'UPDATE_PRIORITY_SUCCESS';
export const UPDATE_PRIORITY_FAILURE = 'UPDATE_PRIORITY_FAILURE';
/*--------------------Warehouses----------------------------*/
export function fetchWarehouse(params) {
    return dispatch => {
        dispatch(fetchWarehouseBegin());//calling this action to show loading
        return adminApi.fetchWarehouse(params)// calling list of warehouses
            .then(json => {

                dispatch(fetchWarehouseSuccess(json));
                return json;
            })
            .catch(error => {
                console.log('error', error);

                dispatch(fetchWarehouseFailure(error))

            });
    };
}

export function fetchWarehouseByID(id) {
    return dispatch => {
        dispatch(fetchWarehouseBegin());//calling this action to show loading
        return adminApi.getWarehousesDetail(id)
            .then(handleErrors)
            .then(json => {
                dispatch(fetchWarehouseByIDSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(fetchWarehouseByIDFailure(error))
            });
    }
}

export function updateWarehouse(id, data) {
    return dispatch => {
        dispatch(updateWarehouseBegin());
        let res = adminApi.updateWarehouse(id, data)
            .then(json => {
                dispatch(updateWarehouseSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(updateWarehouseFailure(error));

            })
        return res;
    }
}

export const fetchWarehouseBegin = () => (
    // the whole object is n action
    {
        type: FETCH_WAREHOUSES_BEGIN
    });

export const fetchWarehouseSuccess = json => ({
    type: FETCH_WAREHOUSES_SUCCESS,
    data: json.data.warehouses,
    filter: json.data.filters,
    pagination: json.data.pagination,
});
export const fetchWarehouseFailure = error => ({
    type: FETCH_WAREHOUSES_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});

export const fetchWarehouseByIDSuccess = json => ({
    type: FETCH_WAREHOUSES_BY_ID_SUCCESS,
    payload: json.data.warehouse
});

export const fetchWarehouseByIDFailure = error => ({
    type: FETCH_WAREHOUSES_BY_ID_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'},
    msg: "An error occurred, please try again later"
});

export function updateWarehouseBegin() {
    return {
        type: UPDATE_WAREHOUSES_BEGIN
    }
}

export function updateWarehouseSuccess(json) {
    return {
        type: UPDATE_WAREHOUSES_SUCCESS,
        payload: json.data.warehouse

    }
}

export function updateWarehouseFailure(error) {
    return {
        type: UPDATE_WAREHOUSES_FAILURE,
        payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
    }
}

export function createWarehouse(data) {
    return dispatch => {
        dispatch(createWarehouseBegin());
        let res = adminApi.createWarehouse(data)
            .then(json => {
                dispatch(createWarehouseSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(createWarehouseFailure(error));

            })
        return res;
    }

}

export function createWarehouseBegin() {
    return {
        type: CREATE_WAREHOUSES_BEGIN,
    }
}


export function createWarehouseSuccess(json) {
    return {
        type: CREATE_WAREHOUSES_SUCCESS,
        payload: {
            warehouse: json.data.warehouse
        }
    }
}


export function createWarehouseFailure(error) {
    return {
        type: CREATE_WAREHOUSES_FAILURE,
        payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
    }
}

export function getDataToAddWarehouse() {
    return dispatch => {
        dispatch(getDataToAddWarehouseBegin());
        let res = adminApi.getDataToAddWarehouse()
            .then(json => {
                dispatch(getDataToAddWarehouseSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(getDataToAddWarehouseFailure(error));

            })
        return res;
    }

}

export function getDataToAddWarehouseBegin() {
    return {
        type: FETCH_DETAILS_FOR_WAREHOUSES_BEGIN,
    }
}


export function getDataToAddWarehouseSuccess(json) {
    return {
        type: FETCH_DETAILS_FOR_WAREHOUSES_SUCCESS,
        payload: {
            users: json.data.admin_users
        }
    }
}


export function getDataToAddWarehouseFailure(error) {
    return {
        type: FETCH_DETAILS_FOR_WAREHOUSES_FAILURE,
        payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
    }
}


export function deleteWarehouse(id) {
    return dispatch => {
        dispatch(deleteWarehouseBegin());//calling this action to show loading
        return adminApi.deleteWarehouse(id)
            .then(handleErrors)
            .then(json => {
                dispatch(deleteWarehouseSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(deleteWarehouseFailure(error))
            });
    }
}

export function deleteWarehouseBegin() {
    return {
        type:DELETE_WAREHOUSE_BEGIN,
    }
}


export function deleteWarehouseSuccess(json) {
    return {
        type: DELETE_WAREHOUSE_SUCCESS,
        payload: {
            users: json.data.admin_users
        }
    }
}


export function deleteWarehouseFailure(error) {
    return {
        type: DELETE_WAREHOUSE_FAILURE,
        payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
    }
}


export function fetchLocation(id,params) {
    return dispatch => {
        dispatch(fetchWarehouseBegin());//calling this action to show loading
        return adminApi.getLocations(id,params)// calling list of warehouses
            .then(json => {

                dispatch(fetchLocationsSuccess(json, params));
                return json;
            })
            .catch(error => {
                console.log('error', error);

                dispatch(fetchLocationsFailure(error))

            });
    };
}

export const fetchLocationsSuccess = (json, params) => ({
    type: FETCH_LOCATION_SUCCESS,
    data: json.data.locations,
    filter: params,
    pagination: json.data.pagination,
});

export const fetchLocationsFailure = error => ({
    type: FETCH_LOCATION_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});

export function createLocation(id,data) {
    return dispatch => {
        dispatch(createWarehouseBegin());
        let res = adminApi.createLocation(id,data)
            .then(json => {
                dispatch(createLocationSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(createLocationFailure(error));

            })
        return res;
    }

}
export function createLocationSuccess(json) {
    return {
        type: CREATE_LOCATION_SUCCESS,
        payload: json.data.location

    }
}
export function createLocationFailure(error) {
    return {
        type: CREATE_LOCATION_FAILURE,
        payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
    }
}
export function updateLocation(wid,id, data) {
    return dispatch => {
        dispatch(updateWarehouseBegin());
        let res = adminApi.updateLocation(wid,id, data)
            .then(json => {
                dispatch(updateLocationSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(updateLocationFailure(error));

            })
        return res;
    }
}

export function updateLocationSuccess(json) {
    return {
        type: UPDATE_LOCATION_SUCCESS,
        payload: json.data.location

    }
}

export function updateLocationFailure(error) {
    return {
        type: UPDATE_LOCATION_FAILURE,
        payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
    }
}

export function fetchLocationByID(wid,id) {
    return dispatch => {
        dispatch(fetchWarehouseBegin());//calling this action to show loading
        return adminApi.getLocationDetail(wid,id)
            .then(handleErrors)
            .then(json => {
                dispatch(fetchLocationByIDSuccess(json));
                return json;
            })
            .catch(error => {

                dispatch(fetchLocationByIDFailure(error));
            });
    }
}
export const fetchLocationByIDSuccess = json => ({
    type: FETCH_LOCATION_BY_ID_SUCCESS,
    payload: json.data.location
});

export const fetchLocationByIDFailure = error => ({
    type: FETCH_LOCATION_BY_ID_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'},
    msg: "An error occurred, please try again later"
});


export function printBarcodes(wid,data) {
    return dispatch => {
        dispatch(createWarehouseBegin());
        let res = adminApi.printBarcodes(wid,data)
            .then(json => {
                dispatch(printBarcodesSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(printBarcodesFailure(error));

            })
        return res;
    }

}
export const printBarcodesSuccess = json => ({
    type: PRINT_BARCODES_SUCCESS,
    payload: json
});

export const printBarcodesFailure = error => ({
    type: PRINT_BARCODES_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'},
    msg: "An error occurred, please try again later"
});


export function clearWarehouse() {
    return {
        type: CLEAR_WAREHOUSES
    }
}

export function clearNotification() {
    return {
        type: CLEAR_WAREHOUSES_NOTIFICATION
    }
}


/*-------------------------Zones-------------------------*/
export function fetchZones(params) {
    return dispatch => {
        dispatch(fetchZonesBegin());//calling this action to show loading
        return adminApi.fetchWHZones(params)// calling list of zones
            .then(json => {

                dispatch(fetchZonesSuccess(json));
                return json;
            })
            .catch(error => {
                console.log('error', error);

                dispatch(fetchZonesFailure(error))

            });
    };
}

export function fetchZoneByID(id) {
    return dispatch => {
        dispatch(fetchZonesBegin());//calling this action to show loading
        return adminApi.getWHZonesDetail(id)
            .then(handleErrors)
            .then(json => {
                dispatch(fetchZoneByIDSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(fetchZoneByIDFailure(error))
            });
    }
}

export function updateZone(id, data) {
    return dispatch => {
        dispatch(updateZoneBegin());
        let res = adminApi.updateWHZone(id, data)
            .then(json => {
                dispatch(updateZoneSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(updateZoneFailure(error));

            })
        return res;
    }
}

export const fetchZonesBegin = () => (
    // the whole object is n action
    {
        type: FETCH_WH_ZONES_BEGIN
    });

export const fetchZonesSuccess = json => ({
    type: FETCH_WH_ZONES_SUCCESS,
    data: json.data.zones,
});
export const fetchZonesFailure = error => ({
    type: FETCH_WH_ZONES_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});

export const fetchZoneByIDSuccess = json => ({
    type: FETCH_WH_ZONE_BY_ID_SUCCESS,
    payload: json.data.zone
});

export const fetchZoneByIDFailure = error => ({
    type: FETCH_WH_ZONE_BY_ID_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'},
    msg: "An error occurred, please try again later"
});

export function updateZoneBegin() {
    return {
        type: UPDATE_WH_ZONE_BEGIN
    }
}

export function updateZoneSuccess(json) {
    return {
        type: UPDATE_WH_ZONE_SUCCESS,
        payload: json.data.zone

    }
}

export function updateZoneFailure(error) {
    return {
        type: UPDATE_WH_ZONE_FAILURE,
        payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
    }
}

export function createZone(data) {
    return dispatch => {
        dispatch(createZoneBegin());
        let res = adminApi.createWHZone(data)
            .then(json => {
                dispatch(createZoneSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(createZoneFailure(error));

            })
        return res;
    }

}

export function createZoneBegin() {
    return {
        type: CREATE_WH_ZONE_BEGIN,
    }
}


export function createZoneSuccess(json) {
    return {
        type: CREATE_WH_ZONE_SUCCESS,
        payload: {
            zone: json.data.zone
        }
    }
}


export function createZoneFailure(error) {
    return {
        type: CREATE_WH_ZONE_FAILURE,
        payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
    }
}



export function deleteZone(id) {
    return dispatch => {
        dispatch(deleteZoneBegin());
        let res = adminApi.deleteWHZone(id)
            .then(json => {
                dispatch(deleteZoneSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(deleteZoneFailure(error));

            })
        return res;
    }

}

export function deleteZoneBegin() {
    return {
        type: DELETE_WH_ZONE_BEGIN,
    }
}


export function deleteZoneSuccess(json) {
    return {
        type: DELETE_WH_ZONE_SUCCESS,
        payload: {
            zone: json.data.zone
        }
    }
}


export function deleteZoneFailure(error) {
    return {
        type: DELETE_WH_ZONE_FAILURE,
        payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
    }
}


export function fetchTeams(wid) {
    return dispatch => {
        dispatch(fetchTeamsBegin());
        let res = adminApi.fetchTeams(wid)
            .then(json => {
                dispatch(fetchTeamsSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(fetchTeamsFailure(error));

            })
        return res;
    }

}

export function fetchTeamsBegin() {
    return {
        type:FETCH_TEAMS_BEGIN,
    }
}


export function fetchTeamsSuccess(json) {
    return {
        type: FETCH_TEAMS_SUCCESS,
        payload: {
            team_users: json.data.team_users
        }
    }
}


export function fetchTeamsFailure(error) {
    return {
        type: FETCH_TEAMS_FAILURE,
        payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
    }
}

export function fetchCountryByName(serchterm) {
    return dispatch => {
        let res = adminApi.fetchCountryByName(serchterm)
            .then(json => {
                dispatch(fetchCountryByNameSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(fetchCountryByNameFailure(error));
            })
        return res;
    }
}

export function fetchCountryByNameSuccess(json) {
    return {
        type: FETCH_WH_COUNTRY_SUCCESS,

        countries: json.results

    }
}


export function fetchCountryByNameFailure(error) {
    return {
        type: FETCH_WH_COUNTRY_FAILURE,
        payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
    }
}



export function fetchPriority(params) {
	return dispatch => {
		dispatch(fetchPriorityBegin());//calling this action to show loading
		return adminApi.fetchPriority(params)// calling list of warehouses
			.then(json => {

				dispatch(fetchPrioritySuccess(json));
				return json;
			})
			.catch(error => {
				console.log('error', error);

				dispatch(fetchPriorityFailure(error))

			});
	};
}

export function fetchPriorityByID(id) {
	return dispatch => {
		dispatch(fetchPriorityBegin());//calling this action to show loading
		return adminApi.getPrioritysDetail(id)
			.then(handleErrors)
			.then(json => {
				dispatch(fetchPriorityByIDSuccess(json));
				return json;
			})
			.catch(error => {
				console.log(error)
				dispatch(fetchPriorityByIDFailure(error))
			});
	}
}

export function updatePriority(id, data) {
	return dispatch => {
		dispatch(updatePriorityBegin());
		let res = adminApi.updatePriority(id, data)
			.then(json => {
				dispatch(updatePrioritySuccess(json));
				return json;
			})
			.catch(error => {
				dispatch(updatePriorityFailure(error));

			})
		return res;
	}
}

export const fetchPriorityBegin = () => (
	// the whole object is n action
	{
		type: FETCH_PRIORITY_BEGIN
	});

export const fetchPrioritySuccess = json => ({
	type: FETCH_PRIORITY_SUCCESS,
	data: json.data.zone_warehouses
});
export const fetchPriorityFailure = error => ({
	type: FETCH_PRIORITY_FAILURE,
	payload: { error: (error.response) ? error.response.data : 'Something went wrong. Please try again' }
});

export const fetchPriorityByIDSuccess = json => ({
	type: FETCH_PRIORITY_BY_ID_SUCCESS,
	payload: json.data.zone_warehouse
});

export const fetchPriorityByIDFailure = error => ({
	type: FETCH_PRIORITY_BY_ID_FAILURE,
	payload: { error: (error.response) ? error.response.data : 'Something went wrong. Please try again' },
	msg: "An error occurred, please try again later"
});

export function updatePriorityBegin() {
	return {
		type: UPDATE_PRIORITY_BEGIN
	}
}

export function updatePrioritySuccess(json) {
	return {
		type: UPDATE_PRIORITY_SUCCESS,
		payload: json.data.zone_warehouse

	}
}

export function updatePriorityFailure(error) {
	return {
		type: UPDATE_PRIORITY_FAILURE,
		payload: { error: (error.response) ? error.response.data : 'Something went wrong. Please try again' }
	}
}

// Private methods

function handleErrors(response) {
    if(!response.success) {
        throw Error(response.statusText);
    }
    return response;
}

function handleWarehousesMessageErrors(response) {
    if(!response.success) {
        throw Error(response.statusText);
    }
    return response;
}
