import React, {useState, useEffect, useRef} from 'react';
import {withStyles, FormControl, MenuItem, Checkbox, ListItemText, ListItem, ListItemIcon, List, Button, TextField, Grid, Divider, Dialog, DialogActions, DialogTitle, } from '@material-ui/core';
import filterIcon from '@iconify-icons/feather/filter';
import {Icon, InlineIcon} from '@iconify/react';
import Autocomplete from '@material-ui/lab/Autocomplete';
//import date_Picker from 'views/admin/Accounting/Invoices/date_Picker.jsx';
import {KeyboardDatePicker} from "@material-ui/pickers";
import styled from "styled-components";

const InvoiceFilterDrawerContents = props => {

  const {classes, match, handleClearFilters, setInvoicesFilters, getInvoices, filters, activeFilters, onClose, children} = props;
  const {id} = match.params;
  const [invoiceFilters, setInvoiceFilters] = useState({});
  const applyRef = useRef(null);

  useEffect(() => {
    const compArr = (filters && filters.organisations) ? filters.organisations.filter(o => activeFilters.organisation_ids.includes(o[0])) : [];
    const statuses = (filters && filters.statuses) ? filters.statuses.filter(o => activeFilters.statuses.includes(o.value)) : [];


    setInvoiceFilters({
      ...activeFilters,
      "organisation_ids": compArr,
      "statuses": statuses,
      "invoice_date_before": activeFilters.invoice_date_before,
      "invoice_date_after": activeFilters.invoice_date_after,
    })
  }, [activeFilters]);


  const handleChange = (event, val, name) => {
    let newValues;
    if(event) {
      newValues = {...invoiceFilters, [event.target.name]: event.target.value}
    }
    else {
      newValues = {...invoiceFilters, [name]: val}
    }

    setInvoiceFilters(newValues);
  }

  const applyFilters = (event) => {

    const param = {
      per_page: 25,
      page: 1,
    }
    if(invoiceFilters.organisation_ids) {
      const arr = invoiceFilters.organisation_ids.map(o => o[0]);
      param.organisation_ids = arr;
      invoiceFilters['organisation_ids'] = arr;

    }
    if(invoiceFilters.statuses) {
      const arr = invoiceFilters.statuses.map(o => o.value);
      param.statuses = arr;
      invoiceFilters['statuses'] = arr;

    }
    if(invoiceFilters.invoice_date_before) param.invoice_date_before = invoiceFilters.invoice_date_before;
    if(invoiceFilters.invoice_date_after) param.invoice_date_after = invoiceFilters.invoice_date_after;

    getInvoices(param);
    setInvoicesFilters(invoiceFilters)
  };

  const handleKeypress = (event) => {
    if(event.code === "Enter") {
      applyRef.current.click();
    }
  }
  useEffect(() => {

    document.addEventListener("keydown", handleKeypress);
    return () => {
      document.removeEventListener("keydown", handleKeypress);
    };
  }, []);
  const StyledDatePicker = styled(KeyboardDatePicker)`
     .MuiBackdrop-root {
       background-color: unset ;
        opacity: '.15';
     },
    `;
  return (
    <>
      <Grid>
        <div
          className={classes.root}
          tabIndex={0}
          role="button"
          //onClick={onClose}
          onKeyDown={onClose}
        >
          <div className={classes.list}>
            <List>
              <ListItem onClick={onClose} button>
                <ListItemIcon><Icon icon={filterIcon} /> </ListItemIcon>
                <ListItemText primary='Filters' />
              </ListItem>
              {children}
            </List>
          </div>
        </div>
      </Grid>
      <Divider />
      <Grid>
        {!id && <Grid className={classes.textField} >
          <FormControl className={classes.selectForm}>
            <Autocomplete
              id="Organisation"
              multiple={true}
              name="organisation_ids"
              options={(filters && filters.organisations) ? filters.organisations : []}
              getOptionLabel={(option) => option[1]}
              onChange={(event, value) => handleChange(null, value, 'organisation_ids')}
              renderInput={(params) => (
                <TextField {...params} name="organisation_ids" label="Organisation" variant="outlined" />
              )}

              value={invoiceFilters.organisation_ids || []}
            />
          </FormControl>
        </Grid>}

        <Grid className={classes.textField}>
          <FormControl className={classes.selectForm}>

            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="DD/MM/YYYY"
              autoOk={true}
              //margin="normal"
              key='invoice_date_after'
              id='invoice_date_after'
              label="From Date"
              value={invoiceFilters.invoice_date_after || null}
              onChange={(date) => handleChange(null, date, 'invoice_date_after')}
              name='invoice_date_after'
              KeyboardButtonProps={{
                'aria-label': 'change start date',
              }}
            />

          </FormControl>
        </Grid>
        <Grid className={classes.textField}>
          <FormControl className={classes.selectForm}>

            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="DD/MM/YYYY"
              autoOk={true}
              //margin="normal"
              key='invoice_date_before'
              id='invoice_date_before'
              label="To Date"
              value={invoiceFilters.invoice_date_before || null}
              onChange={(date) => handleChange(null, date, 'invoice_date_before')}
              name='invoice_date_before'
              KeyboardButtonProps={{
                'aria-label': 'change start date',
              }}
            />
          </FormControl>
        </Grid>


        <Grid className={classes.textField}>
          <FormControl className={classes.selectForm}>

            <Autocomplete
              id="Status"
              multiple={true}
              name="statuses"
              options={(filters && filters.statuses) ? filters.statuses : []}
              getOptionLabel={(option) => option.text}
              onChange={(event, value) => handleChange(null, value, 'statuses')}
              renderInput={(params) => (
                <TextField {...params} name="statuses" label="Status" variant="outlined" />
              )}

              value={invoiceFilters.statuses || []}
            />

          </FormControl>
        </Grid>
      </Grid>
      <Grid className={classes.footer}>
        <Button onClick={handleClearFilters} className={classes.button}
          variant="outlined">
          Clear
        </Button>

        <Button variant="contained" className={classes.button} onClick={applyFilters} ref={applyRef} >
          Apply
        </Button>


      </Grid>
    </>
  )

}

const styles = theme => ({
  root: {

  },
  selectForm: {
    width: '100%'
  },
  datePicker: {
    display: 'flex',
    flexDirection: 'row'
  },
  calendar: {
    margin: 10
  },
  footer: {
    display: 'flex',
    marginTop: 'auto',
    justifyContent: 'space-between',
    padding: '32px',

  },
  textField: {
    padding: '38px 38px 0px 38px',
    marginLeft: '10px',
    marginRight: '10px',
    width: '350px'
  },

  datePicker: {
    '& .MuiPickersModal-dialog': {
      backgroundColor: 'red !important'
    }
  },
  button: {
    // minWidth: "150px",
    color: 'white',
    backgroundColor: "#3FB5AC",
    fontWeight: 600,
    border: 2,
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '6px',
    // overflow: 'auto',

  },

})

export default withStyles(styles)(InvoiceFilterDrawerContents);
