import {
    FETCH_ORDERS_BEGIN,
    FETCH_ORDERS_SUCCESS,
    FETCH_MORE_ORDERS_SUCCESS,
    FETCH_ORDERS_FAILURE,
    FETCH_ORDERS_PAGINATION,
    FETCH_ORDER_BY_ID_BEGIN,
    FETCH_ORDER_BY_ID_SUCCESS,
    FETCH_ORDER_BY_ID_FAILURE,
    SET_PER_PAGE_NUMBER,
    SET_PAGE_NUMBER,
    INCREASE_PAGE_NUMBER,
    SEND_ORDER_BEGIN,
    SEND_ORDER_FAILURE,
    SEND_ORDER_SUCCESS,
    UPDATE_ORDER_FAILURE,
    UPDATE_ORDER_SUCCESS,
    SEND_ORDER_MESSAGE_BEGIN,
    SEND_ORDER_MESSAGE_SUCCESS,
    SEND_ORDER_MESSAGE_FAILURE,
    SET_NEW_ORDER_IN_PROGRESS,
    DESTROY_NEW_ORDER,
    CREATE_NEW_ORDER,
    ADD_SKU_TO_ORDER,
    MOD_SKU_QUANTITY_TO_ORDER,
    ADD_FORMDATA_TO_ORDER,
    UPDATE_STATUS_FILTER,
    UPDATE_ORDER_FILTER_VALUE,
    UPDATE_SEARCH_VALUE,
    UPDATE_CREATED_AT_FILTER,
    UPDATE_PROCESSED_ON_FILTER,
    CANCEL_ORDERS_SUCCESS,
    CANCEL_ORDERS_FAILURE,
    SET_PREVIOUS_PATH,
    UPDATE_ORDER_BEGIN
    // actionTypes,
} from 'actions/orderActions';

import { applyOrderInterface } from 'Interfaces';
import { orderStatuses } from 'Interfaces';


const initialState = {
    //Orders as a whole list
    orders: [],
    loading: false,
    error: null,
    fetchedOrder: {},
    perPage: 25,
    page: 1,
    pagesLeft: null,
    maxPages: null,
    total: null,

    //filter-ables
    filters: {},
    pagination: {
        'per_page': 25,
        'current_page': 1
    },
    sources: [],
    statuses: [],
    inOuts: [
        { value: 'inbound', text: 'Inbound', isChecked: true },
        { value: 'outbound', text: 'Outbound', isChecked: true },
    ],
    searchTerm: '',
    startDate: null,
    endDate: null,
    processedStartDate: null,
    processedEndDate: null,

    //Order as an individual item
    childLoading: false,

    //New comment on order
    messageSending: false,
    messageError: null,

    //New Order
    newOrderInProgress: false,
    newOrderItems: [],
    newOrderPushing: false,
    newOrderErrors: null,
    newOrderObject: {},
    newOrderSentSuccessfully: false,


    // Edit Order
    updateOrderErrors: null,
    updateSuccess: false,
    updateOrderLoading: false,
    previousPath: null

}


export default function orderReducer(state = initialState, action) {
    let newOrderObject = { ...state.newOrderObject };
    switch (action.type) {
        case FETCH_ORDERS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_ORDERS_SUCCESS:
            //let sources = new Set();
            //let statuses = new Set();

            let sources = new Set(state.sources);
            let statuses = new Set(state.statuses);

            let orders = action.payload.orders.map(order => ({
                ...applyOrderInterface(order)
            }));

            if (!state.sources.length) {
                orders.forEach((order, i) => {

                    sources.add(order.source_shop)

                });

                sources = [...sources].map(source => ({
                    value: !(source === null) ? source : "",
                    text: !(source === null) ? "" : 'Keepspace App',
                    isChecked: true
                }))
            }
            else {
                sources = [...sources];
            }

            if (!state.statuses.length) {
                //get status from payload only first time
                statuses = [...action.payload.filters.statuses];

                statuses = statuses.map(v => ({ ...v, isChecked: true }));
            }
            else {
                statuses = [...statuses];
            }

            return {
                ...state,
                loading: false,
                orders: orders,
                sources: sources,
                statuses: statuses,
                perPage: parseInt(action.payload.pagination.per_page),
                page: parseInt(action.payload.pagination.current_page),
                pagesLeft: parseInt(action.payload.pagination.per_page),
                total: parseInt(action.payload.pagination.total_count),
                maxPages: parseInt(action.payload.pagination.total_pages),
                pagination: action.payload.pagination
            };

        case FETCH_ORDERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error || "Unspecified Error",
            };

        case FETCH_MORE_ORDERS_SUCCESS:
            sources = new Set(state.sources);
            statuses = new Set(state.statuses);

            let moreSources = new Set();

            let moreOrders = action.payload.orders.map(order => ({
                ...applyOrderInterface(order)
            }));

            moreOrders.forEach(order => {
                statuses.add(order.status_obj)
                moreSources.add(order.source_shop)
            });

            const sourceShops = state.sources.map(source => source.value)

            const newSources = [...moreSources].filter(source => !sourceShops.includes(source))

            moreSources = newSources.map(source => ({
                value: !(source === null) ? source : null,
                string: !(source === null) ? source : 'No Source URL Recorded',
                isChecked: true
            }))

            // sources.add([...moreSources]);

            // Transform sets to lists
            sources = [...sources, ...moreSources];
            statuses = [...statuses]

            return {
                ...state,
                orders: [...state.orders, ...moreOrders],
                loading: false,
                statuses: statuses,
                sources: sources,
                //page: state.page + 1,

                pagesLeft: state.maxPages - (state.page + 1)
            }

        case FETCH_ORDERS_PAGINATION:
            let total = parseInt(action.payload.pagination.total_count)
            let maxPages = parseInt(action.payload.pagination.total_pages)
            return {
                ...state,
                total,
                maxPages,
                pagesLeft: maxPages - 1
            }



        case FETCH_ORDER_BY_ID_BEGIN:
            return {
                ...state,
                childLoading: true,
                error: null
            };

        case FETCH_ORDER_BY_ID_SUCCESS:
            return {
                ...state,
                childLoading: false,
                fetchedOrder: applyOrderInterface(action.payload.order)
            };

        case FETCH_ORDER_BY_ID_FAILURE:
            return {
                ...state,
                childLoading: false,
                error: action.payload.error,
            };

        case SET_PAGE_NUMBER:
            return {
                ...state,
                page: action.payload.page
            }
        case SET_PER_PAGE_NUMBER:
            return {
                ...state,
                perPage: action.payload.perPage
            }
        case INCREASE_PAGE_NUMBER:
            return {
                ...state,
                page: action.payload.page
            }

        case SEND_ORDER_MESSAGE_BEGIN:
            return {
                ...state,
                messageSending: true,
                messageError: null,
            };

        case SEND_ORDER_MESSAGE_SUCCESS:
            return {
                ...state,
                messageSending: false,
            };

        case SEND_ORDER_MESSAGE_FAILURE:
            return {
                ...state,
                messageSending: false,
                messageError: action.payload.error
            };

        case SET_NEW_ORDER_IN_PROGRESS:
            return {
                ...state,
                newOrderObject: {
                    ...state.newOrderObject,
                    orderType: action.orderType,
                    source: "KeepSpace Platform",
                    source_shop: window.location.origin
                },
                newOrderInProgress: action.isTrue,
                newOrderSentSuccessfully: false
            };

        case DESTROY_NEW_ORDER:
            return {
                ...state,
                newOrderInProgress: false,
                newOrderItems: [],
                newOrderPushing: false,
                newOrderObject: {},
                newOrderErrors: null,
            };

        case SEND_ORDER_BEGIN:
            return {
                ...state,
                newOrderPushing: true,
                newOrderErrors: null,
            }

        case SEND_ORDER_SUCCESS: {
            Object.keys(newOrderObject).forEach(key => newOrderObject[key] = '');

            let newOrder = applyOrderInterface(action.payload.order)

            return {
                ...state,
                errors: null,
                newOrderError: null,
                newOrderPushing: false,
                newOrderObject,
                newOrderItems: [],
                newOrderSentSuccessfully: true,
                newOrderInProgress: false,
                orders: [newOrder, ...state.orders],
                total: state.total + 1 // Needed otherwise the total and orders in ram gets confused
            }
        }
        case SEND_ORDER_FAILURE:
            let send_order_failure_errors
            if (action.payload.error.response && action.payload.error.response.status === 422) {
                // Unprocessable Entity; It's likely that the response contains fields with errors
                send_order_failure_errors = {
                    ...action.payload.error.response.data
                }
            }
            return {
                ...state,
                error: action.payload.error,
                newOrderErrors: send_order_failure_errors,
                newOrderPushing: false,
                newOrderObject: newOrderObject,
            }

        case UPDATE_ORDER_BEGIN: {
            return {
                ...state,
                updateOrderLoading: true
            }
        }

        case UPDATE_ORDER_SUCCESS: {
            let updatedOrder = applyOrderInterface(action.payload.order)
            let updatedOrders = state.orders.map(order => order.id === action.payload.order.id ?
                updatedOrder : order
            )
            return {
                ...state,
                updateOrderLoading: false,
                errors: null,
                updateOrderErrors: null,
                fetchedOrder: updatedOrder,
                orders: [...updatedOrders] || state.orders
            }
        }
        case UPDATE_ORDER_FAILURE:

            let update_order_failure_errors, error = {};
            if (action.payload.error.response && action.payload.error.response.status === 422) {
                action.payload?.error?.response.data instanceof Object ?
                    Object.keys(action.payload?.error?.response.data).forEach(key => {
                        action.payload.error.response.data[key].forEach(e =>
                            error.message ? error.message += `${e} ` : error.message = `${e} `
                        )
                    }
                    ) : error.message = 'Something went wrong'
            }

            if (action.payload.error.response && action.payload.error.response.data.base) {
                action.payload.error.response.data.base.forEach(e =>
                    error.message ? error.message += `${e} ` : error.message = `${e} `
                )
            }

            return {
                ...state,
                updateOrderLoading: false,
                error: error.message ? error : action.payload.error,
                updateOrderErrors: update_order_failure_errors
            }

        case CREATE_NEW_ORDER:
            return {
                ...state,
                newOrderInProgress: false
            };

        case ADD_SKU_TO_ORDER:
            let newObject = true;
            state.newOrderItems.forEach(val => {
                if (val.item && action.item && val.item.id === action.item.id) {
                    newObject = false
                }
            })
            if (newObject) {
                return {
                    ...state,
                    newOrderItems: [
                        ...state.newOrderItems,
                        { item: action.item, quantity: action.qty }
                    ]
                }
            }
            else {
                return {
                    ...state,
                    //newOrderItems: [...state.newOrderItems, {item: action.item, quantity: 1}],
                    newOrderItems: [
                        ...state.newOrderItems.map(bookingItem => (
                            bookingItem.item.id === action.item.id ? (
                                { item: bookingItem.item, quantity: action.qty + bookingItem.quantity }
                            ) : (
                                bookingItem
                            )
                        ))
                    ]
                }
            };

        case MOD_SKU_QUANTITY_TO_ORDER:
            let remove = false
            state.newOrderItems.forEach(val => {
                if (val.item.id === action.item.id && (val.quantity + action.quantityVariation) < 1) {
                    remove = true
                }
            })

            if (remove) {
                return {
                    ...state,
                    newOrderItems: [
                        ...state.newOrderItems.filter(val => (val.item.id !== action.item.id))  // Remove the orderItem because it reaches zero quantity
                    ]
                }
            }
            else {
                return {
                    ...state,
                    newOrderItems: [
                        ...state.newOrderItems.map(bookingItem => (
                            bookingItem.item.id === action.item.id ? (
                                {
                                    item: bookingItem.item, quantity: (bookingItem.quantity + action.quantityVariation),
                                    sale_price: action.price, sale_currency: action.currency
                                }
                            ) : (
                                bookingItem
                            )
                        ))
                    ],
                };
            }

        case ADD_FORMDATA_TO_ORDER:

            newOrderObject[action.id] = action.value
            return {
                ...state,
                newOrderObject: newOrderObject,
                error: null
            }

        case UPDATE_STATUS_FILTER:
            let newStatuses = state.statuses.map(status => ({
                ...status,
                isChecked: (status.value === action.value ? action.isChecked : status.isChecked)
            }))
            return {
                ...state,
                statuses: newStatuses,
            }

        case UPDATE_ORDER_FILTER_VALUE:
            let newFilters = state[action.key].map(inOut => ({
                ...inOut,
                isChecked: (inOut.value === action.value ? action.isChecked : inOut.isChecked)
            }))
            return {
                ...state,
                [action.key]: newFilters
            }

        case UPDATE_CREATED_AT_FILTER:
            return {
                ...state,
                startDate: action.startDate,
                endDate: action.endDate
            }

        case UPDATE_PROCESSED_ON_FILTER:
            return {
                ...state,
                processedStartDate: action.startDate,
                processedEndDate: action.endDate
            }

        case UPDATE_SEARCH_VALUE:
            return {
                ...state,
                searchTerm: action.searchTerm,
            }
        case CANCEL_ORDERS_SUCCESS:
            let updatedOrders = state.orders.map(order => ({
                ...order,
                status: action.payload.cancelled.includes(order.id) ? 'canceled' : order.status,
                status_obj: action.payload.cancelled.includes(order.id) ? orderStatuses['canceled'] : order.status_obj
            }))
            let updatedOrder = action.payload.cancelled.includes(state.fetchedOrder.id) ? { ...state.fetchedOrder, status: 'canceled', status_obj: orderStatuses['canceled'] } : state.fetchedOrder

            return {
                ...state,
                orders: updatedOrders,
                updateSuccess: true,
                error: false,
                fetchedOrder: updatedOrder
            }
        case CANCEL_ORDERS_FAILURE:
            return {
                ...state,
                error: action.payload.error
            }
        case SET_PREVIOUS_PATH:
            return {
                ...state,
                previousPath: action.previousPath
            }
        default:
            // Always have a default case in case a reducer doesn't complete
            return state;
    }
};
