import React from 'react'
import { Route } from 'react-router-dom';

import Login from 'views/Authentication/view/Login';
import RegisterScreen from 'views/Authentication/RegisterScreen';
import ResetPassword from 'views/Authentication/ResetPassword';
import ResetPasswordConfirmation from 'views/Authentication/ResetPasswordConfirmation';
import MagicSigninLink from 'views/Authentication/MagicSigninLink';
import AlmostThere from 'views/Authentication/AlmostThere';
import AcceptInvitation from 'views/Authentication/AcceptInvitation';
import SelectOrganisation from 'views/Authentication/SelectOrganisation';
import AddOrganisation from 'views/Authentication/AddOrganisation';
import InvitationRedirect from 'views/Authentication/InvitationRedirect';

import CssBaseline from '@material-ui/core/CssBaseline';
import withStyles from '@material-ui/core/styles/withStyles';

// import Particles from './Particles';
import login from "assets/img/login.jpg";
import password from "assets/img/password.png";
import register from "assets/img/register.jpg";

const path = '/auth';

const styles = theme => ({
  main: {
    height: '100vh',
    marginRight: 'none',
    color: 'rgba(0, 0, 0, 0.54)',
    width: '100vW',
    "& a": {
      color: theme.palette.keepspaceBlue.main, textDecoration: 'none'
    },
    [theme.breakpoints.up('sm')]: {
      width: 400,
      // height: '100%',
      overflow: 'auto',
      marginLeft: 'auto'
    },
    [theme.breakpoints.up('md')]: {
      width: 400,
      // height: '100%',
      overflow: 'auto',
      margin: '0 10% 0 auto'
    },
  },
  container: {
    width: 'auto',
    height: '100%',
    display: 'block', // Fix IE 11 issue.
  },
  login: {
    ...base(theme),
    backgroundImage: `url(${login})`,
  },
  password: {
    ...base(theme),
    backgroundImage: `url(${password})`,
  },
  register: {
    ...base(theme),
    backgroundImage: `url(${register})`,
  }
})

const base = (theme) =>( {
  backgroundSize: 'cover',
  repeat: 'none',
  height: '100vh',
  width: '100vw',
  // display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'right',
})

const AuthLayout = (props) => {
  const { classes } = props;
  const className =  props.location.pathname === '/auth/register' ? classes.register : props.location.pathname === '/auth/reset_password' ?  classes.password : classes.login

  return (
    <div className={className}>
      <main className={classes.main} >
          <CssBaseline />

          <>
            <Route path={`${path}/register/`} render={(props) => <RegisterScreen history={props.history} />} />
            <Route path={`${path}/login/`} render={(props) => <Login history={props.history} /> } />
            <Route path={`${path}/reset_password/`} render={(props) => <ResetPassword history={props.history} />} />
            <Route path={`${path}/almost_there/`} render={(props) => <AlmostThere history={props.history} />} />
            <Route path={`${path}/reset_password_confirmation/`} render={(props) => <ResetPasswordConfirmation history={props.history} location={props.location} />} />
            <Route path={`${path}/magic_signin_link/`} render={(props) => <MagicSigninLink history={props.history} location={props.location} />} />
            <Route path={`${path}/invitation/accept`} render={(props) => <AcceptInvitation history={props.history} location={props.location} />} />
            <Route path={`${path}/organisations`} render={(props) => <SelectOrganisation history={props.history} location={props.location} />} />
            <Route path={`${path}/add_organisation`} render={(props) => <AddOrganisation history={props.history} location={props.location} />} />
            <Route path={`${path}/accept_invitation_redirect/:uid`} render={(props) => <InvitationRedirect history={props.history} location={props.location} />} />
          </>

      </main>
    </div>
  )
}

export default withStyles(styles)(AuthLayout);
