import React from "react";
import { Pagination } from "@material-ui/lab";
import { Typography, TablePagination } from "@material-ui/core";

const ROWS_PER_PAGE_OPTIONS_DEFAULT = [25, 50, 100, 500, 600];

const PaginationComp = (props) => {
  const {
    pagination,
    onChangePage,
    onChangeRowsPerPage,
    maxRowsPerPageOptions,
  } = props;

  if (!pagination) {
    return false;
  }

  const currentPage = pagination.current_page;
  const perPage = pagination.per_page;
  const firstRecord = perPage * (currentPage - 1) + 1 || 0;
  const totalCount = pagination.total_count || 0;
  let lastRecord = firstRecord + perPage - 1;
  const rowsPerPageOpts =
    maxRowsPerPageOptions ?? ROWS_PER_PAGE_OPTIONS_DEFAULT;

  if (totalCount < lastRecord) {
    lastRecord = totalCount;
  }

  return (
    <>
      {totalCount > 0 && (
        <>
          <TablePagination
            component="div"
            count={totalCount}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangePage={onChangePage}
            page={currentPage}
            rowsPerPage={perPage}
            rowsPerPageOptions={rowsPerPageOpts}
          />
          <Typography>
            {" "}
            {firstRecord} - {lastRecord} of {totalCount}{" "}
          </Typography>

          <Pagination
            count={pagination.total_pages}
            defaultPage={currentPage}
            page={currentPage}
            onChange={onChangePage}
          />
        </>
      )}
    </>
  );
};

export default PaginationComp;
