import React, { useMemo } from 'react';
import {
  AppBar,
  Dialog,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Slide,
  Toolbar,
  Typography,
  Paper,
  makeStyles,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import { useSelector, useDispatch } from 'react-redux';
import { addSkuToOrder } from 'actions/orderActions';
import { Dots } from 'components/Loading';
import Search from 'components/Search/Search';
import { setSearchTerm } from 'actions/spaceActions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const applyType = (productsArray, type) => {
  let newArray = productsArray.map(product => {
    return { ...product, productType: type };
  });

  return newArray;
};

const SKUDialog = (props) => {
  const { open, setOpen, handleEditOrder } = props;
  const classes = useStyles();

  const allItems = applyType(useSelector(state => state.spaceReducer.items), 'item');
  const allBundles = applyType(useSelector(state => state.bundlesReducer.bundles), 'bundle');
  const allProducts = allItems.concat(allBundles);

  const unarchivedItems = allProducts.filter(item => !item.archived);

  const searchTerm = useSelector(state => state.spaceReducer.searchTerm);

  const loadingItems = useSelector(state => state.spaceReducer.loading);
  const dispatch = useDispatch();

  const handleSkuClose = () => {
    setOpen(false);
    dispatch(setSearchTerm(''));
  };

  const addItemOrBundle = product => {

    handleEditOrder ? addToEditedOrder(product) : addToNewOrder(product);

    handleSkuClose();
    dispatch(setSearchTerm(''));
  };

  const addToNewOrder = product => {
    if (product.productType === 'item') {
      dispatch(addSkuToOrder(product));
    } else if (product.productType === 'bundle') {
      breakBundle(product);
    }
  };

  const addToEditedOrder = product => {
    if (product.productType === 'item') {
      handleEditOrder([{ item_id: product.id, item_quantity: 1 }]);
    } else if (product.productType === 'bundle') {
      handleEditOrder(product.components);
    }
  };

  const breakBundle = async (bundle) => {
    for (let component of bundle.components) {
      dispatch(addSkuToOrder({ id: component.item_id }, component.item_quantity));
    }
  };

  const search = (searchTerm, stringToProcess) => {
    let stp = stringToProcess.toLowerCase();
    let st = searchTerm.toLowerCase();
    return stp.includes(st);
  };

  /**
   * filtered Items for KS-1107
   * we have used useMemo hook to improve the search results.
   */
  const filteredItems = useMemo(() => {
    if (!searchTerm) {
      return unarchivedItems;
    }
    if (searchTerm && searchTerm.length >= 3) {
      return unarchivedItems.filter((item) => {
        return (
          search(searchTerm, item.sku) ||
          (searchTerm === item.id.toString()) ||
          search(searchTerm, item.title)
        );
      });
    }
  }, [searchTerm, unarchivedItems]);

  const handleSearchInput = e => dispatch(setSearchTerm(e.target.value));

  const componentsString = bundle => {
    let arr = bundle.components.map(comp =>
      `${comp.item_sku}:${comp.item_quantity}`);
    return arr.toString(' ');
  };

  const warehouseStock = (object, is_bundle = false) => {
    return <>
      <Typography>{is_bundle ? 'BUNDLE' : ''} {object.sku}</Typography>
      {
        !!object?.warehouse_stock_mapping && object?.warehouse_stock_mapping?.map(row => (
          <Typography>{row.warehouse_name} Warehouse - {row.quantity_stored} in stock</Typography>
        ))
      }
    </>;
  };

  return (
    <Dialog fullScreen open={open} onClose={handleSkuClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleSkuClose} aria-label="close">
            <CloseIcon/>
          </IconButton>
          <Typography variant="h6" style={{ color: 'inherit' }} className={classes.title}>
            Add SKU to order
          </Typography>
        </Toolbar>
      </AppBar>

      <div className={classes.root}
           style={{
             paddingTop: 90, // Appbar height
           }}
      >

        <Search
          className={classes.search}
          searchTerm={searchTerm}
          onChange={handleSearchInput}
          placeholder={loadingItems ? 'Loading SKUs to search' : 'Type minimum 3 characters to search...'}
          loading={loadingItems}
        />

        <Paper className={classes.paper}>
          <List>
            {!loadingItems && filteredItems?.map(item => (
              <ListItem button divider key={item.id} onClick={() => {
                addItemOrBundle(item);
              }}>
                {item.productType === 'item' ?
                  <ListItemText primary={item.title} secondary={warehouseStock(item)}/>
                  :
                  <ListItemText primary={item.title}
                                secondary={warehouseStock(item, true)}/>
                }
              </ListItem>
            ))}

            {loadingItems ? (
              <Dots/>
            ) : (null)}
          </List>
        </Paper>

      </div>

    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh',
  },
  paper: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  appBar: {
    position: 'fixed',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  search: {
    marginBottom: theme.spacing(3),
  },
}))

export default SKUDialog
