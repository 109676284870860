import {AuthActions} from "./actionTypes";
import adminApi from "../../services/admin/adminApi";
import {clearStorageData, getStorageData, setStorageData} from "../../services/admin/adminAuth";
import adminErrorHandler from "./adminErrorHandler";
import {updateAdminAuthHeaders} from 'AppConstants';

// action to perform LoginWithMail 
export const loginWithMail = (email, password) => async (dispatch) => {
    try {
        dispatch(internals.beginLoader());
        const response = await adminApi.postLogin(email, password);
        if(!!response.data && !!response.data.data) {
            const res = dispatch(internals.handleSuccess(response));
            return res;
        }
        dispatch(internals.handleFailure());
        return false;
    } catch(e) {
        dispatch(internals.handleFailure());
        adminErrorHandler('loginWithMail', e);
        return false;
    }
}

export const logoutAdmin = (id) => async (dispatch) => {
    try {
        // dispatch(internals.beginLoader());
        const response = await adminApi.postLogout(id);
        if(!!response?.success) {
            dispatch(internals.handleLogoutSuccess(response));
            return true;
        }

        dispatch(internals.handleFailure());
        return false;
    } catch(e) {
        adminErrorHandler('loginWithMail', e);
        return false;
    }
}


// fetch admin on page load and set data
export const fetchAdmin = () => async (dispatch) => {
    try {
        const admin = getStorageData();
        if(!admin) {
            return;
        }
        await internals.setAdminData(admin);
        dispatch(internals.loginSuccess(admin));
        await adminApi.getAdminUserValidate();
    } catch(e) {
        console.error('>>>> error in fetch admin >>>>', e);
        dispatch(internals.loginFailure());
    }
}

const internals = {
    // save in reducer
    beginLoader: () => ({
        type: AuthActions.LOGIN_START
    }),

    //perform operations
    handleSuccess: (response) => async(dispatch) => {
        const admin = {
            ...internals.extractHeaders(response.headers),
            email: response.data.data.email,
            id: response.data.data.id
        };

        await internals.setAdminData(admin, true);
        dispatch(internals.loginSuccess(admin));
    },

    //perform operations
    handleLogoutSuccess: (response) => (dispatch) => {
        internals.removeAdminData();
        dispatch(internals.clearAuth());
    },

    // save in reducer 
    loginSuccess: (adminData) => ({
        type: AuthActions.LOGIN_SUCCESS,
        adminData
    }),

    // perform operations
    handleFailure: () => (dispatch) => {
        internals.removeAdminData();
        dispatch(internals.loginFailure());
    },

    // perform operations
    loginFailure: () => ({
        type: AuthActions.LOGIN_FAIL
    }),

    //clear authentication
    clearAuth: () => ({
        type: AuthActions.CLEAR_AUTH
    }),
    // extract headers to get token and other info below
    extractHeaders: (headers) => {
        return {
            accessToken: headers['access-token'],
            tokenType: headers['token-type'],
            client: headers['client'],
            expiry: headers['expiry'],
            uid: headers['uid']
        }
    },

    // 1. set in localstorage
    // 2. set in headers
    setAdminData: async (admin, isLogin) => {
        const isDataSet = await setStorageData(admin);
        if(isLogin) {
            updateAdminAuthHeaders()
        }
        if(isDataSet) await adminApi.refreshAdminAuthToken();
    },
    // 1. remove from local storage
    // 2. remove from headers
    removeAdminData: async () => {
        clearStorageData();
        await adminApi.cleanAdminAuthToken();
    }
}