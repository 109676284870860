import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Snackbar, SnackbarContent, IconButton} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutlined';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  content: {
    backgroundColor: theme.palette['header'].dark
  },
  error: {
    backgroundColor: theme.palette['error'].dark
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    fontSize: "1.2em"
  },
  icon: {
    marginRight: theme.spacing(2)
  }
}));

const SuccessSnackbar = props => {
  const {open, messageText, error, onClose} = props;
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(open);
    if(!open) {
      //closeSnackbar();
    }
  });


  const closeSnackbar = () => {
    setIsOpen(false);
    //onClose();
  }

  if(!error && !messageText) return null;

  return (<>
    {isOpen ?
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        autoHideDuration={6000}
        resumeHideDuration={2000}
        onClose={onClose}
        open={isOpen}
      >
        {error ? (
          <SnackbarContent
            className={classes.error}
            message={
              <span className={classes.message}>
                <ErrorIcon className={classes.icon} />
                {error}
              </span>
            }
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                className={classes.icon}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            }
          />
        ) : messageText ? (
          <SnackbarContent
            className={classes.content}
            message={
              <span className={classes.message}>
                <CheckCircleIcon className={classes.icon} />
                {messageText}
              </span>
            }
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                className={classes.icon}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            }
          />

        ) : null}
      </Snackbar> : null}
  </>);
};

SuccessSnackbar.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

SuccessSnackbar.defaultProps = {
  open: true,
  onClose: () => {}
};

export default SuccessSnackbar;
