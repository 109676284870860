import {apiPath, authHeaders} from 'AppConstants';
import adminApi from 'services/admin/adminApi';

export const FETCH_ADMIN_INVOICES_BEGIN = 'FETCH_ADMIN_INVOICES_BEGIN';
export const FETCH_ADMIN_INVOICES_SUCCESS = 'FETCH_ADMIN_INVOICES_SUCCESS';
export const FETCH_ADMIN_INVOICES_FAILURE = 'FETCH_ADMIN_INVOICES_FAILURE';

export const FETCH_DRAFT_INVOICES_BEGIN = 'FETCH_DRAFT_INVOICES_BEGIN';
export const FETCH_DRAFT_INVOICES_SUCCESS = 'FETCH_DRAFT_INVOICES_SUCCESS';
export const FETCH_DRAFT_INVOICES_FAILURE = 'FETCH_DRAFT_INVOICES_FAILURE';

export const CLEAR_INVOICESS = 'CLEAR_INVOICESS';
export const CLEAR_INVOICES_NOTIFICATION = 'CLEAR_INVOICES_NOTIFICATION';

export const CREATE_INVOICES_BEGIN = 'CREATE_INVOICES_BEGIN';
export const CREATE_INVOICES_SUCCESS = 'CREATE_INVOICES_SUCCESS';
export const CREATE_INVOICES_FAILURE = 'CREATE_INVOICES_FAILURE'

export const UPDATE_QUICKBOOKURL_BEGIN = 'UPDATE_QUICKBOOKURL_BEGIN';
export const UPDATE_QUICKBOOKURL_SUCCESS = 'UPDATE_QUICKBOOKURL_SUCCESS';
export const UPDATE_QUICKBOOKURL_FAILURE = 'UPDATE_QUICKBOOKURL_FAILURE';

export const ADD_NEW_PAYMENT_BEGIN = 'ADD_NEW_PAYMENT_BEGIN';
export const ADD_NEW_PAYMENT_SUCCESS = 'ADD_NEW_PAYMENT_SUCCESS';
export const ADD_NEW_PAYMENT_FAILURE = 'ADD_NEW_PAYMENT_FAILURE';

export const SET_MULTIPLE_INVOICES_ERROR = 'SET_MULTIPLE_INVOICES_ERROR'
export const SET_INVOICES_FILTERS = 'SET_INVOICES_FILTERS'

export const FETCH_ADMIN_INVOICES_BY_ID_BEGIN = 'FETCH_ADMIN_INVOICES_BY_ID_BEGIN';
export const FETCH_ADMIN_INVOICES_BY_ID_SUCCESS = 'FETCH_ADMIN_INVOICES_BY_ID_SUCCESS';
export const FETCH_ADMIN_INVOICES_BY_ID_FAILURE = 'FETCH_ADMIN_INVOICES_BY_ID_FAILURE';

export const CANCEL_INVOICE_BEGIN = 'CANCEL_INVOICE_BEGIN';
export const CANCEL_INVOICE_SUCCESS = 'CANCEL_INVOICE_SUCCESS';
export const CANCEL_INVOICE_FAILURE = 'CANCEL_INVOICE_FAILURE';

export const RECREATE_PDF_BEGIN = 'RECREATE_PDF_BEGIN';
export const RECREATE_PDF_SUCCESS = 'RECREATE_PDF_SUCCESS';
export const RECREATE_PDF_FAILURE = 'RECREATE_PDF_FAILURE';
// Get Invoices for Table


export function fetchInvoices(params) {
    return dispatch => {
        dispatch(fetchInvoicesBegin());
        return adminApi.fetchInvoices(params)
            .then(json => {
                dispatch(fetchInvoicesSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(fetchInvoicesFailure(error))

            });
    };
}

export function cancelInvoice(id, params = {}) {
    return dispatch => {
        dispatch(cancelInvoiceBegin());
        return adminApi.cancelInvoice(id)
            .then(json => {
                dispatch(cancelInvoiceSuccess(json));
                dispatch(fetchInvoices(params));
                return json;
            })
            .catch(error => {
                dispatch(cancelInvoiceFailure(error))

            });
    };
}

export const cancelInvoiceBegin = () => ({
    type: CANCEL_INVOICE_BEGIN
});

export const cancelInvoiceSuccess = item => ({
    type: CANCEL_INVOICE_SUCCESS,
    payload: {
        item: item
    }
});

export const cancelInvoiceFailure = error => ({
    type: CANCEL_INVOICE_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});

function handleErrors(response) {
    if(!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export const fetchInvoicesBegin = () => ({
    type: FETCH_ADMIN_INVOICES_BEGIN
});

export const fetchInvoicesSuccess = json => ({
    type: FETCH_ADMIN_INVOICES_SUCCESS,
    payload: {
        invoices: json.data.invoices,
        pagination: json.data.pagination,
        filters: json.data.filters
    }
});

export const fetchInvoicesFailure = error => ({
    type: FETCH_ADMIN_INVOICES_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});


// Filtering & Searching the Table




export const setInvoicesFilters = (params) => ({
    type: SET_INVOICES_FILTERS,
    data: params
})


// Drafts Invoices
export function fetchDraftInvoices(params) {
    return dispatch => {
        dispatch(fetchInvoicesBegin());
        return adminApi.fetchDraftInvoices(params)
            .then(json => {
                dispatch(fetchInvoicesSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(fetchInvoicesFailure(error))

            });
    };
}
//Get Invoices details

export function fetchInvoiceById(id) {
    return dispatch => {
        dispatch(fetchInvoiceByIDBegin());
        return adminApi.fetchInvoiceById(id)
            .then(json => {
                dispatch(fetchInvoiceByIDSuccess(json.data));

                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(fetchInvoiceByIDFailure(error))
            });
    }


}


export const fetchInvoiceByIDBegin = () => ({
    type: FETCH_ADMIN_INVOICES_BY_ID_BEGIN
});

export const fetchInvoiceByIDSuccess = item => ({
    type: FETCH_ADMIN_INVOICES_BY_ID_SUCCESS,
    payload: {item: item.invoice}
});

export const fetchInvoiceByIDFailure = error => ({
    type: FETCH_ADMIN_INVOICES_BY_ID_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});


// Add new Invoices

export const createInvoiceBegin = () => ({
    type: CREATE_INVOICES_BEGIN
});

export const createInvoicesSuccess = (json) => ({
    type: CREATE_INVOICES_SUCCESS,
    payload: {
        item: json.data.invoice
    }
});

export const createInvoiceFailure = error => ({
    type: CREATE_INVOICES_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});


export function createInvoice(id, formData) {
    return dispatch => {
        dispatch(createInvoiceBegin());
        return adminApi.createInvoice(id, formData)
            .then(json => {
                dispatch(createInvoicesSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(createInvoiceFailure(error))
            });
    }
}

export function updateQuickBookUrl(id, data) {
    return dispatch => {
        dispatch(updateQuickBookUrlBegin());
        return adminApi.updateQuickBookUrl(id, data)
            .then(json => {
                dispatch(updateQuickBookUrlsSuccess(json.data));
                return json;
            })
            .catch(error => {dispatch(updateQuickBookUrlFailure(error))});
    }
}


export const updateQuickBookUrlBegin = () => ({
    type: UPDATE_QUICKBOOKURL_BEGIN,
})


export const updateQuickBookUrlsSuccess = (item) => ({
    type: UPDATE_QUICKBOOKURL_SUCCESS,
    payload: {item}
})

export const updateQuickBookUrlFailure = error => ({
    type: UPDATE_QUICKBOOKURL_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});


export function addNewPayment(id, data) {
    return dispatch => {
        dispatch(addNewPaymentBegin());
        return adminApi.addNewPayment(id, data)
            .then(json => {
                dispatch(addNewPaymentSuccess(json.data));
                return json;
            })
            .catch(error => {dispatch(addNewPaymentFailure(error))});
    }
}


export const addNewPaymentBegin = () => ({
    type: ADD_NEW_PAYMENT_BEGIN,
})


export const addNewPaymentSuccess = (item) => ({
    type: ADD_NEW_PAYMENT_SUCCESS,
    payload: {item}
})

export const addNewPaymentFailure = error => ({
    type: ADD_NEW_PAYMENT_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});


export const clearInvoices = () => ({
    type: CLEAR_INVOICESS
});

export const clearInvoicesNotifications = () => ({
    type: CLEAR_INVOICES_NOTIFICATION
});


export const setMultipleInvoicesError = error => ({
    type: SET_MULTIPLE_INVOICES_ERROR,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});

export function recreateInvoicePdf(id) {
    return dispatch => {
        dispatch(recreateInvoicePdfBegin());
        return adminApi.recreateInvoicePdf(id)
            .then(json => {
                dispatch(recreateInvoicePdfSuccess(json.data));
                return json;
            })
            .catch(error => {dispatch(recreateInvoicePdfFailure(error))});
    }
}

export const recreateInvoicePdfBegin = () => ({
    type: RECREATE_PDF_BEGIN,
})


export const recreateInvoicePdfSuccess = (item) => ({
    type: RECREATE_PDF_SUCCESS,
    payload: {item}
})

export const recreateInvoicePdfFailure = error => ({
    type: RECREATE_PDF_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});
