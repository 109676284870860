import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Divider,
  List,
  TablePagination
} from '@material-ui/core';

import { ConversationListItem } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.white
  },
  searchInput: {
    flexGrow: 1
  },
  chatFooterPagination: {
    position: 'sticky',
    bottom: '0',
    background: '#fff'
  }
}));


const ConversationList = props => {
  const { conversations, className, fetchComments, pagination, fetchConversation, selectedConversationId, currentUserAvatar, ...rest } = props;

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const rowsPageOptions = [25, 50]

  const handleChangePage = (event, page) => {
    setPage(page);
    const params = `per_page=${rowsPerPage}&page=${page+1}`
    fetchComments(params)
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
    const params = `per_page=${event.target.value}&page=${0}`
    fetchComments(params)
  };
  
  return (
    <div
      { ...rest }
      className={ clsx(classes.root, className) }
    >
      {/* <Toolbar>
              <Input
                className={classes.searchInput}
                disableUnderline
                placeholder="Search contacts"
              />
              <Tooltip title="Search">
                <IconButton edge="end">
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            </Toolbar> */}
      <Divider />
      <List disablePadding>
        <>
          { conversations.sort((a, b) => new Date(b.last_message.created_at) - new Date(a.last_message.created_at))
            .map((conversation, i) => (
              <ConversationListItem
                active={ conversation.id === selectedConversationId }
                conversation={ conversation }
                divider={ i < conversations.length - 1 }
                key={ conversation.id }
                currentUserAvatar={ currentUserAvatar }
              // onClick={()=>fetchConversation(conversation)}
              />
            )) }
          {
             conversations.length > 0 && <div className={ classes.chatFooterPagination }>
             <TablePagination
               component="div"
               count={ pagination?.total_count}
               onChangePage={ handleChangePage }
               onChangeRowsPerPage={ handleChangeRowsPerPage }
               page={ page  }
               rowsPerPage={ rowsPerPage }
               rowsPerPageOptions={ rowsPageOptions }
             />
           </div>
          }
          

        </>
      </List>
    </div>
  );
};

ConversationList.propTypes = {
  className: PropTypes.string,
  conversations: PropTypes.array.isRequired
};

export default ConversationList;
