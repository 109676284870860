export default {
  root: {
    "& $notchedOutline": {
      borderColor: "	#D8D8D8"
    },
    "&:hover $notchedOutline": {
      borderColor: "#777777"
    },
    "&$focused $notchedOutline": {
      borderColor: "#3d7bf6"
    }
  }
};