import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Remove the Link styling for use in Material-UI's MenuItems
 */

const MenuLink = (props) => {
    const { to, onClick, children } = props;
    return (
        <Link
            to={to}
            onClick={onClick}
            style={{
                color: 'inherit',
                textDecoration: 'none'
            }}
        >
            {children}
        </Link>
    )
}

export default MenuLink
