import React from 'react'
import PricingCard from '../PricingCard';
import {
	Grid,
	Card,
	CardHeader,
	CardContent,
	Divider,
	Typography,
	colors
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import Chart from '../Chart';
import NotAuthorised from 'views/Error401'

const useStyles = makeStyles((theme) => ({
	root: {
		flexWrap: 'wrap',
		width: '100%',
	},
	secondItem: {
		marginTop: 16
	},
	progressBar: {
		display: 'flex',
		alignItems: 'center'
	},
	status: {
		minWidth: '10%',
		paddingLeft: '20px',
		textAlign: "right"
	},
	flexBox: {
		display: 'flex',
		flexDirection: 'column'
	},
	statsContainer: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	statsItem: {
		// flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		padding: theme.spacing(3, 2),
		borderTop: `1px solid ${theme.palette.divider}`,
		'&:not(:last-of-type)': {
			borderRight: `1px solid ${theme.palette.divider}`
		},
		width: '25%',
		[theme.breakpoints.down("sm")]: {
			width: '50%',
		},
	},
	chart: {
		height: 281,
		padding: '18px'
	},
	content: {
		padding: 0
	},
	subtitle: {
		display: 'flex',
		justifyContent: 'space-between'
	}
}));

const Pricing = props => {
	const {order, permittedToAccess} = props;
	const classes = useStyles();

	const procentage = (total, val) => {
		if(total === 0 || val === 0) {
			return 0
		} else {
			return (val * 100 / total).toFixed(2);
		}
	}

	const margin = (total, val1, val2, val3) => {
		if(total === 0) {
			return 0
		} else {
			return (100 - ((val1 + val2 + val3) * 100 / total)).toFixed(2);
		}
	}

	const orderHeadRows = [
		{name: "fulfillment", label: "Fulfillment", prefix: '$'},
		{name: "shipping_price", label: "Shipping", prefix: '$'},
		{name: "customs_total_cost", label: "Customs", prefix: '$', hidden: !order.customs_total_cost},
	]

	const inboundHeadRows = [
		{name: "fulfillment", label: "Fulfillment", prefix: '$'},
	]

	const outboundRows = [
		{name: "pick_price", subtitle: 'Fulfillment', details: 'total_products', label: "Pick", prefix: '$'},
		{name: "prepping_price", subtitle: 'Fulfillment', details: 'prepping_title', label: "Prepping", prefix: '$'},
		{name: "administration_cost_price", subtitle: 'Fulfillment', details: 'administration_cost_title', label: "Administration", prefix: '$', quantity: "administration_cost_qty"},
		{
			name: "booking_parcels", label: "Handling", subtitle: 'Fulfillment', children: [
				{name: "handling_price", details: 'handling_title', subcategory: 'handling', prefix: '$'},
			]
		},
		{name: "extra_costs", label: "Extra Costs", prefix: '$'},
		{name: "shipping_price", details: 'shipper_name', label: "Shipping", prefix: '$'},
		{
			name: "customs", label: "Customs", prefix: '$',
			children: [
				{name: "price", details: 'title',  subcategory: 'charge', prefix: '$'}
			],
			hidden: !order.customs_total_cost
		}
	]

	const inboundRows = [
		{
			name: "booking_inbound_inbound_charges", label: "Inbound Charges", subtitle: 'Fulfillment', children: [
				{name: "locked_inbound_charge", details: 'title', subcategory: 'inbound_charge', prefix: '$', quantity: 'quantity'},
			]
		},
		{name: "administration_cost_price", subtitle: 'Fulfillment', details: 'administration_cost_title', label: "Administration", prefix: '$'},
		{name: "extra_costs", label: "Extra Costs", prefix: '$'},
	]

	const costs = [
		{value: procentage(order.total_sale, order.cost_of_products), label: 'Cost of products', color: colors.indigo[500]},
		{value: procentage(order.total_sale, order.fulfillment), label: 'Fulfillment', color: colors.indigo[300]},
		order.customs_total_cost ? {
			value: procentage(order.total_sale, order.shipping_price + order.customs_total_cost),
			label: "Shipping and Customs",
			color: colors.indigo[100]
		} : {
			value: procentage(order.total_sale, order.shipping_price),
			label: "Shipping",
			color: colors.indigo[100]
		},
		{value: margin(order.total_sale, order.cost_of_products, order.fulfillment, order.shipping_price), label: 'Margin', color: colors.indigo[400]},
	]
	if(!permittedToAccess("orders:pricing")) return <NotAuthorised />

	return (
		<Grid
			className={classes.root}
			container
			spacing={2}
			direction="row"
		>
			<Grid
				item
				md={6}
				xs={12}
			>
				<PricingCard
					title={'Pricing'}
					order={order}
					headRows={order.booking_type === 'outbound' ? orderHeadRows : inboundHeadRows}
					rows={order.booking_type === 'outbound' ? outboundRows : inboundRows}
				/>
			</Grid>

			{order.booking_type === 'outbound' ?
				<Grid
					item
					md={6}
					xs={12}
				>
					<Card>
						<CardHeader
							title="Margin"
						/>
						<Divider />
						<CardContent className={classes.subtitle}>
							<Typography>Total Sale</Typography>
							<Typography>{`$ ${order.total_sale}`}</Typography>
						</CardContent>
						<Divider />
						<CardContent className={classes.content}>
							<div className={classes.chartContainer}>
								<Chart
									className={classes.chart}
									data={costs}
								/>
							</div>
							<div className={classes.statsContainer}>
								{costs.map(earning => (
									<div
										className={classes.statsItem}
										key={earning.value}
									>
										<Typography
											align="center"
											component="h6"
											gutterBottom
											variant="overline"
										>
											{earning.label}
										</Typography>
										<Typography
											align="center"
											variant="h4"
										>
											{earning.value}%
										</Typography>
									</div>
								))}
							</div>
						</CardContent>
					</Card>
				</Grid>
				: null}

		</Grid>
	)

}

export default Pricing
