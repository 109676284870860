import React from 'react'
import PropTypes from 'prop-types';
import {
    makeStyles,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import clsx from "clsx";

const OrderItem = props => {
    const { className, line, deleteItemFunction, quantityChangeCallback } = props;
    const classes = useStyles();
    let inputRef = React.createRef("form");

    return (
        <ListItem className={clsx(className, classes.root, classes.gutter)}>
            <div style={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <CancelIcon className={classes.cancelIcon} fontSize="small" onClick={deleteItemFunction} />
                <ListItemText primary={line.item.title} secondary={line.item.sku} />
            </div>
            <ValidatorForm
                ref={inputRef}
                onSubmit={quantityChangeCallback}
                onError={errors => console.log(errors)}
            >
                <TextValidator
                    type='number'
                    validators={['minNumber:1']}
                    errorMessages={['must be greater than 0', 'regex']}
                    id="outlined-quantity-input"
                    label="Quantity"
                    value={line.quantity}
                    className={classes.textField}
                    onChange={quantityChangeCallback}
                    // onBlurr={quantityChangeCallback}
                    inputProps={{
                        //readOnly: false,
                        style: {
                            textAlign: 'center',
                            padding: "8px 14px",
                            width: 140,
                        },
                    }}
                    variant="outlined"
                />
            </ValidatorForm>
        </ListItem >
    )
}

OrderItem.propTypes = {
    className: PropTypes.string,
    line: PropTypes.object.isRequired,
}

const useStyles = makeStyles(theme => ({
    root: {
        justifyContent: 'space-between'
    },
    cancelIcon: {
        color: theme.palette.text.secondary,
        marginRight: 8
    },
    textField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    gutter: {
        paddingLeft: '16px',
        paddingRight: '22px',
    }

}));

export default OrderItem
