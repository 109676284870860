import React, { Fragment, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { SwipeableDrawer, Divider, Paper, Avatar, Typography } from '@material-ui/core';
import { Hidden } from '@material-ui/core';

import useRouter from 'utils/useRouter';
import { Navigation } from 'components';
import navigationConfig from './navigationConfig';

import { reverseUserRoute } from 'routes';
// import cow from "assets/img/avatars/svg/001-cow.svg"

const useStyles = makeStyles(theme => ({
  root: {
    // height: '100vh',
    overflowY: 'auto'
  },
  content: {
    padding: theme.spacing(2),
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1),
  },
  version: {
    textAlign: "center",
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  navigation: {
    marginTop: theme.spacing(2),
    minHeight: '90vh'//'calc(100VH -10px)'
  },
  navMenuItem: {
    '& a': {
      padding: "15px 5px"
    },
    '& button': {
      padding: "15px 5px"
    },

  }
}));

const NavBar = props => {
  const { openMobile, onMobileClose, onMobileOpen, className, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();
  //const session = useSelector(state => state.session);
  const session = useSelector(state => state.reduxTokenAuth.currentUser);

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);

  let avatar

  if (session.attributes.id) {
    avatar =
      <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        // src={session.attributes.id ? require(`assets/img/avatars/00${session.attributes.id%46+1}.svg`) : null}
        src={session.attributes.avatar.url ? session.attributes.avatar.url : require(`assets/img/avatars/00${session.attributes.id % 46 + 1}.svg`)}
        to_old="/profile/1"
        to={reverseUserRoute('Profile', 2)}
      />
  }

  const navbarContent = (<>
    <div className='menuBar'>
      {/*<div className={classes.profile}>
        {avatar}
        <Typography
          className={classes.name}
          variant="h4"
        >
          {session.attributes.firstName} {session.attributes.lastName}
        </Typography>
        <Typography variant="subTitle2">{session.attributes.companyName}</Typography>
  </div>*/}
      <Typography
        className='name'
        variant="h4"
      >Menu</Typography>
      <Divider className='divider' />
      <nav className='navigation'>
        {navigationConfig.map((list, i) => (
          <Navigation
            component="div"
            key={`${list.title}_${i}`}
            pages={list.pages}
            title={list.title}
            icon={list.icon}
            className='navMenuItem'
          />
        ))}
      </nav>

      <Typography
        className='version'
        variant="body2"
      >Version 2.0</Typography>
    </div>
  </>);

  return (
    <Fragment>
      <Hidden mdUp>
        <SwipeableDrawer
          anchor="left"
          onClose={onMobileClose}
          onOpen={onMobileOpen}
          open={openMobile}
          variant="temporary"
        >
          <div
            {...rest}
            className={clsx(classes.root, className)}
          >
            {navbarContent}
          </div>
        </SwipeableDrawer>
      </Hidden>
      <Hidden smDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
