import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import SuccessSnackbar from 'components/Help/SuccessSnackbar';

import ProfileDetails from './components/ProfileDetails';
import GeneralSettings from './components/GeneralSettings';
import { connect } from 'react-redux';

const useStyles = () => ({
  root: {}
});

class General extends React.Component  {

  render() {
    const { className, currentUser, classes, error, notification, ...rest } = this.props;

    return (
      <>
        <SuccessSnackbar
          open={(error || notification) ? true : false}
          error={(error) ? error : null}
          messageText={notification}
        />
    
        <Grid
          {...rest}
          className={clsx(classes.root, className)}
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <ProfileDetails profile={currentUser} error={error} notification={notification} />
          </Grid>

          <Grid
            item
            lg={8}
            md={6}
            xl={9}
            xs={12}
          >
            <GeneralSettings profile={currentUser} error={error} notification={notification}/>
          </Grid>
        </Grid>
      </>
    );
  }
};

General.propTypes = {
  className: PropTypes.string
};

const mapPropsToState = (state, ownProps) => ({
    ...ownProps,
})

export default connect(mapPropsToState)(withStyles(useStyles)(General));
