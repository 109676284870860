import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider, Card } from '@material-ui/core';

import {
  ConversationMessages,
  ConversationForm
} from 'views/user/Chat/components/ConversationDetails/components';

const useStyles = makeStyles(theme => ({
  root: {
    height: 'calc(100vh - 310px)',
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column'
  },
}));

const Chat = (props) => {
  const {id, conversation, currentUserAvatar } = props
  const classes = useStyles();

  return (
      <Card className={classes.root}>
        <ConversationMessages public_comments={conversation.public_comments} currentUserAvatar={currentUserAvatar} />
        <Divider />
        <ConversationForm id={id} currentUserAvatar={currentUserAvatar}/>
      </Card>
  );
};

export default Chat;
