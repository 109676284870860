import adminApi from 'services/admin/adminApi';
/*----------------materials--------------------------*/
export const FETCH_MATERIAL_BEGIN = 'FETCH_MATERIAL_BEGIN';
export const FETCH_MATERIAL_SUCCESS = 'FETCH_MATERIAL_SUCCESS';
export const FETCH_MATERIAL_FILTERS_SUCCESS = 'FETCH_MATERIAL_FILTERS_SUCCESS';
export const FETCH_MATERIAL_FAILURE = 'FETCH_MATERIAL_FAILURE'

export const DELETE_MATERIAL = 'DELETE_MATERIAL'
export const DELETE_MATERIAL_SUCCESS = 'DELETE_MATERIAL_SUCCESS'
export const DELETE_MATERIAL_FAILURE = 'DELETE_MATERIAL_FAILURE'

export const FETCH_MATERIAL_BY_ID_SUCCESS = 'FETCH_MATERIAL_BY_ID_SUCCESS';
export const FETCH_MATERIAL_BY_ID_FAILURE = 'FETCH_MATERIAL_BY_ID_FAILURE';

export const CREATE_MATERIAL_BEGIN = 'CREATE_MATERIAL_BEGIN';
export const CREATE_MATERIAL_SUCCESS = 'CREATE_MATERIAL_SUCCESS';
export const CREATE_MATERIAL_FAILURE = 'CREATE_MATERIAL_FAILURE';

export const UPDATE_MATERIAL_BEGIN = 'UPDATE_MATERIAL_BEGIN';
export const UPDATE_MATERIAL_SUCCESS = 'UPDATE_MATERIAL_SUCCESS';
export const UPDATE_MATERIAL_FAILURE = 'UPDATE_MATERIAL_FAILURE';


export const FETCH_MATERIAL_MOVEMENT_SUCCESS = 'FETCH_MATERIAL_MOVEMENT_SUCCESS';
export const FETCH_MATERIAL_MOVEMENT_FAILURE = 'FETCH_MATERIAL_MOVEMENT_FAILURE';

export const ADD_MATERIAL_STOCK_BEGIN = 'ADD_MATERIAL_STOCK_BEGIN';
export const ADD_MATERIAL_STOCK_SUCCESS = 'ADD_MATERIAL_STOCK_SUCCESS';
export const ADD_MATERIAL_STOCK_FAILURE = 'ADD_MATERIAL_STOCK_FAILURE';

export const SET_MATERIAL_FILTER = 'SET_MATERIAL_FILTER';
export const CLEAR_MATERIAL_FILTER = 'CLEAR_MATERIAL_FILTER';
export const CLEAR_MATERIALS = 'CLEAR_MATERIALS';
export const CLEAR_MATERIAL_NOTIFICATION = 'CLEAR_MATERIAL_NOTIFICATION';

export const FETCH_LOCATIONS_BEGIN = 'FETCH_LOCATIONS_BEGIN';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_FAILURE = 'FETCH_LOCATIONS_FAILURE';
export const FETCH_LOCATIONS_BY_BARCODE_SUCCESS = 'FETCH_LOCATIONS_BY_BARCODE_SUCCESS';
export const FETCH_LOCATIONS_BY_BARCODE_FAILURE = 'FETCH_LOCATIONS_BY_BARCODE_FAILURE'

/*--------------------Material----------------------------*/
export function fetchMaterial(params) {
  return dispatch => {
    dispatch(fetchMaterialBegin());//calling this action to show loading
    return adminApi.fetchMaterial(params)// calling list of materials
      .then(json => {

        dispatch(fetchMaterialSuccess(json));
        return json;
      })
      .catch(error => {
        console.log('error', error);

        dispatch(fetchMaterialFailure(error))

      });
  };
}

export function fetchMaterialFilters(params) {
  return dispatch => {
    dispatch(fetchMaterialBegin());//calling this action to show loading
    return adminApi.fetchMaterialFilters(params)// calling list of materials
      .then(json => {

        dispatch(fetchMaterialFiltersSuccess(json));
        return json;
      })
      .catch(error => {
        console.log('error', error);
      });
  };
}

export function deleteMaterial(id) {
  return dispatch => {
    return adminApi.deleteMaterial(id)
      .then(json => {
        dispatch(deleteMaterialSuccess(json));
        return json;
      })
      .catch(error => {
        console.log(error)
        dispatch(deleteMaterialFailure(error))

      });
  }
}

export function fetchMaterialByID(id) {
  return dispatch => {
    dispatch(fetchMaterialBegin());//calling this action to show loading
    return adminApi.getMaterialDetail(id)
      .then(handleErrors)
      .then(json => {
        dispatch(fetchMaterialByIDSuccess(json));
        return json;
      })
      .catch(error => {
        console.log(error)
        dispatch(fetchMaterialByIDFailure(error))

      });
  }
}

export function updateMaterial(id, data) {
  return dispatch => {
    dispatch(updateMaterialBegin());
    let res = adminApi.updateMaterial(id, data)
      .then(json => {
        dispatch(updateMaterialSuccess(json));
        return json;
      })
      .catch(error => {
        dispatch(updateMaterialFailure(error));
      })
    return res;
  }
}

export function fetchMovements(id, params) {
  return dispatch => {
    dispatch(fetchMaterialBegin());//calling this action to show loading
    return adminApi.getMovements(id, params)
      .then(handleErrors)
      .then(json => {
        dispatch(getMovementsSuccess(json));
        return json;
      })
      .catch(error => {
        console.log(error)
        dispatch(fetchMovementsFailure(error))
      });
  }
}

export function addMaterialStock(id, data) {
  return dispatch => {
    dispatch(addMaterialStockBegin());
    let res = adminApi.addMaterialStock(id, data)
      .then(json => {
        dispatch(addMaterialStockSuccess(json));
        return json;
      })
      .catch(error => {
        dispatch(addMaterialStockFailure(error));
      })
    return res;
  }
}

export const fetchMaterialBegin = () => (

  {
    type: FETCH_MATERIAL_BEGIN
  });

export const fetchMaterialSuccess = json => ({
  type: FETCH_MATERIAL_SUCCESS,
  data: json.data.parcel_materials,
  filter: json.data.filters,
  pagination: json.data.pagination,
  suppliers: json.data.suppliers
});
export const fetchMaterialFiltersSuccess = json => ({
  type: FETCH_MATERIAL_FILTERS_SUCCESS,
  filter: json.data.filters,
});
export const fetchMaterialFailure = error => ({
  type: FETCH_MATERIAL_FAILURE,
  payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});
export const getMovementsSuccess = json => ({
  type: FETCH_MATERIAL_MOVEMENT_SUCCESS,
  data: json.data,
})
export const fetchMovementsFailure = error => ({
  type: FETCH_MATERIAL_MOVEMENT_FAILURE,
  payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});

export const deleteMaterialSuccess = (json) => ({
  type: DELETE_MATERIAL_SUCCESS,
  data: json.data.parcel_material,
  msg: json.message
})

export const deleteMaterialFailure = (error) => ({
  type: DELETE_MATERIAL_FAILURE,
  payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'},
  msg: "An error occurred, please try again later"
});

export const fetchMaterialByIDSuccess = json => ({
  type: FETCH_MATERIAL_BY_ID_SUCCESS,
  payload: json.data
});

export const fetchMaterialByIDFailure = error => ({
  type: FETCH_MATERIAL_BY_ID_FAILURE,
  payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'},
  msg: "An error occurred, please try again later"
});

export function updateMaterialBegin() {
  return {
    type: UPDATE_MATERIAL_BEGIN
  }
}

export function updateMaterialSuccess(json) {
  return {
    type: UPDATE_MATERIAL_SUCCESS,
    payload: json.data.parcel_material

  }
}

export function updateMaterialFailure(error) {
  return {
    type: UPDATE_MATERIAL_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
  }
}

export function createMaterial(data) {
  return dispatch => {
    dispatch(createMaterialBegin());
    let res = adminApi.createMaterial(data)
      .then(json => {
        dispatch(createMaterialSuccess(json));
        return json;
      })
      .catch(error => {
        dispatch(createMaterialFailure(error));
      })
    return res;
  }

}

export function createMaterialBegin() {
  return {
    type: CREATE_MATERIAL_BEGIN,
  }
}


export function createMaterialSuccess(json) {
  return {
    type: CREATE_MATERIAL_SUCCESS,
    payload: {
      material: json.data.parcel_material
    }
  }
}


export function createMaterialFailure(error) {
  return {
    type: CREATE_MATERIAL_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
  }
}


export function addMaterialStockBegin() {
  return {
    type: ADD_MATERIAL_STOCK_BEGIN
  }
}

export function addMaterialStockSuccess(json) {
  return {
    type: ADD_MATERIAL_STOCK_SUCCESS,
    payload: json.data.parcel_material_stocks

  }
}

export function addMaterialStockFailure(error) {
  return {
    type: ADD_MATERIAL_STOCK_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
  }
}

export function setMaterialFilter(filters) {
  return {
    type: SET_MATERIAL_FILTER,
    payload: {filters}
  }
}

export function clearMaterialFilters() {
  return {
    type: CLEAR_MATERIAL_FILTER
  }
}

export function clearMaterials() {
  return {
    type: CLEAR_MATERIALS
  }
}

export function clearMaterialsNotification() {
  return {
    type: CLEAR_MATERIAL_NOTIFICATION
  }
}

// Private methods

function handleErrors(response) {
  if (!response.success) {
    throw Error(response.statusText);
  }
  return response;
}

function handleMaterialMessageErrors(response) {
  if (!response.success) {
    throw Error(response.statusText);
  }
  return response;
}

export function deleteInventoryLocation(data, id) {
  return dispatch => {
    dispatch(updateMaterialBegin());
    return adminApi.deleteInventoryLocation(data, id)
      .then(json => {
        dispatch(updateMaterialSuccess(json));
        return json;
      })
      .catch(error => {
        dispatch(updateMaterialFailure(error))
      });
  }
}

export function addInventoryLocation(data, id) {
  return dispatch => {
    dispatch(updateMaterialBegin());
    return adminApi.addInventoryLocation(data, id)
      .then(json => {
        dispatch(updateMaterialSuccess(json));
        return json;
      })
      .catch(error => {
        dispatch(updateMaterialFailure(error))
      });
  }
}

export function getLocationsByBarcode(term, warehouseId) {
  return dispatch => {
    dispatch(getLocationBegin());
    return adminApi.getLocationsByBarcode(term, warehouseId)
      .then(json => {
        dispatch(getLocationsByBarcodeSuccess(json.results));
        return json;
      })
      .catch(error => {
        dispatch(getLocationsByBarcodeFailure(error))
      });
  }
}

export const getLocationsByBarcodeSuccess = (data) => ({
  type: FETCH_LOCATIONS_BY_BARCODE_SUCCESS,
  payload: {data}
})

export const getLocationsByBarcodeFailure = error => ({
  type: FETCH_LOCATIONS_BY_BARCODE_FAILURE,
  payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});

export function getlocations(term, warehouseId = 1) {
  return dispatch => {
    dispatch(getLocationBegin());
    return adminApi.getSkuLocations(term, warehouseId)
      .then(json => {
        dispatch(getlocationsSuccess(json.results));
        return json;
      })
      .catch(error => {
        dispatch(getlocationsFailure(error))
      });
  }
}

export const getLocationBegin = (data) => ({
  type: FETCH_LOCATIONS_BEGIN
})

export const getlocationsSuccess = (data) => ({
  type: FETCH_LOCATIONS_SUCCESS,
  payload: {data}
})

export const getlocationsFailure = error => ({
  type: FETCH_LOCATIONS_FAILURE,
  payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});
