import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {
    Popover,
    CardHeader,
    Divider,
    colors,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import GroupIcon from '@material-ui/icons/Group';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import * as Storage from 'utils/storage';
import {useDispatch} from 'react-redux';
import {signOutUser} from 'redux-token-auth-config';
import useRouter from 'utils/useRouter';
import {reverseUserRoute} from 'routes';
import {removeUserDetails} from "../../actions/userActions";

const useStyles = makeStyles(() => ({
    root: {
        width: 200,
        maxWidth: '100%'
    },
    actions: {
        backgroundColor: colors.grey[50],
        justifyContent: 'center'
    }
}));

const ProfilePopover = props => {
    const classes = useStyles();
    const {notifications, anchorEl, disabled, ...rest} = props;

    const dispatch = useDispatch();
    const {history} = useRouter();

  const handleLogout = async () => {
    try {
      await dispatch(signOutUser())
    } catch (e) {

    } finally {
      removeUserDetails();
    }

  };

    return (
        <Popover
            {...rest}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
        >
            <div className={classes.root} onClick={props.onClose}>
                <CardHeader title="Account" />
                <Divider />
                <List component='div' aria-label='profile tab'>

                    {/* See Profile */}
                    <RouterLink to={reverseUserRoute('Profile')}>
                        <ListItem button>
                            <ListItemIcon>
                                <SentimentSatisfiedIcon />
                            </ListItemIcon>
                            <ListItemText primary={<Typography>My Profile</Typography>} />
                        </ListItem>
                    </RouterLink>

                    {/* Manage Team */}
                    <RouterLink to={disabled ? "#" : reverseUserRoute('OrganisationSettings')}>
                        <ListItem button disabled={disabled}>
                            <ListItemIcon>
                                <GroupIcon />
                            </ListItemIcon>
                            <ListItemText primary={<Typography>Manage Team</Typography>} />
                        </ListItem>
                    </RouterLink>

                    {/* Log Out */}
                    <ListItem button onClick={handleLogout}>
                        <ListItemIcon>
                            <InputIcon />
                        </ListItemIcon>
                        <ListItemText primary="Log Out" />
                    </ListItem>
                </List>


            </div>
        </Popover>
    );
};

ProfilePopover.propTypes = {
    anchorEl: PropTypes.any,
    className: PropTypes.string,
    notifications: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default ProfilePopover;
