import {apiPath, apiPathV2, authHeaders} from 'AppConstants';
import store from 'index';
import keepspaceApi from 'services/keepspaceApi';
import { apiErrorHandler } from 'utils/apiErrorHandler';

export const FETCH_ORDERS_BEGIN = 'FETCH_ORDERS_BEGIN';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const CLEAR_ORDERS = 'CLEAR_ORDERS';
export const FETCH_MORE_ORDERS_SUCCESS = 'FETCH_MORE_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE';
export const FETCH_ORDERS_PAGINATION = 'FETCH_ORDERS_PAGINATION';
export const FETCH_ORDER_BY_ID_BEGIN = 'FETCH_ORDER_BY_ID_BEGIN';
export const FETCH_ORDER_BY_ID_SUCCESS = 'FETCH_ORDER_BY_ID_SUCCESS';
export const FETCH_ORDER_BY_ID_FAILURE = 'FETCH_ORDER_BY_ID_FAILURE';
export const SET_PER_PAGE_NUMBER = 'SET_PER_PAGE_NUMBER';
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
export const INCREASE_PAGE_NUMBER = 'INCREASE_PAGE_NUMBER';

export const SEND_ORDER_MESSAGE_BEGIN = 'SEND_ORDER_MESSAGE_BEGIN';
export const SEND_ORDER_MESSAGE_SUCCESS = 'SEND_ORDER_MESSAGE_SUCCESS';
export const SEND_ORDER_MESSAGE_FAILURE = 'SEND_ORDER_MESSAGE_FAILURE';

export const SET_NEW_ORDER_IN_PROGRESS = 'SET_NEW_ORDER_IN_PROGRESS';
export const DESTROY_NEW_ORDER = 'DESTROY_NEW_ORDER';
export const CREATE_NEW_ORDER = 'CREATE_NEW_ORDER';
export const ADD_SKU_TO_ORDER = 'ADD_SKU_TO_ORDER';
export const MOD_SKU_QUANTITY_TO_ORDER = 'MOD_SKU_QUANTITY_TO_ORDER';
export const ADD_FORMDATA_TO_ORDER = 'ADD_FORMDATA_TO_ORDER';

export const CANCEL_ORDERS_SUCCESS = 'CANCEL_ORDERS_SUCCESS';
export const CANCEL_ORDERS_FAILURE = 'CANCEL_ORDERS_FAILURE';

export const SEND_ORDER_BEGIN = 'SEND_ORDER_BEGIN';
export const SEND_ORDER_SUCCESS = 'SEND_ORDER_SUCCESS';
export const SEND_ORDER_FAILURE = 'SEND_ORDER_FAILURE';

export const UPDATE_ORDER_BEGIN = 'UPDATE_ORDER_BEGIN';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE';

export const UPDATE_STATUS_FILTER = 'UPDATE_STATUS_FILTER';
export const UPDATE_ORDER_FILTER_VALUE = 'UPDATE_ORDER_FILTER_VALUE';
export const UPDATE_SEARCH_VALUE = 'UPDATE_SEARCH_VALUE';
export const UPDATE_CREATED_AT_FILTER = 'UPDATE_CREATED_AT_FILTER';
export const UPDATE_PROCESSED_ON_FILTER = 'UPDATE_PROCESSED_ON_FILTER';

export const SET_PREVIOUS_PATH = 'SET_PREVIOUS_PATH';


const NS = '@keepspace/order';

export const actionTypes = {
    FETCH_ORDERS_BEGIN: `${NS}/FETCH_ORDERS_BEGIN`,
    FETCH_ORDERS_SUCCESS: `${NS}/FETCH_ORDERS_SUCCESS`,
    FETCH_MORE_ORDERS_SUCCESS: `${NS}/FETCH_MORE_ORDERS_SUCCESS`,
    FETCH_ORDERS_FAILURE: `${NS}/FETCH_ORDERS_FAILURE`,
    FETCH_ORDERS_PAGINATION: `${NS}/FETCH_ORDERS_PAGINATION`,
    FETCH_ORDER_BY_ID_BEGIN: `${NS}/FETCH_ORDER_BY_ID_BEGIN`,
    FETCH_ORDER_BY_ID_SUCCESS: `${NS}/FETCH_ORDER_BY_ID_SUCCESS`,
    FETCH_ORDER_BY_ID_FAILURE: `${NS}/FETCH_ORDER_BY_ID_FAILURE`,
    INCREASE_PAGE_NUMBER: `${NS}/INCREASE_PAGE_NUMBER`,
}

const action = (type, payload) => ({type, payload});

const actions = {
    fetchOrderById: (payload = {}) => {
        return dispatch => {
            dispatch(action(actionTypes.FETCH_ORDER_BY_ID_BEGIN, payload))
            const {id} = payload;

            return keepspaceApi.getOrderById(id)
                .then(order => dispatch(action(actionTypes.FETCH_ORDER_BY_ID_SUCCESS, {order})))
                .catch(err => dispatch(action(actionTypes.FETCH_ORDER_BY_ID_FAILURE, err)))
        }
    }
}

export function cancelOrders(ids) {
    return dispatch => {
        return fetch(
            (apiPath + '/bookings/batch_cancel'),
            {
                method: 'PATCH',
                body: JSON.stringify({
                    status: 'canceled',
                    ids: ids
                }),
                headers: {
                    ...authHeaders,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            }
        )
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(cancelOrdersSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(cancelOrdersFailure(error));
            });
    }
}

export const cancelOrdersSuccess = json => ({
    type: CANCEL_ORDERS_SUCCESS,
    payload: json
})

export const cancelOrdersFailure = error => ({
    type: CANCEL_ORDERS_FAILURE,
    payload: {error}
})

export function fetchOrdersProcedure() {
    return dispatch => {
        dispatch(fetchOrdersBegin());
        let perPage, page, orderType = [], statuses = [], startDate, apiUrl = "", endDate, processedStartDate, processedEndDate, searchTerm, sources;

        try {
            const state = store.getState();
            perPage = state.orderReducer.perPage;
            page = state.orderReducer.page;
            orderType = state.orderReducer.inOuts;
            statuses = state.orderReducer.statuses;
            startDate = state.orderReducer.startDate;
            endDate = state.orderReducer.endDate;
            processedStartDate = state.orderReducer.processedStartDate;
            processedEndDate = state.orderReducer.processedEndDate;
            sources = state.orderReducer.sources;
            searchTerm = state.orderReducer.searchTerm;

        } catch {
            perPage = 25;
            page = 1;
        }

        apiUrl = `/bookings?per_page=${perPage}&page=${page}`;

        if(orderType.length) {
            let filtered = orderType.filter(obj => obj.isChecked == true);
            let r = [], orderLink = "";
            let res = filtered.map(function (o) {
                r.push(o.value);
                return r;
            });
            for(const element of r) {
                orderLink = orderLink + `&order_types[]=${element}`;
            }
            apiUrl = apiUrl + orderLink;

        }

        if(statuses.length) {
            let filtered1 = statuses.filter(obj => obj.isChecked == true);
            let r = [], statusLink = "";
            let res = filtered1.map(function (o) {

                r.push(o.value);
                return r;
            });
            for(const element of r) {
                statusLink = statusLink + `&statuses[]=${element}`;
            }
            apiUrl = apiUrl + statusLink;
        }
        if(startDate) {
            apiUrl = apiUrl + `&created_from_date=${startDate}`;
        }
        if(endDate) {
            apiUrl = apiUrl + `&created_to_date=${endDate}`;
        }
        if(processedStartDate) {
            apiUrl = apiUrl + `&processed_from_date=${processedStartDate}`;
        }
        if(processedEndDate) {
            apiUrl = apiUrl + `&processed_to_date=${processedEndDate}`;
        }

        if(searchTerm) {
            apiUrl = apiUrl + `&user_search_order_summary=${searchTerm}`;
        }


        fetch(apiPathV2 + apiUrl, {
            method: 'GET',
            headers: authHeaders
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                if(json.success == true) {
                    dispatch(fetchOrdersSuccess(json))
                    return json;
                }
                else {
                    return dispatch(fetchOrdersFailure())
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(fetchOrdersFailure(error))
            })

    }
}

export function fetchOrderByID(id) {
    return dispatch => {
        dispatch(fetchOrderByIDBegin());
        return fetch(
            (apiPath + '/bookings/' + id),
            {
                method: 'GET',
                headers: authHeaders
            }
        )
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(fetchOrderByIDSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(fetchOrderByIDFailure(error));
                return error;
            });
    }
}

export function sendOrderMessage(messageText, orderId) {
    return dispatch => {
        dispatch(sendOrderMessageBegin());
        return fetch(
            `${apiPath}/bookings/${orderId}/new_comment`,
            {
                method: 'post',
                body: JSON.stringify({
                    body: messageText,
                }),
                //credentials: 'include',
                headers: {
                    ...authHeaders,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            }
        ).then(handleOrderMessageErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(sendOrderMessageSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error);
                dispatch(sendOrderMessageFailure);
            });
    }
}


export const sendOrderMessageBegin = () => ({
    type: SEND_ORDER_MESSAGE_BEGIN
})

export const sendOrderMessageSuccess = message => ({
    type: SEND_ORDER_MESSAGE_SUCCESS,
    payload: {message}
})


export const sendOrderMessageFailure = error => ({
    type: SEND_ORDER_MESSAGE_FAILURE,
    payload: {error}
})

export const fetchOrdersBegin = () => ({
    type: FETCH_ORDERS_BEGIN
});

export const fetchOrdersSuccess = json => ({
    type: FETCH_ORDERS_SUCCESS,
    payload: {
        orders: json.data.bookings,
        filters: json.data.filters,
        pagination: json.data.pagination
    }
});

export const resetOrders = () => ({
    type: CLEAR_ORDERS
});


export const fetchOrdersPagination = pagination => ({
    type: FETCH_ORDERS_PAGINATION,
    payload: {pagination}
})

export const fetchOrdersFailure = error => ({
    type: FETCH_ORDERS_FAILURE,
    payload: {error}
});

export const fetchOrderByIDBegin = () => ({
    type: FETCH_ORDER_BY_ID_BEGIN
});

export const fetchOrderByIDSuccess = order => ({
    type: FETCH_ORDER_BY_ID_SUCCESS,
    payload: {order}
});

export const fetchOrderByIDFailure = error => ({
    type: FETCH_ORDER_BY_ID_FAILURE,
    payload: {error}
});

export function setNewOrderInProgress(isTrue, orderType) {
    return {type: SET_NEW_ORDER_IN_PROGRESS, isTrue, orderType};
};

export function destroyNewOrder() {
    return {type: DESTROY_NEW_ORDER};
};

export function createNewOrder() {
    return {type: CREATE_NEW_ORDER};
};

export function addSkuToOrder(sku, skuQty = 1) {
    const state = store.getState();
    const retailPrice = state.spaceReducer.items.filter(x => x.id === sku.id).map(x => x.retail_cost) || [""];
    return {
        type: ADD_SKU_TO_ORDER,
        item: state.spaceReducer.items.filter(item => {
            return (item.id === sku.id)
        })[0],
        qty: skuQty,
        price: sku.sale_price || sku.retail_cost || retailPrice[0],
        currency: sku.sale_currency || 'AUD'

    };
};

export function modSkuQuantityToOrder(skuId, quantityVariation, price, currency) {
    return {
        type: MOD_SKU_QUANTITY_TO_ORDER,
        item: store.getState().spaceReducer.items.filter(item => {
            return (item.id === skuId)
        })[0],
        quantityVariation: quantityVariation,
        price: price,
        currency: currency || 'AUD',
    };
};

export function addFormDataToOrder(id, value) {
    return {
        type: ADD_FORMDATA_TO_ORDER,
        id: id,
        value: value,
    }
}





// // Inbound
// export function sendOrderInbound(formData) {
//     return dispatch => {
//         dispatch(sendOrderBegin());
//         return fetch(
//             (apiPath + '/bookings/pick-up'),
//             {
//                 method: 'POST',
//                 headers: {
//                     ...authHeaders,
//                     'Content-Stype': 'application/json'
//                 },
//                 body: JSON.stringify(formData),
//             }
//         )
//             .then(handleErrors)
//             .then(res => res.json())
//             .then(json => {
//                 dispatch(sendOrderSuccess(json));
//             })
//             .catch(error => {
//                 dispatch(sendOrderFailure(error));
//             });
//     }
// }

export function sendOrderInbound(orderDetails, items) {
    return dispatch => {
        dispatch(sendOrderBegin());
        let res = keepspaceApi.createInbound(orderDetails, items)
            .then(json => {
                dispatch(sendOrderSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(sendOrderFailure(error));
            })
        return res;
    }

}

export function sendOrderOutbound(orderDetails, items) {
    return dispatch => {
        dispatch(sendOrderBegin());
        let res = keepspaceApi.createOutbound(orderDetails, items)
            .then(json => {
                dispatch(sendOrderSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(sendOrderFailure(error));
            })
        return res;
    }

}

export function updateOrderInbound(orderDetails, items) {
    return dispatch => {
        dispatch(updateOrderBegin());
        let res = keepspaceApi.editInbound(orderDetails, items)
            .then(json => {
                dispatch(updateOrderSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(updateOrderFailure(error));
            })
        return res;
    }
}

export function updateOrderOutbound(orderDetails, items) {
    return dispatch => {
        dispatch(updateOrderBegin());
        let res = keepspaceApi.editOutbound(orderDetails, items)
            .then(json => {
                dispatch(updateOrderSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(updateOrderFailure(error));
            })
        return res;
    }
}



export function sendOrderBegin() {
    return {
        type: SEND_ORDER_BEGIN,
    }
}


export function sendOrderSuccess(json) {
    return {
        type: SEND_ORDER_SUCCESS,
        payload: {
            order: json
        }
    }
}


export function sendOrderFailure(error) {
    return {
        type: SEND_ORDER_FAILURE,
        payload: {error}
    }
}

export function updateOrderBegin() {
    return {
        type: UPDATE_ORDER_BEGIN
    }
}

export function updateOrderSuccess(json) {
    return {
        type: UPDATE_ORDER_SUCCESS,
        payload: {
            order: json
        }
    }
}


export function updateOrderFailure(error) {
    return {
        type: UPDATE_ORDER_FAILURE,
        payload: {error}
    }
}

export function updateStatusFilter(value, isChecked) {
    return {
        type: UPDATE_STATUS_FILTER,
        value,
        isChecked,
    }
}

export function updateOrderFilterValue(key, value, isChecked) {
    return {
        type: UPDATE_ORDER_FILTER_VALUE,
        key,
        value,
        isChecked
    }
}

export function updateSearchValue(searchTerm) {
    return {
        type: UPDATE_SEARCH_VALUE,
        searchTerm,
    }
}

export function updateCreatedOnFilter(startDate, endDate) {
    return {
        type: UPDATE_CREATED_AT_FILTER,
        startDate,
        endDate
    }
}

export function updateProcessedOnFilter(startDate, endDate) {
    return {
        type: UPDATE_PROCESSED_ON_FILTER,
        startDate,
        endDate
    }
}

export function setPreviousPath(previousPath) {
    return {
        type: SET_PREVIOUS_PATH,
        previousPath
    }
}

// Private methods

function handleErrors(response) {
    if(!response.ok) {
        apiErrorHandler(response);
        throw Error(response.status);
    }
    return response;
}

function handleOrderMessageErrors(response) {
    if(!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export default actions;
