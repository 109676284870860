import React from 'react'
import {
    Card,
    CardHeader,
} from '@material-ui/core';
import { PropTypes } from 'prop-types';

export const CardWithTitle = props => (
    <Card className={props.className} >
        <CardHeader title={props.title} />
        {props.children}
    </Card>
)

CardWithTitle.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.object
}

export default CardWithTitle;