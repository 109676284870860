import React, {Fragment, useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/styles';
import {SwipeableDrawer, Divider, Paper, Avatar, Typography} from '@material-ui/core';
import {Hidden} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import useRouter from 'utils/useRouter';
import {Navigation} from 'components';
import navigationConfig from './navigationConfig';
import checkPermission from "components/checkPermission";

import {reverseUserRoute} from 'routes';
// import cow from "assets/img/avatars/svg/001-cow.svg"

const useStyles = makeStyles(theme => ({
  root: {
    // height: '100vh',
    overflowY: 'auto',
    width: '256px'
  },
  content: {
    padding: theme.spacing(2),
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60,
    cursor: 'pointer'
  },
  name: {
    marginTop: theme.spacing(1),
    textTransform: 'uppercase',
    textAlign: "center",
    width: "100%"
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  navigation: {
    marginTop: theme.spacing(2),
  },
  swipeableDrawer: {
    width: "256px"
  }
}));

const NavBar = props => {
  const {openMobile, onMobileClose, onMobileOpen, className, ...rest} = props;

  const classes = useStyles();
  const router = useRouter();
  const currentOrganisation = useSelector(state => state.organisationsReducer.currentOrganisation);

  useEffect(() => {
    if(openMobile) {
      onMobileClose && onMobileClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);
  let logo

  if(currentOrganisation.id) {
    logo =
      <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        src={currentOrganisation.logo.url ? currentOrganisation.logo.url : require(`assets/img/avatars/00${currentOrganisation.id % 46 + 1}.svg`)}
        to={checkPermission(currentOrganisation.currentOrganisationUser.role, "organisations:view") ? reverseUserRoute('OrganisationSettings') : "#"}
      />
  }

  const navbarContent = (
    <div className={classes.content}>
      <div className={classes.profile}>
        {logo ? logo : <Skeleton animation="wave" variant="circle" width={60} height={60}></Skeleton>}
        <Typography
          className={classes.name}
          variant="h4"
        >
          {!currentOrganisation?.name ? <Skeleton animation="wave" width="100%"></Skeleton> : currentOrganisation.name}
        </Typography>
        <Typography
          className={classes.name}
          variant="subtitle2"
        >
          {currentOrganisation.currentOrganisationUser ? currentOrganisation.currentOrganisationUser.role : <Skeleton animation="wave" width="100%"></Skeleton>}
        </Typography>
      </div>
      <Divider className={classes.divider} />
      <nav className={classes.navigation}>
        {navigationConfig.map(list => (
          <Navigation
            component="div"
            key={list.title}
            pages={list.pages}
            title={list.title}
            currentOrganisation={currentOrganisation}
          />
        ))}
      </nav>
    </div>
  );
  //const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari']);

  return (
    <Fragment>
      <Hidden mdUp>
        <SwipeableDrawer
          anchor="left"
          onClose={onMobileClose}
          onOpen={onMobileOpen}
          open={openMobile}
          variant="temporary"

        >
          <div
            {...rest}
            className={clsx(classes.root, className)}
          >
            {navbarContent}
          </div>
        </SwipeableDrawer>
      </Hidden>
      <Hidden smDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
