import React, { useEffect } from 'react'

import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import {
    CircularProgress,
    Paper
} from '@material-ui/core';

import { useParams, Route } from 'react-router-dom';
import { acceptInvitation } from 'actions/organisationUserActions'
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
        height: '100%',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        boxShadow: 'none'
    },
    header: {
        fontSize: theme.spacing(3.5),
        fontWeight: 'lighter',
        padding: '50px 0'
    },
}));

const InvitationRedirect = props => {
  const dispatch = useDispatch();
  const { history } = props;

  const classes = useStyles();
  const { uid } = useParams();

  useEffect(() => {
    if (uid) {
      dispatch(acceptInvitation({uid: uid}))
      .then(response => {
        history.push("/auth/login");
      })
    }
  }, [uid, dispatch]);

  return (
      <Paper className={classes.paper}>
          <div className={classes.header}> Accepting Invitation... </div>
          <CircularProgress />
      </Paper>
  )
}

InvitationRedirect.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default InvitationRedirect;
