import React, { Component } from 'react';
import { withStyles, FormControl, MenuItem, Checkbox, ListItemText, Button } from '@material-ui/core';

import OrderFilterCollapse from 'views/user/Orders/OrderFilterCollapse'

import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';

import Motorcycle from '@material-ui/icons/Motorcycle';
import SwapVert from '@material-ui/icons/SwapVert';
import Opacity from '@material-ui/icons/Opacity';
import DateRange from '@material-ui/icons/DateRange';
import Event from '@material-ui/icons/Event';


class OrderFilterDrawerContents extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    this.handleDateChange = this.handleDateChange.bind(this)

  }

  handleDateChange(date, range) {
    const today = null;
    if (range === 'startDate' || range === 'endDate') {
      let createdOnDates = { startDate: this.props.startDate || today, endDate: this.props.endDate || today }
      createdOnDates[range] = date;
      let startDateNew = (createdOnDates.startDate) ? createdOnDates.startDate.format('DD-MM-YYYY') : today;
      let endDateNew = (createdOnDates.endDate) ? createdOnDates.endDate.format('DD-MM-YYYY') : today;
      this.props.updateState("startDate", createdOnDates.startDate || today);
      this.props.updateState("endDate", createdOnDates.endDate || today);
      this.props.updateState("startDateNew", startDateNew);
      this.props.updateState("endDateNew", endDateNew, true);
    } else {
      let processedOnDates = { processedStartDate: this.props.processedStartDate || today, processedEndDate: this.props.processedEndDate || today }
      processedOnDates[range] = date;

      let processedStartDateNew = (processedOnDates.processedStartDate) ? processedOnDates.processedStartDate.format('DD-MM-YYYY') : today;
      let processedEndDateNew = (processedOnDates.processedEndDate) ? processedOnDates.processedEndDate.format('DD-MM-YYYY') : today;
      this.props.updateState("processedStartDate", processedOnDates.processedStartDate || today);
      this.props.updateState("processedEndDate", processedOnDates.processedEndDate || today);
      this.props.updateState("processedStartDateNew", processedStartDateNew);
      this.props.updateState("processedEndDateNew", processedEndDateNew, true);

    }

  }

  render() {
    const { classes, statusClick, sortKeyClick, handleClearFilters, updateState } = this.props;
    return (
      <>

        <OrderFilterCollapse title='Status' icon={Motorcycle}>

          <FormControl className={classes.selectForm}>

            {this.props.statuses.map(status => (
              <MenuItem key={status.value} id={status.value} value={status.value} onClick={() => statusClick(status.value, !status.isChecked)} >
                <Checkbox checked={status.isChecked} value={status.value} />
                <ListItemText primary={status.text} />
              </MenuItem>
            ))}

          </FormControl>

        </OrderFilterCollapse>


        <OrderFilterCollapse title='In / Out' icon={SwapVert} collapseItems={['Test']}>
          <FormControl className={classes.selectForm}>
            {this.props.inOuts.map(sortKey => (
              <MenuItem key={sortKey.value} id={sortKey.value} value={sortKey.value} onClick={() => sortKeyClick('inOuts', sortKey.value, !sortKey.isChecked)} >
                <Checkbox checked={sortKey.isChecked} value={sortKey.value} />
                <ListItemText primary={sortKey.text} />
              </MenuItem>
            ))}
          </FormControl>
        </OrderFilterCollapse>

        <OrderFilterCollapse title='Created between' icon={DateRange} collapseItems={['Test']}>
          <FormControl className={classes.selectForm}>
            <div className={classes.datePicker}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM-DD-YYYY"
                id="date-picker-inline"
                label="Start"
                value={this.props.startDate || null}
                onChange={e => this.handleDateChange(e, 'startDate')}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                maxDate={this.props.endDate || moment()}
                className={classes.calendar}
                error={false}
                helperText={null}
              />
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM-DD-YYYY"
                id="date-picker-inline"
                label="End"
                value={this.props.endDate || null}
                onChange={e => this.handleDateChange(e, 'endDate')}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className={classes.calendar}
                error={false}
                helperText={null}
              />
            </div>
          </FormControl>
        </OrderFilterCollapse>

        <OrderFilterCollapse title='Processed between' icon={Event} collapseItems={['Test']}>
          <FormControl className={classes.selectForm}>
            <div className={classes.datePicker}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM-DD-YYYY"
                id="date-picker-inline"
                label="Start"
                value={this.props.processedStartDate || null}
                onChange={e => this.handleDateChange(e, 'processedStartDate')}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                maxDate={this.props.processedEndDate || moment()}
                className={classes.calendar}
                error={false}
                helperText={null}
              />
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM-DD-YYYY"
                id="date-picker-inline"
                label="End"
                value={this.props.processedEndDate || null}
                onChange={e => this.handleDateChange(e, 'processedEndDate')}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className={classes.calendar}
                error={false}
                helperText={null}
              />
            </div>
          </FormControl>
        </OrderFilterCollapse>
        <div className={classes.footer}>
          <Button onClick={handleClearFilters}>
            Clear filters
          </Button>
        </div>
      </>
    )
  }
}

const styles = theme => ({
  root: {

  },
  selectForm: {
    width: '100%'
  },
  datePicker: {
    display: 'flex',
    flexDirection: 'row'
  },
  calendar: {
    margin: 10
  },
  footer: {
    float: 'right',
    padding: '20px'
  }
})

export default withStyles(styles)(OrderFilterDrawerContents);
