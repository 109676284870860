import React from 'react';

import {
  makeStyles,
  Typography,
  CircularProgress
} from '@material-ui/core';

const Notice = props => {

  const { sub1, sub2, progressBar } = props;
  const classes = useStyles();

  return (
    <div className={classes.textCenter}>
      <Typography variant='subtitle1' gutterBottom>{sub1}</Typography>
      <Typography variant='subtitle2' gutterBottom>{sub2}</Typography>
      { progressBar ? <CircularProgress size='20vH'/> : null}
    </div>
  );
}

const useStyles = makeStyles(() => ({
    textCenter: {
      textAlign: 'center'
    }
}));

export default Notice;
