import React, { memo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  Paper,
} from "@material-ui/core";
import Pagination from "layouts/Pagination";
import moment from "moment";
import StatusCell from "./StatusCell";
import OrdersEditBar from "./OrdersEditBar";

import { useDispatch } from "react-redux";
import { cancelOrders } from "actions/orderActions";
import ConfirmAlert from "components/Help/ConfirmAlert";
import { UID } from "AppConstants";

const styles = (theme) => ({
  root: {
    //marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    overflow: "auto",
    marginBottom: theme.spacing(3),
  },
  marginBottom: {},
  link: {
    textDecoration: "none",
    color: "black",
  },
  hidden: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  checkboxCell: {
    marginRight: 10,
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end",
    flexWrap: "wrap",
    "& .MuiTablePagination-actions": {
      display: "none",
    },
    "& .MuiTablePagination-input + .MuiTablePagination-caption": {
      display: "none",
    },
  },
  pageTilesContainer: {
    display: "flex",
  },
  pageTile: {
    border: "1px #8080803d solid",
    height: 20,
    minWidth: 20,
    width: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 2,
    cursor: "pointer",
  },
  navigationTile: {
    border: "1px #8080803d solid",
    height: 20,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 2,
    cursor: "pointer",
  },
});

function arrayFromRange(start, stop, step) {
  var a = [start],
    b = start;
  while (b < stop) {
    a.push((b += step || 1));
  }
  return a;
}

export function SimpleTable(props) {
  const { classes, data, updateSuccess, pagination } = props;

  const dispatch = useDispatch();
  const [selectedOrdersIds, setSelectedOrdersIds] = useState([]);
  const page = pagination.current_page;
  const rowsPerPage = pagination.per_page;
  const maxPage = pagination.total_pages || 1;
  const totalCount = pagination.total_count;
  const [openAlert, setOpenAlert] = useState(false);

  const handleSelectAll = (event) => {
    const selectedOrdersIds = event.target.checked
      ? data.map((order) => order.id)
      : [];

    setSelectedOrdersIds(selectedOrdersIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedOrdersIds.indexOf(id);
    let newSelectedOrdersIds = [];

    if (selectedIndex === -1) {
      // when new order is selected
      newSelectedOrdersIds = newSelectedOrdersIds.concat(selectedOrdersIds, id);
    } else if (selectedIndex >= 0) {
      // when order is un-selected
      newSelectedOrdersIds = selectedOrdersIds.filter(
        (selectedId) => selectedId !== id
      );
    }

    setSelectedOrdersIds(newSelectedOrdersIds);
  };

  useEffect(() => {
    setSelectedOrdersIds([]);
  }, [updateSuccess]);

  const handleChangePage = (event, pageNumber) => {
    setSelectedOrdersIds([]);
    props.updateState("page", pageNumber, true);
  };

  const handleChangeRowsPerPage = (event) => {
    setSelectedOrdersIds([]);
    props.updateState("page", 1);
    props.updateState(
      "perPage",
      event.target.value === "All"
        ? pagination.total_count
        : event.target.value,
      true
    );
  };

  const handleCancel = (event) => {
    dispatch(cancelOrders(selectedOrdersIds));
    handleAlertClose();
  };

  const handleAlertOpen = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const headCells = [
    { id: "id", isHidden: false, label: "Order", align: "left" },
    { id: "warehouse", isHidden: false, label: "Warehouse", align: "center" },
    { id: "orderType", isHidden: false, label: "In/Out" },
    { id: "source", isHidden: true, label: "Source" },
    { id: "created", isHidden: false, label: "Created" },
    { id: "processed", isHidden: true, label: "Processed" },
    { id: "name", isHidden: false, label: "Name" },
    { id: "status", isHidden: false, label: "Status" },
    { id: "skus", isHidden: true, label: "SKUs" },
    { id: "value", isHidden: true, label: "Value" },
  ];
  return (
    <div className={classes.root1}>
      <ConfirmAlert
        open={openAlert}
        description="Do you really want to cancel these orders?"
        onSubmit={handleCancel}
        onClose={handleAlertClose}
      />

      <Typography color="textSecondary" gutterBottom variant="body2">
        {totalCount} Records found. Page {page} of {maxPage}
      </Typography>
      <Paper className={classes.paper1}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedOrdersIds?.length === rowsPerPage}
                  color="primary"
                  indeterminate={
                    selectedOrdersIds?.length > 0 &&
                    selectedOrdersIds?.length < data.length
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.align ? headCell.align : "center"}
                  className={headCell.isHidden ? classes.hidden : ""}
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow
                selected={selectedOrdersIds.indexOf(row.id) !== -1}
                key={row.id}
              >
                <TableCell align="center" padding="checkbox">
                  <Checkbox
                    checked={selectedOrdersIds.indexOf(row.id) !== -1}
                    color="primary"
                    onChange={(event) => handleSelectOne(event, row.id)}
                    value={selectedOrdersIds.indexOf(row.id) !== -1}
                  />
                </TableCell>
                <TableCell>
                  <Link
                    to={{
                      pathname: `/user/${UID}/orders/summary/${row.id}/details`,
                      state: { from: `/user/${UID}/orders/summary/` },
                    }}
                    key={row.id}
                    className={classes.link}
                  >
                    {row.id}
                    <Typography variant="body2">{row.reference}</Typography>
                  </Link>
                </TableCell>
                <TableCell align="center">
                  <Typography>{row?.warehouse?.name}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Link
                    to={{
                      pathname: `/user/${UID}/orders/summary/${row.id}/details`,
                      state: { from: `/user/${UID}/orders/summary/` },
                    }}
                    key={row.id}
                    className={classes.link}
                  >
                    {row.order_type.string}
                  </Link>
                </TableCell>
                <TableCell align="center" className={classes.hidden}>
                  <Link
                    to={{
                      pathname: `/user/${UID}/orders/summary/${row.id}/details`,
                      state: { from: `/user/${UID}/orders/summary/` },
                    }}
                    key={row.id}
                    className={classes.link}
                  >
                    {row.source ? row.source : "N/A"}
                  </Link>
                </TableCell>
                <TableCell align="center">
                  <Link
                    to={{
                      pathname: `/user/${UID}/orders/summary/${row.id}/details`,
                      state: { from: `/user/${UID}/orders/summary/` },
                    }}
                    key={row.id}
                    className={classes.link}
                  >
                    {moment(row.created_at).format("DD/MM")}
                  </Link>
                </TableCell>
                {/* see for formatting: https://momentjs.com/docs/#/displaying/  */}
                <TableCell align="center" className={classes.hidden}>
                  <Link
                    to={{
                      pathname: `/user/${UID}/orders/summary/${row.id}/details`,
                      state: { from: `/user/${UID}/orders/summary/` },
                    }}
                    key={row.id}
                    className={classes.link}
                  >
                    {row.processed_on
                      ? moment(row.processed_on).format("DD/MM")
                      : "-"}
                  </Link>
                </TableCell>
                <TableCell align="center">
                  <Link
                    to={{
                      pathname: `/user/${UID}/orders/summary/${row.id}/details`,
                      state: { from: `/user/${UID}/orders/summary/` },
                    }}
                    key={row.id}
                    className={classes.link}
                  >
                    {row.contact_name}
                  </Link>
                </TableCell>
                <TableCell align="center">
                  <Link
                    to={{
                      pathname: `/user/${UID}/orders/summary/${row.id}/details`,
                      state: { from: `/user/${UID}/orders/summary/` },
                    }}
                    key={row.id}
                    className={classes.link}
                  >
                    {row.status_obj ? (
                      <StatusCell status={row.status_obj} />
                    ) : (
                      row.status
                    )}
                  </Link>
                </TableCell>
                <TableCell align="center" className={classes.hidden}>
                  <Link
                    to={{
                      pathname: `/user/${UID}/orders/summary/${row.id}/details`,
                      state: { from: `/user/${UID}/orders/summary/` },
                    }}
                    key={row.id}
                    className={classes.link}
                  >
                    {row.booking_items ? row.booking_items.length : 0}
                  </Link>
                </TableCell>
                <TableCell align="center" className={classes.hidden}>
                  <Link
                    to={{
                      pathname: `/user/${UID}/orders/summary/${row.id}/details`,
                      state: { from: `/user/${UID}/orders/summary/` },
                    }}
                    key={row.id}
                    className={classes.link}
                  >
                    {row.total_price ? "$" + row.total_price : null}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {maxPage ? (
          <CardActions className={classes.actions}>
            <Pagination
              pagination={pagination}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </CardActions>
        ) : null}
      </Paper>
      <OrdersEditBar
        selectedIds={selectedOrdersIds}
        selectedOrders={
          data.filter((order) => selectedOrdersIds.includes(order.id)) || []
        }
        onCancel={handleAlertOpen}
      />
    </div>
  );
}
SimpleTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default memo(withStyles(styles)(SimpleTable));
