import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';

import logger from 'redux-logger';

let middleware = [];
if (window.location.hostname  === 'platform.keepspace.com.au') {
  middleware = [...middleware, thunk];
} else {
  middleware = [...middleware, thunk, logger];
}

export default function configureStore(initialState={
    reduxTokenAuth: {
        currentUser: {
            hasVerificationBeenAttempted: false,
            isLoading: false,
            isSignedIn: null,
            attributes: {
              email: null
            }
        }
    }
}) {
    return createStore(
        rootReducer,
        initialState,
        compose(
            applyMiddleware(...middleware),
            //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()  https://github.com/zalmoxisus/redux-devtools-extension/issues/320
            window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
        )
    );
}