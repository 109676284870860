import React from 'react';

import withMobileDialog from '@material-ui/core/withMobileDialog';

import { withStyles, Dialog, Button, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';

function AccurateDetails(props) {

  const { classes, isOpen, onSubmit, handleClose } = props;

  return (
    <Dialog
      open={isOpen}
      onEscapeKeyDown={handleClose}
      onClose={handleClose}
      onBackdropClick={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={classes.dialog}>
        <DialogTitle id="alert-dialog-title" disableTypography className={classes.title}>
          Accurate Details
              </DialogTitle>
        <DialogContent>
          <Typography variant='h6' paragraph>
            KeepSpace relies on accurate weights and
            dimensions to arrange appropriate shipping
            options and estimate shipping costs.
          </Typography>
          
          <Typography variant='h6' paragraph>
            Incorrect weights and dimensions can result
            in surprise charges.
          </Typography>
          
          <Typography variant='h6' paragraph>
            Please be aware for incorrect data points, like
            dimensions and weights, once it arrives at the
            warehouse may incur charges starting from $15.00AUD per SKU
            for corrections on behalf of the warehouse team.
          </Typography>
          
          <Typography variant='h6' paragraph>
            It is your responsibility to ensure that accuracy
            of product weight and dimension information.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" >
            Cancel
                </Button>
          <Button onClick={onSubmit} color="primary" >
            Agree
                </Button>
        </DialogActions>

      </div>
    </Dialog>
  );
}


const styles = theme => ({
  root: {
    minHeight: '400px',
    flexWrap: 'wrap'
  },
  dialog: {
    background: '#eeeeee',
    overflowY: 'scroll',
    '-webkit-overflow-scrolling': 'touch',
    padding: '30px'
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold'
  }
});

export default withMobileDialog({
  breakpoint: 'sm',
})(withStyles(styles)(AccurateDetails));


