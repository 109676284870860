import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Page } from 'components';
import Can from "components/Can";

import {
    Header,
    Statistics,
    Notifications,
    TopIntegrations,
    Countries
} from './components';
import {Grow, Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        width: theme.breakpoints.values.lg,
        maxWidth: '100%',
        margin: '0 auto',
        padding: theme.spacing(3),
    },
    statistics: {
        marginTop: theme.spacing(3)
    },
    countries: {
        marginTop: theme.spacing(3)
    },
    notifications: {
        marginTop: theme.spacing(6)
    },
    projects: {
        marginTop: theme.spacing(6)
    },
    todos: {
        marginTop: theme.spacing(6)
    }
}));

const Overview = () => {
    const classes = useStyles();
    const currentOrganisation = useSelector(state => state.organisationsReducer.currentOrganisation);

  const uid = (window.location.pathname.split('/')[1] === 'user') ? window.location.pathname.split('/')[2] : null;


  return (
    <>{(uid === ":uid") ?
      < Redirect to="/auth/login" />
      : <Page
        className={classes.root}
        title="Overview"
      >
        <Grow in><Header /></Grow>

        <Typography variant="overline">Last 30 days</Typography>

        <Can
          role={currentOrganisation.currentOrganisationUser.role}
          perform="overview:viewFinancials"
          yes={() => (
            <Grow
              in
              style={{ transformOrigin: '0 0 0' }}
              timeout={1000}
            >
              <Statistics className={classes.statistics} />
            </Grow>

          )}
        />

        <Grow
          in
          style={{ transofrmOrigin: '0 0 0' }}
          timeout={1000}
        >
          <Countries className={classes.countries} />
        </Grow>

        <Grow
          in
          style={{ transofrmOrigin: '0 0 0' }}
          timeout={1000}
        >
          <Notifications className={classes.notifications} />
        </Grow>

        <Can
          role={currentOrganisation.currentOrganisationUser.role}
          perform="overview:viewFinancials"
          yes={() => (
            <Grow
              in
              style={{ transformOrigin: '0 0 0' }}
              timeout={1000}
            >
              <TopIntegrations className={classes.todos} />
            </Grow>

          )}
        />
      </Page>}</>
  );
};

export default Overview;
