import React from 'react'
import { detect } from 'detect-browser';
import { Link as RRLink } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
    Button,
    Paper,
    TextField,
    Grid,
    Link
} from '@material-ui/core';

import KeepSpaceIcon from 'components/Icons/KeepSpaceIcon';

import authStyles from "../styles";

const RouterLink = React.forwardRef((props, ref) => (
    <RRLink innerRef={ref} {...props} />
));


const useStyles = makeStyles(theme => ({
    ...authStyles,
    paperLogin: {
        ...authStyles.paper,
        justifyContent: 'center'
    },
    ksLogo: {
        display: 'flex',
        alignItems: 'center',
        color: '#1b30e1',
        margin: '30px'
    },
    ksName: {
        fontSize: 47,
        marginLeft: 20
    },

    errColor: {
        color: 'red',

    },

    forgotPass: {
        marginLeft: 'auto',

    },

}
))

const ColorButton = withStyles((theme) => ({
    root: {
        color: "white",
        backgroundColor: theme.palette.adminPrimary.dark,
        '&:hover': {
            backgroundColor: theme.palette.adminBlue,
        },
    },
}))(Button);

const LoginForm = props => {
    const classes = useStyles();
    const { submit, handleChange, adminAuth, sending } = props;
    const browser = detect();

    return (
        <Paper className={classes.paperLogin}>


            <div className={classes.ksLogo}>

                <KeepSpaceIcon />
                <div className={classes.ksName}>KeepSpace</div>

            </div>
            <form className={classes.form} onSubmit={submit}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    value={props.email}
                    onChange={handleChange}
                    id="email"
                    label="Email Address"
                    name="email"
                    type="email"
                    autoComplete="username"
                    variant="outlined"
                // autoFocus
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    id="password"
                    label="Password"
                    value={props.password}
                    onChange={handleChange}
                    type="password"
                    autoComplete="current-password"

                />

                <div>
                    {


                        adminAuth.isLoginFailed && (
                            <div className={classes.errColor}>There is some error</div>
                            // <h6>There is some error</h6>
                        )
                    }
                </div>


                <ColorButton
                    type="submit"
                    fullWidth
                    disabled={sending}
                    variant="contained"
                    color='adminPrimary'
                    textTransform="inherit"


                    className={classes.submit}
                >
                    Sign In
                </ColorButton>

                <div className={classes.forgotPass}>

                    {/*below one is incorrect, add admin forget password page once done*/}
                    {/*     <Link component={RouterLink} to="/auth/reset_password" variant='body1' className={classes.link}>
                            Forgot your password?
                        </Link>*/}

                </div>


            </form>
        </Paper>
    )
}

export default LoginForm;
