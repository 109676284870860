import {
  FETCH_WAREHOUSES_BEGIN,
  FETCH_WAREHOUSES_SUCCESS,
  FETCH_WAREHOUSES_FAILURE,
  FETCH_WAREHOUSES_BY_ID_SUCCESS,
  FETCH_WAREHOUSES_BY_ID_FAILURE,
  UPDATE_WAREHOUSES_BEGIN,
  UPDATE_WAREHOUSES_FAILURE,
  UPDATE_WAREHOUSES_SUCCESS,
  CREATE_WAREHOUSES_BEGIN,
  CREATE_WAREHOUSES_FAILURE,
  CREATE_WAREHOUSES_SUCCESS,
  FETCH_LOCATION_SUCCESS,
  FETCH_LOCATION_FAILURE,
  FETCH_LOCATION_BY_ID_SUCCESS,
  FETCH_LOCATION_BY_ID_FAILURE,
  PRINT_BARCODES_SUCCESS,
  PRINT_BARCODES_FAILURE,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAILURE,
  CREATE_LOCATION_SUCCESS,
  CREATE_LOCATION_FAILURE,
  CLEAR_WAREHOUSES,
  CLEAR_WAREHOUSES_NOTIFICATION,
  DELETE_WAREHOUSE_BEGIN,
  DELETE_WAREHOUSE_FAILURE,
  DELETE_WAREHOUSE_SUCCESS,
  FETCH_TEAMS_BEGIN,
  FETCH_TEAMS_SUCCESS,
  FETCH_TEAMS_FAILURE,
  FETCH_WH_ZONES_BEGIN,
  FETCH_WH_ZONES_SUCCESS,
  FETCH_WH_ZONES_FAILURE,
  FETCH_WH_ZONE_BY_ID_SUCCESS,
  FETCH_WH_ZONE_BY_ID_FAILURE,
  CREATE_WH_ZONE_BEGIN,
  CREATE_WH_ZONE_SUCCESS,
  CREATE_WH_ZONE_FAILURE,
  UPDATE_WH_ZONE_BEGIN,
  UPDATE_WH_ZONE_SUCCESS,
  UPDATE_WH_ZONE_FAILURE,
  DELETE_WH_ZONE_BEGIN,
  DELETE_WH_ZONE_SUCCESS,
  DELETE_WH_ZONE_FAILURE,
  FETCH_WH_COUNTRY_SUCCESS,
  FETCH_WH_COUNTRY_FAILURE,
  FETCH_PRIORITY_BEGIN,
  FETCH_PRIORITY_SUCCESS,
  FETCH_PRIORITY_FAILURE,
  FETCH_PRIORITY_BY_ID_SUCCESS,
  FETCH_PRIORITY_BY_ID_FAILURE,
  UPDATE_PRIORITY_BEGIN,
  UPDATE_PRIORITY_SUCCESS,
  UPDATE_PRIORITY_FAILURE,
} from "actions/admin/warehouseActions";

const defaultWarehouses = {
  id: null,
};
// specify initial state
const initialState = {
  warehouses: [],
  locations: [],
  loading: false,
  error: null,
  notification: null,
  dummyData: [...Array(10)],
  warehouse: { ...defaultWarehouses },
  location: {},
  filter: {
    per_page: 25,
    page: 1,
  },
  formLoading: false,
  formSuccess: false,
  pagination: {
    current_page: 1,
    total_count: 1,
    per_page: 2,
  },
  barcode: null,
  filename: null,
  zones: [],
  zone: {},
  teams: [],
  countries: [],
  priorities: [],
  priority: {},
};

export default function warehouseReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_WAREHOUSES_BEGIN:
      return {
        ...state, //imp line
        loading: true,
        error: null,
        formSuccess: false,
      };

    case FETCH_WAREHOUSES_SUCCESS:
      return {
        ...state,
        loading: false,
        warehouses: action.data,
        pagination: action.pagination,
        notification: null,
      };

    case FETCH_WAREHOUSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        warehouses: [],
      };

    case FETCH_WAREHOUSES_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        warehouse: action.payload,
      };

    case FETCH_WAREHOUSES_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        notification: action.msg,
      };
    case UPDATE_WAREHOUSES_BEGIN: {
      return {
        ...state,
        formLoading: true,
        formSuccess: false,
      };
    }
    case UPDATE_WAREHOUSES_SUCCESS: {
      return {
        ...state,
        formLoading: false,
        formSuccess: true,
        errors: null,
        updateWarehousesErrors: null,
        warehouse: action.payload,
      };
    }
    case UPDATE_WAREHOUSES_FAILURE: {
      let update_warehouses_failure_errors,
        error = {};

      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
        updateWarehousesErrors: update_warehouses_failure_errors,
      };
    }
    case CLEAR_WAREHOUSES:
      return {
        ...state,
        ...initialState,
      };
    case CLEAR_WAREHOUSES_NOTIFICATION:
      return {
        ...state,
        error: null,
        notification: null,
      };
    case CREATE_WAREHOUSES_BEGIN:
      return {
        ...state,
        formLoading: true,
        formSuccess: false,
      };

    case CREATE_WAREHOUSES_SUCCESS: {
      return {
        ...state,
        errors: null,
        formSuccess: true,
        formLoading: false,
        warehouse: action.payload.warehouses,
      };
    }
    case CREATE_WAREHOUSES_FAILURE:
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };

    case DELETE_WAREHOUSE_BEGIN: {
      return {
        ...state,
        formLoading: true,
        formSuccess: false,
        errors: null,
      };
    }
    case DELETE_WAREHOUSE_SUCCESS: {
      return {
        ...state,
        formLoading: false,
        formSuccess: true,
      };
    }
    case DELETE_WAREHOUSE_FAILURE: {
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    }
    case FETCH_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        locations: action.data,
        pagination: action.pagination,
        filter: action.filter,
      };

    case FETCH_LOCATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        locations: [],
      };
    case FETCH_LOCATION_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        location: action.payload,
      };
    case FETCH_LOCATION_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        location: {},
      };
    case PRINT_BARCODES_SUCCESS:
      let headerLine = action.payload.headers["content-disposition"];
      let startFileNameIndex = headerLine.indexOf('"') + 1;
      let endFileNameIndex = headerLine.lastIndexOf('"');
      let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
      return {
        ...state,
        formLoading: false,
        barcode: action.payload.data,
        filename: filename,
      };
    case PRINT_BARCODES_FAILURE:
      return {
        ...state,
        formLoading: false,
        barcode: null,
        filename: "",
      };
    case UPDATE_LOCATION_SUCCESS: {
      return {
        ...state,
        formLoading: false,
        formSuccess: true,
        errors: null,
        location: action.payload,
      };
    }
    case UPDATE_LOCATION_FAILURE: {
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
        location: {},
      };
    }
    case CREATE_LOCATION_SUCCESS: {
      return {
        ...state,
        formLoading: false,
        formSuccess: true,
        errors: null,
        location: action.payload,
      };
    }
    case CREATE_LOCATION_FAILURE: {
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
        location: {},
      };
    }

    case FETCH_TEAMS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        formSuccess: false,
      };

    case FETCH_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        teams: action.payload.team_users,
      };

    case FETCH_TEAMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        warehouses: [],
      };

    case FETCH_WH_ZONES_BEGIN:
      return {
        ...state, //imp line
        loading: true,
        error: null,
        formSuccess: false,
      };

    case FETCH_WH_ZONES_SUCCESS:
      return {
        ...state,
        loading: false,
        zones: action.data,
        pagination: action.pagination,
        notification: null,
      };

    case FETCH_WH_ZONES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        zones: [],
      };

    case FETCH_WH_ZONE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        zone: action.payload,
      };

    case FETCH_WH_ZONE_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        notification: action.msg,
      };
    case UPDATE_WH_ZONE_BEGIN: {
      return {
        ...state,
        formLoading: true,
        formSuccess: false,
      };
    }
    case UPDATE_WH_ZONE_SUCCESS: {
      return {
        ...state,
        formLoading: false,
        formSuccess: true,
        errors: null,
        zone: action.payload,
      };
    }
    case UPDATE_WH_ZONE_FAILURE: {
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    }
    /*case CLEAR_WH_ZONES:
            return {
                ...state,
                zones:[]
            }*/

    case CREATE_WH_ZONE_BEGIN:
      return {
        ...state,
        formLoading: true,
        formSuccess: false,
      };

    case CREATE_WH_ZONE_SUCCESS: {
      return {
        ...state,
        errors: null,
        formSuccess: true,
        formLoading: false,
        zone: action.payload.zones,
      };
    }
    case CREATE_WH_ZONE_FAILURE:
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    case FETCH_WH_COUNTRY_SUCCESS: {
      return {
        ...state,
        countries: action.countries,
      };
    }
    case FETCH_WH_COUNTRY_FAILURE: {
      return {
        ...state,
        countries: [],
      };
    }

    case DELETE_WH_ZONE_BEGIN: {
      return {
        ...state,
        formLoading: true,
        formSuccess: false,
        errors: null,
      };
    }
    case DELETE_WH_ZONE_SUCCESS: {
      return {
        ...state,
        formLoading: false,
        formSuccess: true,
      };
    }
    case DELETE_WH_ZONE_FAILURE: {
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    }

    case FETCH_PRIORITY_BEGIN:
      return {
        ...state, //imp line
        loading: true,
        error: null,
        formSuccess: false,
      };

    case FETCH_PRIORITY_SUCCESS:
      return {
        ...state,
        loading: false,
        priorities: action.data,
        pagination: action.pagination,
        notification: null,
      };

    case FETCH_PRIORITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        priorities: [],
      };

    case FETCH_PRIORITY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        priority: action.payload,
      };

    case FETCH_PRIORITY_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        notification: action.msg,
      };
    case UPDATE_PRIORITY_BEGIN: {
      return {
        ...state,
        formLoading: true,
        formSuccess: false,
      };
    }
    case UPDATE_PRIORITY_SUCCESS: {
      return {
        ...state,
        formLoading: false,
        formSuccess: true,
        errors: null,
        priority: action.payload,
      };
    }
    case UPDATE_PRIORITY_FAILURE: {
      return {
        ...state,
        formLoading: false,
        formSuccess: false,
        error: action.payload.error,
      };
    }

    default:
      // Always have a default case in case a reducer doesn't complete
      return state;
  }
}
