import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {Card, Typography, Grid} from '@material-ui/core';
import keepspaceApi from 'services/keepspaceApi';
import {useSelector} from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  itemContainer: {
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1),

  },
  overline: {
    marginTop: theme.spacing(1),
    display: 'block'
  }
}));

const Statistics = props => {
  const {className, ...rest} = props;
  const isSignedIn = useSelector(state => state.reduxTokenAuth.currentUser.isSignedIn);
  const classes = useStyles();
  const [statistics, setStatistics] = useState({
    // template data to be removed
    spaceUsed: null,
    orders: null,
    revenue: null,
    skuPerOrder: null,
  });

  useEffect(() => {
    let ignore = false;

    const fetchSpaceUsage = () => {
      keepspaceApi.getTotalUsageVolume()
        .then(json => {
          if(!ignore) setStatistics(
            statistics => ({...statistics, spaceUsed: json.total_volume}),
          )
        })
        .catch(error => console.log(error))
    }

    const fetchOrdersCount = () => {
      keepspaceApi.getOrderCount()
        .then(json => {
          if(!ignore) setStatistics(
            statistics => ({...statistics, orders: json.orders})
          )
        })
        .catch(error => console.log(error))
    }

    const fetchSKUPerOrder = () => {
      keepspaceApi.getSKUPerOrder()
        .then(json => {
          if(!ignore) setStatistics(
            statistics => ({...statistics, skuPerOrder: json.average_sku_per_order})
          )
        })
        .catch(error => console.log(error))
    }

    const fetchTotalRevenue = () => {
      keepspaceApi.getTotalRevenue()
        .then(json => {
          if(!ignore) setStatistics(
            statistics => ({...statistics, revenue: json.total_revenue})
          )
        })
        .catch(error => console.log(error))
    }

    if(!ignore && isSignedIn) {
      fetchSpaceUsage();
      fetchOrdersCount();
      fetchSKUPerOrder();
      fetchTotalRevenue();
    }

    return () => {
      ignore = true; // Avoid memory leaks
    }
  }, [isSignedIn]);

  if(!statistics) {
    return null;
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems="center"
        container
        justify="space-between"
      >
        <Grid
          className={classes.itemContainer}
          item
          md={3}
          sm={6}
          xs={12}
        >
          <div className={classes.item}>
            <Typography variant="h2">{statistics.spaceUsed ? statistics.spaceUsed.toFixed(4) : '0'}m³</Typography>
            <Typography
              className={classes.overline}
              variant="overline"
            >
              Space Used
            </Typography>
          </div>
        </Grid>

        <Grid
          className={classes.itemContainer}
          item
          md={3}
          sm={6}
          xs={12}
        >
          <div className={classes.item}>
            <Typography variant="h2">{statistics.orders ? statistics.orders : '0'}</Typography>
            <Typography
              className={classes.overline}
              variant="overline"
            >
              Orders
            </Typography>
          </div>
        </Grid>

        <Grid
          className={classes.itemContainer}
          item
          md={3}
          sm={6}
          xs={12}
        >
          <div className={classes.item}>
            <div className={classes.titleWrapper}>
              <Typography
                variant="h2"
              >
                {statistics.revenue ? `$ ${statistics.revenue}` : '-'}
              </Typography>
            </div>
            <Typography
              className={classes.overline}
              variant="overline"
            >
              Estimated Revenue
            </Typography>
          </div>
        </Grid>

        <Grid
          className={classes.itemContainer}
          item
          md={3}
          sm={6}
          xs={12}
        >
          <div className={classes.item}>
            <div className={classes.titleWrapper}>
              <Typography
                component="span"
                variant="h2"
              >
                {statistics.skuPerOrder ? statistics.skuPerOrder.toFixed(2) : '-'}
              </Typography>
            </div>
            <Typography
              className={classes.overline}
              variant="overline"
            >
              SKU / Order
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Card >
  );
};

Statistics.propTypes = {
  className: PropTypes.string
};

export default Statistics;
