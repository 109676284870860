import {
    FETCH_SUPPLIERS_BEGIN,
    FETCH_SUPPLIERS_SUCCESS,
    FETCH_SUPPLIERS_FAILURE,
    CREATE_ORG_SUPPLIER_BEGIN,
    CREATE_ORG_SUPPLIER_SUCCESS,
    CREATE_ORG_SUPPLIER_FAILURE,
    GET_ORG_SUPPLIER_BY_ID_BEGIN,
    GET_ORG_SUPPLIER_BY_ID_SUCCESS,
    GET_ORG_SUPPLIER_BY_ID_FAILURE,
    UPDATE_ORG_SUPPLIER_SUCCESS,
    UPDATE_ORG_SUPPLIER_FAILURE,
    DELETE_ORG_SUPPLIER_SUCCESS,
    DELETE_ORG_SUPPLIER_FAILURE,
    CLEAR_SUPPLIERS,
    UPDATE_SUPPLIERS_BEGIN,
    GET_ORG_SUPPLIER_BY_NAME_BEGIN,
    GET_ORG_SUPPLIER_BY_NAME_FAILURE,
    GET_ORG_SUPPLIER_BY_NAME_SUCCESS,
    CLEAR_SUPPLIERS_NOTIFICATION
} from 'actions/admin/SupplierActions';

import {
    FETCH_ORG_SUPPLIERS_BEGIN,
    FETCH_ORG_SUPPLIERS_SUCCESS,
    FETCH_ORG_SUPPLIERS_FAILURE,

} from 'actions/admin/OrganisationsActions';

const initialState = {
    suppliers: [],
    loading: false,
    error: null,
    notification: null,
    dummyData: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    pagination: {},
    supplierDetail: {},
    formSuccess: false,
    searchResult: []
}

export default function orgSuppliers(state = initialState, action) {
    switch(action.type) {
        case FETCH_SUPPLIERS_BEGIN || FETCH_ORG_SUPPLIERS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_SUPPLIERS_SUCCESS:
            return {
                ...state,
                loading: false,
                suppliers: action.data.suppliers,
                pagination: action.data.pagination,
                filters: action.data.filters
            };

        case FETCH_ORG_SUPPLIERS_SUCCESS:
            return {
                ...state,
                loading: false,
                suppliers: action.data.suppliers,
                pagination: action.data.pagination,
                filters: action.data.filters,
                organisation: action.data.organisation
            };
        case FETCH_SUPPLIERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                suppliers: []
            };
        case FETCH_ORG_SUPPLIERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                suppliers: []
            };
        case CREATE_ORG_SUPPLIER_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case CREATE_ORG_SUPPLIER_SUCCESS:
            return {
                ...state,
                loading: false,
                formSuccess: true
            };
        case CREATE_ORG_SUPPLIER_FAILURE:
            return {
                ...state,
                loading: false,
                formSuccess: false,
                error: action.payload.error,
            };
        case GET_ORG_SUPPLIER_BY_ID_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case GET_ORG_SUPPLIER_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                supplierDetail: action.data
            };

        case GET_ORG_SUPPLIER_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };


        case GET_ORG_SUPPLIER_BY_NAME_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case GET_ORG_SUPPLIER_BY_NAME_SUCCESS:
            return {
                ...state,
                loading: false,
                searchResult: action.data
            };

        case GET_ORG_SUPPLIER_BY_NAME_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case UPDATE_SUPPLIERS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case UPDATE_ORG_SUPPLIER_SUCCESS:

            return {
                ...state,
                loading: false,
                formSuccess: true,
                supplierDetail: action.data
            }
        case UPDATE_ORG_SUPPLIER_FAILURE:
            return {
                ...state,
                loading: false,
                formSuccess: false,
                error: action.payload.error,
            };
        case DELETE_ORG_SUPPLIER_SUCCESS:
            return {
                ...state,
                formSuccess: true,
                loading: false,
                notification: action.msg
            };
        case DELETE_ORG_SUPPLIER_FAILURE:
            return {
                ...state,
                loading: false,
                formSuccess: false,
                error: action.payload.error,
            };
        case CLEAR_SUPPLIERS:
            return {
                ...state,
                suppliers: [],
                loading: false,
                error: null,
                supplierDetail: {},
                formSuccess: false
            }
        case CLEAR_SUPPLIERS_NOTIFICATION:
            return{
                ...state,
                error:null,
                notification:null
            }
        default:
            return state
    }
}
