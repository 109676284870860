import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';

import { List, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

class OrderFilterCollapse extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick = (target) => {
        let newVal = this.state[target];
        this.setState(state => ({
            [target]: !newVal
        }))
    }

    render() {
        const { classes, collapseItems, icon } = this.props;
        return (
            <>
                <ListItem button onClick={() => this.handleClick('open')}>
                    <ListItemIcon>
                        {icon !== undefined ? <this.props.icon /> : null}
                    </ListItemIcon>
                    <ListItemText inset primary={this.props.title} />
                    {this.state.open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>


                <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>

                        {this.props.children ? (
                            this.props.children
                        ) : (
                                collapseItems.map(item => (
                                    <ListItem button className={classes.nested}>
                                        <ListItemText inset primary={item || ''}></ListItemText>
                                    </ListItem>
                                ))
                            )
                        }


                    </List>
                </Collapse>
            </>
        )
    }
}

const styles = theme => ({
    root: {

    },
})

export default withStyles(styles)(OrderFilterCollapse);