import {
    FETCH_CATEGORIES_BEGIN,
    FETCH_CATEGORIES_SUCCESS,
    FETCH_CATEGORIES_FAILURE,
    FETCH_CATEGORY_BY_ID_BEGIN,
    FETCH_CATEGORY_BY_ID_SUCCESS,
    FETCH_CATEGORY_BY_ID_FAILURE,
    CREATE_CATEGORY_BEGIN,
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_FAILURE,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_FAILURE,
    UPDATE_CATEGORY_FILTER
  } from 'actions/categoryActions';

import { applyCategoryInterface } from 'Interfaces';

const initialState = {
    categories: [],
    loading: false,
    error: null,
    uploading: false,
    childLoading: false,
    categoriesFilter: []
}

export default function categoriesReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_CATEGORIES_BEGIN:
            return  {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_CATEGORIES_SUCCESS:
            let newArray = [...action.payload.categories, {
                  id: '-1',
                  title: 'No Category',
                  isChecked: true
                }]
            return  {
                ...state,
                loading: false,
                categories: action.payload.categories,
                categoriesFilter: newArray.map(category => {
                  return {
                    id: category.id,
                    title: category.title,
                    isChecked: true
                  }
                })

            };
        case FETCH_CATEGORIES_FAILURE:
            return  {
                ...state,
                loading: false,
                error: action.payload.error,
                categories: []
            };

        case FETCH_CATEGORY_BY_ID_BEGIN:
            return {
                ...state,
                childLoading: true,
                error: null
            };

        case FETCH_CATEGORY_BY_ID_SUCCESS:
            return {
                ...state,
                childLoading: false,
                categories: state.categories.map(category => category.id === action.payload.category.id ?
                    // Transform matching element, and add interfaces back
                    applyCategoryInterface(action.payload.category)  :
                    // Keep those that don't match
                    category
                )
            };

        case FETCH_CATEGORY_BY_ID_FAILURE:
            return {
                ...state,
                childLoading: false,
                error: action.payload.error,
            };

        case CREATE_CATEGORY_BEGIN:
            return {
                ...state,
                uploading: true,
                error: null
            };
        case CREATE_CATEGORY_SUCCESS:
            let newCategoryFilter= {
                    id: action.payload.category.id,
                    title: action.payload.category.title,
                    isChecked: true
             }
            return {
                ...state,
                uploading: false,
                error: null,
                categories: [action.payload.category, ...state.categories ],
                categoriesFilter: [newCategoryFilter, ...state.categoriesFilter]
            };
        case CREATE_CATEGORY_FAILURE:
            return {
                ...state,
                uploading: false,
                error: action.payload.error,
            };
        case UPDATE_CATEGORY_SUCCESS:
            let updatedCategories= state.categories.map(category => category.id === action.payload.category.id ?
               action.payload.category : category
            )

            let updatedCategoryFilters= state.categoriesFilter.map(category_filter => category_filter.id === action.payload.category.id ?
               {id: action.payload.category.id, title: action.payload.category.title, isChecked: true} : category_filter
            )
            return {
              ...state,
              error: null,
              categories: updatedCategories,
              categoriesFilter: updatedCategoryFilters
            };
        case UPDATE_CATEGORY_FAILURE:
            return {
              ...state,
              error: action.payload.error
            };
        case UPDATE_CATEGORY_FILTER:
            let newCategories = state.categoriesFilter.map(category => ({
                ...category,
                isChecked: (category.id === action.value ? action.isChecked : category.isChecked)
            }))
            return {
                ...state,
                categoriesFilter: newCategories,
            }
        default:
            return state
    }
}
