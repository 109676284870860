import React from 'react'
import {CardWithTitle} from 'components/Card';
import {
    makeStyles,
} from "@material-ui/core";
import SearchAutocomplete from 'components/Search/SearchAutocomplete';
import Form from 'components/Form';


const Destination = React.memo(props => {
    const {formInputs, formState, formErrors, onChange, orderType, searchInput, handleSelect, autosuggestFields} = props;
    const classes = useStyles();
    let searchPlaceholder;
    let cardTitle;

    if(orderType === 'inbound') {
        searchPlaceholder = 'Search your Suppliers'
        cardTitle = "Where are they coming from?"
    }
    else {
        searchPlaceholder = 'Search your Customers'
        cardTitle = "Where are they going to?"
    }

    return (
        <CardWithTitle className={classes.card} title={cardTitle} >
            {/* children of CardWithTitle in Fragment <> ... </> */}
            <>
                <SearchAutocomplete className={classes.search} placeholder={searchPlaceholder} suggestionsList={searchInput} handleSelect={handleSelect} autosuggestFields={autosuggestFields} />

                <Form key="2destination" inputs={formInputs} state={formState} onChange={onChange} errors={formErrors} gridWidth={6} />
            </>
        </CardWithTitle>
    )
});

const useStyles = makeStyles(theme => ({

    card: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    search: {
        margin: theme.spacing(3, 3, 0)  // Top, Sides, Bottom
    }

}))

export default Destination;
