import { AuthActions } from '../../actions/admin/actionTypes';
import { FETCH_COUNTRIES_SUCCESS } from '../../actions/otherActions';

const initialState = {
  isLoggedIn: false,
  currentAdmin: {},
  isLoading: false,
  isLoginFailed: false,
  countries: [],
  devEmails: ['simran@keepspace.com.au', 'ania@keepspace.com.au'],
};

const adminAuthReducer = (state = initialState, action) => {
  switch (action.type) {

    // handle login start and show loaders stuff
    case AuthActions.LOGIN_START:
      return {
        ...state,
        isLoading: true,
        isLoginFailed: false,
        isLoggedIn: false,
        currentAdmin: {},
      };

    // handle login success and set data
    case AuthActions.LOGIN_SUCCESS:
      let payload = action.adminData;
      let data = {
        email: payload.email,
        id: payload.id,
        accessToken: payload.accessToken,
        client: payload.client,
        tokenType: payload.tokenType,
        expiry: payload.expiry,
        uid: payload.uid,
        isDev: !!payload.email && (typeof (payload.email) == 'string' && state.devEmails.includes(payload.email)),
      };
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
        isLoginFailed: false,
        currentAdmin: data,
      };

    case AuthActions.LOGIN_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        isLoginFailed: true,
        currentAdmin: {},

      };

    case FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
      };

    case AuthActions.CLEAR_AUTH:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default adminAuthReducer;
