import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import useRouter from 'utils/useRouter';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import { setCurrentOrganisation } from 'actions/organisationActions';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    boxShadow: 'none'
  },
  card: {
    width: '100%',
    marginBottom: '90%'
  },
  logo: {
    width: 40,
    height: 40,
    marginRight: 20
  },
  listItem: {
    color: theme.palette.grey[900],
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      cursor: 'pointer',
      color: 'white'
    }
  },
  icon: {
    color: theme.palette.grey[400],
    fontSize: 60
  },  
  header: {
    fontSize: theme.spacing(3.3),
    padding: '60px 0',
    fontWeight: 'lighter',
    color: theme.palette.grey[900]
  },
}));

const SelectOrganisation = props => {
  const {  error } = props;
  const classes = useStyles();
  let organisations = useSelector(state => state.organisationsReducer.organisations);

  const { history } = useRouter();
  const dispatch = useDispatch();

  const handleClick = organisation => {
    let response = dispatch(setCurrentOrganisation(organisation))

    if (response) {
      history.push('/');
    } else {
      console.log(error)
    }
  }

  if (organisations.length === 0) return <Redirect to="/auth/login"/>

  return (
    <div className={classes.paper}>
        <div className={classes.header}>Select your Organisation</div>
        <Card>
          <List
            disablePadding
          >
            {organisations.map((organisation, i) => (
              <ListItem
                className={classes.listItem}
                divider={i < organisations.length - 1}
                key={organisation.id}
                onClick={() => handleClick(organisation)}
              >
                <ListItemAvatar>
                    <Avatar
                      alt="Organisation"
                      className={classes.logo}
                      src={organisation.logo.url ? organisation.logo.url : require(`assets/img/avatars/00${organisation.id%46+1}.svg`)}
                    />
                </ListItemAvatar>
                <ListItemText
                  primary={organisation.name}
                  primaryTypographyProps={{ variant: 'h4', color: 'inherit' }}
                  secondary={organisation.web_url}
                />
                <KeyboardArrowRight 
                  className={classes.icon}
                />
              </ListItem>
            ))}
          </List>
        </Card>
    </div>
  );
};

SelectOrganisation.propTypes = {
  className: PropTypes.string
};

export default SelectOrganisation;
