/**
 *
 * @param {string} key Key of the item to fetch.
 */
export const getItem = (key) => {
    try {
        return window.localStorage.getItem(key);
    } catch (error) {
        return error;
    }
};

/**
 *
 * @param {string} key Key of the item to set.
 * @param {string} value Value to set for the key.
 */
export const setItem = async (key, value) => {
    try {

        await window.localStorage.setItem(key, value);
        return true;
    } catch (error) {
        return error;
    }
};

/**
 *
 * @param {string} key Key of the item to remove.
 */
export const removeItem = async (key) => {
    try {
        await window.localStorage.removeItem(key);
        return true;
    } catch (error) {
        return error;
    }
};

/**
 *
 * @description method to remove user localStorage data
 */
export const removeUserInfo = async () => {
    const items = ['organisation-uid', 'expiry', 'uid', 'token-type', 'access-token', 'client']
    try {
        for (let item of items) {
            await window.localStorage.removeItem(item);
        }
        return true;
    } catch (error) {
        return error;
    }
};

/**
 *
 * clear the storage, try to use removeItem method instead of this
 */
export const clear = async () => {
    try {
        window.localStorage.clear();
    } catch (error) {
        return error;
    }
};

/**
 * Gets all keys known to your app
 */
export const getAllKeys = async () => {
    try {
        return Object.entries(window.localStorage)
    } catch (error) {
        return error;
    }
};
