import * as Storage from '../../utils/storage';

const ADMIN_KEY = "_ADMIN";

export const setStorageData = async (admin) => {
   const isDataSet = await Storage.setItem(ADMIN_KEY, JSON.stringify(admin));
   return isDataSet;
}

export const isLoggedIn = () => {
    let admin = Storage.getItem(ADMIN_KEY);
    if (!admin) {
        return false;
    }
    admin = JSON.parse(admin);
    return (!!admin && !!admin['uid'] && !!admin['accessToken']);
}

export const clearStorageData = () => {
    Storage.removeItem(ADMIN_KEY);
}

export const getStorageData = () => {
    if (!isLoggedIn()) {
        return null;
    }

    let admin = Storage.getItem(ADMIN_KEY);
    return JSON.parse(admin);
}