import React, { PureComponent } from 'react'

import { withStyles } from '@material-ui/core';
import LazyLoad, { forceVisible } from 'react-lazyload';


class ProductImage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            currentImage: '',
            loading: true,
        }
    }

    componentDidMount() {
        if (!!this.props.image) {
            this.fetchImage(this.props.image.thumb.url || this.props.image.url);
        }
        forceVisible();
    }

    componentWillUnmount() {
        if (this.loadingImage) {
            this.loadingImage.onload = null
        }
    }

    fetchImage = src => {
        const image = new Image()
        image.onload = () => {
            this.setState({
                currentImage: this.loadingImage.src,
                loading: false
            });
        }
        image.src = src
        this.loadingImage = image
    }

    style = (loading, blur, archived) => {
        let filter = archived ? 'grayscale(100%)' : ''
        filter = loading ? `${filter} blur(10px)` : filter

        return {
            transition: '0.5s ',
            Webkitransition: '0.5s -webkit-filter linear',
            MozTransition: '0.5s',
            OTransition: '0.5s',
            msTransition: '0.5s',

            filter: filter,
            WebkitFilter: `${loading ? 'blur(10px)' : ''}`,
            MozFilter: `${loading ? 'blur(10px)' : ''}`,
        }
    }

    render() {
        const { classes } = this.props;
        const { currentImage, loading } = this.state;
        const { blur } = 'blur(10px)';
        return (
            <div style={this.style(loading, blur)} className={classes.imageDiv}>
                <LazyLoad>
                    <img className={classes.image} style={this.style(loading, blur, this.props.archived)} src={currentImage} alt={'product'} />
                </LazyLoad>
            </div>
        )


    }
}

const styles = theme => ({
    image: {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
        transition: '0.5s all ease-in-out',
        '&:hover': {
            transform: 'scale(1.1)'
        }
    },
    imageDiv: {
        height: '100%',
        width: '100%',
        backgroundSize: 'cover',
        background: 'white'
    }
})

export default withStyles(styles)(ProductImage);
