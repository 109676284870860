import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withStyles } from '@material-ui/core/styles';

import NewCategory from 'components/FormsPleaseStopDoingThis/CategoryForm/NewCategory';
import {UID} from 'AppConstants';

class CategoriesAdd extends Component {

    constructor(props){
        super(props)
        this.state = {
            open: true,
            anchorEl: false,
            shouldDestroyOrder: false,
        }
        this.handleClose = this.handleClose.bind(this)
    }


    handleClose(){
        this.setState({
            open: false,
        })
        setTimeout(() => {
            this.props.history.push(`/user/${UID}/space/categories`);
        }, 100);

    }
    
    render() {
      const {classes, permittedToAccess} = this.props
        if (!permittedToAccess("categories:create")) {this.props.history.push(`/user/${UID}/space/categories`)}
        
        return (
            <Dialog
                maxWidth={'sm'} 
                
                open={this.state.open}
                onEscapeKeyDown={this.handleClose}
                onClose={this.handleClose}
                onBackdropClick={this.handleClose}
                aria-labelledby="responsive-dialog-title"
                overflow="visible"
                classes = {{paper: classes.dialogPaper, scrollPaper: classes.dialogScrollPaper}} 
                
            >
                <div className={classes.dialog} style={{margin:'auto'}}>
                  <div className={classes.dialog}>
                      <NewCategory categories={this.props.categories} handleClose={this.handleClose} saveButton='Create category' />
                  </div>

                </div>
            </Dialog>
        );
    }
  }

CategoriesAdd.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

const styles = theme => ({
    root: {
      minHeight: '400px',
      flexWrap: 'wrap'
    },
    dialog: {
      background: '#eeeeee',
      overflowY: 'scroll',
      '-webkit-overflow-scrolling': 'touch',
    },
    dialogTitle: {
      display: 'inline-block',
    },
    dialogPaper: {
      minHeight: '100%'
    },
    dialogScrollPaper: {
      justifyContent: 'right',
      height: '100vH'
    }
});

export default withMobileDialog({
    breakpoint: 'sm',
})(withStyles(styles)(CategoriesAdd));


