import React from 'react'
import Grid from '@material-ui/core/Grid';
import { withStyles, Button, Typography } from '@material-ui/core';
import { TextValidator } from 'react-material-ui-form-validator';
import isFractionalNumber from '../isFractionalNumber'

const styles = theme => ({
  textField: {
    marginTop: '10px',

  },
  container: {
    marginTop: '20px'
  },
  containerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBpttom: '10px'
  },
  helperText: {
    textAlign: 'right',
    padding: '10px',
    width: '100%'
  },
  labelRoot: {
    [theme.breakpoints.down("sm")]: {
      width: '80%',
      fontSize: '12px',
    }
  },

})

function P2_Weight(props) {
  const { classes, parentState, onChange, addUnitFields, onNewFieldChange, handleDelete } = props;

  const size_fields =
    [
      { value: 'length', label: 'Length [meters]', required: ['required', 'isFloat', 'minFloat:0.000001'], errorMessages: ['length is required', "That's not a number", "Must be greater than 0", "Must be greater than unit above"], width: 3 },
      { value: 'width', label: 'Width [meters]', required: ['required', 'isFloat', 'minFloat:0.000001'], errorMessages: ['width is required', "That's not a number", "Must be greater than 0", "Must be greater than unit above"], width: 3 },
      { value: 'height', label: 'Height [meters]', required: ['required', 'isFloat', 'minFloat:0.000001'], errorMessages: ['height is required', "That's not a number", "Must be greater than 0", "Must be greater than unit above"], width: 3 },
      { value: 'weight', label: 'Weight [kg]', required: ['required', 'isFloat', 'minFloat:0.000001'], errorMessages: ['weight is required', "That's not a number", "Must be greater than 0", "Must be greater than unit above"], width: 3 },
    ]

  const unit_fields =
    [
      { value: 'unit_type', label: "Packaging type", required: ['required'], errorMessages: ['This field is required'], width: 3, options: ["", "BOX", "CARTON", "PALLET"] },
      { value: 'barcode', label: "Barcode", required: ['required'], errorMessages: ['This field is required'], width: 3 },
      { value: 'sku', label: "Packaging SKU", required: ['required'], errorMessages: ['This field is required'], width: 3 },
      { value: 'items_per_unit', label: "Child units per Packaging", required: ['required', 'isPositive'], errorMessages: ['This field is required', "That's not a number", "Must be greater than unit above"], width: 3 },
    ]

  const validateInput = (column, index) => {
    let validation = [...column.required]
    let minValue

    if (index === 0) {
      minValue = parentState[column.value] || 0
      validation.push(`minFloat:${minValue}`)
      return validation
    } else if (index > 0) {
      minValue = parentState.units[index - 1][column.value]
      validation.push(`minFloat:${minValue}`)
      return validation
    }
  }

  return (
    <>
      <Grid container spacing={3}>

        <Grid item xs={12} sm={12} >
          <div className={classes.gridHeader} variant='h6'>
            Base Unit:
          </div>
        </Grid>

        {size_fields.map((column, i) =>
          <Grid item xs={3} sm={3} key={i}>
            <TextValidator
              key={`${column.value}_${i}`}
              className={classes.textField}
              validators={column.required}
              errorMessages={column.errorMessages}
              id={column.value}
              name={column.value}
              label={`${column.label}`}
              value={parentState[column.value]}
              onChange={onChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
        )}
      </Grid>

      {parentState.units.map((unit, index) => (
        <div className={classes.container} key={index}>

          <div className={classes.containerHeader}>
            <Typography variant='h6' gutterBottom>New Packaging for {parentState.title}</Typography>
            <Button onClick={handleDelete(index)}>X</Button>
          </div>

          <Grid container spacing={1}>
            {unit_fields.map((column) =>
              <Grid item xs={column.width} sm={column.width} key={column.value}>
                <TextValidator
                  fullWidth
                  key={`${column.value}_${index}`}
                  errorMessages={column.errorMessages}
                  validators={column.value === 'items_per_unit' ? validateInput(column, index) : column.required}
                  id={column.value}
                  name={column.value}
                  label={column.label}
                  value={unit[column.value]}
                  onChange={e => onNewFieldChange(e, index)}
                  variant="outlined"
                  InputLabelProps={{ classes: { root: classes.labelRoot } }}
                  options
                  select={column.options ? true : false}
                  SelectProps={{ native: true }}
                >
                  {column.options && column.options.map(option =>
                    <option value={option} >
                      {option}
                    </option>
                  )}
                </TextValidator>

              </Grid>
            )}
            {size_fields.map((column) =>
              <Grid item xs={column.width} sm={column.width} key={column.value}>
                <TextValidator
                  fullWidth
                  key={`${column.value}_${index}`}
                  validators={validateInput(column, index)}
                  errorMessages={column.errorMessages}
                  id={column.value}
                  name={column.value}
                  label={column.label}
                  value={unit[column.value]}
                  onChange={e => onNewFieldChange(e, index)}
                  variant="outlined"
                  InputLabelProps={{ classes: { root: classes.labelRoot } }}
                />
              </Grid>
            )}
            {index > 0 ?
              <div className={classes.helperText}>
                This packaging is equal to {unit.unitsOfLowerPackaging} x {parentState.units[index - 1]['unit_type']} units
              </div> : null
            }

          </Grid>

        </div>
      ))}
    </>
  )
}

export default withStyles(styles)(P2_Weight)
