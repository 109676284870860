import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import CustomersTable from 'components/Table/CustomersTable';
import Header from 'components/Header/Header';
import AddNew from 'components/Help/AddNew';
import AddButton from 'components/Button/AddButton';
import {UID} from 'AppConstants';

class CustomersIndex extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }

  handleCellClick = (data) => {
    this.props.history.push(`/user/${UID}/manage/customers/${data[0]}`);
  };

  render() {
    const {classes, permittedToAccess} = this.props;

      return (
        <div className={classes.root}>
          <Header title="Manage" subtitle="Customers"/>
          <AddButton text='Add Customer' link={`/user/${UID}/manage/customers/add`} tooltip='Create Customer' disabled={!permittedToAccess("customers:create")}/>
          
          { this.props.customers.length === 0 ? (<AddNew title="customer"/> ):( <CustomersTable customers={this.props.customers} />)}
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
})


const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
  // customers: state.customersReducer.customers,
  // loading: state.customersReducer.loading,
});

export default connect(mapStateToProps)(withStyles(styles)(CustomersIndex));
