import React, {Fragment, useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/styles';
import {Paper, Avatar, Typography} from '@material-ui/core';
import NoRecord from 'assets/img/No_Records.svg';

// import cow from "assets/img/avatars/svg/001-cow.svg"

const useStyles = makeStyles(theme => ({
  root: {
    // height: '100vh',
    overflowY: 'auto'
  },
  themeText: {
    color: theme.palette.adminPrimary.dark
  },
  noRecordWrapper: {
    width: '100%',
    margin: "200px auto",
    textAlign: "center"
  },
  noRecordSingleWrapper: {
    width: '100%',
    margin: "50px auto",
    textAlign: "center"
  },
  img: {
    marginBottom: "40px",
    width: 200
  }
}));

const NoRecords = props => {
  const {singleLine} = props;

  const classes = useStyles();

  return (
    <>
      {(singleLine) ?

        <Paper elevation={0} className={classes.noRecordSingleWrapper}>
          <Typography variant="h4" gutterBottom className={classes.themeText}>Oops! Looks like data does not exist yet.</Typography>
        </Paper>
        :
        <Paper elevation={0} className={classes.noRecordWrapper}>
          <img className={classes.img} alt="complex" src={NoRecord} />
          <Typography variant="h3" gutterBottom className={classes.themeText}>Oops! No Records found</Typography>
          <Typography color="textSecondary">We could not find any data in here.</Typography>
          <Typography color="textSecondary">Please headback and check the filters apply or create new record.</Typography>
        </Paper >
      }
    </>
  );
};

export default NoRecords;
