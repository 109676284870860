import {
    FETCH_BUNDLES_BEGIN,
    FETCH_BUNDLES_SUCCESS,
    FETCH_BUNDLES_FAILURE,
    SET_BUNDLES_FILTERS,
    CLEAR_BUNDLESS,
    FETCH_BUNDLES_BY_ID_BEGIN,
    FETCH_BUNDLES_BY_ID_SUCCESS,
    FETCH_BUNDLES_BY_ID_FAILURE,
    ARCHIVE_BUNDLE_BEGIN,
    ARCHIVE_BUNDLE_SUCCESS,
    ARCHIVE_BUNDLE_FAILURE,
    UNARCHIVE_BUNDLE_BEGIN,
    UNARCHIVE_BUNDLE_SUCCESS,
    UNARCHIVE_BUNDLE_FAILURE,
    UPDATE_BUNDLES_BEGIN,
    UPDATE_BUNDLES_SUCCESS,
    UPDATE_BUNDLES_FAILURE,
    CREATE_BUNDLES_BEGIN,
    CREATE_BUNDLES_SUCCESS,
    CREATE_BUNDLES_FAILURE,
    FETCH_BUNDLES_FILTERS_SUCCESS,
    CLEAR_BUNDLES_NOTIFICATION
} from 'actions/admin/bundlesActions';

import {
    FETCH_ORG_BUNDLES_BEGIN,
    FETCH_ORG_BUNDLES_SUCCESS,
    FETCH_ORG_BUNDLES_FAILURE
} from 'actions/admin/OrganisationsActions';

//import { applyBUNDLESInterface } from 'Interfaces';


const initialState = {
    items: [],
    fetchedBundleItem: {},
    loading: false,
    formSuccess: false,
    uploading: false,
    error: null,
    notification: null,
    multipleSkuError: null,
    activeFilters: {
        company_ids: [],
        sku: null,
        title: null
    },
    pagination: {},
    filter: {},
}

export default function bundlesReducer(state = initialState, action) {
    switch (action.type) {
        case (FETCH_BUNDLES_BEGIN || FETCH_ORG_BUNDLES_BEGIN):
            return {
                ...state,
                loading: true,
                error: null,
                notification: null,
                items: []
            };

        case FETCH_BUNDLES_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload.products,
                pagination: action.payload.pagination,
                filters: action.payload.filters
            };
        case FETCH_ORG_BUNDLES_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload.products,
                pagination: action.payload.pagination,
                filters: action.payload.filters,
                organisation: action.payload.organisation
            };

        case FETCH_ORG_BUNDLES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                items: []
            };

        case FETCH_BUNDLES_FILTERS_SUCCESS:
            return {
                ...state,
                loading: false,
                filters: action.payload.filters
            };

        case FETCH_BUNDLES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                items: []
            };


        case SET_BUNDLES_FILTERS:
            return {
                ...state,
                activeFilters: action.data
            };

        case CLEAR_BUNDLESS:
            return {
                ...initialState
            };

        case CLEAR_BUNDLES_NOTIFICATION:
            return {
                ...state,
                error: null,
                notification: null
            };
        case FETCH_BUNDLES_BY_ID_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                notification: null
            };


        case FETCH_BUNDLES_BY_ID_SUCCESS:
            let itemVal = action.payload.item.bundle;
            return {
                ...state,
                loading: false,
                filters: action.payload.item.filters,
                fetchedBundleItem: {
                    ...itemVal,
                    "components": itemVal.bundle_contents.map(ele => {
                        return {
                            "item_id": ele.item_id,
                            "item_quantity": ele.item_quantity,
                            "description": ele.description,
                            "name": ele.name,
                            "sku": ele.sku,
                            "id": ele.id
                        }

                    })
                }

            };

        case FETCH_BUNDLES_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case ARCHIVE_BUNDLE_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                notification: null
            };


        case ARCHIVE_BUNDLE_SUCCESS:
            return {
                ...state,
                loading: false,
                notification: "This item has been archived successfully.",
                fetchedBundleItem: {...action.payload?.bundle}
            };

        case ARCHIVE_BUNDLE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        case UPDATE_BUNDLES_BEGIN:
            return {
                ...state,
                loading: true,
                formSuccess: false,
                error: null,
                notification: null
            }

        case UPDATE_BUNDLES_SUCCESS: {
            const itemVal = action.item;

            return {
                ...state,
                loading: false,
                formSuccess: true,
                error: null,
                fetchedBundleItem: {
                    ...itemVal,
                    "components": itemVal.bundle_contents.map(ele => {
                        return {
                            "item_id": ele.item_id,
                            "item_quantity": ele.item_quantity,
                            "description": ele.description,
                            "name": ele.name,
                            "sku": ele.sku,
                            "id": ele.id
                        }

                    })
                }
            }
        }
        case UPDATE_BUNDLES_FAILURE:
            return {
                ...state,
                loading: false,
                formSuccess: false,
                error: action.payload.error,
            };
        case CREATE_BUNDLES_BEGIN:
            return {
                ...state,
                loading: true,
                formSuccess: false,
                error: null,
                notification: null,
                fetchedBundleItem: {}
            }
        case CREATE_BUNDLES_SUCCESS:
            return {
                ...state,
                loading: false,
                formSuccess: true,
                error: null,
                fetchedBundleItem: action.payload.bundle
            }

        case CREATE_BUNDLES_FAILURE:
            return {
                ...state,
                loading: false,
                formSuccess: false,
                fetchedBundleItem: {},
                error: action.payload.error
            };

        case UNARCHIVE_BUNDLE_BEGIN:
            return {
                ...state,
                updating: true,
                formSuccess: false,
                notification: null,
                error: null,
            };

        case UNARCHIVE_BUNDLE_SUCCESS:
            return {
                ...state,
                updating: false,
                formSuccess: true,
                notification: "This item has been unarchived successfully.",
                fetchedBundleItem: action.payload.bundle
            };

        case UNARCHIVE_BUNDLE_FAILURE:
            return {
                ...state,
                updating: false,
                formSuccess: false,
                error: action.payload.error,
            };
        /* case UPDATE_PER_PAGE_COUNT:
      return {
          ...state,
          perPage:action.payload.count
      }
 
  case SEARCH_ORDER_BY_BUNDLES_SUCCESS:
      return {
          ...state,
          itemStocks: action.payload.itemStocks,
          monthlyOutbounds: action.payload.monthlyOutbounds || {},
          itemSku: action.payload.itemSku,
          orderBySkuError: null,
          loading: false,
      }
  case SEARCH_ORDER_BY_BUNDLES_FAILURE:
      return {
          ...state,
          orderBySkuError: action.payload.error,
          monthlyOutbounds: {},
          itemStocks: [],
          itemSku: null,
          loading: false,
      }
 
 
  case UPDATE_ARCHIVED_FILTER:
      return  {
          ...state,
          archivedFilter:  {
            yes: action.property === 'yes' ? action.value : false,
            any:  action.property === 'any' ? action.value : false,
            no:  action.property === 'no' ? action.value : false
          }
  };
  case UPDATE_AVAILABLE_FILTER:
      Object.keys(state[action.property]).forEach(v => (v === action.value ? state[action.property][v] = action.isChecked  : state[action.property][v] = false))
      return  {
          ...state,
         [action.property]:  state[action.property]
  };
  case UPDATE_LOW_STOCK_FILTER:
      return  {
          ...state,
         lowStockFilter: action.isChecked
  };
 
  case FETCH_BUNDLES_BY_ID_BEGIN:
      return {
          ...state,
          loading: true,
          error: null
      };
 
 
  case FETCH_BUNDLES_BY_ID_SUCCESS:
      return {
          ...state,
          loading: false,
          fetchedBundleItem:  action.payload.item,
      };
 
  case FETCH_BUNDLES_BY_ID_FAILURE:
      return {
          ...state,
          loading: false,
          error: action.payload.error,
      };
  case SET_MULTIPLE_BUNDLES_ERROR:
      return {
          ...state,
          multipleSkuError: action.payload.error,
      };*/
        default:
            // Always have a default case in case a reducer doesn't complete
            return state;
    }
};
