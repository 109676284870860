
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Route} from 'react-router';
import {makeStyles} from '@material-ui/styles';
import DraftsInvoices from 'layouts/AdminLayout/components/InvoiceTable/DraftInvoiceTable';
//import CreateInvoice from 'views/admin/Accounting/Invoices/GenerateInvoice';
import InvoicesIndexAll from 'views/admin/Accounting/Invoices/InvoiceIndexAll';
import InvoicesDetail from 'views/admin/Accounting/Invoices/InvoiceDetail';
//import SummaryEdit from 'views/admin/Invoices/InvoicesDetail/components/Summary/SummaryEdit';
import Header from 'components/Header/Header';
import {Breadcrumbs, Typography} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {Link} from 'react-router-dom';
import {
  fetchSku, fetchItemById, clearSKUs
} from 'actions/admin/skuActions';
import {
  fetchInvoices, fetchDraftInvoices, updateQuickBookUrl, createInvoice,
  clearInvoices, setInvoicesFilters, fetchInvoiceById, cancelInvoice, addNewPayment
} from 'actions/admin/invoicesActions';
import {
  fetchOrgInvoices
} from 'actions/admin/OrganisationsActions';
const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: 'wrap',
    width: '100%',
    padding: '12px 76px',
    [theme.breakpoints.down("sm")]: {
      padding: 0
    }
  },
  padding: {
    padding: '20px 30px'
  }
}));

const Invoices = props => {
  const {history, InvoiceDetail, location, clearInvoices} = props;
  let title = ""
  const classes = useStyles();

  useEffect(() => {
    title = getTitle();
    return () => {
      clearInvoices();
    }
  }, []);

  useEffect(() => {
    title = getTitle();
  }, [InvoiceDetail]);


  const getTitle = () => {
    const path = location.pathname;
    if(path.includes("details")) {
      return (InvoiceDetail) ? `#${InvoiceDetail.id}` : null
    }
    else if(path.includes("drafts")) {
      return "Draft Invoices"
    }
    /*else if (path.includes("new")) {
      return "Generate Invoice"
    }*/
    else {
      return "Invoices"
    }

  }

  return (
    <div className="root">

      {(!location.pathname.includes("organisations")) && <div>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Typography color="inherit">
            Accounting
          </Typography>
          {(location.pathname != "/admin/accounting/invoices") ?
            <Link to={'/admin/accounting/invoices'}><Typography color="textPrimary"> Invoices
            </Typography></Link> : null}
        </Breadcrumbs>
        <Header className={classes.topHead} subtitle={title} />

      </div>}
      <Route path='/admin/accounting/invoices'
        exact
        render={(routeProps) =>
          <InvoicesIndexAll  {...routeProps} {...props} />}
      />
      <Route path='/admin/organisations/summary/:id/invoices'
        exact
        render={(routeProps) =>
          <InvoicesIndexAll  {...routeProps} {...props} />}
      />

      <Route path='/admin/accounting/invoices/drafts'
        exact
        render={(routeProps) =>
          <DraftsInvoices  {...routeProps} {...props} />}
      />
      <Route path='/admin/accounting/invoices/:invoice_id/details'
        exact
        render={(routeProps) => <InvoicesDetail {...routeProps} {...props} />}

      />

      {/* <Route path='/admin/accounting/invoices/new'
        exact
        render={(routeProps) =>
          <CreateInvoice  {...routeProps} {...props} />}
      />

     <Route path='/admin/accounting/invoices/:string/:id/details/:tab'
        exact
        render={(routeProps) => <InvoicesDetail {...routeProps} props={props} />}
      />
      * <Route path='/admin/accounting/invoices/:string/:id/edit/:tab'
        exact
        render={(routeProps) => <SummaryEdit {...routeProps} props={props} />}
      />
        */}



    </div>);
};


const mapStateToProps = (state) => ({

  loading: state.adminInvoiceReducer.loading,
  formSuccess: state.adminInvoiceReducer.formSuccess,
  error: state.adminInvoiceReducer.error,
  invoices: state.adminInvoiceReducer.invoices,
  pagination: state.adminInvoiceReducer.pagination,
  filters: state.adminInvoiceReducer.filters,
  notification: state.adminInvoiceReducer.notification,
  activeFilters: state.adminInvoiceReducer.activeFilter,
  invoiceDetail: state.adminInvoiceReducer.invoiceDetail

})
function mapDispatchToProps(dispatch) {
  return {
    fetchInvoices: (params) => dispatch(fetchInvoices(params)),
    fetchOrgInvoices: (id, params) => dispatch(fetchOrgInvoices(id, params)),
    fetchDraftInvoices: (params) => dispatch(fetchDraftInvoices(params)),
    clearInvoices: () => dispatch(clearInvoices()),
    setInvoicesFilters: (params) => dispatch(setInvoicesFilters(params)),
    fetchInvoiceById: (id) => dispatch(fetchInvoiceById(id)),
    updateQuickBookUrl: (id, data) => dispatch(updateQuickBookUrl(id, data)),
    addNewPayment: (id, data) => dispatch(addNewPayment(id, data)),
    createInvoice: (id, data) => dispatch(createInvoice(id, data)),
    cancelInvoice: (id, param) => dispatch(cancelInvoice(id, param)),
    fetchSku: (params) => dispatch(fetchSku(params)),
    clearSKUs: () => dispatch(clearSKUs()),
    fetchItemById: (id) => dispatch(fetchItemById(id)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
