import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Maintenance from 'views/Maintenance';
import App from './App';
//import './assets/scss/material-dashboard-pro-react.scss';
import './index.scss';
import { verifyCredentials } from './redux-token-auth-config';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';
import { HelmetProvider } from 'react-helmet-async';
import * as Storage from "utils/storage";

const isMaintenance = false; // Set this true to show maintenance page


const store = configureStore();

if (Storage.getItem("access-token") === "undefined") {
    Storage.clear();
} else if (!isMaintenance && window.location.pathname.split('/')[1] !== 'admin') {
    verifyCredentials(store);
}
window._redux_store = store


ReactDOM.render(
    <Provider store={store}>
        <HelmetProvider>
            {isMaintenance ? <Maintenance /> : <App />}
        </HelmetProvider>
    </Provider>,
    document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export default store;
