import React, { useState, useEffect } from 'react';
import {
  withMobileDialog,
  Grid
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { reverseUserRoute } from 'routes';
import CustomerEdit from 'components/FormsPleaseStopDoingThis/CustomerForm/CustomerEdit';
import { useDispatch } from 'react-redux';
import { createCustomer } from 'actions/customerActions';
import { UID } from 'AppConstants';

function ResponsiveDialog(props) {
  const [open, setOpen] = useState(true)
  const { countries, error, permittedToAccess } = props;
  const [values, setValues] = useState({});
  const dispatch = useDispatch();

  if (!permittedToAccess("customers:create")) { props.history.push(`/user/${UID}/manage/customers/`) }

  function handleClose() {
    setOpen(false);
    setTimeout(() => {
      props.history.push(reverseUserRoute('Customers'));
    }, 100);
  }

  const handleChange = event => {
    const newValues = { ...values, [event.target.name]: event.target.value }
    setValues(newValues);
  }

  useEffect(() => {
    setValues({
      requestStatus: '',
      name: '',
      company_name: '',
      email: '',
      phone: '',
      address_line_1: '',
      address_line_2: '',
      postcode: '',
      suburb: '',
      state: '',
      country: '',
      customer_type: ''
    })
  }, []);

  const submitForm = () => {
    var formData = new FormData();
    formData.append('name', values.name)
    formData.append('company_name', values.company_name)
    formData.append('phone', values.phone)
    formData.append('email', values.email)
    formData.append('address_line_1', values.address_line_1)
    formData.append('address_line_2', values.address_line_2)
    formData.append('postcode', values.postcode)
    formData.append('state', values.state)
    formData.append('suburb', values.suburb)
    formData.append('country', values.country)
    formData.append('customer_type', values.customer_type)
    dispatch(createCustomer(formData))
      .then(result => {
        if (result) {
          // result is null when in error
          handleClose()
        }
      })
  }

  const fields =
    [
      { value: 'name', label: 'Name *', required: ['required'], errorMessages: ['this field is required'], options: [] },
      { value: 'company_name', label: 'Company Name', required: [], options: [] },
      { value: 'email', label: 'Email address *', required: ['required', 'isEmail'], errorMessages: ['this field is required', 'email is not valid'], options: [] },
      { value: 'phone', label: 'Phone', required: ['required'], errorMessages: ['this field is required'], options: [] },
      { value: 'address_line_1', label: 'Address 1 *', required: ['required'], errorMessages: ['this field is required'], options: [] },
      { value: 'address_line_2', label: 'Address 2', required: [], options: [] },
      { value: 'postcode', label: 'Postcode *', required: ['required'], errorMessages: ['this field is required'], options: [] },
      { value: 'suburb', label: 'Suburb *', required: ['required'], errorMessages: ['this field is required'], options: [] },
      { value: 'state', label: 'State/Region *', required: ['required'], errorMessages: ['this field is required'], options: [] },
      { value: 'country', label: 'Country * ', required: ['required'], errorMessages: ['this field is required'], options: countries, select: true },
      { value: 'customer_type', label: 'Type ', required: [], options: ["", "Wholesale", "Retail"], select: true },
    ]

  return (
    <Grid
      container
      spacing={2}
      direction="column"
    >
      <Grid
        item
        lg={6}
        md={6}
        xl={6}
        xs={12}
      >
        <CustomerEdit
          onClose={handleClose}
          countries={countries}
          values={values}
          handleChange={handleChange}
          handleSave={submitForm}
          open={open}
          fields={fields}
          title="Create Customer"
          error={error}
        />
      </Grid>
    </Grid>
  );
}


const styles = theme => ({
  dialogBox: {
    minHeight: '92%',
  },
  dialog: {
    background: '#eeeeee',
    overflowY: 'scroll',
    '-webkit-overflow-scrolling': 'touch',
  },
  dialogTitle: {
    display: 'inline-block',
  },
  dialogContent: {
    minHeight: '500px'
  },
})

export default withMobileDialog({
  breakpoint: 'sm',
})(withStyles(styles)(ResponsiveDialog));


