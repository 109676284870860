import React from 'react'
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme, status) => ({
  root: {
    margin: 'auto',
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: 4,
    borderColor: status => status.color,
    color: status => status.color,
    padding: 7,
    maxWidth: '115px',
    textAlign: 'center',
    fontSize: '0.85em',
    [theme.breakpoints.down("sm")]: {
      minWidth: '-moz-available',
    }
  },
}));

const StatusCell = props => {

  const { status } = props;
  const classes = useStyles(status);


  return (
    <div className={classes.root} >
      <b>{status?.string}</b>
    </div>
  )
}

StatusCell.propTypes = {

}

export default StatusCell

