import { createMuiTheme, responsiveFontSizes} from '@material-ui/core';

import palette from './palette';
import typography from './typographyAdmin';
import overrides from './overrides';

let theme = createMuiTheme({
  palette,
  typography,
  overrides
});
theme = responsiveFontSizes(theme);

export default theme;
