import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button, Divider, Typography} from '@material-ui/core';


const styles = theme => ({
    container: {
      minHeight: "400px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "50px"
    },
    divider: {
      width: '100%'
    },
    resendEmail: {
      textAlign: 'center'
    }
  });



export class Step3 extends Component {

  render() {
    const { handleResendConfirmation, classes } = this.props;

    return (
      <div className={classes.container}>
        <Typography variant='h4'>
          Almost there
        </Typography>
        <Typography>
          Please check your email to activate your account
        </Typography>
        <Divider className={classes.divider}/>
        <div className={classes.resendEmail}>
          <Typography variant='h6' gutterBottom>
            No confirmation email received?
          </Typography>
          <Typography variant='subtitle2' gutterBottom paragraph>
            Please check your spam folder or
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color='secondary'
            onClick={handleResendConfirmation}
          >
            Resend confirmation email
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Step3)
