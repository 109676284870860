import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    layoutDesign: {
      marginTop: '20px',
      fontFamily: "lato",
      color: "#595959",
    },

    img: {
        height: 250,
        width: 250,
        // zIndex: 1041,
        marginTop: '20px',
        objectFit: 'contain',
        border: '1px solid black',
        padding: '5px',
    },
    progress: {
        borderRadius: '50%',
        height: 250,
        width: 250,
        zIndex: 1041
    },
    textInput: {
        marginTop: '20px',
        width: '100%'
    },
    customizedContent: {
      padding: '50px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },

}));


const TrackingEmails = props => {
  const classes = useStyles();
  return (
    <>
      <Typography>KeepSpace will send a dispatch email to your customers notifying them that the order has left the warehouse and in on route to them via the carrier
      This email will have a tracking link for your customer to follow along with the carrier. This reduces the customer support request on tracking update
          request to your company.</Typography>

      <Grid container spacing={8}>
        <Grid item xs={12} sm={12} md={4}>
          <div className={classes.customizedContent}>
            <Typography variant="h4"> Stages when emails are released </Typography>
            <div className={classes.textInput}>
              <Typography variant="h5"> Collected and In Transit </Typography>
              When the parcel is collected and begins it transit
                          </div>
            <div className={classes.textInput}>
              <Typography variant="h5"> Out for Delivery </Typography>
              When the parcel reaches its last mile delivery zone an is
             planned to be arriving that day
                          </div>
            <div className={classes.textInput}>
              <Typography variant="h5"> Failed Attempt </Typography>
              When a parcel has been attempted to be accepted
              but something occurs and is requiring to be out for
              Delivery again.
                          </div>
            <div className={classes.textInput}>
              <Typography variant="h5"> Delivered </Typography>
              When the parcel has been accepted by the receiver
                          </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={8}>
          <div className={classes.layoutDesign}>
            <Typography variant="h4" >Layout Design</Typography>

            <div className={classes.emailContent} style={{ border: '1px black solid', padding: '30px', marginTop: "20px" }}>
              <div style={{ color: '#505050', fontFamily: ' Arial', fontSize: '14px', lineHeight: '150%', textAlign: 'left' }}>
                <br />
                Hi Mark,<br />
                Great! xxxxxx was delivered to its destination on 16 September, 2019 10:56.<br />
                <br />
                <span style={{ fontWeight: 'bold' }}>View Tracking Details:</span> <a href='http://track.keepspace.com.au/xxxxxxxxxxxxxxxxxxxxx' style={{ color: '#e77f11', textDecoration: 'underline' }}>http://track.keepspace.com.au/xxxxxxxxxxxxxxxxxxxxx</a><br />
                <span style={{ fontWeight: 'bold' }}>Tracking Number:</span> xxxxxxxxxxxxxxxxx<br />
                <span style={{ fontWeight: 'bold' }}>Detail Status:</span> Delivered<br />
                <span style={{ fontWeight: 'bold' }}>Courier:</span> Australia Post<br />
                <span style={{ fontWeight: 'bold' }}>Sign By:</span> <br />
                <span style={{ fontWeight: 'bold' }}>Courier Contact:</span> +61 x xxxx xxxx<br />
                <br />
                Thank you very much!<br />
                <br />

              </div>
            </div>
          </div>

        </Grid>

      </Grid>

    </>
  );
};


export default TrackingEmails;
