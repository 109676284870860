import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Tabs, Tab, Divider, colors } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "components";
import { Page } from "components";
import { Header, Overview, Chat, Pricing } from "./components";
import {
  fetchOrderByID,
  cancelOrders,
  setPreviousPath,
} from "actions/orderActions";

import {
  fetchPublicCommentById,
  updateConversationRead,
} from "actions/publicCommentActions";
import ConfirmAlert from "components/Help/ConfirmAlert";
import SuccessSnackbar from "components/Help/SuccessSnackbar";
import { Dots } from "components/Loading";
import { UID } from "AppConstants";
import checkPermission from "components/checkPermission";
import keepspaceApi from "services/keepspaceApi";
import { set } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingTop: theme.spacing(1),
    },
  },
  tabs: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  divider: {
    backgroundColor: colors.grey[300],
  },
  content: {
    // marginBottom: theme.spacing(6),
    marginTop: theme.spacing(3),
  },
  loading: {
    marginTop: "200px",
  },
}));

const OrdersDetails = (props) => {
  const { match, history, location, countries } = props;
  const classes = useStyles();
  const { id, tab } = match.params;
  const dispatch = useDispatch();
  const [openAlert, setOpenAlert] = useState(false);
  const [reportServices, setReportServices] = useState([]);
  const [loadingLabel, setLoadingLabel] = useState(false);
  const [error, setError] = useState(null);

  let order = useSelector((state) => state.orderReducer.fetchedOrder);
  let orderDetailLoading = useSelector(
    (state) => state.orderReducer.childLoading
  );
  let conversation = useSelector(
    (state) => state.publicCommentsReducer.conversation
  );
  let previousPath = useSelector((state) => state.orderReducer.previousPath);
  const currentUser = useSelector(
    (state) => state.reduxTokenAuth.currentUser.attributes
  );
  const currentOrganisation = useSelector(
    (state) => state.organisationsReducer.currentOrganisation
  );

  const handleTabsChange = (event, value) => {
    if (value === "chat") {
      dispatch(updateConversationRead(id));
    }

    history.push(value);
  };

  useEffect(() => {
    if (Boolean(location.state)) {
      dispatch(setPreviousPath(location.state.from));
    }
  });

  useEffect(() => {
    if (location && location.errorMessage) {
      setError(location.errorMessage);
    }
  }, [location]);

  useEffect(() => {
    const orderDetails = async () => {
      if (order.id !== parseFloat(id)) {
        const orderResponse = await dispatch(fetchOrderByID(id));
        if(orderResponse['message'] === '404'){
            redirectToOrderPage();
        }
      }
    };
    orderDetails();
  }, [dispatch, id]);


  useEffect(() => {
    if (
      (conversation && conversation.length === 0) ||
      conversation.id !== parseFloat(id)
    )
      dispatch(fetchPublicCommentById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (order && order.booking_type == "inbound") {
      keepspaceApi
        .getInboundReport(order.id)
        .then((json) => {
          setReportServices(json.data.report.services);
        })
        .catch((error) => {
          setError(error.response.data.error_message);
        });
    }
  }, [order]);

  let currentUserAvatar;
  currentUserAvatar = currentUser.id
    ? currentUser.avatar.url ||
      require(`assets/img/avatars/00${(currentUser.id % 46) + 1}.svg`)
    : null;

  if (!tab) {
    return (
      <Redirect to={`/user/${UID}/orders/summary/${id}/details/overview`} />
    );
  }
  const redirectToOrderPage = () => {
    setError('Order Not Found.')
    setTimeout(() => {
      props.history.push(`/user/${UID}/orders/summary`);
    }, 3000);
  }

  if (order && Object.keys(order).length === 0) {
    return null;
  }

  const tabs = [
    { value: "overview", label: "Overview" },
    { value: "chat", label: "Chat" },
    { value: "pricing", label: "Price / Margin" },
  ];

  if (!tabs.find((t) => t.value === tab)) {
    return <Redirect to='/errors/error-404' />;
  }

  const onGoBack = () => {
    let path = previousPath ? previousPath : `/user/${UID}/orders/summary`;
    history.push(path);
  };

  const handleCancel = (event) => {
    dispatch(cancelOrders([id]));
    handleAlertClose();
  };

  const handleAlertOpen = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const getLabel = async () => {
    setLoadingLabel(true);
    keepspaceApi
      .getInboundLabel(order.id)
      .then((response) => {
        let filename = getFilename(response);
        let label = response.data;
        downloadLabel(label, filename);
        setLoadingLabel(false);
      })
      .catch((error) => {
        setError(error.response.statusText);
        setLoadingLabel(false);
      });
  };

  const downloadLabel = (label, filename) => {
    const pdfBlob = new Blob([label], { type: "application/pdf" });
    var downloadUrl = URL.createObjectURL(pdfBlob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", filename); //any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
    setTimeout(function() {
      URL.revokeObjectURL(downloadUrl);
    }, 100);
  };

  const getFilename = (data) => {
    let headerLine = data.headers["content-disposition"];
    let startFileNameIndex = headerLine.indexOf('"') + 1;
    let endFileNameIndex = headerLine.lastIndexOf('"');
    let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
    return filename;
  };

  if (orderDetailLoading) {
    return (
      <div className={classes.loading}>
        <Dots />
      </div>
    );
  }
  const permittedToAccess = (page) => {
    return checkPermission(
      currentOrganisation.currentOrganisationUser.role,
      page
    );
  };

  return (
    <Page className={classes.root} title='Order Details'>
      <SuccessSnackbar
        open={Boolean(error)}
        error={error ? error : null}
        onClose={() => setError(null)}
      />

      <ConfirmAlert
        open={openAlert}
        description='Do you really want to cancel this order?'
        onSubmit={handleCancel}
        onClose={handleAlertClose}
      />
      <Header
        order={order}
        onGoBack={onGoBack}
        cancelOrders={handleAlertOpen}
        disabled={
          order.batch_id ||
          ![
            "info_received",
            "no_stock",
            "amending",
            "unconfirmed",
            "awaiting_arrival",
          ].includes(order.status)
        }
      />
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons='auto'
        value={tab}
        variant='scrollable'
      >
        {tabs.map((tab) =>
          permittedToAccess(`orders:${tab.value}`) ? (
            <Tab
              key={tab.value}
              label={
                tab.value === "chat" && conversation.unread > 0 ? (
                  <div>
                    {tab.value}
                    <Label
                      className={classes.unread}
                      color={colors.red[500]}
                      shape='rounded'
                    >
                      {conversation.unread}
                    </Label>
                  </div>
                ) : (
                  tab.value
                )
              }
              value={tab.value}
            />
          ) : null
        )}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {tab === "overview" && (
          <Overview
            id={id}
            order={order}
            countries={countries}
            report={reportServices}
            getLabel={getLabel}
            loadingLabel={loadingLabel}
          />
        )}
        {tab === "chat" && (
          <Chat
            id={id}
            conversation={conversation}
            currentUserAvatar={currentUserAvatar}
          />
        )}
        {tab === "pricing" && (
          <Pricing
            id={id}
            order={order}
            permittedToAccess={permittedToAccess}
          />
        )}
      </div>
    </Page>
  );
};

OrdersDetails.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default OrdersDetails;
