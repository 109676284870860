import adminApi from 'services/admin/adminApi';
/*----------------transactions--------------------------*/
export const FETCH_TRANSACTIONS_BEGIN = 'FETCH_TRANSACTIONS_BEGIN';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_FAILURE = 'FETCH_TRANSACTIONS_FAILURE';

export const DELETE_TRANSACTION = 'DELETE_TRANSACTION'
export const DELETE_ORG_TRANSACTION_SUCCESS = 'DELETE_ORG_TRANSACTION_SUCCESS'
export const DELETE_ORG_TRANSACTION_FAILURE = 'DELETE_ORG_TRANSACTION_FAILURE'

export const GET_ORG_TRANSACTION_BY_ID_BEGIN = 'GET_ORG_TRANSACTION_BY_ID_BEGIN';
export const GET_ORG_TRANSACTION_BY_ID_SUCCESS = 'GET_ORG_TRANSACTION_BY_ID_SUCCESS';
export const GET_ORG_TRANSACTION_BY_ID_FAILURE = 'GET_ORG_TRANSACTION_BY_ID_FAILURE';

export const CREATE_ORG_TRANSACTION_BEGIN = 'CREATE_ORG_TRANSACTION_BEGIN';
export const CREATE_ORG_TRANSACTION_SUCCESS = 'CREATE_ORG_TRANSACTION_SUCCESS';
export const CREATE_ORG_TRANSACTION_FAILURE = 'CREATE_ORG_TRANSACTION_FAILURE';

export const UPDATE_TRANSACTIONS_BEGIN = 'UPDATE_TRANSACTIONS_BEGIN';
export const UPDATE_ORG_TRANSACTION_SUCCESS = 'UPDATE_ORG_TRANSACTION_SUCCESS';
export const UPDATE_ORG_TRANSACTION_FAILURE = 'UPDATE_ORG_TRANSACTION_FAILURE';


export const CLEAR_TRANSACTIONS = 'CLEAR_TRANSACTIONS';
export const CLEAR_TRANSACTIONS_NOTIFICATION = 'CLEAR_TRANSACTIONS_NOTIFICATION';


/*--------------------Transactions----------------------------*/
export function fetchTransactions(param, id) {
    return dispatch => {
        dispatch(fetchTransactionsBegin());//calling this action to show loading
        return adminApi.fetchTransactions(param, id)// calling list of materials
            .then(json => {

                dispatch(fetchTransactionsSuccess(json));
                return json;
            })
            .catch(error => {
                console.log('error', error);

                dispatch(fetchTransactionsFailure(error))
                

            });
    };
}

export function deleteTransactions(id) {
    return dispatch => {
        return adminApi.deleteTransactions(id)
            .then(json => {
                dispatch(deleteTransactionsSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(deleteTransactionsFailure(error))

            });
    }
}


export function fetchTransactionsByID(id) {
    return dispatch => {
        dispatch(fetchTransactionsBegin());//calling this action to show loading
        return adminApi.getTransactionDetail(id)
            .then(handleErrors)
            .then(json => {
                dispatch(fetchTransactionByIDSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(fetchTransactionByIDFailure(error))
            });
    }
}

export function updateTransactions(data, id) {
    return dispatch => {
        dispatch(updateTransactionsBegin());
        let res = adminApi.updateTransaction(data, id)
            .then(json => {
                dispatch(updateTransactionsSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(updateTransactionsFailure(error));

            })
        return res;
    }
}

export const fetchTransactionsBegin = () => (
    // the whole object is n action
    {
        type: FETCH_TRANSACTIONS_BEGIN
    });

export const fetchTransactionsSuccess = json => ({
    type: FETCH_TRANSACTIONS_SUCCESS,
    data: json.data
});
export const fetchTransactionsFailure = error => ({
    type: FETCH_TRANSACTIONS_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});


export const deleteTransactionsSuccess = (json) => ({
    type: DELETE_ORG_TRANSACTION_SUCCESS,
    data: json.data.supplier,
    msg: json.message
})

export const deleteTransactionsFailure = (error) => ({
    type: DELETE_ORG_TRANSACTION_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'},
    msg: "An error occurred, please try again later"
});

export const fetchTransactionByIDBegin = () => ({
    type: GET_ORG_TRANSACTION_BY_ID_BEGIN
});
export const fetchTransactionByIDSuccess = json => ({
    type: GET_ORG_TRANSACTION_BY_ID_SUCCESS,
    data: json.data.supplier
});

export const fetchTransactionByIDFailure = error => ({
    type: GET_ORG_TRANSACTION_BY_ID_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'},
    msg: "An error occurred, please try again later"
});



export function updateTransactionsBegin() {
    return {
        type: UPDATE_TRANSACTIONS_BEGIN
    }
}

export function updateTransactionsSuccess(json) {
    return {
        type: UPDATE_ORG_TRANSACTION_SUCCESS,
        data: json.data.supplier

    }
}

export function updateTransactionsFailure(error) {
    return {
        type: UPDATE_ORG_TRANSACTION_FAILURE,
        payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
    }
}

export function createTransaction(data, id) {
    return dispatch => {
        dispatch(createTransactionBegin());
        let res = adminApi.createTransaction(data, id)
            .then(json => {
                dispatch(createTransactionSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(createTransactionFailure(error));
            })
        return res;
    }

}

export function createTransactionBegin() {
    return {
        type: CREATE_ORG_TRANSACTION_BEGIN,
    }
}


export const createTransactionSuccess = supplier => ({
    type: CREATE_ORG_TRANSACTION_SUCCESS,
    payload: {supplier}
});

export const createTransactionFailure = error => ({
    type: CREATE_ORG_TRANSACTION_FAILURE,
    payload: {error: (error.response) ? error.response.data : 'Something went wrong. Please try again'}
});

export function clearTransactions() {
    return {
        type: CLEAR_TRANSACTIONS
    }
}

export function clearTransactionsNotifications(){
    return {
        type:CLEAR_TRANSACTIONS_NOTIFICATION
    }
}

// Private methods

function handleErrors(response) {
    if(!response.success) {
        throw Error(response.statusText);
    }
    return response;
}
