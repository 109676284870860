import keepspaceApi from 'services/keepspaceApi';

export const FETCH_USERS_BEGIN = 'FETCH_USERS_BEGIN';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'

export const UPDATE_ORGANISATION_USER_BEGIN = 'UPDATE_ORGANISATION_USER_BEGIN';
export const UPDATE_ORGANISATION_USER_SUCCESS = 'UPDATE_ORGANISATION_USER_SUCCESS';
export const UPDATE_ORGANISATION_USER_FAILURE = 'UPDATE_ORGANISATION_USER_FAILURE'

export const CREATE_ORGANISATION_USER_BEGIN = 'CREATE_ORGANISATION_USER_BEGIN';
export const CREATE_ORGANISATION_USER_SUCCESS = 'CREATE_ORGANISATION_USER_SUCCESS';
export const CREATE_ORGANISATION_USER_FAILURE = 'CREATE_ORGANISATION_USER_FAILURE'

export const DELETE_ORGANISATION_USER_BEGIN = 'DELETE_ORGANISATION_USER_BEGIN';
export const DELETE_ORGANISATION_USER_SUCCESS = 'DELETE_ORGANISATION_USER_SUCCESS';
export const DELETE_ORGANISATION_USER_FAILURE = 'DELETE_ORGANISATION_USER_FAILURE'

export const ACCEPT_INVITATION_BEGIN = 'ACCEPT_INVITATION_BEGIN';
export const ACCEPT_INVITATION_SUCCESS = 'ACCEPT_INVITATION_SUCCESS';
export const ACCEPT_INVITATION_FAILURE = 'ACCEPT_INVITATION_FAILURE'

export const RESEND_INVITATION_BEGIN = 'RESEND_INVITATION_BEGIN';
export const RESEND_INVITATION_SUCCESS = 'RESEND_INVITATION_SUCCESS';
export const RESEND_INVITATION_FAILURE = 'RESEND_INVITATION_FAILURE'

export function fetchUsers() {
  return dispatch => {
      dispatch(fetchUsersBegin());
      return keepspaceApi.getOrganisationUsers()
          .then(json => {
              dispatch(fetchUsersSuccess(json));
              return json;
          })
          .catch(error => {
              console.log(error)
              dispatch(fetchUsersFailure(error))
          });
  };
}

export function updateOrganisationUser(organisationUser) {
  return dispatch => {
      dispatch(updateOrganisationUserBegin());
      return keepspaceApi.updateOrganisationUser(organisationUser)
          .then(json => {
              dispatch(updateOrganisationUserSuccess(json));
              return json;
          })
          .catch(error => {
              console.log(error)
              dispatch(updateOrganisationUserFailure(error))
          });
  };
}

export function createOrganisationUser(formData) {
  return dispatch => {
      dispatch(createOrganisationUserBegin());
      let res = keepspaceApi.createOrganisationUser(formData)
          .then(json => {
              dispatch(createOrganisationUserSuccess(json));
              return json;
          })
          .catch(error => {
              dispatch(createOrganisationUserFailure(error))
          });
      return res
  }
}

export function acceptInvitation(organisation_user) {
  return dispatch => {
      dispatch(acceptInvitationBegin());
      return keepspaceApi.acceptExsistingUserInvitation(organisation_user)
          .then(json => {
              dispatch(acceptInvitationSuccess(json));
              return json;
          })
          .catch(error => {
              console.log(error)
              dispatch(acceptInvitationFailure(error))
          });
  };
}

export function resendInvitation(organisationUserId) {
  return dispatch => {
      dispatch(resendInvitationBegin());
      return keepspaceApi.resendInvitation(organisationUserId)
          .then(json => {
              dispatch(resendInvitationSuccess(json));
              return json;
          })
          .catch(error => {
              dispatch(resendInvitationFailure(error))
          });
  };
}

export function deleteOrganisationUser(organisationUserId) {
  return dispatch => {
      dispatch(deleteOrganisationUserBegin());
      return keepspaceApi.deleteOrganisationUser(organisationUserId)
          .then(() => {
              dispatch(deleteOrganisationUserSuccess(organisationUserId));
              return organisationUserId;
          })
          .catch(error => {
              dispatch(deleteOrganisationUserFailure(error))
          });
  };
}

export const deleteOrganisationUserBegin = () => ({
  type: DELETE_ORGANISATION_USER_BEGIN
});

export const deleteOrganisationUserSuccess = id => ({
  type: DELETE_ORGANISATION_USER_SUCCESS,
  payload: { id }
});

export const deleteOrganisationUserFailure = error => ({
  type: DELETE_ORGANISATION_USER_FAILURE,
  payload: { error }
});

export const fetchUsersBegin = () => ({
  type: FETCH_USERS_BEGIN
});

export const fetchUsersSuccess = users => ({
  type: FETCH_USERS_SUCCESS,
  payload: { users }
});

export const fetchUsersFailure = error => ({
  type: FETCH_USERS_FAILURE,
  payload: { error }
});

export const updateOrganisationUserBegin = () => ({
  type: UPDATE_ORGANISATION_USER_BEGIN
});

export const updateOrganisationUserSuccess = organisationUser => ({
  type: UPDATE_ORGANISATION_USER_SUCCESS,
  payload: { organisationUser }
});

export const updateOrganisationUserFailure = error => ({
  type: UPDATE_ORGANISATION_USER_FAILURE,
  payload: { error }
});

export const createOrganisationUserBegin = () => ({
  type: CREATE_ORGANISATION_USER_BEGIN
});

export const createOrganisationUserSuccess = organisationUser => ({
  type: CREATE_ORGANISATION_USER_SUCCESS,
  payload: { organisationUser }
});

export const createOrganisationUserFailure = error => ({
  type: CREATE_ORGANISATION_USER_FAILURE,
  payload: { error }
});

export const acceptInvitationBegin = () => ({
  type: ACCEPT_INVITATION_BEGIN
});

export const acceptInvitationSuccess = organisationUser => ({
  type: ACCEPT_INVITATION_SUCCESS,
  payload: { organisationUser }
});

export const acceptInvitationFailure = error => ({
  type: ACCEPT_INVITATION_FAILURE,
  payload: { error }
});

export const resendInvitationBegin = () => ({
  type: RESEND_INVITATION_BEGIN
});

export const resendInvitationSuccess = organisationUser => ({
  type: RESEND_INVITATION_SUCCESS,
  payload: { organisationUser }
});

export const resendInvitationFailure = error => ({
  type: RESEND_INVITATION_FAILURE,
  payload: { error }
});
