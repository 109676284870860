import {
    FETCH_ADMIN_USERS_BEGIN,
    FETCH_ADMIN_USERS_SUCCESS,
    FETCH_ADMIN_USERS_FAILURE,
    CREATE_ADMIN_USER_BEGIN,
    CREATE_ADMIN_USER_SUCCESS,
    CREATE_ADMIN_USER_FAILURE,
    GET_ADMIN_USER_BY_ID_BEGIN,
    GET_ADMIN_USER_BY_ID_SUCCESS,
    GET_ADMIN_USER_BY_ID_FAILURE,
    UPDATE_ADMIN_USER_SUCCESS,
    UPDATE_ADMIN_USER_FAILURE,
    DELETE_ADMIN_USER_SUCCESS,
    DELETE_ADMIN_USER_FAILURE,
    CLEAR_ADMIN_USERS,
    UPDATE_ADMIN_USERS_BEGIN,
    CLEAR_ADMIN_USERS_FILTER,
    SET_ADMIN_USERS_FILTER,
    CLEAR_USERS_NOTIFICATION
} from 'actions/admin/UserActions';


const initialState = {
    users: [],
    loading: false,
    error: null,
    notification: null,
    dummyData: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    pagination: {},
    userDetail: {},
    formSuccess: false,
    activeFilter: {
        page: 1,
        per_page: 25,
        name: null,
        role_id: null,
        job_role: null
    }
}

export default function adminUsers(state = initialState, action) {
    switch(action.type) {
        case FETCH_ADMIN_USERS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                notification: null
            };
        case FETCH_ADMIN_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.data.admin_users,
                pagination: action.data.pagination,
                filters: action.data.filters
            };
        case FETCH_ADMIN_USERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                users: []
            };

        case CREATE_ADMIN_USER_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                notification: null
            };
        case CREATE_ADMIN_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                formSuccess: true
            };
        case CREATE_ADMIN_USER_FAILURE:
            return {
                ...state,
                loading: false,
                formSuccess: false,
                error: action.payload.error,
            };
        case GET_ADMIN_USER_BY_ID_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                notification: null
            };

        case GET_ADMIN_USER_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                userDetail: action.data
            };

        case GET_ADMIN_USER_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case UPDATE_ADMIN_USERS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                notification: null
            };
        case UPDATE_ADMIN_USER_SUCCESS:

            return {
                ...state,
                loading: false,
                formSuccess: true,
                userDetail: action.data
            }
        case UPDATE_ADMIN_USER_FAILURE:
            return {
                ...state,
                loading: false,
                formSuccess: false,
                error: action.payload.error,
            };
        case DELETE_ADMIN_USER_SUCCESS:
            return {
                ...state,
                formSuccess: true,
                loading: false,
                notification: action.msg
            };
        case DELETE_ADMIN_USER_FAILURE:
            return {
                ...state,
                loading: false,
                formSuccess: false,
                error: action.payload.error,
            };
        case CLEAR_ADMIN_USERS:
            return {
                ...state,
                users: [],
                loading: false,
                error: null,
                userDetail: {},
                formSuccess: false
            }
        case SET_ADMIN_USERS_FILTER:
            return {
                ...state,
                activeFilter: {...action.payload.filters}
            }
        case CLEAR_ADMIN_USERS_FILTER:
            return {
                ...state,
                activeFilter: initialState.activeFilter
            }
        case CLEAR_USERS_NOTIFICATION:
            return {
                ...state,
                error:null,
                notification:null
            }
        default:
            return state
    }
}
