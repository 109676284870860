import {
  blackColor,
  facebookColor,
  googleColor,
  hexToRgb,
  whiteColor,
} from 'assets/jss/material-dashboard-pro-react.jsx';

const style =  {
  buttons: {
    // display: 'flex',
    // justifyContent: 'space-between',
    width: '100%'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    padding: '16px 10px 24px',
    boxShadow: 'none'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: 8,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: '20px'
  },
  submit: {
    margin: '24px 0',
  },
  subtitle: {
    width: '100%',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '20px',
    fontSize: 14,
    lineHeight: 'normal'
  },
  header: {
    fontSize: 28,
    fontWeight: 'lighter',
    padding: '50px 0'
  },
  alignRight: {
    width: '100%',
    textAlign: 'right',
    height: '10%'
  },
  divider: {
    paddingTop: '20px',
    width: '100%',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.35)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.35)',
    lineHeight: '0.1em',
    margin: '10px 0 20px',
    "& > span": {
      background: '#ffffff',
      padding: '0 10px',
    }
  },
  facebook: {
    // padding: 'calc(-0.656vw + 18.388px) calc(3.344vw + 18.38843px);',
    // width: '170px',
    backgroundColor: facebookColor,
    margin: '2px',
    color: whiteColor,
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(facebookColor) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(facebookColor) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(facebookColor) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: facebookColor,
      color: whiteColor,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(facebookColor) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(facebookColor) +
        ", 0.2)"
    }
  },
  google: {
    backgroundColor: googleColor,
    color: whiteColor,
    width: '170px',
    margin: '2px',
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(googleColor) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(googleColor) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(googleColor) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: googleColor,
      color: whiteColor,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(googleColor) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(googleColor) +
        ", 0.2)"
    }
  },
  betaVersionText: {
    marginTop: 30,
    justifyContent: 'center',
  }
}

export default style
