import React from 'react'
import {withStyles} from '@material-ui/core/styles';

function Cell(props) {
    const {classes} = props;
    return (
        <div className={classes.root}>
            {props.children}
        </div>
    )
}

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minHeight: '64px',
        padding: '4px 16px',
        borderBottom: '#ccc 1px solid'
    }
})

export default withStyles(styles)(Cell)