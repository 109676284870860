import React from 'react'
import {CardWithTitle} from 'components/Card';
import {
    makeStyles
} from "@material-ui/core";
import Form from 'components/Form';

const AdditionalDetails = props => {
    const {formInputs, formState, formErrors, onChange} = props;
    const classes = useStyles();

    return (
        <CardWithTitle className={classes.card} title="What are the shipping details?" >
            <Form key="3additionaldetails" inputs={formInputs} state={formState} onChange={onChange} errors={formErrors} gridWidth={6} />
        </CardWithTitle>
    )
}

const useStyles = makeStyles(theme => ({

    card: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },

}))

export default AdditionalDetails;
