import React, { Component } from 'react';
import { withStyles, Paper } from '@material-ui/core';
import { Typography } from '@material-ui/core';

class index extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Paper className={classes.block}>
                    <Typography className={classes.header} variant='h1'>Typography</Typography> 
                    <div className={classes.innerBlock}>
                        <Typography variant='h1'>h1</Typography>
                        <Typography variant='h2'>h2</Typography>
                        <Typography variant='h3'>h3</Typography>
                        <Typography variant='h4'>h4</Typography>
                        <Typography variant='h5'>h5</Typography>
                        <Typography variant='h6'>h6</Typography>
                        <Typography variant='subtitle1'>subtitle1</Typography>
                        <Typography variant='subtitle2'>subtitle2</Typography>
                        <Typography variant='body1'>body1</Typography>
                        <Typography variant='body2'>body2</Typography>
                        <Typography variant='button'>button</Typography>
                        <Typography variant='caption'>caption</Typography>
                        <Typography variant='overline'>overline</Typography>
                        <span>unformatted</span>
                    </div>
                </Paper>
            </div>
        );
    }
}

const styles = (theme) => ({
    root: {
        padding: theme.spacing(2)
    },
    block: {
        border: '1px solid #cccc',
        padding: theme.spacing(1)
    },
    header: {
        paddingBottom: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    innerBlock: {
        margin: theme.spacing(2),
    }
});

export default withStyles(styles)(index);